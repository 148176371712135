// @flow
import camelCase from "lodash/camelCase";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import reduce from "lodash/reduce";
import snakeCase from "lodash/snakeCase";
import {type FieldBuilderType} from "../packages/FormBuilder/FiledBuilderType";

type GroupedFieldBuilerType = {
    [key: string]: FieldBuilderType,
};

export function camelCaseKeys<T: any>(obj: T): T {
    if (!isObject(obj)) {
        return obj;
    } else if (isArray(obj)) {
        return obj.map((value) => camelCaseKeys(value));
    }

    // $FlowFixMe
    return reduce(
        obj,
        (sum, value, key) => ({
            ...sum,
            [camelCase(key)]: camelCaseKeys(value),
        }),
        {},
    );
}

export function snakeCaseKeys<T>(obj: T): T {
    if (!isObject(obj)) {
        return obj;
    } else if (isArray(obj)) {
        return obj.map((value) => snakeCaseKeys(value));
    }

    // $FlowFixMe
    return reduce(
        obj,
        (sum, value, key) => ({
            ...sum,
            [snakeCase(key)]: snakeCaseKeys(value),
        }),
        {},
    );
}

export const groupByOne = (attributes?: Array<FieldBuilderType>, key: string): GroupedFieldBuilerType => {
    const groupedAttributes = {};

    if (attributes) {
        attributes.forEach((attribute) => {
            groupedAttributes[attribute[key]] = attribute;
        });
    }

    return groupedAttributes;
};

export const validPattern = (data: Object, src?: Array<Object>, title?: string) => {
    let result = false;

    if (src) {
        const newSrc = src.slice(0, src.length + 1);

        if (title && title.indexOf("$") !== -1) {
            const titleArrPattern = title.match(/\$\d+/gi);

            if (titleArrPattern) {
                titleArrPattern.forEach((pattern) => {
                    const index = Number(pattern.slice(1));

                    newSrc.splice(index, 1);
                });
            }
        }

        result = newSrc.some((item) => {
            const {key} = item;
            const value = data[camelCase(key)];

            return value !== null && value !== undefined && value !== "";
        });
    }

    return result;
};
