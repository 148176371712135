// @flow
import * as actionTypes from "../../constants/ActionTypes";
import {createReducer} from "../../../../redux/reducers/createReducer";

export type TransmitDataReducerType = {
    [key: string]: any,
};

const initialState: TransmitDataReducerType = {};

export const transmitDataReducer = createReducer(initialState, {
    [actionTypes.TRANSMIT_DATA_ACTION]: (state, {payload}) => ({
        ...state,
        [payload.key]: payload.data,
    }),
});
