// @flow
import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import DialogActionsMaterial from "@material-ui/core/DialogActions";

const styles = (theme: any) => {
    const spacing = theme.spacing.unit + theme.spacing.unit / 2;

    return {
        action: {
            margin: 8,
        },
        root: {
            "& button": {
                margin: 8,
            },
            justifyContent: "flex-start",
            padding: `0 ${spacing}px ${spacing}px ${spacing}px`,
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
            },
        },
    };
};

type Props = {
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
};

export class DialogActions extends Component<Props> {
    render() {
        return <DialogActionsMaterial {...this.props} />;
    }
}

export default withStyles(styles)(DialogActions);
