// @flow
import * as actionTypes from "../../constants/types/EnergyContractsActionTypes";
import {type ApiMiddlewareActionType, type ApiMiddlewareResponsePromiseType} from "../middlewares/apiMiddleware";

export type CrmRegPowerSupplyContractActionType = (
    kdProvider: number,
    session: string,
    prMirror: boolean,
) => ApiMiddlewareResponsePromiseType;

export type PowerSupplyInfoVisibilityActionType = (session: string) => ApiMiddlewareResponsePromiseType;

export type PowerSupplyProvidersActionType = (session: string) => ApiMiddlewareResponsePromiseType;

export type GetPowSupProvidersActionType = (session: string) => ApiMiddlewareResponsePromiseType;

export type CrmGetPowerSupplyContractHistActionType = ({
    cancelToken: any,
    dtEn: string,
    dtSt: string,
    session: string,
}) => ApiMiddlewareResponsePromiseType;

export type CrmSetMsgReadedActionParamsType = {
    idService: number,
    idMessage: number,
    session: string,
};

export type ReadMessagesActionParamsType = {
    idService: number,
    unreadMessages: Array<number>,
    session: string,
};

export type CrmRegPowerSupplyTempLsActionParamsType = {
    kdProvider: number,
    nnLs: string,
    profileContact: string,
    session: string,
};

export type CrmSetMsgReadedActionType = (params: CrmSetMsgReadedActionParamsType) => ApiMiddlewareResponsePromiseType;

export type ReadMessagesActionType = (params: ReadMessagesActionParamsType) => ApiMiddlewareResponsePromiseType;

export const crmRegPowerSupplyContractAction = (kdProvider: number, session: string, prMirror: boolean) => (
    dispatch: any,
): ApiMiddlewareActionType => {
    const currentPath = window.location.href;

    return dispatch({
        api: {
            data: {
                idProvider: kdProvider,
                prMirror,
            },
            query: "CrmRegPowerSupplyContract",
            session,
            types: [
                actionTypes.CRM_REG_POWER_SUPPLY_CONTRACT_ACTION,
                actionTypes.CRM_REG_POWER_SUPPLY_CONTRACT_ACTION_SUCCESS,
                actionTypes.CRM_REG_POWER_SUPPLY_CONTRACT_ACTION_FAILURE,
            ],
        },
    })
        .then((action) => {
            if (!action.error && action.response && action.response.nmLink) {
                window.location.href = action.response.nmLink;
            } else {
                window.location.href = currentPath;
            }

            return action;
        })
        .catch(() => (window.location.href = currentPath));
};
export const getPowSupProvidersAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        list: true,
        query: "GetPowSupProviders",
        session,
        types: [
            actionTypes.GET_POW_SUP_PROVIDERS_ACTION,
            actionTypes.GET_POW_SUP_PROVIDERS_ACTION_SUCCESS,
            actionTypes.GET_POW_SUP_PROVIDERS_ACTION_FAILURE,
        ],
    },
});
export const crmGetPowerSupplyContractHistAction = ({
    cancelToken,
    dtSt,
    dtEn,
    session,
}: Object): ApiMiddlewareActionType => ({
    api: {
        data: {
            dtEn,
            dtSt,
        },
        query: "CrmGetPowerSupplyContractHist",
        session,
        types: [
            actionTypes.CRM_GET_POWER_SUPPLY_CONTRACT_HIST_ACTION,
            actionTypes.CRM_GET_POWER_SUPPLY_CONTRACT_HIST_ACTION_SUCCESS,
            actionTypes.CRM_GET_POWER_SUPPLY_CONTRACT_HIST_ACTION_FAILURE,
        ],
    },
    payload: {
        cancelToken,
    },
});
export const crmRejectServiceeAction = ({session, nmReason, idCrmService}: Object): ApiMiddlewareActionType => ({
    api: {
        data: {
            idCrmService,
            nmReason,
        },
        query: "CrmRejectService",
        session,
        types: [
            actionTypes.CRM_REJECT_SERVICEE_ACTION,
            actionTypes.CRM_REJECT_SERVICEE_ACTION_SUCCESS,
            actionTypes.CRM_REJECT_SERVICEE_ACTION_FAILURE,
        ],
    },
});
export const crmSetMsgReadedAction = ({
    idService,
    idMessage,
    session,
}: CrmSetMsgReadedActionParamsType): ApiMiddlewareActionType => ({
    api: {
        data: {
            idMessage,
            idService,
        },
        query: "CrmSetMsgReaded",
        session,
        types: [
            actionTypes.CRM_SET_MSG_READED_ACTION,
            actionTypes.CRM_SET_MSG_READED_ACTION_SUCCESS,
            actionTypes.CRM_SET_MSG_READED_ACTION_FAILURE,
        ],
    },
});

export const readMessagesAction = ({idService, session, unreadMessages}: ReadMessagesActionParamsType) => (
    dispatch: any,
): Promise<any> =>
    Promise.all(
        unreadMessages.map(async (idMessage: number) => {
            const res = await dispatch(crmSetMsgReadedAction({idMessage, idService, session}));

            return res;
        }),
    );

export const powerSupplyInfoVisibilityAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        query: "PowerSupplyInfoVisibility",
        session,
        types: [
            actionTypes.POWER_SUPPLY_INFO_VISIBILITY_ACTION,
            actionTypes.POWER_SUPPLY_INFO_VISIBILITY_ACTION_SUCCESS,
            actionTypes.POWER_SUPPLY_INFO_VISIBILITY_ACTION_FAILURE,
        ],
    },
});

export const powerSupplyProvidersAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        list: true,
        query: "PowerSupplyProviders",
        session,
        types: [
            actionTypes.POWER_SUPPLY_PROVIDERS_ACTION,
            actionTypes.POWER_SUPPLY_PROVIDERS_ACTION_SUCCESS,
            actionTypes.POWER_SUPPLY_PROVIDERS_ACTION_FAILURE,
        ],
    },
});

export const crmRegPowerSupplyTempLsAction = ({
    kdProvider,
    nnLs,
    profileContact,
    session,
}: CrmRegPowerSupplyTempLsActionParamsType): ApiMiddlewareActionType => ({
    api: {
        data: {
            kdProvider,
            nnLs,
            profileContact,
        },
        query: "CrmRegPowerSupplyTempLs",
        session,
        types: [
            actionTypes.CRM_REG_POWER_SUPPLY_TEMP_LS_ACTION,
            actionTypes.CRM_REG_POWER_SUPPLY_TEMP_LS_ACTION_SUCCESS,
            actionTypes.CRM_REG_POWER_SUPPLY_TEMP_LS_ACTION_FAILURE,
        ],
    },
});
export const crmAcceptDocAction = ({session, idCrmDoc}: Object): ApiMiddlewareActionType => ({
    api: {
        data: {
            idCrmDoc,
        },
        query: "CrmAcceptDoc",
        session,
        types: [
            actionTypes.CRM_ACCEPT_DOC_ACTION,
            actionTypes.CRM_ACCEPT_DOC_ACTION_SUCCESS,
            actionTypes.CRM_ACCEPT_DOC_ACTION_FAILURE,
        ],
    },
});

export const getLSListEditable = (session: string) => ({
    api: {
        list: true,
        query: "LSListEditable",
        session,
        types: [
            actionTypes.LS_LIST_EDITABLE_ACTION,
            actionTypes.LS_LIST_EDITABLE_ACTION_SUCCESS,
            actionTypes.LS_LIST_EDITABLE_ACTION_FAILURE,
        ],
    },
});
