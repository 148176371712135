// @flow

import React from "react";
import Button from "../Button/Button";
import Typography from "../Typography/Typography";

type Props = {
    labelLink: string,
    time: number,
    onClickLink: Function,
    onTimeEnd?: Function,
    waitTimeText: string,
    testID?: string,
    withStartTimer?: boolean,
};

type State = {
    seconds: number,
    interval: any,
};

const STEP = 1;
const TICK_MS = 1000;
const SEC_SEPARATOR = 10;
const MIN_UNCHENGEABLE_ENDING = 5;
const MAX_UNCHENGEABLE_ENDING = 20;

class LinkTimer extends React.Component<Props, State> {
    state = {
        interval: undefined,
        seconds: 0,
    };

    static defaultProps = {
        labelLink: "Выслать код повторно",
        testID: "linkTimer",
        time: 60,
        waitTimeText: "Запросить код повторно можно",
    };

    tick = () => {
        const {seconds, interval} = this.state;

        this.setState((prevState) => ({
            seconds: prevState.seconds - STEP,
        }));

        if (seconds === 0) {
            clearInterval(interval);
            this.setState({seconds: 0}, () => {
                if (this.props.onTimeEnd) {
                    this.props.onTimeEnd();
                }
            });
        }
    };

    resetTimer = (disableOnClickEvent?: boolean) => {
        if (disableOnClickEvent !== true) {
            this.props.onClickLink();
        }
        this.setState({
            interval: setInterval(this.tick, TICK_MS),
            seconds: this.props.time,
        });
    };

    componentDidMount() {
        this.resetTimer(true);
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    getEnding = (seconds: any) => {
        const lastNuber = seconds % SEC_SEPARATOR;

        switch (true) {
            case seconds >= MIN_UNCHENGEABLE_ENDING && seconds <= MAX_UNCHENGEABLE_ENDING:
            case lastNuber >= MIN_UNCHENGEABLE_ENDING:
                return "";
            case lastNuber <= 4 && lastNuber >= 2:
                return "ы";
            case lastNuber === 1:
                return "у";
            default:
                return "";
        }
    };

    render() {
        const {seconds} = this.state;
        const {labelLink, testID, waitTimeText} = this.props;

        return (
            <Typography fontSize={16} verticalPadding={16}>
                {seconds === 0 ? (
                    <Button link capitalize underline="dashed" onClick={this.resetTimer} testid={testID}>
                        {labelLink}
                    </Button>
                ) : (
                    `${waitTimeText} через ${seconds} секунд${this.getEnding(seconds)}`
                )}
            </Typography>
        );
    }
}

export default LinkTimer;
