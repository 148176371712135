/* eslint-disable max-lines */
// @flow
import sortBy from "lodash/sortBy";
import * as servicesActionTypes from "../../constants/types/ServicesActionTypes";
import {
    buildOrderFormConfig,
    transformVlPaidServiceListByData,
    getConfigs,
    getInitialValues,
} from "../../utils/servicesUtils";
import * as crmActionTypes from "../../constants/types/CrmActionTypes";
import {RESPONSE_RESULT_TEXT_CODE} from "../../constants/KdResults";
import {createReducer} from "./createReducer";

export type ServicesImgType = {
    fetchingImg: boolean,
    imgLink: string,
};

export type ServicesPromoFilterType = {
    idPromo: string,
    nnGroup: number,
    vlPriceListRow: Array<{
        idPriceListRow: string,
        nnCount: number,
    }>,
};

export type ServicesPromoItemType = {
    dtFinish: string,
    dtStart: string,
    idImg: Array<number>,
    idPromo: string,
    nmDescription: string,
    nmPromo: string,
    prUseButton: boolean,
    vlFilter: Array<ServicesPromoFilterType>,
};

export type ServicesPromoType = {
    fetching: boolean,
    promoList: Array<ServicesPromoItemType>,
};

export type ServicesReducerType = {
    configs: Object,
    initial: Object,
    initialValues: Object,
    orderConfigs: Array<Object>,
    paidServices: Array<Object>,
    providerList: Array<Object>,
    regionList: Array<Object>,
    serviceGroupsList: Array<Object>,
    paidServiceHistory: Array<Object>,
    fetchingPaidServiceParams: boolean,
    fetchingCrmPaidServiceAttr: boolean,
    fetchingServiceHistory: boolean,
    orderServiceFormIsOpen: boolean,
    paidResultModal: Object,
    catalogPayConfirmModal: {
        data: Object,
        serviceRekvFetching: boolean,
        open: boolean,
    },
    fetchingPaidServices: boolean,
    documents: Array<Object>,
    idCrmProvider: string,
    idCrmService: string,
    serviceAttributes: Array<Object>,
    serviceLsList: Array<Object>,
    img: ServicesImgType,
    servicesPromo: ServicesPromoType,
    orderPreCalcData: Object,
};

const initialServiceFiltersConfig = {
    lsFilter: {
        kdAttribute: 1,
        nmAttrType: "DICT",
        nmAttribute: "Адрес и лицевой счет",
        nmColumn: "LS",
        nmEntity: "Учетная запись",
        nmTable: "catalogForm",
        prBase: false,
        prRequired: true,
        prVisible: true,
        vlDict: [],
    },
    promocodeFilter: {
        helperText: "Промокод введен неверно",
        kdAttribute: 1,
        nmAttrDataType: "PROMOCODE",
        nmAttrType: "BASE",
        nmAttribute: "Введите промокод",
        nmColumn: "PROMOCODE",
        nmRegexp: "^[A-Za-zА-Яа-яЁё0-9]*$",
        nmRegexpError: "Может состоять из цифр и русских/латинских букв, чувствителен к регистру",
        nnFieldSize: 30,
        prVisible: true,
    },
    providerFilter: {
        kdAttribute: 1,
        nmAttrType: "DICT",
        nmAttribute: "Поставщик",
        nmColumn: "PROVIDER",
        nmTable: "catalogForm",
        prBase: false,
        prRequired: false,
        prVisible: true,
        vlDict: [],
    },
    regionFilter: {
        kdAttribute: 1,
        nmAttrType: "DICT",
        nmAttribute: "Регион",
        nmColumn: "REGION",
        nmTable: "catalogForm",
        prBase: false,
        prRequired: false,
        prVisible: true,
        vlDict: [],
    },
    serviceFilter: {
        kdAttribute: 1,
        nmAttrType: "DICT",
        nmAttribute: "Вид услуги",
        nmColumn: "SERVICE_TYPE",
        nmTable: "catalogForm",
        prBase: false,
        prRequired: false,
        prVisible: true,
        vlDict: [],
    },
};

const initialState: ServicesReducerType = {
    catalogPayConfirmModal: {
        data: {},
        open: false,
        serviceRekvFetching: false,
    },
    configs: initialServiceFiltersConfig,
    documents: [],
    fetchingCrmPaidServiceAttr: false,
    fetchingPaidServiceParams: false,
    fetchingPaidServices: false,
    fetchingServiceHistory: false,
    idCrmProvider: "",
    idCrmService: "",
    img: {
        fetchingImg: false,
        imgLink: "",
    },
    initial: {},
    initialValues: {},
    orderConfigs: [],
    orderPreCalcData: {},
    orderServiceFormIsOpen: false,
    paidResultModal: {},
    paidServiceAttributes: [],
    paidServiceHistory: [],
    paidServiceLSList: [],
    paidServices: [],
    providerList: [],
    regEmailPaidService: [],
    regionList: [],
    serviceAttributes: [],
    serviceGroupsList: [],
    serviceLsList: [],
    servicesPromo: {
        fetching: false,
        promoList: [],
    },
};

export const servicesReducer = createReducer(initialState, {
    [servicesActionTypes.REG_PAID_SERVICE_ACTION_SUCCESS]: (state, {response}) =>
        response.kdResult === RESPONSE_RESULT_TEXT_CODE
            ? {
                  ...state,
                  paidResultModal: {...response, open: true},
              }
            : state,
    [servicesActionTypes.CLOSE_PAY_CONFIRM_MODAL_ACTION]: (state) => ({
        ...state,
        catalogPayConfirmModal: {...state.catalogPayConfirmModal, open: false},
        paidResultModal: {...state.paidResultModal, open: false},
    }),
    [servicesActionTypes.GET_PAID_SERVICE_LIST_ACTION]: (state) => ({
        ...state,
        fetchingPaidServices: true,
    }),
    [servicesActionTypes.GET_PAID_SERVICE_LIST_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        fetchingPaidServices: false,
        paidServices: response,
    }),
    [servicesActionTypes.GET_PAID_SERVICE_HISTORY_ACTION]: (state) => ({
        ...state,
        fetchingServiceHistory: true,
        paidServiceHistory: [],
    }),
    [servicesActionTypes.GET_PAID_SERVICE_HISTORY_ACTION_SUCCESS]: (state, {response, payload: {nnLs}}) => ({
        ...state,
        fetchingServiceHistory: false,
        paidServiceHistory: nnLs ? response.map((serviceItem) => ({...serviceItem, nnLs})) : response,
    }),
    [servicesActionTypes.GET_PAID_SERVICE_HISTORY_ACTION_FAILURE]: (state) => ({
        ...state,
        fetchingServiceHistory: false,
    }),
    [servicesActionTypes.CRM_GET_PAID_SERVICE_HISTORY_ACTION]: (state) => ({
        ...state,
        fetchingServiceHistory: true,
        paidServiceHistory: [],
    }),
    [servicesActionTypes.CRM_GET_PAID_SERVICE_HISTORY_ACTION_SUCCESS]: (state, {response: {vlPaidServiceList}}) => ({
        ...state,
        fetchingServiceHistory: false,
        paidServiceHistory: sortBy(
            transformVlPaidServiceListByData(vlPaidServiceList),
            ([service]) => service.dtRequest,
        ).reverse(),
    }),
    [servicesActionTypes.CRM_GET_PAID_SERVICE_HISTORY_ACTION_FAILURE]: (state) => ({
        ...state,
        fetchingServiceHistory: false,
    }),
    [servicesActionTypes.GET_PROVIDER_LIST_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        configs: {
            ...state.configs,
            providerFilter: {
                ...state.configs.providerFilter,
                vlDict: response.map((provider) => ({
                    nmValue: provider.nmProvider,
                    nnCode: String(provider.kdProvider),
                })),
            },
        },
    }),
    [servicesActionTypes.GET_REGION_LIST_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        configs: {
            ...state.configs,
            regionFilter: {
                ...state.configs.regionFilter,
                vlDict: response.map((region) => ({nmValue: region.nmRegion, nnCode: region.kdRegion})),
            },
        },
    }),
    [servicesActionTypes.GET_PAID_SERVICE_GROUPS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        configs: {
            ...state.configs,
            serviceFilter: {
                ...state.configs.serviceFilter,
                vlDict: [
                    ...response.map((service) => ({
                        nmValue: service.nmPaidServiceGroup,
                        nnCode: service.kdPaidServiceGroup,
                    })),
                    {
                        nmValue: "Все",
                        nnCode: "null",
                    },
                ],
            },
        },
    }),
    [servicesActionTypes.GET_PAID_SERVICE_CATEGORY_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        configs: {
            ...state.configs,
            serviceFilter: {
                ...state.configs.serviceFilter,
                vlDict: [
                    ...response.map((service) => ({
                        idCategory: service.idCategory,
                        nmValue: service.nmCategory,
                        nnCode: service.idCategory ? service.idCategory : "null",
                    })),
                ],
            },
        },
    }),
    [servicesActionTypes.GET_LS_LIST_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        configs: {
            ...state.configs,
            lsFilter: {
                ...state.configs.lsFilter,
                vlDict: response.map((service) => ({
                    kdProvider: String(service.kdProvider),
                    nmValue: service.nnLs,
                    nnCode: String(service.idService),
                })),
            },
        },
    }),
    [servicesActionTypes.GET_PAID_SERVICE_PARAMS_ACTION]: (state) => ({
        ...state,
        fetchingPaidServiceParams: true,
    }),
    [servicesActionTypes.GET_PAID_SERVICE_PARAMS_ACTION_SUCCESS]: (state, {response, payload}) => {
        const configs = buildOrderFormConfig(payload.paymentTypes, payload.ls);

        return {
            ...state,
            fetchingPaidServiceParams: true,
            initial: response,
            orderConfigs: configs,
        };
    },
    [servicesActionTypes.GET_PAID_SERVICE_PARAMS_ACTION_FAILURE]: (state) => ({
        ...state,
        fetchingPaidServiceParams: false,
    }),
    [crmActionTypes.CRM_GET_PAID_SERVICE_ATTR_ACTION]: (state) => ({
        ...state,
        fetchingCrmPaidServiceAttr: true,
    }),
    [crmActionTypes.CRM_GET_PAID_SERVICE_ATTR_ACTION_SUCCESS]: (
        state,
        {response: {attributes, idCrmProvider, idCrmService, documents}, payload: {initialValues: formVal}},
    ) => ({
        ...state,
        documents,
        fetchingCrmPaidServiceAttr: false,
        idCrmProvider,
        idCrmService,
        initialValues: getInitialValues(attributes, formVal),
        orderConfigs: getConfigs(attributes),
    }),
    [crmActionTypes.CRM_GET_PAID_SERVICE_ATTR_ACTION_FAILURE]: (state) => ({
        ...state,
        fetchingCrmPaidServiceAttr: false,
    }),
    [servicesActionTypes.GET_PAID_SERVICE_LS_LIST_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        serviceLsList: response,
    }),
    [servicesActionTypes.GET_PAID_SERVICE_ATTRIBUTES_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        serviceAttributes: response.attributes,
    }),
    [servicesActionTypes.REG_EMAIL_PAID_SERVICE_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        emailPaidService: response,
    }),
    [servicesActionTypes.OPEN_ORDER_SERVICE_FORM]: (state) => ({
        ...state,
        orderServiceFormIsOpen: !state.orderServiceFormIsOpen,
    }),
    [servicesActionTypes.CLOSE_ORDER_SERVICE_FORM]: (state) => ({
        ...state,
        orderServiceFormIsOpen: false,
    }),
    [crmActionTypes.CRM_GET_IMG_URL_ACTION]: (state) => ({
        ...state,
        img: {
            fetchingImg: true,
        },
    }),
    [crmActionTypes.CRM_GET_IMG_URL_ACTION_SUCCESS]: (state, {response: {nmLinkPattern}}) => ({
        ...state,
        img: {
            fetchingImg: false,
            imgLink: nmLinkPattern,
        },
    }),
    [crmActionTypes.CRM_GET_PAID_SERVICE_REKV_ACTION]: (state) => ({
        ...state,
        catalogPayConfirmModal: {
            open: false,
            serviceRekvFetching: true,
        },
    }),
    [crmActionTypes.CRM_GET_PAID_SERVICE_REKV_ACTION_SUCCESS]: (state, {response: {vlData}}) => ({
        ...state,
        catalogPayConfirmModal: {
            data: vlData,
            open: true,
            serviceRekvFetching: false,
        },
    }),
    [servicesActionTypes.CRM_GET_PROMOS_ACTION]: (state) => ({
        ...state,
        servicesPromo: {
            ...state.servicesPromo,
            fetching: true,
        },
    }),
    [servicesActionTypes.CRM_GET_PROMOS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        servicesPromo: {
            fetching: false,
            promoList: response,
        },
    }),
    [servicesActionTypes.CRM_GET_PROMOS_ACTION_FAILURE]: (state) => ({
        ...state,
        servicesPromo: {
            ...state.servicesPromo,
            fetching: false,
        },
    }),
    [servicesActionTypes.CRM_PRE_CALC_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        orderPreCalcData: response,
    }),
});
