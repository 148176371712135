// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {DEFAULT_SIZE} from "../../../constants/iconConstants";
import {type IconPropsType} from "./iconType";

export const NotificationCriticalReadIcon = ({fontSize, ...otherProps}: IconPropsType) => (
    <SvgIcon {...otherProps} style={{fontSize}}>
        <g>
            <path
                fill="#FF4141"
                d={
                    "M560.9,437.5c-0.6,36.8-1.2,75.6-1.9,114.5c-0.2,10.2-0.4,20.5-0.6,30.7c0,0.8-0.1,1.6-" +
                    "0.1,2.4c-0.1,0.7-0.5,1.2-1.2,1.2c-1.1,0.1-2.2,0.1-3.3,0.1c-25.3,0-50.5,0-75.8,0c-5,0-4" +
                    ".7,0.2-4.8-4.7c-0.5-30.2-1-60.4-1.5-90.6c-0.6-37.4-1.3-74.8-2-112.2c-0.5-29.4-1.1-58.8-" +
                    "1.6-88.2c0-0.5,0-1,0-1.4c0-5.9-0.6-5.6,5.3-5.6c28.5,0,56.9,0,85.4,0c5.2,0,4.8-0.2,4.8,5" +
                    ".1c-0.6,32.9-1,65.9-1.6,98.8C561.7,403.5,561.3,419.5,560.9,437.5z"
                }
            />
            <path
                fill="#FF4141"
                d={
                    "M569.2,694.5c0.4,29.3-24.2,53.4-53.3,53.4c-28,0.1-53.7-22.8-53.5-53.8c0.1-30,24." +
                    "9-53.3,53.6-53.2C545.6,641.1,569.7,665.6,569.2,694.5z"
                }
            />
        </g>
        <circle
            fill="none"
            stroke="#FF4141"
            strokeWidth="59.5276"
            strokeMiterlimit="10"
            cx="515.8"
            cy="515.8"
            r="486"
        />
    </SvgIcon>
);

NotificationCriticalReadIcon.defaultProps = {
    fontSize: DEFAULT_SIZE,
    viewBox: "0 0 1035 1035",
};

export default NotificationCriticalReadIcon;
