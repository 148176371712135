// @flow
// eslint-disable-next-line import/named
import {type MiddlewareAPI} from "redux";
import get from "lodash/get";
import moment from "moment";
import {type StoreType} from "../redux/reducers";
import {getDtCacheAction} from "../redux/actions/cacheQueryAction";

type CacheQuerySetDataType = {
    kdSection: string,
    store: MiddlewareAPI<StoreType, any>,
    queryName: string,
    session?: string,
    responseData: any,
    customCaching?: boolean,
    data?: Object,
};

type CacheQueryGetDataType = {
    kdSection: string,
    store: MiddlewareAPI<StoreType, any>,
    queryName: string,
    session?: string,
    customCaching?: boolean,
    data?: Object,
    payload?: Object,
};

const getDataFromCustomCache = ({store, requestData}: Object) => {
    const data = JSON.parse(window.localStorage.getItem(`${requestData.proxyquery}${requestData.vlProvider}`));
    const cacheTime = get(store.getState(), "config.settings.balanceCacheTime", 0);

    if (data) {
        if (moment().valueOf() - data.date > cacheTime) {
            return null;
        }

        return data.response;
    }

    return "";
};

export const cacheQueryGetData = async ({
    kdSection = "",
    store,
    queryName,
    payload,
    session,
    customCaching,
    data: requestData,
}: CacheQueryGetDataType) => {
    if (customCaching && requestData) {
        return getDataFromCustomCache({requestData, store});
    }

    let data = JSON.parse(window.localStorage.getItem(`${queryName}${kdSection}`));
    const res: Object = await store.dispatch(
        getDtCacheAction(queryName, session, kdSection, {
            isDisabledAuthorisationError: payload?.isDisabledAuthorisationError,
        }),
    );
    const checkValues = !res.error && data;
    const checkDateSection = data
        ? data.lastModification === res.response.dtChange && data.kdSection === kdSection
        : false;

    if (checkValues && checkDateSection) {
        data = data.response;
    } else {
        data = "";
    }

    return data;
};

const getCustomCachingParams = (store: MiddlewareAPI<StoreType, any>, {data, responseData}: Object) => {
    const cacheTime = get(store.getState(), "config.settings.balanceCacheTime", 0);

    if (cacheTime) {
        return {
            key: `${data.proxyquery}${data.vlProvider}`,
            value: {
                date: moment().valueOf(),
                response: responseData,
            },
        };
    }

    return {
        key: "",
        value: {},
    };
};

const getCachingParams = async (
    store: MiddlewareAPI<StoreType, any>,
    {queryName, session, kdSection, responseData}: Object,
) => {
    const res: Object = await store.dispatch(getDtCacheAction(queryName, session, kdSection));

    return {
        key: `${queryName}${kdSection}`,
        value: {
            kdSection,
            lastModification: res.response.dtChange,
            response: responseData,
        },
    };
};

export const cacheQuerySetData = async ({
    kdSection,
    queryName,
    responseData,
    store,
    session,
    customCaching,
    data,
}: CacheQuerySetDataType) => {
    const {value, key} =
        customCaching && data
            ? getCustomCachingParams(store, {data, responseData})
            : await getCachingParams(store, {kdSection, queryName, responseData, session});

    if (key) {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
};
