// @flow

export const CRM_REG_POWER_SUPPLY_CONTRACT_ACTION = "CRM_REG_POWER_SUPPLY_CONTRACT_ACTION";
export const CRM_REG_POWER_SUPPLY_CONTRACT_ACTION_SUCCESS = "CRM_REG_POWER_SUPPLY_CONTRACT_ACTION_SUCCESS";
export const CRM_REG_POWER_SUPPLY_CONTRACT_ACTION_FAILURE = "CRM_REG_POWER_SUPPLY_CONTRACT_ACTION_FAILURE";

export const GET_POW_SUP_PROVIDERS_ACTION = "GET_POW_SUP_PROVIDERS_ACTION";
export const GET_POW_SUP_PROVIDERS_ACTION_SUCCESS = "GET_POW_SUP_PROVIDERS_ACTION_SUCCESS";
export const GET_POW_SUP_PROVIDERS_ACTION_FAILURE = "GET_POW_SUP_PROVIDERS_ACTION_FAILURE";

export const CRM_GET_POWER_SUPPLY_CONTRACT_HIST_ACTION = "CRM_GET_POWER_SUPPLY_CONTRACT_HIST_ACTION";
export const CRM_GET_POWER_SUPPLY_CONTRACT_HIST_ACTION_SUCCESS = "CRM_GET_POWER_SUPPLY_CONTRACT_HIST_ACTION_SUCCESS";
export const CRM_GET_POWER_SUPPLY_CONTRACT_HIST_ACTION_FAILURE = "CRM_REG_POWER_SUPPLY_CONTRACT_ACTION_FAILURE";

export const CRM_REJECT_SERVICEE_ACTION = "CRM_REJECT_SERVICEE_ACTION";
export const CRM_REJECT_SERVICEE_ACTION_SUCCESS = "CRM_REJECT_SERVICEE_ACTION_SUCCESS";
export const CRM_REJECT_SERVICEE_ACTION_FAILURE = "CRM_REJECT_SERVICEE_ACTION_FAILURE";

export const CRM_SET_MSG_READED_ACTION = "CRM_SET_MSG_READED_ACTION";
export const CRM_SET_MSG_READED_ACTION_SUCCESS = "CRM_SET_MSG_READED_ACTION_SUCCESS";
export const CRM_SET_MSG_READED_ACTION_FAILURE = "CRM_SET_MSG_READED_ACTION_FAILURE";

export const POWER_SUPPLY_INFO_VISIBILITY_ACTION = "POWER_SUPPLY_INFO_VISIBILITY_ACTION";
export const POWER_SUPPLY_INFO_VISIBILITY_ACTION_SUCCESS = "POWER_SUPPLY_INFO_VISIBILITY_ACTION_SUCCESS";
export const POWER_SUPPLY_INFO_VISIBILITY_ACTION_FAILURE = "POWER_SUPPLY_INFO_VISIBILITY_ACTION_FAILURE";

export const POWER_SUPPLY_PROVIDERS_ACTION = "POWER_SUPPLY_PROVIDERS_ACTION";
export const POWER_SUPPLY_PROVIDERS_ACTION_SUCCESS = "POWER_SUPPLY_PROVIDERS_ACTION_SUCCESS";
export const POWER_SUPPLY_PROVIDERS_ACTION_FAILURE = "POWER_SUPPLY_PROVIDERS_ACTION_FAILURE";

export const CRM_REG_POWER_SUPPLY_TEMP_LS_ACTION = "CRM_REG_POWER_SUPPLY_TEMP_LS_ACTION";
export const CRM_REG_POWER_SUPPLY_TEMP_LS_ACTION_SUCCESS = "CRM_REG_POWER_SUPPLY_TEMP_LS_ACTION_SUCCESS";
export const CRM_REG_POWER_SUPPLY_TEMP_LS_ACTION_FAILURE = "CRM_REG_POWER_SUPPLY_TEMP_LS_ACTIONN_FAILURE";

export const CRM_ACCEPT_DOC_ACTION = "CRM_ACCEPT_DOC_ACTION";
export const CRM_ACCEPT_DOC_ACTION_SUCCESS = "CRM_ACCEPT_DOC_ACTION_SUCCESS";
export const CRM_ACCEPT_DOC_ACTION_FAILURE = "CRM_ACCEPT_DOC_ACTION_FAILURE";

export const LS_LIST_EDITABLE_ACTION = "LS_LIST_EDITABLE_ACTION";
export const LS_LIST_EDITABLE_ACTION_SUCCESS = "LS_LIST_EDITABLE_ACTION_SUCCESS";
export const LS_LIST_EDITABLE_ACTION_FAILURE = "LS_LIST_EDITABLE_ACTION_FAILURE";
