const initialState = {
    modalIsOpen: false,
};

export const banerReducer = (state = initialState, action = "") => {
    switch (action.type) {
        case "UPDATE_BANNER":
            return action.dt;
        default:
            return state;
    }
};
