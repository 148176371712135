// @flow
import React from "react";
import banerIcon from "../../../images/providers/vld/logo/VldLogo.png";
import {SMALL_ICON_SIZE} from "../../../constants/iconConstants";

type Props = {
    fontSize?: number,
};

const VldLogoIcon = ({fontSize}: Props) => <img height={fontSize} width={fontSize} src={banerIcon} alt="" />;

VldLogoIcon.defaultProps = {
    fontSize: SMALL_ICON_SIZE,
};

export default VldLogoIcon;
