// @flow
import * as React from "react";
import {v4 as uuidv4} from "uuid";
import Grid from "@material-ui/core/Grid";
import {type BrowserHistory} from "history/createBrowserHistory";
import Typography from "../../packages/components/Typography/Typography";
import Content from "../../packages/components/Content/Content";
import Panel from "../../packages/components/Panel/Panel";
import PanelHead from "../../packages/components/Panel/PanelHead";
import PanelBody from "../../packages/components/Panel/PanelBody";
import NotificationIcon from "../../packages/components/Icons/NotificationIcon";
import {reachGoal} from "../../utils/metricUtils";
import {
    SET_LS_SUBSCR_EMAIL,
    FILL_PROFILE_REQUISITES,
    SET_LS_SUBSCR_BY_EVENT,
    SET_LS_SUBSCR_BY_EVENT_KVITONOMIC,
} from "../../constants/Notification";
import {type ApiMiddlewareResponsePromiseType} from "../../redux/middlewares/apiMiddleware";
import Button from "../../packages/components/Button/Button";
import NotificationButton from "./NotificationButton";
import {type NotificationForDayPropsType} from "./NotificationForDay";

// eslint-d
type PropsType = NotificationForDayPropsType & {
    auth: Object,
    noticeItem: Object,
    currentReadNotice?: number,
    firstNotice?: boolean,
    getLSNoticeCount?: (idService: string, session: string) => ApiMiddlewareResponsePromiseType,
    getLSNoticeCriticalStatus?: (idService: string, session: string) => ApiMiddlewareResponsePromiseType,
    history?: BrowserHistory,
    idService?: string,
    match?: {
        url: string,
        params: {
            accountId: string,
        },
    },
    nnLs?: string,
    notificationDayItem?: string,
    session?: string,
    setActionDate?: (idNotice: string, kdEventAction: string, session?: string) => ApiMiddlewareResponsePromiseType,
    setLsSubscrEmail?: (idService: string, session?: string) => ApiMiddlewareResponsePromiseType,
    userSetLsSubscrbyEventAction: (idService: string, session: string) => ApiMiddlewareResponsePromiseType,
    setNoticeReaded?: (idNotice: string, session?: string) => ApiMiddlewareResponsePromiseType,
    openExtraRegistrationFormAction: () => void,
};

/* eslint-disable quote-props */
export const redirectActionConfig = {
    "1": (accountId: string) => ({
        reachGoal: "m_notifications_pay",
        url: `/accounts/${accountId}/pay`,
    }),
    "10": (accountId: string) => ({
        url: `/profile?idService=${accountId}&mode=subscr_ls&isEmail=true&buttonId=${uuidv4()}`,
    }),
    "12": () => ({
        url: "/profile",
    }),
    "13": (accountId: string) => ({
        url: `/profile?idService=${accountId}&mode=subscr_ls&isEmail=true&buttonId=${uuidv4()}`,
    }),
    "14": (accountId: string) => ({
        url: `/profile?idService=${accountId}&mode=subscr_ls&isEmail=true&buttonId=${uuidv4()}`,
    }),
    "2": (accountId: string) => ({
        reachGoal: "m_notifications_pay",
        url: `/accounts/${accountId}/pay`,
    }),
    "3": (accountId: string, noticeItem: Object) => ({
        reachGoal: "m_notifications_order",
        url: `/services/catalog?kdProvider=${noticeItem.kdProvider}&ls=${accountId}`,
    }),
    "4": (accountId: string) => ({
        url: `/accounts/${accountId}/application`,
    }),
    "5": (accountId: string) => ({
        url: `/accounts/${accountId}/transfer-indications`,
    }),
    "7": (accountId: string, noticeItem: Object, {vlAddParam}: Object) => ({
        // eslint-disable-next-line max-len
        url: `/services/catalog?kdProvider=${noticeItem.kdProvider}&ls=${accountId}&hideFilters=true&serviceId=${vlAddParam?.priceId}`,
    }),
    "8": (accountId: string, noticeItem: Object, {vlAddParam}: Object) => ({
        // eslint-disable-next-line max-len
        url: `/services/catalog?kdProvider=${noticeItem.kdProvider}&ls=${accountId}&hideFilters=true&promoId=${vlAddParam?.promoId}`,
    }),
};
/* eslint-enable quote-props */

const boldFontWeight = 500;

export class NotificationForDayPanelBody extends React.PureComponent<PropsType> {
    bannerAction = async (idNotice: string, kdEventAction: string, vlAddParam: ?string) => {
        const {
            auth,
            session,
            idService,
            match: {
                params: {accountId},
            },
            history,
            noticeItem,
        } = this.props;

        if (kdEventAction === SET_LS_SUBSCR_EMAIL && !auth.prMirror) {
            this.props.setLsSubscrEmail(idService, session).then(() => {
                this.props.setNoticeReaded(noticeItem.idNotice, session).then(() => {
                    this.props.getLSNoticeCriticalStatus(idService, session);
                    this.props.getLSNoticeCount(idService, session);
                });
            });

            return;
        } else if (kdEventAction === FILL_PROFILE_REQUISITES) {
            await this.props.openExtraRegistrationFormAction();

            return;
        } else if (kdEventAction === SET_LS_SUBSCR_BY_EVENT || kdEventAction === SET_LS_SUBSCR_BY_EVENT_KVITONOMIC) {
            await this.props.userSetLsSubscrbyEventAction(idService, session);
        }
        const redirectAction = redirectActionConfig[kdEventAction](accountId, noticeItem, {vlAddParam});

        this.props.setActionDate(idNotice, kdEventAction, session).then(() => {
            if (noticeItem.prExpand && !auth.prMirror) {
                this.props.setNoticeReaded(noticeItem.idNotice, session).then(() => {
                    this.props.getLSNoticeCriticalStatus(idService, session);
                    this.props.getLSNoticeCount(idService, session);
                });
            }
        });

        if (redirectAction.reachGoal && !auth.prMirror) {
            reachGoal(redirectAction.reachGoal);
        }
        history.push(redirectAction.url);
    };

    attachRedirectAction = (event: SyntheticEvent<>, url: string) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(url, "_blank");
    };

    renderButtons = ({actions, nnPriority, idNotice, nmAttachLink, vlAddParam}: Object) => (
        <Grid container spacing={16} direction="row">
            {actions
                ? actions.map((btnItem, btnIndex) => (
                      <NotificationButton
                          auth={this.props.auth}
                          nnPriority={nnPriority}
                          idNotice={idNotice}
                          btnItem={btnItem}
                          key={btnIndex}
                          bannerAction={this.bannerAction}
                          vlAddParam={vlAddParam}
                      />
                  ))
                : null}
            {nmAttachLink ? (
                <Grid item>
                    <Button
                        color="transparent"
                        size="sm"
                        border
                        textColor={nnPriority === 1 ? "error" : "primary-light"}
                        onClick={(event: SyntheticEvent<>) => this.attachRedirectAction(event, nmAttachLink)}
                    >
                        Перейти
                    </Button>
                </Grid>
            ) : null}
        </Grid>
    );

    handleOpenNotice = () => {
        const {
            auth,
            noticeItem: {idNotice},
            session,
            idService,
        } = this.props;

        if (!auth.prMirror) {
            this.props.setNoticeReaded(idNotice, session).then(() => {
                this.props.getLSNoticeCriticalStatus(idService, session);
                this.props.getLSNoticeCount(idService, session);
            });
        }
    };

    componentDidUpdate(prevProps: PropsType) {
        const {currentReadNotice, noticeItem} = prevProps;

        if (currentReadNotice !== this.props.currentReadNotice && noticeItem.idNotice === currentReadNotice) {
            this.handleOpenNotice();
        }
    }

    componentDidMount() {
        const {auth, noticeItem, currentReadNotice, setNoticeReaded, session} = this.props;
        const {idNotice, prExpand, prReaded} = noticeItem;

        if (prExpand && !prReaded && !auth.prMirror) {
            setNoticeReaded(idNotice, session);
        }

        if (idNotice === currentReadNotice) {
            this.handleOpenNotice();
        }
    }

    render() {
        const {noticeItem, currentReadNotice} = this.props;
        const [action = {}] = noticeItem.actions || [];

        return (
            <Panel
                onOpen={this.handleOpenNotice}
                open={noticeItem.idNotice === currentReadNotice || noticeItem.prExpand}
            >
                <PanelHead>
                    <Content vertical={10} paddingRight={16}>
                        <Grid container alignItems="center" justify="space-between" spacing={16}>
                            <Grid item justify="space-between">
                                <Grid container alignItems="center" wrap="nowrap">
                                    <Grid item>
                                        <NotificationIcon
                                            error={noticeItem.nnPriority === 1}
                                            read={noticeItem.prReaded}
                                            kvitonomic={action.kdEventAction === SET_LS_SUBSCR_BY_EVENT_KVITONOMIC}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            horizontalPadding={16}
                                            fontSize={16}
                                            fontWeight={noticeItem.prReaded ? undefined : boldFontWeight}
                                            textColor={noticeItem.nnPriority === 1 ? "error" : "primary"}
                                        >
                                            {noticeItem.nmHeader}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {noticeItem.actions ? <Grid item>{this.renderButtons(noticeItem)}</Grid> : null}
                        </Grid>
                    </Content>
                </PanelHead>
                <PanelBody>
                    <Typography verticalPadding={8} dangerouslySetInnerHTML={{__html: noticeItem.nmText}} />
                </PanelBody>
            </Panel>
        );
    }
}

export default NotificationForDayPanelBody;
