// @flow
import React from "react";
import orlLogo from "../../../images/providers/orl/logo/OrlLogo.svg";
import {SMALL_ICON_SIZE} from "../../../constants/iconConstants";

type Props = {
    fontSize?: number,
};

const OrlLogoIcon = ({fontSize}: Props) => <img height={fontSize} width={fontSize} src={orlLogo} alt="" />;

OrlLogoIcon.defaultProps = {
    fontSize: SMALL_ICON_SIZE,
};

export default OrlLogoIcon;
