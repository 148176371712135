// @flow

export const themeIEOverridesStyle = {
    MuiDialog: {
        paper: {
            overflow: "hidden",
        },
    },
    MuiDialogActions: {
        root: {
            justifyContent: "flex-start",
        },
    },
    MuiDialogContent: {
        /* Отступ 230px взят из расчета отсутупа модалки от верха и низа станицы */
        root: {
            maxHeight: "calc(100vh - 230px)",
            overflowY: "auto",
        },
    },
    MuiDrawer: {
        paper: {
            display: "block",
        },
    },
    MuiFormControl: {
        root: {
            width: "100%",
        },
    },
    MuiGrid: {
        "grid-xs": {
            flexBasis: "auto",
        },
        "grid-xs-true": {
            flexBasis: "auto",
        },
        typeContainer: {
            width: "auto",
        },
    },
    MuiIconButton: {
        root: {
            height: 40,
            width: 40,
        },
    },
    MuiInputAdornment: {
        root: {
            maxHeight: 40,
        },
    },
    MuiSvgIcon: {
        root: {
            height: "1em",
            width: "1em",
        },
    },
};
