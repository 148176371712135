// @flow

import React from "react";
import Iframe from "react-iframe";
import {useSelector} from "react-redux";
import type {BrowserHistory} from "history/createBrowserHistory";
import type {Location} from "react-router-dom";
import type {StoreType} from "../../redux/reducers";
import {PAY_ADDITIONAL_SERVICES_KD_SECTION} from "../../constants/kdSections";
import {isSectionAvail} from "../../utils/redirectUtils";

export type Props = {
    history: BrowserHistory,
    location: Location,
};
const HEADER_HEIGHT = 65;

export const PayAdditionalServicesPage = (props: Props) => {
    const {auth, vbrrFrameUrl} = useSelector((store: StoreType) => ({
        auth: store.auth,
        vbrrFrameUrl: store.config.settings.vbrrFrameAddServices,
    }));
    const currentSections = [PAY_ADDITIONAL_SERVICES_KD_SECTION];

    const iFrameHeight = `${window.innerHeight - HEADER_HEIGHT} px`;

    React.useEffect(() => {
        isSectionAvail(props.history, currentSections, auth.idProfile);
    }, []);

    // eslint-disable-next-line no-negated-condition
    return !auth.prMirror ? <Iframe url={vbrrFrameUrl} width="100%" height={iFrameHeight} frameBorder={0} /> : null;
};

export default PayAdditionalServicesPage;
