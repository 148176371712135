// For IE 11 support
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import debug from "debug";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import {backgroundChunkLoads} from "./utils/appUtils";
import "./loadSmartbanner";

// eslint-disable-next-line no-unused-vars, camelcase
const __webpack_nonce__ = "ssl_session_id";

debug.enable(process.env.REACT_APP_DEBUG);

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
backgroundChunkLoads();
