// @flow
import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import qs from "qs";
import Grid from "@material-ui/core/Grid/Grid";
import {withStyles} from "@material-ui/core/styles";
import Button from "../../../components/Button/Button";
import Typography from "../../../components/Typography/Typography";
import {openInfoModalAction} from "../../../../redux/actions/notificationAction";
import type {themeTypes} from "../../../../themeStyle";
import styles from "./styles";

type OwnProps = {|
    classes: {
        [$Keys<$Call<typeof styles, themeTypes>>]: string,
    },
    CustomComponent?: () => React.Node,
    content: Array<{
        vlContent: {
            nmLink: string,
            vlContent: string,
        },
    }>,
    direction: "row" | "column",
    disabled?: boolean,
    fontSize?: 18 | 16 | 22,
    list?: boolean,
    spacing: 8 | 16,
    testID?: string,
    textColor: "primary-light" | "white",
    underline?: "solid" | "dashed" | "native" | "none",
    zeroMinHeight: boolean,
    zeroPadding?: boolean,
|};

type DispatchProps = {|
    openInfoModalAction: (modalname: string) => void,
|};

export type Props = {...OwnProps, ...DispatchProps};

const DEFAULT_PADDING = 10;
const DEFAULT_SPACING = 8;

export class ReferenceElement extends React.Component<Props> {
    handleLink = (nmLink: string) => (event: SyntheticEvent<>) => {
        event.preventDefault();
        if (nmLink) {
            const {mode, modalname} = qs.parse(nmLink.split("?")[1]);

            if (mode === "modal") {
                this.props.openInfoModalAction(modalname);
            } else {
                window.open(nmLink);
            }
        }
    };

    render() {
        const {
            classes,
            fontSize,
            testID,
            list,
            zeroPadding,
            zeroMinHeight,
            underline = "dashed",
            spacing = DEFAULT_SPACING,
            direction = "row",
            CustomComponent,
            textColor = "primary-light",
            disabled,
        } = this.props;
        const [...content] = this.props.content;

        return (
            <Grid container spacing={spacing} direction={direction}>
                {content.reverse().map((reference: Object, index: number) => (
                    <Grid item key={index}>
                        <Button
                            classes={{disabled: classes.fixDisabledButtonBackground}}
                            link
                            list={list}
                            capitalize
                            underline={CustomComponent ? undefined : underline}
                            onClick={this.handleLink(reference.nmLink)}
                            testID={testID}
                            zeroPadding={zeroPadding}
                            zeroMinHeight={zeroMinHeight}
                            disabled={disabled}
                        >
                            {CustomComponent ? <CustomComponent /> : null}
                            <Typography
                                underline={Boolean(CustomComponent)}
                                padding={CustomComponent ? DEFAULT_PADDING : undefined}
                                dangerouslySetInnerHTML={{__html: reference.vlContent}}
                                fontSize={fontSize}
                                textColor={textColor}
                            />
                        </Button>
                    </Grid>
                ))}
            </Grid>
        );
    }
}

export default connect<Props, OwnProps, _, DispatchProps, _, _>(
    () => ({}),
    (dispatch): DispatchProps => ({
        openInfoModalAction: bindActionCreators(openInfoModalAction, dispatch),
    }),
)(withStyles(styles)(ReferenceElement));
