export const GET_EMAIL_REQUEST_LS_LIST_ACTION = "GET_EMAIL_REQUEST_LS_LIST_ACTION";
export const GET_EMAIL_REQUEST_LS_LIST_ACTION_SUCCESS = "GET_EMAIL_REQUEST_LS_LIST_ACTION_SUCCESS";
export const GET_EMAIL_REQUEST_LS_LIST_ACTION_FAILURE = "GET_EMAIL_REQUEST_LS_LIST_ACTION_FAILURE";

export const GET_EMAIL_REQUEST_ATTRIBUTES_ACTION = "GET_EMAIL_REQUEST_ATTRIBUTES_ACTION";
export const GET_EMAIL_REQUEST_ATTRIBUTES_ACTION_SUCCESS = "GET_EMAIL_REQUEST_ATTRIBUTES_ACTION_SUCCESS";
export const GET_EMAIL_REQUEST_ATTRIBUTES_ACTION_FAILURE = "GET_EMAIL_REQUEST_ATTRIBUTES_ACTION_FAILURE";

export const REG_EMAIL_REQUEST_ACTION = "REG_EMAIL_REQUEST_ACTION";
export const REG_EMAIL_REQUEST_ACTION_SUCCESS = "REG_EMAIL_REQUEST_ACTION_SUCCESS";
export const REG_EMAIL_REQUEST_ACTION_FAILURE = "REG_EMAIL_REQUEST_ACTION_FAILURE";
export const CRM_GET_SERVICE_KD_SYSTEM_ACTION = "CRM_GET_SERVICE_KD_SYSTEM_ACTION";
export const CRM_GET_SERVICE_KD_SYSTEM_ACTION_SUCCESS = "CRM_GET_SERVICE_KD_SYSTEM_ACTION_SUCCESS";
export const CRM_GET_SERVICE_KD_SYSTEM_ACTION_FAILURE = "CRM_GET_SERVICE_KD_SYSTEM_ACTION_FAILURE";
