// @flow
import React from "react";
import orlLogo from "../../../images/providers/orl/logo/OrlEpdLogo.svg";
import {SMALL_ICON_SIZE} from "../../../constants/iconConstants";

type Props = {
    fontSize?: number,
};

const OrlEpdLogoIcon = ({fontSize}: Props) => <img height={fontSize} width={fontSize} src={orlLogo} alt="" />;

OrlEpdLogoIcon.defaultProps = {
    fontSize: SMALL_ICON_SIZE,
};

export default OrlEpdLogoIcon;
