// @flow
import React from "react";

export const convertToTemplateString = (string: string = "", data: Object = {}) => {
    let convertedString = string;

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            convertedString = convertedString.replace(`$\{${key}}`, data[key]);
        }
    }

    return convertedString;
};

export const renderHTMLString = (htmlString: string) =>
    htmlString ? <span dangerouslySetInnerHTML={{__html: htmlString}} /> : null;

export const replaceAll = (input: string, find: string, replace: string): string =>
    input.replace(new RegExp(find, "g"), replace);

export const isValidJSON = (str: string): boolean => {
    try {
        JSON.parse(str);

        return true;
    } catch (error) {
        return false;
    }
};
