// @flow

import React from "react";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "../Button/Button";
import Content from "../Content/Content";
import Typography from "../Typography/Typography";

type Props = {
    message: string,
    deleteLsHandler: (idService: string) => Promise<void>,
    idService: string,
};

export const UnsupportedLs = ({message, idService, deleteLsHandler}: Props) => {
    const OnDeleteLsClick = () => {
        deleteLsHandler(idService);
    };

    return (
        <Content horizontal={24} vertical="sm">
            <Grid container spacing={16} direction="column">
                <Grid item>
                    <Typography fontSize={22}>ЛС ликвидирован</Typography>
                </Grid>
                <Grid item>
                    <Typography dangerouslySetInnerHTML={{__html: message}} />
                </Grid>
                <Grid item>
                    <Button color="secondary" size="sm" onClick={OnDeleteLsClick} component={Link} to="">
                        Удалить
                    </Button>
                </Grid>
            </Grid>
        </Content>
    );
};

export default UnsupportedLs;
