/* eslint-disable max-lines */
// @flow
import * as servicesActionTypes from "../../constants/types/ServicesActionTypes";
import {
    type ApiMiddlewareActionType,
    type ApiMiddlewareResponsePromiseType,
    type ApiMiddlewareResponseType,
} from "../middlewares/apiMiddleware";
import {PROMOCODE_INVALID, RESPONSE_RESULT_TEXT_CODE} from "../../constants/KdResults";

type PaidServiceParamsType = {
    idService?: string,
    kdProvider?: number,
    kdRegion?: number,
    kdPaidServiceGroup?: number,
    idMeterType?: number,
    idDepartment?: number,
    session: string,
};

type PaidServiceGroupsParamsType = {
    idService: number | any,
    session: string,
    paymentTypes: Array<Object> | any,
    ls?: string,
};

type RegPaidServiceParamsType = {
    idService: number,
    kdProvider: number,
    kdPaidService: number,
    kdTpPriceList: number,
    dtPriceList: string,
    nmPriceList: string,
    nmPriceListCode: string,
    prLs: boolean,
    vlPrice: number,
    nmAddress: string,
    kdRegion: number,
    nnLs: string,
    nmFirst: string,
    nmMiddle: string,
    nmLast: string,
    nnPhone: number,
    nmEmail: string,
    kdPaymentType: number,
    idCrmPaidService: number,
};

export type GtPaidServiceList20ActionParamsType = {
    session: string,
    idService?: string,
    idCategory: string,
    vlFilter?: string,
    vlPromocodeFilter?: string,
};

export type LSPaidParamsActionType = (vlProvider?: string, session: string) => ApiMiddlewareResponsePromiseType;
export type BuildFilterConfigActionType = (ls: Object, lsList: Array<Object>) => ApiMiddlewareResponsePromiseType;
export type GetPaidServiceListActionType = (params: PaidServiceParamsType) => ApiMiddlewareResponsePromiseType;
export type GetPaidServiceList20ActionType = (
    params: GtPaidServiceList20ActionParamsType,
) => ApiMiddlewareResponsePromiseType;
export type GetProviderListActionType = (session: string) => Promise<any>;
export type GetLsListActionType = (session: string, kdProvider: string) => Promise<any>;
export type GetRegionListActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetPaidServiceGroupsActionType = (session: string, kdProvider: string) => ApiMiddlewareResponsePromiseType;
export type GetPaidServiceCategoryActionType = (
    idService: string,
    lsNumber: string,
) => ApiMiddlewareResponsePromiseType;
export type GetPaidServiceParamsActionType = (params: PaidServiceGroupsParamsType) => ApiMiddlewareResponsePromiseType;
export type RegPaidServiceActionType = (
    params: RegPaidServiceParamsType,
    session: string,
) => ApiMiddlewareResponsePromiseType;
export type GetPaidServiceHistoryType = (params: Object, session: string) => ApiMiddlewareResponsePromiseType;
export type GetPaidServiceLSListActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetPaidServiceAttributeActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type RegEmailPaidServiceActionType = (
    session: string,
    lsNumber: number,
    values: string,
) => ApiMiddlewareResponsePromiseType;
export type CrmRegPaidServiceActionType = (session: string, data: Object) => ApiMiddlewareResponsePromiseType;
export type GetPrintRequestsType = (session: string, idContract: number) => ApiMiddlewareResponsePromiseType;
export type CrmGetPromosActionType = (
    session: string,
    idService: number,
    idCategory: number | null,
) => ApiMiddlewareResponsePromiseType;
export type CrmGetPromocodeFilterActionType = (session: string, vlPromocodeFilter: string) => Promise<any>;
export type CrmPromocodeAvailActionType = (session: string) => Promise<any>;
export type CrmPreCalcActionParamsType = {
    idPriceListRow: number | string,
    idService: string,
    session: string,
    promocode?: string,
};
export type CrmPreCalcActionType = (params: CrmPreCalcActionParamsType) => ApiMiddlewareResponseType;
export type CrmShowGpActionType = (session: string) => ApiMiddlewareResponsePromiseType;

const formattedParam = (value?: string | number) =>
    value === undefined || value === "null" || isNaN(value) ? undefined : Number(value);

export const lSPaidParamsAction = (vlProvider: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            plugin: "bytProxy",
            proxyquery: "LSPaidParams",
            vlProvider,
        },
        query: "bytProxy",
        session,
        types: [
            servicesActionTypes.LS_PAID_PARAMS_ACTION,
            servicesActionTypes.LS_PAID_PARAMS_ACTION_SUCCESS,
            servicesActionTypes.LS_PAID_PARAMS_ACTION_FAILURE,
        ],
    },
});

export const buildFilterConfigAction = (ls: Object, lsList: Array<Object>) => ({
    payload: {
        ls,
        lsList,
    },
    type: servicesActionTypes.BUILD_FILTER_CONFIG_ACTION,
});

export const getPaidServiceListAction = (params: PaidServiceParamsType): ApiMiddlewareActionType => {
    const {idService, kdProvider, kdRegion, kdPaidServiceGroup, idMeterType, idDepartment, session} = params;

    return {
        api: {
            data: {
                idDepartment,
                idMeterType,
                idService: formattedParam(idService),
                kdPaidServiceGroup: formattedParam(kdPaidServiceGroup),
                kdProvider: formattedParam(kdProvider),
                kdRegion: formattedParam(kdRegion),
            },
            list: true,
            query: "GetPaidServiceList",
            session,
            types: [
                servicesActionTypes.GET_PAID_SERVICE_LIST_ACTION,
                servicesActionTypes.GET_PAID_SERVICE_LIST_ACTION_SUCCESS,
                servicesActionTypes.GET_PAID_SERVICE_LIST_ACTION_FAILURE,
            ],
        },
    };
};

export const getPaidServiceList20Action = ({
    session,
    idService,
    idCategory,
    vlFilter,
    vlPromocodeFilter,
}: GtPaidServiceList20ActionParamsType): ApiMiddlewareActionType => ({
    api: {
        data: {
            idCategory,
            idService: formattedParam(idService),
            vlFilter,
            vlPromocodeFilter,
        },
        list: true,
        query: "GetPaidServiceList20",
        session,
        types: [
            servicesActionTypes.GET_PAID_SERVICE_LIST_ACTION,
            servicesActionTypes.GET_PAID_SERVICE_LIST_ACTION_SUCCESS,
            servicesActionTypes.GET_PAID_SERVICE_LIST_ACTION_FAILURE,
        ],
    },
});

export const crmGetPromocodeFilterAction = (session: string, promocode: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            promocode,
        },
        query: "CrmGetPromocodeFilter",
        session,
        types: [
            servicesActionTypes.CRM_GET_PROMOCODE_FILTER_ACTION,
            servicesActionTypes.CRM_GET_PROMOCODE_FILTER_ACTION_SUCCESS,
            servicesActionTypes.CRM_GET_PROMOCODE_FILTER_ACTION_FAILURE,
        ],
    },
    payload: {
        skipkdResults: [PROMOCODE_INVALID],
    },
});

export const crmPromocodeAvailAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        query: "CrmPromocodeAvail",
        session,
        types: [
            servicesActionTypes.CRM_PROMOCODE_AVAIL_ACTION,
            servicesActionTypes.CRM_PROMOCODE_AVAIL_ACTION_SUCCESS,
            servicesActionTypes.CRM_PROMOCODE_AVAIL_ACTION_FAILURE,
        ],
    },
});

export const getPaidServiceLSListAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        list: true,
        query: "GetPaidServiceLSList",
        session,
        types: [
            servicesActionTypes.GET_PAID_SERVICE_LS_LIST_ACTION,
            servicesActionTypes.GET_PAID_SERVICE_LS_LIST_ACTION_SUCCESS,
            servicesActionTypes.GET_PAID_SERVICE_LS_LIST_ACTION_FAILURE,
        ],
    },
});

export const getPaidServiceAttributesAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        query: "GetPaidServiceAttributes",
        session,
        types: [
            servicesActionTypes.GET_PAID_SERVICE_ATTRIBUTES_ACTION,
            servicesActionTypes.GET_PAID_SERVICE_ATTRIBUTES_ACTION_SUCCESS,
            servicesActionTypes.GET_PAID_SERVICE_ATTRIBUTES_ACTION_FAILURE,
        ],
    },
});

export const regEmailPaidServiceAction = (
    session: string,
    idService: number,
    values: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            attributes: values,
            idService,
        },
        query: "RegEmailPaidService",
        session,
        types: [
            servicesActionTypes.REG_EMAIL_PAID_SERVICE_ACTION,
            servicesActionTypes.REG_EMAIL_PAID_SERVICE_ACTION_SUCCESS,
            servicesActionTypes.REG_EMAIL_PAID_SERVICE_ACTION_FAILURE,
        ],
    },
});

export const getProviderListAction = (session: string) => ({
    api: {
        list: true,
        query: "GetProviderList",
        session,
        types: [
            servicesActionTypes.GET_PROVIDER_LIST_ACTION,
            servicesActionTypes.GET_PROVIDER_LIST_ACTION_SUCCESS,
            servicesActionTypes.GET_PROVIDER_LIST_ACTION_FAILURE,
        ],
    },
});

export const getLsListAction = (session: string, kdProvider: string) => ({
    api: {
        data: {
            kdProvider: formattedParam(kdProvider),
        },
        list: true,
        query: "GetLSList",
        session,
        types: [
            servicesActionTypes.GET_LS_LIST_ACTION,
            servicesActionTypes.GET_LS_LIST_ACTION_SUCCESS,
            servicesActionTypes.GET_LS_LIST_ACTION_FAILURE,
        ],
    },
});

export const getRegionListAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        list: true,
        query: "GetRegionList",
        session,
        types: [
            servicesActionTypes.GET_REGION_LIST_ACTION,
            servicesActionTypes.GET_REGION_LIST_ACTION_SUCCESS,
            servicesActionTypes.GET_REGION_LIST_ACTION_FAILURE,
        ],
    },
});

export const getPaidServiceGroupsAction = (session: string, kdProvider: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            kdProvider: formattedParam(kdProvider),
        },
        list: true,
        query: "GetPaidServiceGroups",
        session,
        types: [
            servicesActionTypes.GET_PAID_SERVICE_GROUPS_ACTION,
            servicesActionTypes.GET_PAID_SERVICE_GROUPS_ACTION_SUCCESS,
            servicesActionTypes.GET_PAID_SERVICE_GROUPS_ACTION_FAILURE,
        ],
    },
});

export const getPaidServiceCategory = (session: string, idService: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        list: true,
        query: "GetPaidServiceCategory",
        session,
        types: [
            servicesActionTypes.GET_PAID_SERVICE_CATEGORY_ACTION,
            servicesActionTypes.GET_PAID_SERVICE_CATEGORY_ACTION_SUCCESS,
            servicesActionTypes.GET_PAID_SERVICE_CATEGORY_ACTION_FAILURE,
        ],
    },
});

export const getPaidServiceParamsAction = (params: PaidServiceGroupsParamsType): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService: params.idService,
        },
        query: "GetPaidServiceParams",
        session: params.session,
        types: [
            servicesActionTypes.GET_PAID_SERVICE_PARAMS_ACTION,
            servicesActionTypes.GET_PAID_SERVICE_PARAMS_ACTION_SUCCESS,
            servicesActionTypes.GET_PAID_SERVICE_PARAMS_ACTION_FAILURE,
        ],
    },
    payload: {
        ls: params.ls,
        paymentTypes: params.paymentTypes,
    },
});

export const crmRegPaidServiceAction = (
    session: string,
    {idCrmProvider, idCrmService, idPaymentMethod, nnLs, serviceInfo, vlDocs = [], idPriceListRow, promocode}: Object,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            cnSvc: 1,
            idCrmProvider,
            idCrmService,
            idPaymentMethod,
            idPriceListRow,
            nnLs,
            promocode,
            serviceInfo,
            vlDocs: JSON.stringify(vlDocs),
        },
        query: "CrmRegPaidService",
        session,
        types: [
            servicesActionTypes.CRM_REG_PAID_SERVICE_ACTION,
            servicesActionTypes.CRM_REG_PAID_SERVICE_ACTION_SUCCESS,
            servicesActionTypes.CRM_REG_PAID_SERVICE_ACTION_FAILURE,
        ],
    },
    payload: {
        skipkdResults: [RESPONSE_RESULT_TEXT_CODE],
    },
});

export const regPaidServiceAction = (params: RegPaidServiceParamsType, session: string): ApiMiddlewareActionType => ({
    api: {
        data: params,
        query: "RegPaidService",
        session,
        types: [
            servicesActionTypes.REG_PAID_SERVICE_ACTION,
            servicesActionTypes.REG_PAID_SERVICE_ACTION_SUCCESS,
            servicesActionTypes.REG_PAID_SERVICE_ACTION_FAILURE,
        ],
    },
    payload: {
        skipkdResults: [RESPONSE_RESULT_TEXT_CODE],
    },
});

export const closePayConfirmModal = () => ({
    type: servicesActionTypes.CLOSE_PAY_CONFIRM_MODAL_ACTION,
});

export const getPaidServiceHistory = (
    {cancelToken, kdProvider, nnLs, dtFrom, dtTill}: Object,
    session: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            dtFrom,
            dtTill,
            kdProvider,
            nnLs,
        },
        list: true,
        query: "GetPaidServiceHistory",
        session,
        types: [
            servicesActionTypes.GET_PAID_SERVICE_HISTORY_ACTION,
            servicesActionTypes.GET_PAID_SERVICE_HISTORY_ACTION_SUCCESS,
            servicesActionTypes.GET_PAID_SERVICE_HISTORY_ACTION_FAILURE,
        ],
    },
    payload: {
        cancelToken,
        nnLs,
    },
});

export const crmGetPaidServiceHistory = (
    {cancelToken, kdProvider, nnLs, dtFrom, dtTill}: Object,
    session: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            dtFrom,
            dtTill,
            kdProvider,
            nnLs,
        },
        query: "CrmGetPaidServiceHistory",
        session,
        types: [
            servicesActionTypes.CRM_GET_PAID_SERVICE_HISTORY_ACTION,
            servicesActionTypes.CRM_GET_PAID_SERVICE_HISTORY_ACTION_SUCCESS,
            servicesActionTypes.CRM_GET_PAID_SERVICE_HISTORY_ACTION_FAILURE,
        ],
    },
    payload: {
        cancelToken,
    },
});

export const getPrintRequestsAction = (session: string, idContract: number): ApiMiddlewareActionType => ({
    api: {
        action: "file",
        data: {
            idContract,
            plugin: "propagateCrmPrint",
        },
        query: "GetPrintRequests",
        session,
        types: [
            servicesActionTypes.GET_PRINT_REQUESTS_ACTION,
            servicesActionTypes.GET_PRINT_REQUESTS_ACTION_SUCCESS,
            servicesActionTypes.GET_PRINT_REQUESTS_ACTION_FAILURE,
        ],
    },
});

export const openOrderServiceForm = () => ({
    type: servicesActionTypes.OPEN_ORDER_SERVICE_FORM,
});

export const closeOrderServiceForm = () => ({
    type: servicesActionTypes.CLOSE_ORDER_SERVICE_FORM,
});

export const crmGetPromosAction = (
    session: string,
    idService: number,
    idCategory: number | null,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            idCategory,
            idService,
        },
        list: true,
        query: "CrmGetPromos",
        session,
        types: [
            servicesActionTypes.CRM_GET_PROMOS_ACTION,
            servicesActionTypes.CRM_GET_PROMOS_ACTION_SUCCESS,
            servicesActionTypes.CRM_GET_PROMOS_ACTION_FAILURE,
        ],
    },
});

export const crmPreCalcAction = ({promocode, session, idService, idPriceListRow}: CrmPreCalcActionParamsType) => ({
    api: {
        data: {
            idPriceListRow,
            idService,
            promocode,
        },
        list: true,
        query: "CrmPreCalc",
        session,
        types: [
            servicesActionTypes.CRM_PRE_CALC_ACTION,
            servicesActionTypes.CRM_PRE_CALC_ACTION_SUCCESS,
            servicesActionTypes.CRM_PRE_CALC_ACTION_FAILURE,
        ],
    },
});

export const crmShowGpAction = (session: CrmShowGpActionType) => ({
    api: {
        query: "CrmShowGp",
        session,
        types: [
            servicesActionTypes.CRM_SHOW_GP_ACTION,
            servicesActionTypes.CRM_SHOW_GP_ACTION_SUCCESS,
            servicesActionTypes.CRM_SHOW_GP_ACTION_FAILURE,
        ],
    },
});
