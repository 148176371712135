// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import cn from "classnames";
import MaterialDialogContent from "@material-ui/core/DialogContent";
import {DialogContentProps} from "@material-ui/core/DialogContent/DialogContent";

const styles = () => ({
    contentBottomPadding: {
        paddingBottom: 0,
    },
    "min-height-200": {
        minHeight: 200,
    },
    "min-height-300": {
        minHeight: 300,
    },
    overflow: {
        overflow: "visible",
    },
});

type Props = DialogContentProps & {
    contentBottomPadding?: boolean,
    children?: React.Node,
    minHeight?: 200 | 300,
    classes?: Object,
    className?: string,
};

export const DialogContent = ({
    children,
    minHeight,
    classes = {},
    contentBottomPadding = false,
    className,
    ...otherProps
}: Props) => {
    const dialogContentClassName = cn(className, classes.overflow, {
        [classes[`min-height-${minHeight || ""}`]]: minHeight,
        [classes.contentBottomPadding]: contentBottomPadding,
    });

    return (
        <MaterialDialogContent {...otherProps} className={dialogContentClassName} testid="formMessage">
            {children}
        </MaterialDialogContent>
    );
};

export default withStyles(styles)(DialogContent);
