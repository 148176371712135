// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {DEFAULT_SIZE} from "../../../constants/iconConstants";
import {type IconPropsType} from "./iconType";

const MesLogoIcon = ({fontSize, ...otherProps}: IconPropsType) => (
    <SvgIcon {...otherProps} style={{fontSize}}>
        <path fill="#DF8844" d="M3.3,3.2c-4.3,4.3-4.3,11.4,0,15.7c3.4,3.4,8.4,4.1,12.5,2.2l-1.5-2.7l6.7,2.4L3.3,3.2z" />
        <path fill="#304673" d="M19,19c4.3-4.3,4.3-11.4,0-15.7C15.6-0.1,10.6-0.9,6.5,1L8,3.7L1.3,1.3L19,19z" />
        <path
            fill="#FFFFFF"
            d={
                "M18.8,11.7h-1.3c-0.1,0.6-0.5,0.9-1,0.9c-0.6,0-0.9-0.4-0.9-1.2c0-0.8,0.4-1.9,1.3" +
                "-1.9 c0.6,0,0.8,0.3,0.8,0.8H19v-0.1c0-1.1-0.8-1.7-2-1.7c-1.7,0-2.6,1.3-2.6,2.9c" +
                "0,1.4,0.8,2.2,2.1,2.2C17.7,13.6,18.6,12.9,18.8,11.7 M14.2,10.7c0-1.4-0.8-2.2-2." +
                "1-2.2c-1.2,0-2,0.6-2.3,1.7h1.3c0.2-0.4,0.5-0.7,0.9-0.7c0.5,0,0.9,0.3,0.9,0.9h-1.5l-0." +
                "2,1h1.6 c-0.2,0.6-0.6,1.1-1.2,1.1c-0.6,0-0.8-0.3-0.8-0.8H9.6v0.1c0,1.1,0.8,1.7,2" +
                ",1.7C13.2,13.6,14.2,12.3,14.2,10.7 M9.8,8.6H8l-0.8,1.5 c-0.3,0.5-0.7,1.3-0.8,1.9c" +
                "0-0.5,0-1,0-1.6L6.2,8.6H4.4l-1,4.8h1.2L5,11.4c0.1-0.6,0.2-1.2,0.3-1.8c0,0.5,0,1.1,0.1" +
                ",1.6l0.2,2.2h1 l1.1-2.2c0.3-0.5,0.5-1.1,0.8-1.7c-0.2,0.6-0.3,1.2-0.4,1.8l-0.5,2.1h1" +
                ".2L9.8,8.6z"
            }
        />
    </SvgIcon>
);

MesLogoIcon.defaultProps = {
    fontSize: DEFAULT_SIZE,
    viewBox: "0 0 22.2 22.2",
};

export default MesLogoIcon;
