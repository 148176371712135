// @flow
export const mimeTypes = {
    bmp: "image/bmp",
    doc: "application/word",
    docx: "application/word",
    gif: "image/gif",
    jpeg: "image/jpeg",
    jpg: "image/jpg",
    pdf: "application/pdf",
    png: "image/png",
    pps: "application/pps",
    ppt: "application/ppt",
    ppx: "application/ppt",
    ppz: "application/ppz",
    rar: "application/rar",
    rtf: "application/word",
    svg: "image/svg+xml",
    tif: "application/tif",
    xls: "application/excel",
    xlsx: "application/excel",
    xml: "application/excel",
    zip: "application/zip",
};
