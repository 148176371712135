// @flow
import React, {Component} from "react";
import cn from "classnames";
import {withStyles} from "@material-ui/core/styles";
import {type Color} from "@material-ui/core/Typography/Typography";

const styles = (theme: any) => ({
    // eslint-disable-next-line sort-keys
    absolute: {
        position: "absolute",
    },
    "background-color-black": {
        background: theme.palette.common.black,
    },
    "background-color-blue": {
        background: theme.palette.primary.light,
    },
    "background-color-orange": {
        background: theme.palette.secondary.main,
    },
    "background-color-red": {
        background: theme.palette.common.red,
    },
    "border-none": {
        border: "none",
    },
    "border-primary": {
        borderColor: theme.palette.primary.light,
        borderStyle: "solid",
        borderWidth: "2px",
    },
    "border-secondary": {
        borderColor: theme.palette.secondary.main,
        borderStyle: "solid",
        borderWidth: "2px",
    },
    "color-white": {
        color: theme.palette.common.white,
    },
    "display-flex": {
        display: "flex",
    },
    "display-inline-flex": {
        display: "inline-flex",
    },
    "fontSize-10": {
        fontSize: 10,
    },
    "fontSize-34": {
        fontSize: 34,
    },
    "margin-2": {
        margin: 2,
    },
    "padding-20": {
        padding: 20,
    },
    "position-right-bottom": {
        bottom: 5,
        right: -5,
    },
    "position-right-top": {
        right: -5,
        top: 0,
    },
    rootDefault: {
        alignItems: "center",
        borderRadius: "50%",
        height: 17,
        justifyContent: "center",
        width: 17,
    },
    "size-10": {
        height: 10,
        width: 10,
    },
    "size-18": {
        height: 18,
        width: 18,
    },
    "size-34": {
        height: 34,
        width: 34,
    },
});

type Props = {|
    absolute?: boolean,
    children?: React$Node | string,
    backgroundColor?: "red" | "orange" | "blue" | "white" | "black",
    border?: "primary" | "secondary" | "none",
    classes?: any,
    className?: string,
    component?: React$ElementType,
    color?: Color,
    fontSize?: 34 | 10,
    textColor?: "white",
    textAlign?: "center",
    position?: "right-top" | "right-bottom",
    size?: 34 | 18 | 10,
    padding?: 20,
    display?: "inline-flex" | "flex",
    margin?: 2,
|};

export class CircleText extends Component<Props> {
    render() {
        const {
            absolute,
            backgroundColor = "",
            border = "",
            classes = {},
            children,
            textColor = "",
            fontSize = "",
            padding = "",
            position = "",
            className: parentClassName,
            size = "",
            margin = "",
            display = "flex",
        } = this.props;

        const className = cn(parentClassName, classes.rootDefault, {
            [classes[`background-color-${backgroundColor}`]]: backgroundColor,
            [classes[`border-${border}`]]: border,
            [classes[`color-${textColor}`]]: textColor,
            [classes[`fontSize-${fontSize}`]]: fontSize,
            [classes[`padding-${padding}`]]: padding,
            [classes[`position-${position}`]]: position,
            [classes[`size-${size}`]]: size,
            [classes[`margin-${margin}`]]: margin,
            [classes[`display-${display}`]]: display,
            [classes.absolute]: absolute,
        });

        return <div className={className}>{children}</div>;
    }
}

export default withStyles(styles)(CircleText);
