// @flow
import React from "react";
import camelCase from "lodash/camelCase";
import {type themeTypes} from "../../../../themeStyle";
import {type BPatternSrc} from "../BPattern";
import {replaceAll} from "../../../../utils/stringUtils";
import {patternTypes} from "./patternTypes";

type PatternParsedProps = {
    data: Object,
    pattern: string,
    src: Array<BPatternSrc>,
    theme?: themeTypes,
};

export const PatternParsed = ({data, pattern, src, theme}: PatternParsedProps) => {
    let hasValues = 0;

    return src.reduce((_acc, source, position) => {
        let acc = _acc;
        const startString = `$${position}`;
        const startStringIndex = acc.indexOf(startString);
        const endString = `$${position + 1}`;
        const endStringIndex = acc.indexOf(endString);
        const currentSubstring = acc.slice(startStringIndex, endStringIndex > 0 ? endStringIndex : acc.length);
        let value = data[camelCase(source.key)];

        if (acc.indexOf("$") === -1) {
            return acc;
        }

        if (source.type === "boolean") {
            return value === "true" || value === true ? "<Checkbox checked disabled />" : "<Checkbox disabled />";
        }

        if (source.type in patternTypes) {
            const parser = patternTypes[source.type];
            const params = {
                data: value,
                format: source.format,
                precision: source.precision,
                theme,
            };

            value = parser(params);
        }

        if (value === 0) {
            value = "0";
        }

        hasValues += value ? 0 : 1;
        value = value ? value : "";

        if (hasValues === src.length) {
            return "";
        }

        if (!value) {
            acc = acc.replace(currentSubstring, replaceAll(currentSubstring, "<br>", ""));
        }

        return typeof acc === "string" ? acc.replace(new RegExp(`\\$${position}`, "g"), value) : "";
    }, pattern);
};

export default PatternParsed;
