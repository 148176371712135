// @flow
import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ModalConfirm from "../../packages/components/Modal/ModalConfirm";
import type {InfoModalType} from "../../redux/reducers/notificationReducer";
import {type StoreType} from "../../redux/reducers/index";
import {
    closeInfoModalAction,
    getModalInfoDataAction,
    type GetModalInfoDataActionType,
} from "../../redux/actions/notificationAction";
import ModalInfoComponent from "./ModalInfoComponent";

type OwnProps = {|
    children?: React.Node,
|};

type StoreProps = {|
    infoModal: InfoModalType,
|};

type DispatchProps = {|
    closeInfoModalAction: () => void,
    getModalInfoDataAction: GetModalInfoDataActionType,
|};
type Props = {...OwnProps, ...StoreProps, ...DispatchProps};

export class InfoModal extends React.Component<Props> {
    componentDidMount() {
        this.loadData(this.props);
    }

    componentDidUpdate({infoModal: prevInfoModal}: Props) {
        if (prevInfoModal.modalName !== this.props.infoModal.modalName) {
            this.loadData(this.props);
        }
    }

    loadData = (props: Props) => {
        const {
            infoModal: {modalName},
        } = props;

        if (modalName) {
            this.props.getModalInfoDataAction(modalName);
        }
    };

    render() {
        const {
            infoModal: {
                open,
                title,
                modalName,
                componentData: {fetching, data},
            },
        } = this.props;

        return open ? (
            <ModalConfirm
                open
                title={title}
                onCancel={this.props.closeInfoModalAction}
                noDialogAction
                maxWidth={600}
                loaderFetching={fetching}
            >
                <ModalInfoComponent modalName={modalName} data={data} fetching={fetching} />
            </ModalConfirm>
        ) : null;
    }
}

export default connect<Props, OwnProps, StoreProps, DispatchProps, StoreType, _>(
    ({notification}: StoreType): StoreProps => ({
        infoModal: notification.infoModal,
    }),
    (dispatch): DispatchProps => ({
        closeInfoModalAction: bindActionCreators(closeInfoModalAction, dispatch),
        getModalInfoDataAction: bindActionCreators(getModalInfoDataAction, dispatch),
    }),
)(InfoModal);
