// @flow
import axios from "axios";
import {forOwn} from "lodash";
import debug from "debug";
import {providerConfig} from "../constants/ProvidersConfig";
import {publickUrl} from "../constants/Application";

const logger = debug("tii:lkk:appUtils");

export const isSupportedProvider = (kdProvider?: number) => {
    if (kdProvider && providerConfig[kdProvider] && providerConfig[kdProvider].shortName) {
        return process.env[`REACT_APP_PROVIDER_${providerConfig[kdProvider].shortName.toUpperCase()}`] === "on";
    }

    return false;
};

const COMPERE_PART = 8;

export const backgroundChunkLoads = () => {
    if (process.env.NODE_ENV === "production") {
        axios
            .get(`${publickUrl}/asset-manifest.json`)
            .then((response) => {
                const {data} = response;

                forOwn(data, (path) => {
                    if (path.slice(path.length - COMPERE_PART) === "chunk.js") {
                        axios.get(`${publickUrl}/${path}`);
                    }

                    return null;
                });
            })
            .catch((error) => {
                logger(error);
            });
    }
};
