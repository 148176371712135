// @flow
import React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import "rc-calendar/assets/index.css";
import RangeCalendar from "rc-calendar/lib/RangeCalendar";
import {Form} from "react-final-form";
import moment from "moment";
import {withStyles} from "@material-ui/core/styles";
import Button from "../Button/Button";
import DialogContent from "../Dialog/DialogContent";
import Dialog from "../Dialog/Dialog";
import DialogTitle from "../Dialog/DialogTitle";
import DialogActions from "../Dialog/DialogActions";
import Typography from "../Typography/Typography";
import DialogCloseIcon from "../Dialog/DialogCloseIcon";
import {CLIENT_SIDE_DATE_FORMAT} from "../../../constants/DateFormat";
import {type themeTypes} from "../../../themeStyle";
import Content from "../Content/Content";

const paperProps = {
    testID: "modalConfirm",
};
const LAST_HOUR = 23;
const LAST_MIN_SEC = 59;
const styles = (theme: themeTypes) => ({
    "@global": {
        ".rc-calendar-input": {
            width: "Calc(100% - 12px) !important",
        },
        ".rc-calendar-range .rc-calendar-in-range-cell": {
            backgroundColor: `${theme.palette.primary.translucent} !important`,
        },
        ".rc-calendar-selected-day .rc-calendar-date": {
            backgroundColor: `${theme.palette.primary.light} !important`,
            color: theme.palette.common.white,
        },
    },

    picker: {
        textAlign: "center",
    },
});

type ValuesType = {
    [$key: string]: string,
};
type PropsType = {
    classes?: Object,
    open: boolean,
    onSubmit?: (values: ValuesType) => void,
    initialValues: Object,
    onClose: () => void,
    maxDate: string | Date,
    minDate: string | Date,
};
type StateType = {
    dtEn: string,
    dtSt: string,
};
class DateRangePicker extends React.Component<PropsType, StateType> {
    state = {
        dtEn: "",
        dtSt: "",
    };

    componentDidMount() {
        const {dtSt, dtEn} = this.props.initialValues;

        if (dtSt === "") {
            this.setState({
                dtSt: moment().format(CLIENT_SIDE_DATE_FORMAT),
            });
        } else {
            this.setState({
                dtSt: moment(dtSt).format(CLIENT_SIDE_DATE_FORMAT),
            });
        }
        if (dtEn === "") {
            this.setState({
                dtEn: moment().format(CLIENT_SIDE_DATE_FORMAT),
            });
        } else {
            this.setState({
                dtEn: moment(dtEn).format(CLIENT_SIDE_DATE_FORMAT),
            });
        }
    }

    handleChange = (values: Array<Object>) => {
        const [selectedStartDate, selectedEndDate] = values;

        selectedStartDate
            ? this.setState({dtSt: selectedStartDate.startOf("day").format("DD.MM.YYYY")})
            : this.setState({dtSt: selectedEndDate.startOf("day").format("DD.MM.YYYY")});

        selectedEndDate
            ? this.setState({dtEn: selectedEndDate.format("DD.MM.YYYY")})
            : this.setState({dtEn: moment().format("DD.MM.YYYY")});
    };

    handleSubmit = () => {
        const result = {
            dtEn: this.state.dtEn,
            dtSt: this.state.dtSt,
        };

        if (this.props.onSubmit) {
            this.props.onSubmit(result);
        }

        this.props.onClose();
    };

    disabledDate = (current) => {
        const {maxDate, minDate} = this.props;
        const date = moment();

        date.hour(LAST_HOUR);
        date.minute(LAST_MIN_SEC);
        date.second(LAST_MIN_SEC);

        if (minDate && current.isBefore(moment(minDate))) {
            return true;
        }
        if (maxDate && current.isAfter(moment(maxDate))) {
            return true;
        }

        return current.isAfter(date);
    };

    renderContent = () => {
        const {classes = {}, initialValues} = this.props;

        return (
            <Grid spacing={0} container justify="center" testID="modalConfirmBody">
                <Grid item xs={12}>
                    <div className={classes.picker}>
                        <RangeCalendar
                            style={{
                                display: "inline-block",
                            }}
                            defaultSelectedValue={[moment(initialValues.dtSt), moment(initialValues.dtEn)]}
                            onChange={this.handleChange}
                            format="DD.MM.YYYY"
                            disabledDate={this.disabledDate}
                            showToday={false}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    };

    render() {
        const {open, onClose} = this.props;

        return (
            <Form
                onSubmit={this.handleSubmit}
                initialValues={{
                    dtEn: this.state.dtEn,
                    dtSt: this.state.dtSt,
                }}
                render={({handleSubmit}) => (
                    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose} PaperProps={paperProps}>
                        <DialogCloseIcon onClick={onClose} />
                        <DialogTitle disableTypography>
                            <Typography fontSize={20} lineHeight={1}>
                                Выбрать период
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Content overflowX="auto">{this.renderContent()}</Content>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleSubmit} color="secondary" size="sm" testID="formYesButton">
                                Выбрать
                            </Button>
                            <Button onClick={onClose} link underline="dashed" testID="formNoButton">
                                Отмена
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            />
        );
    }
}

export default withStyles(styles)(DateRangePicker);
