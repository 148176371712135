// @flow
import forOwn from "lodash/forOwn";
import {
    builderAccountEmptyState,
    builderAccountMetadata,
    type BuilderAccountStateType,
    type BuilderAccountMetadataKeysType,
} from "../../constants/types/BuilderAccountTypes";
import * as actionTypes from "../../constants/types/ActionTypes";
import {createReducer} from "./createReducer";

export type BuilderAccountReducerType = {
    [key: BuilderAccountMetadataKeysType]: BuilderAccountStateType,
};

const getInitialState = () => {
    const initState = {};

    forOwn(builderAccountMetadata, (value, key) => {
        initState[key] = builderAccountEmptyState;
    });

    return initState;
};

const initialState = getInitialState();

export const builderAccountReducer = createReducer(initialState, {
    [actionTypes.GET_BUILDER_ACCOUNT_METADATA_SUCCESS]: (
        state: BuilderAccountReducerType,
        action,
    ): BuilderAccountReducerType => ({
        ...state,
        [action.payload.name]: action.response.metadata,
    }),
});
