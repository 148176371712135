import TextField from "../../components/Form/TextField";
import FieldDate from "./CustomDatePicker";
import FieldDateTime from "./CustomDateTimePicker";
import FieldCombo from "./FieldCombo";
import FieldTextPhone from "./FieldTextPhone";
import FieldTextMask from "./FieldTextMask";
import UploadFileGroup from "./UploadFileGroup";
import TreeMenu from "./TreeMenu";
import CheckBoxMenu from "./CheckBoxMenu";
import FIOTextField from "./FIOTextField";
import BirthdayDatePicker from "./BirthdayDatePicker";
import PromocodeTextField from "./PromocodeTextField";
import TextFieldWithMaxLength from "./TextFieldWithMaxLength";
import FieldComboWithSearch from "./FieldComboWithSearch";
import CheckBoxField from "./CheckBoxField";

export const fieldMap = {
    BASE: TextField,
    BASE_BIRTHDATE: BirthdayDatePicker,
    BASE_DATE_DOMAIN: FieldDate,
    BASE_MAX_LENGTH: TextFieldWithMaxLength,
    BASE_PHONE_DOMAIN: FieldTextPhone,
    BASE_PROMOCODE: PromocodeTextField,
    BASE_TIMESTAMP_DOMAIN: FieldDateTime,
    CHECK_BOX_MENU: CheckBoxMenu,
    DICT: FieldCombo,
    DICT_TREE: TreeMenu,
    DICT_WITH_SEARCH: FieldComboWithSearch,
    DOCUMENT: UploadFileGroup,
    FIO_DOMAIN: FIOTextField,
    boolean: CheckBoxField,
    date: FieldDate,
    datetime: FieldDateTime,
    fieldTextMask: FieldTextMask,
};

export const applicationTextFieldFormType = ["integer", "bigint", "float", "text"];

export const getFieldInstance = (config) => {
    switch (true) {
        case `${config.nmAttrType}_${config.nmAttrDataType}` in fieldMap:
            return fieldMap[`${config.nmAttrType}_${config.nmAttrDataType}`];
        case `${config.nmAttrType}_${config.nmDomain}` in fieldMap:
            return fieldMap[`${config.nmAttrType}_${config.nmDomain}`];
        case config.nmDomain in fieldMap:
            return fieldMap[config.nmDomain];
        case config.prDict && config.prMultipleVals:
            return fieldMap.CHECK_BOX_MENU;
        case config.prDict:
            return fieldMap.DICT;
        case Boolean(config.nmInputMask):
            return fieldMap.fieldTextMask;
        case applicationTextFieldFormType.includes(config.nmAttrType):
            return fieldMap.BASE;
        default:
            return fieldMap[config.nmAttrType];
    }
};
