// @flow
import {createNumberMask} from "./numberMask";

export const parsingNumber = (number: number | string): string => {
    const result = String(number).split(".");
    const firstPart = result[0].split("").reverse();
    const transformArr = [];

    for (let temp = 0; temp < firstPart.length; temp += 1) {
        transformArr.push(firstPart[temp]);

        if ((temp + 1) % 3 === 0 && firstPart.length > 3) {
            transformArr.push(" ");
        }
    }

    result[0] = transformArr.reverse().join("");

    return result.join(",");
};

export const isFirstLetterIsNumber = (value?: string): boolean =>
    value !== undefined && value !== "" && /[()+0-9]/.test(value.slice(0, 1));

export const unformatNumber = (value: string, deleteSubstr?: string): string | number => {
    let result = value;

    if (deleteSubstr && value.indexOf(deleteSubstr) !== -1) {
        result = result.replace(deleteSubstr, "");
    }
    if (typeof value === "string") {
        result = result
            .replace(",", ".")
            .replace(/\s/g, "")
            .replace("₽", "");
    }

    return result;
};

export const getMaskForIndicatorInput = (separator: number, isFloat: boolean) => {
    let params = {
        allowDecimal: isFloat,
        decimalLimit: null,
        decimalSymbol: ",",
        includeThousandsSeparator: true,
        prefix: "",
        suffix: "",
        thousandsSeparatorSymbol: " ",
    };

    if (separator !== 0) {
        const [intPart, floatPart] = String(separator).split(".");

        params = {...params, decimalLimit: Number(floatPart), integerLimit: Number(intPart)};
    }

    return createNumberMask(params);
};
