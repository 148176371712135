/* eslint-disable max-lines */
// @flow
import moment from "moment/moment";
import uniqueId from "lodash/uniqueId";
import sortBy from "lodash/sortBy";
import forOwn from "lodash/forOwn";
import map from "lodash/map";
import {type PaymentInfoType} from "../redux/actions/tmkprovider/tmkPayAction";

export type ConfigFieldType = {
    color: string,
    dataKey: string,
    nameKey: string,
};
export type ConfigFieldsType = Array<ConfigFieldType>;

export const isShowPrivilegesInfo = (privileges: Object, nnDays?: number) => {
    if (!privileges.dtEn || !nnDays) {
        return false;
    }

    const diffInDays = Math.abs(moment(privileges.dtEn).diff(moment(), "days"));

    return diffInDays <= nnDays;
};

export const updateField = (state: Object, field: string, {name, mapId, normalizeFunction, value}: Object) => ({
    ...state[field],
    [name]: {
        ...state[field][name],
        [mapId]: normalizeFunction ? normalizeFunction(value) : value,
    },
});

type SortByDateParamsType = Array<Object>;

type sortByDateProps = {
    fromBiggest?: boolean,
    sortKey?: string,
};

export const sortByDate = (data: SortByDateParamsType, params?: sortByDateProps) => {
    const {fromBiggest = true, sortKey = "dtPeriod"} = params || {};
    const newData = [...data];

    newData.sort((item1, item2) =>
        fromBiggest ? moment(item2[sortKey]) - moment(item1[sortKey]) : moment(item1[sortKey]) - moment(item2[sortKey]),
    );

    return newData;
};

export const groupIndicatorsDataMoe = (response: Array<Object>) => {
    const newArray: Array<Object> = [];

    response.forEach((indicatorItem) => {
        const item = newArray.find((newArrayItem) => {
            const equalNnPu = indicatorItem.nnPu && newArrayItem.nnPu === indicatorItem.nnPu;
            const equalIdBillingCounter =
                indicatorItem.idBillingCounter && newArrayItem.idBillingCounter === indicatorItem.idBillingCounter;

            return equalNnPu && equalIdBillingCounter;
        });

        if (item && item.indicators) {
            item.indicators.push(indicatorItem);
        } else {
            newArray.push({
                idBillingCounter: indicatorItem.idBillingCounter,
                indicators: [indicatorItem],
                nnPu: indicatorItem.nnPu,
            });
        }
    });

    return newArray;
};

export const getBarCodeId = (field: Object) => {
    const {serviceProviderBarcode = "", idGisService = "", serviceProviderNds = "", idService = ""} = field;

    return `${serviceProviderBarcode}${idGisService}${serviceProviderNds}${idService}`;
};

export const checkConditionsTmkContractType = (field: Array<number | string>) =>
    field.every((condition: number | string) => condition);

export const checkTmkContractType = (field: PaymentInfoType) => {
    const checkServiceProvider1 = checkConditionsTmkContractType([
        field.serviceProviderSupplierid,
        field.serviceProviderBarcode,
        field.serviceProviderNds,
    ]);
    const checkServiceProvider2 = checkConditionsTmkContractType([field.serviceProviderTel, field.serviceProviderInn]);
    const checkOther = checkConditionsTmkContractType([field.nnLs, field.idFacility]);

    const checkFirstContractType = checkServiceProvider1 && checkServiceProvider2 && checkOther;
    const checkSecondContractType = checkServiceProvider1 && checkOther;
    const checkContractType = field.tpContract === 1 ? checkFirstContractType : checkSecondContractType;

    const id = uniqueId(`id-${getBarCodeId(field)}-`);

    if (checkContractType) {
        return {
            ...field,
            id,
            valid: true,
        };
    }

    return {
        ...field,
        id,
        invalidText: `Онлайн оплата за услугу ${field.service || ""} ${field.serviceProvider || ""} ${
            field.nnLs
        } временно недоступна`,
        valid: false,
    };
};

const getFieldNmAttributeTomskProvider = ({service, serviceProvider, nnLs}: Object) =>
    `${service || ""} ${serviceProvider || ""} ЛС № ${nnLs}`;

const getFieldNmAttributeDefaultProvider = ({service, serviceProvider, nnLs}: Object) =>
    `${serviceProvider || ""} ${service || ""} ЛС № ${nnLs}`;

export const buildFieldsConfig = (fieldsInfo: Object, provider: string) =>
    map<Object, Array<Object>>(fieldsInfo, (fields) =>
        fields.map<Object>((field) => ({
            idGisService: field.idGisService,
            idService: field.idService || "",
            invalidText: field.invalidText || "",
            mask: true,
            nmAttrDataType: "VARCHAR",
            nmAttrType: "BASE",
            nmAttribute:
                provider === "tomsk"
                    ? getFieldNmAttributeTomskProvider(field)
                    : getFieldNmAttributeDefaultProvider(field),
            nmColumn: field.id,
            nmEntity: "Оплата томск",
            nmFee: field.nmFee,
            nmRegexp: "^[0-9]",
            nmRegexpError: "Введены некорректные данные",
            nmTable: "LKA.LKA_PROFILE",
            prBase: true,
            prRequired: true,
            prVisible: true,
            serviceProviderBarcode: field.serviceProviderBarcode,
            serviceProviderNds: field.serviceProviderNds,
            serviceProviderSupplierid: field.serviceProviderSupplierid,
            valid: field.valid,
            valueId: uniqueId(`id-${getBarCodeId(field)}-`),
        })),
    );

export const checkTmkInfo = (fieldsInfo: Array<PaymentInfoType>) => {
    const result = [];

    fieldsInfo.forEach((field) => {
        if (field && (field.tpContract === 1 || field.tpContract === 0)) {
            result.push(checkTmkContractType(field));
        }
    });

    return sortBy<PaymentInfoType>(result, "serviceProviderBarcode");
};
export const checkOrlInfo = (fieldsInfo: Array<PaymentInfoType>) => {
    const result = [];

    fieldsInfo.forEach((field) => {
        if (
            field &&
            (field.tpContract === 1 || field.tpContract === 0) &&
            field.paymentMethod !== null &&
            field.paymentMethod !== "" &&
            field.paymentObject !== null
        ) {
            result.push(checkTmkContractType(field));
        }
    });

    return result;
};

const groupByGroupFields = (arr: Array<Object>, keyArr: Array<string>): Object => {
    const resultObj = {};

    arr.forEach((arrItem) => {
        const key = keyArr.reduce((accumulator, currentValue) => accumulator + (arrItem[currentValue] || ""), "");

        if (key) {
            if (resultObj[key]) {
                resultObj[key].push(arrItem);
            } else {
                resultObj[key] = [arrItem];
            }
        }
    });

    return resultObj;
};

export const getTmkFieldsConfigs = (fieldsInfo: Array<PaymentInfoType>, provider: string) =>
    buildFieldsConfig(
        groupByGroupFields(fieldsInfo, ["serviceProviderBarcode", "idGisService", "serviceProviderNds", "idService"]),
        provider,
    );

export const getTmkInitialValues = (fieldsInfo: Array<PaymentInfoType>) => {
    const initialValues = {};

    fieldsInfo.forEach((field: PaymentInfoType) => {
        initialValues[String(field.id)] = field.totalPayment;
    });

    return initialValues;
};

type BuildFieldsConfigType = Array<{
    serviceProviderBarcode: string,
    idGisService: string,
    serviceProviderNds: string,
    nmColumn: string,
}>;

export const getTmkRadioGroupInitialValues = (fieldsConfig: Array<BuildFieldsConfigType>) => {
    const initialValues = {};

    fieldsConfig.forEach((fields) => {
        if (fields.length > 1) {
            initialValues[`radio-${getBarCodeId(fields[0])}`] = fields[0].nmColumn;
        }
    });

    return initialValues;
};

export const getKdElementAsKeys = (elements: Array<Object>) => {
    const newElements: Object = {};

    if (elements) {
        elements.forEach((element) => {
            newElements[element.kdElement] = element;
        });

        return newElements;
    }

    return null;
};

const configFields: ConfigFieldsType = [
    {
        color: "#8299c0",
        dataKey: "vlT1",
        nameKey: "nmT1",
    },
    {
        color: "#82a537",
        dataKey: "vlT2",
        nameKey: "nmT2",
    },
    {
        color: "#db1337",
        dataKey: "vlT3",
        nameKey: "nmT3",
    },
];

export const normalizeMesStatisticData = (arr: Array<Object>) => {
    const hash = {};

    arr.forEach((element) =>
        forOwn(element, (value, key) => {
            if (value !== null) {
                hash[key] = true;
            }
        }),
    );

    const statistics = arr.map<Object>((element) => {
        const data = {};

        forOwn(hash, (value, key) => {
            data[key] = element[key];
        });

        return data;
    });

    const filteredConfigFields: ConfigFieldsType = configFields.filter((configItem) =>
        Boolean(hash[configItem.dataKey]),
    );

    return {configFields: filteredConfigFields, statistics};
};
