/* eslint-disable max-lines */
// @flow
import * as React from "react";
import omit from "lodash/omit";
import warning from "warning";
import cn from "classnames";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import {withStyles} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MaskedInput from "react-text-mask";
import SelectInputIcon from "../../components/Icons/SelectInputIcon";
import {TextFieldStyle} from "./TextFieldStyle";

type Props = {
    autoComplete?: string,
    autoFocus?: boolean,
    children?: any,
    className?: string,
    defaultValue?: string,
    disabled?: boolean,
    error?: boolean,
    FormHelperTextProps?: Object,
    fullWidth?: boolean,
    helperText?: React$Node,
    helperTextClassName?: string,
    id?: string,
    InputLabelProps?: Object,
    InputProps?: Object,
    inputRef?: Function,
    label?: React$Node,
    labelClassName?: string,
    multiline?: boolean,
    name?: string,
    withMask?: boolean,
    onChange?: (event: SyntheticInputEvent<>) => void,
    placeholder?: string,
    placeholderChar?: string,
    required?: boolean,
    mask?: any,
    rootRef?: Function,
    rows?: string | number,
    rowsMax?: string | number,
    select?: boolean,
    SelectProps?: Object,
    type?: string,
    value?: string | number,
    margin?: "none" | "dense" | "normal",
    classes: Object,
    testID: string,
    selectIcon?: boolean,
    meta?: Object,
    fontSize?: 18,
    height: "auto" | 48,
    wrapLabel?: boolean,
    warningText?: string,
    isSuccessful?: boolean,
    maxLength?: number,
    allowedSymbols?: string,
};

class TextField extends React.PureComponent<Props> {
    static defaultProps = {
        InputProps: {},
        classes: {},
        height: 48,
        testID: "",
        wrapLabel: true,
    };

    handleChange = (event: any) => {
        const {value} = event.currentTarget;
        const {allowedSymbols} = this.props;

        if (allowedSymbols) {
            const reg = new RegExp(allowedSymbols);

            if (!reg.test(value)) {
                return false;
            }
        }
        if (this.props.InputProps?.onChange) {
            this.props.InputProps?.onChange(event);
        }

        return false;
    };

    renderMaskedInput = () => {
        const {
            autoComplete,
            autoFocus,
            classes = {},
            defaultValue,
            disabled,
            mask,
            multiline,
            name,
            rows,
            rowsMax,
            value,
            id,
            inputRef,
            onChange,
            placeholder,
            placeholderChar,
            testID,
            fontSize = "",
        } = this.props;
        const {maskedInput} = classes;

        return (
            <MaskedInput
                mask={mask}
                type="text"
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                defaultValue={defaultValue}
                disabled={disabled}
                multiline={multiline}
                name={name}
                rows={rows}
                rowsMax={rowsMax}
                value={value}
                id={id}
                testID={testID}
                inputRef={inputRef}
                onChange={onChange}
                placeholder={placeholder}
                placeholderChar={placeholderChar}
                className={cn(maskedInput, {
                    [classes[`font-size-${fontSize}`]]: fontSize,
                })}
                {...this.props.InputProps}
            />
        );
    };

    renderInput = () => (
        <Input
            autoComplete={this.props.autoComplete}
            autoFocus={this.props.autoFocus}
            defaultValue={this.props.defaultValue}
            disabled={this.props.disabled}
            multiline={this.props.multiline}
            name={this.props.name}
            rows={this.props.rows}
            rowsMax={this.props.rowsMax}
            type={this.props.type}
            value={this.props.value}
            id={this.props.id}
            inputRef={this.props.inputRef}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            className={cn(this.props.classes.textInput, {
                [this.props.classes[`font-size-${this.props.fontSize || ""}`]]: this.props.fontSize || "",
                [this.props.classes.textInputSuccessful]: this.props.isSuccessful,
            })}
            disableUnderline
            inputProps={{
                className: cn({
                    [this.props.InputProps?.className || ""]: Boolean(this.props.InputProps),
                    [this.props.classes.textInputSuccessful]: this.props.isSuccessful,
                }),
                maxlength: this.props.maxLength,
                onChange: this.handleChange,
                onFocus: this.props.InputProps ? this.props.InputProps.onFocus : "",
                style: this.props.InputProps ? this.props.InputProps.style : "",
                testid: this.props.testID,
            }}
            {...this.props.InputProps}
        />
    );

    renderInputLabel = () => {
        const {id, classes, meta, wrapLabel, labelClassName, testID, InputLabelProps, label, isSuccessful} = this.props;

        return (
            <InputLabel
                htmlFor={id}
                className={cn(
                    {
                        [classes.labelActive]: meta && meta.active,
                        [classes.labelSuccessful]: isSuccessful,
                        [classes.textLabelNoWrap]: !wrapLabel,
                        [classes.textLabelWrap]: wrapLabel,
                    },
                    labelClassName,
                )}
                shrink
                testid={`${testID}-label`}
                {...InputLabelProps}
            >
                {label}
            </InputLabel>
        );
    };

    renderFormHelperText = (helperTextId) => {
        const {meta, classes, helperTextClassName, FormHelperTextProps, testID, helperText, warningText} = this.props;

        return (
            <FormHelperText
                className={cn(
                    {
                        [classes.labelActive]: meta && meta.active,
                    },
                    helperTextClassName,
                )}
                id={helperTextId}
                {...FormHelperTextProps}
                testid={`${testID}-notification`}
            >
                {helperText || warningText}
            </FormHelperText>
        );
    };

    getTextWrapClassName = ({textWrapError, textWrapActive, textWrap, textWrapSuccessful}: Object) =>
        cn(textWrap, {
            [textWrapActive]: this.props.meta && this.props.meta.active && !this.props.error,
            [textWrapError]: this.props.error,
            [textWrapSuccessful]: this.props.isSuccessful,
            [this.props.classes[`height-${this.props.height}`]]: this.props.height,
        });

    render() {
        const {
            children,
            className,
            error,
            fullWidth = true,
            warningText,
            helperText,
            id,
            label,
            required,
            rootRef,
            select,
            SelectProps,
            value,
            classes,
            margin = "dense",
            withMask,
            testID,
            selectIcon,
            ...other
        } = this.props;

        warning(
            !select || Boolean(children),
            "Material-UI: `children` must be passed when using the `TextField` component with `select`.",
        );

        const MaskInput = this.renderMaskedInput();
        const textWrapClassName = this.getTextWrapClassName(classes);
        const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
        let InputComponent = withMask ? MaskInput : this.renderInput();

        if (selectIcon) {
            InputComponent = (
                <div className={classes.treeMenuInputContent}>
                    {InputComponent}
                    <SelectInputIcon />
                </div>
            );
        }

        return (
            <FormControl
                aria-describedby={helperTextId}
                className={className}
                error={error}
                fullWidth={fullWidth}
                ref={rootRef}
                required={required}
                margin={margin}
                testid={`${testID}-wrapped`}
                {...omit(other, [
                    "inputRef",
                    "InputIcon",
                    "InputProps",
                    "maxDate",
                    "minDate",
                    "disableFuture",
                    "nmAttrDataType",
                    "uploadFileAction",
                    "deleteFileAction",
                    "ampm",
                    "autoSubmit",
                    "dateRangeIcon",
                    "returnMoment",
                    "showTabs",
                    "timeIcon",
                    "InputLabelProps",
                    "labelClassName",
                    "height",
                    "wrapLabel",
                    "FormHelperTextProps",
                    "helperTextClassName",
                    "meta",
                    "maxFileSize",
                    "maxFilesCounter",
                ])}
            >
                {label && this.renderInputLabel()}
                <div className={textWrapClassName}>
                    {select ? (
                        <Select value={value} input={InputComponent} {...SelectProps}>
                            {children}
                        </Select>
                    ) : (
                        InputComponent
                    )}
                </div>
                {helperText || warningText ? this.renderFormHelperText(helperTextId) : null}
            </FormControl>
        );
    }
}

export default withStyles(TextFieldStyle)(TextField);
