// @flow
import moment from "moment";

/* eslint-disable no-magic-numbers */
export const getPeriodTypes = {
    "1m": {
        dtEn: moment()
            .endOf("month")
            .format(),
        dtSt: moment()
            .subtract("months")
            .startOf("month")
            .format(),
    },
    "2m": {
        dtEn: moment()
            .endOf("month")
            .format(),
        dtSt: moment()
            .subtract(1, "months")
            .startOf("month")
            .format(),
    },
    "3m": {
        dtEn: moment()
            .endOf("month")
            .format(),
        dtSt: moment()
            .subtract(2, "months")
            .startOf("month")
            .format(),
    },
    "6m": {
        dtEn: moment()
            .endOf("month")
            .format(),
        dtSt: moment()
            .subtract(5, "months")
            .startOf("month")
            .format(),
    },
    default: {
        dtEn: "",
        dtSt: "",
    },
    lastCurrentMonth: {
        dtEn: moment(),
        dtSt: moment()
            .subtract("months")
            .startOf("month")
            .format(),
    },
    lastCurrentYear: {
        dtEn: moment(),
        dtSt: moment()
            .subtract("years")
            .startOf("year")
            .format(),
    },
    lastMonth: {
        dtEn: moment().format(),
        dtSt: moment()
            .subtract(1, "month")
            .format(),
    },
    lastThreeMonth: {
        dtEn: moment().format(),
        dtSt: moment()
            .subtract(3, "month")
            .format(),
    },
    lastThreeYears: {
        dtEn: moment().format(),
        dtSt: moment()
            .subtract(3, "year")
            .format(),
    },
    lastTwoMonth: {
        dtEn: moment().format(),
        dtSt: moment()
            .subtract(2, "month")
            .format(),
    },
    lastYear: {
        dtEn: moment().format(),
        dtSt: moment()
            .subtract(1, "year")
            .format(),
    },
    year: {
        dtEn: moment()
            .endOf("month")
            .format(),
        dtSt: moment()
            .subtract(1, "years")
            .startOf("month")
            .format(),
    },
};
