// @flow
import * as React from "react";
import {type UploadFileActionType, type DeleteFileActionType} from "../../../redux/actions/uploadAction";
import UploadFile from "./UploadFile";

type Props = {
    config: Object,
    InputProps: Object,
    session: string,
    uploadFileAction: UploadFileActionType,
    deleteFileAction: DeleteFileActionType,
    uploadButtonName?: string,
    meta: Object,
    maxFilesCounter?: number,
    maxFileSize?: number,
    fileExtensions?: Array<string>,
};

export class UploadFileGroup extends React.Component<Props> {
    onChangeAdd = (inputValue: Object) => {
        const value = this.getValuePropsValue(this.props.InputProps.value);
        const newValue = [...value, ...inputValue];

        this.props.InputProps.onChange(newValue);
    };

    onChangeDelete = (idDoc: number) => {
        const value = this.getValuePropsValue(this.props.InputProps.value);
        const newValue = ((value.filter((item) => item.idDoc !== idDoc): Array<any>): Array<Object>);

        this.props.InputProps.onChange(newValue);
    };

    getValue = (idDocType: Number) => {
        const value = this.getValuePropsValue(this.props.InputProps.value);

        return ((value.filter((item) => item.idDocType === idDocType): Array<any>): Array<Object>);
    };

    getValuePropsValue = (value?: any): Array<Object> => (value === "" || value === undefined ? [] : value);

    render() {
        const {
            config: {documentsData = []},
            uploadFileAction,
            deleteFileAction,
            uploadButtonName,
            session,
            meta,
            maxFilesCounter,
            maxFileSize,
            fileExtensions,
        } = this.props;
        const splitMetaError = meta.error === undefined ? [] : meta.error;

        return (
            <React.Fragment>
                {documentsData.map((uploaderParams, index) => (
                    <UploadFile
                        key={index}
                        uploadFileAction={uploadFileAction}
                        deleteFileAction={deleteFileAction}
                        session={session}
                        values={this.getValue(uploaderParams.idDocType)}
                        addFile={this.onChangeAdd}
                        validatorError={splitMetaError[uploaderParams.nmColumn]}
                        touchedGlobField={meta.touched}
                        deleteFile={this.onChangeDelete}
                        maxFilesCounter={maxFilesCounter}
                        maxFileSize={maxFileSize}
                        uploadButtonName={uploadButtonName}
                        fileExtensions={fileExtensions}
                        {...uploaderParams}
                    />
                ))}
            </React.Fragment>
        );
    }
}

export default UploadFileGroup;
