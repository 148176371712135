// @flow
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import {type BuilderStateType, builderEmptyState} from "../../constants/types/BuilderTypes";
import * as actionTypes from "../../constants/types/ActionTypes";
import * as profileActionTypes from "../../constants/types/ProfileActionTypes";
import * as adsActionTypes from "../../constants/types/AdPopupTypes";
import {getInitialValues} from "../../packages/FormBuilder/utils";
import {groupByOne} from "../../utils/objectUtils";
import {getKdElementAsKeys} from "../../utils/accoutReducerUtils";
import {createReducer} from "./createReducer";

export type BuilderReducerType = {
    accountMes: BuilderStateType,
    adElementsLs: BuilderStateType,
    ads: BuilderStateType,
    advertisement: BuilderStateType,
    application: BuilderStateType,
    auth: BuilderStateType,
    autopayMes: BuilderStateType,
    autopayUfa: BuilderStateType,
    catalog: BuilderStateType,
    catalogLeftBanner: BuilderStateType,
    catalogRightBanner: BuilderStateType,
    emailSubscr: BuilderStateType,
    energyContracts: BuilderStateType,
    extraRegistration: BuilderStateType,
    extraRegistrationUnnecessaryFields: BuilderStateType,
    faq: BuilderStateType,
    lkkEirc: BuilderStateType,
    loadDocuments: BuilderStateType,
    ls: BuilderStateType,
    lsAdd: BuilderStateType,
    lsChange: BuilderStateType,
    lsIndications: BuilderStateType,
    minimalFunction: BuilderStateType,
    minimalFunctionJournal: BuilderStateType,
    minimalFunctionMeasurements: BuilderStateType,
    minimalFunctionReadings: BuilderStateType,
    pay: BuilderStateType,
    payAll: BuilderStateType,
    paymentDocSubscr: BuilderStateType,
    poll: BuilderStateType,
    presentsBtn: BuilderStateType,
    privileges: BuilderStateType,
    profile: BuilderStateType,
    registration: BuilderStateType,
    requisites: BuilderStateType,
    services: BuilderStateType,
    statistic: BuilderStateType,
};

const initialState: BuilderReducerType = {
    accountMes: builderEmptyState,
    adElementsLs: builderEmptyState,
    ads: builderEmptyState,
    advertisement: builderEmptyState,
    application: builderEmptyState,
    auth: builderEmptyState,
    autopayMes: builderEmptyState,
    autopayUfa: builderEmptyState,
    catalog: builderEmptyState,
    catalogLeftBanner: builderEmptyState,
    catalogRightBanner: builderEmptyState,
    emailSubscr: builderEmptyState,
    energyContracts: builderEmptyState,
    extraRegistration: builderEmptyState,
    extraRegistrationUnnecessaryFields: builderEmptyState,
    faq: builderEmptyState,
    lkkEirc: builderEmptyState,
    loadDocuments: builderEmptyState,
    ls: builderEmptyState,
    lsAdd: builderEmptyState,
    lsChange: builderEmptyState,
    lsIndications: builderEmptyState,
    minimalFunction: builderEmptyState,
    minimalFunctionJournal: builderEmptyState,
    minimalFunctionMeasurements: builderEmptyState,
    minimalFunctionReadings: builderEmptyState,
    pay: builderEmptyState,
    payAll: builderEmptyState,
    paymentDocSubscr: builderEmptyState,
    poll: builderEmptyState,
    presentsBtn: builderEmptyState,
    privileges: builderEmptyState,
    profile: builderEmptyState,
    registration: builderEmptyState,
    requisites: builderEmptyState,
    services: builderEmptyState,
    statistic: builderEmptyState,
};

export const builderReducer = createReducer(initialState, {
    [actionTypes.GET_BUILDER_ATTRIBUTES_SUCCESS]: (state: BuilderReducerType, action): BuilderReducerType => ({
        ...state,
        [action.payload.name]: {
            ...state[action.payload.name],
            attributes: action.response ? sortBy(action.response.attributes, ["nnOrder"]) : [],
            fetching: false,
            groupByColumn: action.response ? groupByOne(action.response.attributes, "nmColumn") : {},
            initialValues: action.response ? getInitialValues(action.response.attributes) : {},
        },
    }),
    [actionTypes.GET_BUILDER_ATTRIBUTES]: (state: BuilderReducerType, action): BuilderReducerType => ({
        ...state,
        [action.payload.name]: {
            ...state[action.payload.name],
            fetching: true,
        },
    }),
    [actionTypes.GET_BUILDER_ATTRIBUTES_FAILURE]: (state: BuilderReducerType, action): BuilderReducerType => ({
        ...state,
        [action.payload.name]: {
            ...state[action.payload.name],
            fetching: false,
        },
    }),
    [actionTypes.GET_BUILDER_ELEMENTS_SUCCESS]: (state: BuilderReducerType, action): BuilderReducerType => {
        const elements = getKdElementAsKeys(action.response.elements);

        return {
            ...state,
            [action.payload.name]: {
                ...state[action.payload.name],
                elements: {...state[action.payload.name].elements, ...elements},
            },
        };
    },
    [profileActionTypes.GET_BUILDER_GROUP_ATTRIBUTES_SUCCESS]: (
        state: BuilderReducerType,
        action,
    ): BuilderReducerType => ({
        ...state,
        [action.payload.name]: {
            ...state[action.payload.name],
            groupAttributes: groupBy(sortBy(action.response.attributes, ["nnOrder"]), "kdAttrGroup"),
            groupByColumn: groupByOne(action.response.attributes, "nmColumn"),
            initialValues: getInitialValues(action.response.attributes),
        },
    }),
    [adsActionTypes.GET_AD_ELEMENTS_POPUP_ACTION_SUCCESS]: (state: BuilderReducerType, action): BuilderReducerType => ({
        ...state,
        action,
    }),
    [actionTypes.AUTH_LOGOUT]: (state: BuilderReducerType) => ({...initialState, auth: state.auth}),
    [actionTypes.AUTH_LOGOUT_ACTION_SUCCESS]: (state: BuilderReducerType) => ({...initialState, auth: state.auth}),
});
