// @flow
import React from "react";
import {getContent} from "../utils";
import {convertToTemplateString} from "../../../../utils/stringUtils";

type Props = {
    content?: Array<{
        vlContent: string,
    }>,
    testID?: string,
    searchParams?: Object,
    extraParams?: Object,
    typographyStyleExtra?: Object,
};

export const TextElement = (props: Props) => {
    const {content = [], extraParams = {}, searchParams = {}, typographyStyleExtra = {}} = props;
    let {vlContent} = getContent(content, searchParams) || {};

    vlContent = convertToTemplateString(vlContent, extraParams);

    return <span style={typographyStyleExtra} dangerouslySetInnerHTML={{__html: vlContent}} />;
};

export default TextElement;
