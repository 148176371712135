/* eslint-disable max-lines */
// @flow
import * as actionTypes from "../../../constants/types/ActionTypes";
import {type ApiMiddlewareActionType, type ApiMiddlewareResponsePromiseType} from "../../middlewares/apiMiddleware";
import {loaderString} from "../../../constants/LoaderString";
import {
    MES_KD_PROVIDER,
    MOE_KD_PROVIDER,
    UFA_KD_PROVIDER,
    TKO_KD_PROVIDER,
    VLG_KD_PROVIDER,
    ALT_KD_PROVIDER,
    ORL_KD_PROVIDER,
    ORL_EPD_KD_PROVIDER,
    SAR_KD_PROVIDER,
    TMB_KD_PROVIDER,
    VLD_KD_PROVIDER,
    KSG_KD_PROVIDER,
} from "../../../constants/KdProvider";
import {type LsList} from "../../../pages/PayAll/types";
import {loadAccountDataAction} from "../accountAction";
import {RESPONSE_RESULT_TEXT_CODE, SBP_PAYMENT_PENDING, SBP_PAYMENT_CANCELLED} from "../../../constants/KdResults";

export type MultipleLSPayActionDataType = {
    paymentInfo: string,
    smTotal: number,
    nmEmail: string,
};

export type AccountPayActionType = (params: Object, session: string | void) => ApiMiddlewareResponsePromiseType;
export type PaymentVActionType = (params: Object, session: string | void) => ApiMiddlewareResponsePromiseType;
export type PaymentEPRActionType = (params: Object, session: string | void) => ApiMiddlewareResponsePromiseType;
export type MultipleLSPayAddressActionType = (session?: string) => ApiMiddlewareResponsePromiseType;
export type MultipleLSPayActionType = (
    data: MultipleLSPayActionDataType,
    session?: string,
) => ApiMiddlewareResponsePromiseType;

export type GetFastPayParamsActionType = (kdProvider: number) => ApiMiddlewareResponsePromiseType;
export type StartFastPayActionType = (params: Object) => ApiMiddlewareResponsePromiseType;
export type StartMoeFastPayActionType = (params: Object) => ApiMiddlewareResponsePromiseType;
export type GetFastPayStatusActionType = (
    session?: string,
    idQrcode: string,
    kdProvider: number,
) => ApiMiddlewareResponsePromiseType;
export type MoeGetFastPayStatusActionType = (
    session?: string,
    qrcId: string,
    idService: number,
) => ApiMiddlewareResponsePromiseType;
export type RejectFastPayActionType = (
    session?: string,
    idQrcode: string,
    kdProvider: number,
) => ApiMiddlewareResponsePromiseType;
export type MoeRejectFastPayActionType = (
    session?: string,
    qrcId: string,
    idService: number,
) => ApiMiddlewareResponsePromiseType;
export type MultiBonusCheckShowActionType = (session?: string) => ApiMiddlewareResponsePromiseType;
export type CheckLSPayAvailActionType = (session?: string, idService: number) => ApiMiddlewareResponsePromiseType;
export type GetLsInsuranceActionType = (session?: string, idService: number) => ApiMiddlewareResponsePromiseType;
export type MultiBonusGetBalanceActionType = (session?: string) => ApiMiddlewareResponsePromiseType;
export type SavePaymentSumToStoreActionType = (sum: string) => ApiMiddlewareResponsePromiseType;
export type PayPromoEventsActionType = (session?: string, idService: number) => ApiMiddlewareResponsePromiseType;
export type PayPromoEventsAltActionType = (session?: string, idService: number) => ApiMiddlewareResponsePromiseType;
export type MetersPromoEventsActionType = (session?: string, idService: number) => ApiMiddlewareResponsePromiseType;
export type CheckEprPayActionType = (idService: number, session?: string) => ApiMiddlewareResponsePromiseType;
export type PayPromoEventSetActionType = (
    session?: string,
    idPromoEvent: string,
    idService: number,
) => ApiMiddlewareResponsePromiseType;

const accountPayActionQueryNames = {
    [ALT_KD_PROVIDER]: "AltPayment",
    [KSG_KD_PROVIDER]: "KsgPayment",
    [MES_KD_PROVIDER]: "MesPayRef",
    [MOE_KD_PROVIDER]: "MoePay",
    [ORL_EPD_KD_PROVIDER]: "OrlPaymentEpd",
    [ORL_KD_PROVIDER]: "OrlPayment",
    [SAR_KD_PROVIDER]: "SarPayment",
    [TKO_KD_PROVIDER]: "TrashPay",
    [TMB_KD_PROVIDER]: "TmbPayment",
    [UFA_KD_PROVIDER]: "UfaPayment",
    [VLD_KD_PROVIDER]: "VldPayment",
    [VLG_KD_PROVIDER]: "VlgPayment",
};

const payKeysProvider = {
    [ALT_KD_PROVIDER]: "smTotal",
    [KSG_KD_PROVIDER]: "vlPay",
    [MES_KD_PROVIDER]: "vlPay",
    [MOE_KD_PROVIDER]: "smPay",
    [ORL_KD_PROVIDER]: "smTotal",
    [SAR_KD_PROVIDER]: "smTotal",
    [TKO_KD_PROVIDER]: "smPay",
    [TMB_KD_PROVIDER]: "smTotal",
    [UFA_KD_PROVIDER]: "smTotal",
    [VLD_KD_PROVIDER]: "smTotal",
    [VLG_KD_PROVIDER]: "smTotal",
};

export const accountPayAction = ({
    idService,
    dtPeriod,
    addPaymentItems,
    session,
    sum,
    kdProvider,
    nmEmail,
    nnPhone,
}: Object) => (dispatch: any): ApiMiddlewareActionType => {
    const payWindow = window.open();

    payWindow.document.write(loaderString);

    return dispatch({
        api: {
            data: {
                addPaymentItems,
                dtPeriod,
                idService,
                kdProvider,
                nmEmail,
                nnPhone,
                [payKeysProvider[kdProvider]]: sum,
            },
            query: accountPayActionQueryNames[kdProvider],
            session,
            types: [actionTypes.LS_PAY_ACTION, actionTypes.LS_PAY_ACTION_SUCCESS, actionTypes.LS_PAY_ACTION_FAILURE],
        },
    })
        .then((action) => {
            if (
                !action.error &&
                action.response &&
                (action.response.nmPayLink || action.response.vlLink || action.response.nmLink)
            ) {
                payWindow.location.href = action.response.nmPayLink || action.response.vlLink || action.response.nmLink;
            } else {
                payWindow.close();
            }

            return action;
        })
        .catch(() => payWindow.close());
};

export const paymentVAction = ({nmEmail, paymentInfo, session, smTotal}: Object) => (
    dispatch: any,
): ApiMiddlewareActionType => {
    const payWindow = window.open();

    payWindow.document.write(loaderString);

    return dispatch({
        api: {
            data: {
                nmEmail,
                paymentInfo,
                smTotal,
            },
            query: "PaymentV",
            session,
            types: [
                actionTypes.PAYMENT_V_ACTION,
                actionTypes.PAYMENT_V_ACTION_SUCCESS,
                actionTypes.PAYMENT_V_ACTION_FAILURE,
            ],
        },
    })
        .then((action) => {
            if (!action.error && action.response && action.response.nmLink) {
                payWindow.location.href = action.response.nmLink;
            } else {
                payWindow.close();
            }

            return action;
        })
        .catch(() => payWindow.close());
};

export const paymentEPRAction = (params: Object, session: string) => (dispatch: any): ApiMiddlewareActionType => {
    const payWindow = window.open();

    payWindow.document.write(loaderString);

    return dispatch({
        api: {
            data: params,
            query: "PaymentEPR",
            session,
            types: [actionTypes.DEFAULT_ACTION, actionTypes.DEFAULT_ACTION_SUCCESS, actionTypes.DEFAULT_ACTION_FAILURE],
        },
    })
        .then((action) => {
            if (!action.error && action.response && action.response.nmLink) {
                payWindow.location.href = action.response.nmLink;
            } else {
                payWindow.close();
            }

            return action;
        })
        .catch(() => payWindow.close());
};

const loadPayAddressQueryName = {
    [ALT_KD_PROVIDER]: "AltPayAddress",
    [KSG_KD_PROVIDER]: "KsgPayAddress",
    [ORL_EPD_KD_PROVIDER]: "OrlPayAddress",
    [ORL_KD_PROVIDER]: "OrlPayAddress",
    [SAR_KD_PROVIDER]: "SarPayAddress",
    [TMB_KD_PROVIDER]: "TmbPayAddress",
    [UFA_KD_PROVIDER]: "UfaPayAddress",
    [VLD_KD_PROVIDER]: "VldPayAddress",
    [VLG_KD_PROVIDER]: "VlgPayAddress",
};

export const loadPayAddress = (session: string, kdProvider: number): ApiMiddlewareActionType => ({
    api: {
        query: loadPayAddressQueryName[kdProvider],
        session,
        types: [
            actionTypes.LOAD_PAY_ADDRES_ACTION,
            actionTypes.LOAD_PAY_ADDRES_ACTION_SUCCESS,
            actionTypes.LOAD_PAY_ADDRES_ACTION_FAILURE,
        ],
    },
});

const prepayParamsQueryName = {
    [ALT_KD_PROVIDER]: "AltPrepayParams",
    [KSG_KD_PROVIDER]: "KsgPrepayParams",
    [MES_KD_PROVIDER]: "MesPrepayParams",
    [MOE_KD_PROVIDER]: "MoePrepayParams",
    [ORL_KD_PROVIDER]: "OrlPrepayParams",
    [SAR_KD_PROVIDER]: "SarPrepayParams",
    [TMB_KD_PROVIDER]: "TmbPrepayParams",
    [UFA_KD_PROVIDER]: "UfaPrepayParams",
    [VLD_KD_PROVIDER]: "VldPrepayParams",
    [VLG_KD_PROVIDER]: "VlgPrepayParams",
};

export const loadPrepayAction = (session: string, kdProvider: 1 | 2): ApiMiddlewareActionType => ({
    api: {
        query: prepayParamsQueryName[kdProvider],
        session,
        types: [
            actionTypes.GET_PREPAY_PARAMS,
            actionTypes.GET_PREPAY_PARAMS_SUCCESS,
            actionTypes.GET_PREPAY_PARAMS_FAILURE,
        ],
    },
});

export const multipleLSPayListAction = (session?: string): ApiMiddlewareActionType => ({
    api: {
        list: true,
        query: "MultipleLSPayList",
        session,
        types: [
            actionTypes.MULTIPLE_LS_PAY_LIST_ACTION,
            actionTypes.MULTIPLE_LS_PAY_LIST_ACTION_SUCCESS,
            actionTypes.MULTIPLE_LS_PAY_LIST_ACTION_FAILURE,
        ],
    },
});

export const getMultipleLSPayRestrictionsAction = (session?: string, kdProvider: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            kdProvider,
        },
        query: "GetMultipleLSPayRestrictions",
        session,
        types: [
            actionTypes.GET_MULTIPLE_LS_PAY_RESTRICTIONS_ACTION,
            actionTypes.GET_MULTIPLE_LS_PAY_RESTRICTIONS_ACTION_SUCCESS,
            actionTypes.GET_MULTIPLE_LS_PAY_RESTRICTIONS_ACTION_FAILURE,
        ],
    },
});

export const getPaySumAction = (lsList: LsList, session: string) => (dispatch: any): ApiMiddlewareActionType => {
    Promise.all(
        lsList.map(async (item) => {
            const {sumParams} = item;
            const {kdProvider, vlProvider} = sumParams;
            let balance = 0;

            await dispatch(loadAccountDataAction("currentBalanceByt", {kdProvider, vlProvider}, session)).then(
                (res) => {
                    if (!res.error) {
                        const [{vlBalance}] = res.response;

                        balance = vlBalance;
                    }
                },
            );

            return Promise.resolve({balance, ls: item.ls});
        }),
    ).then((results: Object[]) => {
        results.forEach(({balance, ls}: Object) => {
            dispatch({
                payload: {[ls]: balance},
                type: actionTypes.SET_PAY_ALL_ACCOUNTS_SUM_ACTION,
            });
        });
    });

    return dispatch({type: actionTypes.SET_PAY_ALL_ACCOUNTS_SUM_ACTION_SUCCESS});
};

export const multipleLSPayAddressAction = (session?: string): ApiMiddlewareActionType => ({
    api: {
        query: "MultipleLSPayAddress",
        session,
        types: [
            actionTypes.MULTIPLE_LS_PAY_ADDRESS_ACTION,
            actionTypes.MULTIPLE_LS_PAY_ADDRESS_ACTION_SUCCESS,
            actionTypes.MULTIPLE_LS_PAY_ADDRESS_ACTION_FAILURE,
        ],
    },
});

export const multipleLSPayAction = (data: MultipleLSPayActionDataType, session: string) => (
    dispatch: any,
): ApiMiddlewareActionType => {
    const payWindow = window.open();

    payWindow.document.write(loaderString);

    return dispatch({
        api: {
            data,
            query: "MultipleLSPay",
            session,
            types: [
                actionTypes.MULTIPLE_LS_PAY_ACTION,
                actionTypes.MULTIPLE_LS_PAY_ACTION_SUCCESS,
                actionTypes.MULTIPLE_LS_PAY_ACTION_FAILURE,
            ],
        },
    })
        .then((action) => {
            if (!action.error && action.response && action.response.nmLink) {
                payWindow.location.href = action.response.nmLink;
            } else {
                payWindow.close();
            }

            return action;
        })
        .catch(() => payWindow.close());
};

export const multipleLSPayClearBackUrlAction = () => ({
    type: actionTypes.MULTIPLE_LS_PAY_CLEAR_BACK_URL_RESPONSE_ACTION,
});

export const multipleLSPayOpenBackUrlModalAction = () => ({
    type: actionTypes.MULTIPLE_LS_PAY_OPEN_BACK_URL_MODAL_ACTION,
});

export const multipleLSPayCloseBackUrlModalAction = () => ({
    type: actionTypes.MULTIPLE_LS_PAY_CLOSE_BACK_URL_MODAL_ACTION,
});

export const getFastPayParamsAction = (kdProvider: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            kdProvider,
        },
        query: "GetFastPayParams",
        types: [
            actionTypes.GET_FAST_PAY_PARAMS_ACTION,
            actionTypes.GET_FAST_PAY_PARAMS_ACTION_SUCCESS,
            actionTypes.GET_FAST_PAY_PARAMS_ACTION_FAILURE,
        ],
    },
});

export const startFastPayAction = ({
    dtPeriod,
    idService,
    nmDescrFull,
    nmDescrShort,
    session,
    smAmount,
}: Object): ApiMiddlewareActionType => ({
    api: {
        data: {
            dtPeriod,
            idService,
            nmDescrFull,
            nmDescrShort,
            smAmount,
        },
        query: "StartFastPay",
        session,
        types: [
            actionTypes.START_FAST_PAY_ACTION,
            actionTypes.START_FAST_PAY_ACTION_SUCCESS,
            actionTypes.START_FAST_PAY_ACTION_FAILURE,
        ],
    },
});

export const getFastPayStatusAction = (
    session: string,
    idQrcode: string,
    kdProvider: number,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            idQrcode,
            kdProvider,
        },
        query: "GetFastPayStatus",
        session,
        types: [
            actionTypes.GET_FAST_PAY_STATUS_ACTION,
            actionTypes.GET_FAST_PAY_STATUS_ACTION_SUCCESS,
            actionTypes.GET_FAST_PAY_STATUS_ACTION_FAILURE,
        ],
    },
    payload: {
        skipkdResults: [RESPONSE_RESULT_TEXT_CODE, SBP_PAYMENT_PENDING, SBP_PAYMENT_CANCELLED],
    },
});

export const rejectFastPayAction = (
    session: string,
    idQrcode: string,
    kdProvider: number,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            idQrcode,
            kdProvider,
        },
        query: "RejectFastPay",
        session,
        types: [
            actionTypes.REJECT_FAST_PAY_ACTION,
            actionTypes.REJECT_FAST_PAY_ACTION_SUCCESS,
            actionTypes.REJECT_FAST_PAY_ACTION_FAILURE,
        ],
    },
});

export const multiBonusCheckShowAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        query: "MultiBonusCheckShow",
        session,
        types: [
            actionTypes.MULTIBONUS_CHECK_SHOW_ACTION,
            actionTypes.MULTIBONUS_CHECK_SHOW_ACTION_SUCCESS,
            actionTypes.MULTIBONUS_CHECK_SHOW_ACTION_FAILURE,
        ],
    },
});

export const multiBonusGetBalanceAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        query: "MultiBonusGetBalance",
        session,
        types: [
            actionTypes.MULTIBONUS_GET_BALANCE_ACTION,
            actionTypes.MULTIBONUS_GET_BALANCE_ACTION_SUCCESS,
            actionTypes.MULTIBONUS_GET_BALANCE_ACTION_FAILURE,
        ],
    },
});

export const savePaymentSumToStoreAction = (sum: string) => ({
    payload: {sum},
    type: actionTypes.SAVE_PAYMENT_SUM_TO_STORE_ACTION,
});

export const multiBonusRegProfileAction = (session: string, nnPhone: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            nnPhone,
        },
        query: "MultiBonusRegProfile",
        session,
        types: [
            actionTypes.MULTIBONUS_GET_BALANCE_ACTION,
            actionTypes.MULTIBONUS_GET_BALANCE_ACTION_SUCCESS,
            actionTypes.MULTIBONUS_GET_BALANCE_ACTION_FAILURE,
        ],
    },
});

export const multiBonusConfirmProfileAction = (
    session: string,
    code: string,
    ident: Object,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            code,
            ident: JSON.stringify(ident),
        },
        query: "MultiBonusConfirmProfile",
        session,
        types: [
            actionTypes.MULTIBONUS_CONFIRM_PROFILE_ACTION,
            actionTypes.MULTIBONUS_CONFIRM_PROFILE_ACTION_SUCCESS,
            actionTypes.MULTIBONUS_CONFIRM_PROFILE_ACTION_FAILURE,
        ],
    },
});

export const multiBonusRenewProfileAction = (session: string, ident: Object): ApiMiddlewareActionType => ({
    api: {
        data: {
            ident: JSON.stringify(ident),
        },
        query: "MultiBonusRenewProfile",
        session,
        types: [
            actionTypes.MULTIBONUS_RENEW_PROFILE_ACTION,
            actionTypes.MULTIBONUS_RENEW_PROFILE_ACTION_SUCCESS,
            actionTypes.MULTIBONUS_RENEW_PROFILE_ACTION_FAILURE,
        ],
    },
});

export const multiBonusPaymentCalcAction = (session: string, vlPay: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            vlPay,
        },
        query: "MultiBonusPaymentCalc",
        session,
        types: [
            actionTypes.MULTIBONUS_PAYMENT_CALC_ACTION,
            actionTypes.MULTIBONUS_PAYMENT_CALC_ACTION_SUCCESS,
            actionTypes.MULTIBONUS_PAYMENT_CALC_ACTION_FAILURE,
        ],
    },
});

export const multiBonusRegPaymentAction = (
    session: string,
    idService: number,
    vlPay: number,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
            vlPay,
        },
        query: "MultiBonusRegPayment",
        session,
        types: [
            actionTypes.MULTIBONUS_REG_PAYMENT_ACTION,
            actionTypes.MULTIBONUS_REG_PAYMENT_ACTION_SUCCESS,
            actionTypes.MULTIBONUS_REG_PAYMENT_ACTION_FAILURE,
        ],
    },
});

export const multiBonusConfirmPaymentAction = (
    session: string,
    {codeSms, idPayment, token}: Object,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            codeSms,
            idPayment,
            token,
        },
        query: "MultiBonusConfirmPayment",
        session,
        types: [
            actionTypes.MULTIBONUS_CONFIRM_PAYMENT_ACTION,
            actionTypes.MULTIBONUS_CONFIRM_PAYMENT_ACTION_SUCCESS,
            actionTypes.MULTIBONUS_CONFIRM_PAYMENT_ACTION_FAILURE,
        ],
    },
});

export const multiBonusRenewCodePaymentAction = (session: string, token: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            token,
        },
        query: "MultiBonusRenewCodePayment",
        session,
        types: [
            actionTypes.MULTIBONUS_RENEW_CODE_PAYMENT_ACTION,
            actionTypes.MULTIBONUS_RENEW_CODE_PAYMENT_ACTION_SUCCESS,
            actionTypes.MULTIBONUS_RENEW_CODE_PAYMENT_ACTION_FAILURE,
        ],
    },
});

export const payPromoEventsAction = (session: string, idService: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "PayPromoEvents",
        session,
        types: [
            actionTypes.PAY_PROMO_EVENTS_ACTION,
            actionTypes.PAY_PROMO_EVENTS_ACTION_SUCCESS,
            actionTypes.PAY_PROMO_EVENTS_ACTION_FAILURE,
        ],
    },
});

export const payPromoEventsAltAction = (session: string, idService: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "PayPromoEventsAlt",
        session,
        types: [
            actionTypes.PAY_PROMO_EVENTS_ACTION,
            actionTypes.PAY_PROMO_EVENTS_ACTION_SUCCESS,
            actionTypes.PAY_PROMO_EVENTS_ACTION_FAILURE,
        ],
    },
});

export const payPromoEventSetAction = (
    session: string,
    idPromoEvent: string,
    idService: number,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            idPromoEvent,
            idService,
        },
        query: "PayPromoEventSet",
        session,
        types: [
            actionTypes.PAY_PROMO_EVENT_SET_ACTION,
            actionTypes.PAY_PROMO_EVENT_SET_ACTION_SUCCESS,
            actionTypes.PAY_PROMO_EVENT_SET_ACTION_FAILURE,
        ],
    },
});

export const metersPromoEventsAction = (session: string, idService: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "MetersPromoEvents",
        session,
        types: [
            actionTypes.METERS_PROMO_EVENTS_ACTION,
            actionTypes.METERS_PROMO_EVENTS_ACTION_SUCCESS,
            actionTypes.METERS_PROMO_EVENTS_ACTION_FAILURE,
        ],
    },
});

export const startMoeFastPayAction = ({
    dtPeriod,
    idService,
    nmDescrFull,
    nmDescrShort,
    session,
    smPay,
}: Object): ApiMiddlewareActionType => ({
    api: {
        data: {
            dtPeriod,
            idService,
            nmDescrFull,
            nmDescrShort,
            smPay,
        },
        query: "StartMoeFastPay",
        session,
        types: [
            actionTypes.START_MOE_FAST_PAY_ACTION,
            actionTypes.START_MOE_FAST_PAY_ACTION_SUCCESS,
            actionTypes.START_MOE_FAST_PAY_ACTION_FAILURE,
        ],
    },
});

export const moeGetFastPayStatusAction = (
    session: string,
    qrcId: string,
    idService: number,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
            qrcId,
        },
        query: "MoeGetFastPayStatus",
        session,
        types: [
            actionTypes.GET_FAST_PAY_STATUS_ACTION,
            actionTypes.GET_FAST_PAY_STATUS_ACTION_SUCCESS,
            actionTypes.GET_FAST_PAY_STATUS_ACTION_FAILURE,
        ],
    },
    payload: {
        skipkdResults: [RESPONSE_RESULT_TEXT_CODE, SBP_PAYMENT_PENDING, SBP_PAYMENT_CANCELLED],
    },
});

export const moeRejectFastPayAction = (session: string, qrcId: string, idService: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
            qrcId,
        },
        query: "MoeRejectFastPay",
        session,
        types: [
            actionTypes.REJECT_FAST_PAY_ACTION,
            actionTypes.REJECT_FAST_PAY_ACTION_SUCCESS,
            actionTypes.REJECT_FAST_PAY_ACTION_FAILURE,
        ],
    },
});

export const multiPayPromoEvents = (session: string, kdProvider: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            kdProvider,
        },
        query: "MultiPayPromoEvents",
        session,
        types: [
            actionTypes.MULTI_PROMO_EVENTS_ACTION,
            actionTypes.MULTI_PROMO_EVENTS_ACTION_SUCCESS,
            actionTypes.MULTI_PROMO_EVENTS_ACTION_FAILURE,
        ],
    },
});

export const multiPayPromoEventSet = (session: string, kdProvider: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            kdProvider,
        },
        query: "MultiPayPromoEventSet",
        session,
        types: [
            actionTypes.MULTI_PROMO_EVENT_SET_ACTION,
            actionTypes.MULTI_PROMO_EVENT_SET_ACTION_SUCCESS,
            actionTypes.MULTI_PROMO_EVENT_SET_ACTION_FAILURE,
        ],
    },
});

export const checkLSPayAvailAction = (session: string, idService: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "CheckLSPayAvail",
        session,
        types: [
            actionTypes.CHECK_LS_PAYAVAIL_ACTION,
            actionTypes.CHECK_LS_PAYAVAIL_ACTION_SUCCESS,
            actionTypes.CHECK_LS_PAYAVAIL_ACTION_FAILURE,
        ],
    },
});

export const getLsInsuranceAction = (session: string, idService: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "GetLsInsurance",
        session,
        types: [
            actionTypes.GET_LS_INSURANCE_ACTION,
            actionTypes.GET_LS_INSURANCE_ACTION_SUCCESS,
            actionTypes.GET_LS_INSURANCE_ACTION_FAILURE,
        ],
    },
});

export const checkEprPayAction = (idService: number, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "CheckEprPay",
        session,
        types: [
            actionTypes.CHECK_EPR_PAY_ACTION,
            actionTypes.CHECK_EPR_PAY_ACTION_SUCCESS,
            actionTypes.DEFAULT_ACTION_FAILURE,
        ],
    },
});
