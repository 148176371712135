// @flow
import * as actionTypes from "../../constants/types/ProfileActionTypes";
import * as actionActionTypes from "../../constants/types/ActionTypes";
import * as crmActionTypes from "../../constants/types/CrmActionTypes";
import {createReducer} from "./createReducer";

export type IFrameReducerType = {
    openIframeModal: boolean,
    iFrameLink: string,
    title?: string,
};
const initialState = {
    iFrameLink: "",
    openIframeModal: false,
};

export const iFrameReducer = createReducer(initialState, {
    [actionTypes.ADDR_EDIT_FRAME_ACTION_SUCCESS]: (state, {response}): IFrameReducerType => ({
        iFrameLink: response.nmLink,
        openIframeModal: true,
        title: "Изменение адреса для направления корреспонденции",
    }),
    [actionActionTypes.PIVELEGES_EDIT_FRAME_ACTION_SUCCESS]: (state, {response}): IFrameReducerType => ({
        iFrameLink: response.nmLink,
        openIframeModal: true,
        title: "Оформление, переоформление, закрытие льготы",
    }),
    [actionActionTypes.PIVELEGES_CREATE_FRAME_ACTION_SUCCESS]: (state, {response}): IFrameReducerType => ({
        iFrameLink: response.nmLink,
        openIframeModal: true,
        title: "Оформление, переоформление, закрытие льготы",
    }),
    [actionTypes.CLOSE_IFRAME_MODAL_ACTION]: (state): IFrameReducerType => ({
        ...state,
        iFrameLink: "",
        openIframeModal: false,
    }),
    [crmActionTypes.CRM_GET_QUALITY_CONTROL_FRAME_ACTION_SUCCESS]: (state, {response}): IFrameReducerType => ({
        iFrameLink: response.nmLink,
        openIframeModal: true,
    }),
});
