// @flow
import * as React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import Button from "../../packages/components/Button/Button";

type Props = {
    idNotice: string,
    btnItem: Object,
    bannerAction: (idNotice: string, kdEventActio: string) => void,
};

export class ButtonAction extends React.PureComponent<Props> {
    bannerAction = () => {
        this.props.bannerAction(this.props.idNotice, this.props.btnItem.kdEventAction);
    };

    render() {
        const {btnItem} = this.props;

        return (
            <Grid item>
                <Button border color="secondary" onClick={this.bannerAction} size="sm">
                    {btnItem.nmTitle}
                </Button>
            </Grid>
        );
    }
}

export default ButtonAction;
