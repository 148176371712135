/* eslint-disable max-lines */
// @flow
import * as actionTypes from "../../constants/types/ActionTypes";
import {type ApiMiddlewareActionType, type ApiMiddlewareResponsePromiseType} from "../middlewares/apiMiddleware";
import {RESPONSE_RESULT_TEXT_CODE} from "../../constants/KdResults";

export type BackUrlActionType = (session?: string, type: string, data: ?Object) => ApiMiddlewareResponsePromiseType;

const queryBackUrl = {
    "/alt_back_url_c": "AltBackUrlC",
    "/alt_back_url_d": "AltBackUrlD",
    "/alt_back_url_s": "AltBackUrlS",
    "/back_url_epr": "SetEprPayStatus",
    "/claimbackurl": "CrmClaimFrameRedirect",
    "/complaintbackurl": "CrmClaimFrameRedirect",
    "/energy-contracts": "CrmFrameRedirect",
    "/gpbackurl": "CrmGpRedirect",
    "/load-documents": "CrmFrameRedirect",
    "/lseditbackurl": "CrmLsEditRedirect",
    "/mass_pay_back_url_f": "MassPayBackUrlF",
    "/mass_pay_back_url_s": "MassPayBackUrlS",
    "/orl_back_url_f": "OrlBackUrlF",
    "/orl_back_url_s": "OrlBackUrlS",
    "/payment_v_status_f": "InsuranceBackF",
    "/payment_v_status_s": "InsuranceBackS",
    "/privileges": "PrivilegesRedirect",
    "/profile": "AddrEditRedirect",
    "/sar_back_url_c": "SarBackUrlC",
    "/sar_back_url_d": "SarBackUrlD",
    "/sar_back_url_f": "SarBackUrlF",
    "/sar_back_url_s": "SarBackUrlS",
    "/stisubackurl": "CrmIsuRedirect",
    "/tmb_back_url": "TmbBackUrl",
    "/tmk_back_url_f": "TmkBackUrlF",
    "/tmk_back_url_s": "TmkBackUrlS",
    "/ufa_back_url_f": "UfaBackUrlF",
    "/ufa_back_url_s": "UfaBackUrlS",
    "/vld_back_url": "VldBackUrl",
    "/vlg_back_url_f": "VlgBackUrlF",
    "/vlg_back_url_s": "VlgBackUrlS",
};

const skipKdResultQueryBackUrl = {
    "/mass_pay_back_url_s": true,
};

export const backUrlAction = (session?: string, type: string, data: ?Object): ApiMiddlewareActionType => ({
    api: {
        data,
        query: queryBackUrl[type],
        session,
        types: [actionTypes.BACK_URL_ACTION, actionTypes.BACK_URL_ACTION_SUCCESS, actionTypes.BACK_URL_ACTION_FAILURE],
    },
    payload: {
        skipkdResults: skipKdResultQueryBackUrl[type] ? [RESPONSE_RESULT_TEXT_CODE] : undefined,
        type,
    },
});
