// @flow
import isEmpty from "lodash/isEmpty";
import * as actionTypes from "../../constants/types/ActionTypes";
import * as actionNotificationTypes from "../../constants/types/ActionNotificationTypes";
import {EXTRA_REGISTRATION_CODE} from "../../constants/KdResults";
import type {OpenConfirmEmailOrSMSModalActionParamsType} from "../actions/authAction";
import {getLocaleStorageAction} from "../actions/storageActions/localeStorageAction";
import {setDeviceToken} from "../../utils/browserUtils";
import {createReducer} from "./createReducer";

export type AuthTfaType = {|
    authToken?: string,
    methods: Array<Object>,
    prChange: boolean,
    resendTimeout?: number,
    suopToken?: string,
|};
const initialTfaState: AuthTfaType = {
    authToken: undefined,
    methods: [],
    prChange: false,
    resendTimeout: undefined,
    suopToken: undefined,
};

export type AuthReducerType = {|
    showCaptcha?: boolean,
    token?: string,
    phone?: string,
    idProfile?: string,
    session?: string,
    newToken?: string | null,
    login?: string,
    tokenRestoreSms?: string,
    tokenOneTimePass?: string,
    remember?: boolean,
    noticeRoutine?: boolean,
    confirmEmailOrSMSModal?: OpenConfirmEmailOrSMSModalActionParamsType,
    extraRegistrationFormOpen?: boolean,
    sudirAuthData?: Object,
    sudirProfileData?: Object,
    savedRedirectUrl?: string,
    prMirror?: boolean,
    tfaData?: AuthTfaType,
|};

const initialEmptyState: AuthReducerType = {
    tfaData: initialTfaState,
};

const {
    payload: {value: localStorageState},
} = getLocaleStorageAction("auth");

export const authReducer = createReducer(isEmpty(localStorageState) ? initialEmptyState : localStorageState, {
    [actionTypes.REMOVE_SESSION_ACTION]: (state: AuthReducerType) => ({
        ...state,
        session: "",
    }),
    [actionNotificationTypes.NOTICE_ROUTINE_ACTION_SUCCESS]: (state: AuthReducerType) => ({
        ...state,
        noticeRoutine: true,
    }),
    [actionTypes.CRM_CHANGE_PSW_ACTION_SUCCESS]: (state: AuthReducerType, {response, payload}) => ({
        ...state,
        login: response.nmLogin || payload.login,
        newToken: response.vlToken,
        remember: true,
    }),
    [actionTypes.AUTH_LOGIN_ACTION_SUCCESS]: (state: AuthReducerType, {response, payload}): AuthReducerType => {
        setDeviceToken(response.vlTfaDeviceToken);

        return {
            ...state,
            ...response,
            login: payload.login,
            prMirror: payload.prMirror,
            remember: payload.remember || state.remember,
        };
    },
    [actionTypes.AUTH_LOGIN_ACTION_FAILURE]: (state: AuthReducerType, {response, payload}) => {
        const tfaData = payload.updateTfaState
            ? {
                  ...state.tfaData,
                  authToken: response.vlTfaAuthToken,
                  methods: response.methodTfa || [],
                  prChange: response.prChangeMethodTfa,
              }
            : {...state.tfaData};

        setDeviceToken(response.vlTfaDeviceToken);

        if (payload.oneTimePass) {
            return {
                ...state,
                idProfile: response.idProfile,
                newToken: response.kdResult === EXTRA_REGISTRATION_CODE ? response.newToken : state.newToken,
            };
        }

        if (window.gib && response.kdResult !== EXTRA_REGISTRATION_CODE) {
            window.gib.setAttribute("failedSignInAttempt", payload.login, {encryption: null, persistent: false});
        }

        return {
            ...initialEmptyState,
            idProfile: response.idProfile,
            newToken: response.newToken,
            phone: payload.login,
            prMirror: payload.prMirror,
            remember: payload.remember,
            showCaptcha: Boolean(response.prShowCaptcha),
            sudirAuthData: state.sudirAuthData,
            tfaData,
        };
    },

    [actionTypes.SEND_TFA_ACTION_SUCCESS]: (state: AuthReducerType, {response}) => ({
        ...state,
        tfaData: {
            ...state.tfaData,
            authToken: response.vlTfaAuthToken || state.tfaData?.authToken,
            resendTimeout: response.vlTimeout,
            suopToken: response.nnSuopSession || state.tfaData?.suopToken,
        },
    }),
    [actionTypes.SEND_TFA_ACTION_FAILURE]: (state: AuthReducerType, {response}) => ({
        ...state,
        tfaData: {
            ...state.tfaData,
            authToken: response.vlTfaAuthToken || state.tfaData?.authToken,
            resendTimeout: response.vlTimeout,
            suopToken: response.nnSuopSession || state.tfaData?.suopToken,
        },
    }),

    [actionTypes.RESEND_TFA_ACTION_SUCCESS]: (state: AuthReducerType, {response}) => ({
        ...state,
        tfaData: {
            ...state.tfaData,
            authToken: response?.vlTfaAuthToken,
            resendTimeout: response?.vlTimeout,
            suopToken: response?.nnSuopSession,
        },
    }),

    [actionTypes.AUTH_REGISTRATION_ACTION_SUCCESS]: (state, action) => ({
        ...state,
        idProfile: action.response.idProfile,
        phone: action.payload.NN_PHONE,
    }),
    [actionTypes.GET_LS_REGISTRATION_ELEMENTS_ACTION_SUCCESS]: (state, action) => ({
        ...state,
        idProfile: action.response.idProfile,
        phone: action.payload.NN_PHONE,
    }),
    [actionTypes.AUTH_LOGOUT]: () => ({
        ...initialEmptyState,
    }),
    [actionTypes.SEND_SMS_ONETIME_PSW_ACTION_SUCCESS]: (state, action) => ({
        ...state,
        phone: action.payload.phone,
        tokenOneTimePass: action.response.vlToken || state.tokenOneTimePass,
    }),
    [actionTypes.SEND_SMS_ONETIME_PSW_ACTION_FAILURE]: (state) => ({
        ...state,
        tokenOneTimePass: "",
    }),
    [actionTypes.AUTH_LOGOUT_ACTION_SUCCESS]: () => ({
        ...initialEmptyState,
    }),
    [actionTypes.PHONE_SEND_PSW_RESTORE_SMS_ACTION_SUCCESS]: (state, action) => ({
        ...state,
        tokenRestoreSms: action.response.vlToken || state.tokenRestoreSms,
    }),
    [actionTypes.PHONE_SEND_PSW_RESTORE_SMS_ACTION_FAILURE]: (state) => ({
        ...state,
        tokenRestoreSms: "",
    }),
    [actionTypes.OPEN_EXTRA_REGISTRATION_FORM_ACTION]: (state) => ({
        ...state,
        extraRegistrationFormOpen: true,
    }),
    [actionTypes.CLOSE_EXTRA_REGISTRATION_FORM_ACTION]: (state) => ({
        ...state,
        extraRegistrationFormOpen: false,
    }),
    [actionTypes.OPEN_CONFIRM_EMAIL_OR_SMS_MODAL_ACTION]: (state, {payload}) => ({
        ...state,
        confirmEmailOrSMSModal: payload,
    }),
    [actionTypes.CLOSE_CONFIRM_EMAIL_OR_SMS_MODAL_ACTION]: (state) => ({
        ...state,
        confirmEmailOrSMSModal: {
            ...state.confirmEmailOrSMSModal,
            openModalConfirm: false,
        },
    }),
    [actionTypes.CHECK_SUDIR_PROFILE_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        sudirAuthData: {
            lkkLogin: response.lkkLogin,
            nnAction: response.nnAction,
            sudirProfile: response.sudirProfile,
        },
    }),
    [actionTypes.SUDIR_CHECK_DIFF_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        sudirProfileData: {
            diffResult: response.diffResult,
            vlParam: response.vlParam,
        },
    }),
    [actionTypes.SAVE_REDIRECT_URL_ACTION]: (state: AuthReducerType, {payload}) => ({
        ...state,
        savedRedirectUrl: payload.location,
    }),
});
