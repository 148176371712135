// @flow
import * as React from "react";
import AccessTime from "@material-ui/icons/AccessTime";
import DateRange from "@material-ui/icons/DateRange";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Event from "@material-ui/icons/Event";
import {withStyles} from "@material-ui/core/styles";
import moment from "moment";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import MuiPickersUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";
import DatePicker from "material-ui-pickers/DatePicker";
import {type DateType} from "material-ui-pickers/constants/prop-types";
import TextField from "../../components/Form/TextField";
import {CLIENT_SIDE_DATE_FORMAT, SERVER_SIDE_DATE_FORMAT} from "../../../constants/DateFormat";

const DEFAULT_PLACEHOLDER = "31.12.2018";

const styles = (theme: any) => ({
    primaryLightColor: {
        color: `${theme.palette.primary.light}`,
    },
});

type ConfigType = {
    calculateMinDate: boolean,
    customFormat?: string,
    placeholder?: string,
    prRequired?: boolean,
    withoutPlaceholder?: boolean,
};

export type Props = {
    InputLabelProps: any,
    InputProps: any,

    classes?: Object,
    config?: ConfigType,
    defaultValue?: Date,
    disableFuture?: boolean,
    error?: boolean,
    helperText?: string,
    label?: string,
    maxDate?: DateType,
    meta?: Object,
    minDate?: DateType,
    nmAttrDataType?: string,
    onChange?: (event: SyntheticInputEvent<>) => void,
    style?: Object,

    testID?: string,
};
export class CustomDatePicker extends React.Component<Props> {
    static defaultProps = {
        noRange: false,
    };

    handleChange = (value: any) => {
        const date = value ? value.format(this.props.config?.customFormat || SERVER_SIDE_DATE_FORMAT) : undefined;

        this.props.InputProps.onChange(date);
    };

    render() {
        const {
            InputProps,
            classes = {},
            config,
            error,
            helperText,
            meta,
            testID,
            minDate,
            maxDate,
            style,
            disableFuture = true,
        } = this.props;
        const dateValue = InputProps.value
            ? moment(InputProps.value, this.props.config?.customFormat || SERVER_SIDE_DATE_FORMAT)
            : null;
        const curDate = new Date();

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale="ru">
                <DatePicker
                    InputLabelProps={this.props.InputLabelProps}
                    testID={testID}
                    onFocus={InputProps.onFocus}
                    onBlur={InputProps.onBlur}
                    label={this.props.label}
                    value={dateValue}
                    clearable
                    onChange={this.handleChange}
                    ampm={false}
                    autoSubmit={false}
                    clearLabel="Очистить"
                    cancelLabel="Отменить"
                    dateRangeIcon={<DateRange className={classes.primaryLightColor} />}
                    format={CLIENT_SIDE_DATE_FORMAT}
                    invalidDateMessage="Неверная дата"
                    maxDateMessage="Дата не может быть больше текущей"
                    rightArrowIcon={<KeyboardArrowRight className={classes.primaryLightColor} />}
                    leftArrowIcon={<KeyboardArrowLeft className={classes.primaryLightColor} />}
                    keyboard
                    keyboardIcon={<Event className={classes.primaryLightColor} />}
                    okLabel="Принять"
                    showTabs
                    TextFieldComponent={TextField}
                    timeIcon={AccessTime}
                    placeholder={config?.withoutPlaceholder ? null : config?.placeholder || DEFAULT_PLACEHOLDER}
                    disableFuture={disableFuture}
                    disableOpenOnEnter
                    meta={meta}
                    mask={(currentValue) =>
                        currentValue ? [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/] : []
                    }
                    InputProps={{name: InputProps.name}}
                    error={error}
                    required={config && config.prRequired}
                    helperText={helperText}
                    minDate={
                        config?.calculateMinDate
                            ? curDate.setFullYear(curDate.getFullYear() - Number(minDate))
                            : minDate
                    }
                    maxDate={maxDate}
                    style={style}
                />
            </MuiPickersUtilsProvider>
        );
    }
}

export default withStyles(styles)(CustomDatePicker);
