// @flow
import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Tooltip from "@material-ui/core/Tooltip";
import Help from "@material-ui/icons/Help";
import InputAdornment from "@material-ui/core/InputAdornment";

type Props = {
    tipText?: string,
    nmDomain?: string,
    classes?: Object,
    noSideBtn?: boolean,
    handleClickShowPassword: () => void,
    type?: string,
};

export const renderTips = ({classes = {}, handleClickShowPassword, nmDomain, noSideBtn, tipText, type}: Props) => {
    const tips = [];

    switch (nmDomain) {
        case "PASSWORD_DOMAIN":
            if (!noSideBtn) {
                tips.push(
                    <IconButton
                        key="passwordTip"
                        onClick={handleClickShowPassword}
                        tabIndex={-1}
                        classes={{root: classes.rootIconBtn}}
                        centerRipple={true}
                    >
                        {type === "text" ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                    </IconButton>,
                );
            }

            break;
        default:
            break;
    }

    if (tipText) {
        tips.push(
            <Tooltip key="tip" title={tipText} enterTouchDelay={0} placement="bottom-end">
                <Help color="primary" />
            </Tooltip>,
        );
    }

    if (tips.length) {
        return <InputAdornment position="end">{tips}</InputAdornment>;
    }

    return null;
};
