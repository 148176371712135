// @flow
import React from "react";
import mesLogoWhite from "../../../images/providers/mes/logo/MesLogoWhite.svg";
import {SMALL_ICON_SIZE} from "../../../constants/iconConstants";

type Props = {
    fontSize?: number,
};

const MesLogoWhiteIcon = ({fontSize}: Props) => <img height={fontSize} width={fontSize} src={mesLogoWhite} alt="" />;

MesLogoWhiteIcon.defaultProps = {
    fontSize: SMALL_ICON_SIZE,
};

export default MesLogoWhiteIcon;
