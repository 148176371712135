// @flow
import * as React from "react";
import {Field} from "react-final-form";
import {get} from "lodash/object";
import {MenuItem} from "@material-ui/core";
import TextField from "../../../components/Form/TextField";
import {required} from "../../../validations/baseValidations";

type Props = {
    name: string,
    prRequired?: boolean,
    margin?: "none" | "dense" | "normal",
    content?: Array<{
        vlContent: string,
        vlContentExtra: Array<Object>,
    }>,
    staticContent?: string,
    onChange?: (event: SyntheticInputEvent<>) => void,
    onClick?: (event: SyntheticInputEvent<>) => void,
    classes?: Object,
    config?: Object,
};
export const ListElement = (props: Props) => {
    const {name, content, prRequired} = props;
    const listItems = get(content, "0.vlContentExtra.elements");

    return (
        <Field
            name={name}
            validate={prRequired ? required : undefined}
            render={({input, meta}) => (
                <TextField
                    label={get(content, "0.vlContent")}
                    select
                    meta={meta}
                    InputProps={{
                        ...input,
                        onFocus: (event: any) => {
                            input.onFocus(event);
                        },
                    }}
                    value={input.value}
                >
                    {listItems.map(({order, value}) => (
                        <MenuItem value={order} key={order}>
                            {value}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        />
    );
};
