// @flow
import {combineReducers} from "redux";
import {connectRouter, type RouterState, type ConnectedRouterProps} from "connected-react-router";
import {metadataReducer} from "../../providers/example/redux/reducers/metadataReducer";
import {dataReducer} from "../../providers/example/redux/reducers/dataReducer";
import {type BaseDataReducerType} from "../../providers/example/redux/reducers/baseReducers";
import {
    modalToogleReducer,
    type ModalToogleReducerType,
} from "../../providers/example/redux/reducers/modalToogleReducer";
import {
    transmitDataReducer,
    type TransmitDataReducerType,
} from "../../providers/example/redux/reducers/transmitDataReducer";
import {builderExampleReducer} from "../../providers/example/redux/reducers/builderReducer";
import {fetchingReducers, type FetchingReducersType} from "../../providers/example/redux/reducers/fetchingReducer";
import {banerReducer} from "./banerReducer";
import {accountReducer} from "./accountReducer";
import {builderReducer, type BuilderReducerType} from "./builderReducer";
import {authReducer, type AuthReducerType} from "./authReducer";
import {sideBarReducer, type SideBarReducerType} from "./sideBarReducer";
import {configReducer, type ConfigReducerType} from "./configReducer";
import {systemErrorReducer, type SystemErrorReducerType} from "./systemErrorReducer";
import {builderAccountReducer, type BuilderAccountReducerType} from "./builderAccountReducer";
import {mesReducer, type MesReducerType} from "./mesReducer";
import {fetchingReducer, type FetchingReducerType} from "./fethingReducer";
import {profileReducer, type ProfileReducerType} from "./profileReducer";
import {faqReducer, type FaqReducerType} from "./faqReducer";
import {applicationFormReducer, type ApplicationFormReducerType} from "./applicationFormReducer";
import {moeReducer, type MoeReducerType} from "./moeReduser";
import {crmChangePswReducer, type CrmChangePswReducerType} from "./crmChangePswReducer";
import {notificationReducer, type NotificationReducerType} from "./notificationReducer";
import {servicesReducer, type ServicesReducerType} from "./servicesReducer";
import {applicationReducer, type ApplicationReducerType} from "./applicationReducer";
import {accountTmkReducer, type AccountTmkReducerType} from "./accountTmkReducer";
import {accountOrlReducer, type AccountOrlReducerType} from "./accountOrlReducer";
import {iFrameReducer, type IFrameReducerType} from "./iFrameReducer";
import {onboardingReducer, type OnboardingReducerType} from "./onboardingReducer";
import {adsReducer, type AdsReducerType} from "./adsReducer";
import {energyContractsReducer, type EnergyContractsReducerType} from "./energyContractsReducer";

export type StoreType = {|
    account: any,
    accountTmk: AccountTmkReducerType,
    ads: AdsReducerType,
    application: ApplicationReducerType,
    applicationForm: ApplicationFormReducerType,
    auth: AuthReducerType,
    baner: any,
    builder: BuilderReducerType,
    builderAccount: BuilderAccountReducerType,
    config: ConfigReducerType,
    crmChangePsw: CrmChangePswReducerType,
    energyContracts: EnergyContractsReducerType,
    example?: {
        data: BaseDataReducerType,
        elements: BaseDataReducerType,
        metadata: BaseDataReducerType,
        modalToogle: ModalToogleReducerType,
        transmitData: TransmitDataReducerType,
    },
    faq: FaqReducerType,
    fetching: FetchingReducerType,
    fetchingExample?: FetchingReducersType,
    iFrame: IFrameReducerType,
    mes: MesReducerType,
    moe: MoeReducerType,
    notification: NotificationReducerType,
    onboarding: OnboardingReducerType,
    orlEpd: AccountOrlReducerType,
    profile: ProfileReducerType,
    router: RouterState,
    services: ServicesReducerType,
    sideBar: SideBarReducerType,
    systemError: SystemErrorReducerType,
|};
const reducers: Object = {
    account: accountReducer,
    accountTmk: accountTmkReducer,
    ads: adsReducer,
    application: applicationReducer,
    applicationForm: applicationFormReducer,
    auth: authReducer,
    baner: banerReducer,
    builder: builderReducer,
    builderAccount: builderAccountReducer,
    config: configReducer,
    crmChangePsw: crmChangePswReducer,
    energyContracts: energyContractsReducer,
    faq: faqReducer,
    fetching: fetchingReducer,
    iFrame: iFrameReducer,
    mes: mesReducer,
    moe: moeReducer,
    notification: notificationReducer,
    onboarding: onboardingReducer,
    orlEpd: accountOrlReducer,
    profile: profileReducer,
    services: servicesReducer,
    sideBar: sideBarReducer,
    systemError: systemErrorReducer,
};

if (process.env.REACT_APP_PROVIDER_EXAMPLE === "on") {
    reducers.example = combineReducers({
        data: dataReducer,
        elements: builderExampleReducer,
        metadata: metadataReducer,
        modalToogle: modalToogleReducer,
        transmitData: transmitDataReducer,
    });
    reducers.fetchingExample = fetchingReducers;
}
export default (history: ConnectedRouterProps) =>
    combineReducers<Object, Object>({
        ...reducers,
        router: connectRouter(history),
    });
