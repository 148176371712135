// @flow
import moment from "moment";
import {periodKey} from "./Application";
import {getPeriodTypes} from "./types/PeriodTypes";
import type {AccountDetailReducerType, AccountReducerType} from "./types/ReducerTypes";
import {LAST_MONTH_NUMBER} from "./DateTime";
import {MES_KD_PROVIDER} from "./KdProvider";

const AVAIL_ACC_DROP_DOWN_LIST_PROVIDERS = {
    [MES_KD_PROVIDER]: "mes",
};

export const defaultPeriod = {dtEn: getPeriodTypes["3m"].dtEn, dtSt: getPeriodTypes["3m"].dtSt, key: "3m"};
export const defaultNotificationPeriod = {
    dtEn: getPeriodTypes.lastMonth.dtEn,
    dtSt: getPeriodTypes.lastMonth.dtSt,
    key: "lastMonth",
};
export const getInitialPeriod = () => {
    const periodStr = localStorage.getItem(periodKey);

    if (periodStr) {
        return JSON.parse(periodStr);
    }

    return defaultPeriod;
};
export const defaultPeriodYear = {
    dtEn: moment([Number(moment().format("YYYY")), LAST_MONTH_NUMBER, 1])
        .endOf("month")
        .format(),
    dtSt: moment([Number(moment().format("YYYY")), 0, 1]).format(),
    key: moment().format("YYYY"),
};
export const defaultStatiscticsMes = {
    configFields: [],
    statistics: [],
};
export const getAccountsDropDownList = (data: Array<Object>): Array<Object> => {
    const resultArray = data
        .map((account) => ({
            kdProvider: account.kdProvider,
            label: `${account.data.nmStreet} (${account.data.nnLsDisp})`,
            nmValue: `${account.data.nmStreet} (${account.data.nnLsDisp})`,
            nnCode: account.idService,
            value: account.idService,
        }))
        .filter((lsData: Object) => Boolean(AVAIL_ACC_DROP_DOWN_LIST_PROVIDERS[lsData.kdProvider]));

    return resultArray;
};
export const accountDetailInitialState: AccountDetailReducerType = {
    abnInfoTmk: [],
    accountDetailPrintPeriod: defaultPeriod,
    actualSettingsAccrualsSubscriber: {},
    allEventsMesSortedData: [],
    allEventsUfaSortedData: [],
    common: [],
    consumptionRanges: [],
    contract: [],
    currentBalance: [],
    currentBalanceByt: [],
    currentBalanceUfa: [],
    equipment: [],
    equipmentContract: [],
    extraVlProviders: [],
    generalInfoSubscriber: [],
    historyCounter: [],
    historyIndicationCounterTmk: {},
    indicationCounter: [],
    indications: [],
    indicationsCom: [],
    insuranceData: {},
    invoice: [],
    isCommunal: 0,
    lsConfirm: {},
    lsIndication: {},
    lsInfo: [],
    lsInfoSubscriber: [],
    mapMarkers: [],
    mapMarkersAlt: [],
    mapMarkersKsg: [],
    mapMarkersMes: [],
    mapMarkersMoe: [],
    mapMarkersOrl: [],
    mapMarkersSar: [],
    mapMarkersTmb: [],
    mapMarkersUfa: [],
    mapMarkersVld: [],
    mapMarkersVlg: [],
    meters: [],
    metersHistory: [],
    minimalFunctions: {
        idMeter: undefined,
        journal: {
            data: [],
            fetching: false,
            link: undefined,
            notice: undefined,
        },
        loaded: false,
        measurings: {
            groups: {
                data: [],
                fetching: false,
                loaded: false,
            },
        },
        nmSchema: undefined,
        nnMeter: undefined,
        prIsu: false,
        prTariffIsu: false,
        readings: {
            data: [],
            fetching: false,
            link: undefined,
            notice: undefined,
        },
    },
    notificationPeriod: defaultNotificationPeriod,
    paymentDoc: [],
    pays: [],
    paysTmk: {},
    paysUfa: {},
    period: getInitialPeriod(),
    periodYears: defaultPeriodYear,
    privileges: [],
    privilegesAvail: false,
    privilegesHistory: [],
    requisites: {
        complaintPowerSupply: {
            available: false,
            hintText: undefined,
        },
        prAvail: {},
    },
    statistics: [],
    statisticsMes: defaultStatiscticsMes,
    statisticsUfa: defaultStatiscticsMes,
    tariffHistory: [],
    tiedServiceProvider: [],
    viewBalanceTmk: [],
    viewInfoFormedAccounts: [],
    viewInfoFormedAccountsDetails: {},
    viewInfoPaymentReceived: {},
};
export const fetchingAccountDetailInitialState: Object = {
    actualSettingsAccrualsSubscriber: {},
    allEventsMes: false,
    common: false,
    contract: false,
    currentBalance: false,
    currentBalanceByt: false,
    equipment: false,
    equipmentContract: {},
    historyIndicationCounterTmk: {},
    indicationCounter: false,
    indications: {},
    indicationsCom: false,
    indicationsMes: false,
    indicationsTmk: {},
    indicationsUfa: false,
    lsInfo: false,
    paymentDoc: false,
    pays: false,
    paysTmk: {},
    paysUfa: false,
    statistics: false,
    tariffHistory: false,
    transferIndications: false,
    viewInfoFormedAccountsDetails: {},
    viewInfoPaymentReceived: {},
};
export const initialState: AccountReducerType = {
    accountDetail: accountDetailInitialState,
    accountGroups: [],
    accountsByAddress: {},
    accountsByIdService: {},
    accountsDropDownList: [],
    accountsList: [],
    controlQuestion: {
        idService: 0,
        kdLsImg: 0,
        lsElements: null,
        questions: [],
        response: {},
        showCapthe: false,
        showModal: false,
        values: {},
    },
    fetchingAccountDetail: fetchingAccountDetailInitialState,
    fetchingAccountLogout: false,
    fetchingAccountsList: false,
    fetchingIndicatorsCorrData: {},
    fetchingIndicatorsData: {},
    idService: "",
    indicatorIsFloat: {},
    indicatorsCorrData: {},
    indicatorsData: {},
    payAll: {
        backUrlResponse: {},
        counters: {},
        email: "",
        lsList: [],
        restrictions: {},
    },
    prEpr: false,
    prSbp: false,
    prepay: {
        nmPrepayTemplate: "",
        vlPrepay: "",
    },
    providerList: [],
    prpayepd: false,
    scheludedInspectionBanerInfo: "",
    statistic: {
        dtEn: null,
        fetchingDetailCounterStatistic: false,
        jsSrc: null,
        nnFrame: 0,
        prRobotronAvail: false,
        prUgreiAvail: false,
    },
    visibleTabs: [],
};
