// @flow
import * as types from "../../constants/types/ActionOrlEpdProviderTypes";
import {createReducer} from "./createReducer";

export type AccountOrlReducerType = {
    pay: {
        payAvail: boolean,
        fetching: boolean,
        payAddress: {
            nmEmail: string,
            nnPhone: string,
        },
    },
};
const initialState: AccountOrlReducerType = {
    pay: {
        fetching: false,
        payAddress: {
            nmEmail: "",
            nnPhone: "",
        },
        payAvail: true,
    },
};

export const accountOrlReducer = createReducer(initialState, {
    [types.ORL_EPD_PAY_ADDRESS_ACTION]: (state) => ({
        ...state,
        pay: {
            ...state.pay,
            fetching: true,
        },
    }),
    [types.ORL_EPD_PAY_ADDRESS_ACTION_SUCCESS]: (state, action) => ({
        ...state,
        pay: {
            ...state.pay,
            fetching: false,
            payAddress: action.response,
        },
    }),
    [types.ORL_EPD_PAY_ADDRESS_ACTION_FAILURE]: (state) => ({
        ...state,
        pay: {
            ...state.pay,
            fetching: false,
            payAddress: [],
        },
    }),
});
