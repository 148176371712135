// @flow
import * as React from "react";
import SliderBtns from "../../../components/SliderBtns/SliderBtns";
import {getBase64ImgSrc} from "../../../../utils/imageUtils";
// TODO: динамичность стилей, delay,
type VlContentExtra = {
    mode: number,
    title: string,
    onView?: string,
    onClick?: string,
    statistic?: boolean,
    delay?: number,
};

type ContentItem = {
    imgsrc: string,
    nnOrder: number,
    nmLink?: string,
    vlContent: string,
    vlContentB?: string,
    vlContentExtra: VlContentExtra,
};

type Content = Array<ContentItem>;

type Props = {
    content: Content,
    CustomComponent?: () => React.Node,
    direction?: "row" | "column",
    fontSize?: 18 | 16 | 22,
    innerImage?: boolean,
    list?: boolean,
    margin?: "none" | "dense" | "normal",
    name?: string,
    nmFileExtension: string,
    required?: boolean,
    spacing?: 8 | 16,
    testID?: string,
    textColor?: "white",
    underline?: "solid" | "dashed" | "native" | "none",
    valueDefault?: any,
    zeroMinHeight?: boolean,
    zeroPadding?: boolean,
};

const SliderElement = (props: Props) => {
    const {content, nmFileExtension} = props;
    const [currentElement, setCurrentElement] = React.useState(content[0]);
    const [currentElementIndex, setCurrentElementIndex] = React.useState(0);

    React.useEffect(() => {
        setCurrentElement(content[currentElementIndex]);
    }, [content, currentElementIndex]);

    const handleClick = () => {
        window.open(currentElement.nmLink);
    };

    const handleSliderBtn = (type: string) => {
        const types = {
            back: currentElementIndex - 1,
            forward: currentElementIndex + 1,
        };

        setCurrentElementIndex(types[type]);
    };

    return (
        <React.Fragment>
            <div onClick={handleClick}>
                <img src={currentElement.imgsrc || getBase64ImgSrc([currentElement], nmFileExtension)} alt="" />
            </div>
            <SliderBtns
                onBtnClick={handleSliderBtn}
                elementIndex={currentElementIndex}
                contentLength={content.length}
                mode={currentElement.vlContentExtra.mode}
            />
        </React.Fragment>
    );
};

export default SliderElement;
