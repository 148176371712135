// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import {NavLink} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import AddAccountIcon from "../../packages/components/Icons/AddAccountIcon";
import Typography from "../../packages/components/Typography/Typography";
import Button from "../../packages/components/Button/Button";
import type {themeTypes} from "../../themeStyle";

const styles = (theme: themeTypes) => ({
    icon: {
        display: "block",
    },
    linkStyle: {
        minHeight: 46,
        padding: "0px 16px",
    },
    sidebarSeparator: {
        borderBottom: `1px solid ${theme.palette.grey.sidebarSeparator}`,
        height: 16,
    },
});

export type Props = {
    classes?: {
        [$Keys<$Call<typeof styles, any>>]: string,
    },
    handelCloseSideBar: () => void,
};

export const SideBarAddAccount = (props: Props) => {
    const {classes = {}, handelCloseSideBar} = props;

    return (
        <React.Fragment>
            <ListItem component={NavLink} to={"/account-add"} testID="sideBarAddAccount" className={classes.linkStyle}>
                <Button
                    capitalize
                    border
                    size="full"
                    color="transparent"
                    textColor="primary-light"
                    padding={10}
                    onClick={handelCloseSideBar}
                    id="id2206"
                >
                    <AddAccountIcon fontSize={23} className={classes.icon} />
                    <Typography
                        textColor="primary-light"
                        display="inline"
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={1}
                        horizontalPadding={8}
                    >
                        Добавить лицевой счет
                    </Typography>
                </Button>
            </ListItem>
            <div className={classes.sidebarSeparator} />
        </React.Fragment>
    );
};

export default withStyles(styles)(SideBarAddAccount);
