// @flow
const styles = () => ({
    zero: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
    },
    zeroBtn: {
        "&:hover": {
            cursor: "pointer",
            opacity: 1,
        },
        color: "black",
        opacity: 0.5,
    },
});

export {styles};
