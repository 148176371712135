/* eslint-disable max-len */
// @flow
import moment from "moment";
import trim from "lodash/trim";
import get from "lodash/get";
import isArray from "lodash/isArray";
import {unformatNumber} from "../../utils/numberUtils";

const phoneRegexp = /^[8]{1}[0-9]{10}$/;
const phoneStartingWith7Regexp = /^[9]{1}[0-9]{9}$/;
const passwordRegexp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
const emailRegexp = new RegExp(
    "(?!.*([\\.])\\1)^([A-Za-z0-9]+([A-Za-z0-9\\-_\\.]+[A-Za-z0-9])*|[А-Яа-яЁё0-9]+([А-Яа-яЁё0-9\\-_\\.]+[А-Яа-яЁё0-9])*)@(([A-Za-z0-9]+([A-Za-z0-9\\-_]+[A-Za-z0-9])*\\.)+|([А-Яа-яЁё0-9]+([А-Яа-яЁё0-9\\-_]+[А-Яа-яЁё0-9])*\\.)+)+(([A-Za-z0-9]+[A-Za-z0-9\\-_]*[A-Za-z0-9]+)|([А-Яа-яЁё0-9]+[А-Яа-яЁё0-9\\-_]*[А-Яа-яЁё0-9]+))$",
);
const acceptedCheckArray = ["on", "yes", 1, "1", true];

export const required = (value: any) => {
    if (isArray(value)) {
        return value.filter((item) => item !== null && item !== undefined).length === 0
            ? "Обязательно для заполнения"
            : undefined;
    }

    return value === undefined || value === null || value === "" ? "Обязательно для заполнения" : undefined;
};

export const accepted = (value: any) =>
    acceptedCheckArray.indexOf(value) === -1 ? "Обязательно для заполнения" : undefined;

export const regexpValidate = (regString: string, errorMessage: string = "Неверный формат") => {
    const reg = new RegExp(regString);

    return (value: string) => (reg.test(trim(value)) || value === "" || value === undefined ? undefined : errorMessage);
};
export const maskRegexpValidate = (regString: string, errorMessage?: string) => {
    const reg = new RegExp(regString);

    return (value: string) => {
        let result = value;

        result = unformatNumber(String(result));

        return reg.test(String(result)) || result === "" || result === undefined ? undefined : errorMessage;
    };
};
export const fieldSizeValidate = (nnFieldSize: number) => (value?: string) =>
    !value || value.length <= nnFieldSize ? undefined : `Поле должно содержать не более ${nnFieldSize} символов`;
export const phoneRequired = (value: string) =>
    phoneRegexp.test(value) ? undefined : "Введен некорректный номер телефона";
export const phoneStartingWith7 = (value?: string) => {
    const missing = value === undefined || value === null;
    const regExtResult = value ? phoneStartingWith7Regexp.test(value) : undefined;

    return missing || regExtResult ? undefined : "Некорректный номер телефона";
};
export const checkPhone = (value: string) =>
    !value || phoneRegexp.test(value) ? undefined : "Введен некорректный номер телефона";
export const maskPhoneRequired = (value: string) =>
    phoneRegexp.test(value.replace(/\D+/g, "")) ? undefined : "Введен некорректный номер телефона";
export const emailRequired = (value: string) => (emailRegexp.test(value) ? undefined : "Введен некорректный email");
export const checkEmail = (value: string) =>
    !value || emailRegexp.test(value) ? undefined : "Введен некорректный email";
export const passwordRequired = (value: string) =>
    passwordRegexp.test(value) ? undefined : "Введен некорректный пароль";
export const passwordRepeat = (value: string, otherValues: Object) => {
    if (otherValues && (otherValues.PSW || otherValues.NEW_PSW)) {
        return value === (otherValues.PSW || otherValues.NEW_PSW) ? undefined : "Пароль не совпадает";
    }

    return undefined;
};

export const valueRepeat = (nmColumn: string) => (value: string, otherValues: Object) =>
    get(otherValues, nmColumn, []).filter((item) => item === value).length > 1
        ? "Совпадающие значения. Введите другое значение."
        : undefined;

export const dateNowValidate = (value: string) => {
    if (value === undefined) {
        return undefined;
    }

    return moment().isSameOrAfter(moment(value, "DD.MM.YYYY")) ? undefined : "Дата должна быть меньше текущей";
};

export const uploadFileGroupValidator = (fieldData?: Array<Object>) => (value: Array<any>) => {
    let error = false;
    const errors = {};

    fieldData?.forEach(({idDocType, prRequired, vlCount, nmColumn}) => {
        const recordsByidDocType =
            value === undefined ? 0 : value.filter((valueItem) => idDocType === valueItem.idDocType).length;
        const requiredCheck = prRequired && recordsByidDocType === 0;
        const vlCountCheck = vlCount !== null && vlCount > 0 && recordsByidDocType < vlCount;

        if (requiredCheck || vlCountCheck) {
            error = true;

            errors[nmColumn] = "error";
        }
    });

    if (error) {
        return errors;
    }

    return undefined;
};

export const composeValidators = (...validators: Array<Function>) => (value: string, otherValues: Object) =>
    validators.reduce((error, validator) => error || validator(value, otherValues), undefined);

export const emailPhoneValidate = (value: string) => {
    if (!value) {
        return "Введен некорректный email или номер телефона.";
    }

    if (emailRegexp.test(value)) {
        return undefined;
    }

    const stripped = value.replace(/[().\-+ ]/g, "");

    if (stripped === "" || /[^\d+]/.test(stripped)) {
        return "Введен некорректный email или номер телефона.";

        // eslint-disable-next-line no-magic-numbers
    } else if (stripped.length < 10 || stripped.length > 11) {
        return "Номер телефона содержит неверное количество символов.";
    }

    return undefined;
};
