// @flow
import * as React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Notifications from "@material-ui/icons/Notifications";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import cn from "classnames";
import {type RouterHistory, type Location} from "react-router-dom";
import {type StoreType} from "../../../redux/reducers";
import {type ApiMiddlewareResponsePromiseType} from "../../../redux/middlewares/apiMiddleware";
import {DialogCloseIcon} from "../../../packages/components/Dialog/DialogCloseIcon";
import {DialogTitle} from "../../../packages/components/Dialog/DialogTitle";
import {DialogContent} from "../../../packages/components/Dialog/DialogContent";
import EmptyNotifications from "../../Notificatin/EmptyNotifications";
import {CriticalNoticeModalBodyRow} from "../../Notificatin/CriticalNoticeModalBodyRow";
import AuthExtraRegistrationForm from "../../auth/AuthExtraRegistrationForm";
import {
    type OpenConfirmEmailOrSMSModalActionType,
    type ExtraRegistrationProfileEditActionType,
    type AuthLoginActionType,
} from "../../../redux/actions/authAction";
import {type BuilderGetAttributesType, type BuilderGetElementsType} from "../../../redux/actions/builderAction";
import {styles} from "./styles";

type Props = {
    accountsList: Array<Object>,
    criticalNotice: Array<Object>,
    history: RouterHistory,
    isCriticalNoticeModalOpen: boolean,
    session: string,
    classes?: Object,
    onOpenNotifications: () => void,
    onCloseNotifications: () => void,
    setNoticeReaded: (idNotice: string, session?: string) => ApiMiddlewareResponsePromiseType,
    setLsSubscrEmail: (idService: string, session?: string) => ApiMiddlewareResponsePromiseType,
    userSetLsSubscrbyEventAction: (idService: string, session?: string) => ApiMiddlewareResponsePromiseType,
    getLsListNoticeStatusAction: (session: string) => ApiMiddlewareResponsePromiseType,
    auth: $PropertyType<StoreType, "auth">,
    config: $PropertyType<StoreType, "config">,
    extraRegistrationBuilder: $PropertyType<$PropertyType<StoreType, "builder">, "registration">,
    extraRegistrationProfileEditAction: ExtraRegistrationProfileEditActionType,
    closeExtraRegistrationFormAction: () => void,
    openExtraRegistrationFormAction: () => void,
    getBuilderElementsAction: BuilderGetElementsType,
    openConfirmEmailOrSMSModalAction: OpenConfirmEmailOrSMSModalActionType,
    getExtraRegistrationAttributesAction: BuilderGetAttributesType,
    authLoginAction: AuthLoginActionType,
    location: Location,
};

const MAX_NOTIFICATIONS_COUNT = 100;

const TopMenuNotifications = ({
    auth,
    classes = {},
    criticalNotice,
    onOpenNotifications,
    history,
    onCloseNotifications,
    setLsSubscrEmail,
    setNoticeReaded,
    getLsListNoticeStatusAction,
    accountsList,
    session,
    isCriticalNoticeModalOpen,
    ...props
}: Props) => {
    const btnRef = React.useRef<HTMLElement | null>(null);

    const handleRead = async (idNotice: string) => {
        if (!auth.prMirror) {
            await setNoticeReaded(idNotice, session);
            getLsListNoticeStatusAction(session);
        }
    };

    const handleOpen = () => {
        onOpenNotifications();
    };

    const handleClose = () => {
        onCloseNotifications();
    };

    const renderExtraRegistrationForm = () => (
        <AuthExtraRegistrationForm
            mode="notification"
            settings={props.config.settings}
            authLoginAction={props.authLoginAction}
            location={props.location}
            extraRegistrationProfileEditAction={props.extraRegistrationProfileEditAction}
            auth={auth}
            closeExtraRegistrationFormAction={props.closeExtraRegistrationFormAction}
            getExtraRegistrationAttributesAction={props.getExtraRegistrationAttributesAction}
            getBuilderElementsAction={props.getBuilderElementsAction}
            extraRegistrationBuilder={props.extraRegistrationBuilder}
            onRedirect={history.push}
            openConfirmEmailOrSMSModalAction={props.openConfirmEmailOrSMSModalAction}
        />
    );

    return (
        <>
            <Grid className={classes.iconContainer} container alignItems="center">
                <Grid item className={classes.item}>
                    <ButtonBase onClick={handleOpen} className={classes.iconBtn} buttonRef={btnRef}>
                        <Badge
                            badgeContent={
                                criticalNotice.length < MAX_NOTIFICATIONS_COUNT ? criticalNotice.length : "99"
                            }
                            color="error"
                            classes={{
                                badge: cn(classes.badge, {
                                    [classes.hideBage]: criticalNotice.length === 0,
                                }),
                            }}
                        >
                            <Notifications className={classes.icon} />
                        </Badge>
                    </ButtonBase>
                    <Popover
                        anchorEl={btnRef.current}
                        open={isCriticalNoticeModalOpen}
                        classes={{
                            paper: classes.popoverPaper,
                        }}
                        ModalClasses={{
                            root: classes.popoverModal,
                        }}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                        }}
                        transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                        }}
                        onClose={handleClose}
                        disableAutoFocus
                        disableEnforceFocus
                    >
                        <DialogCloseIcon onClick={handleClose} className={classes.closeIcon} />
                        <DialogTitle disableTypography>Уведомления</DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            {criticalNotice.length === 0 ? (
                                <EmptyNotifications />
                            ) : (
                                criticalNotice.map<React.Node>((noticeItem, noticeIndex) => (
                                    <CriticalNoticeModalBodyRow
                                        auth={auth}
                                        history={history}
                                        key={noticeIndex}
                                        noticeItem={noticeItem}
                                        accountsList={accountsList}
                                        onCancel={onCloseNotifications}
                                        onRead={handleRead}
                                        setLsSubscrEmail={setLsSubscrEmail}
                                        session={session}
                                        openExtraRegistrationFormAction={props.openExtraRegistrationFormAction}
                                        userSetLsSubscrbyEventAction={props.userSetLsSubscrbyEventAction}
                                    />
                                ))
                            )}
                        </DialogContent>
                    </Popover>
                </Grid>
            </Grid>
            {auth.extraRegistrationFormOpen ? renderExtraRegistrationForm() : null}
        </>
    );
};

export default withStyles(styles)(TopMenuNotifications);
