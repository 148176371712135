// @flow
import {withStyles} from "@material-ui/core/styles";
import * as React from "react";
import Button from "../Button/Button";

const styles = (theme: any) => ({
    buttonContent: {
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    wizardButtonsContent: {
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
        },
    },
});

export type WizardButtonsProps = {
    idNextBtn?: string,
    disabled?: boolean,
    isLastPage: boolean,
    lastStepName?: string,
    nextStepName?: string,
    nextStepBtnType?: string,
    prevStepName?: string,
    showPrevButton?: boolean,
    onLastStep: (event: SyntheticEvent<>) => any,
    onNext: (event?: SyntheticEvent<>) => any,
    onPrev: (event?: SyntheticEvent<>) => any,
    testIDNextBtn?: string,
    testIDPrevBtn?: string,
    pageNumber: number,
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    children?: React.Node,
};

export const WizardButtons = (props: WizardButtonsProps) => {
    const {
        disabled,
        isLastPage,
        lastStepName,
        onNext,
        onPrev,
        nextStepName,
        nextStepBtnType,
        pageNumber,
        prevStepName,
        showPrevButton,
        testIDNextBtn,
        testIDPrevBtn,
        children,
        idNextBtn,
    } = props;

    return (
        <React.Fragment>
            <Button
                modalBtn
                type={nextStepBtnType}
                color="secondary"
                disabled={disabled}
                onClick={onNext}
                testid={testIDNextBtn}
                id={idNextBtn}
            >
                {isLastPage ? lastStepName : nextStepName}
            </Button>
            {children}
            {showPrevButton || (showPrevButton && pageNumber > 0) ? (
                <Button
                    zeroPadding
                    zeroMinHeight
                    link
                    capitalize
                    onClick={onPrev}
                    underline="dashed"
                    testid={testIDPrevBtn}
                    minWidth="0"
                >
                    {prevStepName}
                </Button>
            ) : null}
        </React.Fragment>
    );
};

WizardButtons.defaultProps = {
    nextStepBtnType: "submit",
    nextStepName: "Продолжить",
    prevStepName: "Отмена",
    showPrevButton: true,
    testIDNextBtn: "formYesButton",
    testIDPrevBtn: "formNoButton",
};

export default withStyles(styles)(WizardButtons);
