// @flow
import type {themeTypes} from "../../../themeStyle";
import {isIE} from "../../../utils/browserUtils";

/* eslint-disable sort-keys */
export const styles = (theme: themeTypes) => ({
    badge: {
        backgroundColor: theme.palette.common.balanceRed,
        borderRadius: "16px",
        color: "white",
        fontSize: "12px",
        left: 0,
        padding: "0 8px",
        position: "absolute",
        top: -12,
    },
    border: {
        border: "1px solid",
    },
    "box-shadow-none": {
        boxShadow: "none",
    },
    "color-primary": {
        background: theme.palette.button.background.primary,
    },
    "color-primaryTranslucent": {
        background: theme.palette.primary.translucent,
    },
    "color-secondary": {
        background: theme.palette.button.background.secondary,
    },
    "color-transparent": {
        background: theme.palette.transparent.main,
    },
    "cursor-pointer": {
        cursor: "pointer",
    },
    "cursor-default": {
        cursor: "default",
    },
    "cursor-text": {
        cursor: "text",
    },
    "padding-left-right-0": {
        paddingLeft: 0,
        paddingRight: 0,
    },
    "zero-padding": {
        padding: 0,
    },
    "size-full": {
        width: "100%",
    },
    "xs-size-full": {
        [theme.breakpoints.down("xs")]: {
            width: "100% !important",
        },
    },
    "size-lg": {
        width: 266,
    },
    "size-md": {
        width: 192,
    },
    "size-sm": {
        width: 134,
    },
    "size-xs": {
        width: 110,
    },
    "text-color-primary-light": {
        color: theme.palette.primary.light,
    },
    "text-color-error": {
        color: theme.palette.error.main,
    },
    "text-color-dark-error": {
        color: theme.palette.error.dark,
    },
    "text-color-black": {
        color: theme.palette.common.black,
    },
    "text-color-secondary": {
        color: theme.palette.secondary.main,
    },
    "text-color-white": {
        color: theme.palette.common.white,
    },
    "min-width-0": {
        minWidth: 0,
    },
    "min-width-20": {
        minWidth: "20px",
    },
    "min-width-50": {
        minWidth: "50px",
    },
    "min-width-300": {
        minWidth: "300px",
    },
    "zero-min-height": {
        minHeight: 0,
    },
    "justify-left": {
        justifyContent: "flex-start",
    },
    "justify-center": {
        justifyContent: "center",
    },
    "justify-right": {
        justifyContent: "flex-end",
    },
    "underline-native": {
        textDecoration: `underline ${theme.palette.primary.light}`,
        textAlign: "left",
    },
    "underline-dashed": {
        borderBottom: "1px dashed",
        textAlign: "left",
        width: "auto",
    },
    "underline-solid": {
        borderBottom: "1px solid",
        textAlign: "left",
        width: "auto",
    },
    "text-transform-none": {
        textTransform: "none",
    },
    "font-size-10": {
        fontSize: 10,
    },
    "font-size-12": {
        fontSize: 12,
    },
    "font-size-14": {
        fontSize: 14,
    },
    "font-size-18": {
        fontSize: 18,
    },
    "padding-10": {
        padding: 10,
    },
    root: {
        height: 40,
        overflow: "hidden",
        paddingBottom: 11,
        paddingTop: 11,
    },
    linkClass: {
        "&:hover": {
            background: theme.palette.transparent.main,
            color: theme.palette.primary.main,
        },
        "&:active": {
            boxShadow: "none",
        },
    },
    disabled: {
        background: theme.palette.transparent.main,
        border: "none",
        color: theme.palette.common.white,
    },
    "min-height-48": {
        minHeight: 48,
    },
    "height-48": {
        height: 48,
    },
    list: {
        marginLeft: 12,
        "&:before": {
            background: theme.palette.primary.light,
            height: 4,
            content: "''",
            width: 4,
            display: "block",
            left: -12,
            position: "absolute",
            borderRadius: "50%",
            top: "calc(50% - 2px)",
        },
    },
    modalBtn: {
        [theme.breakpoints.down("xs")]: {
            width: "calc(100% - 16px)",
        },
    },
    marginAuto: isIE()
        ? {}
        : {
              margin: "auto",
          },
    "whiteSpace-nowrap": {
        whiteSpace: "nowrap",
    },
});
/* eslint-enable sort-keys */
