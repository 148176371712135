// @flow

export const LS_PAID_PARAMS_ACTION = "LS_PAID_PARAMS_ACTION";
export const LS_PAID_PARAMS_ACTION_SUCCESS = "LS_PAID_PARAMS_ACTION_SUCCESS";
export const LS_PAID_PARAMS_ACTION_FAILURE = "LS_PAID_PARAMS_ACTION_FAILURE";

export const GET_PAID_SERVICE_LIST_ACTION = "GET_PAID_SERVICE_LIST_ACTION";
export const GET_PAID_SERVICE_LIST_ACTION_SUCCESS = "GET_PAID_SERVICE_LIST_ACTION_SUCCESS";
export const GET_PAID_SERVICE_LIST_ACTION_FAILURE = "GET_PAID_SERVICE_LIST_ACTION_FAILURE";

export const GET_PAID_SERVICE_LIST_20_ACTION = "GET_PAID_SERVICE_LIST_20_ACTION";
export const GET_PAID_SERVICE_LIST_20_ACTION_SUCCESS = "GET_PAID_SERVICE_LIST_20_ACTION_SUCCESS";
export const GET_PAID_SERVICE_LIST_20_ACTION_FAILURE = "GET_PAID_SERVICE_LIST_20_ACTION_FAILURE";

export const BUILD_FILTER_CONFIG_ACTION = "BUILD_FILTER_CONFIG_ACTION";

export const GET_PROVIDER_LIST_ACTION = "GET_PROVIDER_LIST_ACTION";
export const GET_PROVIDER_LIST_ACTION_SUCCESS = "GET_PROVIDER_LIST_ACTION_SUCCESS";
export const GET_PROVIDER_LIST_ACTION_FAILURE = "GET_PROVIDER_LIST_ACTION_FAILURE";

export const GET_REGION_LIST_ACTION = "GET_REGION_LIST_ACTION";
export const GET_REGION_LIST_ACTION_SUCCESS = "GET_REGION_LIST_ACTION_SUCCESS";
export const GET_REGION_LIST_ACTION_FAILURE = "GET_REGION_LIST_ACTION_FAILURE";

export const GET_PAID_SERVICE_GROUPS_ACTION = "GET_PAID_SERVICE_GROUPS_ACTION";
export const GET_PAID_SERVICE_GROUPS_ACTION_SUCCESS = "GET_PAID_SERVICE_GROUPS_ACTION_SUCCESS";
export const GET_PAID_SERVICE_GROUPS_ACTION_FAILURE = "GET_PAID_SERVICE_GROUPS_ACTION_FAILURE";

export const GET_PAID_SERVICE_CATEGORY_ACTION = "GET_PAID_SERVICE_CATEGORY_ACTION";
export const GET_PAID_SERVICE_CATEGORY_ACTION_SUCCESS = "GET_PAID_SERVICE_CATEGORY_ACTION_SUCCESS";
export const GET_PAID_SERVICE_CATEGORY_ACTION_FAILURE = "GET_PAID_SERVICE_CATEGORY_ACTION_FAILURE";

export const GET_PAID_SERVICE_PARAMS_ACTION = "GET_PAID_SERVICE_PARAMS_ACTION";
export const GET_PAID_SERVICE_PARAMS_ACTION_SUCCESS = "GET_PAID_SERVICE_PARAMS_ACTION_SUCCESS";
export const GET_PAID_SERVICE_PARAMS_ACTION_FAILURE = "GET_PAID_SERVICE_PARAMS_ACTION_FAILURE";

export const REG_PAID_SERVICE_ACTION = "REG_PAID_SERVICE_ACTION";
export const REG_PAID_SERVICE_ACTION_SUCCESS = "REG_PAID_SERVICE_ACTION_SUCCESS";
export const REG_PAID_SERVICE_ACTION_FAILURE = "REG_PAID_SERVICE_ACTION_FAILURE";

export const GET_PAID_SERVICE_HISTORY_ACTION = "GET_PAID_SERVICE_HISTORY_ACTION";
export const GET_PAID_SERVICE_HISTORY_ACTION_SUCCESS = "GET_PAID_SERVICE_HISTORY_ACTION_SUCCESS";
export const GET_PAID_SERVICE_HISTORY_ACTION_FAILURE = "GET_PAID_SERVICE_HISTORY_ACTION_FAILURE";

export const CLOSE_PAY_CONFIRM_MODAL_ACTION = "CLOSE_PAY_CONFIRM_MODAL_ACTION";

export const GET_PAID_SERVICE_LS_LIST_ACTION = "GET_PAID_SERVICE_LS_LIST_ACTION";
export const GET_PAID_SERVICE_LS_LIST_ACTION_SUCCESS = "GET_PAID_SERVICE_LS_LIST_ACTION_SUCCESS";
export const GET_PAID_SERVICE_LS_LIST_ACTION_FAILURE = "GET_PAID_SERVICE_LS_LIST_ACTION_FAILURE";

export const GET_PAID_SERVICE_ATTRIBUTES_ACTION = "GET_PAID_SERVICE_ATTRIBUTES_ACTION";
export const GET_PAID_SERVICE_ATTRIBUTES_ACTION_SUCCESS = "GET_PAID_SERVICE_ATTRIBUTES_ACTION_SUCCESS";
export const GET_PAID_SERVICE_ATTRIBUTES_ACTION_FAILURE = "GET_PAID_SERVICE_ATTRIBUTES_ACTION_FAILURE";

export const REG_EMAIL_PAID_SERVICE_ACTION = "REG_EMAIL_PAID_SERVICE_ACTION";
export const REG_EMAIL_PAID_SERVICE_ACTION_SUCCESS = "REG_EMAIL_PAID_SERVICE_ACTION_SUCCESS";
export const REG_EMAIL_PAID_SERVICE_ACTION_FAILURE = "REG_EMAIL_PAID_SERVICE_ACTION_FAILURE";

export const OPEN_ORDER_SERVICE_FORM = "OPEN_ORDER_SERVICE_FORM";
export const CLOSE_ORDER_SERVICE_FORM = "CLOSE_ORDER_SERVICE_FORM";

export const GET_LS_LIST_ACTION = "GET_LS_LIST_ACTION";
export const GET_LS_LIST_ACTION_SUCCESS = "GET_LS_LIST_ACTION_SUCCESS";
export const GET_LS_LIST_ACTION_FAILURE = "GET_LS_LIST_ACTION_FAILURE";

export const CRM_GET_PAID_SERVICE_HISTORY_ACTION = "CRM_GET_PAID_SERVICE_HISTORY_ACTION";
export const CRM_GET_PAID_SERVICE_HISTORY_ACTION_SUCCESS = "CRM_GET_PAID_SERVICE_HISTORY_ACTION_SUCCESS";
export const CRM_GET_PAID_SERVICE_HISTORY_ACTION_FAILURE = "CRM_GET_PAID_SERVICE_HISTORY_ACTION_FAILURE";

export const CRM_REG_PAID_SERVICE_ACTION = "CRM_REG_PAID_SERVICE_ACTION";
export const CRM_REG_PAID_SERVICE_ACTION_SUCCESS = "CRM_REG_PAID_SERVICE_ACTION_SUCCESS";
export const CRM_REG_PAID_SERVICE_ACTION_FAILURE = "CRM_REG_PAID_SERVICE_ACTION_FAILURE";

export const GET_PRINT_REQUESTS_ACTION = "GET_PRINT_REQUESTS_ACTION";
export const GET_PRINT_REQUESTS_ACTION_SUCCESS = "GET_PRINT_REQUESTS_ACTION_SUCCESS";
export const GET_PRINT_REQUESTS_ACTION_FAILURE = "GET_PRINT_REQUESTS_ACTION_FAILURE";

export const CRM_GET_PROMOS_ACTION = "CRM_GET_PROMOS_ACTION";
export const CRM_GET_PROMOS_ACTION_SUCCESS = "CRM_GET_PROMOS_ACTION_SUCCESS";
export const CRM_GET_PROMOS_ACTION_FAILURE = "CRM_GET_PROMOS_ACTION_FAILURE";

export const CRM_GET_PROMOCODE_FILTER_ACTION = "CRM_GET_PROMOCODE_FILTER_ACTION";
export const CRM_GET_PROMOCODE_FILTER_ACTION_SUCCESS = "CRM_GET_PROMOCODE_FILTER_ACTION_SUCCESS";
export const CRM_GET_PROMOCODE_FILTER_ACTION_FAILURE = "CRM_GET_PROMOCODE_FILTER_ACTION_FAILURE";

export const CRM_PROMOCODE_AVAIL_ACTION = "CRM_PROMOCODE_AVAIL_ACTION";
export const CRM_PROMOCODE_AVAIL_ACTION_SUCCESS = "CRM_PROMOCODE_AVAIL_ACTION_SUCCESS";
export const CRM_PROMOCODE_AVAIL_ACTION_FAILURE = "CRM_PROMOCODE_AVAIL_ACTION_FAILURE";

export const CRM_PRE_CALC_ACTION = "CRM_PRE_CALC_ACTION";
export const CRM_PRE_CALC_ACTION_SUCCESS = "CRM_PRE_CALC_ACTION_SUCCESS";
export const CRM_PRE_CALC_ACTION_FAILURE = "CRM_PRE_CALC_ACTION_FAILURE";

export const CRM_SHOW_GP_ACTION = "CRM_SHOW_GP_ACTION";
export const CRM_SHOW_GP_ACTION_SUCCESS = "CRM_SHOW_GP_ACTION_SUCCESS";
export const CRM_SHOW_GP_ACTION_FAILURE = "CRM_SHOW_GP_ACTION_FAILURE";
