// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import EmptyNotificationsImage from "../../../images/emptyNotifications.svg";
import Typography from "../../../packages/components/Typography/Typography";
import {styles} from "./styles";

type Props = {
    classes?: Object,
};

const EmptyNotifications = ({classes = {}}: Props) => (
    <React.Fragment>
        <Divider />
        <Grid
            container
            className={classes.container}
            direction="column"
            alignItems="center"
            justify="center"
            spacing={8}
        >
            <Grid item>
                <img className={classes.image} src={EmptyNotificationsImage} alt="" />
            </Grid>
            <Grid item>
                <Typography fontSize={16}>Новых уведомлений нет</Typography>
            </Grid>
        </Grid>
    </React.Fragment>
);

export default withStyles(styles)(EmptyNotifications);
