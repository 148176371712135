// @flow
import * as React from "react";
import MaterialDialogTitle from "@material-ui/core/DialogTitle";
import {withStyles} from "@material-ui/core/styles";

const styles = {
    root: {
        paddingRight: 35,
    },
};

type Props = {
    classes?: {
        [$Keys<typeof styles>]: string,
    },
    children?: React$Node,
};

export const DialogTitle = ({classes = {}, children}: Props) => (
    <MaterialDialogTitle className={classes.root} testid="formTitle">
        {children}
    </MaterialDialogTitle>
);

export default withStyles(styles)(DialogTitle);
