// @flow
import React from "react";
import TextField from "../../components/Form/TextField";
import {Typography} from "../../components/Typography/Typography";

export const FieldTextPhone = (props: any) => {
    const {...InputProps} = props.InputProps;
    const [isFocused, setIsFocused] = React.useState(false);
    const phoneLength = 10;
    const onPasteCheck = (value) => {
        const phoneMask = /^((\+?7)|8)?9\d{9}$/;
        const phoneMaskWithBrackets = /^\+7\(9\d{2}\)\d{3}-\d{2}-\d{2}$/;

        return phoneMask.test(value) || phoneMaskWithBrackets.test(value);
    };
    const handleChange = (event: any) => {
        const {value} = event.currentTarget;
        const allowedSymbols = /[0-9]/;

        if (event.nativeEvent.inputType === "insertFromPaste") {
            if (onPasteCheck(value)) {
                const insertValue = value.replace(/[^\d]/g, "").substr(-phoneLength);

                props.InputProps.onChange(insertValue);
            }

            return false;
        }
        if (value.length > phoneLength) {
            return false;
        }
        if (!event.nativeEvent.data || allowedSymbols.test(event.nativeEvent.data)) {
            props.InputProps.onChange(value);
        }

        return false;
    };
    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = () => {
        setIsFocused(false);
    };

    React.useEffect(() => {
        const {value} = props.InputProps;

        if (value?.length > phoneLength) {
            props.InputProps.onChange(value.slice(-phoneLength));
        }
    }, [props.InputProps.value]);

    return (
        <TextField
            {...props}
            onBlur={handleBlur}
            onFocus={handleFocus}
            InputProps={{
                ...InputProps,
                onChange: handleChange,
                startAdornment:
                    InputProps.value || isFocused ? (
                        <Typography style={{fontSize: 16, fontWeight: 300}}>+7</Typography>
                    ) : (
                        ""
                    ),
                style: {lineHeight: 2},
                value: InputProps.value.length > phoneLength ? InputProps.value.slice(-phoneLength) : InputProps.value,
            }}
        />
    );
};

export default FieldTextPhone;
