// @flow

import {type BPatternSrc} from "../packages/Builder/BPattern/BPattern";

export const TARIFF_ZONE_SRC: Array<BPatternSrc> = [
    {
        key: "tariffZone",
        type: "string",
    },
];

export const DEFAULT_INDICATIONS_PRECISION = 0;

export const TRANSFER_INDICATIONS_ALL_LS_MES_KEY = "mes";
export const TRANSFER_INDICATIONS_ALL_LS_MOE_KEY = "moe";
