/* eslint-disable max-lines */
// @flow
import * as profileActionTypes from "../../constants/types/ProfileActionTypes";
import {type ApiMiddlewareActionType, type ApiMiddlewareResponsePromiseType} from "../middlewares/apiMiddleware";
import {RESPONSE_RESULT_TEXT_CODE, SUCCESS_CHANGE_DFA_CODE} from "../../constants/KdResults";
import {openFrameLink} from "./accountAction";

export type SetSubscrParams = {idService: number, kdSubscr: number, prSubscr: boolean, nmContact: string};
export type SetProfileSubscrParams = {kdSubscr: number, prSubscr: boolean, nmContact: string};
export type EmailSubscrChgFlagsType = (
    session: string,
    {pdSubscr: boolean, adSubscr: boolean},
) => ApiMiddlewareResponsePromiseType;

export type ProfileEditActionType = (
    attributes: string,
    session: string,
    skipResult?: boolean,
) => ApiMiddlewareResponsePromiseType;
export type PswChangeActionType = (session: string, pswOld: string, pswNew: string) => ApiMiddlewareResponsePromiseType;
export type PswSetActionType = (session: string, pswNew: string) => ApiMiddlewareResponsePromiseType;
export type IsConfirmActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type SendEmailConfirmRepeatActionType = () => ApiMiddlewareResponsePromiseType;
export type GetMoePdSubscrStatusActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetAdSubscrStatusActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetPaymentDocSubscrStatusActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetMesPdSubscrStatusActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetUfaPdSubscrStatusActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetVlgPdSubscrStatusActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetAltPdSubscrStatusActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetOrlPdSubscrStatusActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetOrlEpdPdSubscrStatusActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetSarPdSubscrStatusActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetTmbPdSubscrStatusActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetVldPdSubscrStatusActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetKsgPdSubscrStatusActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type UIDeleteProfileActionType = (nmEmail: string, session: string) => ApiMiddlewareResponsePromiseType;
export type AddrEditRedirectActionType = (session: string, result?: string) => ApiMiddlewareResponsePromiseType;
export type UserChangeLsSubscrActionType = (
    params: SetSubscrParams,
    session: string,
) => ApiMiddlewareResponsePromiseType;
export type UserSetProfileSubscrActionType = (
    params: SetProfileSubscrParams,
    session: string,
) => ApiMiddlewareResponsePromiseType;
export type UserSetPaymentDocSubscrActionType = (
    session: string,
    vlProvider: string,
) => ApiMiddlewareResponsePromiseType;
export type GetSubscrReasonsActionType = (
    session: string,
    kdSubscr: number,
    prSubscr: boolean,
) => ApiMiddlewareResponsePromiseType;
export type AddrEditFrameActionType = (
    session: string,
    idService: number,
    prMirror: boolean,
) => ApiMiddlewareResponsePromiseType;
export type LinkedSystemsActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type SudirUnLinkUserActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type PswSetCheckActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetPaspDetailsActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetCountryListActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetPaspDetailsFailedActionType = (session: string, params?: Object) => ApiMiddlewareResponsePromiseType;
export type ShowSettingTfaActionType = (session: string, show?: boolean) => ApiMiddlewareResponsePromiseType;

export const profileEditAction = (
    attributes: string,
    session: string,
    skipResult?: boolean,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            attributes,
        },
        query: "ProfileEdit",
        session,
        types: [
            profileActionTypes.PROFILE_EDIT_ACTION,
            profileActionTypes.PROFILE_EDIT_ACTION_SUCCESS,
            profileActionTypes.PROFILE_EDIT_ACTION_FAILURE,
        ],
    },
    payload: {
        skipkdResults: skipResult ? [RESPONSE_RESULT_TEXT_CODE] : undefined,
    },
});

export const pswChangeAction = (session: string, pswOld: string, pswNew: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            pswNew,
            pswOld,
        },
        query: "PswChange",
        session,
        types: [
            profileActionTypes.PSW_CHANGE_ACTION,
            profileActionTypes.PSW_CHANGE_ACTION_SUCCESS,
            profileActionTypes.PSW_CHANGE_ACTION_FAILURE,
        ],
    },
});

export const pswSetAction = (session: string, pswNew: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            pswNew,
        },
        query: "PswSet",
        session,
        types: [
            profileActionTypes.PSW_SET_ACTION,
            profileActionTypes.PSW_SET_ACTION_SUCCESS,
            profileActionTypes.PSW_SET_ACTION_FAILURE,
        ],
    },
});

export const isConfirmAction = (session: string) => ({
    api: {
        query: "IsConfirm",
        session,
        types: [
            profileActionTypes.IS_CONFIRM_ACTION,
            profileActionTypes.IS_CONFIRM_ACTION_SUCCESS,
            profileActionTypes.IS_CONFIRM_ACTION_FAILURE,
        ],
    },
});

export const getMoePdSubscrStatusAction = (session: string) => ({
    api: {
        list: true,
        query: "GetMoePdSubscrStatus",
        session,
        types: [
            profileActionTypes.GET_MOE_PD_SUBSCR_STATUS_ACTION,
            profileActionTypes.GET_MOE_PD_SUBSCR_STATUS_ACTION_SUCCESS,
            profileActionTypes.GET_MOE_PD_SUBSCR_STATUS_ACTION_FAILURE,
        ],
    },
});

export const getAdSubscrStatusAction = (session: string) => ({
    api: {
        list: true,
        query: "GetAdSubscrStatus",
        session,
        types: [
            profileActionTypes.GET_AD_SUBSCR_STATUS_ACTION,
            profileActionTypes.GET_AD_SUBSCR_STATUS_ACTION_SUCCESS,
            profileActionTypes.GET_AD_SUBSCR_STATUS_ACTION_FAILURE,
        ],
    },
});

export const getMesPdSubscrStatusAction = (session: string) => ({
    api: {
        list: true,
        query: "GetMesPdSubscrStatus",
        session,
        types: [
            profileActionTypes.GET_MES_PD_SUBSCR_STATUS_ACTION,
            profileActionTypes.GET_MES_PD_SUBSCR_STATUS_ACTION_SUCCESS,
            profileActionTypes.GET_MES_PD_SUBSCR_STATUS_ACTION_FAILURE,
        ],
    },
});
export const getUfaPdSubscrStatusAction = (session: string) => ({
    api: {
        list: true,
        query: "GetUfaPdSubscrStatus",
        session,
        types: [
            profileActionTypes.GET_UFA_PD_SUBSCR_STATUS_ACTION,
            profileActionTypes.GET_UFA_PD_SUBSCR_STATUS_ACTION_SUCCESS,
            profileActionTypes.GET_UFA_PD_SUBSCR_STATUS_ACTION_FAILURE,
        ],
    },
});
export const getVlgPdSubscrStatusAction = (session: string) => ({
    api: {
        list: true,
        query: "GetVlgPdSubscrStatus",
        session,
        types: [
            profileActionTypes.GET_VLG_PD_SUBSCR_STATUS_ACTION,
            profileActionTypes.GET_VLG_PD_SUBSCR_STATUS_ACTION_SUCCESS,
            profileActionTypes.GET_VLG_PD_SUBSCR_STATUS_ACTION_FAILURE,
        ],
    },
});

export const getAltPdSubscrStatusAction = (session: string) => ({
    api: {
        list: true,
        query: "GetAltPdSubscrStatus",
        session,
        types: [
            profileActionTypes.GET_ALT_PD_SUBSCR_STATUS_ACTION,
            profileActionTypes.GET_ALT_PD_SUBSCR_STATUS_ACTION_SUCCESS,
            profileActionTypes.GET_ALT_PD_SUBSCR_STATUS_ACTION_FAILURE,
        ],
    },
});
export const getOrlPdSubscrStatusAction = (session: string) => ({
    api: {
        list: true,
        query: "GetOrlPdSubscrStatus",
        session,
        types: [
            profileActionTypes.GET_ORL_PD_SUBSCR_STATUS_ACTION,
            profileActionTypes.GET_ORL_PD_SUBSCR_STATUS_ACTION_SUCCESS,
            profileActionTypes.GET_ORL_PD_SUBSCR_STATUS_ACTION_FAILURE,
        ],
    },
});
export const getOrlEpdPdSubscrStatusAction = (session: string) => ({
    api: {
        list: true,
        query: "GetOrlEpdPdSubscrStatus",
        session,
        types: [
            profileActionTypes.GET_ORL_EPD_PD_SUBSCR_STATUS_ACTION,
            profileActionTypes.GET_ORL_EPD_PD_SUBSCR_STATUS_ACTION_SUCCESS,
            profileActionTypes.GET_ORL_EPD_PD_SUBSCR_STATUS_ACTION_FAILURE,
        ],
    },
});
export const getSarPdSubscrStatusAction = (session: string) => ({
    api: {
        list: true,
        query: "GetSarPdSubscrStatus",
        session,
        types: [
            profileActionTypes.GET_SAR_PD_SUBSCR_STATUS_ACTION,
            profileActionTypes.GET_SAR_PD_SUBSCR_STATUS_ACTION_SUCCESS,
            profileActionTypes.GET_SAR_PD_SUBSCR_STATUS_ACTION_FAILURE,
        ],
    },
});
export const getTmbPdSubscrStatusAction = (session: string) => ({
    api: {
        list: true,
        query: "GetTmbPdSubscrStatus",
        session,
        types: [
            profileActionTypes.GET_TMB_PD_SUBSCR_STATUS_ACTION,
            profileActionTypes.GET_TMB_PD_SUBSCR_STATUS_ACTION_SUCCESS,
            profileActionTypes.GET_TMB_PD_SUBSCR_STATUS_ACTION_FAILURE,
        ],
    },
});
export const getVldPdSubscrStatusAction = (session: string) => ({
    api: {
        list: true,
        query: "GetVldPdSubscrStatus",
        session,
        types: [
            profileActionTypes.GET_VLD_PD_SUBSCR_STATUS_ACTION,
            profileActionTypes.GET_VLD_PD_SUBSCR_STATUS_ACTION_SUCCESS,
            profileActionTypes.GET_VLD_PD_SUBSCR_STATUS_ACTION_FAILURE,
        ],
    },
});
export const getKsgPdSubscrStatusAction = (session: string) => ({
    api: {
        list: true,
        query: "GetKsgPdSubscrStatus",
        session,
        types: [
            profileActionTypes.GET_KSG_PD_SUBSCR_STATUS_ACTION,
            profileActionTypes.GET_KSG_PD_SUBSCR_STATUS_ACTION_SUCCESS,
            profileActionTypes.GET_KSG_PD_SUBSCR_STATUS_ACTION_FAILURE,
        ],
    },
});

export const userChangeLsSubscrAction = (params: SetSubscrParams, session: string) => ({
    api: {
        data: {...params},
        query: "UserChangeLsSubscr",
        session,
        types: [
            profileActionTypes.USER_CHANGE_LS_SUBSCR_ACTION,
            profileActionTypes.USER_CHANGE_LS_SUBSCR_ACTION_SUCCESS,
            profileActionTypes.USER_CHANGE_LS_SUBSCR_ACTION_FAILURE,
        ],
    },
});

export const userSetProfileSubscrAction = (params: SetProfileSubscrParams, session: string) => ({
    api: {
        data: {...params},
        query: "UserSetProfileSubscr",
        session,
        types: [
            profileActionTypes.USER_SET_PROFILE_SUBSCR_ACTION,
            profileActionTypes.USER_SET_PROFILE_SUBSCR_ACTION_SUCCESS,
            profileActionTypes.USER_SET_PROFILE_SUBSCR_ACTION_FAILURE,
        ],
    },
});

export const userSetPaymentDocSubscrAction = (session: string, vlProvider: string) => ({
    api: {
        data: {
            vlProvider,
        },
        query: "DenyLsEpdSubsc",
        session,
        types: [
            profileActionTypes.USER_SET_PD_SUBSCR_ACTION,
            profileActionTypes.USER_SET_PD_SUBSCR_ACTION_SUCCESS,
            profileActionTypes.USER_SET_PD_SUBSCR_ACTION_FAILURE,
        ],
    },
});

export const emailSubscrChgFlagsAction = (
    session: string,
    {adSubscr, pdSubscr}: {adSubscr: boolean, pdSubscr: boolean},
) => ({
    api: {
        data: {
            prAdSubscrChg: adSubscr,
            prPdSubscrChg: pdSubscr,
        },
        query: "EmailSubscrChgFlags",
        session,
        types: [
            profileActionTypes.EMAIL_SUBSCR_CHG_FLAGS_ACTION,
            profileActionTypes.EMAIL_SUBSCR_CHG_FLAGS_ACTION_SUCCESS,
            profileActionTypes.EMAIL_SUBSCR_CHG_FLAGS_ACTION_FAILURE,
        ],
    },
});

export const getSubscrReasonsAction = (session: string, kdSubscr: number, prSubscr: boolean) => ({
    api: {
        data: {
            kdSubscr,
            prSubscr,
        },
        list: true,
        query: "GetSubscrReasons",
        session,
        types: [
            profileActionTypes.GET_SUBSCR_REASONS_ACTION,
            profileActionTypes.GET_SUBSCR_REASONS_ACTION_SUCCESS,
            profileActionTypes.GET_SUBSCR_REASONS_ACTION_FAILURE,
        ],
    },
});

export const addrEditRedirectAction = (session: string, result: string) => ({
    api: {
        data: {
            result,
        },
        query: "AddrEditRedirect",
        session,
        types: [
            profileActionTypes.ADD_EDIT_REDIRECT_ACTION,
            profileActionTypes.ADD_EDIT_REDIRECT_ACTION_SUCCESS,
            profileActionTypes.ADD_EDIT_REDIRECT_ACTION_FAILURE,
        ],
    },
});

export const addrEditFrameAction = (session: string, idService: number, prMirror: boolean) => (dispatch: any) =>
    dispatch({
        api: {
            data: {
                idService,
                prMirror,
            },
            query: "AddrEditFrame",
            session,
            types: [
                profileActionTypes.ADDR_EDIT_FRAME_ACTION,
                profileActionTypes.ADDR_EDIT_FRAME_ACTION_SUCCESS,
                profileActionTypes.ADDR_EDIT_FRAME_ACTION_FAILURE,
            ],
        },
    }).then(openFrameLink);

export const linkedSystemsAction = (session: string) => ({
    api: {
        query: "LinkedSystems",
        session,
        types: [
            profileActionTypes.LINKED_SYSTEMS_ACTION,
            profileActionTypes.LINKED_SYSTEMS_ACTION_SUCCESS,
            profileActionTypes.LINKED_SYSTEMS_ACTION_FAILURE,
        ],
    },
});

export const sudirUnLinkUserAction = (session: string) => ({
    api: {
        query: "SudirUnLinkUser",
        session,
        types: [
            profileActionTypes.SUDIR_UNLINK_USER_SYSTEMS,
            profileActionTypes.SUDIR_UNLINK_USER_SYSTEMS_SUCCESS,
            profileActionTypes.SUDIR_UNLINK_USER_SYSTEMS_FAILURE,
        ],
    },
});
export const pswSetCheckAction = (session: string) => ({
    api: {
        query: "PswSetCheck",
        session,
        types: [
            profileActionTypes.PSW_SET_CHECK_ACTION,
            profileActionTypes.PSW_SET_CHECK_ACTION_SUCCESS,
            profileActionTypes.PSW_SET_CHECK_ACTION_FAILURE,
        ],
    },
});
export const getPaspDetailsAction = (session: string) => ({
    api: {
        query: "GetPaspDetails",
        session,
        types: [
            profileActionTypes.GET_PASP_DETAILS_ACTION,
            profileActionTypes.GET_PASP_DETAILS_ACTION_SUCCESS,
            profileActionTypes.GET_PASP_DETAILS_ACTION_FAILURE,
        ],
    },
});
export const getCountryListAction = (session: string) => ({
    api: {
        list: true,
        query: "GetCountryList",
        session,
        types: [
            profileActionTypes.GET_COUNTRY_LIST_ACTION,
            profileActionTypes.GET_COUNTRY_LIST_ACTION_SUCCESS,
            profileActionTypes.GET_COUNTRY_LIST_ACTION_FAILURE,
        ],
    },
});
export const savePaspDetailsAction = (params: Object, session: string) => ({
    api: {
        data: params,
        query: "SavePaspDetails",
        session,
        types: [
            profileActionTypes.SAVE_PASP_DETAILS_ACTION,
            profileActionTypes.SAVE_PASP_DETAILS_ACTION_SUCCESS,
            profileActionTypes.SAVE_PASP_DETAILS_ACTION_FAILURE,
        ],
    },
});
export const getPaspDetailsFailedAction = (session: string, params?: Object) => ({
    api: {
        query: "GetPaspDetailsFailed",
        session,
        types: [
            profileActionTypes.GET_PASP_DETAILS_FAILED_ACTION,
            profileActionTypes.GET_PASP_DETAILS_FAILED_ACTION_SUCCESS,
            profileActionTypes.GET_PASP_DETAILS_FAILED_ACTION_FAILURE,
        ],
    },
    payload: {
        isDisabledAuthorisationError: params?.isDisabledAuthorisationError,
    },
});
export const setProfileShowOnboardingAction = (prShowOnboarding: boolean, session: string) => ({
    api: {
        data: {
            prShowOnboarding,
        },
        query: "SetProfileShowOnboarding",
        session,
        types: [
            profileActionTypes.DEFAULT_ACTION,
            profileActionTypes.DEFAULT_ACTION_SUCCESS,
            profileActionTypes.DEFAULT_ACTION_FAILURE,
        ],
    },
});
export const uIDeleteUnsubscrAction = (data: Object, session: string) => ({
    api: {
        data,
        query: "UIDeleteUnsubscr",
        session,
        types: [
            profileActionTypes.UI_DELETE_UNSUBSCR,
            profileActionTypes.UI_DELETE_UNSUBSCR_SUCCESS,
            profileActionTypes.UI_DELETE_UNSUBSCR_FAILURE,
        ],
    },
});
export const uIDeleteProfileAction = (nmEmail: string, session: string) => ({
    api: {
        data: {
            nmEmail,
        },
        query: "UIDeleteProfile",
        session,
        types: [
            profileActionTypes.UI_DELETE_PROFILE,
            profileActionTypes.UI_DELETE_PROFILE_SUCCESS,
            profileActionTypes.UI_DELETE_PROFILE_FAILURE,
        ],
    },
});
export const uIConfirmDeleteAction = (data: Object, session: string) => ({
    api: {
        data,
        query: "UIConfirmDelete",
        session,
        types: [
            profileActionTypes.UI_CONFIRM_DELETE_PROFILE,
            profileActionTypes.UI_CONFIRM_DELETE_PROFILE_SUCCESS,
            profileActionTypes.UI_CONFIRM_DELETE_PROFILE_FAILURE,
        ],
    },
});

export const showSettingTfaAction = (session: string, show?: boolean) => ({
    api: {
        query: "ShowSettingTfa",
        session,
        types: [
            profileActionTypes.SHOW_SETTINGS_TFA_ACTION,
            profileActionTypes.SHOW_SETTINGS_TFA_ACTION_SUCCESS,
            profileActionTypes.SHOW_SETTINGS_TFA_ACTION_FAILURE,
        ],
    },
    payload: {
        show,
    },
});

export const changeSettingTfaAction = (session: string, prCheckbox: boolean) => ({
    api: {
        data: {
            prCheckbox,
        },
        query: "ChangeSettingTfa",
        session,
        types: [
            profileActionTypes.DEFAULT_ACTION,
            profileActionTypes.DEFAULT_ACTION_SUCCESS,
            profileActionTypes.DEFAULT_ACTION_FAILURE,
        ],
    },
});

export const changeMethodTfaAction = ({kdTfa, session}: {kdTfa: string, session: string}) => ({
    api: {
        data: {
            kdTfa,
        },
        query: "ChangeMethodTfa",
        session,
        types: [
            profileActionTypes.DEFAULT_ACTION,
            profileActionTypes.DEFAULT_ACTION_SUCCESS,
            profileActionTypes.DEFAULT_ACTION_FAILURE,
        ],
    },
});

export const resendChangeMethodTfaAction = ({
    kdTfa,
    nnSuopSession,
    session,
}: {
    kdTfa: string,
    nnSuopSession?: number,
    session: string,
}) => ({
    api: {
        data: {
            kdTfa,
            nnSuopSession,
        },
        query: "ResendChangeMethodTfa",
        session,
        types: [
            profileActionTypes.DEFAULT_ACTION,
            profileActionTypes.DEFAULT_ACTION_SUCCESS,
            profileActionTypes.DEFAULT_ACTION_FAILURE,
        ],
    },
});

export const confirmChangeMethodTfaAction = ({
    kdTfa,
    nnCode,
    nnSuopSession,
    session,
}: {
    kdTfa: string,
    nnCode: string,
    nnSuopSession?: number,
    session: string,
}) => ({
    api: {
        data: {
            kdTfa,
            nnCode,
            nnSuopSession,
        },
        query: "ConfirmChangeMethodTfa",
        session,
        types: [
            profileActionTypes.DEFAULT_ACTION,
            profileActionTypes.DEFAULT_ACTION_SUCCESS,
            profileActionTypes.DEFAULT_ACTION_FAILURE,
        ],
    },
    payload: {
        skipkdResults: [SUCCESS_CHANGE_DFA_CODE],
    },
});
