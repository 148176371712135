/* eslint-disable sort-keys */
// @flow
import {
    MES_KD_PROVIDER,
    MOE_KD_PROVIDER,
    TMK_RTS_KD_PROVIDER,
    TMK_NRG_KD_PROVIDER,
    UFA_KD_PROVIDER,
    TKO_KD_PROVIDER,
    VLG_KD_PROVIDER,
    ALT_KD_PROVIDER,
    ORL_KD_PROVIDER,
    ORL_EPD_KD_PROVIDER,
    SAR_KD_PROVIDER,
    TMB_KD_PROVIDER,
    VLD_KD_PROVIDER,
    KSG_KD_PROVIDER,
} from "../KdProvider";

export const accountLoadDataNames = {
    abnInfoTmk: "AbnInfo",
    actualSettingsAccrualsSubscriber: "ActualSettingsAccrualsSubscriber",
    common: "AbonentCommonData",
    consumptionRanges: "AbnConsRange",
    contract: "AbonentContractData",
    currentBalance: "AbonentCurrentBalance",
    currentBalanceByt: "CurrentBalance",
    currentBalanceBytTmk: "CurrentBalance",
    currentBalanceUfa: "CurrentBalance",
    equipment: "AbonentEquipment",
    equipmentContract: "AbonentEquipment",
    generalInfoSubscriber: "ViewGeneralInfoSubscriber",
    historyCounter: "ViewHistoryCounter",
    historyIndicationCounterTmk: "ViewHistoryIndicationsCounter",
    indicationCounter: "IndicationCounter",
    indications: (kdProvider: number) => {
        switch (kdProvider) {
            case MOE_KD_PROVIDER:
                return "AbonentIndications";
            case TKO_KD_PROVIDER:
                return "AbonentIndications";
            default:
                return "Indications";
        }
    },
    indicationsCom: "IndicationsCom",
    invoice: "Invoice",
    isCommunal: "IsCommunal",
    lsInfo: "LSInfo",
    lsInfoSubscriber: "ViewLSInfoSubscriber",
    mapMarkers: "Contacts",
    mapMarkersMes: "Contacts",
    mapMarkersMoe: "Contacts",
    mapMarkersOrl: "Contacts",
    mapMarkersUfa: "Contacts",
    mapMarkersVlg: "Contacts",
    mapMarkersAlt: "Contacts",
    mapMarkersSar: "Contacts",
    mapMarkersTmb: "Contacts",
    mapMarkersVld: "Contacts",
    mapMarkersKsg: "Contacts",
    meters: "Meters",
    metersHistory: "MetersHistory",
    paymentDoc: "AbonentChargeDetail",
    pays: (kdProvider: number) => {
        switch (kdProvider) {
            case MOE_KD_PROVIDER:
                return "AbonentPays";
            case TKO_KD_PROVIDER:
                return "AbonentPays";
            default:
                return "Pays";
        }
    },
    statistics: (kdProvider: number) => {
        switch (kdProvider) {
            case MOE_KD_PROVIDER:
                return "AbonentChargeDetail";
            case TKO_KD_PROVIDER:
                return "AbonentChargeDetail";
            default:
                return "Statistics";
        }
    },
    tariffHistory: "TariffHistory",
    tiedServiceProvider: "ViewTiedServiceProvider",
    viewBalanceTmk: "ViewBalance",
    viewInfoFormedAccounts: "ViewInfoFormedAccounts",
    viewInfoFormedAccountsDetails: "ViewInfoFormedAccountsDetails",
    viewInfoPaymentReceived: "ViewInfoPaymentReceived",
};

export const providersPlugin = {
    [MES_KD_PROVIDER]: "bytProxy",
    [MOE_KD_PROVIDER]: "smorodinaTransProxy",
    [ORL_KD_PROVIDER]: "orlBytProxy",
    [TMK_NRG_KD_PROVIDER]: "tomskProxy",
    [TMK_RTS_KD_PROVIDER]: "tomskProxy",
    [UFA_KD_PROVIDER]: "ufaProxy",
    [TKO_KD_PROVIDER]: "trashProxy",
    [VLG_KD_PROVIDER]: "vlgProxy",
    [ALT_KD_PROVIDER]: "altProxy",
    [SAR_KD_PROVIDER]: "sarProxy",
    [TMB_KD_PROVIDER]: "tmbProxy",
    [VLD_KD_PROVIDER]: "vldProxy",
    [ORL_EPD_KD_PROVIDER]: "orlProxy",
    [KSG_KD_PROVIDER]: "ksgProxy",
};

export type AccountLoadDataPeriodType = {
    dtEn: string,
    dtSt: string,
    key: string,
    provider?: String,
};

export type AccountLoadDataPeriodYearsType = {
    dtEn: string,
    dtSt: string,
    key: string,
};

export type RequisitesComplaintPowerSupplyType = {
    available: boolean,
    hintText?: string,
};

export type RequisitesPrAvailType = {
    prAvailOwnerService?: boolean,
    prAvailObjectService?: boolean,
};

export type RequisitesType = {
    complaintPowerSupply: RequisitesComplaintPowerSupplyType,
    prAvail: RequisitesPrAvailType,
};

export type MinimalFunctionsType = {
    idMeter?: number,
    journal: {
        data: Array<Object>,
        fetching?: boolean,
        link?: string,
        notice?: string,
    },
    loaded: boolean,
    measurings: {
        groups: {
            data: Array<Object>,
            fetching: boolean,
            loaded: boolean,
        },
    },
    nmSchema?: string,
    nnMeter?: string,
    prIsu: boolean,
    prTariffIsu: boolean,
    readings: {
        data: Array<Object>,
        fetching: boolean,
        link?: string,
        notice?: string,
    },
};

export type AccountLoadDataStateType = {
    abnInfoTmk: Array<Object>,
    actualSettingsAccrualsSubscriber: Object,
    allEventsMesSortedData: Array<Object>,
    common: Array<Object>,
    consumptionRanges: Array<Object>,
    contract: Array<Object>,
    currentBalance: Array<Object>,
    currentBalanceByt: Array<Object>,
    currentBalanceUfa: Array<Object>,
    equipment: Array<Object>,
    equipmentContract: Array<Object>,
    generalInfoSubscriber: Array<Object>,
    historyCounter: Array<Object>,
    historyIndicationCounterTmk: Object,
    indicationCounter: Array<Object>,
    indications: Array<Object>,
    indicationsCom: Array<Object>,
    insuranceData: Object,
    invoice: Array<Object>,
    isCommunal: number,
    pays: Array<Object>,
    lsConfirm: Object,
    lsInfo: Array<Object>,
    lsInfoSubscriber: Array<Object>,
    lsIndication: Object,
    mapMarkers: Array<Object>,
    mapMarkersMes: Array<Object>,
    mapMarkersOrl: Array<Object>,
    mapMarkersUfa: Array<Object>,
    mapMarkersVlg: Array<Object>,
    mapMarkersAlt: Array<Object>,
    mapMarkersSar: Array<Object>,
    mapMarkersTmb: Array<Object>,
    mapMarkersVld: Array<Object>,
    mapMarkersKsg: Array<Object>,
    meters: Array<Object>,
    metersHistory: Array<Object>,
    paymentDoc: Array<Object>,
    period: AccountLoadDataPeriodType,
    periodYears: AccountLoadDataPeriodYearsType,
    privilegesHistory: Array<Object>,
    requisites: RequisitesType,
    minimalFunctions: MinimalFunctionsType,
    statistics: Array<Object>,
    tariffHistory: Array<Object>,
    tiedServiceProvider: Array<Object>,
    viewBalanceTmk: Array<Object>,
    viewInfoPaymentReceived: Object,
    viewInfoFormedAccounts: Array<Object>,
    viewInfoFormedAccountsDetails: Object,
};

export type AccountLoadDataKeysType = $Keys<typeof accountLoadDataNames>;

export type AccountParamsLoadDataType = {
    codTariff?: string,
    accessInd?: number,
    dtStart?: string,
    dtEnd?: string,
    dtEn?: string,
    dtSt?: string,
    dtPeriodStart?: string,
    dtPeriodEnd?: string,
    idCounter?: string,
    mapId?: string,
    vlProvider?: string,
    providerType?: string,
    proxyplugin?: string,
    idPu?: string,
    kdTpMode?: number,
    nnLs?: string,
    cancelToken?: any,
    idService?: string,
    idServiceProvider?: string,
    dtPeriod?: string,
    normalizeFunction?: (data: Array<Object>) => any,
    noCache?: boolean,
    kdProvider: number,
    additionalServices?: Array<string>,
};
