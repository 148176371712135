// @flow

import {type themeTypes} from "../../../themeStyle";

export const styles = (theme: themeTypes) => ({
    backgroundImg: {
        bottom: 0,
        position: "absolute",
        width: "100%",
    },
    mainContent: {
        backgroundColor: theme.palette.grey.authBackground,
        display: "flex",
        minHeight: `calc(100vh - ${theme.sizing.appbarHeight}px)`,
        position: "relative",
        zIndex: 1,
    },
});
