// @flow
import hash from "object-hash";

export const parsedItems = (result: Array<any>, newItem: Object) => {
    if (newItem.dtIndication) {
        newItem.type = "readings";
        result.push(newItem);
    }

    if (newItem.dtPay) {
        newItem.type = "payment";
        result.push({dtIndication: newItem.dtPay, ...newItem});
    }

    if (newItem.dtPeriod) {
        newItem.type = "receipts";
        result.push({dtIndication: newItem.dtPeriod, ...newItem});
    }
};

export const allDatesMes = () => {
    const cache = {};

    return (data: Array<Array<Object>>) => {
        const hashedData = hash(data);

        if (hashedData in cache) {
            return cache[hashedData];
        }

        const result = [];

        data.forEach((items) => {
            if (items && items.length > 0) {
                items.forEach((item) => {
                    if (item.dtPay || item.dtPeriod || item.dtIndication) {
                        const newItem = {...item};

                        parsedItems(result, newItem);
                    }
                });
            }
        });

        cache[hashedData] = result;

        return result;
    };
};

export const memoizedAllDatesMes = allDatesMes();
