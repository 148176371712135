// @flow
import React from "react";
import IconLeafActiveIcon from "../../../images/iconLeafActive.svg";
import IconLeafInactiveIcon from "../../../images/iconLeafInactive.png";
import NotificationCriticalReadIcon from "./NotificationCriticalReadIcon";
import NotificationCriticalUnreadIcon from "./NotificationCriticalUnreadIcon";
import NotificationReadIcon from "./NotificationReadIcon";
import NotificationUnreadIcon from "./NotificationUnreadIcon";

type Props = {
    error: boolean,
    read?: boolean,
    kvitonomic?: boolean,
};

export const NotificationIcon = ({error, read, kvitonomic}: Props) => {
    switch (true) {
        case kvitonomic && read:
            return <img src={IconLeafInactiveIcon} alt="1" />;
        case kvitonomic && !read:
            return <img src={IconLeafActiveIcon} alt="1" />;
        case error && read:
            return <NotificationCriticalReadIcon />;
        case error && !read:
            return <NotificationCriticalUnreadIcon />;
        case !error && read:
            return <NotificationReadIcon />;
        case !error && !read:
            return <NotificationUnreadIcon />;
        default:
            return null;
    }
};

NotificationIcon.defaultProps = {
    read: false,
};

export default NotificationIcon;
