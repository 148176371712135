// @flow
import React, {PureComponent} from "react";
import {withStyles} from "@material-ui/core/styles";
import {NavLink} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import Settings from "@material-ui/icons/Settings";
import Typography from "../../packages/components/Typography/Typography";

const styles = () => ({
    link: {
        color: "inherit",
        textAlign: "center",
        textDecoration: "none",
    },
    settingsWidth: {
        paddingLeft: 16,
        paddingRight: 16,
        width: "45%",
    },
});

type Props = {
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    idService: number,
    handelCloseSideBar: () => void,
};

class SideBarAccountSettings extends PureComponent<Props> {
    render() {
        const {classes = {}} = this.props;

        return (
            <ListItem
                component={"div"}
                className={classes.settingsWidth}
                testId={`sideBArAccountSettings-${this.props.idService}`}
            >
                <NavLink
                    to={`/accounts/${this.props.idService}/edit`}
                    className={classes.link}
                    onClick={this.props.handelCloseSideBar}
                >
                    <Settings color="inherit" />
                    <Typography display="inline" color="inherit" textColor="inherit" uppercase>
                        Настройки
                    </Typography>
                </NavLink>
            </ListItem>
        );
    }
}

export default withStyles(styles)(SideBarAccountSettings);
