// @flow
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "../../components/Form/TextField";

export const FieldCombo = (props: any) => {
    const {config, InputProps, ...fieldProps} = props;
    const {vlDict} = config;

    return (
        <TextField
            {...fieldProps}
            InputProps={InputProps}
            select
            value={InputProps.value === undefined || InputProps.value === null ? "" : InputProps.value}
        >
            {vlDict &&
                vlDict.map(({nnCode, nmValue, kdDict, nmDict}, index) => (
                    <MenuItem
                        value={nnCode === undefined || InputProps.value === null ? kdDict : nnCode}
                        key={nnCode || kdDict}
                        testid={`${fieldProps.testID}-${index}`}
                    >
                        {nmValue || nmDict}
                    </MenuItem>
                ))}
        </TextField>
    );
};

export default FieldCombo;
