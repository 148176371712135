// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {type IconPropsType} from "./iconType";

const DEFAULT_SIZE = 40;

export const EpdIcon = ({fontSize, ...otherProps}: IconPropsType) => (
    <SvgIcon {...otherProps} style={{fontSize}} width="40" height="40" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d={
                "M20.9579 0.400635C20.2465 -0.133545 19.2677 -0.133545 18.5563 " +
                "0.400635L0.803057 13.7297C-0.733552 14.8835 0.0823771 17.3291 2.00388 " +
                "17.3291H4.5227V37.1216C4.5227 38.2261 5.41814 39.1216 6.5227 39.1216H32.9915C34.096 " +
                "39.1216 34.9915 38.2261 34.9915 37.1216V17.3291H37.5103C39.4318 17.3291 " +
                "40.2477 14.8835 38.7111 13.7297L20.9579 0.400635Z"
            }
            fill="#E3E5E6"
        />
        <circle cx="2" cy="2" r="2" transform="translate(11 18)" fill="#C0C9D0" />
        <rect width="11" height="2" rx="1" transform="translate(17 19)" fill="#C0C9D0" />
        <circle cx="2" cy="2" r="2" transform="translate(11 23)" fill="#C0C9D0" />
        <rect width="11" height="2" rx="1" transform="translate(17 24)" fill="#C0C9D0" />
        <circle cx="2" cy="2" r="2" transform="translate(11 28)" fill="#C0C9D0" />
        <rect width="11" height="2" rx="1" transform="translate(17 29)" fill="#C0C9D0" />
    </SvgIcon>
);

EpdIcon.defaultProps = {
    fontSize: DEFAULT_SIZE,
    viewBox: "0 0 40 40",
};

export default EpdIcon;
