// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import get from "lodash/get";
import cn from "classnames";

const styles = () => ({
    cursor: {
        cursor: "pointer",
    },
    image: {
        display: "block",
        width: "100%",
    },
});

type Props = {
    nmFileExtensions?: string,
    content: Array<{
        imgsrc: string,
        nmLink: string,
        nnOrder: number,
        vlContent: string,
        vlContentB: string,
    }>,
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    isMirrorDisabled?: boolean,
    onClick?: () => void,
};
export class BannerElement extends React.Component<Props> {
    handleLink = (event: SyntheticEvent<>) => {
        event.preventDefault();

        if (get(this.props.content, "0.nmLink")) {
            window.open(get(this.props.content, "0.nmLink"));
        }
    };

    render() {
        const {content, classes = {}, isMirrorDisabled} = this.props;
        const [{imgsrc}] = content.length > 0 ? content : [{}];

        if (imgsrc) {
            return (
                <img
                    src={imgsrc}
                    alt=""
                    onClick={this.props.onClick ? this.props.onClick : this.handleLink}
                    className={cn(classes.image, {
                        [classes.cursor]: !isMirrorDisabled,
                    })}
                />
            );
        }

        return null;
    }
}

export default withStyles(styles)(BannerElement);
