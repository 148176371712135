// @flow
import React from "react";
import banerIcon from "../../../images/providers/sar/logo/SarLogo.png";
import {SMALL_ICON_SIZE} from "../../../constants/iconConstants";

type Props = {
    fontSize?: number,
};

const SarLogoIcon = ({fontSize}: Props) => <img height={fontSize} width={fontSize} src={banerIcon} alt="" />;

SarLogoIcon.defaultProps = {
    fontSize: SMALL_ICON_SIZE,
};

export default SarLogoIcon;
