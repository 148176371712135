// @flow

import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import Button from "../../packages/components/Button/Button";
import Typography from "../../packages/components/Typography/Typography";

const styles = (theme: any) => ({
    content: {
        height: `calc(100vh - ${theme.sizing.appbarHeight}px)`,
        width: "100%",
    },
});

type Props = {
    classes: {
        [$Keys<$Call<typeof styles>>]: string,
    },
};

export const DummyPage = ({classes}: Props) => (
    <Grid container spacing={0} className={classes.content} alignItems="center" direction="column" justify="center">
        <Grid item>
            <Typography fontSize={16}>Данный раздел находится в разработке</Typography>
        </Grid>
        <Grid>
            <Button underline="dashed" link size="sm" component={Link} to="">
                Назад
            </Button>
        </Grid>
    </Grid>
);

export default withStyles(styles)(DummyPage);
