// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {type IconPropsType} from "./iconType";

const SelectInputIcon = (props: IconPropsType) => (
    <SvgIcon {...props}>
        <path d="M7 10l5 5 5-5z" />
    </SvgIcon>
);

SelectInputIcon.defaultProps = {
    style: {
        color: "rgba(0, 0, 0, 0.54)",
        pointerEvents: "none",
        position: "absolute",
        right: 0,
        top: "calc(50% - 12px)",
    },
    viewBox: "0 0 24 24",
};

export default SelectInputIcon;
