// @flow
import moment from "moment";

export const getAnnulReasonsConfig = (reasons: Array<Object>) => {
    const dict = [];

    reasons.forEach((reason: Object) => {
        const {kdReasonAnnulment, nmReasonAnnulment} = reason;

        dict.push({nmValue: nmReasonAnnulment, nnCode: kdReasonAnnulment});
    });

    const config = {
        kdAttribute: 1,
        kdEntity: 1,
        nmAttrDataType: "INT",
        nmAttrType: "DICT",
        nmAttribute: "Причина",
        nmColumn: "kdReasonAnnulment",
        nmEntity: "Учетная запись",
        nmTable: "LKA.LKA_PROFILE",
        nnOrder: 1,
        prBase: false,
        prRequired: true,
        prVisible: true,
        vlDict: dict,
    };

    return config;
};

export const getAnnulReasonsDateCofig = () => {
    const data = {
        today: `${moment().format("DD.MM.YYYY")}`,
    };
    const fields = [
        {
            class: "FIELD",
            pattern: "$0",
            src: [
                {
                    key: "today",
                    type: "string",
                },
            ],
            title: "Дата отключения",
        },
    ];

    return {data, fields};
};
