// @flow
import {type FetchingReducersType} from "../../redux/reducers/fetchingReducer";

type makeFetchingReducerParamsType = Array<string>;

export function makeFetchingReducer(params: makeFetchingReducerParamsType) {
    const [payload, success, failure] = params;

    return {
        [failure]: (state: FetchingReducersType, action: any) => ({
            ...state,
            [action.payload.name]: false,
        }),
        [payload]: (state: FetchingReducersType, action: any) => ({
            ...state,
            [action.payload.name]: true,
        }),
        [success]: (state: FetchingReducersType, action: any) => ({
            ...state,
            [action.payload.name]: false,
        }),
    };
}
