// @flow
export const cacheQueryList = [
    "GetSectionElements",
    "GetRegistrationElements",
    "GetLSAddElements",
    "GetAuthElements",
    "GetLSRegistrationElements",
    "CurrentBalance",
];

export const dtCacheNames = {
    CurrentBalance: "CurrentBalanceDtCache",
    GetAuthElements: "GetAuthElementsDtCache",
    GetLSAddElements: "GetLSAddElementsDtCache",
    GetLSRegistrationElements: "GetLSRegistrationElementsDtCache",
    GetRegistrationElements: "GetRegistrationElementsDtCache",
    GetSectionElements: "GetSectionElementsDtCache",
};

export type DtCacheNamesType = $Keys<typeof dtCacheNames>;
