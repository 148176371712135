// @flow
import React, {useMemo} from "react";
import {Form as ReactFinalForm} from "react-final-form";
import createDecorator, {getFormInputs} from "final-form-focus";
import {findFirstInput} from "./utils";

export const Form = ({decorators, formName, ...otherProps}: any) => {
    const focusOnError = useMemo(
        () => (formName ? createDecorator(getFormInputs(formName), findFirstInput) : createDecorator()),
        [formName],
    );

    return <ReactFinalForm decorators={decorators ? [...decorators, focusOnError] : [focusOnError]} {...otherProps} />;
};

export default Form;
