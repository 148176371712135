// @flow
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import {withStyles} from "@material-ui/core/styles";
import Typography, {
    type TypographyFontSize,
    type TypographyHorizontalPadding,
} from "../../../components/Typography/Typography";
import {validPattern} from "../../../../utils/objectUtils";
import BPattern from "../../BPattern/BPattern";

const styles = (theme: any) => ({
    mobileTextAlign: {
        textAlign: "right",

        [theme.breakpoints.down("xs")]: {
            textAlign: "left",
        },
    },
    nonMobileDirection: {
        textAlign: "right",
    },
});

export type Props = {
    data: any,
    field: Object,
    firstGray?: boolean,
    renderIcon?: (Object) => React$Node,
    fontSize?: TypographyFontSize,
    horizontalPadding?: TypographyHorizontalPadding,
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    mobileDirection?: boolean,
};

const FULL_WIDTH = 12;
const HALF_WIDTH = 6;

export const TarrifVl = ({
    fontSize,
    firstGray,
    horizontalPadding,
    data,
    field,
    classes = {},
    renderIcon,
    mobileDirection,
}: Props) => (
    <Grid container spacing={16} alignItems="center" testID={get(field, "src[0].key", "TarrifVl")}>
        <Grid item xs={mobileDirection ? FULL_WIDTH : HALF_WIDTH} sm={6}>
            <Typography
                align="left"
                fontSize={fontSize}
                textColor={firstGray ? "grey" : undefined}
                horizontalPadding={horizontalPadding}
                testID={`${get(field, "src[0].key", "TarrifVl")}-left`}
            >
                <BPattern data={data} src={field.src} pattern={field.title} />
            </Typography>
        </Grid>
        <Grid item xs={mobileDirection ? FULL_WIDTH : HALF_WIDTH} sm={6}>
            {field.fields
                ? field.fields.map((subfield, subfieldIndex) => {
                      const isNull = validPattern(data, subfield.src, subfield.title);

                      return isNull ? (
                          <Typography
                              key={subfieldIndex}
                              className={mobileDirection ? classes.mobileTextAlign : classes.nonMobileDirection}
                              fontSize={fontSize}
                              horizontalPadding={horizontalPadding}
                              testID={`${get(field, "src[0].key", "TarrifVl")}-right-${subfieldIndex}`}
                          >
                              <BPattern data={data} src={subfield.src} pattern={subfield.pattern} />
                          </Typography>
                      ) : null;
                  })
                : null}
        </Grid>

        {renderIcon ? (
            <Grid item xs={1}>
                {renderIcon({data, field})}
            </Grid>
        ) : null}
    </Grid>
);

export default withStyles(styles)(TarrifVl);
