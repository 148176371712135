// @flow
import * as actionTypes from "../../constants/types/AdPopupTypes";
import {type ApiMiddlewareActionType} from "../../redux/middlewares/apiMiddleware";

export const getAdElementsPopupAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        query: "GetAdElementsPopUp",
        session,
        types: [
            actionTypes.GET_AD_ELEMENTS_POPUP_ACTION,
            actionTypes.GET_AD_ELEMENTS_POPUP_ACTION_SUCCESS,
            actionTypes.GET_AD_ELEMENTS_POPUP_ACTION_FAILURE,
        ],
    },
});
