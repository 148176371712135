// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {SMALL_ICON_SIZE} from "../../../constants/iconConstants";
import {type IconPropsType} from "./iconType";

const AddAccountIcon = ({fontSize, ...otherProps}: IconPropsType) => (
    <SvgIcon {...otherProps} style={{fontSize}}>
        <path
            d={
                "M240,0C107.452,0,0,107.452,0,240s107.452,240,240," +
                "240s240-107.452,240-240C479.85,107.514,372.486,0.15," +
                "240,0z M240,464 C116.288,464,16,363.712,16,24" +
                "0S116.288,16,240,16s224,100.288,224,224C463.859,363.653,3" +
                "63.653,463.859,240,464z"
            }
        />
        <path
            d={
                "M336,232h-88v-88c0-4.418-3.582-8-8-8s-8,3.582-8,8v88h-8" +
                "8c-4.418,0-8,3.582-8,8s3.582,8,8,8h88v88c0,4.418,3.582," +
                "8,8,8 s8-3.582,8-8v-88h88c4.418,0,8-3.582,8-8S34" +
                "0.418,232,336,232z"
            }
        />
    </SvgIcon>
);

AddAccountIcon.defaultProps = {
    color: "primary",
    fontSize: SMALL_ICON_SIZE,
    viewBox: "0 0 480 480",
};

export default AddAccountIcon;
