// @flow
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import {withStyles} from "@material-ui/core/styles";
import Typography, {
    type TypographyFontSize,
    type TypographyHorizontalPadding,
} from "../../components/Typography/Typography";
import BPattern from "../BPattern/BPattern";

const styles = (theme: any) => ({
    mobileTextAlign: {
        textAlign: "right",

        [theme.breakpoints.down("xs")]: {
            textAlign: "left",
        },
    },
    nonMobileDirection: {
        textAlign: "right",
    },
    verticalCenter: {
        margin: "auto",
    },
});

export type Props = {
    data: any,
    field: Object,
    firstGray?: boolean,
    secondGray?: boolean,
    renderIcon?: (Object) => React$Node,
    fontSize?: TypographyFontSize,
    horizontalPadding?: TypographyHorizontalPadding,
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    mobileDirection?: boolean,
    onlySecondRow: boolean,
    verticalCenter?: boolean,
};

const FULL_WIDTH = 12;
const HALF_WIDTH = 6;
const FULL_WIDTH_WITH_ICON = 11;
const HALF_WIDTH_WITH_ICON = 5;

export class DefaultBehavior extends React.Component<Props> {
    static defaultProps = {
        onlySecondRow: false,
    };

    getXsSize = () => {
        const {mobileDirection, onlySecondRow, renderIcon} = this.props;

        switch (true) {
            case Boolean((mobileDirection || onlySecondRow) && !renderIcon):
                return FULL_WIDTH;
            case Boolean((mobileDirection || onlySecondRow) && renderIcon):
                return FULL_WIDTH_WITH_ICON;
            case Boolean(renderIcon):
                return HALF_WIDTH_WITH_ICON;
            default:
                return HALF_WIDTH;
        }
    };

    getSmSize = () => {
        const {onlySecondRow, renderIcon} = this.props;

        switch (true) {
            case Boolean(onlySecondRow && !renderIcon):
                return FULL_WIDTH;
            case Boolean(onlySecondRow && renderIcon):
                return FULL_WIDTH_WITH_ICON;
            case Boolean(renderIcon):
                return HALF_WIDTH_WITH_ICON;
            default:
                return HALF_WIDTH;
        }
    };

    renderSecondColumn = () => {
        const {
            fontSize,
            horizontalPadding,
            data,
            field,
            renderIcon,
            secondGray,
            classes = {},
            mobileDirection,
            verticalCenter,
        } = this.props;

        return (
            <Grid
                item
                xs={this.getXsSize()}
                sm={this.getSmSize()}
                className={verticalCenter ? classes.verticalCenter : ""}
            >
                <Typography
                    className={mobileDirection ? classes.mobileTextAlign : classes.nonMobileDirection}
                    textColor={secondGray ? "grey" : undefined}
                    fontSize={fontSize}
                    horizontalPadding={renderIcon ? 0 : horizontalPadding}
                    testID={`${get(field, "src[0].key", "")}-right`}
                    wordWrap="normal"
                >
                    <BPattern data={data} src={field.src} pattern={field.pattern} />
                </Typography>
            </Grid>
        );
    };

    render() {
        const {
            fontSize,
            firstGray,
            horizontalPadding,
            data,
            field,
            renderIcon,
            classes = {},
            mobileDirection,
            onlySecondRow,
            verticalCenter,
        } = this.props;

        return (
            <Grid container spacing={16} testID={`BRowField-${get(field, "src[0].key", "")}`} justify="center">
                {onlySecondRow ? null : (
                    <Grid
                        item
                        xs={mobileDirection ? FULL_WIDTH : HALF_WIDTH}
                        sm={6}
                        className={verticalCenter ? classes.verticalCenter : ""}
                        style={{alignSelf: "center"}}
                    >
                        <Typography
                            align="left"
                            fontSize={fontSize}
                            textColor={firstGray ? "grey" : undefined}
                            horizontalPadding={horizontalPadding}
                            testID={`${get(field, "src[0].key", "")}-left`}
                            wordWrap="normal"
                        >
                            <BPattern data={data} src={field.src} pattern={field.title} />
                        </Typography>
                    </Grid>
                )}
                {this.renderSecondColumn()}
                {renderIcon ? (
                    <Grid item xs={1}>
                        {renderIcon({data, field})}
                    </Grid>
                ) : null}
            </Grid>
        );
    }
}

export default withStyles(styles)(DefaultBehavior);
