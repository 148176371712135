// @flow

import React from "react";
import {conformToMask} from "text-mask-core";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import Form from "../../../packages/FormBuilder/Form";
import DialogContent from "../../../packages/components/Dialog/DialogContent";
import DialogTitle from "../../../packages/components/Dialog/DialogTitle";
import {type StoreType} from "../../../redux/reducers";
import WizardButtons, {type WizardButtonsProps} from "../../../packages/components/Wizard/WizardButtons";
import DialogActions from "../../../packages/components/Dialog/DialogActions";
import {type ProfileEditActionType} from "../../../redux/actions/profileAction";
import Typography from "../../../packages/components/Typography/Typography";
import FieldBuilder from "../../../packages/FormBuilder/FieldBuilder";
import {phoneMask} from "../../../constants/Mask";
import Button from "../../../packages/components/Button/Button";
import {styles} from "./styles";

type Props = {
    attribute: Object,
    buttonProps: WizardButtonsProps,
    onChangePhone: Function,
    onClose: $PropertyType<WizardButtonsProps, "onPrev">,
    onNext: $PropertyType<WizardButtonsProps, "onNext">,
    profileEditAction: ProfileEditActionType,
    auth: Object,
    profileBuilder: $PropertyType<$PropertyType<StoreType, "builder">, "profile">,
    classes: Object,
};

const SUBSCRIPTION = {submitting: true};
const INITIAL_VALUES = {
    NN_PHONE: "",
};

const ProfilePhoneAuthConfirmMain = (props: Props) => {
    const [isEnteredPhone, setIsEnteredPhone] = React.useState(false);
    const {auth = {}, attribute, buttonProps, onClose, onChangePhone, classes} = props;

    const handleNewPhoneConfirm = (values: any) => {
        onChangePhone(true, values.NN_PHONE);
    };

    const handleCurrentPhoneConfirm = () => {
        onChangePhone(false, attribute.vlAttribute);
    };

    const handleChangePhone = (value: any) => {
        // eslint-disable-next-line no-magic-numbers
        setIsEnteredPhone(value.length > 9);
    };

    return (
        <Form
            formName="ProfilePhoneAuthConfirmMain"
            onSubmit={handleNewPhoneConfirm}
            subscription={SUBSCRIPTION}
            initialValues={INITIAL_VALUES}
            render={({handleSubmit, submitting}) => (
                <form onSubmit={handleSubmit} name="profilePhoneChangeMain">
                    <DialogTitle>
                        <Typography fontSize={22} wordWrap="normal">
                            Подтвердите номер телефона
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={8}>
                            <Grid item xs={12}>
                                <Typography fontSize={14}>
                                    Уважаемый клиент, указанный Вами номер телефона&nbsp;
                                    <b>{conformToMask(`${attribute.vlAttribute || ""}`, phoneMask).conformedValue}</b>
                                    &nbsp;не подтвержден, просим Вас подтвердить его актуальность
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    size="lg"
                                    color="secondary"
                                    onClick={handleCurrentPhoneConfirm}
                                    disabled={isEnteredPhone || auth.prMirror}
                                    className={classes.fullWidth}
                                >
                                    ПОДТВЕРДИТЬ НОМЕР ТЕЛЕФОНА
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.dividerMainBlock}>
                                    <div className={classes.dividerBlock} />
                                    <div className={classes.dividerText}>ИЛИ</div>
                                    <div className={classes.dividerBlock} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <FieldBuilder
                                    {...attribute}
                                    nmAttribute="Введите новый номер телефона"
                                    onChange={handleChangePhone}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <WizardButtons
                            {...buttonProps}
                            nextStepName="ПРОДОЛЖИТЬ"
                            lastStepName="ПРОДОЛЖИТЬ"
                            onNext={handleSubmit}
                            onPrev={onClose}
                            disabled={submitting || !isEnteredPhone || auth.prMirror}
                        />
                    </DialogActions>
                </form>
            )}
        />
    );
};

export default withStyles(styles)(ProfilePhoneAuthConfirmMain);
