/* eslint-disable react/display-name */
// @flow
import * as React from "react";
import cn from "classnames";
import ErrorIcon from "@material-ui/icons/Error";
import {withStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "../../../packages/components/Typography/Typography";
import {reachGoal} from "../../../utils/metricUtils";
import {styles} from "./styles";

export type Props = {
    classes: Object,
    pathname: string,
    text: string,
    className?: string,
    id?: string,
    testID?: string,
    search?: string,
    selected?: boolean,
    menuItem: Object,
};

export const TopMenuSimpleBtn = React.memo<Props>((props: Props) => {
    const {className, classes = {}, pathname, search, testID, id, text, selected, menuItem} = props;
    const to = React.useMemo(
        () => ({
            pathname,
            search,
        }),
        [pathname, search],
    );

    const classNames = React.useMemo(
        () =>
            cn(classes.root, className, {
                [classes.selected]: selected,
            }),
        [className, classes.root, classes.selected, selected],
    );

    const handleClickButton = () => {
        if (menuItem.nmMetric) {
            reachGoal(menuItem.nmMetric);
        }
    };

    return (
        <ButtonBase
            className={classNames}
            to={to}
            component={Link}
            testID={testID}
            id={id}
            disableRipple
            onClick={handleClickButton}
        >
            <Typography
                className={pathname === "/pay-additional-services" && classes.payAdditionalServicesBtn}
                align="center"
                fontSize={14}
                fontWeight={400}
                textColor="white"
            >
                {text}
            </Typography>
            {pathname === "/pay-additional-services" ? <ErrorIcon className={classes.icon} /> : null}
        </ButtonBase>
    );
});

export default withStyles(styles)(TopMenuSimpleBtn);
