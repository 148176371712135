// @flow

import React from "react";
import {Field} from "react-final-form";
import {conformToMask} from "text-mask-core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Help from "@material-ui/icons/Help";
import Form from "../../../packages/FormBuilder/Form";
import {
    type PhoneChangeConfirmActionType,
    type SendSmsPhoneChangeRepeatActionType,
    type PhoneSendSmsConfirmRepeatActionType,
} from "../../../redux/actions/phoneAction";
import {type BuilderGetGroupAttributesType} from "../../../redux/actions/builderAction";
import DialogContent from "../../../packages/components/Dialog/DialogContent";
import DialogTitle from "../../../packages/components/Dialog/DialogTitle";
import {phoneMask} from "../../../constants/Mask";
import WizardButtons, {type WizardButtonsProps} from "../../../packages/components/Wizard/WizardButtons";
import DialogActions from "../../../packages/components/Dialog/DialogActions";
import Typography from "../../../packages/components/Typography/Typography";
import LinkTimer from "../../../packages/components/LinkTimer/LinkTimer";
import TextField from "../../../packages/components/Form/TextField";
import {required} from "../../../packages/validations/baseValidations";
import type {IsConfirmActionType, GetAdSubscrStatusActionType} from "../../../redux/actions/profileAction";
import type {AuthPhoneConfirmActionType} from "../../../redux/actions/authAction";

type Props = {
    auth: Object,
    buttonProps: WizardButtonsProps,
    phone: string,
    isCurrentPhone?: boolean,
    onClose: Function,
    onRedirectToAccount?: () => void,
    phoneChangeConfirmAction: PhoneChangeConfirmActionType,
    sendSmsPhoneChangeRepeatAction: SendSmsPhoneChangeRepeatActionType,
    getBuilderGroupAttributesAction?: BuilderGetGroupAttributesType,
    isConfirmAction?: IsConfirmActionType,
    getAdSubscrStatusAction?: GetAdSubscrStatusActionType,
    authPhoneConfirmAction?: AuthPhoneConfirmActionType,
    phoneSendSmsConfirmRepeatAction?: PhoneSendSmsConfirmRepeatActionType,
};

class ProfilePhoneChangeSmsConfirm extends React.Component<Props> {
    handleChangeSmsConfirm = async (values: any) => {
        const {session, idProfile} = this.props.auth;
        const res =
            this.props.isCurrentPhone && this.props.authPhoneConfirmAction
                ? await this.props.authPhoneConfirmAction(values.code, idProfile)
                : await this.props.phoneChangeConfirmAction(session || idProfile, values.code);

        if (!res.error) {
            if (this.props.isConfirmAction) {
                await this.props.isConfirmAction(session);
            }
            if (this.props.getBuilderGroupAttributesAction) {
                await this.props.getBuilderGroupAttributesAction("profile", session);
            }
            if (this.props.getAdSubscrStatusAction) {
                await this.props.getAdSubscrStatusAction(session);
            }
            if (this.props.onRedirectToAccount) {
                await this.props.onRedirectToAccount();
            }

            this.props.onClose();
        }
    };

    handleSmsSend = () => {
        const {session, idProfile} = this.props.auth;
        const {phone} = this.props;

        this.props.isCurrentPhone && this.props.phoneSendSmsConfirmRepeatAction
            ? this.props.phoneSendSmsConfirmRepeatAction(phone, idProfile)
            : this.props.sendSmsPhoneChangeRepeatAction(phone, session || idProfile);
    };

    render() {
        const {buttonProps, onClose} = this.props;
        let {phone = ""} = this.props;
        const codeInfo = (
            <InputAdornment position="end">
                <Tooltip
                    enterTouchDelay={0}
                    title="На указанный мобильный телефон будет выслан SMS-код для подтверждения регистрации."
                >
                    <Help color="primary" />
                </Tooltip>
            </InputAdornment>
        );
        const phoneLength = 10;

        if (phone.length !== phoneLength) {
            phone = phone.replace(/^\d/g, "").substr(-phoneLength);
        }
        phone = `7${phone}`;

        return (
            <Form
                formName="profilePhoneChangeSms"
                onSubmit={this.handleChangeSmsConfirm}
                subscription={{submitting: true}}
                render={({handleSubmit}) => (
                    <form onSubmit={handleSubmit} name="profilePhoneChangeSms">
                        <DialogTitle disableTypography>
                            <Typography fontSize={22} lineHeight={1}>
                                Подтверждение
                            </Typography>
                        </DialogTitle>

                        <DialogContent>
                            <Typography fontSize={16} wordWrap="normal">
                                Вам выслан код на номер{" "}
                                <Typography fontSize={16} whiteSpace="nowrap" display="inline">
                                    {conformToMask(phone, phoneMask).conformedValue}
                                </Typography>
                            </Typography>

                            <Field
                                validate={required}
                                name="code"
                                render={({input, meta}) => (
                                    <TextField
                                        InputProps={{...input, endAdornment: codeInfo}}
                                        label="Код"
                                        error={meta.touched && meta.invalid}
                                        helperText={meta.touched && meta.error}
                                        required
                                    />
                                )}
                            />
                            <LinkTimer onClickLink={this.handleSmsSend} withStartTimer />
                        </DialogContent>

                        <DialogActions>
                            <WizardButtons
                                {...buttonProps}
                                lastStepName="Подтвердить"
                                onNext={handleSubmit}
                                onPrev={onClose}
                            />
                        </DialogActions>
                    </form>
                )}
            />
        );
    }
}

export default ProfilePhoneChangeSmsConfirm;
