// @flow
import merge from "lodash/merge";
import isString from "lodash/isString";
import {uniqueDeviceTokenKey} from "../constants/Application";

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    return msie > 0 || Boolean(navigator.userAgent.match(/Trident.*rv:11\./));
};

export const isEdge = () => /Edge\/\d./i.test(window.navigator.userAgent);

export const mergeThemeStyle = (mainThemeStyle: Object, overrides: Object) => ({
    ...mainThemeStyle,
    overrides: merge(mainThemeStyle.overrides, overrides),
});

const DEFAULT_DELAY = 1000;
const MAX_FOUND_COUNT = 10;
const ANIMATE_PARAMS = [
    {
        background: "rgba(248, 143, 31, 0.5)",
    },
    {
        background: "#f0f8ff",
    },
];
const ANIMATE_CONFIG = {
    duration: 800,
    easing: "ease-in",
};

export const removeQueryParam = (history: Object, paramKey: string) => {
    const {location} = history;
    const searchParams = new URLSearchParams(location.search);

    searchParams.delete(paramKey);

    history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
    });
};

export const backlightElement = (
    id: string,
    {
        history,
        params = ANIMATE_PARAMS,
        config = ANIMATE_CONFIG,
    }: {
        history?: Object,
        params?: Object,
        config?: Object,
    },
) => {
    const run = (element) => element && element.animate(params, config);
    let foundCount = 0;
    const intervalId = setInterval(() => {
        const element = id && document.getElementById(id);

        if (element) {
            setTimeout(() => run(element), DEFAULT_DELAY);
            setTimeout(() => run(element), DEFAULT_DELAY * 2);
            setTimeout(() => run(element), DEFAULT_DELAY * 3);

            clearInterval(intervalId);
            removeQueryParam(history, "backlightId");

            return;
        }

        if (foundCount < MAX_FOUND_COUNT) {
            foundCount += 1;
        } else {
            clearInterval(intervalId);
        }
    }, DEFAULT_DELAY);
};

export const setDeviceToken = (value: string) => isString(value) && localStorage.setItem(uniqueDeviceTokenKey, value);

export const getDeviceToken = (): string | null => localStorage.getItem(uniqueDeviceTokenKey) || null;
