// @flow
import {
    required,
    regexpValidate,
    fieldSizeValidate,
    passwordRepeat,
    maskRegexpValidate,
    uploadFileGroupValidator,
    valueRepeat,
} from "./baseValidations";
import {emailFormatValidationBase} from "./emailValidations";

export const firstValidatorsGroup = (props: Object) => {
    const {prRequired, mask, nmRegexp, nmRegexpError, nmAttrType, documentsData} = props;
    const validation = [];

    if (prRequired) {
        validation.push(required);
    }
    if (nmRegexp && mask) {
        validation.push(maskRegexpValidate(nmRegexp, nmRegexpError));
    }
    if (nmAttrType === "DOCUMENT") {
        validation.push(uploadFileGroupValidator(documentsData));
    }

    return validation;
};

export const secondValidatorsGroup = (props: Object) => {
    const {mask, nmRegexp, nmRegexpError, nmFormatRegexp, nnFieldSize, nmColumn, nmDomain} = props;
    const regexp = nmRegexp || nmFormatRegexp;
    const validation = [];

    if (regexp && !mask) {
        if (nmDomain === "PHONE_DOMAIN") {
            validation.push(regexpValidate(regexp.replace("[7]{1}", ""), nmRegexpError));
        } else {
            validation.push(regexpValidate(regexp, nmRegexpError));
        }
    }

    if (nnFieldSize) {
        validation.push(fieldSizeValidate(nnFieldSize));
    }

    if (nmColumn === "PSW_RPT" || nmColumn === "NEW_PSW_RPT") {
        validation.push(passwordRepeat);
    }

    return validation;
};

export const thirdValidatorsGroup = ({prMultipleVals, nmColumn}: Object) => {
    const validation = [];

    if (prMultipleVals) {
        validation.push(valueRepeat(nmColumn));
    }

    return validation;
};

export const emailValidatorsGroup = (props: Object) => {
    const validation = [];

    if (props?.nmDomain === "EMAIL_DOMAIN") {
        validation.push(emailFormatValidationBase());
    }

    return validation;
};

export const validators = (props: Object) => [
    ...firstValidatorsGroup(props),
    ...secondValidatorsGroup(props),
    ...thirdValidatorsGroup(props),
    ...emailValidatorsGroup(props),
];
