// @flow
import statisticsData from "../../../mocks/dataset/chart/statisticsData.json";
import panelHeadData from "./panelPage/data/panelHeadData.json";
import panelHeadMetadata from "./panelPage/metadata/panelHeadMetadata.json";
import panelRowsData from "./panelPage/data/panelRowsData.json";
import panelRowsMetadata from "./panelPage/metadata/panelRowsMetadata.json";
import panelTabsData from "./panelPage/data/panelTabsData.json";
import panelTabsMetadata from "./panelPage/metadata/panelTabsMetadata.json";
import tableData from "./modalPage/data/tableData.json";
import tableMetadata from "./modalPage/metadata/tableMetadata.json";
import checkBoxConfig from "./modalPage/elementConfigs/checkBoxConfig.json";
import table3MData from "./TablesPage/data/table3MData.json";
import table6MData from "./TablesPage/data/table6MData.json";
import tableYearData from "./TablesPage/data/tableYearData.json";
import listMetadata from "./TablesPage/metadata/listMetadata.json";
import list3MData from "./TablesPage/data/list3MData.json";
import list6MData from "./TablesPage/data/list6MData.json";
import listYearData from "./TablesPage/data/listYearData.json";
import listCustomData from "./TablesPage/data/listCustomData.json";
import subStrFields from "./TablesPage/metadata/subStrFields.json";
import accountDetail from "./Baner/data/accountDetail.json";
import mesDetails from "./Baner/data/mesDetails.json";
import currentBalanceData from "./Baner/data/currentBalanceData.json";
import indicationCounterData from "./Baner/data/indicationCounterData.json";
import currentBalanceMetadata from "./Baner/metadata/currentBalanceMetadata.json";

export const baseMocks = {
    accountDetail,
    currentBalanceData,
    currentBalanceMetadata,
    indicationCounterData,
    list3MData,
    list6MData,
    listCustomData,
    listYearData,
    mesDetails,
    modalPageElements: [checkBoxConfig],
    modalTableData: tableData,
    modalTableMetadata: tableMetadata,
    panelHeadData,
    panelHeadMetadata,
    panelRowsData,
    panelRowsMetadata,
    panelTabsData,
    panelTabsMetadata,
    simpleListMetadata: listMetadata,
    statisticsData,
    substrListMetadata: subStrFields,
    tableMetadata,
    tablePage3MData: table3MData,
    tablePage6MData: table6MData,
    tablePageYearData: tableYearData,
};
