// @flow
import * as actionTypes from "../../constants/types/ActionTypes";

export const systemErrorSetMessages = (info: any) => ({
    payload: info,
    type: actionTypes.SET_SYSTEM_ERROR_ACTION,
});

export const systemErrorCloseMessageAction = (code: number) => ({
    payload: {code},
    type: actionTypes.SYSTEM_ERROR_CLOSE_MESSAGE_ACTION,
});
