// @flow
import * as actionTypes from "../../constants/types/ActionTypes";
import {createReducer} from "./createReducer";

export type FetchingReducerType = {
    fetchingCounter: number,
};
const initialState = {
    fetchingCounter: 0,
};

export const fetchingReducer = createReducer(initialState, {
    [actionTypes.INCREMENT_FETCHING_ACTION]: (state: FetchingReducerType): FetchingReducerType => ({
        fetchingCounter: state.fetchingCounter + 1,
    }),
    [actionTypes.DECREMENT_FETCHING_ACTION]: (state: FetchingReducerType): FetchingReducerType => ({
        fetchingCounter: state.fetchingCounter - 1,
    }),
});
