// @flow
import * as React from "react";
import trim from "lodash/trim";
import TextField from "../../components/Form/TextField";
import {getCaretPosition} from "../../../utils/caretPosition";

type Props = {
    InputProps: Object,
    config: Object,
};

const DEFAULT_FIELD_SIZE = 30;
const SPACE_CHAR_CODE = 32;

const PromocodeTextField = (props: Props) => {
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const handleChange = React.useCallback(
        (event: SyntheticEvent<HTMLInputElement>) => {
            const {value} = event.currentTarget;

            if (value !== trim(value)) {
                event.currentTarget.value = trim(value);
            }
            if (value?.length <= (props?.config?.nnFieldSize || DEFAULT_FIELD_SIZE)) {
                props.InputProps.onChange(event);
            }
        },
        [props.config, props.InputProps.onChange],
    );
    const handleFocus = React.useCallback(
        (event: SyntheticEvent<HTMLInputElement>) => {
            if (props.config.isSuccessful) {
                event.currentTarget.blur();
            }
        },
        [props.config],
    );
    const handleKeyPress = React.useCallback(
        (event: any) => {
            const input = inputRef.current;
            const inputCarret = getCaretPosition(input);

            if (event.charCode === SPACE_CHAR_CODE && (inputCarret === 0 || inputCarret === input?.value.length)) {
                event.preventDefault();
            }
        },
        [inputRef.current, getCaretPosition],
    );

    return (
        <TextField
            {...props}
            inputRef={inputRef}
            InputProps={{
                ...props.InputProps,
                onChange: handleChange,
                onFocus: handleFocus,
                onKeyPress: handleKeyPress,
            }}
        />
    );
};

export default PromocodeTextField;
