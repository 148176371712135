// @flow
import * as React from "react";
import get from "lodash/get";
import type {Location} from "react-router-dom";
import Form from "../../packages/FormBuilder/Form";
import type Store from "../../redux/configureStore";
import {ModalConfirm} from "../../packages/components/Modal/ModalConfirm";
import FieldBuilder from "../../packages/FormBuilder/FieldBuilder";
import Typography from "../../packages/components/Typography/Typography";
import BElement from "../../packages/Builder/BElement/BElement";
import type {BuilderGetAttributesType, BuilderGetElementsType} from "../../redux/actions/builderAction";
import Loader from "../../packages/components/Loader/Loader";
import type {BuilderStateType} from "../../constants/types/BuilderTypes";
import {toRequestServiceAttributes} from "../../utils/formUtils";
import type {
    AuthLoginActionType,
    ExtraRegistrationProfileEditActionType,
    OpenConfirmEmailOrSMSModalActionType,
} from "../../redux/actions/authAction";

type State = {
    disabledElements: Array<number>,
};

type Props = {
    mode: string,
    settings: Object,
    closeExtraRegistrationFormAction: () => void,
    extraRegistrationBuilder: BuilderStateType,
    getExtraRegistrationAttributesAction: BuilderGetAttributesType,
    getBuilderElementsAction: BuilderGetElementsType,
    auth: Store.auth,
    extraRegistrationProfileEditAction: ExtraRegistrationProfileEditActionType,
    onRedirect: (path: string) => void,
    location: Location,
    authLoginAction: AuthLoginActionType,
    onOpenPhoneEmailChange?: (nnPhone: string) => void,
    openConfirmEmailOrSMSModalAction: OpenConfirmEmailOrSMSModalActionType,
};

const USERS_AGREEMENT_ELEMENT_CODE = 91;

class AuthExtraRegistrationForm extends React.Component<Props, State> {
    state = {
        disabledElements: [],
    };

    async componentDidMount() {
        const {idProfile} = this.props.auth;

        if (this.props.mode === "auth") {
            const res = await this.props.getExtraRegistrationAttributesAction("extraRegistration", idProfile);

            if (!res.error && res.response) {
                const updatedDisabledElements = res.response.disabledElements.map((element) => element.kdElement);

                this.setState({disabledElements: updatedDisabledElements});
            }
            this.props.getBuilderElementsAction("extraRegistration");
        } else if (this.props.mode === "notification") {
            const res = await this.props.getExtraRegistrationAttributesAction(
                "extraRegistrationUnnecessaryFields",
                idProfile,
            );

            if (!res.error && res.response) {
                const updatedDisabledElements = res.response.disabledElements.map((element) => element.kdElement);

                this.setState({disabledElements: updatedDisabledElements});
            }
            this.props.getBuilderElementsAction("extraRegistrationUnnecessaryFields");
        }
    }

    handleLogin = async (values: Object) => {
        const {
            auth: {idProfile},
            extraRegistrationProfileEditAction,
            extraRegistrationBuilder: {attributes},
        } = this.props;
        const {providerRegType} = this.props.settings;
        const field = attributes.find(
            (attribute) =>
                (attribute.nmColumn === "NN_PHONE" && providerRegType === "phone") || attribute.nmColumn === "NM_EMAIL",
        );
        const skipResult = Boolean(field);

        const profileEditRes = await extraRegistrationProfileEditAction(
            toRequestServiceAttributes(values, this.props.extraRegistrationBuilder.groupByColumn),
            idProfile,
            skipResult,
        );

        if (profileEditRes.error) {
            return null;
        }

        this.checkField(field, values);

        return null;
    };

    checkField = (field: Object, values: Object) => {
        if (field?.nmColumn === "NN_PHONE") {
            this.props.closeExtraRegistrationFormAction();
            if (this.props.onOpenPhoneEmailChange) {
                this.props.onOpenPhoneEmailChange(values[field.nmColumn]);
            }
        } else {
            this.openConfirmModal(field, values);
            const {state: {backUrl = "/accounts"} = {}} = this.props.location;

            this.props.onRedirect(backUrl);
        }

        return null;
    };

    openConfirmModal = (field: Object, values: Object) => {
        if (field && field.nmColumn === "NM_EMAIL") {
            this.props.openConfirmEmailOrSMSModalAction({
                email: values[field.nmColumn],
                openModalConfirm: true,
                phone: "",
                phoneEmailType: "email",
            });
        }
    };

    render() {
        const {disabledElements} = this.state;
        const {
            auth: {extraRegistrationFormOpen, prMirror},
            extraRegistrationBuilder: {attributes, elements, initialValues, fetching},
        } = this.props;
        const isAttributesEmpty = attributes.length === 0;

        return (
            <Form
                formName="extraRegistrationForm"
                onSubmit={this.handleLogin}
                subscription={{submitting: true}}
                initialValues={{
                    ...initialValues,
                }}
                render={({handleSubmit, submitting}) => (
                    <form onSubmit={handleSubmit} name="extraRegistrationForm">
                        <ModalConfirm
                            open={extraRegistrationFormOpen}
                            title={get(elements["90"], "content[0].vlContent")}
                            onCancel={this.props.closeExtraRegistrationFormAction}
                            onConfirm={handleSubmit}
                            buttonConfirmDisable={submitting || prMirror}
                            buttonConfirmTitle="Продолжить"
                            withoutConfirmBtn={isAttributesEmpty}
                            buttonCancelTitle={isAttributesEmpty ? "Отмена" : "Отменить"}
                        >
                            <Loader fetching={fetching} loaderType="innerLoaderOrData">
                                {isAttributesEmpty ? (
                                    <Typography>Все необходимые данные заполнены.</Typography>
                                ) : (
                                    attributes.map((attribute, idx) => <FieldBuilder key={idx} {...attribute} />)
                                )}
                                {isAttributesEmpty ||
                                disabledElements.indexOf(USERS_AGREEMENT_ELEMENT_CODE) >= 0 ? null : (
                                    <BElement {...elements["91"]} required />
                                )}
                            </Loader>
                        </ModalConfirm>
                    </form>
                )}
            />
        );
    }
}

export default AuthExtraRegistrationForm;
