// @flow

import * as React from "react";
import Dialog from "../../../packages/components/Dialog/Dialog";
import DialogTitle from "../../../packages/components/Dialog/DialogTitle";
import DialogContent from "../../../packages/components/Dialog/DialogContent";
import DialogActions from "../../../packages/components/Dialog/DialogActions";
import Button from "../../../packages/components/Button/Button";
import Typography from "../../../packages/components/Typography/Typography";

type Props = {
    open: boolean,
    scheduledInspections: Array<Object>,
    onConfirm: (idSchedule: string) => void,
    onClose: () => void,
};

const ScheduledInspectionModal = (props: Props) => {
    const {open, scheduledInspections, onConfirm, onClose} = props;
    const [page, setPage] = React.useState<number>(0);
    const currentData = scheduledInspections[page];
    const handleConfirm = () => {
        onConfirm(currentData.idSchedule);
        if (page < scheduledInspections.length - 1) {
            setPage(page + 1);
        } else {
            onClose();
        }
    };

    React.useEffect(() => {
        if (open) {
            setPage(0);
        }
    }, [open]);

    return (
        <Dialog open={open}>
            <DialogTitle>Внимание</DialogTitle>
            <DialogContent>
                <Typography
                    fontSize={16}
                    verticalPadding={8}
                    dangerouslySetInnerHTML={{__html: currentData?.nmMessage}}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" size="sm" textColor="white" onClick={handleConfirm}>
                    Ознакомлен
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ScheduledInspectionModal;
