// @flow
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Panel from "../../../packages/components/Panel/Panel";
import PanelHead from "../../../packages/components/Panel/PanelHead";
import PanelBody from "../../../packages/components/Panel/PanelBody";
import Typography from "../../../packages/components/Typography/Typography";
import AccTransfIndicatCountQuestIcon from "../../../packages/components/Icons/AccTransfIndicatCountQuestIcon";
import Content from "../../../packages/components/Content/Content";
import {NoData} from "../../../packages/components/NoData/NoData";

type Props = {
    data: Array<Object>,
    fetching?: boolean,
};

export const UserInstruction = ({data, fetching}: Props) => {
    if (!data || data.length === 0) {
        return <NoData fetching={fetching} />;
    }

    return data.map<React.Node>((faqListItem: Object, index: number): React.Node => (
        <Content key={index} vertical={10}>
            <Panel>
                <PanelHead withoutIcon cursorPointer testID={`questionHeader-${index}`}>
                    <Grid container spacing={16} alignItems="center" wrap="nowrap">
                        <Grid item>
                            <AccTransfIndicatCountQuestIcon size={44} />
                        </Grid>
                        <Grid item xs>
                            <Typography fontSize={20} textColor="primary-light">
                                {faqListItem.nmQuestion}
                            </Typography>
                        </Grid>
                    </Grid>
                </PanelHead>
                <PanelBody>
                    <Typography
                        fontSize={16}
                        verticalPadding={8}
                        dangerouslySetInnerHTML={{__html: faqListItem.nmAnswer}}
                    />
                </PanelBody>
            </Panel>
        </Content>
    ));
};

export default UserInstruction;
