// @flow
import React, {Fragment} from "react";
import Grid, {type Spacing} from "@material-ui/core/Grid/Grid";
import {withStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import {type TypographyHorizontalPadding, type TypographyFontSize} from "../../components/Typography/Typography";
import NoData from "../../components/NoData/NoData";
import {validPattern} from "../../../utils/objectUtils";
import DefaultBehavior from "./DefaultBehavior";
import TarrifVl from "./NonDefaultBehaviorComponents/TarrifVl";

const styles = () => ({
    fullHeight: {
        height: "100%",
    },
});

type Props = {
    data: any,
    fields: Array<Object>,
    firstGray?: boolean,
    renderIcon?: (Object) => React$Node,
    underline?: boolean,
    secondGray?: boolean,
    showTopLine?: boolean,
    spacing: Spacing,
    fontSize?: TypographyFontSize,
    horizontalPadding?: TypographyHorizontalPadding,
    mobileDirection?: boolean,
    onlySecondRow?: boolean,
    justify?: "flex-start" | "center" | "flex-end" | "space-between" | "space-around",
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    fullHeight?: boolean,
    verticalCenter?: boolean,
};

const SPACING_DEFAULT = 8;

const groupNames = {
    TARIFF_VL: TarrifVl,
};

export const BRowDetailFields = (props: Props) => {
    const {
        data,
        fields,
        firstGray,
        renderIcon,
        underline,
        secondGray,
        showTopLine,
        spacing = SPACING_DEFAULT,
        fontSize,
        horizontalPadding = 0,
        mobileDirection,
        onlySecondRow,
        justify,
        classes = {},
        fullHeight,
        verticalCenter,
    } = props;

    if (!data || !fields || data.length === 0) {
        return <NoData />;
    }

    return (
        <Grid container spacing={spacing} justify={justify} className={fullHeight ? classes.fullHeight : ""}>
            {showTopLine ? (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            ) : null}
            {fields.map((field, index) => {
                const Component = groupNames[field.name];
                const isNull = validPattern(data, field.src, field.title);

                return isNull || Component ? (
                    <Fragment key={index}>
                        {Component ? (
                            <Grid item xs={12}>
                                <Component
                                    fontSize={fontSize}
                                    firstGray={firstGray}
                                    horizontalPadding={horizontalPadding}
                                    data={data}
                                    field={field}
                                    renderIcon={renderIcon}
                                    mobileDirection={mobileDirection}
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <DefaultBehavior
                                    onlySecondRow={onlySecondRow}
                                    fontSize={fontSize}
                                    firstGray={firstGray}
                                    secondGray={secondGray}
                                    horizontalPadding={horizontalPadding}
                                    data={data}
                                    field={field}
                                    renderIcon={renderIcon}
                                    mobileDirection={mobileDirection}
                                    verticalCenter={verticalCenter}
                                />
                            </Grid>
                        )}
                        {underline ? (
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        ) : null}
                    </Fragment>
                ) : null;
            })}
        </Grid>
    );
};

export default withStyles(styles)(BRowDetailFields);
