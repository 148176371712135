// @flow
import React from "react";
import Select from "react-select";
import "react-select/dist/react-select.css";
import ClearIcon from "@material-ui/icons/Clear";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import {withStyles} from "@material-ui/core/styles";
import TextField from "../Form/TextField";

/* eslint-disable sort-keys */
const styles = (theme: any) => ({
    "@global": {
        ".Select": {
            padding: 0,
        },
        ".Select-arrow-zone": {
            top: 7,
            right: 20,
        },
        ".Select-arrow-zone, .Select-clear-zone": {
            color: theme.palette.grey.light,
            cursor: "pointer",
            position: "absolute",
            zIndex: 1,
        },
        ".Select-clear-zone": {
            right: 45,
        },
        ".Select.is-focused:not(.is-open) > .Select-control": {
            "-webkit-box-shadow": "none",
            boxShadow: "none",
        },
        ".Select-control": {
            alignItems: "center",
            background: "transparent",
            border: "none",
            boxShadow: "none",
            display: "flex",
            height: "auto",
            width: "100%",
        },
        ".Select-menu": {
            overflow: "visible",
        },
        ".Select-menu-outer": {
            border: "none",
            boxShadow: "none",
            marginLeft: "-1px",
        },
        ".Select-option": {
            borderBottom: `1px solid ${theme.palette.grey.light}`,
            borderRight: `1px solid ${theme.palette.grey.light}`,
            marginRight: "-2px",
        },
        ".Select.is-focused": {
            backgroundColor: "#ebf5ff",
            boxShadow: "none",
        },
        ".Select-placeholder, .Select--single > .Select-control .Select-value": {
            paddingRight: 42,
        },
    },
    dropdownBorder: {
        "&:first-child > .Select-control": {
            border: "none",
        },
        "&:first-child.is-focused:not(.is-open) > .Select-control": {
            "-webkit-box-shadow": "none",
            boxShadow: "none",
        },
        "&:last-child > .Select-menu-outer": {
            marginTop: 0,
            borderTop: `1px solid ${theme.palette.grey.light}`,
            borderBottomRadius: "none",
            border: "none",
            zIndex: 2,
        },
        "&:last-child > .Select-menu-outer > .Select-menu": {
            overflowX: "visible",
            overflowY: "auto",
            maxHeight: 145,
            borderLeft: `1px solid ${theme.palette.grey.light}`,
            paddingRight: 2,
            marginRight: -2,
        },
    },
    textWrap: {
        padding: 0,
    },
});
/* eslint-enable sort-keys */

type Props = {
    onChange?: (currentOption: {label: string, value: string}) => void,
    options: Array<any> | Object,
    clearable?: boolean,
    disabled?: boolean,
    selectedValue: any,
    searchable?: boolean,
    name?: string,
    placeholder?: string,
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    testID?: string,
    label?: string,
};

class Dropdown extends React.Component<Props> {
    render() {
        const {
            options,
            clearable = false,
            disabled = false,
            searchable = false,
            selectedValue = "",
            onChange,
            name,
            placeholder,
            classes = {},
            testID = "periodDropDown",
            label,
        } = this.props;

        return (
            <TextField
                height="auto"
                label={label}
                classes={{textWrap: classes.textWrap}}
                testID={testID}
                InputProps={{
                    inputComponent: Select,
                    inputProps: {
                        arrowRenderer: (arrowProps) =>
                            arrowProps.isOpen ? <KeyboardArrowUp width={40} /> : <KeyboardArrowDown />,
                        autosize: true,
                        className: classes.dropdownBorder,
                        clearRenderer: () => <ClearIcon />,
                        clearable,
                        disabled,
                        id: testID,
                        instanceId: testID,
                        name,
                        onBlurResetsInput: false,
                        onChange,
                        onSelectResetsInput: false,
                        options,
                        placeholder,
                        searchable,
                        value: selectedValue,
                    },
                }}
            />
        );
    }
}

export default withStyles(styles)(Dropdown);
