export const CRM_GET_QUALITY_CONTROL_FRAME_ACTION = "CRM_GET_QUALITY_CONTROL_FRAME_ACTION";
export const CRM_GET_QUALITY_CONTROL_FRAME_ACTION_SUCCESS = "CRM_GET_QUALITY_CONTROL_FRAME_ACTION_SUCCESS";
export const CRM_GET_QUALITY_CONTROL_FRAME_ACTION_FAILURE = "CRM_GET_QUALITY_CONTROL_FRAME_ACTION_FAILURE";

export const CRM_GET_VER_ACTION = "CRM_GET_VER_ACTION";
export const CRM_GET_VER_ACTION_SUCCESS = "CRM_GET_VER_ACTION_SUCCESS";
export const CRM_GET_VER_ACTION_FAILURE = "CRM_GET_VER_ACTION_FAILURE";

export const CRM_GET_PAID_SERVICE_ATTR_ACTION = "CRM_GET_PAID_SERVICE_ATTR_ACTION";
export const CRM_GET_PAID_SERVICE_ATTR_ACTION_SUCCESS = "CRM_GET_PAID_SERVICE_ATTR_ACTION_SUCCESS";
export const CRM_GET_PAID_SERVICE_ATTR_ACTION_FAILURE = "CRM_GET_PAID_SERVICE_ATTR_ACTION_FAILURE";

export const CRM_GET_IMG_URL_ACTION = "CRM_GET_IMG_URL_ACTION";
export const CRM_GET_IMG_URL_ACTION_SUCCESS = "CRM_GET_IMG_URL_ACTION_SUCCESS";
export const CRM_GET_IMG_URL_ACTION_FAILURE = "CRM_GET_IMG_URL_ACTION_FAILURE";

export const CRM_GET_PAID_SERVICE_REKV_ACTION = "CRM_GET_PAID_SERVICE_REKV_ACTION";
export const CRM_GET_PAID_SERVICE_REKV_ACTION_SUCCESS = "CRM_GET_PAID_SERVICE_REKV_ACTION_SUCCESS";
export const CRM_GET_PAID_SERVICE_REKV_ACTION_FAILURE = "CRM_GET_PAID_SERVICE_REKV_ACTION_FAILURE";
