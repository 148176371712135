// @flow
import qs from "qs";
import {backListPathNames} from "../constants/BackLinksPathnames";
import {publickUrl} from "../constants/Application";

export const redirectAuth = (history: Object) => {
    const {pathname, search} = window.location;
    const newPathName = publickUrl ? pathname.replace(publickUrl, "") : pathname;

    const {result} = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });

    if (
        backListPathNames.includes(pathname) ||
        (result &&
            (window.location.pathname.replace(publickUrl, "") === "/profile" ||
                /\/accounts\/\d+\/privileges/.test(window.location.pathname.replace(publickUrl, ""))))
    ) {
        history.push("/auth", {backUrl: `${newPathName}${search}`});
    } else {
        history.push("/auth");
    }
};
