// @flow
import filter from "lodash/filter";
import uuidv4 from "uuid/v4";
import * as actionTypes from "../../constants/types/ActionTypes";
import {createReducer} from "./createReducer";

export type SystemErrorReducerType = {
    messages: Array<Object>,
};

const initialState: SystemErrorReducerType = {
    messages: [],
};

export const systemErrorReducer = createReducer(initialState, {
    [actionTypes.SET_SYSTEM_ERROR_ACTION]: (state, {payload}) => ({
        messages: [
            ...state.messages,
            {
                ...payload,
                uuid: uuidv4(),
            },
        ],
    }),
    [actionTypes.SYSTEM_ERROR_CLOSE_MESSAGE_ACTION]: (state, {payload}) => ({
        ...state,
        messages: filter(state.messages, (message) => message.code !== payload.code),
    }),
});
