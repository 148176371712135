// @flow
import type {PaletteOptions} from "@material-ui/core/styles/createPalette";
import {Color} from "@material-ui/core/index";
import type {ThemeOptions} from "@material-ui/core/styles/createMuiTheme";
import {overrides} from "./themeOverridesStyle";

type TypographyStyleType = {
    color: string,
    fontFamily: string,
    fontSize: number,
    fontStyle: string,
    lineHeight: string,
};

export type themeTypes = {
    palette: {
        primary: {
            contrastText: string,
            dark: string,
            light: string,
            main: string,
            opacityLight: string,
            translucent: string,
        } & Color,
        secondary: {
            contrastText: string,
            dark: string,
            light: string,
            light2: string,
            main: string,
        } & Color,
        common: {
            black: string,
            green: string,
            lightGreen: string,
            red: string,
            yellow: string,
        } & Color,
        grey: {
            authBackground: string,
            authLogin: string,
            banner: string,
            bannerSeparator: string,
            border: string,
            coolGray1: string,
            coolGray4: string,
            coolGray8: string,
            dark: string,
            hightlight: string,
            light: string,
            light2: string,
            main: string,
            sideBar: string,
            shadow: string,
            sidebarSeparator: string,
        } & Color,
        text: {
            main: string,
            primary: string,
            white: string,
        } & Color,
        background: {
            authHeader: string,
            dark: string,
            warning: string,
        } & Color,
        banner: {
            background: string,
            separator: string,
        },
        error: Color,
        button: {
            background: {
                primary: string,
                secondary: string,
            },
        },
        tab: {
            secondary: string,
        },
        topMenu: {
            main: string,
            profile: string,
            selected: string,
            separator: string,
            badge: string,
        },
        transparent: {
            main: string,
        },
    } & PaletteOptions,
    spacing: {
        unit: number,
    },
    typography: {
        body: TypographyStyleType,
        caption: TypographyStyleType,
        fontFamily: "Roboto",
        fontStyle: "Regular",
        header1: TypographyStyleType,
        header2: TypographyStyleType,
        header3: TypographyStyleType,
        subCaption: TypographyStyleType,
    } & Object,
    zIndex: {
        maxZIndex: number,
    } & Object,
    overrides: Object,
    sizing: {
        appbarHeight: number,
        radioBtnWidth: number,
        returnBtnHeight: number,
        sideBarHeadHeight: number,
        sideBarWidth: number,
        tabPanelHeight: number,
        topMenuUserAccountWidth: number,
    },
    breakpoints: Object,
} & ThemeOptions;

export const themeVars: themeTypes = {
    breakpoints: {},
    overrides,
    palette: {
        background: {
            authHeader:
                "linear-gradient(135deg, rgba(244, 144, 53, 1) 0%, rgba(244, 144, 53, 1) 0%," +
                " rgba(244, 179, 53, 1) 100%, rgba(244, 179, 53, 1) 100%)",
            dark: "rgba(0, 0, 0, 0.5)",
            warning: "#fafafa",
        },
        banner: {
            background: "linear-gradient(55deg, #304151 0%, #076194 100%)",
            separator: "rgba(224, 224, 224, 0.2)",
        },
        button: {
            background: {
                primary: "linear-gradient(55deg, #304151 0%, #076194 100%)",
                secondary: "linear-gradient(77.12deg, #F49035 0%, #F4B335 100%)",
            },
        },
        common: {
            balanceGreen: "#27ae60",
            balanceRed: "#ff3b30",
            black: "#304151",
            green: "#007800",
            headerOrange: "#f49035",
            lightGreen: "#B5F4BB",
            orange: "#e95d0f",
            red: "#c80000",
            yellow: "#ffc800",
        },
        error: {
            dark: "#cc2222",
            main: "#f44336",
        },
        // TODO перенести все зеленые цвет в группу green
        green: {
            kvitonomic: "#299442",
            light: "#34c759",
            progressBar: "#27ae60",
            wood: "#008032",
        },
        grey: {
            authBackground: "#f5f7f8",
            authLogin: "#F8F8F8",
            banner: "rgba(255, 255, 255, 0.6)",
            bannerSeparator: "rgba(224, 224, 224, 0.2)",
            border: "#d6dde7",
            coolGray1: "#e6e6e6",
            coolGray4: "#bebebe",
            coolGray8: "#828282",
            dark: "#999999",
            hightlight: "#e2e8f1",
            light: "#d7dee8",
            light2: "#F8F8F8",
            main: "#b8c2d0",
            shadow: "rgba(0, 0, 0, 0.25)",
            sideBar: "#f3f3f3",
            sidebarSeparator: "rgba(0, 0, 0, 0.12)",
        },
        primary: {
            // TODO применить ко всем компонентам, где используется этот цвет
            bright: "#6e89b6",
            contrastText: "#002552",
            dark: "#0d2f55",
            light: "#2689D1",
            main: "#002552",
            multibonusBlue: "#002882",
            opacityLight: "rgba(110, 137, 181, 0.75)",
            translucent: "#f0f8ff",
            white: "#ffffff",
        },
        secondary: {
            contrastText: "#f27b19",
            dark: "#cf7107",
            light: "#fdc388",
            light2: "#f49035",
            main: "#f27b19",
        },
        tab: {
            secondary: "#F3A52F",
        },
        text: {
            main: "#333333",
            primary: "#666666",
            white: "#fff",
        },
        topMenu: {
            badge: "#D80027",
            main: "#F49035",
            profile: "#D16F1F",
            selected: "#E48127",
            separator: "#DE7D26",
        },
        transparent: {
            main: "transparent",
        },
    },
    sizing: {
        appbarHeight: 60,
        radioBtnWidth: 48,
        returnBtnHeight: 40,
        sideBarHeadHeight: 120,
        sideBarWidth: 260,
        tabPanelHeight: 50,
        topMenuUserAccountWidth: 266,
    },
    spacing: {
        unit: 8,
    },
    typography: {
        body: {
            color: "#333333",
            fontFamily: "Roboto",
            fontSize: 16,
            fontStyle: "Regular",
            lineHeight: "19px",
        },
        caption: {
            color: "#82828282",
            fontFamily: "Roboto",
            fontSize: 14,
            fontStyle: "Regular",
            lineHeight: "16px",
        },
        fontFamily: "Roboto",
        fontStyle: "Regular",
        header1: {
            color: "#333333",
            fontFamily: "Roboto",
            fontSize: 36,
            fontStyle: "Regular",
            lineHeight: "42px",
        },
        header2: {
            color: "#333333",
            fontFamily: "Roboto",
            fontSize: 24,
            fontStyle: "Regular",
            lineHeight: "28px",
        },
        header3: {
            color: "#333333",
            fontFamily: "Roboto",
            fontSize: 18,
            fontStyle: "Regular",
            lineHeight: "21px",
        },
        subCaption: {
            color: "#82828282",
            fontFamily: "Roboto",
            fontSize: 12,
            fontStyle: "Regular",
            lineHeight: "14px",
        },
    },
    zIndex: {
        maxZIndex: 9999,
    },
};
