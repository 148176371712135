// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import ConfirmIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/ModeEdit";
import cn from "classnames";
import TextField from "../TextField";
import {styles} from "./EditableTextFieldStyle";

type Props = {|
    auth: Object,
    isEditMode: Boolean,
    value: String,
    editValue: String,
    textColor: "grey" | "blue",
    classes?: Object,
    inputProps?: {|
        placeholder?: String,
        rowsMax?: Number,
        multiline?: Boolean,
    |},
    inputRef?: any,
    onChange?: (event: SyntheticEvent<*>) => void,
    onClear?: () => void,
    onSave?: () => void,
    onChangeMode?: () => void,
|};

const PREVENT_DEFAULT = (event: SyntheticEvent<*>) => {
    event.preventDefault();
    event.stopPropagation();
};

export const EditableTextField = (props: Props) => {
    const {
        auth,
        classes = {},
        isEditMode,
        editValue,
        value,
        inputProps,
        textColor,
        onChange,
        onClear,
        onSave,
        onChangeMode,
        inputRef,
    } = props;

    return (
        <div
            className={cn(classes.container, {
                [classes.underline]: isEditMode,
            })}
            onClick={isEditMode ? PREVENT_DEFAULT : undefined}
        >
            <TextField
                inputRef={inputRef}
                className={classes.root}
                classes={{
                    textWrap: classes.textWrap,
                }}
                InputProps={{
                    className: classes.innerInput,
                    inputProps: {
                        className: cn(classes.textarea, {
                            [classes.greyText]: textColor === "grey",
                            [classes.blueText]: textColor === "blue",
                        }),
                    },
                }}
                value={isEditMode ? editValue : value}
                onChange={onChange}
                disabled={!isEditMode}
                {...inputProps}
            />

            {isEditMode ? (
                <div className={classes.icons} onClick={PREVENT_DEFAULT}>
                    <ConfirmIcon onClick={onSave} color={auth.prMirror ? "disabled" : "secondary"} />
                    <ClearIcon className={classes.clearIcon} onClick={onClear} />
                </div>
            ) : (
                <div className={classes.icons} onClick={PREVENT_DEFAULT}>
                    <EditIcon className={classes.editIcon} onClick={onChangeMode} />
                </div>
            )}
        </div>
    );
};

export default withStyles(styles)(EditableTextField);
