// @flow
import * as actionTypes from "../../constants/types/ActionTypes";
import {createReducer} from "./createReducer";

export type SideBarReducerType = {
    sideBarIsOpen: boolean,
    connectAccountOpen: boolean,
};

const initialState = {
    connectAccountOpen: false,
    sideBarIsOpen: false,
};

export const sideBarReducer = createReducer(initialState, {
    [actionTypes.SIDE_BAR_TOGGLE_ACTION]: (state) => ({
        sideBarIsOpen: !state.sideBarIsOpen,
    }),
    [actionTypes.SIDE_BAR_CLOSE_ACTION]: () => ({
        sideBarIsOpen: false,
    }),
    [actionTypes.SIDE_BAR_CHANGE_PROP_ACTION]: (state, {payload}) => ({
        [payload.prop]: payload.value,
    }),
});
