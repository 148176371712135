// @flow
const styles = (theme: any) => ({
    iconButton: {
        color: theme.palette.text.white,
        display: "block",
        height: `calc(${theme.sizing.appbarHeight}px - 5px)`,
        transition: "transform 300ms ease-in-out",
    },
    item: {
        flexBasis: "auto",
    },
    mainContent: {
        "&:hover": {
            background: theme.palette.topMenu.selected,
        },
        background: theme.palette.secondary.light2,
        borderLeft: `1px solid ${theme.palette.topMenu.separator}`,
        boxSizing: "border-box",
        cursor: "pointer",
        display: "flex",
        flexBasis: "auto",
        height: "60px",
        minHeight: theme.sizing.appbarHeight,
        padding: "0px 8px",
        [theme.breakpoints.down("md")]: {
            width: "auto",
        },
    },
    noBorder: {
        border: "none",
    },
    noHover: {
        "&:hover": {
            background: theme.palette.secondary.light2,
        },
    },
    rotate: {
        transform: "rotate(-180deg)",
    },
    row: {
        display: "flex",
        flexBasis: "auto",
        flexWrap: "nowrap",
        minWidth: "100%",
    },
});

export {styles};
