// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import type {themeTypes} from "../../themeStyle";
import Dialog from "../../packages/components/Dialog/Dialog";
import DialogContent from "../../packages/components/Dialog/DialogContent";
import DialogTitle from "../../packages/components/Dialog/DialogTitle";
import Typography from "../../packages/components/Typography/Typography";
import Button from "../../packages/components/Button/Button";
import DialogActions from "../../packages/components/Dialog/DialogActions";
import DialogCloseIcon from "../../packages/components/Dialog/DialogCloseIcon";

const styles = (theme: themeTypes) => ({
    root: {
        zIndex: theme.zIndex.modal * 2,
    },
});

type Props = {
    onClose: (params: Object) => void,
    classes?: {
        [$Keys<$Call<typeof styles, themeTypes>>]: string,
    },
    title: "Предупреждение" | "Сообщение",
    isSudirLogout: boolean,
    message: {
        additionalText?: string,
        uuid: string,
        code: number,
        text: string,
        resSuccess: boolean,
    },
};

export class SystemErrorDialog extends React.PureComponent<Props> {
    handleClose = () => {
        this.props.onClose(this.props.message);
    };

    render() {
        const {classes, title, message} = this.props;
        const additionalText = message.additionalText ? (
            <Typography fontSize={16} verticalPadding={10}>
                {message.additionalText}
            </Typography>
        ) : null;

        return (
            <Dialog
                open
                classes={classes}
                fullWidth
                maxWidth="sm"
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogCloseIcon onClick={this.handleClose} />
                <DialogTitle disableTypography>
                    <Typography textColor="primary" fontSize={20} lineHeight={1}>
                        {title}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {title === "Предупреждение" && !message.resSuccess ? (
                        <React.Fragment>
                            {additionalText}
                            <Typography>Сервис временно недоступен. Попробуйте выполнить операцию позднее.</Typography>
                            <Typography>Описание:</Typography>
                            <Typography dangerouslySetInnerHTML={{__html: `${message.code} - ${message.text}`}} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {additionalText}
                            <Typography dangerouslySetInnerHTML={{__html: message.text}} />
                        </React.Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="secondary" autoFocus size="sm" testID="formNoButton">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(SystemErrorDialog);
