/* eslint-disable max-lines */
// @flow
import * as React from "react";
import {Field} from "react-final-form";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import {withStyles} from "@material-ui/core/styles";
import {FieldArray} from "react-final-form-arrays";
import {type UploadFileActionType, type DeleteFileActionType} from "../../redux/actions/uploadAction";
import {validators} from "../validations/fieldConnectedValidators";
import {renderTips} from "../../utils/fieldBuilderUtils";
import {composeValidators} from "../validations/baseValidations";
import {getFieldInstance} from "./Fields";

const styles = (theme: any) => ({
    multipalFieldContent: {
        position: "relative",
    },
    rootIcon: {
        cursor: "pointer",
        fill: theme.palette.primary.light,
        height: 20,
        position: "absolute",
        right: theme.spacing.unit / 2,
        top: 32,
        width: 20,
        zIndex: theme.zIndex.maxZIndex,
    },
    rootIconBtn: {
        height: 24,
        width: 24,
    },
});

type Props = {
    allowedSymbols?: string,
    autoComplete?: boolean,
    autoFocus?: boolean,
    background?: "white",
    className?: string,
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    deleteFileAction?: DeleteFileActionType,
    disableFuture?: boolean,
    disabled?: boolean,
    distTreeData?: Array<Object>,
    documentsData?: Array<Object>,
    error?: boolean,
    fileExtensions?: Array<string>,
    fontSize?: 18,
    height?: number | string,
    helperText?: string,
    isSuccessful?: boolean,
    margin?: "none" | "dense" | "normal",
    mask?: any,
    maxDate?: Date,
    maxFileSize?: number,
    maxFilesCounter?: number,
    maxLength?: number,
    minDate?: Date,
    multiline?: boolean,
    nmAttrDataType?: string,
    nmAttrType?: string,
    nmAttribute?: string,
    nmColumn: string,
    nmDomain: string,
    nmFormatRegexp?: string,
    nmRegexp?: string,
    nmRegexpError?: string,
    nmTable: string,
    nnFieldSize?: number,
    noSideBtn?: boolean,
    onChange?: (event: any) => any,
    onClick?: (event: SyntheticInputEvent<>) => void,
    onKeyPress?: (event: SyntheticInputEvent<>) => void,
    placeholder?: string,
    prDict?: boolean,
    prMultipleVals?: boolean,
    prRequired?: boolean,
    prVisible?: boolean,
    push?: (fieldName: string, value: any) => any,
    rows?: number,
    rowsMax?: number,
    session?: string,
    style?: Object,
    tipText?: string,
    uploadButtonName?: string,
    uploadFileAction?: UploadFileActionType,
    validator?: Function,
    withMask?: boolean,
    wrapLabel?: boolean,
};

type State = {
    validation?: Function,
    BuilderField?: string,
    endAdornment?: React$Node,
    type?: string,
};

const inputTypes = {
    PASSWORD_DOMAIN: "password",
};

export class FieldBuilder extends React.Component<Props, State> {
    static defaultProps = {
        nmColumn: "",
        nmDomain: "",
        nmTable: "",
        type: "text",
    };

    constructor(props: Props) {
        super(props);
        const validation = props.validator ? props.validator() : this.getValidation();
        const state: State = {
            BuilderField: getFieldInstance(this.props),
            type: "",
        };

        state.validation = validation.length ? composeValidators(...validation) : undefined;

        this.state = state;
    }

    getValidation = () => validators(this.props);

    handleClickShowPassword = () => {
        this.setState((prevState) => ({
            type: prevState.type === "text" ? "password" : "text",
        }));
    };

    renderSingleField = ({fieldName, BuilderField, inputType, endAdornment}: Object) => {
        const {
            autoFocus,
            background,
            nmAttribute,
            prRequired,
            nmColumn,
            minDate,
            maxDate,
            maxFilesCounter,
            maxFileSize,
            mask,
            onKeyPress,
            disableFuture,
            withMask,
            nmAttrDataType,
            nmTable,
            onChange,
            uploadFileAction,
            deleteFileAction,
            session,
            onClick,
            autoComplete,
            margin,
            fontSize,
            disabled,
            wrapLabel,
            multiline,
            rows,
            rowsMax,
            classes,
            height,
            error,
            helperText,
            isSuccessful,
            uploadButtonName,
            fileExtensions,
            allowedSymbols,
            maxLength,
            style,
            placeholder,
        } = this.props;

        return (
            <Field
                name={fieldName}
                validate={this.state.validation}
                type={inputType}
                render={({input, meta}) => (
                    <BuilderField
                        autoFocus={autoFocus}
                        background={background}
                        config={this.props}
                        InputProps={{
                            ...input,
                            endAdornment,
                            onChange: (event: any) => {
                                if (onChange) {
                                    onChange(event);
                                }
                                input.onChange(event);
                            },
                            onClick,
                            onFocus: (event: any) => {
                                input.onFocus(event);
                            },
                            onKeyPress,
                            placeholder,
                            type: inputType,
                        }}
                        label={nmAttribute}
                        InputLabelProps={{
                            required: prRequired,
                        }}
                        error={(meta.touched && Boolean(meta.invalid || (meta.data && meta.data.error))) || error}
                        helperText={error && helperText ? helperText : meta.touched && meta.error}
                        warningText={meta.touched && meta.data && meta.data.warning}
                        maxDate={maxDate}
                        minDate={minDate}
                        mask={mask}
                        maxFilesCounter={maxFilesCounter}
                        maxFileSize={maxFileSize}
                        disableFuture={disableFuture}
                        withMask={withMask}
                        nmAttrDataType={nmAttrDataType}
                        testID={`${nmTable}-${nmColumn}`}
                        uploadFileAction={uploadFileAction}
                        deleteFileAction={deleteFileAction}
                        session={session}
                        meta={meta}
                        autoComplete={autoComplete ? "on" : "off"}
                        margin={margin}
                        fontSize={fontSize}
                        disabled={disabled}
                        wrapLabel={wrapLabel}
                        multiline={multiline}
                        rows={rows}
                        rowsMax={rowsMax}
                        classes={classes}
                        height={height}
                        isSuccessful={isSuccessful}
                        uploadButtonName={uploadButtonName}
                        fileExtensions={fileExtensions}
                        allowedSymbols={allowedSymbols}
                        maxLength={maxLength}
                        style={style}
                    />
                )}
            />
        );
    };

    addNewField = () => {
        const {nmColumn, push} = this.props;

        if (push) {
            push(nmColumn, undefined);
        }
    };

    renderMultiField = ({BuilderField, inputType, endAdornment}: Object) => {
        const {nmColumn, classes = {}} = this.props;

        return (
            <FieldArray name={nmColumn}>
                {({fields}) =>
                    fields.map((name, index) => (
                        <div key={index} className={classes.multipalFieldContent}>
                            {index === 0 ? (
                                <AddIcon className={classes.rootIcon} onClick={this.addNewField} testid="addIcon" />
                            ) : (
                                <ClearIcon
                                    className={classes.rootIcon}
                                    onClick={() => fields.remove(index)}
                                    testid="clearIcon"
                                />
                            )}
                            {this.renderSingleField({BuilderField, endAdornment, fieldName: name, inputType})}
                        </div>
                    ))
                }
            </FieldArray>
        );
    };

    render() {
        const {prVisible, nmColumn, nmDomain, tipText, prMultipleVals, prDict, noSideBtn} = this.props;
        const {BuilderField, type} = this.state;
        const inputType = type || inputTypes[nmDomain];
        const endAdornment = renderTips({
            classes: this.props.classes,
            handleClickShowPassword: this.handleClickShowPassword,
            nmDomain,
            noSideBtn,
            tipText,
            type,
        });

        if (!BuilderField || !prVisible) {
            return null;
        }
        if (prMultipleVals && !prDict) {
            /*
             * Мультиполя отрабатывают корректно, если есть initialValues в форме со значениями в виде:
             * {FIELD_NAME: [""]}
             */

            return this.renderMultiField({BuilderField, endAdornment, fieldName: nmColumn, inputType});
        }

        return this.renderSingleField({BuilderField, endAdornment, fieldName: nmColumn, inputType});
    }
}

export default withStyles(styles)(FieldBuilder);
