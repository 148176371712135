// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import {type TopMenuItem} from "../../../../redux/reducers/configReducer";
import SubMenuHeader from "../SubMenuHeader";
import SubMenuBody from "../SubMenuBody";
import {styles} from "./styles";

type Props = {
    isMenuOpen: Boolean,
    items: Array<TopMenuItem>,
    headerText: String,
    classes?: Object,
    onCloseMenu?: () => void,
};

export const SubMenuDropdown = (props: Props) => {
    const {classes = {}, onCloseMenu, isMenuOpen, items, headerText} = props;
    const [open, changeOpen] = React.useState<boolean>(false);

    const handleToggle = React.useCallback((event: SyntheticEvent<>) => {
        event.stopPropagation();
        event.preventDefault();
        changeOpen((prevOpen) => !prevOpen);
    }, []);

    const handleClose = React.useCallback((event: any) => {
        event.stopPropagation();
        event.preventDefault();
        changeOpen(false);
    }, []);

    React.useEffect(() => {
        if (!isMenuOpen) {
            changeOpen(false);
        }
    }, [isMenuOpen]);

    return (
        <React.Fragment>
            <SubMenuHeader
                open={open}
                onToggle={handleToggle}
                onClose={handleClose}
                headerText={headerText}
                onTouchEnd={handleToggle}
            />
            <Collapse in={open} className={classes.collapseContent}>
                <Paper className={classes.paperContainer} onClick={handleClose}>
                    <SubMenuBody onClose={onCloseMenu} items={items} />
                </Paper>
            </Collapse>
        </React.Fragment>
    );
};

export default withStyles(styles)(SubMenuDropdown);
