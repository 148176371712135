// @flow
import {type EContractsAvailProvider} from "../redux/reducers/energyContractsReducer";

export const convertProvidersJur = (providers: Array<Object>) =>
    providers.map<EContractsAvailProvider>(({kdProvider, nmProviderJur}: Object) => ({
        nmValue: nmProviderJur,
        nnCode: kdProvider,
    }));

export const convertProviders = (providers: Array<Object>) =>
    providers.map<EContractsAvailProvider>(({kdProvider, nmProvider}: Object) => ({
        nmValue: nmProvider,
        nnCode: kdProvider,
    }));
