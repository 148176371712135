// @flow
import React from "react";
import type {BrowserHistory} from "history/createBrowserHistory";
import {getLocaleStorageAction} from "../redux/actions/storageActions/localeStorageAction";
import {menuSettingsKey} from "../constants/Application";

export const isSectionAvail = (history: BrowserHistory, currentSections: Array<Object>, idProfile: string = "") => {
    const menuItems = getLocaleStorageAction(`${menuSettingsKey}-${idProfile}`)?.payload?.value || [];
    const isEnabledSecton = menuItems.find((item) => currentSections.some((kdSection) => item.kdSection === kdSection));

    if (!isEnabledSecton && history) {
        history.push("/accounts/0");
    }

    return <></>;
};
