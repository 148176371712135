// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import cn from "classnames";
import Typography from "../../../../packages/components/Typography/Typography";
import TopMenuDropDownBtn from "../../TopMenuDropDownBtn";
import {styles} from "./styles";

type Props = {
    open: boolean,
    headerText: String,
    classes?: Object,
    onToggle: () => void,
    onClose?: () => void,
    onTouchEnd?: () => void,
};

export const SubMenuHeader = (props: Props) => {
    const {open, onToggle, classes = {}, headerText, onTouchEnd} = props;

    return (
        <TopMenuDropDownBtn onClick={onToggle} onTouchEnd={onTouchEnd}>
            <Typography align="center" fontSize={14} fontWeight={400} textColor="white">
                {headerText}
            </Typography>
            <ExpandMoreIcon
                className={cn(classes.icon, {
                    [classes.iconRotate]: open,
                })}
            />
        </TopMenuDropDownBtn>
    );
};

export default withStyles(styles)(SubMenuHeader);
