// @flow
import * as React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import {withStyles} from "@material-ui/core/styles";
import Button from "../../../components/Button/Button";
import Typography from "../../../components/Typography/Typography";
import styles from "./styles";

type Props = {|
    classes: {[$Keys<$Call<typeof styles, any>>]: string},
    content: Array<{
        vlContent: {
            nmLink: string,
            vlContent: string,
        },
    }>,
    zeroPadding?: boolean,
    zeroMinHeight?: boolean,
    disabled?: boolean,
    spacing: 8 | 16,
    direction: "row" | "column",
    textColor: "primary-light" | "white",
    fontSize?: 18 | 16 | 22,
    testID?: string,
    list?: boolean,
    link?: boolean,
    typographyStyleExtra?: Object,
    buttonGridItemStyleExtra?: Object,
    color?: "primary" | "primaryTranslucent" | "secondary" | "transparent",
    size?: "xs" | "sm" | "lg" | "full",

    CustomComponent?: () => React.Node,
    onClick?: () => void,
|};

const DEFAULT_PADDING = 16;
const DEFAULT_SPACING = 8;

export class ReferenceBtnElement extends React.Component<Props> {
    handleLink = (nmLink: string) => (event: SyntheticEvent<>) => {
        event.preventDefault();

        if (nmLink) {
            window.open(nmLink);
        }

        this.props.onClick?.();
    };

    render() {
        const {
            classes,
            testID,
            list,
            zeroPadding,
            zeroMinHeight,
            spacing = DEFAULT_SPACING,
            direction = "row",
            CustomComponent,
            textColor = "white",
            color = "secondary",
            size = "lg",
            fontSize,
            link,
            typographyStyleExtra,
            buttonGridItemStyleExtra,
            disabled,
        } = this.props;
        const [...content] = this.props.content;

        return (
            <Grid container spacing={spacing} direction={direction}>
                {content.reverse().map((reference: Object, index: number) => (
                    <Grid item key={index} style={buttonGridItemStyleExtra}>
                        <Button
                            classes={{disabled: classes.fixDisabledButtonBackground}}
                            link={link}
                            list={list}
                            capitalize
                            onClick={this.handleLink(reference.nmLink)}
                            testID={testID}
                            zeroPadding={zeroPadding}
                            zeroMinHeight={zeroMinHeight}
                            size={size}
                            color={color}
                            minHeight={48}
                            disabled={disabled}
                        >
                            {CustomComponent ? <CustomComponent /> : null}

                            <Typography
                                horizontalPadding={CustomComponent ? DEFAULT_PADDING : undefined}
                                fontSize={fontSize}
                                textColor={textColor}
                                variant=""
                                style={typographyStyleExtra}
                            >
                                {reference.vlContent}
                            </Typography>
                        </Button>
                    </Grid>
                ))}
            </Grid>
        );
    }
}

export default withStyles(styles)(ReferenceBtnElement);
