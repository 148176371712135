// @flow
import * as types from "../../constants/types/ActionTypes";

export type ChangePropSideBarActionType = (prop: string, value: boolean) => void;

export const openCloseSideBar = () => ({
    type: types.SIDE_BAR_TOGGLE_ACTION,
});

export const closeSideBar = () => ({
    type: types.SIDE_BAR_CLOSE_ACTION,
});

export const changePropSideBarAction = (prop: string, value: boolean) => ({
    payload: {
        prop,
        value,
    },
    type: types.SIDE_BAR_CHANGE_PROP_ACTION,
});
