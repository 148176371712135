// @flow
/*
 * Исходный функционал взят из
 * "https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Typography/Typography.js"
 */

import * as React from "react";
import classNames from "classnames";
import {withStyles} from "@material-ui/core/styles";
import {type themeTypes} from "../../../themeStyle";

const capitalize = (string) => {
    if (process.env.NODE_ENV !== "production" && typeof string !== "string") {
        throw new Error("Material-UI: capitalize(string) expects a string argument.");
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const styles = (theme: themeTypes) => ({
    alignCenter: {
        textAlign: "center",
    },
    alignJustify: {
        textAlign: "justify",
    },
    alignLeft: {
        textAlign: "left",
    },
    alignRight: {
        textAlign: "right",
    },
    body: theme.typography.body,
    caption: theme.typography.caption,
    colorInherit: {
        color: "inherit",
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    colorTextSecondary: {
        color: theme.palette.text.secondary,
    },
    gutterBottom: {
        marginBottom: "0.35em",
    },
    header1: theme.typography.header1,
    header2: theme.typography.header2,
    header3: theme.typography.header3,
    noWrap: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    paragraph: {
        marginBottom: 16,
    },
    root: {
        display: "block",
        margin: 0,
    },
    subCaption: theme.typography.subCaption,
    title: theme.typography.title,
});

export type VariantTypes = "header1" | "header2" | "header3" | "body" | "body1" | "body2" | "caption" | "subCaption";

type Props = {|
    align: "inherit" | "left" | "center" | "right" | "justify",
    color: "inherit" | "primary" | "textSecondary" | "secondary" | "error" | "default",
    gutterBottom: boolean,
    headlineMapping: Object,
    noWrap: boolean,
    paragraph: boolean,
    component?: string | Function | Object,
    variant: VariantTypes,
    children?: React.Node,
    className?: string,
    classes?: Object,
|};

function Typography(props: Props) {
    const {
        align,
        classes = {},
        className: classNameProp,
        component: componentProp,
        color,
        gutterBottom,
        headlineMapping,
        noWrap,
        paragraph,
        variant,
        ...other
    } = props;

    const className = classNames(
        classes.root,
        {
            [classes[variant || ""]]: variant,
            [classes[`color${capitalize(color) || ""}`]]: color !== "default",
            [classes.noWrap]: noWrap,
            [classes.gutterBottom]: gutterBottom,
            [classes.paragraph]: paragraph,
            [classes[`align${capitalize(align) || ""}`]]: align !== "inherit",
        },
        classNameProp,
    );

    const Component = componentProp || (paragraph ? "p" : headlineMapping[variant]) || "span";

    return <Component className={className} {...other} />;
}

Typography.defaultProps = {
    align: "inherit",
    color: "default",
    gutterBottom: false,
    headlineMapping: {
        body: "p",
        body1: "p",
        caption: "p",
        header1: "p",
        header2: "p",
        header3: "p",
        subCaption: "p",
    },
    noWrap: false,
    paragraph: false,
    variant: "body1",
};

export default withStyles(styles, {name: "MuiTypography"})(Typography);
