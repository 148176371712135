// @flow
import * as React from "react";
import {useDispatch} from "react-redux";
import EditableTextField, {useIsEditMode, useText} from "../../packages/components/Form/EditableTextField";
import {lsSaveDescriptionAction} from "../../redux/actions/accountAction";
import {setCaretPosition} from "../../utils/caretPosition";

type Props = {
    auth: Object,
    account: Object,
    lsDescriptionLength: number,
    session: string,
};

export const SideBarAccountDescription = ({auth, lsDescriptionLength, session, account}: Props) => {
    const dispatch = useDispatch();
    const {text: editableDescriptionText, onChangeText: onChangeEditableDescriptionText} = useText(
        account.nmLsDescription,
    );
    const {isEditMode, onToogleEditMode} = useIsEditMode(false);
    const inputRef = React.useRef(null);

    const handleChangeEditableDescriptionText = (event: Event) => {
        event.stopPropagation();
        event.preventDefault();

        const {target} = event;
        const {value} = target instanceof HTMLTextAreaElement ? target : {};

        if (!value || (value && value.length < lsDescriptionLength)) {
            onChangeEditableDescriptionText(value);
        }
    };

    const handleToogleMode = () => {
        onToogleEditMode();

        setTimeout(() => {
            const isNoEmptyDescription = account.nmLsDescription && account.nmLsDescription.length > 0;

            if (inputRef.current && isNoEmptyDescription) {
                setCaretPosition(inputRef.current, account.nmLsDescription.length);
            }

            inputRef.current && inputRef.current.focus();
        }, 0);
    };

    const handleClear = (event: SyntheticEvent<*>) => {
        event.stopPropagation();
        event.preventDefault();

        onChangeEditableDescriptionText(account.nmLsDescription || "");
        onToogleEditMode();
    };

    const handleSave = (event: SyntheticEvent<*>) => {
        event.stopPropagation();
        event.preventDefault();

        if (!auth.prMirror) {
            dispatch(lsSaveDescriptionAction(session, account.idService, editableDescriptionText)).then(
                onToogleEditMode,
            );
        }
    };

    return (
        <EditableTextField
            auth={auth}
            isEditMode={isEditMode}
            editValue={editableDescriptionText}
            value={account.nmLsDescription}
            inputProps={{
                multiline: true,
                placeholder: "Введите заметку",
                rowsMax: 3,
            }}
            textColor={!editableDescriptionText && !account.nmLsDescription ? "grey" : "blue"}
            inputRef={inputRef}
            onChange={handleChangeEditableDescriptionText}
            onChangeMode={handleToogleMode}
            onClear={handleClear}
            onSave={handleSave}
        />
    );
};

export default SideBarAccountDescription;
