// @flow
// eslint-disable-next-line import/named
import {type DispatchAPI, type MiddlewareAPI} from "redux";
import includes from "lodash/includes";
import {systemErrorSetMessages} from "../actions/systemErrorAction";
import {CODE_UNAUTORIZATION} from "../../constants/StatusCode";
import {NO_BUSINESS_ERR_CODE, UNCONFIRMED_PHONE} from "../../constants/KdResults";
import {type StoreType} from "../reducers";
import {authLogout, saveRedirectUrlAction} from "../actions/authAction";
import {history} from "../../history";
import {redirectAuth} from "../../utils/authUtils";
import {windowReload} from "../../utils/middlewareUtils";
import {getLocaleStorageAction} from "../actions/storageActions/localeStorageAction";
import {authKey} from "../../constants/Application";

export type ActionBaseType = {
    error: boolean,
};

export type SuccessResponseType = {
    error: boolean,
    resSuccess: true,
    response: {
        kdResult: number,
        nmResult: string,
        status?: string,
    },
    message?: string,
    payload: Object,
};

export type FailedResponseType = {
    error: boolean,
    resSuccess: false,
    response: {
        errCode: number,
        errText: string,
        kdResult?: number,
        status?: string,
        vlPromocodeFilter?: Array<Object>,
    },
    message?: string,
    payload: Object,
    networkError?: boolean,
    canceled?: Boolean,
};

export type ActionType = SuccessResponseType | FailedResponseType;

export const authLogoutAndRedirect = (store: MiddlewareAPI<StoreType, any>) => {
    const authData = getLocaleStorageAction(authKey);
    const {pathname} = history.location;

    if (authData.payload.value?.isSudir) {
        window.location = process.env.REACT_APP_SUDIR_LOGOUT_URL;
    } else {
        redirectAuth(history);
    }
    if (authData?.payload?.value?.idProfile) {
        store.dispatch(authLogout());
    }
    if (pathname?.indexOf("auth") === -1) {
        store.dispatch(saveRedirectUrlAction(pathname));
    }
};

export const systemErrorMiddleware = (store: MiddlewareAPI<StoreType, any>) => (next: DispatchAPI<ActionType>) => (
    action: ActionType,
) => {
    if (action.response && !action.canceled) {
        const {payload, response} = action;
        const checkResponse =
            action.response.kdResult !== undefined &&
            action.response.kdResult !== NO_BUSINESS_ERR_CODE &&
            action.response.kdResult !== UNCONFIRMED_PHONE;
        const checkPayload =
            !action.payload || (!includes(payload.skipkdResults, response.kdResult) && !payload.skipAllKdResults);

        if (action.networkError && action.message && (action.response.status || action.message === "Network Error")) {
            store.dispatch(
                systemErrorSetMessages({
                    additionalText: action.payload && action.payload.additionalText,
                    code: action.response.status || "9999",
                    text: action.message,
                }),
            );
        } else if (action.resSuccess === true && checkResponse && checkPayload) {
            const successAction: SuccessResponseType = action;
            const info = {
                additionalText: action.payload && action.payload.additionalText,
                code: successAction.response.kdResult,
                isSudirLogout: action.payload && action.payload.isSudirLogout,
                resSuccess: action.resSuccess,
                text: successAction.response.nmResult,
            };

            store.dispatch(systemErrorSetMessages(info));
        } else if (action.resSuccess === false) {
            const {pathname} = window.location;
            const isDisabledPaths =
                pathname.indexOf("/accounts/0") > -1 ||
                pathname.indexOf("/auth") > -1 ||
                pathname === "/" ||
                pathname === "" ||
                pathname === `${String(process.env.PUBLIC_URL)}/` ||
                pathname === process.env.PUBLIC_URL;

            if (action.response.errCode === CODE_UNAUTORIZATION) {
                const {
                    auth: {remember},
                } = store.getState();

                remember ? windowReload() : authLogoutAndRedirect(store);
            }

            if (!payload || (payload && !includes(payload.skipkdResults, CODE_UNAUTORIZATION))) {
                const failedAction: FailedResponseType = action;
                const info = {
                    additionalText: action.payload && action.payload.additionalText,
                    code: failedAction.response.errCode,
                    resSuccess: action.resSuccess,
                    text: failedAction.response.errText,
                };

                if (
                    !payload?.isDisabledAuthorisationError ||
                    (payload.isDisabledAuthorisationError && !isDisabledPaths)
                ) {
                    store.dispatch(systemErrorSetMessages(info));
                }
            }
        }
    }

    return next(action);
};
