// @flow
import React from "react";
import moment from "moment";
import {useSelector} from "react-redux";
import type {StoreType} from "../../../redux/reducers";
import shallowEqual from "../../../utils/shallowEqual";
import CustomDatePicker, {type Props as CustomDatePickerProps} from "./CustomDatePicker";

type Props = CustomDatePickerProps;

export const BirthdayDatePicker = (props: Props) => {
    const {settings} = useSelector(
        (state: StoreType) => ({
            settings: state.config.settings,
        }),
        shallowEqual,
    );

    return (
        <CustomDatePicker
            {...props}
            minDate={moment().subtract(settings.dateBirthTo, "years")}
            maxDate={moment().subtract(settings.dateBirthFrom, "years")}
        />
    );
};

export default BirthdayDatePicker;
