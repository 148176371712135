// @flow
import * as React from "react";
import Dialog from "../../../packages/components/Dialog/Dialog";
import DialogCloseIcon from "../../../packages/components/Dialog/DialogCloseIcon";
import DialogTitle from "../../../packages/components/Dialog/DialogTitle";
import Typography from "../../../packages/components/Typography/Typography";
import DialogContent from "../../../packages/components/Dialog/DialogContent";
import BRowDetailFields from "../../../packages/Builder/BRowDetailFields/BRowDetailFields";
import DialogActions from "../../../packages/components/Dialog/DialogActions";
import Button from "../../../packages/components/Button/Button";
import payAllBackUrlConfig from "../../../metadata/payAllBackUrlConfig.json";
import {type BackUrlResponseType} from "../../../constants/types/ReducerTypes";

export type Props = {
    open: boolean,
    title: string,
    data: BackUrlResponseType,
    onClose: () => void,
};

export const BackUrlModal = (props: Props) => {
    const {open, onClose, title, data} = props;

    return (
        <Dialog fullWidth disableBackdropClick maxWidth="sm" open={open} onClose={onClose}>
            <DialogCloseIcon onClick={onClose} />
            <DialogTitle disableTypography>
                <Typography fontSize={22}>{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <BRowDetailFields fields={payAllBackUrlConfig} data={data} spacing={16} underline firstGray />
            </DialogContent>
            <DialogActions>
                <Button
                    size="xs"
                    textColor="white"
                    color="secondary"
                    fontSize={12}
                    onClick={onClose}
                    testID="formYesButton"
                >
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BackUrlModal;
