// @flow
import * as actionTypes from "../../constants/types/OnboardingTypes";
import {createReducer} from "./createReducer";

export type OnboardingReducerType = {
    isProfileDropdownOpen: boolean,
    isOnboardingOpen: boolean,
};

const initialState = {
    isOnboardingOpen: false,
    isProfileDropdownOpen: false,
};

export const onboardingReducer = createReducer(initialState, {
    [actionTypes.OPEN_PROFILE_DROPDOWN]: (state: OnboardingReducerType) => ({
        ...state,
        isProfileDropdownOpen: true,
    }),
    [actionTypes.CLOSE_PROFILE_DROPDOWN]: (state: OnboardingReducerType) => ({
        ...state,
        isProfileDropdownOpen: false,
    }),
    [actionTypes.CLOSE_ONBOARDING]: (state: OnboardingReducerType) => ({
        ...state,
        isOnboardingOpen: false,
    }),
    [actionTypes.OPEN_ONBOARDING]: (state: OnboardingReducerType) => ({
        ...state,
        isOnboardingOpen: true,
    }),
});
