// @flow
import * as actionTypes from "../../constants/types/ActionTypes";
import * as applicationTypes from "../../constants/types/ApplicationTypes";
import {groupByDay} from "../../utils/accountUtils";
import {createReducer} from "./createReducer";
import {normalizeFieldBuilderData} from "./applicationFormReducer";

export type ApplicationLoaderType = {
    show: boolean,
    text: string,
};

export type ApplicationReducerType = {
    applications: Array<Object>,
    fetchingApplication: boolean,
    fetchingRejectApplication: boolean,
    qualityQuestion: {attributes: Array<Object>, initialState: Object},
    loader: ApplicationLoaderType,
};

const initialState = {
    applications: [],
    fetchingApplication: false,
    fetchingRejectApplication: false,
    loader: {
        show: false,
        text: "",
    },
    qualityQuestion: {attributes: [], fetching: false, initialState: {}},
};

export const applicationReducer = createReducer(initialState, {
    [actionTypes.CRM_GET_SERVICE_HISTORY_ACTION]: (state: ApplicationReducerType) => ({
        ...state,
        fetchingApplication: true,
    }),
    [actionTypes.CRM_GET_SERVICE_HISTORY_ACTION_SUCCESS]: (state: ApplicationReducerType, {response}) => ({
        ...state,
        applications: groupByDay(response.vlServiceList, "reverse"),
        fetchingApplication: false,
    }),
    [actionTypes.CRM_GET_SERVICE_HISTORY_ACTION_FAILURE]: (state: ApplicationReducerType) => ({
        ...state,
        fetchingApplication: false,
    }),
    [actionTypes.CRM_GET_QUALITY_ATTRIBUTES_ACTION]: (state: ApplicationReducerType) => ({
        ...state,
        qualityQuestion: {
            attributes: [],
            fetching: true,
            initialState: {},
        },
    }),
    [actionTypes.CRM_GET_QUALITY_ATTRIBUTES_ACTION_SUCCESS]: (state: ApplicationReducerType, {response}) => ({
        ...state,
        qualityQuestion: {
            attributes: normalizeFieldBuilderData(response.attributes),
            fetching: false,
            initialState: {},
        },
    }),
    [actionTypes.CRM_GET_QUALITY_ATTRIBUTES_ACTION_FAILURE]: (state: ApplicationReducerType) => ({
        ...state,
        qualityQuestion: {
            attributes: [],
            fetching: false,
            initialState: {},
        },
    }),
    [applicationTypes.SHOW_APPLICATION_LOADER_ACTION]: (state, action) => ({
        ...state,
        loader: {
            show: true,
            text: action.payload.text || "",
        },
    }),
    [applicationTypes.HIDE_APPLICATION_LOADER_ACTION]: (state) => ({
        ...state,
        loader: {show: false, text: ""},
    }),
    [actionTypes.CRM_REJECT_CHANGE_TARIFF_ISU]: (state) => ({
        ...state,
        fetchingRejectApplication: true,
    }),
    [actionTypes.CRM_REJECT_CHANGE_TARIFF_ISU_SUCCESS]: (state) => ({
        ...state,
        fetchingRejectApplication: false,
    }),
});
