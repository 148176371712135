// @flow
export const styles = () => ({
    container: {
        height: "100%",
    },
    image: {
        height: 50,
        marginTop: -50,
        width: 50,
    },
});
