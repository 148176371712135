// @flow
import * as actionTypes from "../../constants/types/ActionNotificationTypes";
import {groupByDay} from "../../utils/accountUtils";
import {sortByDate} from "../../utils/accoutReducerUtils";
import {modalInfoTitle, type ModalInfoDataKeysType} from "../../constants/modalInfoTypes";
import {createReducer} from "./createReducer";

export type InfoModalType = {
    modalName?: ModalInfoDataKeysType,
    open: boolean,
    title?: string,
    componentData: {
        data: Array<Object>,
        fetching: boolean,
    },
};

export type NotificationReducerType = {
    criticalNotice: Array<Object>,
    bannerNotice: Array<Object>,
    lsNotice: Array<Object>,
    lsNoticeStatusList: Object,
    openBannerNoticeModal: number,
    isCriticalNoticeModalOpen: boolean,
    fetchingLsNotice: boolean,
    currentReadNotice: string,
    getLsListStatus: boolean,
    infoModal: InfoModalType,
    fromModal: boolean,
};

const initialState = {
    bannerNotice: [],
    criticalNotice: [],
    currentReadNotice: "",
    fetchingLsNotice: false,
    getLsListStatus: false,
    infoModal: {
        componentData: {},
        open: false,
    },
    isCriticalNoticeModalOpen: false,
    lsNotice: [],
    lsNoticeStatusList: [],
    openBannerNoticeModal: 0,
};

const getLsNoticeStatusList = (noticeArr: Array<Object>) => {
    const resultObj = {};

    noticeArr.forEach(({cntNotice, idService, isCritical}) => {
        resultObj[idService] = {cntNotice, isCritical};
    });

    return resultObj;
};

export const notificationReducer = createReducer(initialState, {
    [actionTypes.GET_LS_LIST_NOTICE_STATUS_ACTION_SUCCESS]: (state: NotificationReducerType, {response}) => ({
        ...state,
        getLsListStatus: true,
        infoModal: {
            ...state.infoModal,
            componentData: {
                data: response,
                fetching: false,
            },
        },
        lsNoticeStatusList: getLsNoticeStatusList(response),
    }),
    [actionTypes.OPEN_NOTICE_ACTION]: (state: NotificationReducerType, {payload}) => ({
        ...state,
        currentReadNotice: payload.idNotice,
    }),
    [actionTypes.GET_CRITICAL_NOTICE_ACTION_SUCCESS]: (state: NotificationReducerType, {response}) => ({
        ...state,
        criticalNotice: response,
    }),
    [actionTypes.SET_NOTICE_READER_ACTION]: (state: NotificationReducerType, {payload}) => ({
        ...state,
        criticalNotice: state.criticalNotice.map((noticeItem) =>
            noticeItem.idNotice === payload.idNotice ? {...noticeItem, prReaded: true} : noticeItem,
        ),
        lsNotice: state.lsNotice.map((dayNoticeItem) => ({
            ...dayNoticeItem,
            dataArray: dayNoticeItem.dataArray.map((noticeItem) =>
                noticeItem.idNotice === payload.idNotice ? {...noticeItem, prReaded: true} : noticeItem,
            ),
        })),
    }),
    [actionTypes.GET_BANNER_NOTICE_ACTION_SUCCESS]: (state: NotificationReducerType, {response}) => ({
        ...state,
        bannerNotice: response,
        openBannerNoticeModal: response.length,
    }),
    [actionTypes.GET_LS_NOTICE_ACTION]: (state: NotificationReducerType) => ({
        ...state,
        fetchingLsNotice: true,
        lsNotice: [],
    }),
    [actionTypes.GET_LS_NOTICE_ACTION_SUCCESS]: (state: NotificationReducerType, {response}) => ({
        ...state,
        fetchingLsNotice: false,
        lsNotice: sortByDate(groupByDay(response), {fromBiggest: true, sortKey: "date"}),
    }),
    [actionTypes.GET_LS_NOTICE_ACTION_FAILURE]: (state: NotificationReducerType) => ({
        ...state,
        fetchingLsNotice: false,
    }),
    [actionTypes.GET_LS_NOTICE_COUNT_ACTION_SUCCESS]: (state: NotificationReducerType, {response, payload}) => ({
        ...state,
        lsNoticeStatusList: {
            ...state.lsNoticeStatusList,
            [payload.idService]: {
                ...state.lsNoticeStatusList[payload.idService],
                cntNotice: response.cntNotice,
            },
        },
    }),
    [actionTypes.GET_LS_NOTICE_CRITICAL_STATUS_ACTION_SUCCESS]: (
        state: NotificationReducerType,
        {response, payload},
    ) => ({
        ...state,
        lsNoticeStatusList: {
            ...state.lsNoticeStatusList,
            [payload.idService]: {
                ...state.lsNoticeStatusList[payload.idService],
                isCritical: response.isCritical,
            },
        },
    }),
    [actionTypes.CLOSE_BANNER_NOTICE_MODAL_ACTION]: (state: NotificationReducerType) => ({
        ...state,
        openBannerNoticeModal: state.openBannerNoticeModal - 1,
    }),
    [actionTypes.OPEN_BANNER_NOTICE_MODAL_ACTION]: (state: NotificationReducerType) => ({
        ...state,
        openBannerNoticeModal: state.openBannerNoticeModal,
    }),
    [actionTypes.CLOSE_CRITICAL_NOTICE_MODAL_ACTION]: (state: NotificationReducerType, {payload: {fromModal}}) => ({
        ...state,
        fromModal: fromModal ? fromModal : state.fromModal,
        isCriticalNoticeModalOpen: false,
    }),
    [actionTypes.OPEN_CRITICAL_NOTICE_MODAL_ACTION]: (state: NotificationReducerType) => ({
        ...state,
        isCriticalNoticeModalOpen: true,
    }),
    [actionTypes.OPEN_INFO_MODAL_ACTION]: (state: NotificationReducerType, {payload: {modalName}}) => ({
        ...state,
        infoModal: {
            ...state.infoModal,
            modalName,
            open: true,
            title: modalInfoTitle[modalName],
        },
    }),
    [actionTypes.CLOSE_INFO_MODAL_ACTION]: (state: NotificationReducerType) => ({
        ...state,
        infoModal: {
            ...state.infoModal,
            open: false,
        },
    }),
    [actionTypes.GET_LS_LIST_NOTICE_STATUS_ACTION]: (state: NotificationReducerType) => ({
        ...state,
        infoModal: {
            ...state.infoModal,
            componentData: {
                data: [],
                fetching: true,
            },
        },
    }),
    [actionTypes.GET_LS_LIST_NOTICE_STATUS_ACTION_FAILURE]: (state: NotificationReducerType) => ({
        ...state,
        infoModal: {
            ...state.infoModal,
            componentData: {
                data: [],
                fetching: false,
            },
        },
    }),
});
