// @flow

// Example page
export const EXAMPLE_DATA_ACTION = "EXAMPLE_DATA_ACTION";
export const EXAMPLE_DATA_ACTION_SUCCESS = "EXAMPLE_DATA_ACTION_SUCCESS";
export const EXAMPLE_DATA_ACTION_FAILURE = "EXAMPLE_DATA_ACTION_FAILURE";

export const EXAMPLE_METADATA_ACTION = "EXAMPLE_METADATA_ACTION";
export const EXAMPLE_METADATA_ACTION_SUCCESS = "EXAMPLE_METADATA_ACTION_SUCCESS";
export const EXAMPLE_METADATA_ACTION_FAILURE = "EXAMPLE_METADATA_ACTION_FAILURE";

export const CONVERT_CHART_DATA_ACTION = "CONVERT_CHART_DATA_ACTION";

export const EXAMPLE_BUILDER_ACTION = "EXAMPLE_BUILDER_ACTION";
export const EXAMPLE_BUILDER_ACTION_SUCCESS = "EXAMPLE_BUILDER_ACTION_SUCCESS";
export const EXAMPLE_BUILDER_ACTION_FAILURE = "EXAMPLE_BUILDER_ACTION_FAILURE";

export const MODAL_TOOGLE_ACTION = "MODAL_TOOGLE_ACTION";

export const TRANSMIT_DATA_ACTION = "TRANSMIT_DATA_ACTION";

export const LOCALE_STORAGE_REMOVE_ITEM_ACTION = "LOCALE_STORAGE_REMOVE_ITEM_ACTION";
