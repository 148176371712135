// @flow
import * as types from "../../constants/types/ActionTypes";
import {createReducer} from "./createReducer";

type FaqType = any;

export type FaqReducerType = {
    faqInfo: Array<Object>,
    faqList: Array<FaqType>,
    fetching: boolean,
};

const initialState: FaqReducerType = {
    faqInfo: [],
    faqList: [],
    fetching: false,
};

export const faqReducer = createReducer(initialState, {
    [types.GET_FAQ_TAB_INFO_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        faqInfo: response.filter((provider) => provider.prVisible),
    }),
    [types.GET_FAQ_LIST_ACTION]: (state: FaqReducerType): FaqReducerType => ({
        ...initialState,
        faqInfo: state.faqInfo,
        fetching: true,
    }),
    [types.GET_FAQ_LIST_ACTION_SUCCESS]: (state: FaqReducerType, {response}): FaqReducerType => ({
        ...state,
        faqList: response,
        fetching: false,
    }),
    [types.GET_FAQ_LIST_ACTION_FAILURE]: (state: FaqReducerType): FaqReducerType => ({
        ...initialState,
        faqInfo: state.faqInfo,
        fetching: false,
    }),
});
