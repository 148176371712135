// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import cn from "classnames";
import {getContent} from "../utils";

const styles = {
    clickableItem: {
        cursor: "pointer",
    },
    innerImage: {
        width: "100%",
    },
    root: {
        backgroundSize: "cover",
        display: "block",
        left: "50%",
        minHeight: "100%",
        minWidth: "100%",
        position: "absolute",
        top: "50%;",
        transform: "translate(-50%, -50%)",
        zIndex: -1,
    },
};

type Props = {
    content: Array<{
        imgsrc: string,
        nmLink: string,
        nnOrder: number,
        vlContent: string,
        vlContentB: string,
    }>,
    classes?: Object,
    innerImage?: boolean,
    nmFileExtensions?: string,
    isMirrorDisabled?: boolean,
    searchParams?: Object,
};
export const ImageElement = ({content = [], classes = {}, innerImage, isMirrorDisabled, searchParams = {}}: Props) => {
    const {imgsrc, nmLink} = getContent(content, searchParams) || {};

    if (imgsrc) {
        return (
            <img
                src={imgsrc}
                alt=""
                className={cn({
                    [classes.innerImage]: Boolean(innerImage),
                    [classes.root]: !innerImage,
                    [classes.clickableItem]: Boolean(nmLink) && !isMirrorDisabled,
                })}
            />
        );
    }

    return null;
};

export default withStyles(styles)(ImageElement);
