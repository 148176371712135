// @flow
import * as React from "react";
import omit from "lodash/omit";
import MaskedInput from "react-text-mask";
import TextField from "../../components/Form/TextField";
import {getMask} from "../utils";
import {phoneNotNumberReg} from "../../../constants/Mask";
import {getCaretPosition, setCaretPosition} from "../../../utils/caretPosition";

type ConfigType = {
    nmInputMask: string,
    showMask?: boolean,
    placeholderChar?: string,
};
type MaskPropsType = {
    "aria-invalid": boolean,
    autoComplete: string,
    autoFocus?: boolean,
    className?: string,
    disabled: boolean,
    name: "NN_LS",
    placeholder?: string,
    readOnly?: boolean,
    required?: boolean,
    type: string,
    value: string,
    testid?: string,
};
type InputPropsType = {
    inputComponent?: (maskProps: MaskPropsType) => React.Node,
    onChange: (value: SyntheticInputEvent<HTMLInputElement> | string) => void,
};

type Props = {
    config: ConfigType,
    InputProps: InputPropsType,
};

export const FieldTextMask = (props: Props) => {
    const maskedInputRef = React.useRef<MaskedInput | null>(null);
    const {
        mask,
        maskedInput,
    }: {
        mask: Array<string | RegExp>,
        maskedInput: (maskProps: MaskPropsType) => React.Node,
    } = React.useMemo(() => {
        const fieldMask: Array<string | RegExp> = getMask(props.config.nmInputMask);
        const maskedInputFunc = (maskProps: MaskPropsType): React.Node => (
            <MaskedInput
                ref={maskedInputRef}
                {...omit(maskProps, ["inputRef"])}
                mask={mask}
                placeholderChar={props.config.placeholderChar || "\u2000"}
                showMask={props.config.showMask}
            />
        );

        return {mask: fieldMask, maskedInput: maskedInputFunc};
    }, [props.config.nmInputMask, props.config.placeholderChar, props.config.showMask]);

    const handleKeyPress = (event: KeyboardEvent) => {
        const input = maskedInputRef.current && maskedInputRef.current.inputElement;
        const inputCarret = getCaretPosition(input);
        const char = event.key;

        if (mask[inputCarret] === char) {
            setTimeout(() => {
                const newPosition = getCaretPosition(input);
                const insertChar = input && input.value[newPosition];

                if (insertChar === char) {
                    setCaretPosition(input, newPosition + 1);
                }
            }, 0);
        }
    };

    const handleChange = (value: SyntheticInputEvent<HTMLInputElement> | string) => {
        const serverValue =
            typeof value === "string"
                ? value.replace(phoneNotNumberReg, "")
                : value.currentTarget.value.replace(phoneNotNumberReg, "");

        props.InputProps.onChange(serverValue);
    };
    const handleMaskClick = (event: SyntheticInputEvent<HTMLInputElement>) => {
        const {target} = event;
        const {value} = target;

        if (value && value.replace(/\D+/g, "").length === 0) {
            setCaretPosition(target, 0);
        }
    };

    const {...InputProps} = props.InputProps;

    InputProps.inputComponent = maskedInput;
    InputProps.onChange = handleChange;

    return (
        <TextField
            {...props}
            InputProps={{
                ...InputProps,
                onClick: handleMaskClick,
                onKeyPress: handleKeyPress,
            }}
        />
    );
};

export default React.memo<Props>(FieldTextMask);
