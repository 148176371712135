// @flow
import React, {PureComponent} from "react";
import cn from "classnames";
import {Transition} from "react-transition-group";
import {withStyles} from "@material-ui/core/styles";

/* eslint-disable sort-keys */
const styles = (theme) => ({
    defaultStyles: {
        marginTop: "-4000px",
        transition: "margin-top 300ms ease-in-out",
        overflow: "hidden",
    },
    "background-color-translucent": {
        background: theme.palette.primary.translucent,
    },
    "padding-none": {
        padding: 0,
    },
    "padding-sm": {
        padding: theme.spacing.unit * 2,
    },
    "cursor-auto": {
        cursor: "auto",
    },
    "cursor-pointer": {
        cursor: "pointer",
    },
});
/* eslint-enable sort-keys */

export type Props = {
    background?: "translucent",
    children?: React$Node,
    classes?: any,
    cursor?: "auto" | "pointer",
    onClick?: () => void,
    padding?: "none" | "sm",
    show?: boolean,
    testID?: string,
};

class PanelBody extends PureComponent<Props> {
    static panelName = "PanelBody";

    render() {
        const {background = "", children, classes = {}, padding = "", show, testID = "", cursor = "auto"} = this.props;

        const className = cn(classes.defaultStyles, {
            [classes[`padding-${padding}`]]: padding,
            [classes[`background-color-${background}`]]: background,
            [classes[`cursor-${cursor}`]]: cursor,
        });

        const transitionStyles = {
            entered: {marginTop: 0},
            entering: {marginTop: "-4000px"},
        };

        return (
            <div style={{overflow: "auto"}} testid={testID} onClick={this.props.onClick}>
                <Transition in={show} timeout={0}>
                    {(state) => (
                        <div className={className} style={{...transitionStyles[state]}}>
                            {children}
                        </div>
                    )}
                </Transition>
            </div>
        );
    }
}

export default withStyles(styles)(PanelBody);
