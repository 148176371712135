// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {type IconPropsType} from "./iconType";

const DEFAULT_SIZE = 40;

export const HeatingIcon = ({fontSize, ...otherProps}: IconPropsType) => (
    <SvgIcon {...otherProps} style={{fontSize}} width="40" height="40">
        <path
            fill="#E3E5E6"
            d="M39.6,15l-19-14.7c-0.4-0.3-0.9-0.3-1.3,0L0.4,15c-0.8,0.6-0.3,1.8,0.6,1.8h3.1V39c0,0.6,0.5,1,1,1h29.7
	c0.6,0,1-0.5,1-1V16.8H39C39.9,16.8,40.4,15.6,39.6,15z"
        />
        <g>
            <path
                fill="#B9C1C6"
                d={
                    "M15.8,29.6c0,1.6-0.6,3-1.8,4.1c-0.4,0.4-0.8,0.5-1.2,0.1s-0.3-0.8,0.1-1.2c2-1.9," +
                    "1.6-4.4-0.2-6.2c-2.3-2.2-2.3-5.7,0-7.9c0.4-0.4,0.8-0.7,1.3-0.2c0.4,0.4,0.4,0.8-0.1," +
                    "1.4c-1.7,1.8-1.6,4.1,0.2,5.8C15.1,26.6,15.7,27.9,15.8,29.6z"
                }
            />
            <path
                fill="#B9C1C6"
                d={
                    "M22.5,29.6c0,1.6-0.6,3-1.8,4.1c-0.4,0.4-0.8,0.5-1.2,0.1c-0.4-0.4-0.3-0.8,0.1-1.2c2-" +
                    "1.9,1.6-4.4-0.2-6.2c-2.3-2.2-2.3-5.7,0-7.9c0.4-0.4,0.8-0.7,1.3-0.2c0.4,0.4,0.4,0.8-0.1," +
                    "1.4c-1.7,1.8-1.6,4.1,0.2,5.8C21.7,26.6,22.4,27.9,22.5,29.6z"
                }
            />
            <path
                fill="#B9C1C6"
                d={
                    "M29.1,29.6c0,1.6-0.6,3-1.8,4.1c-0.4,0.4-0.8,0.5-1.2,0.1c-0.4-0.4-0.3-0.8,0.1-" +
                    "1.2c2-1.9,1.6-4.4-0.2-6.2c-2.3-2.2-2.3-5.7,0-7.9c0.4-0.4,0.8-0.7,1.3-0.2c0.4," +
                    "0.4,0.4,0.8-0.1,1.4c-1.7,1.8-1.6,4.1,0.2,5.8C28.4,26.6,29.1,27.9,29.1,29.6z"
                }
            />
        </g>
    </SvgIcon>
);

HeatingIcon.defaultProps = {
    fontSize: DEFAULT_SIZE,
    viewBox: "0 0 40 40",
};

export default HeatingIcon;
