// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {DEFAULT_SIZE} from "../../../constants/iconConstants";
import {type IconPropsType} from "./iconType";

const TmkNrgLogoIcon = ({fontSize, ...otherProps}: IconPropsType) => (
    <SvgIcon {...otherProps} style={{fontSize}}>
        <path
            fill="#F58F1F"
            d={
                "M83.704,106.762c2.622,6.452,4.265,10.47,6.55,15.631c-23.476,10.523-52.007,6.147-71.28-13.126" +
                "c-24.934-24.934-24.934-65.36,0-90.294l100.173,100.173c0.035,0.035-0.002,0.092-" +
                "0.048,0.076L83.704,106.762z"
            }
        />
        <path
            fill="#004780"
            d={
                "M44.296,21.238c-2.622-6.452-4.265-10.47-6.55-15.631c23.476-10.523,52.007-6.147,71.28,13.126" +
                "c24.934,24.934,24.934,65.36,0,90.294L8.853,8.853C8.819,8.819,8.855,8.761,8.901,8.777L44.296,21.238z"
            }
        />
        <g>
            <path
                fill="#FFFFFF"
                d="M50.818,49.707l-1.275,5.992H41.58L36.98,77.231H29.75l4.601-21.532h-8.234l1.276-5.992H50.818z"
            />
            <path
                fill="#FFFFFF"
                d={
                    "M75.134,61.343c0,4.742-1.353,8.685-4.059,11.829c-2.706,3.145-6.34,4.716-10.901,4.716" +
                    "c-3.454,0-6.205-0.895-8.254-2.687c-2.049-1.791-3.073-4.194-3.073-7.21v-0.619h6.958c" +
                    "0.078,3.119,1.624,4.678,4.64,4.678c1.726,0,3.163-0.586,4.31-1.759c1.146-1.172," +
                    "1.991-2.673,2.532-4.503h-9.354l1.198-5.567h8.775c-0.181-1.727-0.723-3.054-1.624-3.982c-" +
                    "0.902-0.928-2.101-1.392-3.595-1.392c-2.604,0-4.395,1.302-5.374,3.904h-7.268" +
                    "c0.851-3.092,2.416-5.476,4.697-7.151c2.28-1.675,5.096-2.513,8.446-2.513c3.763," +
                    "0,6.694,1.07,8.795,3.208C74.083,54.437,75.134,57.452,75.134,61.343z"
                }
            />
            <path
                fill="#FFFFFF"
                d={
                    "M101.846,67.064c-0.696,3.454-2.217,6.121-4.562,8.002c-2.346,1.882-5.296,2.822-8.853,2.822" +
                    "c-3.763,0-6.694-1.069-8.795-3.208s-3.15-5.154-3.15-9.046c0-4.742,1.354-8.685,4.06-11.829" +
                    "c2.705-3.144,6.34-4.716,10.901-4.716" +
                    "c3.453,0,6.204,0.896,8.253,2.687c2.049,1.792,3.073,4.194,3.073,7.209v0.619h-6.958" +
                    "c-0.077-3.118-1.624-4.677-4.64-4.677" +
                    "c-1.675,0-3.105,0.593-4.29,1.778c-1.187,1.186-2.018,2.552-2.494,4.098" +
                    "c-0.477,1.546-0.715,3.131-0.715,4.755" +
                    "c0,2.088,0.457,3.705,1.372,4.852c0.915,1.147,2.21,1.72,3.886,1.72" +
                    "c1.442,0,2.653-0.431,3.634-1.295c0.979-0.863,1.675-2.12,2.087-3.769H101.846z"
                }
            />
        </g>
    </SvgIcon>
);

TmkNrgLogoIcon.defaultProps = {
    fontSize: DEFAULT_SIZE,
    viewBox: "0 0 128 128",
};

export default TmkNrgLogoIcon;
