// @flow
import React from "react";
import type {Location, RouterHistory} from "react-router-dom";
import {Grid} from "@material-ui/core";
import Button from "../../packages/components/Button/Button";
import Dialog from "../../packages/components/Dialog/Dialog";
import {DialogContent} from "../../packages/components/Dialog/DialogContent";
import Typography from "../../packages/components/Typography/TypographyMaterial";
import DialogActions from "../../packages/components/Dialog/DialogActions";
import {DialogTitle} from "../../packages/components/Dialog/DialogTitle";
import DialogCloseIcon from "../../packages/components/Dialog/DialogCloseIcon";

type Props = {
    history: RouterHistory,
    location: Location,
};

export const OpenMobilePaymentFailurePage = (props: Props) => {
    const openAndroidApp = (packageApp: string, scheme: string) =>
        window.location.replace(
            `intent://payment.redirect?redirect=back_url_f/#Intent;scheme=${scheme};package=${packageApp};end`,
        );
    const redirectToAuth = () => props.history.push("/auth");

    const handleOpenMobileApp = () => {
        switch (process.env.REACT_APP_PLAY_MARKET_ID) {
            case "ru.tii.lkcomu_tmk":
                openAndroidApp(process.env.REACT_APP_PLAY_MARKET_ID, "tmkapp");
                break;
            case "ru.tii.lkcomu_ufa":
                openAndroidApp(process.env.REACT_APP_PLAY_MARKET_ID, "ufaapp");
                break;
            case "ru.tii.lkcomu_sar":
                openAndroidApp(process.env.REACT_APP_PLAY_MARKET_ID, "sarapp");
                break;
            case "ru.tii.lkcomu_vlg":
                openAndroidApp(process.env.REACT_APP_PLAY_MARKET_ID, "vlgapp");
                break;
            default:
                redirectToAuth();
                break;
        }
    };

    React.useEffect(() => {
        handleOpenMobileApp();
    }, []);

    return (
        <React.Fragment>
            <Dialog fullWidth disableBackdropClick maxWidth="sm" open>
                <DialogCloseIcon onClick={redirectToAuth} />
                <DialogTitle>Подтверждение</DialogTitle>
                <DialogContent>
                    <Grid container spacing={16} direction="column" justify="space-between">
                        <Grid item xs={12}>
                            <Typography>
                                Нажмите кнопку <b>Продолжить</b> чтобы открыть мобильное приложение
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" size="full" onClick={handleOpenMobileApp}>
                        Продолжить
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default OpenMobilePaymentFailurePage;
