// @flow
import * as actionTypes from "../../constants/types/ActionTypes";
import * as indicationActionTypes from "../../constants/types/IndicationActionTypes";
import {createReducer} from "./createReducer";

export type MoeReducerType = {
    payResult: Object,
    transferIndicationsModal: Object,
    lsIndication: Object,
    fetchingTransferIndications: Array<string>,
    transferIndication: Object,
};

const initialStatePayResult = {
    payStatus: {},
    showModal: false,
};

const initialState = {
    fetchingTransferIndications: [],
    lsIndication: {},
    payResult: initialStatePayResult,
    transferIndication: {
        currentLs: "",
        requestArr: [],
    },
    transferIndicationsModal: {
        message: false,
        nnLs: "",
        open: false,
    },
};

export const moeReducer = createReducer(initialState, {
    [actionTypes.MOE_PAY_STATUS_ACTION]: (state: MoeReducerType) => ({
        ...state,
        payResult: initialStatePayResult,
    }),
    [actionTypes.MOE_PAY_STATUS_ACTION_SUCCESS]: (state: MoeReducerType, {response}) => ({
        ...state,
        payResult: {
            payStatus: response,
            showModal: true,
        },
    }),
    [actionTypes.MOE_PAY_STATUS_ACTION_FAILURE]: (state: MoeReducerType) => ({
        ...state,
        payResult: initialStatePayResult,
    }),
    [actionTypes.CLOSE_MOE_PAY_STATUS_INFO_ACTION]: (state: MoeReducerType) => ({
        ...state,
        payResult: initialStatePayResult,
    }),
    [indicationActionTypes.ABONENT_SAVE_INDICATION_ACTION]: (state, {payload}) => ({
        ...state,
        fetchingTransferIndications: [...state.fetchingTransferIndications, payload.idCounter],
        lsIndication: payload,
    }),
    [indicationActionTypes.ABONENT_SAVE_INDICATION_ACTION_SUCCESS]: (state, {payload}) => ({
        ...state,
        fetchingTransferIndications: state.fetchingTransferIndications.filter(
            (idCounter) => idCounter !== payload.idCounter,
        ),
        lsIndication: {},
    }),
    [indicationActionTypes.ABONENT_SAVE_INDICATION_ACTION_FAILURE]: (state, {payload, response}) => {
        const {kdResult} = response || {};
        let {prSkipErr} = payload;

        if (kdResult) {
            prSkipErr = kdResult === 1 ? 1 : 0;
        }

        return {
            ...state,
            fetchingTransferIndications: state.fetchingTransferIndications.filter(
                (idCounter) => idCounter !== payload.idCounter,
            ),
            lsIndication: {...payload, prSkipErr},
        };
    },
    [indicationActionTypes.OPEN_CONFIRM_TRANSFER_INDICATION_ACTION]: (state, {payload}) => ({
        ...state,
        transferIndicationsModal: {open: true, ...payload},
    }),
    [indicationActionTypes.CLOSE_CONFIRM_TRANSFER_INDICATION_ACTION]: (state) => ({
        ...state,
        transferIndication: {
            currentLs: state.transferIndication.requestArr.length === 1 ? "" : state.transferIndication.currentLs,
            requestArr: state.transferIndication.requestArr.slice(1),
        },
        transferIndicationsModal: {...state.transferIndicationsModal, open: false},
    }),
    [indicationActionTypes.ADD_REQUEST_LIST_ACTION]: (state, {payload}) => ({
        ...state,
        transferIndication: {
            currentLs: payload.nnLs,
            requestArr: payload.requestArr,
        },
    }),
    [indicationActionTypes.REMOVE_ITEM_FROM_REQUEST_LIST_ACTION]: (state) => ({
        ...state,
        transferIndication: {
            currentLs: state.transferIndication.requestArr.length === 1 ? "" : state.transferIndication.currentLs,
            requestArr: state.transferIndication.requestArr.slice(1),
        },
    }),
});
