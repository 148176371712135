/* eslint-disable max-lines */
// @flow
import get from "lodash/get";
import * as types from "../../constants/types/ActionTmkProviderTypes";
import {RESPONSE_RESULT_TEXT_CODE} from "../../constants/KdResults";
import {
    checkTmkInfo,
    checkOrlInfo,
    getTmkFieldsConfigs,
    getTmkInitialValues,
    getTmkRadioGroupInitialValues,
} from "../../utils/accoutReducerUtils";
import {createReducer} from "./createReducer";

export type payInfoType = {
    fetching: boolean,
    payAddress: {
        nmEmail: string,
        nnPhone: string,
    },
    payAvail: boolean,
    payInfo: {
        bytTmk: {
            disableSubmitBtn: boolean,
            fieldsConfigs: Array<Object>,
            fieldsInfo: Array<Object>,
            initialValues: Object,
            radioGroupInitialValues: Object,
        },
        orl: {
            defaultNotEditableCheckedValue?: boolean,
            disableSubmitBtn: boolean,
            fieldsConfigs: Array<Object>,
            fieldsInfo: Array<Object>,
            initialValues: Object,
            notEditableFieldsConfigs: Array<Object>,
            notEditableIds: Array<number>,
        },
        tomsk: {
            disableSubmitBtn: boolean,
            fieldsConfigs: Array<Object>,
            fieldsInfo: Array<Object>,
            initialValues: Object,
        },
    },
};
const initialPayInfo: payInfoType = {
    fetching: true,
    payAddress: {
        nmEmail: "",
        nnPhone: "",
    },
    payAvail: true,
    payInfo: {
        bytTmk: {
            disableSubmitBtn: true,
            fieldsConfigs: [],
            fieldsInfo: [],
            initialValues: {},
            radioGroupInitialValues: {},
        },
        orl: {
            defaultNotEditableCheckedValue: false,
            disableSubmitBtn: true,
            fieldsConfigs: [],
            fieldsInfo: [],
            initialValues: {},
            notEditableFieldsConfigs: [],
            notEditableIds: [],
        },
        tomsk: {
            disableSubmitBtn: true,
            fieldsConfigs: [],
            fieldsInfo: [],
            initialValues: {},
        },
    },
};

type transferIndicationType = {
    confirmModals: {
        additionalText: Array<string>,
        title: string,
    },
    fetching: boolean,
    fetchingId: string,
};
const initialTransferIndication: transferIndicationType = {
    confirmModals: {additionalText: [], title: ""},
    fetching: false,
    fetchingId: "",
};

type tmkBytBalanceType = {
    smToPaySummary: number,
};
const initialTmkBytBalance: tmkBytBalanceType = {smToPaySummary: 0};

export type AccountTmkReducerType = {
    extraContracts: Array<Object>,
    extraCounters: Array<Object>,
    fetchingExtraContracts: boolean,
    fetchingTmkSubscrLsList: boolean,
    pay: payInfoType,
    transferIndication: transferIndicationType,
    requisitesModal: Object,
    tmkSubscrLsList: Array<Object>,
    tmkBytBalance: tmkBytBalanceType,
};
const initialState: AccountTmkReducerType = {
    extraContracts: [],
    extraCounters: [],
    fetchingExtraContracts: false,
    fetchingTmkSubscrLsList: false,
    pay: initialPayInfo,
    requisitesModal: {},
    tmkBytBalance: initialTmkBytBalance,
    tmkSubscrLsList: [],
    transferIndication: initialTransferIndication,
};

export const accountTmkReducer = createReducer(initialState, {
    [types.GET_EXTRA_CONTRACT_ACTION]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        extraContracts: [],
        fetchingExtraContracts: true,
    }),
    [types.GET_EXTRA_CONTRACT_ACTION_SUCCESS]: (
        state: AccountTmkReducerType,
        {payload, response},
    ): AccountTmkReducerType => ({
        ...state,
        extraContracts: [...state.extraContracts, {...response, vlProvider: payload.vlProvider}],
        fetchingExtraContracts: false,
    }),
    [types.GET_EXTRA_CONTRACT_ACTION_FAILURE]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        extraContracts: [],
        fetchingExtraContracts: false,
    }),
    [types.CLEAN_EXTRA_PROVIDER_INFO_ACTION]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        extraContracts: [],
        extraCounters: [],
    }),
    [types.GET_EXTRA_COUNTERS_ACTION]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        extraCounters: [],
    }),
    [types.GET_EXTRA_COUNTERS_ACTION_SUCCESS]: (
        state: AccountTmkReducerType,
        {payload, response},
    ): AccountTmkReducerType => ({
        ...state,
        extraCounters: [
            ...state.extraCounters,
            ...response.map((responseItem) => ({...responseItem, vlProvider: payload.vlProvider})),
        ],
    }),
    [types.GET_EXTRA_COUNTERS_ACTION_FAILURE]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        extraCounters: [],
    }),
    [types.SEND_INDICATION_TMK_ACTION_FAILURE]: (
        state: AccountTmkReducerType,
        {payload: {data, modal}, response: {kdResult, nmResult}},
    ): AccountTmkReducerType => ({
        ...state,
        transferIndication: {
            confirmModals: {
                ...state.transferIndication.confirmModals,
                additionalText: [...state.transferIndication.confirmModals.additionalText, modal.additionalText],
                buttonCancelTitle: "Нет",
                buttonConfirmTitle: "Да",
                title: nmResult,
            },
            data: {
                ...data,
                prSkipAnomaly: kdResult === 4 ? 1 : 0,
                prSkipErr: kdResult === 1 ? 1 : 0,
            },
            fetching: false,
            fetchingId: "",
            nmResult,
            type: "indication",
        },
    }),
    [types.TRANSFER_EXTRA_INDICATION_CALC_CHARGE_ACTION_SUCCESS]: (
        state: AccountTmkReducerType,
        {payload, response: {kdResult, nmResult}},
    ): AccountTmkReducerType => {
        const {title, buttonConfirmTitle, buttonCancelTitle, ...rest} = payload;

        return kdResult === RESPONSE_RESULT_TEXT_CODE
            ? {
                  ...state,
                  transferIndication: {
                      ...state.transferIndication,
                      confirmModals: {
                          ...state.transferIndication.confirmModals,
                          additionalText: [""],
                          buttonCancelTitle,
                          buttonConfirmTitle,
                          open: true,
                          title,
                      },
                      data: {
                          ...rest,
                      },
                      fetching: false,
                      nmResult,
                      open: true,
                      type: "extraIndication",
                  },
              }
            : state;
    },
    [types.CLOSE_TRANSFER_INDICATION_ACTION]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        transferIndication: {
            ...state.transferIndication,
            confirmModals: {
                additionalText: [],
                buttonCancelTitle: "",
                errorDialog: false,
                sameButtons: false,
                title: "",
            },
            fetching: false,
            nmResult: "",
            type: "",
        },
    }),
    [types.TRANSFER_EXTRA_INDICATION_ACTION]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        transferIndication: {
            ...state.transferIndication,
            fetching: true,
        },
    }),
    [types.SEND_INDICATION_TMK_ACTION]: (state: AccountTmkReducerType, {payload: {data}}): AccountTmkReducerType => ({
        ...state,
        transferIndication: {
            ...state.transferIndication,
            fetchingId: data.fetchingId,
        },
    }),
    [types.SEND_INDICATION_TMK_ACTION_SUCCESS]: (
        state: AccountTmkReducerType,
        {payload: {data, modal}, response: {nmResult}},
    ): AccountTmkReducerType => ({
        ...state,
        transferIndication: {
            ...state.transferIndication,
            confirmModals: {
                ...state.transferIndication.confirmModals,
                additionalText: [...state.transferIndication.confirmModals.additionalText, modal.additionalText],
                buttonCancelTitle: modal.buttonCancelTitle,
                errorDialog: true,
                open: true,
                sameButtons: true,
                title: nmResult,
            },
            fetchingId: data.idCounter,
        },
    }),
    [types.GET_REQUISITES_MODAL_DATA_ACTION]: (state: AccountTmkReducerType, {payload}): AccountTmkReducerType => ({
        ...state,
        requisitesModal: {
            fetching: true,
            metaDataKey: payload.metaDataKey,
            modalWidth: payload.modalWidth,
            open: true,
            proxy: payload.proxy,
            requestData: payload.requestData,
            responseData: [],
            title: payload.title,
            type: payload.type,
            withPeriod: payload.withPeriod,
        },
    }),
    [types.GET_REQUISITES_MODAL_DATA_ACTION_SUCCESS]: (
        state: AccountTmkReducerType,
        {response},
    ): AccountTmkReducerType => ({
        ...state,
        requisitesModal: {
            ...state.requisitesModal,
            fetching: false,
            responseData: response,
        },
    }),
    [types.GET_REQUISITES_MODAL_DATA_ACTION_FAILURE]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        requisitesModal: {...state.requisitesModal, fetching: false, responseData: []},
    }),
    [types.CLOSE_REQUISITES_MODAL_ACTION]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        requisitesModal: {...state.requisitesModal, open: false},
    }),
    [types.GET_TMK_PD_SUBSCR_STATUS_ACTION]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        fetchingTmkSubscrLsList: true,
    }),
    [types.GET_TMK_PD_SUBSCR_STATUS_ACTION_SUCCESS]: (
        state: AccountTmkReducerType,
        {response},
    ): AccountTmkReducerType => ({
        ...state,
        fetchingTmkSubscrLsList: false,
        tmkSubscrLsList: response,
    }),
    [types.GET_TMK_PD_SUBSCR_STATUS_ACTION_FAILURE]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        fetchingTmkSubscrLsList: false,
    }),
    [types.GET_TMK_BYT_BALANCE_ACTION]: (state: AccountTmkReducerType, {payload}): AccountTmkReducerType => ({
        ...state,
        tmkBytBalance: payload.value,
    }),
    [types.CLEAN_TMK_BYT_BALANCE_ACTION]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        tmkBytBalance: initialTmkBytBalance,
    }),
    [types.TMK_CHECK_PAY_AVAIL_ACTION]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        pay: {...state.pay, fetching: true},
    }),
    [types.TMK_CHECK_PAY_AVAIL_ACTION_SUCCESS]: (state: AccountTmkReducerType, action): AccountTmkReducerType => ({
        ...state,
        pay: {...state.pay, fetching: false, payAvail: action.response.prPayAvail},
    }),
    [types.TMK_CHECK_PAY_AVAIL_ACTION_FAILURE]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        pay: {...state.pay, fetching: false, payAvail: false},
    }),
    [types.TMK_PAY_ADDRESS_ACTION]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        pay: {...state.pay, fetching: true},
    }),
    [types.TMK_PAY_ADDRESS_ACTION_SUCCESS]: (state: AccountTmkReducerType, action): AccountTmkReducerType => ({
        ...state,
        pay: {...state.pay, fetching: false, payAddress: action.response},
    }),
    [types.TMK_PAY_ADDRESS_ACTION_FAILURE]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        pay: {...state.pay, fetching: false, payAddress: {}},
    }),
    [types.INFO_PAYMENT_ACTION]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        pay: {...state.pay, fetching: true},
    }),
    [types.INFO_PAYMENT_ACTION_SUCCESS]: (state: AccountTmkReducerType, {response, payload}): AccountTmkReducerType => {
        if (payload.provider === "bytTmk") {
            return {
                ...state,
                pay: {
                    ...state.pay,
                    fetching: false,
                },
            };
        }

        const isOrelProvider = payload.provider === "orl";
        const notEditableIds = get(state, "pay.payInfo.orl.notEditableIds");
        const notEditableFields = [];
        const checkedFields = isOrelProvider ? checkOrlInfo(response) : checkTmkInfo(response);
        const filterableFields = isOrelProvider
            ? checkedFields.filter((field) => {
                  const id = field?.serviceProviderSupplierid;

                  if (id && notEditableIds.includes(id)) {
                      notEditableFields.push(field);

                      return false;
                  }

                  return true;
              })
            : checkedFields;
        const fieldsConfigs = getTmkFieldsConfigs(filterableFields, payload.provider);
        const radioGroupInitialValues = isOrelProvider ? null : getTmkRadioGroupInitialValues(fieldsConfigs);
        const disableSubmitBtn = filterableFields.length > 0 ? filterableFields.every((field) => !field?.valid) : true;
        const initialValues = getTmkInitialValues(checkedFields);
        let newPayInfoState = {
            ...state.pay.payInfo[payload.provider],
            disableSubmitBtn,
            fieldsConfigs,
            fieldsInfo: checkedFields,
            initialValues,
            radioGroupInitialValues,
        };

        if (isOrelProvider) {
            newPayInfoState = {
                ...newPayInfoState,
                notEditableFieldsConfigs: notEditableFields,
            };
        }

        return {
            ...state,
            pay: {
                ...state.pay,
                fetching: false,
                payInfo: {
                    ...state.pay.payInfo,
                    [payload.provider]: newPayInfoState,
                },
            },
        };
    },
    [types.INFO_PAYMENT_BYT_ACTION]: (state: AccountTmkReducerType, {payload}): AccountTmkReducerType => {
        const checkedFields = checkTmkInfo(payload.fieldsInfo);

        return {
            ...state,
            pay: {
                ...state.pay,
                fetching: false,
                payInfo: {
                    ...state.pay.payInfo,
                    [payload.provider]: {
                        disableSubmitBtn:
                            checkedFields.length > 0 ? checkedFields.every((field) => !field.valid) : true,
                        fieldsConfigs: getTmkFieldsConfigs(checkedFields, payload.provider),
                        fieldsInfo: checkedFields,
                        initialValues: getTmkInitialValues(checkedFields),
                    },
                },
            },
        };
    },
    [types.INFO_PAYMENT_ACTION_FAILURE]: (state: AccountTmkReducerType, {payload}): AccountTmkReducerType => ({
        ...state,
        pay: {
            ...state.pay,
            fetching: false,
            payInfo: {
                ...state.pay.payInfo,
                [payload.provider]: {},
            },
        },
    }),
    [types.TMK_REMOVE_PAY_DATA_ACTION]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        pay: initialPayInfo,
    }),

    [types.ORL_PAY_SETTINGS_ACTION]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        pay: {
            ...state.pay,
            payInfo: {
                ...state.pay.payInfo,
                orl: {
                    ...state.pay.payInfo.orl,
                    defaultNotEditableCheckedValue: undefined,
                    notEditableIds: [],
                },
            },
        },
    }),
    [types.ORL_PAY_SETTINGS_ACTION_SUCCESS]: (state: AccountTmkReducerType, {response}): AccountTmkReducerType => ({
        ...state,
        pay: {
            ...state.pay,
            payInfo: {
                ...state.pay.payInfo,
                orl: {
                    ...state.pay.payInfo.orl,
                    defaultNotEditableCheckedValue: response?.prImmutablePayCheckbox || false,
                    notEditableIds: response?.vlImmutablePaySupplierId || [],
                },
            },
        },
    }),
    [types.ORL_PAY_SETTINGS_ACTION_FAILURE]: (state: AccountTmkReducerType): AccountTmkReducerType => ({
        ...state,
        pay: {
            ...state.pay,
            payInfo: {
                ...state.pay.payInfo,
                orl: {
                    ...state.pay.payInfo.orl,
                    defaultNotEditableCheckedValue: undefined,
                    notEditableIds: [],
                },
            },
        },
    }),
});
