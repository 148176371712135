// @flow
import * as React from "react";
import cn from "classnames";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "./styles";

export type ContentHorizontalPadding = "xl" | "xs" | "sm" | "md" | 8 | 16 | 20 | 24 | 30 | 48;
export type ContentVerticalPadding = false | "xs" | "sm" | "lg" | 4 | 10 | 15 | 20 | 24 | 30 | 40 | 48 | 56 | 64 | 100;
export type ContentVerticalMargin = 20;
export type BackgroundColor = "" | "grey" | "translucent" | "white" | "primary-light" | "black" | "light-grey";
type Props = {|
    children: React.Node,
    background?: BackgroundColor,
    className?: string,
    classes?: Object,
    vertical?: ContentVerticalPadding,
    verticalMargin?: ContentVerticalMargin,
    marginTop?: 8 | 16,
    horizontal?: ContentHorizontalPadding,
    component?: React.ComponentType<Props>,
    underline?: boolean,
    maxHeight?: "45vh" | 600,
    height?: 400 | 600,
    minHeight?: 24 | 36 | 52 | 250 | 300 | 340,
    maxWidth?: 110 | 220 | 407 | 520 | 600 | 700 | "40%" | "80%",
    overflowX?: "scroll" | "auto" | "hidden",
    overflowY?: "hidden" | "auto",
    paddingTop?: 0 | 8 | 20,
    paddingRight?: 16,
    withBorder?: boolean,
    error?: boolean,
    position?: "relative",
    display?: "inlineBlock" | "inlineTable" | "flex",
    align?: "right" | "left",
|};

export function Content(props: Props) {
    const {
        background,
        classes = {},
        component: Component = "div",
        className: parentClassName,
        maxHeight,
        minHeight,
        overflowX,
        overflowY,
        paddingRight,
        paddingTop,
        vertical,
        verticalMargin,
        horizontal,
        underline,
        withBorder,
        error,
        maxWidth,
        position,
        marginTop,
        display,
        align,
        height,
        ...otherProps
    } = props;
    const className = cn(parentClassName, {
        [classes.rootDefault]: !display,
        [classes[`align-${align || ""}`]]: align,
        [classes[`display-${display || ""}`]]: display,
        [classes[`vertical-padding-${vertical || ""}`]]: vertical,
        [classes[`margin-top-${marginTop || ""}`]]: marginTop,
        [classes[`vertical-margin-${verticalMargin || ""}`]]: verticalMargin,
        [classes[`horizontal-padding-${horizontal || ""}`]]: horizontal,
        [classes[`background-${background || ""}`]]: background,
        [classes[`padding-right-${paddingRight || ""}`]]: paddingRight,
        [classes[`padding-top-${paddingTop || ""}`]]: paddingTop,
        [classes[`overflow-x-${overflowX || ""}`]]: overflowX,
        [classes[`overflow-y-${overflowY || ""}`]]: overflowY,
        [classes[`max-height-${maxHeight || ""}`]]: maxHeight,
        [classes[`height-${height || ""}`]]: height,
        [classes[`min-height-${minHeight || ""}`]]: minHeight,
        [classes[`max-width-${maxWidth || ""}`]]: maxWidth,
        [classes[`position-${position || ""}`]]: position,
        [classes.underline]: underline,
        [classes.border]: withBorder,
        [classes.error]: error,
    });

    return <Component className={className} {...otherProps} />;
}
export default withStyles(styles)(Content);
