// @flow
import * as actionTypes from "../../constants/types/ActionTypes";
import * as crmActionTypes from "../../constants/types/CrmActionTypes";
import {
    SERVICE_PAGE_KD_SECTION_WITH_CRMKU,
    SERVICE_PAGE_KD_SECTION_PASS_INDICATIONS,
    SERVICE_PAGE_KD_SECTION_QUESTION_AND_ANSWER,
    SERVICE_PAGE_KD_SECTION_WITHOUT_CRMKU,
    SERVICE_PAGE_KD_SECTION_TEST,
} from "../../constants/ServicePageKdSection";
import {ENERGY_CONTRACTS_KD_SECTION, PAY_ADDITIONAL_SERVICES_KD_SECTION} from "../../constants/kdSections";
import {type PhoneEmailType} from "../../packages/components/Form/PhoneEmailField";
import {patchMetadata} from "../../utils/patch";
import {getLocaleStorageAction, setLocaleStorageAction} from "../actions/storageActions/localeStorageAction";
import {authKey, menuSettingsKey} from "../../constants/Application";
import {createReducer} from "./createReducer";

const minutesToMillisecondsCoefficient = 600000;

export type ConfigSettingsType = {
    adContainerRotationTime: number,
    appOnetimePswEnable: boolean,
    balanceCacheTime: number,
    captchaCheck: boolean,
    crm20Integration: boolean,
    crm20QaFrame?: boolean,
    crmVer?: number,
    crmkuIntegration: boolean,
    dateBirthFrom?: number,
    dateBirthTo?: number,
    datePlaceholder?: string,
    deleteAccaunt?: boolean,
    lkkMainUrl?: string,
    lsDescriptionLength: number,
    maxFileSizeUpload?: number,
    prBtnSubscrEmails?: Array<Object>,
    prKvitonomBtnAddLs?: Array<Object>,
    prKvitonomBtnSubscrEmails?: Array<Object>,
    prShowBudget?: Array<Object>,
    prShowNumberTempLs?: boolean,
    prUseDestUrl?: boolean,
    providerRegType: PhoneEmailType,
    pswMetamodel: Object,
    showBankIdeyWeb10?: Array<Object>,
    showPassWeb: boolean,
    sudirIntegration: boolean,
    vbrrFrameAddServices?: string,
};

export type TopMenuItem = {
    url: string,
    query: Object,
    text: string,
    id?: string,
    testID?: string,
    kdSection?: number,
};

export type OperationsForAllAccountsType = {
    fetching: boolean,
    isOperationsForAllAccountsVisible?: boolean,
    prIndicationsVisible?: boolean,
    prPaymentsVisible?: boolean,
};

export type ConfigReducerType = {
    recaptcha: {
        hl: string,
        sitekey: string,
    },
    settings: ConfigSettingsType,
    topMenuItems: Array<TopMenuItem>,
    poll: {nmSection: string, kdSection: number, prVisible: boolean},
    operationsForAllAccounts: OperationsForAllAccountsType,
};

const initialState: ConfigReducerType = {
    operationsForAllAccounts: {fetching: true},
    poll: {kdSection: SERVICE_PAGE_KD_SECTION_TEST, nmSection: "", prVisible: false},
    recaptcha: {
        hl: "ru",
        sitekey: process.env.REACT_APP_CAPTCHA_SITEKEY || "",
    },
    settings: {
        adContainerRotationTime: 0,
        appOnetimePswEnable: false,
        balanceCacheTime: 0,
        captchaCheck: true,
        crm20Integration: false,
        crm20QaFrame: false,
        crmVer: 0,
        crmkuIntegration: true,
        dateBirthFrom: 0,
        dateBirthTo: 0,
        deleteAccaunt: false,
        lkkMainUrl: "",
        lsDescriptionLength: 0,
        prShowNumberTempLs: false,
        providerRegType: "default",
        pswMetamodel: {},
        showPassWeb: false,
        sudirIntegration: false,
    },
    topMenuItems: [],
};

/* eslint-disable quote-props */
const menuItems = {
    [ENERGY_CONTRACTS_KD_SECTION]: {
        id: "",
        query: {},
        testID: "EnergyContractsBtn",
        url: "/energy-contracts",
    },
    [PAY_ADDITIONAL_SERVICES_KD_SECTION]: {
        id: "",
        query: {},
        testID: "PayAdditionalServicesBtn",
        url: "/pay-additional-services",
    },
    [SERVICE_PAGE_KD_SECTION_PASS_INDICATIONS]: {
        id: "id2209",
        query: {},
        testID: "PassIndicationsBtn",
        url: "/transfer-indications-all",
    },
    [SERVICE_PAGE_KD_SECTION_QUESTION_AND_ANSWER]: {
        id: "id22014",
        query: {},
        testID: "QuestionsAndAnswersBtn",
        url: "/faq",
    },
    [SERVICE_PAGE_KD_SECTION_WITHOUT_CRMKU]: {
        query: {},
        testID: "withoutCrmkuIntegration",
        url: "/services",
    },
    [SERVICE_PAGE_KD_SECTION_WITH_CRMKU]: {
        id: "id22011",
        query: {},
        testID: "CatalogOfServicesBtn",
        url: "/services",
    },
};
/* eslint-enable quote-props */

export const configReducer = createReducer(initialState, {
    [actionTypes.GET_CONFIG_SYS_SETTINGS_SUCCESS]: (state, {response: {settings}}) => {
        const {
            appOnetimePswEnable,
            adContainerRotationTime,
            balanceCacheTime,
            captchaCheck,
            crm20Integration,
            crmkuIntegration,
            psw,
            crm20QaFrame,
            lsDescriptionLength,
            maxFileSizeUpload,
            sudirIntegration,
            vbrrFrameAddServices,
            lkkMainUrl,
            prBtnSubscrEmails,
            prKvitonomBtnAddLs,
            prKvitonomBtnSubscrEmails,
            prShowBudget,
            dateBirthFrom,
            dateBirthTo,
            prUseDestUrl,
            deleteAccaunt,
            prShowNumberTempLs,
            showBankIdeyWeb10,
            showPassWeb,
            datePlaceholder,
        } = settings;
        const providerRegType = settings.providerRegType.toLowerCase();

        patchMetadata(providerRegType);

        return {
            ...state,
            settings: {
                adContainerRotationTime,
                appOnetimePswEnable,
                balanceCacheTime: balanceCacheTime * minutesToMillisecondsCoefficient,
                captchaCheck,
                crm20Integration,
                crm20QaFrame,
                crmkuIntegration,
                dateBirthFrom,
                dateBirthTo,
                datePlaceholder,
                deleteAccaunt,
                lkkMainUrl,
                lsDescriptionLength,
                maxFileSizeUpload,
                prBtnSubscrEmails,
                prKvitonomBtnAddLs,
                prKvitonomBtnSubscrEmails,
                prShowBudget,
                prShowNumberTempLs,
                prUseDestUrl,
                providerRegType,
                pswMetamodel: psw,
                showBankIdeyWeb10,
                showPassWeb,
                sudirIntegration,
                vbrrFrameAddServices,
            },
        };
    },
    [actionTypes.GET_MENU_SETTINGS_ACTION_SUCCESS]: (state, {response}) => {
        const resultArr = [];

        response
            .filter(({kdSection}) => kdSection !== SERVICE_PAGE_KD_SECTION_TEST)
            .forEach(({kdSection, nmSection, prVisible, nmMetric}) => {
                if (prVisible) {
                    const otherServices =
                        kdSection !== SERVICE_PAGE_KD_SECTION_WITHOUT_CRMKU &&
                        kdSection !== SERVICE_PAGE_KD_SECTION_WITH_CRMKU;
                    const withCrmkuIntegration =
                        kdSection === SERVICE_PAGE_KD_SECTION_WITH_CRMKU && state.settings.crmkuIntegration;
                    const withoutCrmkuIntegration =
                        kdSection === SERVICE_PAGE_KD_SECTION_WITHOUT_CRMKU && !state.settings.crmkuIntegration;

                    if (otherServices || withoutCrmkuIntegration || withCrmkuIntegration) {
                        resultArr.push({
                            ...menuItems[kdSection],
                            kdSection,
                            nmMetric,
                            text: nmSection,
                        });
                    }
                }
            });
        const {idProfile} = getLocaleStorageAction(authKey)?.payload?.value || {};

        setLocaleStorageAction(`${menuSettingsKey}-${idProfile}`, resultArr);

        return {
            ...state,
            poll: response.find(({kdSection}) => kdSection === SERVICE_PAGE_KD_SECTION_TEST),
            topMenuItems: resultArr,
        };
    },
    [crmActionTypes.CRM_GET_VER_ACTION_SUCCESS]: (state, {response: {crmVer}}) => ({
        ...state,
        settings: {
            ...state.settings,
            crmVer,
        },
    }),
    [actionTypes.GET_MULTIPLE_LS_PAY_VISIBILITY_ACTION]: (state) => ({
        ...state,
        operationsForAllAccounts: {
            fetching: true,
        },
    }),
    [actionTypes.GET_MULTIPLE_LS_PAY_VISIBILITY_ACTION_SUCCESS]: (state, action) => {
        const {prPaymentsVisible, prIndicationsVisible} = action.response;

        return {
            ...state,
            operationsForAllAccounts: {
                ...state.operationsForAllAccounts,
                fetching: false,
                isOperationsForAllAccountsVisible: prPaymentsVisible && prIndicationsVisible,
                prIndicationsVisible,
                prPaymentsVisible,
            },
        };
    },
    [actionTypes.GET_MULTIPLE_LS_PAY_VISIBILITY_ACTION_FAILURE]: (state) => ({
        ...state,
        operationsForAllAccounts: {
            ...state.operationsForAllAccounts,
            fetching: false,
        },
    }),
});
