/* eslint-disable max-lines */
// @flow
import * as profileActionTypes from "../../constants/types/ProfileActionTypes";
import patternTypes from "../../packages/Builder/BPattern/PatternParsed/patternTypes";
import {createReducer} from "./createReducer";

const parseSettingsTfa = (arr: Array<Object>) => {
    const data = {
        activeItemKd: undefined,
        items: [],
    };

    arr.forEach(({kdTfa, nmTfa, prActive, prEnable, prAvailChange, prHintNotAvail}) => {
        if (prEnable) {
            if (prActive && kdTfa) {
                data.activeItemKd = `${kdTfa}`;
            }

            data.items.push({
                editable: Boolean(prAvailChange),
                enable: Boolean(prEnable),
                hint: Boolean(prHintNotAvail),
                kd: `${kdTfa}`,
                nm: nmTfa,
            });
        }
    });

    return data;
};

export type PaymentDocSubscrItemType = {
    idService: number,
    nnLs: string,
    kdProvider: number,
    nmProvider: string,
    vlProvider: string,
};
type SettingTfaItemType = {
    editable: boolean,
    enable: boolean,
    kd: string,
    nm: string,
    hint?: string,
};
type SettingTfaType = {
    active: boolean,
    activeItemKd?: string,
    defaultExpand: boolean,
    editable: boolean,
    fetching: boolean,
    items: Array<SettingTfaItemType>,
    show: boolean,
};
export type ProfileReducerType = {
    adSubscrList: Array<Object>,
    isConfirm: Object,
    paymentDocSubscrList: Array<PaymentDocSubscrItemType>,
    altSubscrLsList: Array<Object>,
    mesSubscrLsList: Array<Object>,
    moeSubscrLsList: Array<Object>,
    orlEpdSubscrLsList: Array<Object>,
    orlSubscrLsList: Array<Object>,
    sarSubscrLsList: Array<Object>,
    tmbSubscrLsList: Array<Object>,
    ufaSubscrLsList: Array<Object>,
    vldSubscrLsList: Array<Object>,
    vlgSubscrLsList: Array<Object>,
    ksgSubscrLsList: Array<Object>,
    linkedSystems: Object,
    fetching: {
        getAdSubscrStatus: boolean,
        getAltPdSubscrStatus: boolean,
        getKsgPdSubscrStatus: boolean,
        getMesPdSubscrStatus: boolean,
        getMoePdSubscrStatus: boolean,
        getOrlPdSubscrStatus: boolean,
        getSarPdSubscrStatus: boolean,
        getTmbPdSubscrStatus: boolean,
        getTmkPdSubscrStatus: boolean,
        getUfaPdSubscrStatus: boolean,
        getVldPdSubscrStatus: boolean,
        getVlgPdSubscrStatus: boolean,
        userChangeLsSubscr: boolean,
        userSetLsSubscr: boolean,
        userSetProfileSubscr: boolean,
    },
    prSetPsw: number,
    countryList: Array<Object>,
    paspDetails: Object,
    paspDetailsFailed: Object,
    settingTfa: SettingTfaType,
};

const settingTfaInitialState = {
    active: false,
    activeItemKd: undefined,
    defaultExpand: false,
    editable: false,
    fetching: false,
    items: [],
    show: false,
};
const initialState: ProfileReducerType = {
    adSubscrList: [],
    altSubscrLsList: [],
    countryList: [],
    fetching: {
        getAdSubscrStatus: true,
        getAltPdSubscrStatus: false,
        getKsgPdSubscrStatus: false,
        getMesPdSubscrStatus: false,
        getMoePdSubscrStatus: false,
        getOrlPdSubscrStatus: false,
        getSarPdSubscrStatus: false,
        getTmbPdSubscrStatus: false,
        getTmkPdSubscrStatus: false,
        getUfaPdSubscrStatus: false,
        getVldPdSubscrStatus: false,
        getVlgPdSubscrStatus: false,
        userChangeLsSubscr: false,
        userSetLsSubscr: false,
        userSetProfileSubscr: false,
    },
    isConfirm: {},
    ksgSubscrLsList: [],
    linkedSystems: {},
    mesSubscrLsList: [],
    moeSubscrLsList: [],
    orlEpdSubscrLsList: [],
    orlSubscrLsList: [],
    paspDetails: {},
    paspDetailsFailed: {},
    paymentDocSubscrList: [],
    prSetPsw: 0,
    sarSubscrLsList: [],
    settingTfa: settingTfaInitialState,
    tmbSubscrLsList: [],
    ufaSubscrLsList: [],
    vldSubscrLsList: [],
    vlgSubscrLsList: [],
};

export const profileReducer = createReducer(initialState, {
    [profileActionTypes.IS_CONFIRM_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        isConfirm: {...response},
    }),
    [profileActionTypes.GET_PASP_DETAILS_ACTION_SUCCESS]: (state, {response}) => {
        const {dateIn, nmCountry, nmCodeOrgIn, ...other} = response;

        return {
            ...state,
            paspDetails: {
                ...other,
                dateIn: dateIn
                    ? patternTypes.datetime({
                          data: dateIn,
                          format: "dd.mm.yyyy",
                      })
                    : null,
                nmCodeOrgIn:
                    nmCodeOrgIn && nmCountry.toLowerCase() === "россия" ? nmCodeOrgIn.replace(/\\-/g, "") : nmCodeOrgIn,
                nmCountry,
            },
        };
    },
    [profileActionTypes.GET_COUNTRY_LIST_ACTION_SUCCESS]: (state, {response}) => {
        const russiaIndex = response.findIndex((item) => item.nmCountry.toLowerCase() === "россия");
        const filteredArray = [
            response[russiaIndex],
            ...response.slice(0, russiaIndex),
            ...response.slice(russiaIndex + 1),
        ];

        return {
            ...state,
            countryList: filteredArray.map((item) => ({
                nmValue: item.nmCountry,
                nnCode: item.kdCountry,
            })),
        };
    },
    [profileActionTypes.GET_PASP_DETAILS_FAILED_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        paspDetailsFailed: {...response?.chkInfo} || {},
    }),
    [profileActionTypes.GET_MOE_PD_SUBSCR_STATUS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getMoePdSubscrStatus: false,
        },
        moeSubscrLsList: response,
    }),
    [profileActionTypes.GET_MES_PD_SUBSCR_STATUS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getMesPdSubscrStatus: false,
        },
        mesSubscrLsList: response,
    }),
    [profileActionTypes.GET_UFA_PD_SUBSCR_STATUS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getUfaPdSubscrStatus: false,
        },
        ufaSubscrLsList: response,
    }),
    [profileActionTypes.GET_VLG_PD_SUBSCR_STATUS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getVlgPdSubscrStatus: false,
        },
        vlgSubscrLsList: response,
    }),
    [profileActionTypes.GET_ALT_PD_SUBSCR_STATUS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        altSubscrLsList: response,
        fetching: {
            ...state.fetching,
            getAltPdSubscrStatus: false,
        },
    }),
    [profileActionTypes.GET_ORL_PD_SUBSCR_STATUS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getOrlPdSubscrStatus: false,
        },
        orlSubscrLsList: response,
    }),
    [profileActionTypes.GET_ORL_EPD_PD_SUBSCR_STATUS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        orlEpdSubscrLsList: response,
    }),
    [profileActionTypes.GET_SAR_PD_SUBSCR_STATUS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getSarPdSubscrStatus: false,
        },
        sarSubscrLsList: response,
    }),
    [profileActionTypes.GET_TMB_PD_SUBSCR_STATUS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getTmbPdSubscrStatus: false,
        },
        tmbSubscrLsList: response,
    }),
    [profileActionTypes.GET_VLD_PD_SUBSCR_STATUS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getVldPdSubscrStatus: false,
        },
        vldSubscrLsList: response,
    }),
    [profileActionTypes.GET_KSG_PD_SUBSCR_STATUS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getKsgPdSubscrStatus: false,
        },
        ksgSubscrLsList: response,
    }),
    [profileActionTypes.GET_AD_SUBSCR_STATUS_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getAdSubscrStatus: true,
        },
    }),
    [profileActionTypes.GET_AD_SUBSCR_STATUS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        adSubscrList: response,
        fetching: {
            ...state.fetching,
            getAdSubscrStatus: false,
        },
    }),
    [profileActionTypes.GET_AD_SUBSCR_STATUS_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getAdSubscrStatus: false,
        },
    }),
    [profileActionTypes.GET_PD_SUBSCR_STATUS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        paymentDocSubscrList: response,
    }),
    [profileActionTypes.USER_SET_PROFILE_SUBSCR_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            userSetProfileSubscr: true,
        },
    }),
    [profileActionTypes.USER_SET_PROFILE_SUBSCR_ACTION_SUCCESS]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            userSetProfileSubscr: false,
        },
    }),
    [profileActionTypes.USER_SET_PROFILE_SUBSCR_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            userSetProfileSubscr: false,
        },
    }),
    [profileActionTypes.LINKED_SYSTEMS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        linkedSystems: response,
    }),
    [profileActionTypes.PSW_SET_CHECK_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        prSetPsw: response.nnAction,
    }),

    [profileActionTypes.SET_LS_SUBSCR_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            userSetLsSubscr: true,
        },
    }),
    [profileActionTypes.SET_LS_SUBSCR_ACTION_SUCCESS]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            userSetLsSubscr: false,
        },
    }),
    [profileActionTypes.SET_LS_SUBSCR_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            userSetLsSubscr: false,
        },
    }),
    [profileActionTypes.USER_CHANGE_LS_SUBSCR_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            userChangeLsSubscr: true,
        },
    }),
    [profileActionTypes.USER_CHANGE_LS_SUBSCR_ACTION_SUCCESS]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            userChangeLsSubscr: false,
        },
    }),
    [profileActionTypes.USER_CHANGE_LS_SUBSCR_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            userChangeLsSubscr: false,
        },
    }),

    [profileActionTypes.GET_MES_PD_SUBSCR_STATUS_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getMesPdSubscrStatus: true,
        },
    }),
    [profileActionTypes.GET_MES_PD_SUBSCR_STATUS_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getMesPdSubscrStatus: false,
        },
    }),
    [profileActionTypes.GET_MOE_PD_SUBSCR_STATUS_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getMoePdSubscrStatus: true,
        },
    }),
    [profileActionTypes.GET_MOE_PD_SUBSCR_STATUS_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getMoePdSubscrStatus: false,
        },
    }),
    [profileActionTypes.GET_UFA_PD_SUBSCR_STATUS_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getUfaPdSubscrStatus: true,
        },
    }),
    [profileActionTypes.GET_UFA_PD_SUBSCR_STATUS_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getUfaPdSubscrStatus: true,
        },
    }),
    [profileActionTypes.GET_VLG_PD_SUBSCR_STATUS_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getVlgPdSubscrStatus: true,
        },
    }),
    [profileActionTypes.GET_VLG_PD_SUBSCR_STATUS_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getVlgPdSubscrStatus: false,
        },
    }),
    //
    [profileActionTypes.GET_ORL_PD_SUBSCR_STATUS_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getOrlPdSubscrStatus: true,
        },
    }),
    [profileActionTypes.GET_ORL_PD_SUBSCR_STATUS_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getOrlPdSubscrStatus: false,
        },
    }),
    //
    [profileActionTypes.GET_ALT_PD_SUBSCR_STATUS_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getAltPdSubscrStatus: true,
        },
    }),
    [profileActionTypes.GET_ALT_PD_SUBSCR_STATUS_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getAltPdSubscrStatus: false,
        },
    }),
    [profileActionTypes.GET_SAR_PD_SUBSCR_STATUS_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getSarPdSubscrStatus: true,
        },
    }),
    [profileActionTypes.GET_SAR_PD_SUBSCR_STATUS_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getSarPdSubscrStatus: false,
        },
    }),
    [profileActionTypes.GET_TMB_PD_SUBSCR_STATUS_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getTmbPdSubscrStatus: true,
        },
    }),
    [profileActionTypes.GET_TMB_PD_SUBSCR_STATUS_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getTmbPdSubscrStatus: false,
        },
    }),
    [profileActionTypes.GET_VLD_PD_SUBSCR_STATUS_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getVldPdSubscrStatus: true,
        },
    }),
    [profileActionTypes.GET_VLD_PD_SUBSCR_STATUS_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
            getVldPdSubscrStatus: false,
        },
    }),
    [profileActionTypes.GET_KSG_PD_SUBSCR_STATUS_ACTION]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
        },
        getKsgPdSubscrStatus: true,
    }),
    [profileActionTypes.GET_KSG_PD_SUBSCR_STATUS_ACTION_FAILURE]: (state) => ({
        ...state,
        fetching: {
            ...state.fetching,
        },
        getKsgPdSubscrStatus: false,
    }),
    // ДФА
    [profileActionTypes.SHOW_SETTINGS_TFA_ACTION]: (state, {payload: {show}}) => ({
        ...state,
        settingTfa: {
            ...state.settingTfa,
            ...settingTfaInitialState,
            fetching: true,
            show: Boolean(show),
        },
    }),
    [profileActionTypes.SHOW_SETTINGS_TFA_ACTION_SUCCESS]: (state, {response}) => {
        const {items, activeItemKd} = parseSettingsTfa(response?.settingTfa || []);

        return {
            ...state,
            settingTfa: {
                ...state.settingTfa,
                active: Boolean(response?.prCheckboxActiveTfa),
                activeItemKd,
                defaultExpand: Boolean(response?.prControlShowTfa),
                editable: Boolean(response?.prCheckboxEnableTfa),
                fetching: false,
                items,
                show: Boolean(response?.prControlTfa),
            },
        };
    },
    [profileActionTypes.SHOW_SETTINGS_TFA_ACTION_FAILURE]: (state) => ({
        ...state,
        settingTfa: {
            ...state.settingTfa,
            ...settingTfaInitialState,
        },
    }),
});
