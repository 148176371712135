// @flow
import React from "react";
import qs from "qs";
import type {Location, RouterHistory} from "react-router-dom";

type Props = {
    history: RouterHistory,
    location: Location,
};

export const OpenMobileAppPage = (props: Props) => {
    const openAndroidApp = (intent: string, search: string) =>
        window.location.replace(`intent://${intent}${search}/#Intent;scheme=mesapp;package=ru.tii.lkcomu;end`);
    const redirectToAuth = () => props.history.push("/auth");

    React.useEffect(() => {
        const {search} = props.location;
        const {mode} = qs.parse(search, {
            ignoreQueryPrefix: true,
        });

        switch (mode) {
            case "sudir_auth":
                openAndroidApp("sudir.auth", search);
                break;
            case "sudir_profile":
                openAndroidApp("sudir.profile", search);
                break;
            case "sudir_logout":
                openAndroidApp("open.app", search);
                break;
            default:
                redirectToAuth();
                break;
        }
    }, []);

    return <React.Fragment />;
};

export default OpenMobileAppPage;
