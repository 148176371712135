// @flow

export const getContent = (content: Array<Object>, searchParams: Object) => {
    for (const key in searchParams) {
        if (Object.prototype.hasOwnProperty.call(searchParams, key)) {
            return content.find((item) => item[key] === searchParams[key]);
        }
    }

    return content[0];
};
