export function getCaretPosition(input) {
    input.focus();
    if (input.selectionStart) {
        return input.selectionStart;
    } else if (document.selection) {
        const sel = document.selection.createRange();
        const clone = sel.duplicate();

        sel.collapse(true);
        clone.moveToElementText(input);
        clone.setEndPoint("EndToEnd", sel);

        return clone.text.length;
    }

    return 0;
}

export function setCaretPosition(input, pos, endPos) {
    const end = endPos || pos;

    if (input.setSelectionRange) {
        input.focus();
        input.setSelectionRange(pos, end);
    } else if (input.createTextRange) {
        const range = input.createTextRange();

        range.collapse(true);
        range.moveEnd("character", end);
        range.moveStart("character", pos);
        range.select();
    }
}
