// @flow
import * as React from "react";
import cn from "classnames";
import ButtonMaterial from "@material-ui/core/Button/Button";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "./ButtonStyle";

export type Props = {|
    padding?: 10,
    border?: boolean,
    capitalize?: boolean,
    centerRipple?: boolean,
    children?: React$Node,
    className?: string,
    classes?: any,
    component?: React$ElementType,
    color?: "primary" | "primaryTranslucent" | "secondary" | "transparent",
    disableRipple?: boolean,
    disabled?: boolean,
    focusRipple?: boolean,
    fontSize?: 10 | 12 | 14 | 18,
    justify?: "left" | "center" | "right",
    link?: boolean,
    onBlur?: Function,
    onClick?: Function,
    onFocus?: Function,
    onKeyDown?: Function,
    onKeyUp?: Function,
    onKeyboardFocus?: (event: SyntheticEvent<>) => void,
    onMouseDown?: Function,
    onMouseLeave?: Function,
    onMouseUp?: Function,
    onTouchEnd?: Function,
    onTouchMove?: Function,
    onTouchStart?: Function,
    minWidth?: "0" | 20 | 50 | 300,
    role?: string,
    rootRef?: Function,
    tabIndex?: number | string,
    textColor?: "white" | "black" | "primary-light" | "secondary" | "error" | "dark-error",
    type?: string,
    size?: "xs" | "sm" | "md" | "lg" | "full",
    xsSize?: "full",
    underline?: "solid" | "dashed" | "native" | "none",
    noShadow?: boolean,
    zeroMinHeight?: boolean,
    zeroPadding?: boolean,
    cursor?: "text" | "default" | "pointer",
    minHeight?: 48,
    list?: boolean,
    modalBtn?: boolean,
    whiteSpace?: "nowrap",
    badgeText?: string,
|};

class Button extends React.PureComponent<Props> {
    static defaultProps: Props = {
        noShadow: true,
    };

    render() {
        const {
            border,
            capitalize,
            color = "",
            disabled,
            size = "",
            classes = {},
            className: classNameProp,
            fontSize = "",
            link,
            justify = "",
            zeroPadding,
            minWidth = "",
            zeroMinHeight,
            noShadow,
            textColor: textColorDefault = "",
            underline = "",
            cursor = "",
            padding = "",
            children,
            minHeight = "",
            list = "",
            xsSize = "",
            whiteSpace = "",
            modalBtn,
            badgeText,
            ...otherProps
        } = this.props;

        const textColor = textColorDefault || (link ? "primary-light" : "white");
        const className = cn(classNameProp, {
            [classes[`font-size-${fontSize}`]]: fontSize,
            [classes["box-shadow-none"]]: link || noShadow,
            [classes["color-transparent"]]: link,
            [classes[`color-${color}`]]: color,
            [classes[`text-color-${textColor}`]]: textColor,
            [classes["zero-padding"]]: zeroPadding,
            [classes["padding-left-right-0"]]: link,
            [classes[`min-width-${minWidth}`]]: minWidth,
            [classes["zero-min-height"]]: zeroMinHeight,
            [classes[`xs-size-${xsSize}`]]: xsSize,
            [classes[`size-${size}`]]: size,
            [classes[`justify-${justify}`]]: justify,
            [classes[`padding-${padding}`]]: padding,
            [classes.border]: border,
            [classes.modalBtn]: modalBtn,
            [classes.linkClass]: link,
            [classes.disabled]: disabled,
            [classes.list]: list,
            [classes[`cursor-${cursor}`]]: cursor,
            [classes[`min-height-${minHeight}`]]: minHeight,
            [classes[`height-${minHeight}`]]: minHeight,
            [classes["text-transform-none"]]: capitalize,
            [classes[`whiteSpace-${whiteSpace}`]]: whiteSpace,
        });
        const labelClasses = cn(classes.label, {
            [classes[`underline-${underline}`]]: underline,
            [classes.marginAuto]: !justify,
        });

        return (
            <ButtonMaterial
                variant="raised"
                className={className}
                classes={{label: labelClasses}}
                disabled={disabled}
                disableRipple={link}
                disableFocusRipple={link}
                {...otherProps}
            >
                {badgeText && <div className={classes.badge}>{badgeText}</div>}
                {children}
            </ButtonMaterial>
        );
    }
}

export default withStyles(styles)(Button);
