// @flow
export const GET_EXTRA_CONTRACT_ACTION = "GET_EXTRA_CONTRACT_ACTION";
export const GET_EXTRA_CONTRACT_ACTION_SUCCESS = "GET_EXTRA_CONTRACT_ACTION_SUCCESS";
export const GET_EXTRA_CONTRACT_ACTION_FAILURE = "GET_EXTRA_CONTRACT_ACTION_FAILURE";

export const GET_TMK_CHECK_BY_LS_ACTION = "GET_TMK_CHECK_BY_LS_ACTION";
export const GET_TMK_CHECK_BY_LS_ACTION_SUCCESS = "GET_TMK_CHECK_BY_LS_ACTION_SUCCESS";
export const GET_TMK_CHECK_BY_LS_ACTION_FAILURE = "GET_TMK_CHECK_BY_LS_ACTION_FAILURE";

export const GET_REQUISITES_MODAL_DATA_ACTION = "GET_REQUISITES_MODAL_DATA_ACTION";
export const GET_REQUISITES_MODAL_DATA_ACTION_SUCCESS = "GET_REQUISITES_MODAL_DATA_ACTION_SUCCESS";
export const GET_REQUISITES_MODAL_DATA_ACTION_FAILURE = "GET_REQUISITES_MODAL_DATA_ACTION_FAILURE";

export const CLOSE_REQUISITES_MODAL_ACTION = "CLOSE_REQUISITES_MODAL_ACTION";

export const SEND_INDICATION_TMK_ACTION = "SEND_INDICATION_TMK_ACTION";
export const SEND_INDICATION_TMK_ACTION_SUCCESS = "SEND_INDICATION_TMK_ACTION_SUCCESS";
export const SEND_INDICATION_TMK_ACTION_FAILURE = "SEND_INDICATION_TMK_ACTION_FAILURE";

export const CLOSE_TRANSFER_INDICATION_ACTION = "CLOSE_TRANSFER_INDICATION_ACTION";

export const GET_EXTRA_COUNTERS_ACTION = "GET_EXTRA_COUNTERS_ACTION";
export const GET_EXTRA_COUNTERS_ACTION_SUCCESS = "GET_EXTRA_COUNTERS_ACTION_SUCCESS";
export const GET_EXTRA_COUNTERS_ACTION_FAILURE = "GET_EXTRA_COUNTERS_ACTION_FAILURE";

export const TRANSFER_EXTRA_INDICATION_ACTION = "TRANSFER_EXTRA_INDICATION_ACTION";
export const TRANSFER_EXTRA_INDICATION_ACTION_SUCCESS = "TRANSFER_EXTRA_INDICATION_ACTION_SUCCESS";
export const TRANSFER_EXTRA_INDICATION_ACTION_FAILURE = "TRANSFER_EXTRA_INDICATION_ACTION_FAILURE";

export const TRANSFER_EXTRA_INDICATION_CALC_CHARGE_ACTION = "TRANSFER_EXTRA_INDICATION_CALC_CHARGE_ACTION";
export const TRANSFER_EXTRA_INDICATION_CALC_CHARGE_ACTION_SUCCESS =
    "TRANSFER_EXTRA_INDICATION_CALC_CHARGE_ACTION_SUCCESS";
export const TRANSFER_EXTRA_INDICATION_CALC_CHARGE_ACTION_FAILURE =
    "TRANSFER_EXTRA_INDICATION_CALC_CHARGE_ACTION_FAILURE";

export const CLEAN_EXTRA_PROVIDER_INFO_ACTION = "CLEAR_EXTRA_PROVIDER_INFO_ACTION";

export const GET_TMK_PD_SUBSCR_STATUS_ACTION = "GET_TMK_PD_SUBSCR_STATUS_ACTION";
export const GET_TMK_PD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_TMK_PD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_TMK_PD_SUBSCR_STATUS_ACTION_FAILURE = "GET_TMK_PD_SUBSCR_STATUS_ACTION_FAILURE";

export const GET_TMK_BYT_BALANCE_ACTION = "GET_TMK_BYT_BALANCE_ACTION";
export const CLEAN_TMK_BYT_BALANCE_ACTION = "CLEAN_TMK_BYT_BALANCE_ACTION";

// Pay actions
export const TMK_CHECK_PAY_AVAIL_ACTION = "TMK_CHECK_PAY_AVAIL_ACTION";
export const TMK_CHECK_PAY_AVAIL_ACTION_SUCCESS = "TMK_CHECK_PAY_AVAIL_ACTION_SUCCESS";
export const TMK_CHECK_PAY_AVAIL_ACTION_FAILURE = "TMK_CHECK_PAY_AVAIL_ACTION_FAILURE";

export const TMK_PAY_ADDRESS_ACTION = "TMK_PAY_ADDRESS_ACTION";
export const TMK_PAY_ADDRESS_ACTION_SUCCESS = "TMK_PAY_ADDRESS_ACTION_SUCCESS";
export const TMK_PAY_ADDRESS_ACTION_FAILURE = "TMK_PAY_ADDRESS_ACTION_FAILURE";

export const INFO_PAYMENT_ACTION = "INFO_PAYMENT_ACTION";
export const INFO_PAYMENT_ACTION_SUCCESS = "INFO_PAYMENT_ACTION_SUCCESS";
export const INFO_PAYMENT_ACTION_FAILURE = "INFO_PAYMENT_ACTION_FAILURE";

export const INFO_PAYMENT_BYT_ACTION = "INFO_PAYMENT_BYT_ACTION";

export const TMK_CHECK_BYT_LS_ACTION = "TMK_CHECK_BYT_LS_ACTION";
export const TMK_CHECK_BYT_LS_ACTION_SUCCESS = "TMK_CHECK_BYT_LS_ACTION_SUCCESS";
export const TMK_CHECK_BYT_LS_ACTION_FAILURE = "TMK_CHECK_BYT_LS_ACTION_FAILURE";

export const TMK_PAYMENT_ACTION = "TMK_PAYMENT_ACTION";
export const TMK_PAYMENT_ACTION_SUCCESS = "TMK_PAYMENT_ACTION_SUCCESS";
export const TMK_PAYMENT_ACTION_FAILURE = "TMK_PAYMENT_ACTION_FAILURE";

export const TMK_REMOVE_PAY_DATA_ACTION = "TMK_REMOVE_PAY_DATA_ACTION";

export const ORL_PAY_SETTINGS_ACTION = "ORL_PAY_SETTINGS_ACTION";
export const ORL_PAY_SETTINGS_ACTION_SUCCESS = "ORL_PAY_SETTINGS_ACTION_SUCCESS";
export const ORL_PAY_SETTINGS_ACTION_FAILURE = "ORL_PAY_SETTINGS_ACTION_FAILURE";
