// @flow
// eslint-disable-next-line import/named
import {type DispatchAPI} from "redux";
import omit from "lodash/omit";
import {baseMocks} from "../providers/example/mocks";
import {type ApiMiddlewareActionType, type ApiMiddlewareResponseType} from "../redux/middlewares/apiMiddleware";
import {authKey} from "../constants/Application";
import {getLocaleStorageAction, setLocaleStorageAction} from "../redux/actions/storageActions/localeStorageAction";
import {camelCaseKeys} from "./objectUtils";

const DELAY_TIME = Number(process.env.EXAMPLE_REQUEST_DELAY || "500");
type Params = {
    next: DispatchAPI<ApiMiddlewareActionType | ApiMiddlewareResponseType>,
    action: ApiMiddlewareActionType,
    successType: string,
    requestType: string,
};

export const mocksRequest = (params: Params): Promise<any> => {
    const {next, action, successType, requestType} = params;
    const name: string = action.payload && action.payload.name ? action.payload.name : "";
    const data = camelCaseKeys(baseMocks[name]);

    // eslint-disable-next-line no-console
    console.info(`${name} будут загружены в течении ${DELAY_TIME} мс`);

    next({
        error: false,
        networkError: false,
        payload: action.payload,
        resSuccess: true,
        type: requestType,
    });

    return new Promise((resolve) => {
        setTimeout(() => {
            // eslint-disable-next-line no-console
            console.info(`${name} загружены`);

            return resolve(
                next({
                    error: false,
                    networkError: false,
                    payload: action.payload,
                    resSuccess: true,
                    response: data,
                    type: successType,
                }),
            );
        }, DELAY_TIME);
    });
};

export const windowReload = () => {
    const authParams = omit(getLocaleStorageAction("auth").payload.value || {}, ["session"]);

    setLocaleStorageAction(authKey, authParams);

    window.location.href = window.location.href;
};
