// @flow

import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import Button from "../Button/Button";
import Typography from "../Typography/Typography";

const styles = (theme: any) => ({
    content: {
        height: "200px",
        width: "100%",
    },
    contentFullHeight: {
        height: `calc(100vh - ${theme.sizing.appbarHeight}px)`,
        width: "100%",
    },
});

type Props = {
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    message?: string,
    fullHeight?: boolean,
    showBtn?: boolean,
};

export const UnSupportedProviderPage = ({classes = {}, message, fullHeight, showBtn}: Props) => (
    <Grid
        container
        spacing={0}
        className={fullHeight ? classes.contentFullHeight : classes.content}
        alignItems="center"
        direction="column"
        justify="center"
    >
        <Grid item>
            <Typography fontSize={16}>{message}</Typography>
        </Grid>
        {showBtn ? (
            <Grid>
                <Button underline="dashed" link size="sm" component={Link} to="">
                    Назад
                </Button>
            </Grid>
        ) : null}
    </Grid>
);

UnSupportedProviderPage.defaultProps = {
    classes: {},
    fullHeight: true,
    message: "Данный провайдер не поддерживается приложением",
    showBtn: true,
};
export default withStyles(styles)(UnSupportedProviderPage);
