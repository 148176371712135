// @flow
import * as React from "react";
import TextField from "../../components/Form/TextField";

type Props = {
    config: Object,
    InputProps: Object,
};

const DEFAULT_FIELD_SIZE = 30;

const TextFieldWithMaxLength = (props: Props) => {
    const handleChange = React.useCallback(
        (event: SyntheticEvent<HTMLInputElement>) => {
            const {value} = event.currentTarget;

            if (value?.length <= (props?.config?.nnFieldSize || DEFAULT_FIELD_SIZE)) {
                props.InputProps.onChange(event);
            }
        },
        [props.config, props.InputProps.onChange],
    );

    return (
        <TextField
            {...props}
            InputProps={{
                ...props.InputProps,
                onChange: handleChange,
            }}
        />
    );
};

export default TextFieldWithMaxLength;
