// @flow
const styles = (theme: any) => ({
    dropDown: {
        position: "relative",
        zIndex: "2",
    },
    icon: {
        display: "block",
    },
    iconButton: {
        color: theme.palette.text.white,
        display: "block",
        height: `calc(${theme.sizing.appbarHeight}px - 5px)`,
        transition: "transform 300ms ease-in-out",
    },
    mainContent: {
        "&:hover": {
            background: theme.palette.topMenu.selected,
        },
        background: theme.palette.secondary.light2,
        borderLeft: `1px solid ${theme.palette.topMenu.separator}`,
        boxSizing: "border-box",
        cursor: "pointer",
        display: "flex",
        minHeight: theme.sizing.appbarHeight,
        padding: "0px 16px",
        [theme.breakpoints.down("md")]: {
            width: "auto",
        },
    },
    rotate: {
        transform: "rotate(-180deg)",
    },
    warningIcon: {
        fontSize: 48,
        position: "relative",
        right: 16,
        top: 24,
    },
});

export {styles};
