// @flow
import React, {Component} from "react";
import cn from "classnames";
import {withStyles} from "@material-ui/core/styles";
import {type Align, type Color} from "@material-ui/core/Typography/Typography";
import TypographyMaterial, {type VariantTypes} from "./TypographyMaterial";
import {TypographyStyles} from "./TypographyStyle";

export type TypographyBackgrColor =
    | "grey"
    | "grey-hightlight"
    | "primary-light"
    | "opacity-primary-light"
    | "secondary"
    | "translucent"
    | "grey-sideBar"
    | "grey-Banner"
    | "white"
    | "secondary-TopMenu";
export type TypographyTextColorType =
    | "inherit"
    | "black"
    | "primary"
    | "primary-light"
    | "secondary"
    | "white"
    | "green"
    | "grey"
    | "grey-sideBar"
    | "grey-Banner"
    | "grey-coolGray1"
    | "grey-coolGray4"
    | "grey-dark"
    | "secondary-TopMenu"
    | "error";

export type TypographyFontSize =
    | 7
    | 10
    | 12
    | 13
    | 14
    | 16
    | 18
    | 20
    | 22
    | 24
    | 28
    | 30
    | 36
    | 40
    | 56
    | 64
    | 80
    | 112;

export type TypographyHorizontalPadding = 0 | 8 | 16;
export type TypographyVerticalPadding = 0 | 8 | 10 | 14 | 16 | 24 | 36 | 42;
export type TypographyProps = {
    align?: Align,
    backgrColor?: TypographyBackgrColor,
    capitalize?: boolean,
    children?: React$Node,
    classes?: Object,
    className?: string,
    component?: React$ElementType,
    color?: Color,
    display?: "inline",
    textColor?: TypographyTextColorType,
    gutterBottom?: boolean,
    noWrap?: boolean,
    paragraph?: boolean,
    fontSize?: TypographyFontSize,
    fontWeight?: "bold" | 300 | 400 | 500 | 700,
    padding?: 5 | 8 | 10 | 15 | 16 | 20 | 28,
    horizontalPadding?: TypographyHorizontalPadding,
    verticalPadding?: TypographyVerticalPadding,
    underline?: boolean,
    uppercase?: boolean,
    mobileHorizontalPadding?: 0,
    lineHeight?: 1 | 14 | 16 | 21 | 28 | 36 | 42,
    minWidth?: 250,
    whiteSpace?: "nowrap",
    textOverflow?: "ellipsis",
    variant?: VariantTypes,
    wordWrap?: "normal",
};

export class Typography extends Component<TypographyProps> {
    render() {
        const {
            backgrColor = "",
            classes = {},
            color,
            textColor = "",
            fontSize = "",
            fontWeight = "",
            padding = "",
            className: parentClassName,
            underline,
            display = "",
            uppercase,
            capitalize,
            horizontalPadding = "",
            verticalPadding = "",
            mobileHorizontalPadding = "",
            lineHeight = "",
            minWidth = "",
            whiteSpace = "",
            textOverflow = "",
            wordWrap = "",
            variant,
            ...other
        } = this.props;

        const rootClassName = cn(parentClassName, classes.rootDefault);
        const className = cn(rootClassName, {
            [classes.colorDefault]: !color && !variant,
            [classes[`background-color-${backgrColor}`]]: !padding && backgrColor,
            [classes[`color-${textColor}`]]: textColor,
            [classes[`fontSize-${fontSize}`]]: fontSize,
            [classes[`display-${display}`]]: display,
            [classes.underline]: underline,
            [classes.uppercase]: uppercase,
            [classes.capitalize]: capitalize,
            [classes[`line-height-${lineHeight}`]]: lineHeight,
            [classes[`font-weight-${fontWeight}`]]: fontWeight,
            [classes[`padding-horizontal-${horizontalPadding}`]]: horizontalPadding,
            [classes[`mobile-horizontal-padding${mobileHorizontalPadding}`]]: mobileHorizontalPadding,
            [classes[`padding-vertical-${verticalPadding}`]]: verticalPadding,
            [classes[`minWidth-${minWidth}`]]: minWidth,
            [classes[`text-overflow-${textOverflow}`]]: textOverflow,
            [classes[`white-space-${whiteSpace}`]]: whiteSpace,
            [classes[`word-wrap-${wordWrap}`]]: wordWrap,
            [rootClassName]: !padding && !wordWrap,
        });
        const wrapperClassName = padding
            ? cn(rootClassName, {
                  [classes[`background-color-${backgrColor}`]]: backgrColor,
                  [classes[`padding-${padding}`]]: padding,
                  [classes["display-inline-block"]]: display,
              })
            : undefined;

        if (padding) {
            return (
                <div className={wrapperClassName} style={{color}}>
                    <TypographyMaterial {...other} className={className} color={color} />
                </div>
            );
        }

        return <TypographyMaterial {...other} variant={variant} color={color} className={className} />;
    }
}

export default withStyles(TypographyStyles)(Typography);
