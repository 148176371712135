// @flow

import {turnedOnProviders} from "../constants/Providers";
import {SMART_BANNER_PROVIDERS_ICON_PATH, DEFAULT_SMART_BANNER_ICON_PATH} from "../constants/smartBanner";

const createMeta = (smartBannerMetaEl: HTMLMetaElement, isAvailProvaderTurnedOn: boolean, providerId: string) => {
    const appleIconMeta = document.createElement("meta");
    const googleIconMeta = document.createElement("meta");
    const iconPath = isAvailProvaderTurnedOn
        ? SMART_BANNER_PROVIDERS_ICON_PATH[providerId]
        : DEFAULT_SMART_BANNER_ICON_PATH;

    appleIconMeta.name = "martbanner:icon-apple";
    appleIconMeta.content = iconPath;

    googleIconMeta.name = "smartbanner:icon-google";
    googleIconMeta.content = iconPath;

    smartBannerMetaEl?.parentNode?.insertBefore(appleIconMeta, smartBannerMetaEl.nextSibling);
    smartBannerMetaEl?.parentNode?.insertBefore(googleIconMeta, smartBannerMetaEl.nextSibling);
};

export const injectSmartBannerIcons = () => {
    const smartBannerMetaEl = Array.prototype.find.call(
        document.getElementsByTagName("meta"),
        (el) => el.getAttribute("name") === "smartbanner:price-suffix-google",
    );

    if (smartBannerMetaEl) {
        let isAvailProvaderTurnedOn = false;
        let providerId = "";

        Object.keys(turnedOnProviders).forEach((id: string) => {
            const isTurnedOn: boolean = turnedOnProviders[id];

            if (isTurnedOn && SMART_BANNER_PROVIDERS_ICON_PATH[id]) {
                isAvailProvaderTurnedOn = true;
                providerId = id;
            }
        });

        createMeta(smartBannerMetaEl, isAvailProvaderTurnedOn, providerId);
    }
};

export default injectSmartBannerIcons();
