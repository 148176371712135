// @flow
import React from "react";
import AltLogo from "../../../images/providers/alt/logo/AltLogo.svg";
import {SMALL_ICON_SIZE} from "../../../constants/iconConstants";

type Props = {
    fontSize?: number,
};

const AltLogoIcon = ({fontSize}: Props) => <img height={fontSize} width={fontSize} src={AltLogo} alt="" />;

AltLogoIcon.defaultProps = {
    fontSize: SMALL_ICON_SIZE,
};

export default AltLogoIcon;
