export const accountMoeTabsSettings = [
    {
        label: "Уведомления",
        notification: true,
        value: "notifications",
    },
    {
        label: "История",
        value: "events",
    },
    {
        label: "Реквизиты ЛС",
        value: "requisites",
    },
    {
        label: "Статистика",
        value: "statistics",
    },
    {
        label: "Обращения",
        value: "application",
    },
];

export const eventsMoeTabsSettings = [
    {
        label: "Единый платежный документ",
        value: "payment-doc",
    },
    {
        label: "Показания",
        value: "readings",
    },
    {
        label: "Оплаты",
        value: "payments",
    },
];
