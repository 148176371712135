// @flow
import {MOE_KD_PROVIDER, TKO_KD_PROVIDER} from "../KdProvider";

export type BuilderAccountMetadataType = {
    [$key: string]: string,
};

export type BuilderAccountStateType = Array<Object>;

export const builderAccountMetadata = {
    abnInfo: "AbnInfo",
    actualSettingsAccrualsSubscriber: "ActualSettingsAccrualsSubscriber",
    autopayMes: "GetAutoPaymentRequestInfo",
    autopayUfa: "GetAutoPaymentRequestInfo",
    common: "AbonentCommonData",
    consumptionRanges: "AbnConsRange",
    contract: "AbonentContractData",
    currentBalance: "AbonentCurrentBalance",
    currentBalanceByt: "CurrentBalance",
    currentBalanceUfa: "CurrentBalance",
    equipment: "AbonentEquipment",
    equipmentContract: "AbonentEquipment",
    historyCounter: "ViewHistoryCounter",
    historyTariffRateSubscriber: "HistoryTariffRateSubscriber",
    indications: (kdProvider: number) => {
        switch (kdProvider) {
            case MOE_KD_PROVIDER:
                return "AbonentIndications";
            case TKO_KD_PROVIDER:
                return "AbonentIndications";
            default:
                return "Indications";
        }
    },
    indicationsCom: "IndicationsCom",
    lsInfo: "LSInfo",
    lsInfoPayment: "LsInfo",
    lsInfoReadings: "LsInfo",
    lsInfoTmk: "LsInfo",
    lsInfoUfa: "LSInfo",
    mapMarkers: "MapMarkers",
    metersHistory: "MetersHistory",
    paymentDoc: "AbonentChargeDetail",
    pays: (kdProvider: number) => {
        switch (kdProvider) {
            case MOE_KD_PROVIDER:
                return "AbonentPays";
            case TKO_KD_PROVIDER:
                return "AbonentPays";
            default:
                return "Pays";
        }
    },
    tariffHistory: "TariffHistory",
    viewGeneralInfoSubscriber: "ViewGeneralInfoSubscriber",
    viewHistoryCounter: "ViewHistoryCounter",
    viewHistoryIndicationsCounter: "ViewHistoryIndicationsCounter",
    viewInfoFormedAccounts: "ViewInfoFormedAccounts",
    viewInfoFormedAccountsDetails: "ViewInfoFormedAccountsDetails",
    viewInfoPaymentReceived: "ViewInfoPaymentReceived",
    viewLSInfoSubscriber: "ViewLSInfoSubscriber",
    viewTiedServiceProvider: "ViewTiedServiceProvider",
};

export const builderAccountKdSection = {
    abnInfo: 16,
    accountMes: 16,
    actualSettingsAccrualsSubscriber: 16,
    autopayMes: 21,
    autopayUfa: 21,
    common: 16,
    consumptionRanges: 16,
    contract: 16,
    currentBalance: 21,
    currentBalanceByt: 21,
    currentBalanceUfa: 21,
    emailSubscr: 6,
    equipment: 11,
    equipmentContract: 16,
    historyCounter: 16,
    historyTariffRateSubscriber: 16,
    indications: 11,
    indicationsCom: 11,
    loadDocuments: 146,
    lsInfo: 16,
    lsInfoPayment: 13,
    lsInfoReadings: 11,
    lsInfoTmk: 16,
    lsInfoUfa: 16,
    mapMarkers: 16,
    metersHistory: 16,
    paymentDoc: 15,
    pays: 13,
    tariffHistory: 16,
    viewGeneralInfoSubscriber: 16,
    viewHistoryCounter: 11,
    viewHistoryIndicationsCounter: 11,
    viewInfoFormedAccounts: 15,
    viewInfoFormedAccountsDetails: 15,
    viewInfoPaymentReceived: 13,
    viewLSInfoSubscriber: 13,
    viewTiedServiceProvider: 16,
};

export const builderAccountEmptyState: BuilderAccountStateType = [];

export type BuilderAccountMetadataKeysType = $Keys<typeof builderAccountMetadata>;
