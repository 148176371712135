export const phoneMask = ["+", /[1-9]/, "(", /\d/, /\d/, /\d/, ")", /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/];
export const phoneHiddenMask = [
    "+",
    /[1-9]/,
    "(",
    /\d/,
    /\d/,
    /\d/,
    ")",
    "*",
    "*",
    "*",
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
];
export const snilsMask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, " ", /\d/, /\d/];

export const phoneNotNumberReg = /[()\- +\s]/g;
