// @flow
import * as React from "react";
import type {ModalInfoDataKeysType} from "../../constants/modalInfoTypes";
import UserInstruction from "./Components/UserInstruction";

type ModalInfoProps = {
    modalName?: ModalInfoDataKeysType,
    data: Array<Object>,
    fetching?: boolean,
};

export const ModalInfoComponent = ({modalName, ...otherProps}: ModalInfoProps) => {
    switch (modalName) {
        case "userinstruction":
            return <UserInstruction {...otherProps} />;
        default:
            return null;
    }
};

export default ModalInfoComponent;
