// @flow

import * as actionTypes from "../../constants/types/CacheActionTypes";
import {dtCacheNames} from "../../constants/types/CacheTypes";
import {type ApiMiddlewareActionType, type ApiMiddlewareResponsePromiseType} from "../middlewares/apiMiddleware";

export type GetDtCacheActionType = (
    name: string,
    session?: string,
    kdSection?: string,
    params?: Object,
) => ApiMiddlewareResponsePromiseType;

export const getDtCacheAction = (
    name: string,
    session?: string,
    kdSection?: string,
    params?: Object,
    // eslint-disable-next-line max-params
): ApiMiddlewareActionType => ({
    api: {
        data: {
            kdSection,
        },
        query: dtCacheNames[name],
        session,
        types: [
            actionTypes.GET_DT_CACHE_ACTION,
            actionTypes.GET_DT_CACHE_ACTION_SUCCESS,
            actionTypes.GET_DT_CACHE_ACTION_FAILURE,
        ],
    },
    payload: {
        isDisabledAuthorisationError: params?.isDisabledAuthorisationError,
        name,
    },
});
