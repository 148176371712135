// @flow
import * as React from "react";
import {connect} from "react-redux";
import {withRouter, type RouterHistory} from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import {withStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {type StoreType} from "../../redux/reducers";
import {openCloseSideBar, closeSideBar} from "../../redux/actions/sideBarAction";
import {
    deleteAccountAction,
    type DeleteAccountActionType,
    accountClearAccountDetailAction,
    type AccountClearAccountDetailActionType,
} from "../../redux/actions/accountAction";
import {
    getMenuSettings,
    type GetMenuSettingsActionType,
    getMultipleLSPayVisibility,
    type GetMultipleLSPayVisibilityActionType,
} from "../../redux/actions/configAction";
import {styles} from "./SideBarStyle";
import SideBarAccounts from "./SideBarAccounts";
import SideBarZeroAccounts from "./SideBarZeroAccounts";
import SideBarHead from "./SideBarHead";

export type OwnProps = {|
    classes?: Object,
    wrapped?: boolean,
|};

type RouterProps = {|
    location: Object,
    history: RouterHistory,
|};

type StoreProps = {|
    auth: Object,
    accountsByAddress: Object,
    accountsList: Array<Object>,
    currentLs: string,
    lsDescriptionLength: number,
    lsNoticeStatusList: Object,
    profileBuilder: $PropertyType<$PropertyType<StoreType, "builder">, "profile">,
    session: string,
    sideBarIsOpen: boolean,
|};

type DispatchProps = {|
    clearAccountDetailAction: AccountClearAccountDetailActionType,
    deleteAccountAction: DeleteAccountActionType,
    getMenuSettings: GetMenuSettingsActionType,
    openCloseSideBar: () => void,
    closeSideBar: () => void,
    getMultipleLSPayVisibilityAction: GetMultipleLSPayVisibilityActionType,
|};

export type Props = {...OwnProps, ...RouterProps, ...StoreProps, ...DispatchProps};

class SideBar extends React.PureComponent<Props> {
    componentDidMount = () => {
        const root = document.getElementById("root");

        if (root) {
            root.addEventListener("scroll", this.handleScroll);
        }
    };

    componentWillUnmount = () => {
        const root = document.getElementById("root");

        if (root) {
            root.removeEventListener("scroll", this.handleScroll);
        }
    };

    handleScroll = () => {
        const root = document.getElementById("root");
        const sBar = document.getElementById("sideBar");

        if (sBar && root) {
            const {firstChild}: any = sBar;

            if (firstChild && firstChild.style) {
                // $FlowFixMe
                firstChild.style.top = `${root.scrollTop}px`;
            }
        }
    };

    render() {
        const {
            auth,
            accountsList,
            session,
            classes = {},
            accountsByAddress,
            wrapped = true,
            location,
            lsDescriptionLength,
            lsNoticeStatusList,
        } = this.props;
        const sidebar = (
            <Hidden smDown={!this.props.sideBarIsOpen}>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    id="sideBar"
                >
                    <SideBarHead handelCloseSideBar={this.props.closeSideBar} />
                    {accountsList.length === 0 ? <SideBarZeroAccounts /> : null}
                    <SideBarAccounts
                        auth={auth}
                        accountsByAddress={accountsByAddress}
                        currentLs={this.props.currentLs}
                        deleteAccountAction={this.props.deleteAccountAction}
                        session={session}
                        location={location}
                        history={this.props.history}
                        accountsList={accountsList}
                        lsNoticeStatusList={lsNoticeStatusList}
                        clearAccountDetailAction={this.props.clearAccountDetailAction}
                        handleCloseSideBar={this.props.closeSideBar}
                        getMenuSettings={this.props.getMenuSettings}
                        lsDescriptionLength={lsDescriptionLength}
                        getMultipleLSPayVisibilityAction={this.props.getMultipleLSPayVisibilityAction}
                    />
                </Drawer>
            </Hidden>
        );

        if (wrapped) {
            return (
                <React.Fragment>
                    <Hidden only={["xs", "sm", "md"]} implementation="css">
                        {sidebar}
                    </Hidden>
                    <Hidden only={["lg", "xl"]}>
                        <Modal open={this.props.sideBarIsOpen} onClose={this.props.openCloseSideBar}>
                            {sidebar}
                        </Modal>
                    </Hidden>
                </React.Fragment>
            );
        }

        return sidebar;
    }
}

export default withRouter<OwnProps>(
    connect<Props, {|...OwnProps, ...RouterProps|}, StoreProps, DispatchProps, StoreType, _>(
        ({account, auth, sideBar, builder, notification, config}: StoreType): StoreProps => ({
            accountsByAddress: account.accountsByAddress,
            accountsList: account.accountsList,
            auth,
            currentLs: account.accountDetail.nnLs,
            lsDescriptionLength: config.settings.lsDescriptionLength,
            lsNoticeStatusList: notification.lsNoticeStatusList,
            profileBuilder: builder.profile,
            session: auth.session || "",
            sideBarIsOpen: sideBar.sideBarIsOpen,
        }),
        (dispatch): DispatchProps => ({
            clearAccountDetailAction: () => dispatch(accountClearAccountDetailAction()),
            closeSideBar: () => dispatch(closeSideBar()),
            deleteAccountAction: (nnLs, session) => dispatch(deleteAccountAction(nnLs, session)),
            getMenuSettings: (session) => dispatch(getMenuSettings(session)),
            getMultipleLSPayVisibilityAction: (session) => dispatch(getMultipleLSPayVisibility(session)),
            openCloseSideBar: () => dispatch(openCloseSideBar()),
        }),
    )(withStyles(styles)(SideBar)),
);
