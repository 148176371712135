// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {DEFAULT_SIZE} from "../../../constants/iconConstants";
import {type IconPropsType} from "./iconType";

const UfaLogoIcon = ({fontSize, ...otherProps}: IconPropsType) => (
    <SvgIcon {...otherProps} style={{fontSize}}>
        <path
            fill="#F58F1F"
            d={
                "M-113.5,377.6c-12,12-12,31.4,0,43.4c9.3,9.3,23.1,11.3,34.4,6.2l-4-7.3l18.4,6.6 " +
                "C-64.7,426.5-113.5,377.6-113.5,377.6z"
            }
        />
        <path
            fill="#004780"
            d="M-70.1,421c12-12,12-31.4,0-43.4c-9.3-9.3-23.1-11.3-34.4-6.2l4,7.3l-18.4-6.6L-70.1,421z"
        />
        <path
            fill="#FFFFFF"
            d={
                "M-109.8,403.7h3.4c1.4,0,2.4-0.8,2.6-2c0.2-1.2-0.6-1.8-1.9-1.8h-3.4C-109.1,400-109.8,403." +
                "7-109.8,403.7z M-100.3,392.5l-0.4,2.3h-7.5l-0.5,2.8h3.7c2.7,0,4.6,1.6,4.1,4.3c-0.5,2.7-2.8,4.1-5.6," +
                "4.1h-6.6l2.4-13.5H-100.3z"
            }
        />
        <path
            fill="#FFFFFF"
            d={
                "M-89.3,397.7c-0.1-2.5-1.6-3.2-2.8-3.2c-2.5,0-3.2,1.6-3.6,2.4h-3c0.5-2,2.7-4.8,7-4.8 " +
                "c3.7,0,6.2,2.4,5.3,7.1c-0.8,4.6-4,7.1-7.8,7.1c-3.6,0-5.2-2-5.3-5h3c0.1,1.7,1.1,2.6,2.7,2.6c2.3,0," +
                "3.6-1.8,4.2-3.9h-4.1l0.4-2.3C-93.3,397.7-89.3,397.7-89.3,397.7z"
            }
        />
        <path
            fill="#FFFFFF"
            d={
                "M-74.7,396.9c-0.1-0.8-0.3-2.4-2.7-2.4c-1.4,0-3.8,1-4.4,4.8c-0.4,2.4,0.3,4.7,2.8,4.7c1.6,0,3-0.9,3." +
                "6-2.6h3c-1.1,2.9-3.5,5-7.1,5c-3.8,0-6.1-2.4-5.2-7.1c0.8-4.7,4.1-7.1,7.8-7.1c4.3,0,5.5,2.8,5.3,4.8C" +
                "-71.8,396.9-74.7,396.9-74.7,396.9z"
            }
        />
    </SvgIcon>
);

UfaLogoIcon.defaultProps = {
    fontSize: DEFAULT_SIZE,
    viewBox: "-122.5 368.7 61.3 61.1",
};

export default UfaLogoIcon;
