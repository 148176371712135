// @flow
import * as React from "react";
import {connect} from "react-redux";
import uniqBy from "lodash/uniqBy";
import {type StoreType} from "../../redux/reducers";
import {RESPONSE_RESULT_TEXT_CODE, NO_BUSINESS_ERR_CODE} from "../../constants/KdResults";
import {CODE_UNAUTORIZATION} from "../../constants/StatusCode";

import {systemErrorCloseMessageAction} from "../../redux/actions/systemErrorAction";
import SystemErrorDialog from "./SystemErrorDialog";

type OwnProps = {|
    children?: React.Node,
|};
type StoreProps = {|
    messages: $PropertyType<$PropertyType<StoreType, "systemError">, "messages">,
    remember?: boolean,
    config: $PropertyType<StoreType, "config">,
|};
type DispatchProps = {|
    handleClose: (code: number) => void,
|};

type Props = {...OwnProps, ...StoreProps, ...DispatchProps};

export const SystemError = (props: Props) => {
    const errors = uniqBy(props.messages, "code");
    const {remember} = props;
    const handleClose = (params: Object) => {
        if (params.isSudirLogout) {
            window.location = process.env.REACT_APP_SUDIR_LOGOUT_URL;
        } else {
            props.handleClose(params.code);
        }
    };

    return errors.map<React.Node>((message) => {
        const {code} = message;
        let sessionMessage = "";
        let title =
            (code === NO_BUSINESS_ERR_CODE || code === RESPONSE_RESULT_TEXT_CODE) && message.resSuccess
                ? "Сообщение"
                : "Предупреждение";

        if (code === CODE_UNAUTORIZATION) {
            if (remember) {
                return null;
            }
            title = "Сообщение";
            sessionMessage = {...message};
            sessionMessage.text = "Ваш сеанс истек. Необходимо повторно пройти процесс авторизации";
        }

        return (
            <SystemErrorDialog
                key={message.uuid}
                title={title}
                message={sessionMessage || message}
                onClose={handleClose}
            />
        );
    });
};

export default connect<Props, OwnProps, StoreProps, DispatchProps, StoreType, _>(
    ({auth, config, systemError}: StoreType): StoreProps => ({
        config,
        messages: systemError.messages,
        remember: auth.remember,
    }),
    (dispatch): DispatchProps => ({
        handleClose: (code: number) => dispatch(systemErrorCloseMessageAction(code)),
    }),
)(SystemError);
