// @flow
import * as React from "react";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Panel from "../../components/Panel/Panel";
import PanelHead from "../../components/Panel/PanelHead";
import PanelBody from "../../components/Panel/PanelBody";
import Typography from "../../components/Typography/Typography";
import DropDownMenu from "./DropDownMenu";

type Props = {
    config: Object,
    InputProps: Object,
    label: string,
    error: boolean,
    helperText: string,
    testID?: string,
    meta?: Object,
};

class TreeMenu extends React.Component<Props> {
    findObjectInDataByValue = (value: string): Object => {
        let result = {text: "", value: ""};
        const {distTreeData = []} = this.props.config;

        distTreeData.find(
            (item) =>
                item.data.find((innerItem) => {
                    if (innerItem.value.toString() === value.toString()) {
                        result = innerItem;

                        return true;
                    }

                    return false;
                }) !== undefined,
        );

        return result;
    };

    renderBody = ({handleClose}: Object) => {
        const {
            config: {distTreeData},
        } = this.props;

        return distTreeData.map((item, index) => (
            <Panel key={index}>
                <PanelHead headerBorder={"top"}>
                    <Typography fontSize={16} horizontalPadding={16} verticalPadding={16}>
                        {item.title}
                    </Typography>
                </PanelHead>
                <PanelBody key="panel-body">
                    <Divider />
                    <MenuList role="menu">
                        {item.data.map(({text, value: itemValue}) => (
                            <MenuItem onClick={() => handleClose({text, value: itemValue})} key={itemValue}>
                                {text}
                            </MenuItem>
                        ))}
                    </MenuList>
                </PanelBody>
            </Panel>
        ));
    };

    render() {
        const {
            InputProps: {value = ""},
        } = this.props;

        return (
            <DropDownMenu
                {...this.props}
                inputValue={this.findObjectInDataByValue(value).text}
                ComponentBody={this.renderBody}
            />
        );
    }
}

export default TreeMenu;
