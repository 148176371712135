/**
 * Мосэнергосбыт, МЭС
 */
export const MES_KD_PROVIDER = 1;

/**
 * МосОблЕИРЦ, МОЕ
 */
export const MOE_KD_PROVIDER = 2;

/**
 * Томскэнергосбыт
 */
export const TMK_NRG_KD_PROVIDER = 3;

/**
 * ТомскРТС
 */
export const TMK_RTS_KD_PROVIDER = 4;

/**
 * ЭСКБ
 */
export const UFA_KD_PROVIDER = 5;

/**
 * Мосэнергосбыт + ТКО
 */
export const TKO_KD_PROVIDER = 6;

/**
 * Вологда, Северная сбытовая компания (ССК)
 */
export const VLG_KD_PROVIDER = 7;

/**
 * Орловский энергосбыт
 */
export const ORL_KD_PROVIDER = 8;

/**
 * ЕПД Орел
 */
export const ORL_EPD_KD_PROVIDER = 9;

/**
 * Алтайэнергосбыт
 */
export const ALT_KD_PROVIDER = 10;

/**
 * Тамбовская энергосбытовая компания
 */
export const TMB_KD_PROVIDER = 11;

/**
 * Владимир, ЭСВ, Волга
 */
export const VLD_KD_PROVIDER = 12;

/**
 * Саратовэнерго
 */
export const SAR_KD_PROVIDER = 13;

/**
 * МОЭСК, Красногорск
 */
export const KSG_KD_PROVIDER = 14;
