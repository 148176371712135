// @flow
import * as React from "react";
import moment from "moment";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import MenuItem from "@material-ui/core/MenuItem";
import {parsePeriod, dayFormat, endOfDayFormat} from "../../../utils/formUtils";
import {selectAccountPerionAction, type SelectAccountPerionActionType} from "../../../redux/actions/accountAction";
import type {StoreType} from "../../../redux/reducers";
import Dropdown from "../../../packages/components/Dropdown/Dropdown";
import DateRangePicker from "../../../packages/components/DateRangePicker/DateRangePicker";
import TextField from "../../../packages/components/Form/TextField";

export type OwnProps = {|
    date?: string,
    customPeriodKey?: string,
    period?: Object,
    dateOptions?: Array<{
        label: string,
        value: string,
    }>,
    disableCustomDate?: boolean,
    savePeriod?: boolean,
    selectType?: "dropDown" | "textFieldSelect",
    periodName: string,
    provider?: string,
    testID?: string,
    label?: string,
|};

export type State = {
    isRangeOpen: boolean,
    [$key: string]: string,
    selectedPeriod: string,
};

export type StoreProps = {|
    periodKey: string,
    period: Object,
|};

export type DispatchProps = {|
    selectAccountPerionAction: SelectAccountPerionActionType,
|};

export type Props = {...OwnProps, ...StoreProps, ...DispatchProps};

const periodNames = {
    accountDetailPrintPeriod: true,
    notificationPeriod: true,
    periodYears: true,
};

export class AccountPeriod extends React.Component<Props, State> {
    static defaultProps = {
        selectType: "dropDown",
    };

    state = {
        isRangeOpen: false,
        notificationPeriod: "1m",
        period: "3m",
        periodYears: moment().format("YYYY"),
        selectedPeriod: "",
    };

    componentDidMount = () => {
        const {customPeriodKey, period, periodKey, periodName} = this.props;

        if (periodNames[periodName]) {
            this.setState(() => ({
                [periodName]: customPeriodKey,
            }));
        }

        this.setState(() => ({
            period: periodKey,
            selectedPeriod: `C ${moment(period.dtSt).format("DD.MM.YYYY")} по ${moment().format("DD.MM.YYYY")}`,
        }));
    };

    handleChangeTextFieldSelect = (event: SyntheticInputEvent<>) => {
        this.handleChange(event.target.value);
    };

    handleChangeDropDownSelect = (currentOption: Object) => {
        this.handleChange(currentOption.value);
    };

    handleChange = (selectedPeriod: string) => {
        if (selectedPeriod === "range") {
            this.handleOpenCloseRange();
        }

        this.setState({
            [this.props.periodName]: selectedPeriod,
        });

        if (selectedPeriod !== "range") {
            const {savePeriod, periodName, provider} = this.props;
            const parsedPeriod = parsePeriod(selectedPeriod);

            const {dtSt} = parsedPeriod.dtSt && parsedPeriod.dtEn ? parsedPeriod : {};

            this.setState({
                selectedPeriod: `C ${moment(dtSt).format("DD.MM.YYYY")} по ${moment().format("DD.MM.YYYY")}`,
            });

            this.props.selectAccountPerionAction(
                {
                    ...parsedPeriod,
                    key: selectedPeriod,
                    provider: provider || "",
                },
                savePeriod,
                periodName,
            );
        }
    };

    handleSubmitRange = (datesObject: Object) => {
        const dtEn = endOfDayFormat(datesObject.dtEn);
        const dtSt = dayFormat(datesObject.dtSt);

        const period = {
            dtEn,
            dtSt,
            key: "range",
            provider: this.props.provider ? this.props.provider : "",
        };

        this.setState({
            selectedPeriod: `C ${datesObject.dtSt} по ${datesObject.dtEn}`,
        });

        this.props.selectAccountPerionAction(period, this.props.savePeriod, this.props.periodName);
    };

    handleOpenCloseRange = () => {
        this.setState({
            isRangeOpen: !this.state.isRangeOpen,
        });
    };

    InputProps = {
        onChange: this.handleChangeTextFieldSelect,
    };

    render() {
        const {disableCustomDate, dateOptions, periodName, testID, label, selectType} = this.props;
        const {period, isRangeOpen} = this.state;
        const options = [
            {
                label: "За последние 3 месяца",
                value: "3m",
            },
            {
                label: "За последние 6 месяцев",
                value: "6m",
            },
            {
                label: "За год",
                value: "year",
            },
            {
                label: this.state.selectedPeriod || "Выбрать период",
                value: "range",
            },
        ];
        let selected = period;

        if (periodNames[periodName]) {
            selected = this.state[periodName];
        }

        if (selectType === "textFieldSelect") {
            return (
                <form autoComplete="on">
                    <TextField label={label} InputProps={this.InputProps} select value={selected}>
                        {options.map(({label: optionLabel, value}) => (
                            <MenuItem value={value} key={value}>
                                {optionLabel}
                            </MenuItem>
                        ))}
                    </TextField>
                    {disableCustomDate ? null : (
                        <DateRangePicker
                            key={this.state.period}
                            open={isRangeOpen}
                            onClose={this.handleOpenCloseRange}
                            onSubmit={this.handleSubmitRange}
                            initialValues={{...this.props.period}}
                        />
                    )}
                </form>
            );
        }

        return (
            <form autoComplete="on">
                <Dropdown
                    label={label}
                    selectedValue={selected}
                    options={dateOptions ? dateOptions : options}
                    name="period"
                    onChange={this.handleChangeDropDownSelect}
                    testID={testID}
                />
                {disableCustomDate ? null : (
                    <DateRangePicker
                        key={this.state.period}
                        open={isRangeOpen}
                        onClose={this.handleOpenCloseRange}
                        onSubmit={this.handleSubmitRange}
                        initialValues={{...this.props.period}}
                    />
                )}
            </form>
        );
    }
}

const mapStateToProps = ({account}: StoreType): StoreProps => ({
    period: account.accountDetail.period,
    periodKey: account.accountDetail.period.key,
});
const mapDispatchToProps = (dispatch): DispatchProps => bindActionCreators({selectAccountPerionAction}, dispatch);

export default connect<Props, OwnProps, StoreProps, DispatchProps, StoreType, _>(
    mapStateToProps,
    mapDispatchToProps,
)(AccountPeriod);
