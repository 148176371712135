// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "../Dialog/Dialog";
import DialogContent from "../Dialog/DialogContent";
import DialogTitle from "../Dialog/DialogTitle";
import Button from "../Button/Button";
import DialogActions from "../Dialog/DialogActions";
import DialogCloseIcon from "../Dialog/DialogCloseIcon";
import Typography from "../Typography/Typography";
import Loader from "../Loader/Loader";
import AccountPeriod from "../../../components/account/AccountPeriod/AccountPeriod";

const styles = (theme: any) => ({
    actionContent: {
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
        },
    },
    buttonContent: {
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    contentBottomPadding: {
        paddingBottom: 0,
    },
    "max-width-1200": {
        maxWidth: 1200,
    },
    "max-width-600": {
        maxWidth: 600,
    },
    "max-width-750": {
        maxWidth: 750,
    },
    "max-width-900": {
        maxWidth: 900,
    },
    "max-width-none": {
        maxWidth: "none",
    },
});

type Props = {
    title?: string,
    onConfirm?: Function,
    onCancelBtnClick?: () => void,
    onCancel: Function,
    open: boolean,
    children?: React.Node,
    errorDialog?: boolean,
    buttonCancelTitle?: string,
    buttonConfirmTitle?: string,
    buttonConfirmDisable?: boolean,
    sameButtons?: boolean,
    loaderFetching?: boolean,
    classes?: Object,
    accountPeriodSettings?: Object,
    withPeriod?: boolean,
    testID?: string,
    contentMinHeight?: 300,
    maxWidth?: "none" | 600 | 750 | 900 | 1200,
    noDialogAction?: boolean,
    contentBottomPadding?: boolean,
    withoutCancelBtn?: boolean,
    withoutCloseIcon?: boolean,
    withoutConfirmBtn?: boolean,
};

export const ModalConfirm = (props: Props) => {
    const {
        accountPeriodSettings,
        buttonCancelTitle,
        buttonConfirmDisable,
        buttonConfirmTitle,
        children,
        classes = {},
        contentBottomPadding = false,
        contentMinHeight,
        errorDialog,
        loaderFetching = false,
        maxWidth,
        noDialogAction,
        onCancel,
        onCancelBtnClick,
        onConfirm,
        open,
        sameButtons,
        testID,
        title,
        withPeriod,
        withoutCancelBtn,
        withoutCloseIcon,
        withoutConfirmBtn,
    } = props;

    const overrides = maxWidth ? {paperWidthSm: classes[`max-width-${maxWidth}`]} : {};

    const cancelBtns = sameButtons ? (
        <Button
            modalBtn
            onClick={onCancelBtnClick ? onCancelBtnClick : onCancel}
            color="secondary"
            testid="formNoButton"
        >
            {buttonCancelTitle}
        </Button>
    ) : (
        <Button
            zeroPadding
            zeroMinHeight
            onClick={onCancelBtnClick ? onCancelBtnClick : onCancel}
            link
            underline="dashed"
            capitalize
            testid="formNoButton"
        >
            {buttonCancelTitle}
        </Button>
    );

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={onCancel} classes={overrides} testID={testID}>
            {!withoutCloseIcon && <DialogCloseIcon onClick={onCancel} />}
            <DialogTitle disableTypography>
                <Grid container spacing={16} alignItems="center">
                    <Grid item>
                        {title ? (
                            <Typography textColor="primary" fontSize={22} lineHeight={1}>
                                {title}
                            </Typography>
                        ) : null}
                    </Grid>
                    {withPeriod ? (
                        <Grid item xs={12} sm={9} md={6}>
                            <AccountPeriod {...accountPeriodSettings} periodName="period" />
                        </Grid>
                    ) : null}
                    <Loader loaderType="periodLoader" fetching={loaderFetching} />
                </Grid>
            </DialogTitle>
            <DialogContent minHeight={contentMinHeight} contentBottomPadding={contentBottomPadding}>
                {children}
            </DialogContent>
            {noDialogAction ? null : (
                <DialogActions>
                    {errorDialog || withoutConfirmBtn ? null : (
                        <Button
                            modalBtn
                            onClick={onConfirm}
                            color="secondary"
                            testid="formYesButton"
                            disabled={buttonConfirmDisable}
                        >
                            {buttonConfirmTitle}
                        </Button>
                    )}
                    {withoutCancelBtn ? null : cancelBtns}
                </DialogActions>
            )}
        </Dialog>
    );
};

ModalConfirm.defaultProps = {
    buttonCancelTitle: "Отменить",
    buttonConfirmTitle: "Да",
    errorDialog: false,
    loaderFetching: false,
    noDialogAction: false,
    sameButtons: false,
};
export default withStyles(styles)(ModalConfirm);
