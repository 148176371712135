/* eslint-disable sort-keys */
// @flow
import {type themeTypes} from "../../../themeStyle";

const styles = (theme: themeTypes) => ({
    iconContainer: {
        height: "100%",
    },
    icon: {
        color: theme.palette.common.white,
        fontSize: "30px",
    },
    badge: {
        top: 14,
        width: 15,
        height: 15,
        right: 2,
        backgroundColor: theme.palette.topMenu.badge,
    },
    hideBage: {
        display: "none",
    },
    item: {
        height: "inherit",
    },
    iconBtn: {
        padding: "0 24px",
        height: "inherit",
        [theme.breakpoints.down("xs")]: {
            padding: "0 7px",
        },
    },
    popoverPaper: {
        overflow: "visible",
        maxHeight: 540,
        width: 740,
        [theme.breakpoints.down("sm")]: {
            width: 470,
        },
        [theme.breakpoints.down("xs")]: {
            minWidth: "100%",
            left: "0 !important",
            top: "0 !important",
            bottom: 0,
            maxHeight: "initial",
        },
        "&::before": {
            content: "''",
            top: -35,
            right: 22,
            position: "absolute",
            height: 0,
            width: 0,
            border: "18px solid transparent",
            borderBottomColor: theme.palette.common.white,
        },
    },
    closeIcon: {
        top: 20,
        fill: theme.palette.primary.light,
        right: 20,
        cursor: "pointer",
        position: "absolute",
    },
    popoverModal: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1200,
    },
    dialogContent: {
        height: 450,
        overflowX: "hidden",
        overflowY: "auto",
        paddingBottom: 0,
        [theme.breakpoints.down("xs")]: {
            height: "calc(100% - 60px)",
        },
    },
});

export {styles};
