// @flow

import React, {Fragment} from "react";
import DialogContent from "../../../packages/components/Dialog/DialogContent";
import DialogTitle from "../../../packages/components/Dialog/DialogTitle";
import WizardButtons, {type WizardButtonsProps} from "../../../packages/components/Wizard/WizardButtons";
import DialogActions from "../../../packages/components/Dialog/DialogActions";
import Typography from "../../../packages/components/Typography/Typography";

type Props = {
    email: string,
    buttonProps: WizardButtonsProps,
    onClose: () => void,
};

const ProfileEmailSend = ({email, buttonProps}: Props) => (
    <Fragment>
        <DialogTitle>
            <Typography fontSize={22}>Вам отправлено письмо!</Typography>
        </DialogTitle>
        <DialogContent>
            <Typography>
                Письмо со ссылкой подтверждения отправлено на почту <b>{email}</b>
            </Typography>
        </DialogContent>
        <DialogActions>
            <WizardButtons {...buttonProps} lastStepName="OK" showPrevButton={false} />
        </DialogActions>
    </Fragment>
);

export default ProfileEmailSend;
