// @flow
import React, {Component} from "react";
import injectSheet from "react-jss";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CircleText from "../../packages/components/CircleText/CircleText";
import Typography from "../../packages/components/Typography/Typography";
import {MAX_NOTICE_COUNT} from "../../constants/Notification";
import {accountIcons} from "../../constants/AccountIcons";
import SideBarAccountDescription from "./SideBarAccountDescription";

const styles = () => ({
    iconContent: {
        position: "relative",
    },
    linkDefault: {
        color: "rgba(0, 0, 0, 0.87)",
    },
    // eslint-disable-next-line sort-keys
    listItemContent: {
        paddingBottom: 0,
        paddingLeft: 16,
        paddingRight: 16,
    },
    primary: {
        color: "inherit",
    },
    rootConainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    secondary: {
        color: "inherit",
    },
});

export type Props = {
    auth: Object,
    account: Object,
    status: Object,
    lsDescriptionLength: number,
    session: string,
    classes?: Object,

    handleCloseSideBar: () => void,
};

class SideBarItem extends Component<Props> {
    render() {
        const {auth, account, classes = {}, status, lsDescriptionLength, session, handleCloseSideBar} = this.props;
        const Icon = accountIcons[account.kdServiceType] ? accountIcons[account.kdServiceType] : () => null;

        return (
            <div className={classes.rootConainer}>
                <ListItem component={"div"} className={classes.listItemContent} onClick={handleCloseSideBar}>
                    <div className={classes.iconContent}>
                        <Icon />
                        {status.cntNotice > 0 && !status.isCritical ? (
                            <CircleText
                                absolute
                                backgroundColor="orange"
                                textColor="white"
                                position="right-top"
                                fontSize={10}
                                size={18}
                            >
                                {status.cntNotice > MAX_NOTICE_COUNT ? "99+" : status.cntNotice}
                            </CircleText>
                        ) : null}
                        {status.isCritical ? (
                            <CircleText absolute backgroundColor="red" textColor="white" position="right-top">
                                !
                            </CircleText>
                        ) : null}
                    </div>
                    <ListItemText
                        classes={{
                            primary: classes.primary,
                            secondary: classes.secondary,
                        }}
                        primary={
                            <Typography
                                textColor="primary-light"
                                fontSize={18}
                                fontWeight={400}
                                testID={`sideBarAccountNumber-${account.nnLs}`}
                                underline
                                display="inline"
                            >
                                {account.nnLs}
                            </Typography>
                        }
                        secondary={
                            <Typography
                                textColor="grey-sideBar"
                                fontWeight={400}
                                testID={`sideBarAccountProvide-${account.nnLs}`}
                            >
                                {account.nmType}
                            </Typography>
                        }
                    />
                </ListItem>

                <SideBarAccountDescription
                    auth={auth}
                    lsDescriptionLength={lsDescriptionLength}
                    account={account}
                    session={session}
                />
            </div>
        );
    }
}

export default injectSheet(styles)(SideBarItem);
