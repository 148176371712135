// @flow
export const reachGoal = (name: string) => {
    const counterName = `yaCounter${process.env.REACT_APP_YACOUNTER || ""}`;
    const counter = window[counterName];

    if (counter) {
        counter.reachGoal(name, {
            ecommerce: "dataLayer",
            trackHash: true,
        });
    }
};
