// @flow
import * as React from "react";
import get from "lodash/get";
import cn from "classnames";
import {withStyles} from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import {Field} from "react-final-form";
import Checkbox from "@material-ui/core/Checkbox";
import {accepted} from "../../../validations/baseValidations";
import Typography from "../../../components/Typography/Typography";
import {convertToTemplateString} from "../../../../utils/stringUtils";

const styles = (theme: any) => ({
    error: {
        color: `${theme.palette.error.main}!important`,
    },
    label: {
        color: "#666666",
    },
    labelKvitonom: {
        color: theme.palette.green.wood,
        lineHeight: "1.05em",
    },
    rootKvitonom: {
        "&$checked": {
            color: `${theme.palette.green.wood}!important`,
        },
        color: `${theme.palette.green.wood}!important`,
    },
});

type Props = {
    name: string,
    required?: boolean,
    margin?: "none" | "dense" | "normal",
    content?: Array<{
        vlContent: string,
    }>,
    staticContent?: string,
    presetStyleExtra?: string,
    extraParams?: Object,
    onChange?: (event: SyntheticInputEvent<>) => void,
    onClick?: (event: SyntheticInputEvent<>) => void,
    testID: string,
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
};

export class CheckboxElement extends React.PureComponent<Props> {
    static defaultProps = {
        testID: "CheckboxElement",
    };

    renderLabel = (error: boolean) => {
        const {staticContent, classes = {}, content, presetStyleExtra, extraParams} = this.props;
        let vlContent = get(content, "0.vlContent");

        vlContent = convertToTemplateString(vlContent, extraParams);

        return staticContent ? (
            <Typography
                fontSize={14}
                className={cn(classes.label, {
                    [classes.error]: error,
                    [classes.labelKvitonom]: presetStyleExtra === "kvitonom",
                })}
                dangerouslySetInnerHTML={{__html: staticContent}}
            />
        ) : (
            <Typography
                fontSize={14}
                className={cn(classes.label, {
                    [classes.error]: error,
                    [classes.labelKvitonom]: presetStyleExtra === "kvitonom",
                })}
                dangerouslySetInnerHTML={{__html: vlContent}}
            />
        );
    };

    render() {
        const {
            name,
            required,
            margin,
            testID,
            onChange,
            onClick,
            presetStyleExtra = "default",
            classes = {},
        } = this.props;
        const rootClasses = {
            default: {},
            kvitonom: classes.rootKvitonom,
        };

        return (
            <Field
                name={name}
                type="checkbox"
                validate={required ? accepted : undefined}
                render={({input, meta}) => (
                    <FormControl error={meta.touched && meta.invalid} margin={margin}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={meta.touched && meta.invalid ? "primary" : "secondary"}
                                    margin={margin ? classes.error : ""}
                                    checked={input.checked}
                                    testID={testID}
                                    classes={{
                                        root: rootClasses[presetStyleExtra],
                                    }}
                                    inputProps={{
                                        ...input,
                                        onChange: (event) => {
                                            if (onChange) {
                                                onChange(event);
                                            }
                                            input.onChange(event);
                                        },
                                        onClick: (event) => {
                                            if (onClick) {
                                                onClick(event);
                                            }
                                        },
                                    }}
                                />
                            }
                            label={this.renderLabel(Boolean(meta.touched) && Boolean(meta.invalid))}
                        />
                    </FormControl>
                )}
            />
        );
    }
}

export default withStyles(styles)(CheckboxElement);
