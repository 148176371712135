/* eslint-disable max-lines */
// @flow
import filter from "lodash/filter";
import map from "lodash/map";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

export type VlDictType = {
    nmValue: string,
    nnCode: number,
};

export const getInitialServiceParams = (accountsList?: Array<Object>, servicesProviderList: Array<Object> = []) => {
    const currentKdProvider = (accountsList || []).find((lsItem) =>
        Boolean(servicesProviderList.find((serviseItem) => serviseItem.kdProvider === lsItem.kdProvider)),
    );

    if (currentKdProvider) {
        return {
            kdProvider: currentKdProvider.kdProvider,
            ls: currentKdProvider.nnLs,
        };
    }

    return null;
};

type FilterConfigParamsType = {
    ls: Object,
    lsList: Array<Object>,
    provider?: string,
    providerList: Array<Object>,
    regionList: Array<Object>,
    serviceGroupsList: Array<Object>,
};

export const PHONE_CONFIG = {
    kdAttribute: 8,
    kdEntity: 1,
    kdRegexpError: 139,
    nmAttrDataType: "VARCHAR",
    nmAttrType: "BASE",
    nmAttribute: "Мобильный телефон",
    nmColumn: "nnPhone",
    nmDomain: "PHONE_DOMAIN",
    nmEntity: "Учетная запись",
    nmInputMask: "+7(999)999-99-99",
    nmRegexp: "^[7]{1}[9]{1}[0-9]{9}$",
    nmRegexpError: "Некорректный номер телефона",
    nmTable: "LKA.LKA_PROFILE",
    nnFieldSize: 16,
    nnOrder: 1,
    prBase: true,
    prRequired: true,
    prVisible: true,
};

const EMAIL_CONFIG = {
    kdAttribute: 4,
    kdEntity: 1,
    kdRegexpError: 140,
    nmAttrDataType: "VARCHAR",
    nmAttrType: "BASE",
    nmAttribute: "E-mail",
    nmColumn: "nmEmail",
    nmDomain: "EMAIL_DOMAIN",
    nmEntity: "Учетная запись",
    nmRegexp: "^[A-Za-z0-9\\-_]+(\\.[A-Za-z0-9\\-_]+)*@([A-Za-z0-9]([A-Za-z0-9\\-_]*[A-Za-z0-9])?\\.)+?[A-Za-z]{2,6}$",
    nmRegexpError: "Некорректный email",
    nmTable: "LKA.LKA_PROFILE",
    nnOrder: 2,
    pBbase: true,
    prRequired: true,
    prVisible: true,
};

const LAST_CONFIG = {
    kdAttribute: 5,
    kdEntity: 1,
    nmAttrDataType: "VARCHAR",
    nmAttrType: "BASE",
    nmAttribute: "Фамилия",
    nmColumn: "nmLast",
    nmEntity: "Учетная запись",
    nmTable: "LKA.LKA_PROFILE",
    nnOrder: 3,
    prBase: true,
    prRequired: true,
    prVisible: true,
};

const FIRST_CONFIG = {
    kdAttribute: 5,
    kdEntity: 1,
    nmAttrDataType: "VARCHAR",
    nmAttrType: "BASE",
    nmAttribute: "Имя",
    nmColumn: "nmFirst",
    nmEntity: "Учетная запись",
    nmTable: "LKA.LKA_PROFILE",
    nnOrder: 4,
    prBase: true,
    prRequired: true,
    prVisible: true,
};

const MIDDLE_CONFIG = {
    kdAttribute: 5,
    kdEntity: 1,
    nmAttrDataType: "VARCHAR",
    nmAttrType: "BASE",
    nmAttribute: "Отчество",
    nmColumn: "nmMiddle",
    nmEntity: "Учетная запись",
    nmTable: "LKA.LKA_PROFILE",
    nnOrder: 5,
    prBase: true,
    prRequired: false,
    prVisible: true,
};

const getFiltersLists = (providerList: Array<Object>, regionList: Array<Object>, serviceGroupsList: Array<Object>) => {
    const emptyValue: VlDictType = {nmValue: "Все", nnCode: 0};

    const provList = providerList.map<VlDictType>((provider) => ({
        nmValue: provider.nmProvider,
        nnCode: provider.kdProvider,
    }));
    const regList = regionList.map<VlDictType>((region) => ({nmValue: region.nmRegion, nnCode: region.kdRegion}));
    const servList = serviceGroupsList.map<VlDictType>((serviceGroup) => ({
        nmValue: serviceGroup.nmPaidServiceGroup,
        nnCode: serviceGroup.kdPaidServiceGroup,
    }));

    return {
        provList,
        regList,
        servList: [emptyValue, ...servList],
    };
};

export const buildFilterConfig = ({
    ls = {},
    lsList = [],
    providerList,
    regionList,
    serviceGroupsList,
}: FilterConfigParamsType) => {
    const {provList, regList, servList} = getFiltersLists(providerList, regionList, serviceGroupsList);
    const selectedLsList = ls.kdProvider ? filter(lsList, {kdProvider: ls.kdProvider}) : lsList;
    const displayListLs = selectedLsList.map<VlDictType>((singleLs) => ({
        nmValue: singleLs.nnLs,
        nnCode: singleLs.nnLs,
    }));

    return [
        {
            kdAttribute: 1,
            nmAttrType: "DICT",
            nmAttribute: "Поставщик",
            nmColumn: "PROVIDER",
            nmTable: "catalogForm",
            prBase: false,
            prRequired: false,
            prVisible: true,
            vlDict: provList,
        },
        {
            kdAttribute: 1,
            nmAttrType: "DICT",
            nmAttribute: "Лицевой счет",
            nmColumn: "LS",
            nmEntity: "Учетная запись",
            nmTable: "catalogForm",
            prBase: false,
            prRequired: true,
            prVisible: true,
            vlDict: displayListLs,
        },
        {
            kdAttribute: 1,
            nmAttrType: "DICT",
            nmAttribute: "Регион",
            nmColumn: "REGION",
            nmTable: "catalogForm",
            prBase: false,
            prRequired: false,
            prVisible: true,
            vlDict: regList,
        },
        {
            kdAttribute: 1,
            nmAttrType: "DICT",
            nmAttribute: "Вид услуги",
            nmColumn: "SERVICE_TYPE",
            nmTable: "catalogForm",
            prBase: false,
            prRequired: false,
            prVisible: true,
            vlDict: servList,
        },
    ];
};

export const buildOrderFormConfig = (types?: Array<Object>, ls?: string) => {
    const paymentTypes =
        types && types.length > 0
            ? types.map<VlDictType>((type) => ({
                  nmValue: type.nmPaymentType,
                  nnCode: type.kdPaymentType,
              }))
            : [];

    return [
        PHONE_CONFIG,
        EMAIL_CONFIG,
        LAST_CONFIG,
        FIRST_CONFIG,
        MIDDLE_CONFIG,
        {
            disabled: Boolean(ls),
            kdAttribute: 5,
            kdEntity: 1,
            nmAttrDataType: "VARCHAR",
            nmAttrType: "BASE",
            nmAttribute: "Адрес оказания услуг",
            nmColumn: "nmAddress",
            nmEntity: "Учетная запись",
            nmTable: "LKA.LKA_PROFILE",
            nnOrder: 6,
            prBase: true,
            prRequired: false,
            prVisible: true,
        },
        {
            kdAttribute: 1,
            nmAttrType: "DICT",
            nmAttribute: "Способ оплаты",
            nmColumn: "paymentType",
            nmEntity: "Учетная запись",
            prBase: false,
            prRequired: true,
            prVisible: true,
            vlDict: paymentTypes,
        },
    ];
};

export const transformVlPaidServiceListByData = (list: Array<Object>) =>
    groupBy<string, Object>(
        map(sortBy(list, "dtRequest").reverse(), ({dtRequest, ...rest}) => ({
            ...rest,
            dtRequest: dtRequest.split(" ", 1)[0],
        })),
        "dtRequest",
    );

export const getConfigs = (attributes: Array<Object>) => {
    const result = attributes.map(({nmColumnName, nmFormatRegexp, nmInputMask, nmAttrType, ...rest}) => {
        let config = {};

        switch (nmColumnName) {
            case "NM_FEEDBACK_PHONE":
                config = {
                    ...rest,
                    nmAttrType: "BASE",
                    nmColumn: nmColumnName,
                    nmDomain: "PHONE_DOMAIN",
                    nmInputMask: "+7(999) 999-99-99",
                    nmRegexp: "^[7]{1}[9]{1}[0-9]{9}$",
                    nmTable: "LKA.LKA_ORDER_SERVICE_FORM",
                    prVisible: Boolean(nmColumnName),
                };

                break;
            case "NM_FEEDBACK_EMAIL":
                config = {
                    ...rest,
                    nmAttrType,
                    nmColumn: nmColumnName,
                    nmDomain: "EMAIL_DOMAIN",
                    nmInputMask,
                    nmRegexp: null,
                    nmTable: "LKA.LKA_ORDER_SERVICE_FORM",
                    prVisible: Boolean(nmColumnName),
                };

                break;
            default:
                config = {
                    ...rest,
                    nmAttrType,
                    nmColumn: nmColumnName,
                    nmDomain: "",
                    nmInputMask,
                    nmRegexp: nmFormatRegexp,
                    nmTable: "LKA.LKA_ORDER_SERVICE_FORM",
                    prVisible: Boolean(nmColumnName),
                };

                break;
        }

        return config;
    });

    return [...result];
};

export const getInitialValues = (
    values: Array<Object>,
    initialValues: Array<{nmCrmAttr: string, vlAttribute: string}>,
) => {
    const returnValues = {};
    const normalizedInit = initialValues.reduce(
        (result, value) => ({...result, [value.nmCrmAttr]: value.vlAttribute}),
        {},
    );

    values.forEach(({prDict, prMultipleVals, nmColumnName, nmColumn = nmColumnName}) => {
        if (!prDict && prMultipleVals) {
            // Fix бага с мультиполями, при отсутствии значения по умолчанию необходимо оставлять [""]
            if (normalizedInit[nmColumn]) {
                returnValues[nmColumn] = [normalizedInit[nmColumn]];
            } else {
                returnValues[nmColumn] = [""];
            }
        }
        if (!prMultipleVals && normalizedInit[nmColumn]) {
            returnValues[nmColumn] = normalizedInit[nmColumn];
        }
    });

    return returnValues;
};
