// @flow

import React from "react";
import qs from "qs";
import type {Location, RouterHistory} from "react-router-dom";

type Props = {
    history: RouterHistory,
    location: Location,
};
export const OpenUrlPage = (props: Props) => {
    React.useEffect(() => {
        const {search} = props.location;
        const {mode} = qs.parse(search, {
            ignoreQueryPrefix: true,
        });
        const url = process.env[mode];

        if (url) {
            window.location.replace(url);
        } else {
            props.history.push("/auth");
        }
    }, []);

    return <React.Fragment />;
};
