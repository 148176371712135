// @flow
import * as actionTypes from "../../constants/ActionTypes";
import {createReducer} from "../../../../redux/reducers/createReducer";
import {baseDataActionSuccess, type BaseDataReducerType} from "./baseReducers";

const initialState: BaseDataReducerType = {
    modalTableData: [],
    panelHeadData: [],
    panelRowsData: [],
    panelTabsData: [],
    statisticsData: [],
};

export const dataReducer = createReducer(initialState, {
    [actionTypes.EXAMPLE_DATA_ACTION_SUCCESS]: baseDataActionSuccess,
    [actionTypes.CONVERT_CHART_DATA_ACTION]: (state) => ({
        ...state,
    }),
});
