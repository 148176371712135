import {applyMiddleware, compose, createStore} from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import {history} from "../history";
import {apiMiddleware} from "./middlewares/apiMiddleware";
import {systemErrorMiddleware} from "./middlewares/systemErrorMiddleware";
import {historyMiddleware} from "./middlewares/historyMiddleware";
import rootReducer from "./reducers";

function configureStoreProd(initialState) {
    const middlewares = [
        apiMiddleware,
        systemErrorMiddleware,
        historyMiddleware,
        // Add other middleware on this line...

        /*
         * Thunk middleware can also accept an extra argument to be passed to each thunk action
         * https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
         */
        thunk,
    ];

    return createStore(rootReducer(history), initialState, compose(applyMiddleware(...middlewares)));
}

function configureStoreDev(initialState) {
    const middlewares = [
        apiMiddleware,
        systemErrorMiddleware,
        historyMiddleware,
        // Add other middleware on this line...

        /*
         * Redux middleware that spits an error on you when you try
         * to mutate your state either inside a dispatch or between dispatches.
         */
        reduxImmutableStateInvariant(),

        /*
         * Thunk middleware can also accept an extra argument to be passed to each thunk action
         * https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
         */
        thunk,
    ];

    // Add support for Redux dev tools
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(rootReducer(history), initialState, composeEnhancers(applyMiddleware(...middlewares)));

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept("./reducers", () => {
            // eslint-disable-next-line global-require
            const nextReducer = require("./reducers").default;

            store.replaceReducer(nextReducer);
        });
    }

    return store;
}

const configureStore = process.env.NODE_ENV === "production" ? configureStoreProd : configureStoreDev;

export default configureStore;
