// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {DEFAULT_SIZE} from "../../../constants/iconConstants";
import {type IconPropsType} from "./iconType";

export const NotificationReadIcon = ({fontSize, ...otherProps}: IconPropsType) => (
    <SvgIcon {...otherProps} style={{fontSize}}>
        <g>
            <path
                fill="#6F8AB6"
                d={
                    "M515.6,1088.6c0.5-0.2,0.7-0.6,0.6-1.1c5.7,0.1,11.3,0.1,17,0.1c1.9,0," +
                    "3.8-0.1,5.5,1C530.9,1088.6,523.2,1088.6,515.6,1088.6z"
                }
            />
            <path
                fill="#6F8AB6"
                d={
                    "M586,1087.5c-0.2,0.6,0,0.9,0.6,1.1c-7.4,0-14.7,0-22.1,0c1.7-1,3." +
                    "6-0.9,5.5-1C575.3,1087.6,580.7,1087.6,586,1087.5z"
                }
            />
            <path
                fill="#6F8AB6"
                d={
                    "M551.1,800.5c-107.3,0-214.7,0-322,0c-1.9,0-3.8,0-5.8,0c-9.9-0.4-18.5-" +
                    "7.3-20.7-17c-0.5-2-0.6-4.1-0.8-6.2c-0.1-1.1,0-2.2,0-3.4c0-121.1,0-242.2,0-363.3c0-" +
                    "2.9,0-5.7,0.7-8.6c1.7-6.4,5.3-11.2,11.1-14.4c42-22.9,84-45.7,126-68.6c66.8-36.5,133." +
                    "6-72.9,200.5-109.4c1.3-0.7,2.5-1.4,3.8-2c7.1-3.5,14.2-3.5,21.2,0.1c5.1,2.7,10.1,5.5,15." +
                    "2,8.2c99.8,54.5,199.6,109,299.4,163.4c4.7,2.6,9.1,5.3,12.1,9.9c0.5,0.8,1.3,1.4,2,2.1c4.5" +
                    ",4.6,6.6,10.1,6.7,16.4c0,1.1,0,2.2,0,3.4c0,121.1,0,242.2,0,363.3c0,3.2,0,6.4-0.8,9.5c-2.6," +
                    "9.4-10.9,16.1-20.7,16.4c-1.9,0.1-3.8,0-5.8,0C765.8,800.5,658.4,800.5,551.1,800.5z M855.1,440" +
                    ".2c-2,0.2-3.3,1.2-4.7,2c-89.6,49.3-179.3,98.7-269,148.1c-9.9,5.5-19.9,10.9-29.8,16.4c-3.7,2." +
                    "1-7.6,3.2-11.9,3.2c-4.7,0-8.7-1.5-12.7-3.7C435.5,555.8,344,505.4,252.5,455c-1.3-0.7-2.5-1." +
                    "4-3.8-2c-1.2-0.6-1.9-0.2-2,1c-0.1,1.3-0.1,2.6-0.1,3.8c0,97.3,0,194.5,0,291.8c0,1.3,0,2.6,0.1,3" +
                    ".8c0.1,1.7,0.5,2.2,2.4,2.2c2.1,0.1,4.2,0.1,6.2,0.1c197.1,0,394.2,0,591.3,0c1.1,0,2.2,0,3.4,0c6," +
                    "0,5.7,0.3,5.7-5.8c0-101.6,0-203.2,0-304.8C855.7,443.6,855.9,442,855.1,440.2z M794.6,384.4c-" +
                    "2.4-1.4-3.9-2.2-5.4-3.1c-76.7-41.9-153.3-83.7-230-125.5c-5.7-3.1-4-3.2-9.9,0.1c-68.4,37.3-136." +
                    "8,74.6-205.1,112c-9.1,5-18.2,10-27.4,15c-0.7,0.4-1.9,0.6-1.5,2.2c159,0,318,0,477,0C792.8,385," +
                    "793.2,384.8,794.6,384.4z"
                }
            />
        </g>
        <circle
            fill="none"
            stroke="#6F8AB6"
            strokeWidth="63.4471"
            strokeMiterlimit="10"
            cx="551.1"
            cy="554.9"
            r="518"
        />
    </SvgIcon>
);

NotificationReadIcon.defaultProps = {
    fontSize: DEFAULT_SIZE,
    viewBox: "0 0 1109.9 1108.4",
};

export default NotificationReadIcon;
