// @flow
/* eslint complexity: ["error", 27] */
// eslint-disable-next-line import/named
import {type DispatchAPI, type MiddlewareAPI} from "redux";
import lodash from "lodash";
import axios from "axios";
import qs from "qs";
import hash from "object-hash";
import lru from "lru-cache";
import {camelCaseKeys, snakeCaseKeys} from "../../utils/objectUtils";
import {RESPONSE_RESULT_TEXT_CODE, NO_BUSINESS_ERR_CODE} from "../../constants/KdResults";
import {cacheQueryList} from "../../constants/types/CacheTypes";
import {mocksRequest} from "../../utils/middlewareUtils";
import {cacheQueryGetData, cacheQuerySetData} from "../../utils/cacheQueryUtils";
import {type StoreType} from "../reducers";

export type ApiMiddlewareType = {|
    formData?: FormData,
    action?: string,
    cache?: boolean,
    endpoint?: string,
    method?: string,
    query: string,
    data?: Object,
    reduce?: boolean,
    session?: string,
    list?: boolean,
    types: [string, string, string],
    noSnakeCaseTransform?: boolean,
    options?: Object,
|};
export type ApiMiddlewareActionType = {|
    payload?: Object,
    api?: ApiMiddlewareType,
    error?: boolean,
|};

export type ApiMiddlewareResponseType = {|
    type: string,
    networkError: boolean,
    error: boolean,
    payload?: any,
    resSuccess: boolean,
    response?: any,
    canceled?: boolean,
    message?: string,
|};
export type ApiMiddlewareResponsePromiseType = Promise<ApiMiddlewareResponseType>;

const defaultEndpoint = process.env.REACT_APP_GATE_LKCOMU || "";

/* eslint-disable no-magic-numbers */
const maxAge = 1000 * 60 * 15;
const cacheOptions = {
    max: 50,
    maxAge,
};

/* eslint-enable no-magic-numbers */
export const apiCache = lru(cacheOptions);

const getAxiosParams = ({
    endpoint = defaultEndpoint,
    method = "POST",
    data,
    noSnakeCaseTransform,
    formData,
    action,
    urlParams,
    options,
}: Object) => {
    const basicParams = {
        data: qs.stringify(noSnakeCaseTransform ? data : snakeCaseKeys(data)),
        headers: {
            "Content-type": "application/x-www-form-urlencoded",
        },
        method,
        url: `${endpoint}?${qs.stringify(urlParams)}`,
    };
    const urlencodedAxiosParams =
        action.payload && action.payload.cancelToken
            ? {...basicParams, cancelToken: action.payload.cancelToken}
            : basicParams;

    const formDataAxioxParams = {
        data: formData ? formData : new FormData(),
        headers: {
            "Content-type": "multipart/form-data",
        },
        method,
        onUploadProgress: options?.onUploadProgress,
        url: `${endpoint}?${qs.stringify(snakeCaseKeys(urlParams))}`,
    };

    return formData ? formDataAxioxParams : urlencodedAxiosParams;
};

export const apiMiddleware = (store: MiddlewareAPI<StoreType, any>) => (
    next: DispatchAPI<ApiMiddlewareActionType | ApiMiddlewareResponseType>,
    // eslint-disable-next-line complexity
) => async (action: ApiMiddlewareActionType) => {
    if (!action.api) {
        return next(action);
    }

    const {
        action: actionRequest = "sql",
        cache,
        query,
        endpoint = defaultEndpoint,
        method = "POST",
        types,
        data,
        list,
        noSnakeCaseTransform,
        reduce,
        session,
        formData,
        options,
    } = action.api;
    const [requestType, successType, faulerType] = types;
    const urlParams = formData
        ? {
              query,
              session,
              ...data,
          }
        : {
              action: actionRequest,
              query,
              session,
          };
    const key = cache ? hash(action.api) : "";
    const kdSection = data && data.kdSection ? `${data.kdSection}` : "";

    const loadDataCache = data && cacheQueryList.indexOf(data.proxyquery) !== -1;
    const cacheToLocalStorage =
        (!action.payload || !action.payload.noCache) && (cacheQueryList.indexOf(query) !== -1 || loadDataCache);

    if (cacheToLocalStorage) {
        const params = {
            customCaching: loadDataCache,
            data,
            kdSection,
            payload: action.payload,
            queryName: query,
            session,
            store,
        };
        const result = await cacheQueryGetData(params);

        if (result) {
            return next({
                error: false,
                networkError: false,
                payload: action.payload,
                resSuccess: true,
                response: result,
                type: successType,
            });
        }
    }

    if (process.env.REACT_APP_PROVIDER_EXAMPLE === "on") {
        if (query === "exampleProxy") {
            return mocksRequest({action, next, requestType, successType});
        }
    }

    if (cache && apiCache.has(key)) {
        if (reduce) {
            return next({
                error: false,
                networkError: false,
                payload: action.payload,
                resSuccess: true,
                response: apiCache.get(key),
                type: successType,
            });
        }

        return null;
    }

    // eslint-disable-next-line callback-return
    next({
        error: false,
        networkError: false,
        payload: action.payload,
        resSuccess: true,
        type: requestType,
    });

    try {
        const response = await axios(
            getAxiosParams({
                action,
                data,
                endpoint,
                formData,
                method,
                noSnakeCaseTransform,
                options,
                urlParams,
            }),
        );
        const responsRawData = response.data.data;
        const resSuccess = response.data.success;
        const isArray = list && resSuccess;
        let responseData: Object | Array<any> = {};

        if (resSuccess) {
            const isEmptyArray = responsRawData && isArray && lodash.isEmpty(responsRawData[0]);

            responseData = isEmptyArray ? [] : camelCaseKeys(isArray ? responsRawData : responsRawData[0] || {});
        } else {
            responseData = camelCaseKeys(response.data);
        }

        const kdResult = isArray || Array.isArray(responseData) ? 0 : responseData.kdResult || 0;

        const error = !resSuccess || !(kdResult === NO_BUSINESS_ERR_CODE || kdResult === RESPONSE_RESULT_TEXT_CODE);

        if (cache) {
            apiCache.set(key, responseData, maxAge);
        }

        if (cacheToLocalStorage && !error) {
            cacheQuerySetData({
                customCaching: loadDataCache,
                data,
                kdSection,
                queryName: query,
                responseData,
                session,
                store,
            });
        }

        return next({
            error,
            networkError: false,
            payload: action.payload,
            resSuccess,
            response: responseData,
            type: error ? faulerType : successType,
        });
    } catch (error) {
        return next({
            canceled: axios.isCancel(error),
            error: true,
            message: error.message,
            networkError: true,
            payload: action.payload,
            resSuccess: false,
            response: error.response || {},
            type: faulerType,
        });
    }
};
