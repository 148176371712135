// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {DEFAULT_SIZE} from "../../../constants/iconConstants";
import {type IconPropsType} from "./iconType";

export const NotificationUnreadIcon = ({fontSize, ...otherProps}: IconPropsType) => (
    <SvgIcon {...otherProps} style={{fontSize}}>
        <circle fill="#6F8AB6" cx="518" cy="518" r="518" />
        <path
            fill="#FEFEFE"
            d={
                "M518.4,323c100,0,200,0,299.9,0c2.9,0,5.7,0,8.6,0.8c8.7,2.4,14.8,9.7,15.5,18.7c0.1,1.6" +
                ",0.1,3.2,0.1,4.8c0,116.6,0,233.2,0,349.9c0,1.3,0,2.6-0.1,3.8c-0.6,11.1-8.9,19.5-20," +
                "20.2c-1.3,0.1-2.6,0-3.8,0c-200.3,0-400.6,0-600.9,0c-1.8,0-3.5,0.1-5.3-0.1c-10.7-1.3-18." +
                "2-9.4-18.9-20.3c-0.1-2.1-0.1-4.2-0.1-6.2c0-112,0-224,0-335.9c0-4,0-8-0.1-12c0-2.2," +
                "0.1-4.5,0.6-6.7c2-9.7,10.2-16.5,20.1-16.8c1.8-0.1,3.5,0,5.3,0C319.1,322.9,418.8,322.9" +
                ",518.4,323C518.4,322.9,518.4,322.9,518.4,323z M517.9,679c91.8,0,183.7,0,275.5,0c7.4," +
                "0,6.9,0.8,6.9-7c0-96.8,0-193.6,0-290.4c0-7.3,0.3-7.1-6-3.5c-83,47.3-165.9,94.5-248.9," +
                "141.8c-8.9,5.1-17.8,10.1-26.7,15.2c-3.5,2-7.3,3.2-11.4,3.1c-4.5,0-8.4-1.6-12.2-3.8c-27." +
                "9-15.9-55.9-31.8-83.8-47.7c-56.8-32.4-113.7-64.8-170.5-97.1c-5.1-2.9-5.1-2.9-5.1,3.1c0" +
                ",93.6,0,187.2,0,280.8c0,1.3,0,2.6,0.1,3.8c0.1,1.1,0.8,1.7,1.9,1.8c1.8,0.1,3.5,0.1,5.3,0" +
                ".1C334.6,679,426.3,679,517.9,679zM285.5,365.8c1.4,1.7,3.3,2.4,5.1,3.3c70.6,40.2,141.2,80" +
                ".5,211.8,120.7c6.1,3.5,4.3,3.4,10.2,0c61.2-34.8,122.3-69.7,183.5-104.5c10.6-6,21.1-12," +
                "31.7-18.1c0.8-0.4,1.9-0.6,1.6-2.1c-147.6,0-295.3,0-442.9,0C286.2,365.2,286,365.5,285.5,365.8z"
            }
        />
    </SvgIcon>
);

NotificationUnreadIcon.defaultProps = {
    fontSize: DEFAULT_SIZE,
    viewBox: "0 0 1036 1036",
};

export default NotificationUnreadIcon;
