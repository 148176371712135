// @flow
import * as React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import {Field} from "react-final-form";
import Checkbox from "@material-ui/core/Checkbox";
import Content from "../../components/Content/Content";
import DropDownMenu from "./DropDownMenu";

type Props = {
    config: Object,
    InputProps: Object,
    label: string,
    error: boolean,
    helperText: string,
    testID?: string,
};

class CheckBoxMenu extends React.Component<Props> {
    getValueForUser = (valueArray: Array<string>) => {
        if (!valueArray || (valueArray && valueArray.length === 0)) {
            return "";
        }
        const {
            config: {vlDict},
        } = this.props;

        return valueArray
            .filter(Boolean)
            .map((value) => vlDict.find(({kdDict}) => kdDict === value).nmDict)
            .join(", ");
    };

    renderBody = () => {
        const {
            config: {vlDict, nmColumn},
        } = this.props;

        return (
            <Content horizontal="xs">
                {vlDict.map(({kdDict, nmDict}, index) => (
                    <Field
                        key={kdDict}
                        name={`${nmColumn}[${index}]`}
                        render={({input, meta}) => (
                            <FormControl error={meta.touched && meta.invalid} margin="dense" fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(input.value)}
                                            inputProps={{
                                                ...input,
                                                onChange: () => {
                                                    if (input.value) {
                                                        return input.onChange("");
                                                    }

                                                    return input.onChange(kdDict);
                                                },
                                            }}
                                        />
                                    }
                                    label={nmDict}
                                />
                            </FormControl>
                        )}
                    />
                ))}
            </Content>
        );
    };

    render() {
        const {
            InputProps: {value = []},
        } = this.props;

        return (
            <DropDownMenu {...this.props} inputValue={this.getValueForUser(value)} ComponentBody={this.renderBody} />
        );
    }
}

export default CheckBoxMenu;
