// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import uniqueId from "lodash/uniqueId";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {Typography} from "../../components/Typography/Typography";
import {type UploadFileActionType, type DeleteFileActionType} from "../../../redux/actions/uploadAction";
import Loader from "../../components/Loader/Loader";
import {ModalConfirm} from "../../components/Modal/ModalConfirm";
import {checkFileExtension} from "../../components/UploadFile/helpers";

const styles = (theme: any) => ({
    customInputFile: {
        height: 0.1,
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        width: 0.1,
        zIndex: -1,
    },
    innerUploadContent: {
        marginTop: 35,
    },
    inputLabelClass: {
        color: theme.palette.primary.light,
        cursor: "pointer",
        fontSize: 14,
        textDecoration: "underline",
    },
    rootIcon: {
        cursor: "pointer",
        fill: theme.palette.primary.light,
        height: 20,
        right: theme.spacing.unit / 2,
        top: theme.spacing.unit + theme.spacing.unit / 2,
        width: 20,
        zIndex: theme.zIndex.maxZIndex,
    },
    textLabel: {
        left: 0,
        position: "absolute",
        top: -5,
    },
    wrapContent: {
        paddingBottom: 8,
        paddingTop: 8,
        position: "relative",
    },
});

type Props = {
    session: string,
    validatorError: string,
    touchedGlobField: boolean,
    uploadFileAction: UploadFileActionType,
    deleteFileAction: DeleteFileActionType,
    uploadButtonName?: string,
    idDocType?: number,
    prRequired?: boolean,
    vlCount: number,
    nmVdDoc: string,
    disabled?: boolean,
    margin?: "none" | "dense" | "normal",
    nmColumn: string,
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    addFile: (data: Array<any>) => void,
    deleteFile: (idDoc: number) => void,
    values: Array<Object>,
    maxFilesCounter?: number,
    maxFileSize: number,
    fileExtensions?: Array<string>,
};

type State = {
    counter: number,
    error: boolean,
    errorMessage: string,
    fetching: number,
    touched: boolean,
    id: string,
    uploadedFilesCounter: number,
};

export class UploadFile extends React.PureComponent<Props, State> {
    inputInstance: HTMLInputElement | null;

    state = {
        counter: 0,
        error: false,
        errorMessage: "",
        fetching: 0,
        id: uniqueId("file_"),
        touched: false,
        uploadedFilesCounter: 0,
    };

    static defaultProps = {
        maxFileSize: 10000000000,
        nmVdDoc: "",
        values: [],
        vlCount: 0,
    };

    isError = () => {
        const {validatorError, touchedGlobField} = this.props;

        return Boolean(validatorError) && (touchedGlobField || this.state.touched);
    };

    onChangeFile = (event: any) => {
        const {session, idDocType, values, maxFilesCounter, maxFileSize, fileExtensions} = this.props;
        const {files} = event.target;
        const loadingFilesCounter = files.length;
        const loadedFilesCounter = values.length;

        this.setState({counter: loadingFilesCounter + loadedFilesCounter});

        if (loadingFilesCounter + loadedFilesCounter > maxFilesCounter) {
            this.setState({
                error: true,
                errorMessage: "Превышено допустимое количество файлов",
            });

            return;
        }

        Array.from(files).forEach((file) => {
            if (fileExtensions) {
                const isExtValid = checkFileExtension(file, fileExtensions);

                if (!isExtValid) {
                    this.setState({
                        error: true,
                        errorMessage: "Некорректный формат файла",
                    });

                    return;
                }
            }

            if (file.size > maxFileSize) {
                this.setState({
                    error: true,
                    errorMessage: "Превышен максимальный лимит файла",
                });

                return;
            }

            const formData = new FormData();

            formData.append("vl_doc", file);
            this.setState((prevState) => ({
                fetching: prevState.fetching + 1,
                uploadedFilesCounter: prevState.uploadedFilesCounter + 1,
            }));
            this.props
                .uploadFileAction({idDocType, vlDocName: file.name}, session, formData)
                .then((fileResult) => {
                    if (!fileResult.error && fileResult.response) {
                        this.props.addFile([
                            {
                                filename: file.name,
                                idDoc: fileResult.response.idDocument || fileResult.response.vlDoc,
                                idDocType,
                            },
                        ]);
                    }
                })
                .catch()
                .then(() => {
                    this.setState((prevState) => ({fetching: prevState.fetching - 1, touched: true}));
                    if (this.inputInstance) {
                        this.inputInstance.value = "";
                    }
                });
        });
    };

    deleteFile = (idDoc: number) => {
        const {session} = this.props;

        this.props.deleteFileAction(idDoc, session);
        this.props.deleteFile(idDoc);
        this.setState({counter: this.state.counter - 1});
        this.setState((prevState) => ({
            counter: prevState.counter - 1,
            uploadedFilesCounter: prevState.uploadedFilesCounter - 1,
        }));
    };

    renderCancelIcon = (idDoc: number) => {
        const {classes = {}} = this.props;

        return <ClearIcon className={classes.rootIcon} onClick={() => this.deleteFile(idDoc)} />;
    };

    getInputInstance = (ref: HTMLInputElement | null) => (this.inputInstance = ref);

    closeErrorModal = () => {
        this.setState({counter: this.state.uploadedFilesCounter, error: false});
    };

    render() {
        const {classes = {}, prRequired, margin, nmColumn, nmVdDoc, vlCount, values, maxFilesCounter} = this.props;
        const {fetching} = this.state;
        const label = vlCount !== null && vlCount > 0 ? `${nmVdDoc} - минимальное кол-во файлов ${vlCount}` : nmVdDoc;

        return (
            <FormControl fullWidth required={prRequired} error={this.isError()} margin={margin}>
                <div className={classes.wrapContent}>
                    <InputLabel className={classes.textLabel}>{label}</InputLabel>
                    <input
                        ref={this.getInputInstance}
                        type="file"
                        id={this.state.id}
                        multiple
                        onChange={this.onChangeFile}
                        className={classes.customInputFile}
                        name={nmColumn}
                    />
                    <div className={classes.innerUploadContent}>
                        {values.map(({filename, idDoc}) => (
                            <Grid container spacing={8} key={idDoc}>
                                <Grid item>
                                    <Typography>{filename}</Typography>
                                </Grid>
                                <Grid item>{this.renderCancelIcon(idDoc)}</Grid>
                            </Grid>
                        ))}
                        <div>
                            <Loader fetching={fetching !== 0} loaderType="default" />
                        </div>
                        {this.state.error ? (
                            <ModalConfirm
                                open={this.state.error}
                                title={this.state.errorMessage}
                                errorDialog
                                onCancel={this.closeErrorModal}
                                contentBottomPadding
                            />
                        ) : null}
                        {maxFilesCounter && this.state.counter >= maxFilesCounter ? null : (
                            <label htmlFor={this.state.id} className={classes.inputLabelClass}>
                                {this.props.uploadButtonName ? this.props.uploadButtonName : "Приложить файл"}
                            </label>
                        )}
                    </div>
                </div>
            </FormControl>
        );
    }
}

export default withStyles(styles)(UploadFile);
