// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {DEFAULT_SIZE} from "../../../constants/iconConstants";
import {type IconPropsType} from "./iconType";

const VlgLogoIcon = ({fontSize, ...otherProps}: IconPropsType) => (
    <SvgIcon {...otherProps} style={{fontSize}}>
        <g>
            <g>
                <path
                    fill="#F18D16"
                    d={
                        "M15.3,15.2c-20.4,20.4-20.4,53.5,0,73.8c15.8,15.8" +
                        ",39.3,19.3,58.5,10.5L67,87.1l31.3,11.2L15.3,15.2z"
                    }
                />
            </g>
        </g>
        <path
            fill="#16487E"
            d="M89.1,89.1c20.4-20.4,20.4-53.5,0-73.9C73.2-0.6,49.8-4.1,30.5,4.7l6.8,12.5L6.1,6L89.1,89.1L89.1,89.1z"
        />
        <g>
            <path
                fill="#FFFFFF"
                d={
                    "M62.6,56.1h-6.3c-0.7,2.9-2.5,4.4-5,4.4c-2.9,0-4.6-2.1-4.6-" +
                    "5.7c0-3.9,1.9-9.3,6.5-9.3c2.8,0,4,1.4,4,4.1h6.1V49c0-5.3-3.9-" +
                    "8.6-9.9-8.6c-8.2,0-13,6.4-13,14.4c0,6.8,3.8,10.7,10.4,10.7C57.1" +
                    ",65.5,61.4,62.1,62.6,56.1L62.6,56.1z"
                }
            />
            <path
                fill="#FFFFFF"
                d={
                    "M39.4,56.1h-6.3c-0.7,2.9-2.5,4.4-5,4.4c-2.9,0-4.6-2.1-4." +
                    "6-5.7c0-3.9,1.9-9.3,6.5-9.3c2.8,0,4,1.4,4,4.1h6.1V49c0-5.3" +
                    "-3.9-8.6-9.9-8.6c-8.2,0-13,6.4-13,14.4c0,6.8,3.8,10.7,10.4," +
                    "10.7C33.9,65.5,38.2,62.1,39.4,56.1L39.4,56.1z"
                }
            />
            <polygon
                fill="#FFFFFF"
                points="88.8,40.8 80.5,40.8 70.6,50.1 72.7,40.8 66.4,40.8 61.4,64.8 67.6,64.8 69.2,57.5 72.4,54.7
		77.3,64.8 84.4,64.8 77.3,50.7 88.8,40.8 	"
            />
        </g>
    </SvgIcon>
);

VlgLogoIcon.defaultProps = {
    fontSize: DEFAULT_SIZE,
    viewBox: "0 0 104.7 105.2",
};

export default VlgLogoIcon;
