// @flow
import React, {PureComponent} from "react";
import cn from "classnames";

export type Props = {
    className?: string,
    children?: React$Node,
    onClick?: Function,
    open: boolean,
    testID?: string,
    minWidth?: number,
    onOpen?: () => void,
    onClose?: () => void,
    isDefaultOpen?: boolean,
    toUpdatePanels?: boolean,
};

export type StateProps = {
    show: boolean,
};

class Panel extends PureComponent<Props, StateProps> {
    static defaultProps = {
        open: false,
    };

    state = {
        show: false,
    };

    componentDidMount = () => {
        this.setState({show: this.props.isDefaultOpen || this.props.open});
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.toUpdatePanels && this.props.toUpdatePanels !== prevProps.toUpdatePanels) {
            this.setState({show: this.props.isDefaultOpen});
        }
    }

    handleToggle = () => {
        if (this.state.show) {
            if (this.props.onClose) {
                this.props.onClose();
            }
        } else if (this.props.onOpen) {
            this.props.onOpen();
        }

        this.setState(({show}) => ({
            show: !show,
        }));

        if (this.props.onClick) {
            this.props.onClick();
        }
    };

    render() {
        const {children, className: classNameProp, testID, minWidth = 0} = this.props;
        const {show} = this.state;

        const className = cn(classNameProp);

        const childrenWithProps = React.Children.map(children, (child) => {
            const nodeName = child ? child.type.panelName : {};

            if (nodeName === "PanelHead") {
                return React.cloneElement(child, {
                    handleToggle: this.handleToggle,
                    show,
                });
            }
            if (nodeName === "PanelBody") {
                return show ? React.cloneElement(child, {show}) : null;
            }

            return child;
        });

        return (
            <div className={className} testid={testID} style={{minWidth: `${minWidth}px`}}>
                {childrenWithProps}
            </div>
        );
    }
}

export default Panel;
