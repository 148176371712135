// @flow
import * as React from "react";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import cn from "classnames";
import Hidden from "@material-ui/core/Hidden/Hidden";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {withStyles} from "@material-ui/core/styles";
import UserAccountIcon from "../../packages/components/Icons/UserAccountIcon";
import WarningOutlineIcon from "../../packages/components/Icons/WarningOutlineIcon";
import Typography from "../../packages/components/Typography/Typography";
import Content from "../../packages/components/Content/Content";
import TopMenuDropDown from "./TopMenuDropDown";
import TopMenuDropDownBtn from "./TopMenuDropDownBtn";
import {styles} from "./styles";

export type Props = {
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    firstName: string,
    secondName: string,
    handleLogout: () => Promise<any>,
    fetchingAccountLogout: boolean,
    isProfileDropdownOpen: boolean,
    paspDetailsFailed: Object,
};

export class TopMenuUserAccount extends React.Component<Props> {
    renderBody = ({onClose}: Object) => {
        const {classes = {}, fetchingAccountLogout, firstName, secondName} = this.props;

        return (
            <React.Fragment>
                {firstName || secondName ? (
                    <div id="id22012">
                        <Hidden lgUp implementation="css" className={classes.dropDown}>
                            <Typography
                                backgrColor="secondary-TopMenu"
                                align="center"
                                fontSize={14}
                                fontWeight={400}
                                verticalPadding={14}
                                textColor="white"
                            >
                                {`${firstName} ${secondName}`}
                            </Typography>
                        </Hidden>
                    </div>
                ) : null}
                <TopMenuDropDownBtn
                    disableRipple
                    to={"/profile"}
                    component={Link}
                    testID="ProfileBtn"
                    id="id22012"
                    onClick={onClose}
                >
                    <Typography align="left" fontSize={14} fontWeight={400} textColor="white">
                        Профиль
                    </Typography>
                </TopMenuDropDownBtn>
                <TopMenuDropDownBtn
                    disableRipple
                    onClick={this.props.handleLogout}
                    disabled={fetchingAccountLogout}
                    testID="LogoutBtn"
                    component="div"
                    id="id22012"
                >
                    <Typography align="left" fontSize={14} fontWeight={400} textColor="white">
                        Выход
                    </Typography>
                </TopMenuDropDownBtn>
            </React.Fragment>
        );
    };

    renderHeader = ({open, onToggle}: Object) => {
        const {classes = {}, firstName, secondName, paspDetailsFailed} = this.props;
        const iconClassName = cn(classes.iconButton, {
            [classes.rotate]: open,
        });
        const isDisplayedWarningIcon = Object.values(paspDetailsFailed).some((val) => !val);

        return (
            <Content horizontal="xs" className={classes.mainContent} onClick={onToggle} id="id22012">
                <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                    <Grid item xs>
                        <Grid container spacing={16} alignItems="center" wrap="nowrap">
                            {/* eslint-disable-next-line no-magic-numbers */}
                            <Grid item style={{display: "inline-flex", marginRight: isDisplayedWarningIcon ? -48 : 0}}>
                                <UserAccountIcon fontSize={40} className={classes.icon} />
                                {isDisplayedWarningIcon ? (
                                    <WarningOutlineIcon
                                        className={classes.warningIcon}
                                        backgroundColor="#fff"
                                        iconColor="#fff"
                                    />
                                ) : null}
                            </Grid>

                            <Grid item>
                                <Hidden mdDown implementation="css">
                                    <Content
                                        testID="sideBarUserName"
                                        overflowX="hidden"
                                        overflowY="hidden"
                                        maxWidth={110}
                                    >
                                        <Typography textColor="white" whiteSpace="nowrap" textOverflow="ellipsis">
                                            {firstName}
                                        </Typography>
                                        <Typography textColor="white" whiteSpace="nowrap" textOverflow="ellipsis">
                                            {secondName}
                                        </Typography>
                                    </Content>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <ExpandMoreIcon className={iconClassName} testID="exitMenu" />
                    </Grid>
                </Grid>
            </Content>
        );
    };

    render() {
        const {classes = {}, isProfileDropdownOpen} = this.props;

        return (
            <TopMenuDropDown
                ComponentBody={this.renderBody}
                ComponentHead={this.renderHeader}
                className={classes.dropDown}
                isProfileDropdownOpen={isProfileDropdownOpen}
            />
        );
    }
}

export default withStyles(styles)(TopMenuUserAccount);
