import moment from "moment";
import "moment/locale/ru";
import camelCase from "lodash/camelCase";
import {parsingNumber} from "../../../../utils/numberUtils";

const defaultFormat = "MM.DD.YYYY";

const dateFormats = {
    "dd mmmm yyyy": "DD MMMM YYYY",
    "dd.mm.yyyy": "DD.MM.YYYY",
    "dd.mm.yyyy hh:mm": "DD.MM.YYYY HH:mm",
    "dd.mm.yyyy hh:mm:ss": "DD.MM.YYYY HH:mm:ss",
    "hh:mm": "HH:mm",
    "mmmm yyyy": "MMMM YYYY",
};

const numericFormats = {
    absGreen: (data, precision, theme) =>
        `<span style="color: ${theme.palette.common.green};">${parsingNumber(
            Math.abs(Number(data)).toFixed(precision),
        )}</span>`,
    absRed: (data, precision, theme) =>
        `<span style="color: ${theme.palette.common.red};">${parsingNumber(
            Math.abs(Number(data)).toFixed(precision),
        )}</span>`,
    default: (data, precision) => parsingNumber(Number(data).toFixed(precision), true),
    mesNegredPosgreenBalanceWithSign: (data, precision, theme) => {
        if (Number(data) < 0) {
            return `<span style="color: ${theme.palette.common.balanceRed};">-${parsingNumber(
                Math.abs(Number(data)).toFixed(precision),
            )}</span>`;
        }

        return `<span style="color: ${theme.palette.common.balanceGreen};">${parsingNumber(
            Number(data).toFixed(precision),
        )}</span>`;
    },
    module: (data, precision) => parsingNumber(Math.abs(Number(data), true).toFixed(precision)),
    negRedWithSignPosGreen: (data, precision, theme) =>
        `<span style="color: ${Number(data) <= 0 ? theme.palette.common.green : theme.palette.common.red};">${
            Number(data) <= 0 ? "" : "-"
        }${parsingNumber(Math.abs(Number(data)).toFixed(precision))}</span>`,
    neggreenPosred: (data, precision, theme) => {
        if (Number(data) <= 0) {
            return `<span style="color: ${theme.palette.common.green};">${parsingNumber(
                Math.abs(Number(data)).toFixed(precision),
            )}</span>`;
        }

        return `<span style="color: ${theme.palette.common.red};">${parsingNumber(
            Number(data).toFixed(precision),
        )}</span>`;
    },
    neggreenPosredWozero: (data, precision, theme) => {
        if (Number(data) <= 0) {
            return `<span style="color: ${theme.palette.common.balanceGreen};">${parsingNumber(
                Math.abs(Number(data)).toFixed(precision),
            )}</span>`;
        }

        return `<span style="color: ${theme.palette.common.balanceRed};">${parsingNumber(
            Number(data).toFixed(precision),
        )}</span>`;
    },
    negredPosgreen: (data, precision, theme) => {
        if (Number(data) < 0) {
            return `<span style="color: ${theme.palette.common.red};">${parsingNumber(
                Math.abs(Number(data)).toFixed(precision),
            )}</span>`;
        }

        return `<span style="color: ${theme.palette.common.green};">${parsingNumber(
            Number(data).toFixed(precision),
        )}</span>`;
    },
    negredPosgreenBalance: (data, precision, theme) => {
        if (Number(data) < 0) {
            return `<span style="color: ${theme.palette.common.balanceRed};">${parsingNumber(
                Math.abs(Number(data)).toFixed(precision),
            )}</span>`;
        }

        return `<span style="color: ${theme.palette.common.balanceGreen};">${parsingNumber(
            Number(data).toFixed(precision),
        )}</span>`;
    },
    negredPosgreenWoabs: (data, precision, theme) => {
        if (Number(data) < 0) {
            return `<span style="color: ${theme.palette.common.red};">${parsingNumber(
                Number(data).toFixed(precision),
            )}</span>`;
        }

        return `<span style="color: ${theme.palette.common.green};">${parsingNumber(
            Number(data).toFixed(precision),
        )}</span>`;
    },
};

export const patternTypes = {
    datetime: ({data, format}) => {
        if (!data) {
            return "";
        }

        return moment(data).format(dateFormats[format] || defaultFormat);
    },
    numeric: ({data, format, precision, theme}) => {
        if (data === null || data === undefined) {
            return "";
        }
        const func = numericFormats[camelCase(format)] || numericFormats.default;

        return func(data, precision, theme);
    },
};

export default patternTypes;
