// @flow

export const GET_LS_QUESTION_ACTION = "GET_LS_QUESTION_ACTION";
export const GET_LS_QUESTION_ACTION_SUCCESS = "GET_LS_QUESTION_ACTION_SUCCESS";
export const GET_LS_QUESTION_ACTION_FAILURE = "GET_LS_QUESTION_ACTION_FAILURE";

export const LS_CONFIRM_ACTION = "LS_CONFIRM_ACTION";
export const LS_CONFIRM_ACTION_SUCCESS = "LS_CONFIRM_ACTION_SUCCESS";
export const LS_CONFIRM_ACTION_FAILURE = "LS_CONFIRM_ACTION_FAILURE";

export const LS_ADD_ACTION = "LS_ADD_ACTION";
export const LS_ADD_ACTION_SUCCESS = "LS_ADD_ACTION_SUCCESS";
export const LS_ADD_ACTION_FAILURE = "LS_ADD_ACTION_FAILURE";

export const GET_LS_PD_SUBSCR_INFO_ACTION = "GET_LS_PD_SUBSCR_INFO_ACTION";
export const GET_LS_PD_SUBSCR_INFO_ACTION_SUCCESS = "GET_LS_PD_SUBSCR_INFO_ACTION_SUCCESS";
export const GET_LS_PD_SUBSCR_INFO_ACTION_FAILURE = "GET_LS_PD_SUBSCR_INFO_ACTION_FAILURE";

export const SET_LS_SUBSCR_ACTION = "SET_LS_SUBSCR_ACTION";
export const SET_LS_SUBSCR_ACTION_SUCCESS = "SET_LS_SUBSCR_ACTION_SUCCESS";
export const SET_LS_SUBSCR_ACTION_FAILURE = "SET_LS_SUBSCR_ACTION_FAILURE";

export const CLOSE_LS_ADD_QUESTION_FORM_ACTION = "CLOSE_LS_ADD_QUESTION_FORM_ACTION";

export const GET_LS_QUESTIONS_ELEMENTS = "GET_LS_QUESTIONS_ELEMENTS";
export const GET_LS_QUESTIONS_ELEMENTS_SUCCESS = "GET_LS_QUESTIONS_ELEMENTS_SUCCESS";
export const GET_LS_QUESTIONS_ELEMENTS_FAILURE = "GET_LS_QUESTIONS_ELEMENTS_FAILURE";
