// @flow
import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {type BrowserHistory} from "history/createBrowserHistory";
import Grid from "@material-ui/core/Grid/Grid";
import {type StoreType} from "../../redux/reducers";
import {type ApiMiddlewareResponsePromiseType} from "../../redux/middlewares/apiMiddleware";
import ModalNotification from "../../packages/components/Modal/ModalNotification";
import {publickUrl} from "../../constants/Application";
import {
    closeBannerNoticeModal,
    closeCriticalNoticeModal,
    setActionDate,
    setNoticeReaded,
    getCriticalNotice,
    openNotice,
    getLsListNoticeStatusAction,
    setLsSubscrEmail,
} from "../../redux/actions/notificationAction";
import {reachGoal} from "../../utils/metricUtils";
import {SET_LS_SUBSCR_EMAIL} from "../../constants/Notification";
import CriticalNoticeModalBodyRow from "./CriticalNoticeModalBodyRow";
import ButtonAction from "./ButtonAction";
import EmptyNotifications from "./EmptyNotifications";

type OwnProps = {|
    history: BrowserHistory,
    accountsList: Array<Object>,
|};

type StoreProps = {|
    auth: Object,
    newToken?: string,
    login?: string,
    bannerNotice: Array<Object>,
    criticalNotice: Array<Object>,
    openBannerNoticeModal: number,
    isCriticalNoticeModalOpen: boolean,
    idService: string,
    session: string,
|};

type DispatchProps = {|
    closeBannerNoticeModal: () => void,
    closeCriticalNoticeModal: (fromModal: boolean) => void,
    getCriticalNotice: (session: string) => ApiMiddlewareResponsePromiseType,
    setActionDate: (idNotice: string, kdEventAction: string, session?: string) => ApiMiddlewareResponsePromiseType,
    setLsSubscrEmail: (idService: string, session?: string) => ApiMiddlewareResponsePromiseType,
    setNoticeReaded: (idNotice: string, session?: string) => ApiMiddlewareResponsePromiseType,
    openNotice: (idNotice: string) => void,
    getLsListNoticeStatusAction: (session: string) => ApiMiddlewareResponsePromiseType,
|};

type Props = {...OwnProps, ...StoreProps, ...DispatchProps};

export class NotificationModals extends React.Component<Props> {
    bannerAction = (idNotice: string, kdEventAction: string) => {
        const {session, idService} = this.props;
        const bannerNotice = this.props.bannerNotice[this.props.openBannerNoticeModal - 1];

        if (kdEventAction === SET_LS_SUBSCR_EMAIL) {
            this.props.setLsSubscrEmail(bannerNotice.idService ? bannerNotice.idService : idService, session);

            return;
        }

        this.props.setActionDate(idNotice, kdEventAction, session);
        this.props.closeBannerNoticeModal();
    };

    onRead = async (idNotice: string) => {
        const {session} = this.props;

        await this.props.setNoticeReaded(idNotice, session);
        this.props.getLsListNoticeStatusAction(session);
    };

    renderBannerNoticeModalBody = () => {
        if (this.props.openBannerNoticeModal <= 0) {
            return null;
        }

        const bannerNotice = this.props.bannerNotice[this.props.openBannerNoticeModal - 1];

        return (
            <React.Fragment>
                <div>
                    <img
                        width="100%"
                        src={`data:image/png;base64,${bannerNotice.vlBanner}`}
                        alt={bannerNotice.nmAltText}
                    />
                </div>
                <Grid container spacing={16}>
                    {bannerNotice.actions.map((btnItem, btnIndex) => (
                        <ButtonAction
                            key={btnIndex}
                            idNotice={bannerNotice.idNotice}
                            btnItem={btnItem}
                            bannerAction={this.bannerAction}
                        />
                    ))}
                </Grid>
            </React.Fragment>
        );
    };

    criticalNoticeAction = (idNotice: string, idService: string) => {
        window.open(`${publickUrl}/accounts/${idService}/notifications?idNotice=${idNotice}`);
        this.props.openNotice(idNotice);
        this.props.closeCriticalNoticeModal(true);
    };

    renderCriticalNoticeModalBody = () => {
        const {auth, criticalNotice, accountsList, history, session} = this.props;

        return criticalNotice.length === 0 ? (
            <EmptyNotifications />
        ) : (
            criticalNotice.map<React.Node>((noticeItem, noticeIndex) => (
                <CriticalNoticeModalBodyRow
                    auth={auth}
                    history={history}
                    key={noticeIndex}
                    noticeItem={noticeItem}
                    criticalNoticeAction={this.criticalNoticeAction}
                    accountsList={accountsList}
                    onCancel={this.closeCriticalNoticeModal}
                    onRead={this.onRead}
                    setLsSubscrEmail={this.props.setLsSubscrEmail}
                    session={session}
                />
            ))
        );
    };

    closeCriticalNoticeModal = () => {
        reachGoal("m_notifications_close");
        this.props.closeCriticalNoticeModal(true);
    };

    render() {
        const {openBannerNoticeModal} = this.props;

        return (
            <React.Fragment>
                <ModalNotification
                    testID="BannerNoticeModal"
                    onCancel={this.props.closeBannerNoticeModal}
                    open={openBannerNoticeModal > 0}
                    title=""
                >
                    {this.renderBannerNoticeModalBody()}
                </ModalNotification>
            </React.Fragment>
        );
    }
}

export default connect<Props, OwnProps, StoreProps, DispatchProps, StoreType, _>(
    ({notification, account, auth}: StoreType): StoreProps => ({
        auth,
        bannerNotice: notification.bannerNotice,
        criticalNotice: notification.criticalNotice,
        idService: account.accountDetail.idService,
        isCriticalNoticeModalOpen: notification.isCriticalNoticeModalOpen,
        openBannerNoticeModal: notification.openBannerNoticeModal,
        session: auth.session || "",
    }),
    (dispatch): DispatchProps => ({
        closeBannerNoticeModal: bindActionCreators(closeBannerNoticeModal, dispatch),
        closeCriticalNoticeModal: bindActionCreators(closeCriticalNoticeModal, dispatch),
        getCriticalNotice: bindActionCreators(getCriticalNotice, dispatch),
        getLsListNoticeStatusAction: bindActionCreators(getLsListNoticeStatusAction, dispatch),
        openNotice: bindActionCreators(openNotice, dispatch),
        setActionDate: bindActionCreators(setActionDate, dispatch),
        setLsSubscrEmail: bindActionCreators(setLsSubscrEmail, dispatch),
        setNoticeReaded: bindActionCreators(setNoticeReaded, dispatch),
    }),
)(NotificationModals);
