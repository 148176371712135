// @flow
import React from "react";
import cn from "classnames";
import {withStyles} from "@material-ui/core/styles";
import {getContent} from "../utils";
import type {themeTypes} from "../../../../themeStyle";

const styles = (theme: themeTypes) => ({
    disabled: {
        cursor: "default !important",
        opacity: 0.6,
    },
    root: {
        borderBottom: "1px dashed",
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
        cursor: "pointer",
        fontWeight: 400,
    },
});

type Props = {
    classes: {
        [$Keys<$Call<typeof styles, themeTypes>>]: string,
    },
    content?: Array<{
        vlContent: string,
    }>,
    onClick?: () => void,
    disabled?: boolean,
};

export const ClickableTextElement = ({classes, content = [], onClick, disabled}: Props) => {
    const {vlContent} = getContent(content, {}) || {};

    return (
        <span
            className={cn(classes.root, {
                [classes.disabled]: disabled,
            })}
            onClick={disabled ? undefined : onClick || undefined}
            dangerouslySetInnerHTML={{__html: vlContent}}
        />
    );
};

export default withStyles(styles)(ClickableTextElement);
