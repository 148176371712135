export const accountKsgTabsSettings = [
    {
        label: "Уведомления",
        notification: true,
        value: "notifications",
    },
    {
        label: "История",
        value: "events",
    },
    {
        label: "Реквизиты ЛС",
        value: "requisites",
    },
    {
        label: "Статистика",
        value: "statistics",
    },
    {
        label: "Обращения",
        value: "application",
    },
];
