// @flow
import get from "lodash/get";
import {mimeTypes} from "../constants/types/MimeTypes";

export const getBase64ImgSrc = (
    content: Array<{nmLink?: string, nnOrder: number, vlContent: string, vlContentB?: string}>,
    nmFileExtensions?: string,
) => {
    const imgCode = get(content, "0.vlContentB");

    if (imgCode) {
        const format = nmFileExtensions && mimeTypes[nmFileExtensions] ? mimeTypes[nmFileExtensions] : "image/png";

        return `data:${format};base64,${imgCode}`;
    }

    return undefined;
};
