// @flow
import * as React from "react";
import cn from "classnames";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core/styles";

const styles = (theme: any) => ({
    collapseContent: {
        position: "absolute",
        transformOrigin: "0 0 0",
        width: theme.sizing.topMenuUserAccountWidth,
    },
    mdFullWidth: {
        [theme.breakpoints.down("md")]: {
            left: 0,
            width: "100%",
        },
    },
    paperContainer: {
        background: theme.palette.secondary.light2,
        border: `1px solid ${theme.palette.topMenu.separator}`,
        borderRadius: 0,
    },
});

export type Props = {
    classes?: Object,
    ComponentBody: React.StatelessFunctionalComponent<*>,
    ComponentHead: React.StatelessFunctionalComponent<*>,
    isProfileDropdownOpen?: boolean,
    dropdownHeadName: string,
    dropdownWidthFixed?: boolean,
};
type State = {
    open: boolean,
};

export class TopMenuDropDown extends React.Component<Props, State> {
    state = {
        open: false,
    };

    componentDidMount() {
        this.setState({open: this.props.isProfileDropdownOpen});
    }

    componentDidUpdate(prevProps: Object) {
        if (prevProps.isProfileDropdownOpen !== this.props.isProfileDropdownOpen) {
            this.setState({open: this.props.isProfileDropdownOpen});
        }
    }

    handleToggle = (event: SyntheticEvent<>) => {
        event.stopPropagation();
        this.setState((state) => ({open: !state.open}));
    };

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const {classes = {}, ComponentBody, ComponentHead, dropdownHeadName, dropdownWidthFixed} = this.props;
        const {open} = this.state;

        return (
            <React.Fragment>
                <ClickAwayListener onClickAway={this.handleClose} mouseEvent="onClick" touchEvent={false}>
                    <ComponentHead
                        open={open}
                        onToggle={this.handleToggle}
                        onClose={this.handleClose}
                        dropdownHeadName={dropdownHeadName}
                    />
                    <Collapse
                        in={open}
                        className={cn(classes.collapseContent, {
                            [classes.mdFullWidth]: !dropdownWidthFixed,
                        })}
                    >
                        <Paper className={classes.paperContainer}>
                            <ComponentBody open={open} onClose={this.handleClose} className={classes.componentBody} />
                        </Paper>
                    </Collapse>
                </ClickAwayListener>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(TopMenuDropDown);
