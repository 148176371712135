// @flow
import * as React from "react";
import cn from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {withStyles} from "@material-ui/core/styles";

/* eslint-disable sort-keys */
const styles = (theme) => ({
    pointer: {
        cursor: "pointer",
    },
    defaultHeadStyle: {
        alignItems: "center",
        display: "flex",
    },
    headerContent: {
        flexBasis: "95%",
    },
    headerGray: {
        background: theme.palette.primary.translucent,
    },
    iconButton: {
        position: "relative",
        transition: "transform 300ms ease-in-out",
        color: theme.palette.grey.dark,
    },
    rotate: {
        transform: "rotate(-180deg)",
    },
    "border-bottom": {
        borderBottom: `1px solid ${theme.palette.grey.coolGray1}`,
    },
    "border-top": {
        borderTop: `1px solid ${theme.palette.grey.coolGray1}`,
    },
    "border-top-bottom": {
        borderBottom: `1px solid ${theme.palette.grey.coolGray1}`,
        borderTop: `1px solid ${theme.palette.grey.coolGray1}`,
    },
    "padding-none": {
        padding: 0,
    },
    "padding-sm": {
        padding: theme.spacing.unit * 2,
    },
});
/* eslint-enable sort-keys */

export type PanelHeaderRenderPropsType = {show?: boolean};

export type Props = {
    children?: React.Node,
    classes?: any,
    className?: string,
    cursorPointer?: boolean,
    handleToggle?: Function,
    headerBorder?: "top" | "bottom" | "top-bottom",
    headerGray?: boolean,
    onClick?: Function,
    padding?: "none" | "sm",
    show?: boolean,
    testID?: string,
    withoutIcon?: boolean,
    render?: (data: PanelHeaderRenderPropsType) => React.Node,
};

class PanelHead extends React.PureComponent<Props> {
    static panelName = "PanelHead";

    render() {
        const {
            children,
            classes = {},
            className: classNameProp,
            cursorPointer,
            handleToggle,
            headerGray,
            headerBorder = "",
            onClick,
            padding = "",
            show,
            testID = "",
            withoutIcon,
        } = this.props;

        const className = cn(classNameProp, classes.defaultHeadStyle, {
            [classes[`padding-${padding}`]]: padding,
            [classes[`border-${headerBorder}`]]: headerBorder,
            [classes.headerGray]: headerGray,
            [classes.pointer]: cursorPointer,
        });

        const iconClassName = cn(classes.iconButton, {
            [classes.rotate]: show,
        });

        return (
            <div className={className} onClick={handleToggle} testid={testID}>
                <div className={classes.headerContent} onClick={onClick}>
                    {children}
                    {this.props.render ? this.props.render({show}) : null}
                </div>
                {withoutIcon ? null : (
                    <div className={iconClassName} onClick={onClick}>
                        <ExpandMoreIcon />
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(styles, {name: "PanelHeadTheme"})(PanelHead);
