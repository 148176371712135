// @flow
import * as React from "react";
import {Paper, withStyles, Divider} from "@material-ui/core";
import type {BrowserHistory} from "history/createBrowserHistory";
import {useSelector} from "react-redux";

import type {StoreType} from "../../../redux/reducers";
import {ENERGY_CONTRACTS_KD_SECTION} from "../../../constants/kdSections";
import {type TopMenuItem} from "../../../redux/reducers/configReducer";
import Typography from "../../../packages/components/Typography/Typography";
import Button from "../../../packages/components/Button/Button";
import Content from "../../../packages/components/Content/Content";
import {styles} from "./styles";

type Props = {
    history: BrowserHistory,
    backgroundUrl: string,
    nmFirst: string,
    nmLast: string,
    topMenuItems: Array<TopMenuItem>,
    classes?: Object,
};

const WelcomePageButtons = (props: Props) => {
    const {availProviders} = useSelector(({energyContracts}: StoreType) => ({
        availProviders: energyContracts.availProviders,
    }));
    const {backgroundUrl, classes = {}, history, nmFirst, nmLast, topMenuItems} = props;
    const showMakeRequestBtn = React.useMemo(() => {
        const enerContractsMenuItem = topMenuItems.find(
            (menuItem: TopMenuItem) => menuItem.kdSection === ENERGY_CONTRACTS_KD_SECTION,
        );

        return Boolean(enerContractsMenuItem) && availProviders.length > 0;
    }, [availProviders.length, topMenuItems]);

    const handleAddLs = React.useCallback(() => {
        history.push("/account-add");
    }, [history]);
    const handleAddContractRequest = React.useCallback(() => {
        history.push("/energy-contracts", {
            openRequestModal: true,
        });
    }, [history]);

    return (
        <div className={classes.mainContent}>
            <img src={backgroundUrl} alt="" className={classes.backgroundImg} />
            <Paper className={classes.paperContainer}>
                <Content horizontal={30} vertical={40}>
                    <Typography fontSize={24} fontWeight={700}>
                        Добро пожаловать,
                    </Typography>
                    <Typography
                        fontSize={24}
                        textOverflow="ellipsis"
                        wordWrap="normal"
                        fontWeight={700}
                    >{`${nmFirst} ${nmLast}!`}</Typography>
                    <Typography fontSize={16} verticalPadding={24}>
                        Начните работу с сервисом добавлением лицевого счета
                    </Typography>
                    <Button color="secondary" size="full" fontSize={14} minHeight={48} onClick={handleAddLs}>
                        Добавить лицевой счет
                    </Button>
                    {showMakeRequestBtn ? (
                        <React.Fragment>
                            <Divider className={classes.divider} />
                            <Typography fontSize={16}>
                                Вы можете подать заявку на оформление договора энергоснабжения
                            </Typography>
                            <Button
                                className={classes.requestButton}
                                color="transparent"
                                textColor="secondary"
                                size="full"
                                fontSize={14}
                                minHeight={48}
                                border
                                onClick={handleAddContractRequest}
                            >
                                Подать заявку
                            </Button>
                        </React.Fragment>
                    ) : null}
                </Content>
            </Paper>
        </div>
    );
};

export default withStyles(styles)(WelcomePageButtons);
