/* eslint-disable max-lines */
import {
    MES_KD_PROVIDER,
    UFA_KD_PROVIDER,
    MOE_KD_PROVIDER,
    TKO_KD_PROVIDER,
    TMK_NRG_KD_PROVIDER,
    TMK_RTS_KD_PROVIDER,
    VLG_KD_PROVIDER,
    ORL_KD_PROVIDER,
    ORL_EPD_KD_PROVIDER,
    ALT_KD_PROVIDER,
    SAR_KD_PROVIDER,
    TMB_KD_PROVIDER,
    VLD_KD_PROVIDER,
    KSG_KD_PROVIDER,
} from "./KdProvider";
import {accountMesTabsSettings} from "./accountTabsSettings/mesAccoutTabsSettings";
import {accountUfaTabsSettings} from "./accountTabsSettings/ufaAccoutTabsSettings";
import {accountVlgTabsSettings} from "./accountTabsSettings/vlgAccoutTabsSettings";
import {accountAltTabsSettings} from "./accountTabsSettings/altAccoutTabsSettings";
import {accountOrlTabsSettings} from "./accountTabsSettings/orlAccoutTabsSettings";
import {accountSarTabsSettings} from "./accountTabsSettings/sarAccoutTabsSettings";
import {accountVldTabsSettings} from "./accountTabsSettings/vldAccoutTabsSettings";
import {accountTmbTabsSettings} from "./accountTabsSettings/tmbAccoutTabsSettings";
import {accountKsgTabsSettings} from "./accountTabsSettings/ksgAccoutTabsSettings";
import {accountMoeTabsSettings, eventsMoeTabsSettings} from "./accountTabsSettings/moeAccoutTabsSettings";
import {accountTkoTabsSettings, eventsTkoTabsSettings} from "./accountTabsSettings/tkoAccoutTabsSettings";
import {
    TomskNrgLogoIcon,
    TomskRtsLogoIcon,
    MoeLogoIcon,
    TkoLogoIcon,
    MesLogoIcon,
    UfaLogoIcon,
    VlgLogoIcon,
    AltLogoIcon,
    OrlLogoIcon,
    OrlEpdLogoIcon,
    SarLogoIcon,
    TmbLogoIcon,
    VldLogoIcon,
    KsgLogoWhiteIcon,
    KsgLogoBlueIcon,
    MesLogoWhiteIcon,
    TkoLogoWhiteIcon,
} from "./ProvidersIcons";
import {TRANSFER_INDICATIONS_ALL_LS_MES_KEY, TRANSFER_INDICATIONS_ALL_LS_MOE_KEY} from "./TransferIndications";

export const providerConfig = {
    [ALT_KD_PROVIDER]: {
        addLs: {
            nmInputMask: "99999-999-99",
        },
        autoPay: false,
        bannerTabs: accountAltTabsSettings,
        checkBalance: false,
        complaintPowerSupplyButton: 892,
        detailInfoBtnInBalanceModal: false,
        disableBannerPayBtn: false,
        faq: {
            newAppealText: "Задать вопрос",
        },
        gpItemKdBElements: {
            gpError: 461,
            gpHelp: 448,
            gpNotification: 468,
            gpTooltip: 447,
        },
        logoIcon: AltLogoIcon,
        payFormAddress: false,
        payFormKdBElements: {
            agreement: 261,
            agreementEpr: 777,
            bannerRegisterMIR: 664,
            bannerRegisterSBP: 966,
            error: 263,
            extraPayInfo: 262,
            extraPayInfoEpr: 766,
            onboardingPay: 223,
            onboardingPayMob: 248,
            payInfo: 260,
            paymentButtonText: 914,
            paymentIcons: 913,
            prePay: 265,
            prePayInfo: 264,
            sum: 266,
        },
        payFormKdSection: 116,
        payFormOnlyEmail: false,
        payFormServerConfig: true,
        payFromPrepay: true,
        presentsBtn: {
            kdElement: 399,
            kdSection: 7,
        },
        profileConfirmChangeSubscr: false,
        shortName: "alt",
        showPrintBtnInBalanceModal: false,
        statistics: {
            detailInfo: false,
        },
        transferIndication: {
            kdSection: 115,
            plugins: "propagateAltInd",
        },
        transferIndicationKdBElements: {
            agreement: 257,
            helper: 258,
            helperFloat: 259,
            info: 256,
        },
    },
    [KSG_KD_PROVIDER]: {
        addLs: {
            nmInputMask: "99999-999-99",
            nnLsInfo: 359,
        },
        autoPay: false,
        balanceDetailPayBtnName: "Пополнить баланс",
        balancePayBtnName: "Пополнить баланс",
        bannerTabs: accountKsgTabsSettings,
        catalog: {
            crm20: {
                iconImg: 30,
            },
        },
        checkBalance: false,
        detailInfoBtnInBalanceModal: false,
        disableBannerPayBtn: false,
        faq: {
            kdBElements: {
                crm20: {
                    maxFileSize: "234",
                },
                withoutCrm20: {
                    maxFileSize: "235",
                },
            },
            kdSection: 32,
            kdSectionWithoutCrm20: 48,
            maxFileSize: 47,
            newAppealText: "Подать обращение",
        },
        gpItemKdBElements: {
            gpError: 465,
            gpHelp: 456,
            gpTooltip: 455,
        },
        logoIcon: KsgLogoBlueIcon,
        logoIconBaner: KsgLogoWhiteIcon,
        onBoarding: {
            payBtnId: "id2232",
            paySumId: "id2231",
        },
        payFormAddress: false,
        payFormKdBElements: {
            SBPPayButton: 475,
            agreement: 371,
            bannerRegisterMIR: 543,
            bannerRegisterSBP: null,
            error: 373,
            extraPayInfo: 372,
            extraPayInfoSBP: 478,
            onboardingPay: 377,
            onboardingPayMob: 378,
            payInfoBottom: 370,
            prePay: 375,
            prePayInfo: 374,
            sum: 376,
        },
        payFormKdSection: 131,
        presentsBtn: {
            kdElement: 392,
            kdSection: 7,
        },
        profile: {
            kdSection: 6,
        },
        profileConfirmChangeSubscr: false,
        profileEditAddr: true,
        shortName: "ksg",
        showPrintBtnInBalanceModal: false,
        statistics: {
            detailInfo: false,
        },
        transferIndication: {
            allLs: TRANSFER_INDICATIONS_ALL_LS_MES_KEY,
            kdSection: 130,
            plugins: "propagateKsgInd",
        },
        transferIndicationKdBElements: {
            helper: 360,
            helperFloat: 361,
            info: 365,
        },
    },
    [MES_KD_PROVIDER]: {
        addLs: {
            nmInputMask: "99999-999-99",
        },
        autoPay: true,
        balanceDetailPayBtnName: "Пополнить баланс",
        balancePayBtnName: "Пополнить баланс",
        bannerTabs: accountMesTabsSettings,
        budget: 810,
        catalog: {
            crm20: {
                iconImg: 30,
            },
        },
        checkBalance: false,
        complaintPowerSupplyButton: 887,
        detailInfoBtnInBalanceModal: false,
        disableBannerPayBtn: false,
        faq: {
            kdBElements: {
                crm20: {
                    maxFileSize: "234",
                },
                withoutCrm20: {
                    maxFileSize: "235",
                },
            },
            kdSection: 32,
            kdSectionWithoutCrm20: 48,
            maxFileSize: 47,
            newAppealText: "Подать обращение",
        },
        gpItemKdBElements: {
            gpError: 418,
            gpHelp: 420,
            gpNotification: 419,
            gpTooltip: 417,
        },
        logoIcon: MesLogoIcon,
        logoIconBaner: MesLogoWhiteIcon,
        name: "Мосэнергосбыт",
        onBoarding: {
            payBtnId: "id2232",
            paySumId: "id2231",
        },
        payFormAddress: false,
        payFormKdBElements: {
            SBPPayButton: 474,
            agreement: 40,
            agreementEpr: 771,
            bannerRegisterMIR: 539,
            bannerRegisterSBP: 958,
            error: 42,
            extraPayInfo: 41,
            extraPayInfoEpr: 760,
            extraPayInfoSBP: 477,
            insuranceCheckbox: 720,
            insuranceCheckboxHint: 721,
            insuranceCondition: 723,
            insuranceExtraInfo: 722,
            onboardingPay: 223,
            onboardingPayMob: 248,
            payAuto: 38,
            payAuto2: 39,
            paymentButtonText: 827,
            paymentIcons: 814,
            prePay: 112,
            prePayInfo: 111,
            sum: 113,
            textAboveTheFirstButton: 829,
            textAboveTheSecondButton: 831,
        },
        payFormKdSection: 27,
        presentsBtn: {
            kdElement: 389,
            kdSection: 7,
        },
        profile: {
            kdSection: 6,
        },
        profileConfirmChangeSubscr: false,
        profileEditAddr: true,
        requisites: {
            kdBElements: {
                changelsInfoRef: 341,
                requisitesChangeInfo: 340,
            },
        },
        shortName: "mes",
        showPrintBtnInBalanceModal: false,
        statistics: {
            detailInfo: true,
        },
        transferIndication: {
            allLs: TRANSFER_INDICATIONS_ALL_LS_MES_KEY,
            kdSection: 26,
            plugins: "propagateMesInd",
            unionFlat: 817,
            unionFlatMulti: 819,
        },
        transferIndicationKdBElements: {
            agreement: 72,
            helper: 99,
            helperFloat: 106,
            info: 37,
        },
    },
    [MOE_KD_PROVIDER]: {
        autoPay: true,
        bannerTabs: accountMoeTabsSettings,
        checkBalance: false,
        detailInfoBtnInBalanceModal: false,
        disableBannerPayBtn: false,
        disableBannerTransferIndicationBtn: false,
        eventsTabs: eventsMoeTabsSettings,
        faq: {
            newAppealText: "Подать обращение",
        },
        logoIcon: MoeLogoIcon,
        name: "МособлЕИРЦ",
        payFormEdit: true,
        payFormExtraPayment: true,
        payFormKdBElements: {
            SBPPayButton: 603,
            agreement: 49,
            agreementEpr: 772,
            bannerRegisterMIR: 540,
            bannerRegisterSBP: 959,
            error: 51,
            extraPayInfo: 812,
            extraPayInfoEpr: 761,
            extraPayInfoSBP: 604,
            onboardingPay: 233,
            onboardingPayMob: 249,
            payAuto: 47,
            payInfo: 48,
            paymentIcons: 815,
            prePay: 109,
            prePayInfo: 108,
            sum: 110,
        },
        payFormKdSection: 28,
        payFromPrepay: true,
        payStatusActionQuery: "MoePayStatus",
        presentsBtn: {
            kdElement: 390,
            kdSection: 7,
        },
        profileConfirmChangeSubscr: false,
        shortName: "moe",
        statistics: {
            detailInfo: false,
        },
        transferIndication: {
            allLs: TRANSFER_INDICATIONS_ALL_LS_MOE_KEY,
            kdSection: 25,
            plugins: "propagateMoeInd",
        },
        transferIndicationKdBElements: {
            excessOfAverages: 36,
            indicationError: 35,
            indicationHint: 98,
            indicationWithPointHint: 105,
            measureIndicationHint: 32,
            outOfOrder: 34,
            transferIndication: 33,
        },
    },
    [ORL_EPD_KD_PROVIDER]: {
        checkBalance: false,
        complaintPowerSupplyButton: 891,
        faq: {
            newAppealText: "Подать обращение",
        },
        fullProxy: "orlProxy",
        isBytSupported: false,
        logoIcon: OrlEpdLogoIcon,
        name: "Орел ЕПД",
        orlPayAddress: true,
        payAvail: false,
        payFormKdBElements: {
            agreement: 205,
            agreementEpr: 792,
            bannerRegisterMIR: 663,
            bannerRegisterSBP: 965,
            cardPayInfo: 608,
            error: 42,
            extraPayInfo: 206,
            extraPayInfoEpr: 745,
            onboardingPay: 233,
            onboardingPayMob: 249,
            payAuto: 38,
            payAuto2: 39,
            paymentButtonText: 991,
            paymentIcons: 989,
            prePay: 112,
            prePayInfo: 111,
            sum: 113,
        },
        payFormKdSection: 46,
        plugin: "orl",
        presentsBtn: {
            kdElement: 398,
            kdSection: 7,
        },
        profileConfirmChangeSubscr: false,
        providerPayment: {
            query: "OrlPaymentEpd",
        },
        shortName: "orlEpd",
        shortProxy: "orl",
        statistics: {
            detailInfo: false,
        },
        transferIndication: {
            kdSection: 45,
            plugins: "propagateOrlEpdInd",
        },
        transferIndicationKdBElements: {
            excessOfAverages: 214,
            helper: 202,
            helperFloat: 203,
            indicationError: 213,
            measureIndicationHint: 201,
            outOfOrder: 210,
            transferIndication: 212,
        },
    },
    [ORL_KD_PROVIDER]: {
        addLs: {
            nmInputMask: "99999-999-99",
        },
        autoPay: false,
        bannerTabs: accountOrlTabsSettings,
        checkBalance: false,
        complaintPowerSupplyButton: 891,
        detailInfoBtnInBalanceModal: false,
        disableBannerPayBtn: false,
        faq: {
            newAppealText: "Подать обращение",
        },
        gpItemKdBElements: {
            gpError: 460,
            gpHelp: 446,
            gpNotification: 467,
            gpTooltip: 445,
        },
        logoIcon: OrlLogoIcon,
        payFormAddress: true,
        payFormKdBElements: {
            agreement: 193,
            agreementEpr: 775,
            bannerRegisterMIR: 662,
            bannerRegisterSBP: 964,
            cardPayInfo: 607,
            error: 195,
            extraPayInfo: 194,
            extraPayInfoEpr: 764,
            onboardingPay: 223,
            onboardingPayMob: 248,
            payAuto: 191,
            payAuto2: 192,
            paymentButtonText: 990,
            paymentIcons: 988,
            prePay: 197,
            prePayInfo: 196,
            sum: 198,
        },
        payFormKdSection: 44,
        payFormOnlyEmail: true,
        payFromPrepay: true,
        presentsBtn: {
            kdElement: 397,
            kdSection: 7,
        },
        profileConfirmChangeSubscr: false,
        shortName: "orlNrg",
        showPrintBtnInBalanceModal: false,
        statistics: {
            detailInfo: false,
        },
        transferIndication: {
            kdSection: 43,
            plugins: "propagateOrlInd",
        },
        transferIndicationKdBElements: {
            agreement: 188,
            helper: 189,
            helperFloat: 190,
            info: 187,
        },
        yMetrica: {
            prepay: "orl_plus1000rub_pay",
        },
    },
    [SAR_KD_PROVIDER]: {
        addLs: {
            nmInputMask: "99999-999-99",
        },
        autoPay: false,
        bannerTabs: accountSarTabsSettings,
        checkBalance: false,
        complaintPowerSupplyButton: 895,
        detailInfoBtnInBalanceModal: false,
        disableBannerPayBtn: false,
        faq: {
            newAppealText: "Задать вопрос",
        },
        gpItemKdBElements: {
            gpError: 464,
            gpHelp: 454,
            gpNotification: 471,
            gpTooltip: 453,
        },
        logoIcon: SarLogoIcon,
        payFormAddress: true,
        payFormKdBElements: {
            agreement: 317,
            agreementEpr: 781,
            bannerRegisterMIR: 667,
            bannerRegisterSBP: 969,
            error: 319,
            extraPayInfo: 318,
            extraPayInfoEpr: 770,
            onboardingPay: 223,
            onboardingPayMob: 248,
            payAuto: 315,
            payAuto2: 316,
            paymentButtonText: 909,
            paymentIcons: 906,
            prePay: 321,
            prePayInfo: 320,
            sum: 322,
        },
        payFormKdSection: 123,
        payFormOnlyEmail: true,
        payFromPrepay: true,
        presentsBtn: {
            kdElement: 402,
            kdSection: 7,
        },
        profileConfirmChangeSubscr: false,
        shortName: "sar",
        showPrintBtnInBalanceModal: false,
        statistics: {
            detailInfo: false,
        },
        transferIndication: {
            kdSection: 122,
            plugins: "propagateSarInd",
        },
        transferIndicationKdBElements: {
            agreement: 312,
            helper: 313,
            helperFloat: 314,
            info: 311,
        },
    },
    [TKO_KD_PROVIDER]: {
        autoPay: false,
        bannerTabs: accountTkoTabsSettings,
        checkBalance: true,
        detailInfoBtnInBalanceModal: false,
        disableBannerPayBtn: false,
        disableBannerTransferIndicationBtn: true,
        eventsTabs: eventsTkoTabsSettings,
        faq: {
            newAppealText: "Подать обращение",
        },
        logoIcon: TkoLogoIcon,
        logoIconBaner: TkoLogoWhiteIcon,
        payFormEdit: false,
        payFormExtraPayment: false,
        payFormKdBElements: {
            SBPPayButton: 476,
            agreement: 152,
            agreementEpr: 773,
            bannerRegisterMIR: 541,
            bannerRegisterSBP: 960,
            error: 154,
            extraPayInfo: 153,
            extraPayInfoEpr: 762,
            extraPayInfoSBP: 479,
            onboardingPay: 233,
            onboardingPayMob: 249,
            payAuto: 150,
            payInfo: 151,
            paymentIcons: 816,
            textAboveTheFirstButton: 833,
            textAboveTheSecondButton: 834,
        },
        payFormKdSection: 42,
        payFromPrepay: false,
        payStatusActionQuery: "TrashPayStatus",
        presentsBtn: {
            kdElement: 391,
            kdSection: 7,
        },
        profileConfirmChangeSubscr: false,
        shortName: "tko",
        statistics: {
            detailInfo: false,
        },
        transferIndication: {
            kdSection: 25,
            plugins: "propagateMoeInd",
        },
        transferIndicationKdBElements: {
            excessOfAverages: 36,
            indicationError: 35,
            indicationHint: 98,
            indicationWithPointHint: 105,
            measureIndicationHint: 32,
            outOfOrder: 34,
            transferIndication: 33,
        },
    },
    [TMB_KD_PROVIDER]: {
        addLs: {
            nmInputMask: "99999-999-99",
        },
        autoPay: false,
        bannerTabs: accountTmbTabsSettings,
        checkBalance: false,
        complaintPowerSupplyButton: 893,
        detailInfoBtnInBalanceModal: false,
        disableBannerPayBtn: false,
        faq: {
            newAppealText: "Подать обращение",
        },
        gpItemKdBElements: {
            gpError: 462,
            gpHelp: 450,
            gpNotification: 469,
            gpTooltip: 449,
        },
        logoIcon: TmbLogoIcon,
        payFormAddress: false,
        payFormKdBElements: {
            agreement: 289,
            agreementEpr: 778,
            bannerRegisterMIR: 665,
            bannerRegisterSBP: 967,
            error: 291,
            extraPayInfo: 290,
            extraPayInfoEpr: 767,
            onboardingPay: 223,
            onboardingPayMob: 248,
            payInfo: 288,
            paymentButtonText: 920,
            paymentIcons: 919,
            prePay: 283,
            prePayInfo: 292,
            sum: 294,
        },
        payFormKdSection: 119,
        payFormOnlyEmail: false,
        payFormServerConfig: true,
        payFromPrepay: true,
        presentsBtn: {
            kdElement: 400,
            kdSection: 7,
        },
        profileConfirmChangeSubscr: false,
        shortName: "tmb",
        showPrintBtnInBalanceModal: false,
        statistics: {
            detailInfo: false,
        },
        transferIndication: {
            kdSection: 124,
            plugins: "propagateTmbInd",
        },
        transferIndicationKdBElements: {
            agreement: 284,
            helper: 285,
            helperFloat: 286,
            info: 283,
        },
    },
    [TMK_NRG_KD_PROVIDER]: {
        checkBalance: false,
        complaintPowerSupplyButton: 888,
        faq: {
            newAppealText: "Задать вопрос",
        },
        gpItemKdBElements: {
            gpError: 457,
            gpHelp: 440,
            gpTooltip: 439,
        },
        isBytSupported: true,
        logoIcon: TomskNrgLogoIcon,
        name: "Томскэнергосбыт",
        payAvail: true,
        payFormKdBElements: {
            agreement: 86,
            agreementEpr: 774,
            bannerRegisterMIR: 542,
            bannerRegisterSBP: 961,
            extraPayInfo: 87,
            extraPayInfoEpr: 763,
            paymentFKRButton: 625,
        },
        payFormKdSection: 34,
        plugin: "tomsk",
        presentsBtn: {
            kdElement: 393,
            kdSection: 7,
        },
        profileConfirmChangeSubscr: true,
        shortName: "tmknrg",
        statistics: {
            detailInfo: false,
        },
        transferIndication: {
            kdSection: 33,
            plugins: "propagateTmkInd",
        },
        transferIndicationKdBElements: {
            helper: 100,
            helperFloat: 107,
            outOfOrder: 83,
        },
    },
    [TMK_RTS_KD_PROVIDER]: {
        checkBalance: false,
        complaintPowerSupplyButton: 888,
        faq: {
            newAppealText: "Задать вопрос",
        },
        isBytSupported: true,
        logoIcon: TomskRtsLogoIcon,
        name: "Томск РТС",
        payAvail: true,
        payFormKdBElements: {
            agreement: 86,
            agreementEpr: 774,
            bannerRegisterMIR: 542,
            bannerRegisterSBP: 961,
            extraPayInfo: 87,
            extraPayInfoEpr: 763,
            paymentFKRButton: 625,
        },
        payFormKdSection: 34,
        plugin: "tomsk",
        presentsBtn: {
            kdElement: 394,
            kdSection: 7,
        },
        profileConfirmChangeSubscr: true,
        shortName: "tmkrts",
        statistics: {
            detailInfo: false,
        },
        transferIndication: {
            kdSection: 33,
            plugins: "propagateTmkInd",
        },
        transferIndicationKdBElements: {
            helper: 100,
            helperFloat: 107,
            outOfOrder: 83,
        },
    },
    [UFA_KD_PROVIDER]: {
        addLs: {
            nmInputMask: "99999-999-99",
        },
        autoPay: false,
        bannerTabs: accountUfaTabsSettings,
        checkBalance: false,
        complaintPowerSupplyButton: 889,
        detailInfoBtnInBalanceModal: false,
        disableBannerPayBtn: false,
        faq: {
            newAppealText: "Подать обращение",
        },
        gpItemKdBElements: {
            gpError: 458,
            gpHelp: 442,
            gpNotification: 466,
            gpTooltip: 441,
        },
        logoIcon: UfaLogoIcon,
        name: "Уфа",
        payFormAddress: true,
        payFormKdBElements: {
            agreement: 126,
            agreementEpr: 780,
            bannerRegisterMIR: 661,
            bannerRegisterSBP: 962,
            error: 128,
            extraPayInfo: 127,
            extraPayInfoEpr: 769,
            onboardingPay: 223,
            onboardingPayMob: 248,
            payAuto: 124,
            payAuto2: 125,
            paymentButtonText: 907,
            paymentIcons: 904,
            prePay: 130,
            prePayInfo: 129,
            sum: 131,
        },
        payFormKdSection: 39,
        payFromPrepay: true,
        presentsBtn: {
            kdElement: 395,
            kdSection: 7,
        },
        profileConfirmChangeSubscr: false,
        shortName: "ufa",
        showPrintBtnInBalanceModal: false,
        statistics: {
            detailInfo: false,
        },
        transferIndication: {
            allLs: TRANSFER_INDICATIONS_ALL_LS_MES_KEY,
            kdSection: 38,
            plugins: "propagateUfaInd",
        },
        transferIndicationKdBElements: {
            agreement: 121,
            helper: 122,
            helperFloat: 123,
            info: 120,
        },
    },
    [VLD_KD_PROVIDER]: {
        addLs: {
            nmInputMask: "99999-999-99",
        },
        autoPay: false,
        bannerTabs: accountVldTabsSettings,
        checkBalance: false,
        complaintPowerSupplyButton: 894,
        detailInfoBtnInBalanceModal: false,
        disableBannerPayBtn: false,
        faq: {
            newAppealText: "Подать обращение",
        },
        gpItemKdBElements: {
            gpError: 463,
            gpHelp: 452,
            gpNotification: 470,
            gpTooltip: 451,
        },
        logoIcon: VldLogoIcon,
        payFormAddress: false,
        payFormKdBElements: {
            agreement: 303,
            agreementEpr: 779,
            bannerRegisterMIR: 666,
            bannerRegisterSBP: 968,
            error: 305,
            extraPayInfo: 304,
            extraPayInfoEpr: 768,
            onboardingPay: 223,
            onboardingPayMob: 248,
            payAuto: 301,
            payInfo: 302,
            paymentButtonText: 917,
            paymentIcons: 916,
            prePay: 307,
            prePayInfo: 306,
            sum: 308,
        },
        payFormKdSection: 121,
        payFormOnlyEmail: false,
        payFormServerConfig: true,
        payFromPrepay: true,
        presentsBtn: {
            kdElement: 401,
            kdSection: 7,
        },
        profileConfirmChangeSubscr: false,
        shortName: "vld",
        showPrintBtnInBalanceModal: false,
        statistics: {
            detailInfo: false,
        },
        transferIndication: {
            kdSection: 120,
            plugins: "propagateVldInd",
        },
        transferIndicationKdBElements: {
            agreement: 298,
            helper: 299,
            helperFloat: 300,
            info: 297,
        },
    },
    [VLG_KD_PROVIDER]: {
        addLs: {
            nmInputMask: "99999-999-99",
        },
        autoPay: false,
        bannerTabs: accountVlgTabsSettings,
        checkBalance: false,
        complaintPowerSupplyButton: 890,
        detailInfoBtnInBalanceModal: false,
        disableBannerPayBtn: false,
        faq: {
            newAppealText: "Подать обращение",
        },
        gpItemKdBElements: {
            gpError: 459,
            gpHelp: 444,
            gpTooltip: 443,
        },
        logoIcon: VlgLogoIcon,
        payFormAddress: true,
        payFormKdBElements: {
            agreement: 142,
            agreementEpr: 776,
            bannerRegisterMIR: 660,
            bannerRegisterSBP: 963,
            error: 144,
            extraPayInfo: 143,
            extraPayInfoEpr: 765,
            onboardingPay: 223,
            onboardingPayMob: 248,
            payAuto: 140,
            payAuto2: 141,
            paymentButtonText: 908,
            paymentIcons: 905,
            prePay: 146,
            prePayInfo: 145,
            sum: 147,
        },
        payFormKdSection: 41,
        payFormOnlyEmail: true,
        payFromPrepay: true,
        presentsBtn: {
            kdElement: 396,
            kdSection: 7,
        },
        profileConfirmChangeSubscr: false,
        shortName: "vlg",
        showPrintBtnInBalanceModal: false,
        statistics: {
            detailInfo: false,
        },
        transferIndication: {
            kdSection: 40,
            plugins: "propagateVlgInd",
        },
        transferIndicationKdBElements: {
            agreement: 137,
            helper: 138,
            helperFloat: 139,
            info: 136,
        },
    },
};

export const commonKdSections = {
    catalogMain: {
        adsBanner: {leftBanner: 50, rightBanner: 52},
    },
};
