// @flow
import React, {Component} from "react";
import {type ThemeOptions} from "@material-ui/core/styles/createMuiTheme";
import {MuiThemeProvider, createMuiTheme, withStyles} from "@material-ui/core/styles";
import {Provider} from "react-redux";
import moment from "moment";
import "moment/locale/ru";
import "core-js/stable";
import "regenerator-runtime/runtime";

import {getConfigSysSettings} from "./redux/actions/configAction";
import configureStore from "./redux/configureStore";
import {themeVars} from "./themeStyle";
import {themeIEOverridesStyle} from "./themeIEOverridesStyle";
import {themeEdgeOverridesStyle} from "./themeEdgeOverridesStyle";
import AppLoader from "./AppLoader";
import {isIE, isEdge, mergeThemeStyle} from "./utils/browserUtils";
import "./utils/injectSmartBannerIcons";

moment.locale("ru");

const styles = () => ({
    center: {
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
        maxWidth: "1920px",
        position: "relative",
        width: "100%",
    },
});

const getStyle = (): ThemeOptions => {
    if (isIE()) {
        return mergeThemeStyle(themeVars, themeIEOverridesStyle);
    }

    if (isEdge()) {
        return mergeThemeStyle(themeVars, themeEdgeOverridesStyle);
    }

    return themeVars;
};

const store = configureStore();
const themeStyle: ThemeOptions = getStyle();
const theme = createMuiTheme(themeStyle);

store.dispatch(getConfigSysSettings());

class App extends Component<*> {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Provider store={store}>
                    <AppLoader {...this.props} />
                </Provider>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(styles)(App);
