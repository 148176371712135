// @flow
import * as actionTypes from "../../constants/ActionTypes";
import {createReducer} from "../../../../redux/reducers/createReducer";

export type ModalToogleReducerType = {
    [key: string]: boolean,
};

const initialState: ModalToogleReducerType = {
    modalsPageSteps: false,
    modalsPageTable: false,
};

export const modalToogleReducer = createReducer(initialState, {
    [actionTypes.MODAL_TOOGLE_ACTION]: (state, {payload}) => ({
        ...state,
        [payload.modalName]: !state[payload.modalName],
    }),
});
