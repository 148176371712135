/* eslint-disable global-require */
import {
    MES_KD_PROVIDER,
    UFA_KD_PROVIDER,
    MOE_KD_PROVIDER,
    TKO_KD_PROVIDER,
    TMK_NRG_KD_PROVIDER,
    TMK_RTS_KD_PROVIDER,
    VLG_KD_PROVIDER,
    ORL_KD_PROVIDER,
    ORL_EPD_KD_PROVIDER,
    ALT_KD_PROVIDER,
    SAR_KD_PROVIDER,
    TMB_KD_PROVIDER,
    VLD_KD_PROVIDER,
    KSG_KD_PROVIDER,
} from "./KdProvider";
import {turnedOnProviders} from "./Providers";

export const TomskRtsLogoIcon = turnedOnProviders[TMK_RTS_KD_PROVIDER]
    ? require("../packages/components/ImageIcons/TmkRnsLogoIcon").default
    : () => null;
export const TomskNrgLogoIcon = turnedOnProviders[TMK_NRG_KD_PROVIDER]
    ? require("../packages/components/Icons/TmkNrgLogoIcon").default
    : () => null;
export const MoeLogoIcon = turnedOnProviders[MOE_KD_PROVIDER]
    ? require("../packages/components/ImageIcons/MoeLogo").default
    : () => null;
export const MesLogoIcon = turnedOnProviders[MES_KD_PROVIDER]
    ? require("../packages/components/Icons/MesLogoIcon").default
    : () => null;
export const TkoLogoIcon = turnedOnProviders[TKO_KD_PROVIDER]
    ? require("../packages/components/Icons/MesLogoIcon").default
    : () => null;
export const UfaLogoIcon = turnedOnProviders[UFA_KD_PROVIDER]
    ? require("../packages/components/Icons/UfaLogoIcon").default
    : () => null;
export const VlgLogoIcon = turnedOnProviders[VLG_KD_PROVIDER]
    ? require("../packages/components/Icons/VlgLogoIcon").default
    : () => null;
export const AltLogoIcon = turnedOnProviders[ALT_KD_PROVIDER]
    ? require("../packages/components/ImageIcons/AltLogoIcon").default
    : () => null;
export const OrlLogoIcon = turnedOnProviders[ORL_KD_PROVIDER]
    ? require("../packages/components/ImageIcons/OrlLogoIcon").default
    : () => null;
export const OrlEpdLogoIcon = turnedOnProviders[ORL_EPD_KD_PROVIDER]
    ? require("../packages/components/ImageIcons/OrlEpdLogoIcon").default
    : () => null;
export const SarLogoIcon = turnedOnProviders[SAR_KD_PROVIDER]
    ? require("../packages/components/ImageIcons/SarLogoIcon").default
    : () => null;
export const TmbLogoIcon = turnedOnProviders[TMB_KD_PROVIDER]
    ? require("../packages/components/ImageIcons/TmbLogoIcon").default
    : () => null;
export const VldLogoIcon = turnedOnProviders[VLD_KD_PROVIDER]
    ? require("../packages/components/ImageIcons/VldLogoIcon").default
    : () => null;
export const KsgLogoWhiteIcon = turnedOnProviders[KSG_KD_PROVIDER]
    ? require("../packages/components/ImageIcons/KsgLogoIcons/KsgLogoWhiteIcon").default
    : () => null;
export const KsgLogoBlueIcon = turnedOnProviders[KSG_KD_PROVIDER]
    ? require("../packages/components/ImageIcons/KsgLogoIcons/KsgLogoBlueIcon").default
    : () => null;
export const MesLogoWhiteIcon = turnedOnProviders[MES_KD_PROVIDER]
    ? require("../packages/components/ImageIcons/MesLogoWhiteIcon").default
    : () => null;
export const TkoLogoWhiteIcon = turnedOnProviders[TKO_KD_PROVIDER]
    ? require("../packages/components/ImageIcons/MesLogoWhiteIcon").default
    : () => null;
