// @flow
import * as React from "react";
import {bindActionCreators} from "redux";
import type {BrowserHistory} from "history/createBrowserHistory";
import qs from "qs";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid/Grid";
import {connect} from "react-redux";
import {
    moePayStatusAction,
    type MoePayStatusActionType,
    closeMoePayStatusInfoAction,
} from "../../redux/actions/account/moeAction";
import type {StoreType} from "../../redux/reducers";
import ModalConfirm from "../../packages/components/Modal/ModalConfirm";
import Typography from "../../packages/components/Typography/Typography";
import getFieldInstance from "../../packages/Builder/BPattern/PatternParsed";

type StoreProps = {|
    session: string,
    payStatus: Object,
    showModal: boolean,
|};

type DispatchProps = {|
    moePayStatusAction: MoePayStatusActionType,
    closeMoePayStatusInfoAction: () => void,
|};

type OwnProps = {|
    history: BrowserHistory,
    location: {
        search: string,
        pathname: string,
    },
|};

type Props = {...OwnProps, ...StoreProps, ...DispatchProps};

const queryName = {
    "/moepaystatus": "MoePayStatus",
    "/trashpaystatus": "TrashPayStatus",
};

class PayMoeResultPage extends React.Component<Props> {
    componentDidMount = async () => {
        const {location, session, history} = this.props;
        const idPayment = qs.parse(location.search, {ignoreQueryPrefix: true}).id_payment;
        const res = await this.props.moePayStatusAction(session, idPayment, queryName[location.pathname]);

        if (res.error || (res.response && res.response.kdResult !== 0)) {
            history.push("/accounts/0");
        }
    };

    closeModal = () => {
        this.props.closeMoePayStatusInfoAction();
        this.props.history.push("/accounts/0");
    };

    renderRow = (title: string, value?: string | React.Node) =>
        value ? (
            <React.Fragment>
                <Grid item xs={12}>
                    <Grid container spacing={16}>
                        <Grid item xs={4}>
                            <Typography align="left" fontSize={16} textColor="grey">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography align="right" fontSize={16}>
                                {value}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </React.Fragment>
        ) : null;

    renderBody = () => {
        const {payStatus} = this.props;
        const smPayment = getFieldInstance({
            data: {smPayment: payStatus.smPayment},
            pattern: "$0 руб.",
            src: [{format: "module", key: "smPayment", precision: 2, type: "numeric"}],
        });
        const dtPayment = getFieldInstance({
            data: {dtPayment: payStatus.dtPayment},
            pattern: "$0",
            src: [{format: "dd.mm.yyyy hh:mm:ss", key: "dtPayment", type: "datetime"}],
        });

        return (
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                {this.renderRow("Дата:", dtPayment)}
                {this.renderRow("Сумма:", smPayment)}
                {this.renderRow("Описание:", payStatus.nmDescription)}
                {this.renderRow("Внутренний номер:", payStatus.idOrderInternal)}
                {this.renderRow("Внешний номер:", payStatus.vlOrderExternal)}
                {this.renderRow("Результат оплаты:", payStatus.nmResult)}
                {this.renderRow("Код авторизации:", payStatus.vlAuthCode)}
            </Grid>
        );
    };

    render() {
        return (
            <ModalConfirm
                open={this.props.showModal}
                onCancel={this.closeModal}
                errorDialog
                title="Статус платежа"
                buttonCancelTitle="Закрыть"
                sameButtons
            >
                {this.renderBody()}
            </ModalConfirm>
        );
    }
}

const mapStoreToProps = ({auth, moe}: StoreType): StoreProps => ({
    payStatus: moe.payResult.payStatus,
    session: auth.session || "",
    showModal: moe.payResult.showModal,
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    closeMoePayStatusInfoAction: bindActionCreators(closeMoePayStatusInfoAction, dispatch),
    moePayStatusAction: bindActionCreators(moePayStatusAction, dispatch),
});

export default connect<Props, OwnProps, StoreProps, DispatchProps, StoreType, _>(
    mapStoreToProps,
    mapDispatchToProps,
)(PayMoeResultPage);
