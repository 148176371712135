// @flow

// Profile
export const DEFAULT_ACTION = "DEFAULT_ACTION";
export const DEFAULT_ACTION_SUCCESS = "DEFAULT_ACTION_SUCCESS";
export const DEFAULT_ACTION_FAILURE = "DEFAULT_ACTION_FAILURE";

export const GET_BUILDER_GROUP_ATTRIBUTES = "GET_BUILDER_GROUP_ATTRIBUTES";
export const GET_BUILDER_GROUP_ATTRIBUTES_SUCCESS = "GET_BUILDER_GROUP_ATTRIBUTES_SUCCESS";
export const GET_BUILDER_GROUP_ATTRIBUTES_FAILURE = "GET_BUILDER_GROUP_ATTRIBUTES_FAILURE";

export const PROFILE_EDIT_ACTION = "PROFILE_EDIT_ACTION";
export const PROFILE_EDIT_ACTION_SUCCESS = "PROFILE_EDIT_ACTION_SUCCESS";
export const PROFILE_EDIT_ACTION_FAILURE = "PROFILE_EDIT_ACTION_FAILURE";

export const PSW_CHANGE_ACTION = "PSW_CHANGE_ACTION";
export const PSW_CHANGE_ACTION_SUCCESS = "PSW_CHANGE_ACTION_SUCCESS";
export const PSW_CHANGE_ACTION_FAILURE = "PSW_CHANGE_ACTION_FAILURE";

export const IS_CONFIRM_ACTION = "IS_CONFIRM_ACTION";
export const IS_CONFIRM_ACTION_SUCCESS = "IS_CONFIRM_ACTION_SUCCESS";
export const IS_CONFIRM_ACTION_FAILURE = "IS_CONFIRM_ACTION_FAILURE";

export const GET_MOE_PD_SUBSCR_STATUS_ACTION = "GET_MOE_PD_SUBSCR_STATUS_ACTION";
export const GET_MOE_PD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_MOE_PD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_MOE_PD_SUBSCR_STATUS_ACTION_FAILURE = "GET_MOE_PD_SUBSCR_STATUS_ACTION_FAILURE";

export const GET_MES_PD_SUBSCR_STATUS_ACTION = "GET_MES_PD_SUBSCR_STATUS_ACTION";
export const GET_MES_PD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_MES_PD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_MES_PD_SUBSCR_STATUS_ACTION_FAILURE = "GET_MES_PD_SUBSCR_STATUS_ACTION_FAILURE";

export const GET_UFA_PD_SUBSCR_STATUS_ACTION = "GET_UFA_PD_SUBSCR_STATUS_ACTION";
export const GET_UFA_PD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_UFA_PD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_UFA_PD_SUBSCR_STATUS_ACTION_FAILURE = "GET_UFA_PD_SUBSCR_STATUS_ACTION_FAILURE";

export const GET_AD_SUBSCR_STATUS_ACTION = "GET_AD_SUBSCR_STATUS_ACTION";
export const GET_AD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_AD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_AD_SUBSCR_STATUS_ACTION_FAILURE = "GET_AD_SUBSCR_STATUS_ACTION_FAILURE";

export const SET_LS_SUBSCR_ACTION = "SET_LS_SUBSCR_ACTION";
export const SET_LS_SUBSCR_ACTION_SUCCESS = "SET_LS_SUBSCR_ACTION_SUCCESS";
export const SET_LS_SUBSCR_ACTION_FAILURE = "SET_LS_SUBSCR_ACTION_FAILURE";

export const USER_CHANGE_LS_SUBSCR_ACTION = "USER_CHANGE_LS_SUBSCR_ACTION";
export const USER_CHANGE_LS_SUBSCR_ACTION_SUCCESS = "USER_CHANGE_LS_SUBSCR_ACTION_SUCCESS";
export const USER_CHANGE_LS_SUBSCR_ACTION_FAILURE = "USER_CHANGE_LS_SUBSCR_ACTION_FAILURE";

export const USER_SET_PROFILE_SUBSCR_ACTION = "USER_SET_PROFILE_SUBSCR_ACTION";
export const USER_SET_PROFILE_SUBSCR_ACTION_SUCCESS = "USER_SET_PROFILE_SUBSCR_ACTION_SUCCESS";
export const USER_SET_PROFILE_SUBSCR_ACTION_FAILURE = "USER_SET_PROFILE_SUBSCR_ACTION_FAILURE";

export const GET_PD_SUBSCR_STATUS_ACTION = "GET_PD_SUBSCR_STATUS_ACTION";
export const GET_PD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_PD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_PD_SUBSCR_STATUS_ACTION_FAILURE = "GET_PD_SUBSCR_STATUS_ACTION_FAILURE";

export const USER_SET_PD_SUBSCR_ACTION = "USER_SET_PD_SUBSCR_ACTION";
export const USER_SET_PD_SUBSCR_ACTION_SUCCESS = "USER_SET_PD_SUBSCR_ACTION_SUCCESS";
export const USER_SET_PD_SUBSCR_ACTION_FAILURE = "USER_SET_PD_SUBSCR_ACTION_FAILURE";

export const EMAIL_SUBSCR_CHG_FLAGS_ACTION = "EMAIL_SUBSCR_CHG_FLAGS_ACTION";
export const EMAIL_SUBSCR_CHG_FLAGS_ACTION_SUCCESS = "EMAIL_SUBSCR_CHG_FLAGS_ACTION_SUCCESS";
export const EMAIL_SUBSCR_CHG_FLAGS_ACTION_FAILURE = "EMAIL_SUBSCR_CHG_FLAGS_ACTION_FAILURE";

export const GET_VLG_PD_SUBSCR_STATUS_ACTION = "GET_VLG_PD_SUBSCR_STATUS_ACTION";
export const GET_VLG_PD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_VLG_PD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_VLG_PD_SUBSCR_STATUS_ACTION_FAILURE = "GET_VLG_PD_SUBSCR_STATUS_ACTION_FAILURE";

export const GET_ALT_PD_SUBSCR_STATUS_ACTION = "GET_ALT_PD_SUBSCR_STATUS_ACTION";
export const GET_ALT_PD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_ALT_PD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_ALT_PD_SUBSCR_STATUS_ACTION_FAILURE = "GET_ALT_PD_SUBSCR_STATUS_ACTION_FAILURE";

export const GET_ORL_PD_SUBSCR_STATUS_ACTION = "GET_ORL_PD_SUBSCR_STATUS_ACTION";
export const GET_ORL_PD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_ORL_PD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_ORL_PD_SUBSCR_STATUS_ACTION_FAILURE = "GET_ORL_PD_SUBSCR_STATUS_ACTION_FAILURE";

export const GET_ORL_EPD_PD_SUBSCR_STATUS_ACTION = "GET_ORL_EPD_PD_SUBSCR_STATUS_ACTION";
export const GET_ORL_EPD_PD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_ORL_EPD_PD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_ORL_EPD_PD_SUBSCR_STATUS_ACTION_FAILURE = "GET_ORL_EPD_PD_SUBSCR_STATUS_ACTION_FAILURE";

export const GET_SAR_PD_SUBSCR_STATUS_ACTION = "GET_SAR_PD_SUBSCR_STATUS_ACTION";
export const GET_SAR_PD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_SAR_PD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_SAR_PD_SUBSCR_STATUS_ACTION_FAILURE = "GET_SAR_PD_SUBSCR_STATUS_ACTION_FAILURE";

export const GET_TMB_PD_SUBSCR_STATUS_ACTION = "GET_TMB_PD_SUBSCR_STATUS_ACTION";
export const GET_TMB_PD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_TMB_PD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_TMB_PD_SUBSCR_STATUS_ACTION_FAILURE = "GET_TMB_PD_SUBSCR_STATUS_ACTION_FAILURE";

export const GET_VLD_PD_SUBSCR_STATUS_ACTION = "GET_VLD_PD_SUBSCR_STATUS_ACTION";
export const GET_VLD_PD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_VLD_PD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_VLD_PD_SUBSCR_STATUS_ACTION_FAILURE = "GET_VLD_PD_SUBSCR_STATUS_ACTION_FAILURE";

export const GET_KSG_PD_SUBSCR_STATUS_ACTION = "GET_KSG_PD_SUBSCR_STATUS_ACTION";
export const GET_KSG_PD_SUBSCR_STATUS_ACTION_SUCCESS = "GET_KSG_PD_SUBSCR_STATUS_ACTION_SUCCESS";
export const GET_KSG_PD_SUBSCR_STATUS_ACTION_FAILURE = "GET_KSG_PD_SUBSCR_STATUS_ACTION_FAILURE";

export const GET_SUBSCR_REASONS_ACTION = "GET_SUBSCR_REASONS_ACTION";
export const GET_SUBSCR_REASONS_ACTION_SUCCESS = "GET_SUBSCR_REASONS_ACTION_SUCCESS";
export const GET_SUBSCR_REASONS_ACTION_FAILURE = "GET_SUBSCR_REASONS_ACTION_FAILURE";

export const ADDR_EDIT_FRAME_ACTION = "ADDR_EDIT_FRAME_ACTION";
export const ADDR_EDIT_FRAME_ACTION_SUCCESS = "ADDR_EDIT_FRAME_ACTION_SUCCESS";
export const ADDR_EDIT_FRAME_ACTION_FAILURE = "ADDR_EDIT_FRAME_ACTION_FAILURE";

export const ADD_EDIT_REDIRECT_ACTION = "ADD_EDIT_REDIRECT_ACTION";
export const ADD_EDIT_REDIRECT_ACTION_SUCCESS = "ADD_EDIT_REDIRECT_ACTION_SUCCESS";
export const ADD_EDIT_REDIRECT_ACTION_FAILURE = "ADD_EDIT_REDIRECT_ACTION_FAILURE";

export const SUDIR_UNLINK_USER_SYSTEMS = "SUDIR_UNLINK_USER_SYSTEMS";
export const SUDIR_UNLINK_USER_SYSTEMS_SUCCESS = "SUDIR_UNLINK_USER_SYSTEMS_SUCCESS";
export const SUDIR_UNLINK_USER_SYSTEMS_FAILURE = "SUDIR_UNLINK_USER_SYSTEMS_FAILURE";

export const LINKED_SYSTEMS_ACTION = "LINKED_SYSTEMS_ACTION";
export const LINKED_SYSTEMS_ACTION_SUCCESS = "LINKED_SYSTEMS_ACTION_SUCCESS";
export const LINKED_SYSTEMS_ACTION_FAILURE = "LINKED_SYSTEMS_ACTION_FAILURE";

export const PSW_SET_CHECK_ACTION = "PSW_SET_CHECK_ACTION";
export const PSW_SET_CHECK_ACTION_SUCCESS = "PSW_SET_CHECK_ACTION_SUCCESS";
export const PSW_SET_CHECK_ACTION_FAILURE = "PSW_SET_CHECK_ACTION_FAILURE";

export const PSW_SET_ACTION = "PSW_SET_ACTION";
export const PSW_SET_ACTION_SUCCESS = "PSW_SET_ACTION_SUCCESS";
export const PSW_SET_ACTION_FAILURE = "PSW_SET_ACTION_FAILURE";

export const CLOSE_IFRAME_MODAL_ACTION = "CLOSE_IFRAME_MODAL_ACTION";

export const GET_PASP_DETAILS_ACTION = "GET_PASP_DETAILS_ACTION";
export const GET_PASP_DETAILS_ACTION_SUCCESS = "GET_PASP_DETAILS_ACTION_SUCCESS";
export const GET_PASP_DETAILS_ACTION_FAILURE = "GET_PASP_DETAILS_ACTION_FAILURE";

export const GET_COUNTRY_LIST_ACTION = "GET_COUNTRY_LIST_ACTION";
export const GET_COUNTRY_LIST_ACTION_SUCCESS = "GET_COUNTRY_LIST_ACTION_SUCCESS";
export const GET_COUNTRY_LIST_ACTION_FAILURE = "GET_COUNTRY_LIST_ACTION_FAILURE";

export const SAVE_PASP_DETAILS_ACTION = "SAVE_PASP_DETAILS_ACTION";
export const SAVE_PASP_DETAILS_ACTION_SUCCESS = "SAVE_PASP_DETAILS_ACTION_SUCCESS";
export const SAVE_PASP_DETAILS_ACTION_FAILURE = "SAVE_PASP_DETAILS_ACTION_FAILURE";

export const GET_PASP_DETAILS_FAILED_ACTION = "GET_PASP_DETAILS_FAILED_ACTION";
export const GET_PASP_DETAILS_FAILED_ACTION_SUCCESS = "GET_PASP_DETAILS_FAILED_ACTION_SUCCESS";
export const GET_PASP_DETAILS_FAILED_ACTION_FAILURE = "GET_PASP_DETAILS_FAILED_ACTION_FAILURE";

export const UI_DELETE_UNSUBSCR = "UI_DELETE_UNSUBSCR";
export const UI_DELETE_UNSUBSCR_SUCCESS = "UI_DELETE_UNSUBSCR_SUCCESS";
export const UI_DELETE_UNSUBSCR_FAILURE = "UI_DELETE_UNSUBSCR_FAILURE";

export const UI_DELETE_PROFILE = "UI_DELETE_PROFILE";
export const UI_DELETE_PROFILE_SUCCESS = "UI_DELETE_PROFILE_SUCCESS";
export const UI_DELETE_PROFILE_FAILURE = "UI_DELETE_PROFILE_FAILURE";

export const UI_CONFIRM_DELETE_PROFILE = "UI_CONFIRM_DELETE_PROFILE";
export const UI_CONFIRM_DELETE_PROFILE_SUCCESS = "UI_CONFIRM_DELETE_PROFILE_SUCCESS";
export const UI_CONFIRM_DELETE_PROFILE_FAILURE = "UI_CONFIRM_DELETE_PROFILE_FAILURE";

export const SHOW_SETTINGS_TFA_ACTION = "SHOW_SETTINGS_TFA_ACTION";
export const SHOW_SETTINGS_TFA_ACTION_SUCCESS = "SHOW_SETTINGS_TFA_ACTION_SUCCESS";
export const SHOW_SETTINGS_TFA_ACTION_FAILURE = "SHOW_SETTINGS_TFA_ACTION_FAILURE";
