export const GET_CRITICAL_NOTICE_ACTION = "GET_CRITICAL_NOTICE_ACTION";
export const GET_CRITICAL_NOTICE_ACTION_SUCCESS = "GET_CRITICAL_NOTICE_ACTION_SUCCESS";
export const GET_CRITICAL_NOTICE_ACTION_FAILURE = "GET_CRITICAL_NOTICE_ACTION_FAILURE";

export const GET_BANNER_NOTICE_ACTION = "GET_BANNER_NOTICE_ACTION";
export const GET_BANNER_NOTICE_ACTION_SUCCESS = "GET_BANNER_NOTICE_ACTION_SUCCESS";
export const GET_BANNER_NOTICE_ACTION_FAILURE = "GET_BANNER_NOTICE_ACTION_FAILURE";

export const SET_NOTICE_READER_ACTION = "SET_NOTICE_READER_ACTION";
export const SET_NOTICE_READER_ACTION_SUCCESS = "SET_NOTICE_READER_ACTION_SUCCESS";
export const SET_NOTICE_READER_ACTION_FAILURE = "SET_NOTICE_READER_ACTION_FAILURE";

export const GET_LS_NOTICE_ACTION = "GET_LS_NOTICE_ACTION";
export const GET_LS_NOTICE_ACTION_SUCCESS = "GET_LS_NOTICE_ACTION_SUCCESS";
export const GET_LS_NOTICE_ACTION_FAILURE = "GET_LS_NOTICE_ACTION_FAILURE";

export const SET_ACTION_DATE_ACTION = "SET_ACTION_DATE_ACTION";
export const SET_ACTION_DATE_ACTION_SUCCESS = "SET_ACTION_DATE_ACTION_SUCCESS";
export const SET_ACTION_DATE_ACTION_FAILURE = "SET_ACTION_DATE_ACTION_FAILURE";

export const SET_LS_SUBSCR_EMAIL_ACTION = "SET_LS_SUBSCR_EMAIL_ACTION";
export const SET_LS_SUBSCR_EMAIL_ACTION_SUCCESS = "SET_LS_SUBSCR_EMAIL_ACTION_SUCCESS";
export const SET_LS_SUBSCR_EMAIL_ACTION_FAILURE = "SET_LS_SUBSCR_EMAIL_ACTION_FAILURE";

export const GET_LS_NOTICE_COUNT_ACTION = "GET_LS_NOTICE_COUNT_ACTION";
export const GET_LS_NOTICE_COUNT_ACTION_SUCCESS = "GET_LS_NOTICE_COUNT_ACTION_SUCCESS";
export const GET_LS_NOTICE_COUNT_ACTION_FAILURE = "GET_LS_NOTICE_COUNT_ACTION_FAILURE";

export const GET_LS_NOTICE_CRITICAL_STATUS_ACTION = "GET_LS_NOTICE_CRITICAL_STATUS_ACTION";
export const GET_LS_NOTICE_CRITICAL_STATUS_ACTION_SUCCESS = "GET_LS_NOTICE_CRITICAL_STATUS_ACTION_SUCCESS";
export const GET_LS_NOTICE_CRITICAL_STATUS_ACTION_FAILURE = "GET_LS_NOTICE_CRITICAL_STATUS_ACTION_FAILURE";

export const CLOSE_BANNER_NOTICE_MODAL_ACTION = "CLOSE_BANNER_NOTICE_MODAL_ACTION";
export const OPEN_BANNER_NOTICE_MODAL_ACTION = "OPEN_BANNER_NOTICE_MODAL_ACTION";
export const CLOSE_CRITICAL_NOTICE_MODAL_ACTION = "CLOSE_CRITICAL_NOTICE_MODAL_ACTION";
export const OPEN_CRITICAL_NOTICE_MODAL_ACTION = "OPEN_CRITICAL_NOTICE_MODAL_ACTION";

export const OPEN_NOTICE_ACTION = "OPEN_NOTICE_ACTION";

export const NOTICE_ROUTINE_ACTION = "NOTICE_ROUTINE_ACTION";
export const NOTICE_ROUTINE_ACTION_SUCCESS = "NOTICE_ROUTINE_ACTION_SUCCESS";
export const NOTICE_ROUTINE_ACTION_FAILURE = "NOTICE_ROUTINE_ACTION_FAILURE";

export const GET_LS_LIST_NOTICE_STATUS_ACTION = "GET_LS_LIST_NOTICE_STATUS_ACTION";
export const GET_LS_LIST_NOTICE_STATUS_ACTION_SUCCESS = "GET_LS_LIST_NOTICE_STATUS_ACTION_SUCCESS";
export const GET_LS_LIST_NOTICE_STATUS_ACTION_FAILURE = "GET_LS_LIST_NOTICE_STATUS_ACTION_FAILURE";

export const OPEN_INFO_MODAL_ACTION = "OPEN_INFO_MODAL_ACTION";
export const CLOSE_INFO_MODAL_ACTION = "CLOSE_INFO_MODAL_ACTION";

export const USER_SET_LS_SUBSCR_BY_EVENT_ACTION = "USER_SET_LS_SUBSCR_BY_EVENT_ACTION";
export const USER_SET_LS_SUBSCR_BY_EVENT_ACTION_SUCCESS = "USER_SET_LS_SUBSCR_BY_EVENT_ACTION_SUCCESS";
export const USER_SET_LS_SUBSCR_BY_EVENT_ACTION_FAILURE = "USER_SET_LS_SUBSCR_BY_EVENT_ACTION_FAILURE";
