// @flow
import React from "react";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {Field} from "react-final-form";
import {withStyles} from "@material-ui/core/styles";
import {accepted} from "../../validations/baseValidations";

const styles = (theme: any) => ({
    error: {
        color: theme.palette.error.main,
    },
});

export const CheckBoxField = ({config, classes, label}: any) => (
    <Field
        name={config.nmColumn}
        type="checkbox"
        validate={config.prRequired ? accepted : undefined}
        render={({input, meta}) => (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={input.checked}
                        inputProps={input}
                        color={meta.touched && meta.invalid ? "primary" : "secondary"}
                    />
                }
                label={
                    <span
                        className={meta.touched && meta.invalid ? classes.error : ""}
                        dangerouslySetInnerHTML={{__html: label}}
                    />
                }
                checked={Boolean(input.value)}
            />
        )}
    />
);

export default withStyles(styles)(CheckBoxField);
