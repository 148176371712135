// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {DEFAULT_SIZE} from "../../../constants/iconConstants";
import {type IconPropsType} from "./iconType";

export const NotificationCriticalUnreadIcon = ({fontSize, ...otherProps}: IconPropsType) => (
    <SvgIcon {...otherProps} style={{fontSize}}>
        <circle fill="#FF4141" cx="517.5" cy="517.5" r="517.5" />
        <g>
            <path
                fill="#FFFFFF"
                d={
                    "M562.6,439.2c-0.6,36.8-1.2,75.6-1.9,114.5c-0.2,10.2-0.4,20.5-0.6,30.7c0,0.8" +
                    "-0.1,1.6-0.1,2.4c-0.1,0.7-0.5,1.2-1.2,1.2c-1.1,0.1-2.2,0.1-3.3,0.1c-25.3,0-50." +
                    "5,0-75.8,0c-5,0-4.7,0.2-4.8-4.7c-0.5-30.2-1-60.4-1.5-90.6c-0.6-37.4-1.3-74.8-2-" +
                    "112.2c-0.5-29.4-1.1-58.8-1.6-88.2c0-0.5,0-1,0-1.4c0-5.9-0.6-5.6,5.3-5.6c28.5," +
                    "0,56.9,0,85.4,0c5.2,0,4.8-0.2,4.8,5.1c-0.6,32.9-1,65.9-1.6,98.8C563.4,405.2,563,421.2,562.6,439.2z"
                }
            />
            <path
                fill="#FFFFFF"
                d={
                    "M570.9,696.2c0.4,29.3-24.2,53.4-53.3,53.4c-28,0.1-53.7-22.8-53.5-53.8c0.1-30" +
                    ",24.9-53.3,53.6-53.2C547.3,642.8,571.4,667.3,570.9,696.2z"
                }
            />
        </g>
    </SvgIcon>
);

NotificationCriticalUnreadIcon.defaultProps = {
    fontSize: DEFAULT_SIZE,
    viewBox: "0 0 1031.5 1031.5",
};

export default NotificationCriticalUnreadIcon;
