// @flow

export const publickUrl = process.env.REACT_APP_PUBLIC_URL || "";
export const YANDEX_MAP_API_KEY = process.env.REACT_APP_YANDEX_MAP_KEY || "";
export const commitId = process.env.REACT_APP_COMMIT_ID || "";
export const branchName = process.env.REACT_APP_BRANCH_NAME || "";
export const branchDateTime = process.env.REACT_APP_BRANCH_DATE_TIME || "";
export const INIT_INDENT_PATH_LENGTH = 0;
export const defaultEndpoint = process.env.REACT_APP_GATE_LKCOMU || "";
export const loaderDelay = process.env.REACT_APP_LOADER_DELAY;
export const smartBannerAndroidTitle = process.env.REACT_APP_ANDROID_TITLE;
export const smartBannerIosTitle = process.env.REACT_APP_IOS_TITLE;
export const smartBannerAuthor = process.env.REACT_APP_AUTHOR || "Integrator IT LLC";
export const smartBannerAppleStoreId = process.env.REACT_APP_APPLE_STORE_ID || "id1439322951";
export const smartBannerPlayMarketId = process.env.REACT_APP_PLAY_MARKET_ID || "ru.tii.lkcomu";

// Storage keys
export const authKey = "auth";
export const menuSettingsKey = "menuSettings";
export const periodKey = "period";
export const sessionKey = "session";
export const onboardingKey = "onboarding";
export const onboardingSettingsKey = "onboardingSettings";
export const uniqueDeviceTokenKey = "udt";

export const ONE_HUNDRED = 100;

export const ONE_MB_IN_BYTES = 1048576;
