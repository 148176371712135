// @flow

import * as React from "react";
import Dialog from "../../../packages/components/Dialog/Dialog";
import DialogTitle from "../../../packages/components/Dialog/DialogTitle";
import DialogContent from "../../../packages/components/Dialog/DialogContent";
import DialogActions from "../../../packages/components/Dialog/DialogActions";
import Button from "../../../packages/components/Button/Button";
import Typography from "../../../packages/components/Typography/Typography";
import DialogCloseIcon from "../../../packages/components/Dialog/DialogCloseIcon";

type Props = {
    open: boolean,
    notifyData: Array<Object>,
    onConfirm: (idNotify: string) => void,
    onClose: (idNotify?: string) => void,
};

const NotifyModal = (props: Props) => {
    const {open, notifyData, onConfirm, onClose} = props;
    const [page, setPage] = React.useState<number>(0);
    const currentData = notifyData[page] || {};
    const handleConfirm = () => {
        onConfirm(currentData.idNotify);
        if (page < notifyData.length - 1) {
            setPage(page + 1);
        } else {
            onClose();
        }
    };

    React.useEffect(() => {
        if (open) {
            setPage(0);
        }
    }, [open]);

    return (
        <Dialog open={open} onBackdropClick={() => onClose(currentData.idNotify)}>
            <DialogTitle>{currentData.nmHeader}</DialogTitle>
            <DialogCloseIcon onClick={() => onClose(currentData.idNotify)} />
            <DialogContent>
                <Typography fontSize={16} verticalPadding={8} dangerouslySetInnerHTML={{__html: currentData.nmText}} />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" size="sm" textColor="white" onClick={handleConfirm}>
                    Согласен
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NotifyModal;
