/* eslint-disable max-lines */
// @flow

// Other
export const UPDATE_BANNER = "UPDATE_BANNER";
export const ACCOUNTS_LIST_LOAD = "ACCOUNTS_LIST_LOAD";
export const SIDE_BAR_TOGGLE_ACTION = "OPEN_CLOSE_SIDE_BAR";
export const SIDE_BAR_CHANGE_PROP_ACTION = "SIDE_BAR_CHANGE_PROP_ACTION";
export const SIDE_BAR_CLOSE_ACTION = "SIDE_BAR_CLOSE_ACTION";

export const DEFAULT_ACTION = "DEFAULT_ACTION";
export const DEFAULT_ACTION_SUCCESS = "DEFAULT_ACTION_SUCCESS";
export const DEFAULT_ACTION_FAILURE = "DEFAULT_ACTION_FAILURE";

// Auth
export const AUTH_LOGIN_ACTION = "AUTH_LOGIN_ACTION";
export const AUTH_LOGIN_ACTION_SUCCESS = "AUTH_LOGIN_ACTION_SUCCESS";
export const AUTH_LOGIN_ACTION_FAILURE = "AUTH_LOGIN_ACTION_FAILURE";

export const AUTH_REGISTRATION_ACTION = "AUTH_REGISTRATION_ACTION";
export const AUTH_REGISTRATION_ACTION_SUCCESS = "AUTH_REGISTRATION_ACTION_SUCCESS";
export const AUTH_REGISTRATION_ACTION_FAILURE = "AUTH_REGISTRATION_ACTION_FAILURE";

export const AUTH_PHONE_CONFIRM_ACTION = "AUTH_PHONE_CONFIRM_ACTION";
export const AUTH_PHONE_CONFIRM_ACTION_SUCCESS = "AUTH_PHONE_CONFIRM_ACTION_SUCCESS";
export const AUTH_PHONE_CONFIRM_ACTION_FAILURE = "AUTH_PHONE_CONFIRM_ACTION_FAILURE";

export const GET_LS_REGISTRATION_ELEMENTS_ACTION = "GET_LS_REGISTRATION_ELEMENTS_ACTION";
export const GET_LS_REGISTRATION_ELEMENTS_ACTION_SUCCESS = "GET_LS_REGISTRATION_ELEMENTS_ACTION_SUCCESS";
export const GET_LS_REGISTRATION_ELEMENTS_ACTION_FAILURE = "GET_LS_REGISTRATION_ELEMENTS_ACTION_FAILURE";

export const SEND_SMS_ONETIME_PSW_ACTION = "SEND_SMS_ONETIME_PSW_ACTION";
export const SEND_SMS_ONETIME_PSW_ACTION_SUCCESS = "SEND_SMS_ONETIME_PSW_ACTION_SUCCESS";
export const SEND_SMS_ONETIME_PSW_ACTION_FAILURE = "SEND_SMS_ONETIME_PSW_ACTION_FAILURE";

export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const AUTH_LOGOUT_ACTION = "AUTH_LOGOUT_ACTION";
export const AUTH_LOGOUT_ACTION_SUCCESS = "AUTH_LOGOUT_ACTION_SUCCESS";
export const AUTH_LOGOUT_ACTION_FAILURE = "AUTH_LOGOUT_ACTION_FAILURE";

export const OPEN_CHANGE_PSW_MODAL_ACTION = "OPEN_CHANGE_PSW_MODAL_ACTION";
export const CLOSE_CHANGE_PSW_MODAL_ACTION = "CLOSE_CHANGE_PSW_MODAL_ACTION";

export const CRM_CHANGE_PSW_ACTION = "CRM_CHANGE_PSW_ACTION";
export const CRM_CHANGE_PSW_ACTION_SUCCESS = "CRM_CHANGE_PSW_ACTION_SUCCESS";
export const CRM_CHANGE_PSW_ACTION_FAILURE = "CRM_CHANGE_PSW_ACTION_FAILURE";

export const CRM_SEND_CHECK_PHONE_ACTION = "CRM_SEND_CHECK_PHONE_ACTION";
export const CRM_SEND_CHECK_PHONE_ACTION_SUCCESS = "CRM_SEND_CHECK_PHONE_ACTION_SUCCESS";
export const CRM_SEND_CHECK_PHONE_ACTION_FAILURE = "CRM_SEND_CHECK_PHONE_ACTION_FAILURE";

export const CRM_CHECK_PHONE_CONFIRM_ACTION = "CRM_CHECK_PHONE_CONFIRM_ACTION";
export const CRM_CHECK_PHONE_CONFIRM_ACTION_SUCCESS = "CRM_CHECK_PHONE_CONFIRM_ACTION_SUCCESS";
export const CRM_CHECK_PHONE_CONFIRM_ACTION_FAILURE = "CRM_CHECK_PHONE_CONFIRM_ACTION_FAILURE";

export const CHECK_NEED_PHONE_CONFIRM_ACTION = "CHECK_NEED_PHONE_CONFIRM_ACTION";
export const CHECK_NEED_PHONE_CONFIRM_ACTION_SUCCESS = "CHECK_NEED_PHONE_CONFIRM_ACTION_SUCCESS";
export const CHECK_NEED_PHONE_CONFIRM_ACTION_FAILURE = "CHECK_NEED_PHONE_CONFIRM_ACTION_FAILURE";

export const CHECK_SUDIR_PROFILE_ACTION = "CHECK_SUDIR_PROFILE_ACTION";
export const CHECK_SUDIR_PROFILE_ACTION_SUCCESS = "CHECK_SUDIR_PROFILE_ACTION_SUCCESS";
export const CHECK_SUDIR_PROFILE_ACTION_FAILURE = "CHECK_SUDIR_PROFILE_ACTION_FAILURE";

export const SUDIR_CHECK_DIFF_ACTION = "SUDIR_CHECK_DIFF_ACTION";
export const SUDIR_CHECK_DIFF_ACTION_SUCCESS = "SUDIR_CHECK_DIFF_ACTION_SUCCESS";
export const SUDIR_CHECK_DIFF_ACTION_FAILURE = "SUDIR_CHECK_DIFF_ACTION_FAILURE";

export const SUDIR_UPDATE_USER_ACTION = "SUDIR_UPDATE_USER_ACTION";
export const SUDIR_UPDATE_USER_ACTION_SUCCESS = "SUDIR_UPDATE_USER_ACTION_SUCCESS";
export const SUDIR_UPDATE_USER_ACTION_FAILURE = "SUDIR_UPDATE_USER_ACTION_FAILURE";

export const SUDIR_NO_UPDATE_USER_ACTION = "SUDIR_NO_UPDATE_USER_ACTION";
export const SUDIR_NO_UPDATE_USER_ACTION_SUCCESS = "SUDIR_NO_UPDATE_USER_ACTION_SUCCESS";
export const SUDIR_NO_UPDATE_USER_ACTION_FAILURE = "SUDIR_NO_UPDATE_USER_ACTION_FAILURE";

export const SUDIR_REG_USER_ACTION = "SUDIR_REG_USER_ACTION";
export const SUDIR_REG_USER_ACTION_SUCCESS = "SUDIR_REG_USER_ACTION_SUCCESS";
export const SUDIR_REG_USER_ACTION_FAILURE = "SUDIR_REG_USER_ACTION_FAILURE";

export const SUDIR_LINK_USER_ACTION = "SUDIR_LINK_USER_ACTION";
export const SUDIR_LINK_USER_ACTION_SUCCESS = "SUDIR_LINK_USER_ACTION_SUCCESS";
export const SUDIR_LINK_USER_ACTION_FAILURE = "SUDIR_LINK_USER_ACTION_FAILURE";

// Phone
export const PHONE_SEND_SMS_CONFIRM_REPEAT_ACTION = "PHONE_SEND_SMS_CONFIRM_REPEAT_ACTION";
export const PHONE_SEND_SMS_CONFIRM_REPEAT_ACTION_SUCCESS = "PHONE_SEND_SMS_CONFIRM_REPEAT_ACTION_SUCCESS";
export const PHONE_SEND_SMS_CONFIRM_REPEAT_ACTION_FAILURE = "PHONE_SEND_SMS_CONFIRM_REPEAT_ACTION_FAILURE";

export const PHONE_SEND_PSW_RESTORE_SMS_ACTION = "PHONE_SEND_PSW_RESTORE_SMS_ACTION";
export const PHONE_SEND_PSW_RESTORE_SMS_ACTION_SUCCESS = "PHONE_SEND_PSW_RESTORE_SMS_ACTION_SUCCESS";
export const PHONE_SEND_PSW_RESTORE_SMS_ACTION_FAILURE = "PHONE_SEND_PSW_RESTORE_SMS_ACTION_FAILURE";

export const PHONE_PSW_RESTORE_CONFIRM_ACTION = "PHONE_PSW_RESTORE_CONFIRM_ACTION";
export const PHONE_PSW_RESTORE_CONFIRM_ACTION_SUCCESS = "PHONE_PSW_RESTORE_CONFIRM_ACTION_SUCCESS";
export const PHONE_PSW_RESTORE_CONFIRM_ACTION_FAILURE = "PHONE_PSW_RESTORE_CONFIRM_ACTION_FAILURE";

export const PHONE_CHANGE_CONFIRM_ACTION = "PHONE_CHANGE_CONFIRM_ACTION";
export const PHONE_CHANGE_CONFIRM_ACTION_SUCCESS = "PHONE_CHANGE_CONFIRM_ACTION_SUCCESS";
export const PHONE_CHANGE_CONFIRM_ACTION_FAILURE = "PHONE_CHANGE_CONFIRM_ACTION_FAILURE";

export const SEND_SMS_PHONE_CHANGE_REPEAT_WO_AUTH_ACTION = "SEND_SMS_PHONE_CHANGE_REPEAT_WO_AUTH_ACTION";
export const SEND_SMS_PHONE_CHANGE_REPEAT_WO_AUTH_ACTION_SUCCESS =
    "SEND_SMS_PHONE_CHANGE_REPEAT_WO_AUTH_ACTION_SUCCESS";
export const SEND_SMS_PHONE_CHANGE_REPEAT_WO_AUTH_ACTION_FAILURE =
    "SEND_SMS_PHONE_CHANGE_REPEAT_WO_AUTH_ACTION_FAILURE";

export const PHONE_CHANGE_CONFIRM_WO_AUTH_ACTION = "PHONE_CHANGE_CONFIRM_WO_AUTH_ACTION";
export const PHONE_CHANGE_CONFIRM_WO_AUTH_ACTION_SUCCESS = "PHONE_CHANGE_CONFIRM_WO_AUTH_ACTION_SUCCESS";
export const PHONE_CHANGE_CONFIRM_WO_AUTH_ACTION_FAILURE = "PHONE_CHANGE_CONFIRM_WO_AUTH_ACTION_FAILURE";

export const SEND_SMS_PHONE_CHANGE_REPEAT_ACTION = "SEND_SMS_PHONE_CHANGE_REPEAT_ACTION";
export const SEND_SMS_PHONE_CHANGE_REPEAT_ACTION_SUCCESS = "SEND_SMS_PHONE_CHANGE_REPEAT_ACTION_SUCCESS";
export const SEND_SMS_PHONE_CHANGE_REPEAT_ACTION_FAILURE = "SEND_SMS_PHONE_CHANGE_REPEAT_ACTION_FAILURE";

// Email
export const SEND_EMAIL_CONFIRM_REPEAT_ACTION = "SEND_EMAIL_CONFIRM_REPEAT_ACTION";
export const SEND_EMAIL_CONFIRM_REPEAT_ACTION_SUCCESS = "SEND_EMAIL_CONFIRM_REPEAT_ACTION_SUCCESS";
export const SEND_EMAIL_CONFIRM_REPEAT_ACTION_FAILURE = "SEND_EMAIL_CONFIRM_REPEAT_ACTION_FAILURE";

export const EMAIL_CONFIRM_ACTION = "EMAIL_CONFIRM_ACTION";
export const EMAIL_CONFIRM_ACTION_SUCCESS = "EMAIL_CONFIRM_ACTION_SUCCESS";
export const EMAIL_CONFIRM_ACTION_FAILURE = "EMAIL_CONFIRM_ACTION_FAILURE";

export const EMAIL_CHANGE_CONFIRM_ACTION = "EMAIL_CHANGE_CONFIRM_ACTION";
export const EMAIL_CHANGE_CONFIRM_ACTION_SUCCESS = "EMAIL_CHANGE_CONFIRM_ACTION_SUCCESS";
export const EMAIL_CHANGE_CONFIRM_ACTION_FAILURE = "EMAIL_CHANGE_CONFIRM_ACTION_FAILURE";

// Builder Account
export const GET_BUILDER_ACCOUNT_METADATA = "GET_BUILDER_ACCOUNT_METADATA";
export const GET_BUILDER_ACCOUNT_METADATA_SUCCESS = "GET_BUILDER_ACCOUNT_METADATA_SUCCESS";
export const GET_BUILDER_ACCOUNT_METADATA_FAILURE = "GET_BUILDER_ACCOUNT_METADATA_FAILURE";

// Builder
export const GET_BUILDER_ATTRIBUTES = "GET_BUILDER_ATTRIBUTES";
export const GET_BUILDER_ATTRIBUTES_SUCCESS = "GET_BUILDER_ATTRIBUTES_SUCCESS";
export const GET_BUILDER_ATTRIBUTES_FAILURE = "GET_BUILDER_ATTRIBUTES_FAILURE";

export const GET_BUILDER_ELEMENTS = "GET_BUILDER_ELEMENTS";
export const GET_BUILDER_ELEMENTS_SUCCESS = "GET_BUILDER_ELEMENTS_SUCCESS";
export const GET_BUILDER_ELEMENTS_FAILURE = "GET_BUILDER_ELEMENTS_FAILURE";

// Config
export const GET_CONFIG_SYS_SETTINGS = "GET_CONFIG_SYS_SETTINGS";
export const GET_CONFIG_SYS_SETTINGS_SUCCESS = "GET_CONFIG_SYS_SETTINGS_SUCCESS";
export const GET_CONFIG_SYS_SETTINGS_FAILURE = "GET_CONFIG_SYS_SETTINGS_FAILURE";

// Email
export const EMAIL_SEND_PSW_RESTORE_ACTION = "EMAIL_SEND_PSW_RESTORE_ACTION";
export const EMAIL_SEND_PSW_RESTORE_ACTION_SUCCESS = "EMAIL_SEND_PSW_RESTORE_ACTION_SUCCESS";
export const EMAIL_SEND_PSW_RESTORE_ACTION_FAILURE = "EMAIL_SEND_PSW_RESTORE_ACTION_FAILURE";

export const EMAIL_PSW_RESTORE_CONFIRM_ACTION = "EMAIL_PSW_RESTORE_CONFIRM_ACTION";
export const EMAIL_PSW_RESTORE_CONFIRM_ACTION_SUCCESS = "EMAIL_PSW_RESTORE_CONFIRM_ACTION_SUCCESS";
export const EMAIL_PSW_RESTORE_CONFIRM_ACTION_FAILURE = "EMAIL_PSW_RESTORE_CONFIRM_ACTION_FAILURE";

// SystemErrors
export const SET_SYSTEM_ERROR_ACTION = "SET_SYSTEM_ERROR_ACTION";
export const SYSTEM_ERROR_CLOSE_MESSAGE_ACTION = "SYSTEM_ERROR_CLOSE_MESSAGE_ACTION";

// Account actions
export const GET_ACCOUNT_CONTACT_PHONE_ACTION = "GET_ACCOUNT_CONTACT_PHONE_ACTION";
export const GET_ACCOUNT_CONTACT_PHONE_ACTION_SUCCESS = "GET_ACCOUNT_CONTACT_PHONE_ACTION_SUCCESS";
export const GET_ACCOUNT_CONTACT_PHONE_ACTION_FAILURE = "GET_ACCOUNT_CONTACT_PHONE_ACTION_FAILURE";

// LS
export const GET_LS_LIST = "GET_LS_LIST";
export const GET_LS_LIST_SUCCESS = "GET_LS_LIST_SUCCESS";
export const GET_LS_LIST_FAILURE = "GET_LS_LIST_FAILURE";

export const GET_MAP_PROVIDER_LIST = "GET_MAP_PROVIDER_LIST";
export const GET_MAP_PROVIDER_LIST_SUCCESS = "GET_MAP_PROVIDER_LIST_SUCCESS";
export const GET_MAP_PROVIDER_LIST_FAILURE = "GET_MAP_PROVIDER_LIST_FAILURE";

export const LS_DELETE = "LS_DELETE";
export const LS_DELETE_SUCCESS = "LS_DELETE_SUCCESS";
export const LS_DELETE_FAILURE = "LS_DELETE_FAILURE";

export const LS_TRANSFER_INDICATION_ACTION = "LS_TRANSFER_INDICATION_ACTION";
export const LS_TRANSFER_INDICATION_ACTION_SUCCESS = "LS_TRANSFER_INDICATION_ACTION_SUCCESS";
export const LS_TRANSFER_INDICATION_ACTION_FAILURE = "LS_TRANSFER_INDICATION_ACTION_FAILURE";

export const CLOSE_MODAL_ACTION = "CLOSE_MODAL_ACTION";

export const LS_LOAD_DATA = "LS_LOAD_DATA";
export const LS_LOAD_DATA_SUCCESS = "LS_LOAD_DATA_SUCCESS";
export const LS_LOAD_DATA_FAILURE = "LS_LOAD_DATA_FAILURE";
export const LS_LOAD_DATA_MULTIPLY_SUCCESS = "LS_LOAD_DATA_MULTIPLY_SUCCESS";

export const LS_LOAD_INDICATOR_DATA_ACTION = "LS_LOAD_INDICATOR_DATA_ACTION";
export const LS_LOAD_INDICATOR_DATA_ACTION_SUCCESS = "LS_LOAD_INDICATOR_DATA_ACTION_SUCCESS";
export const LS_LOAD_INDICATOR_DATA_ACTION_FAILURE = "LS_LOAD_INDICATOR_DATA_ACTION_FAILURE";

export const LS_CHECK_INDICATOR_CORRECTION_ACTION = "LS_CHECK_INDICATOR_CORRECTION_ACTION";
export const LS_CHECK_INDICATOR_CORRECTION_ACTION_SUCCESS = "LS_CHECK_INDICATOR_CORRECTION_ACTION_SUCCESS";
export const LS_CHECK_INDICATOR_CORRECTION_ACTION_FAILURE = "LS_CHECK_INDICATOR_CORRECTION_ACTION_FAILURE";

export const LS_CHECK_INDICATOR_CORRECTION_REQUEST_ACTION = "LS_CHECK_INDICATOR_CORRECTION_REQUEST_ACTION";
export const LS_CHECK_INDICATOR_CORRECTION_REQUEST_ACTION_SUCCESS =
    "LS_CHECK_INDICATOR_CORRECTION_REQUEST_ACTION_SUCCESS";
export const LS_CHECK_INDICATOR_CORRECTION_REQUEST_ACTION_FAILURE =
    "LS_CHECK_INDICATOR_CORRECTION_REQUEST_ACTION_FAILURE";

export const LS_LOAD_DATA_MAP = "LS_LOAD_DATA_MAP";
export const LS_LOAD_DATA_MAP_SUCCESS = "LS_LOAD_DATA_MAP_SUCCESS";
export const LS_LOAD_DATA_MAP_FAILURE = "LS_LOAD_DATA_MAP_FAILURE";

export const GET_LS_GROUPS = "GET_LS_GROUPS";
export const GET_LS_GROUPS_SUCCESS = "GET_LS_GROUPS_SUCCESS";
export const GET_LS_GROUPS_FAILURE = "GET_LS_GROUPS_FAILURE";

export const LS_SET_GROUP = "LS_SET_GROUP";
export const LS_SET_GROUP_SUCCESS = "LS_SET_GROUP_SUCCESS";
export const LS_SET_GROUP_FAILURE = "GLS_SET_GROUP_FAILURE";

export const LS_EDIT_ACTION = "LS_EDIT_ACTION";
export const LS_EDIT_ACTION_SUCCESS = "LS_EDIT_ACTION_SUCCESS";
export const LS_EDIT_ACTION_FAILURE = "LS_EDIT_ACTION_FAILURE";

export const LS_SELECT_PERIOD_ACTION = "LS_SELECT_PERIOD_ACTION";

export const LS_PAY_ACTION = "LS_PAY_ACTION";
export const LS_PAY_ACTION_SUCCESS = "LS_PAY_ACTION_SUCCESS";
export const LS_PAY_ACTION_FAILURE = "LS_PAY_ACTION_FAILURE";

export const PAYMENT_V_ACTION = "PAYMENT_V_ACTION";
export const PAYMENT_V_ACTION_SUCCESS = "PAYMENT_V_ACTION_SUCCESS";
export const PAYMENT_V_ACTION_FAILURE = "PAYMENT_V_ACTION_FAILURE";

export const MOE_PAY_STATUS_ACTION = "MOE_PAY_STATUS_ACTION";
export const MOE_PAY_STATUS_ACTION_SUCCESS = "MOE_PAY_STATUS_ACTION_SUCCESS";
export const MOE_PAY_STATUS_ACTION_FAILURE = "MOE_PAY_STATUS_ACTION_FAILURE";

export const CLOSE_MOE_PAY_STATUS_INFO_ACTION = "CLOSE_MOE_PAY_STATUS_INFO_ACTION";

export const CLEAR_ACCOUNT_DETAIL_ACTION = "CLEAR_ACCOUNT_DETAIL_ACTION";
export const CLEAR_LOCAL_ACCOUNT_DETAIL_ACTION = "CLEAR_LOCAL_ACCOUNT_DETAIL_ACTION";

export const GET_PRINT_BILL_LINK_ACTION = "GET_PRINT_BILL_LINK_ACTION";
export const GET_PRINT_BILL_LINK_ACTION_SUCCESS = "GET_PRINT_BILL_LINK_ACTION_SUCCESS";
export const GET_PRINT_BILL_LINK_ACTION_FAILURE = "GET_PRINT_BILL_LINK_ACTION_FAILURE";

export const LS_SAVE_DESCRIPTION_ACTION = "LS_SAVE_DESCRIPTION_ACTION";
export const LS_SAVE_DESCRIPTION_ACTION_SUCCESS = "LS_SAVE_DESCRIPTION_ACTION_SUCCESS";
export const LS_SAVE_DESCRIPTION_ACTION_FAILURE = "LS_SAVE_DESCRIPTION_ACTION_FAILURE";

// Fetching
export const INCREMENT_FETCHING_ACTION = "INCREMENT_FETCHING_ACTION";
export const DECREMENT_FETCHING_ACTION = "DECREMENT_FETCHING_ACTION";

// Storage Actions

// Session Storage Action
export const SET_SESSION_STORAGE_ACTION = "SET_SESSION_STORAGE_ACTION";
export const GET_SESSION_STORAGE_ACTION = "GET_SESSION_STORAGE_ACTION";
export const SESSION_STORAGE_REMOVE_ITEM_ACTION = "SESSION_STORAGE_REMOVE_ITEM_ACTION";
export const CLEAR_SESSION_STORAGE_ACTION = "CLEAR_SESSION_STORAGE_ACTION";

// Locale Storage Action
export const SET_LOCALE_STORAGE_ACTION = "SET_LOCALE_STORAGE_ACTION";
export const GET_LOCALE_STORAGE_ACTION = "GET_LOCALE_STORAGE_ACTION";
export const LOCALE_STORAGE_REMOVE_ITEM_ACTION = "LOCALE_STORAGE_REMOVE_ITEM_ACTION";
export const CLEAR_LOCALE_STORAGE_ACTION = "CLEAR_LOCALE_STORAGE_ACTION";

// Cookies Storage Action
export const SET_COOKIE_ACTION = "SET_COOKIE_ACTION";
export const GET_COOKIE_ACTION = "GET_COOKIE_ACTION";
export const SET_SESSION_COOKIE_ACTION = "SET_SESSION_COOKIE_ACTION";
export const COOKIE_REMOVE_ITEM_ACTION = "COOKIE_REMOVE_ITEM_ACTION";

// FAQ
export const GET_FAQ_TAB_INFO_ACTION = "GET_FAQ_TAB_INFO_ACTION";
export const GET_FAQ_TAB_INFO_ACTION_SUCCESS = "GET_FAQ_TAB_INFO_ACTION_SUCCESS";
export const GET_FAQ_TAB_INFO_ACTION_FAILURE = "GET_FAQ_TAB_INFO_ACTION_FAILURE";

export const GET_FAQ_LIST_ACTION = "GET_FAQ_LIST_ACTION";
export const GET_FAQ_LIST_ACTION_SUCCESS = "GET_FAQ_LIST_ACTION_SUCCESS";
export const GET_FAQ_LIST_ACTION_FAILURE = "GET_FAQ_LIST_ACTION_FAILURE";

// All events mes
export const ALL_EVENTS_ACTION = "ALL_EVENTS_ACTION";
export const ALL_EVENTS_AGGREGATION_ACTION = "ALL_EVENTS_AGGREGATION_ACTION";

// Upload file
export const UPLOAD_FILE_ACTION = "UPLOAD_FILE_ACTION";
export const UPLOAD_FILE_ACTION_SUCCESS = "UPLOAD_FILE_ACTION_SUCCESS";
export const UPLOAD_FILE_ACTION_FAILURE = "UPLOAD_FILE_ACTION_FAILURE";

export const CLEAR_FILE_ACTION = "CLEAR_FILE_ACTION";
export const CLEAR_FILE_ACTION_SUCCESS = "CLEAR_FILE_ACTION_SUCCESS";
export const CLEAR_FILE_ACTION_FAILURE = "CLEAR_FILE_ACTION_FAILURE";

export const DELETE_FILE_ACTION = "DELETE_FILE_ACTION";
export const DELETE_FILE_ACTION_SUCCESS = "DELETE_FILE_ACTION_SUCCESS";
export const DELETE_FILE_ACTION_FAILURE = "DELETE_FILE_ACTION_FAILURE";

export const SEND_FILE_ACTION = "SEND_FILE_ACTION";
export const SEND_FILE_ACTION_SUCCESS = "SEND_FILE_ACTION_SUCCESS";
export const SEND_FILE_ACTION_FAILURE = "SEND_FILE_ACTION_FAILURE";

// ApplicationFormAction
export const GET_PROVIDER_LIST_ACTION = "GET_PROVIDER_LIST_ACTION";
export const GET_PROVIDER_LIST_ACTION_SUCCESS = "GET_PROVIDER_LIST_ACTION_SUCCESS";
export const GET_PROVIDER_LIST_ACTION_FAILURE = "GET_PROVIDER_LIST_ACTION_FAILURE";

export const GET_SERVICE_LIST_ACTION = "GET_SERVICE_LIST_ACTION";
export const GET_SERVICE_LIST_ACTION_SUCCESS = "GET_SERVICE_LIST_ACTION_SUCCESS";
export const GET_SERVICE_LIST_ACTION_FAILURE = "GET_SERVICE_LIST_ACTION_FAILURE";

export const SEND_APPLICATION_FORM_DATA_ACTION = "SEND_APPLICATION_FORM_DATA_ACTION";
export const SEND_APPLICATION_FORM_DATA_ACTION_SUCCESS = "SEND_APPLICATION_FORM_DATA_ACTION_SUCCESS";
export const SEND_APPLICATION_FORM_DATA_ACTION_FAILURE = "SEND_APPLICATION_FORM_DATA_ACTION_FAILURE";

export const GET_SERVICE_ATTR_ACTION = "GET_SERVICE_ATTR_ACTION";
export const GET_SERVICE_ATTR_ACTION_SUCCESS = "GET_SERVICE_ATTR_ACTION_SUCCESS";
export const GET_SERVICE_ATTR_ACTION_FAILURE = "GET_SERVICE_ATTR_ACTION_FAILURE";

export const CHANGE_PROVIDER_ACTION = "CHANGE_PROVIDER_ACTION";
export const OPEN_APPLICATION_FORM_MODAL_ACTION = "OPEN_APPLICATION_FORM_MODAL_ACTION";
export const CLOSE_APPLICATION_FORM_MODAL_ACTION = "CLOSE_APPLICATION_FORM_MODAL_ACTION";

export const CRM_GET_SERVICE_HISTORY_ACTION = "CRM_GET_SERVICE_HISTORY_ACTION";
export const CRM_GET_SERVICE_HISTORY_ACTION_SUCCESS = "CRM_GET_SERVICE_HISTORY_ACTION_SUCCESS";
export const CRM_GET_SERVICE_HISTORY_ACTION_FAILURE = "CRM_GET_SERVICE_HISTORY_ACTION_FAILURE";

export const CRM_GET_FILE_ACTION = "CRM_GET_FILE_ACTION";
export const CRM_GET_FILE_ACTION_SUCCESS = "CRM_GET_FILE_ACTION_SUCCESS";
export const CRM_GET_FILE_ACTION_FAILURE = "CRM_GET_FILE_ACTION_FAILURE";

export const CRM_SET_MSG_READED_ACTION = "CRM_SET_MSG_READED_ACTION";
export const CRM_SET_MSG_READED_ACTION_SUCCESS = "CRM_SET_MSG_READED_ACTION_SUCCESS";
export const CRM_SET_MSG_READED_ACTION_FAILURE = "CRM_SET_MSG_READED_ACTION_FAILURE";

export const REMOVE_SESSION_ACTION = "REMOVE_SESSION_ACTION";

export const AUTH_INIT_ACTION = "AUTH_INIT_ACTION";
export const AUTH_INIT_ACTION_SUCCESS = "AUTH_INIT_ACTION_SUCCESS";
export const AUTH_INIT_ACTION_FAILURE = "AUTH_INIT_ACTION_FAILURE";

export const SEND_TFA_ACTION_SUCCESS = "SEND_TFA_ACTION_SUCCESS";
export const SEND_TFA_ACTION_FAILURE = "SEND_TFA_ACTION_FAILURE";

export const RESEND_TFA_ACTION_SUCCESS = "RESEND_TFA_ACTION_SUCCESS";

export const GET_MENU_SETTINGS_ACTION = "GET_MENU_SETTINGS_ACTION";
export const GET_MENU_SETTINGS_ACTION_SUCCESS = "GET_MENU_SETTINGS_ACTION_SUCCESS";
export const GET_MENU_SETTINGS_ACTION_FAILURE = "GET_MENU_SETTINGS_ACTION_FAILURE";

export const GET_APPLICATION_FORM_LS_LIST_ACTION = "GET_APPLICATION_FORM_LS_LIST_ACTION";
export const GET_APPLICATION_FORM_LS_LIST_ACTION_SUCCESS = "GET_APPLICATION_FORM_LS_LIST_ACTION_SUCCESS";
export const GET_APPLICATION_FORM_LS_LIST_ACTION_FAILURE = "GET_APPLICATION_FORM_LS_LIST_ACTION_FAILURE";

export const OPEN_EXTRA_REGISTRATION_FORM_ACTION = "OPEN_EXTRA_REGISTRATION_FORM_ACTION";
export const CLOSE_EXTRA_REGISTRATION_FORM_ACTION = "CLOSE_EXTRA_REGISTRATION_FORM_ACTION";

export const EXTRA_REGISTRATION_PROFILE_EDIT_ACTION = "EXTRA_REGISTRATION_PROFILE_EDIT_ACTION";
export const EXTRA_REGISTRATION_PROFILE_EDIT_ACTION_SUCCESS = "EXTRA_REGISTRATION_PROFILE_EDIT_ACTION_SUCCESS";
export const EXTRA_REGISTRATION_PROFILE_EDIT_ACTION_FAILURE = "EXTRA_REGISTRATION_PROFILE_EDIT_ACTION_FAILURE";

export const OPEN_CONFIRM_EMAIL_OR_SMS_MODAL_ACTION = "OPEN_CONFIRM_EMAIL_OR_SMS_MODAL_ACTION";
export const CLOSE_CONFIRM_EMAIL_OR_SMS_MODAL_ACTION = "CLOSE_CONFIRM_EMAIL_OR_SMS_MODAL_ACTION";

export const GET_PREPAY_PARAMS = "GET_PREPAY_PARAMS";
export const GET_PREPAY_PARAMS_SUCCESS = "GET_PREPAY_PARAMS_SUCCESS";
export const GET_PREPAY_PARAMS_FAILURE = "GET_PREPAY_PARAMS_FAILURE";

export const CRM_SERVICE_FAQ_LIST_ACTION = "CRM_SERVICE_FAQ_LIST_ACTION";
export const CRM_SERVICE_FAQ_LIST_ACTION_SUCCESS = "CRM_SERVICE_FAQ_LIST_ACTION_SUCCESS";
export const CRM_SERVICE_FAQ_LIST_ACTION_FAILURE = "CRM_SERVICE_FAQ_LIST_ACTION_FAILURE";

export const LOAD_PAY_ADDRES_ACTION = "LOAD_PAY_ADDRES_ACTION";
export const LOAD_PAY_ADDRES_ACTION_SUCCESS = "LOAD_PAY_ADDRES_ACTION_SUCCESS";
export const LOAD_PAY_ADDRES_ACTION_FAILURE = "LOAD_PAY_ADDRES_ACTION_FAILURE";

export const BACK_URL_ACTION = "BACK_URL_ACTION";
export const BACK_URL_ACTION_SUCCESS = "BACK_URL_ACTION_SUCCESS";
export const BACK_URL_ACTION_FAILURE = "BACK_URL_ACTION_FAILURE";

export const INDICATION_AND_PAY_ACTION = "INDICATION_AND_PAY_ACTION";
export const INDICATION_AND_PAY_ACTION_SUCCESS = "INDICATION_AND_PAY_ACTION_SUCCESS";
export const INDICATION_AND_PAY_ACTION_FAILURE = "INDICATION_AND_PAY_ACTION_FAILURE";

export const EMAIL_SEND_PSW_CHECK_ACTION = "EMAIL_SEND_PSW_CHECK_ACTION";
export const EMAIL_SEND_PSW_CHECK_ACTION_SUCCESS = "EMAIL_SEND_PSW_CHECK_ACTION_SUCCESS";
export const EMAIL_SEND_PSW_CHECK_ACTION_FAILURE = "EMAIL_SEND_PSW_CHECK_ACTION_FAILURE";

export const ACCOUNT_LOAD_ACTION = "ACCOUNT_LOAD_ACTION";
export const ACCOUNT_LOAD_ACTION_SUCCESS = "ACCOUNT_LOAD_ACTION_SUCCESS";
export const ACCOUNT_LOAD_ACTION_FAILURE = "ACCOUNT_LOAD_ACTION_FAILURE";

export const PROVIDER_TAB_ACTION = "PROVIDER_TAB_ACTION";
export const PROVIDER_TAB_ACTION_SUCCESS = "PROVIDER_TAB_ACTION_SUCCESS";
export const PROVIDER_TAB_ACTION_FAILURE = "PROVIDER_TAB_ACTION";

export const PRIVILEGES_AVAIL_ACTION = "PRIVILEGES_AVAIL_ACTION";
export const PRIVILEGES_AVAIL_ACTION_SUCCESS = "PRIVILEGES_AVAIL_ACTION_SUCCESS";
export const PRIVILEGES_AVAIL_ACTION_FAILURE = "PRIVILEGES_AVAIL_ACTION_FAILURE";

export const PRIVILEGES_DAY_AVAIL_ACTION = "PRIVILEGES_DAY_AVAIL_ACTION";
export const PRIVILEGES_DAY_AVAIL_ACTION_SUCCESS = "PRIVILEGES_DAY_AVAIL_ACTION_SUCCESS";
export const PRIVILEGES_DAY_AVAIL_ACTION_FAILURE = "PRIVILEGES_DAY_AVAIL_ACTION_FAILURE";

export const PIVELEGES_CREATE_FRAME_ACTION = "PIVELEGES_CREATE_FRAME_ACTION";
export const PIVELEGES_CREATE_FRAME_ACTION_SUCCESS = "PIVELEGES_CREATE_FRAME_ACTION_SUCCESS";
export const PIVELEGES_CREATE_FRAME_ACTION_FAILURE = "PIVELEGES_CREATE_FRAME_ACTION_FAILURE";

export const PRIVILEGES_REDIRECT_ACTION = "PRIVILEGES_REDIRECT_ACTION";
export const PRIVILEGES_REDIRECT_ACTION_SUCCESS = "PRIVILEGES_REDIRECT_ACTION_SUCCESS";
export const PRIVILEGES_REDIRECT_ACTION_FAILURE = "PRIVILEGES_REDIRECT_ACTION_FAILURE";

export const CRM_GET_PRIVILEGES_HISTORY_ACTION = "CRM_GET_PRIVILEGES_HISTORY_ACTION";
export const CRM_GET_PRIVILEGES_HISTORY_ACTION_SUCCESS = "CRM_GET_PRIVILEGES_HISTORY_ACTION_SUCCESS";
export const CRM_GET_PRIVILEGES_HISTORY_ACTION_FAILURE = "CRM_GET_PRIVILEGES_HISTORY_ACTION_FAILURE";

export const PIVELEGES_EDIT_FRAME_ACTION = "PIVELEGES_EDIT_FRAME_ACTION";
export const PIVELEGES_EDIT_FRAME_ACTION_SUCCESS = "PIVELEGES_EDIT_FRAME_ACTION_SUCCESS";
export const PIVELEGES_EDIT_FRAME_ACTION_FAILURE = "PIVELEGES_EDIT_FRAME_ACTION_FAILURE";

export const GET_PRIVILEGES_ACTION = "GET_PRIVILEGES_ACTION";
export const GET_PRIVILEGES_ACTION_SUCCESS = "GET_PRIVILEGES_ACTION_SUCCESS";
export const GET_PRIVILEGES_ACTION_FAILURE = "GET_PRIVILEGES_ACTION_FAILURE";

export const CRM_GET_QUALITY_ATTRIBUTES_ACTION = "CRM_GET_QUALITY_ATTRIBUTES_ACTION";
export const CRM_GET_QUALITY_ATTRIBUTES_ACTION_SUCCESS = "CRM_GET_QUALITY_ATTRIBUTES_ACTION_SUCCESS";
export const CRM_GET_QUALITY_ATTRIBUTES_ACTION_FAILURE = "CRM_GET_QUALITY_ATTRIBUTES_ACTION_FAILURE";

export const CRM_SEVE_QUALITY_CONTROLS_ACTION = "CRM_SEVE_QUALITY_CONTROLS_ACTION";
export const CRM_SEVE_QUALITY_CONTROLS_ACTION_SUCCESS = "CRM_SEVE_QUALITY_CONTROLS_ACTION_SUCCESS";
export const CRM_SEVE_QUALITY_CONTROLS_ACTION_FAILURE = "CRM_SEVE_QUALITY_CONTROLS_ACTION_FAILURE";

export const GET_COUNTER_STATISTIC_FRAME_AVAIL_ACTION = "GET_COUNTER_STATISTIC_FRAME_AVAIL_ACTION";
export const GET_COUNTER_STATISTIC_FRAME_AVAIL_ACTION_SUCCESS = "GET_COUNTER_STATISTIC_FRAME_AVAIL_ACTION_SUCCESS";
export const GET_COUNTER_STATISTIC_FRAME_AVAIL_ACTION_FAILURE = "GET_COUNTER_STATISTIC_FRAME_AVAIL_ACTION_FAILURE";

export const GET_COUNTER_STATISTIC_FRAME_ACTION = "GET_COUNTER_STATISTIC_FRAME_ACTION";
export const GET_COUNTER_STATISTIC_FRAME_ACTION_SUCCESS = "GET_COUNTER_STATISTIC_FRAME_ACTION_SUCCESS";
export const GET_COUNTER_STATISTIC_FRAME_ACTION_FAILURE = "GET_COUNTER_STATISTIC_FRAME_ACTION_FAILURE";

export const SAVE_PAYMENT_SUM_TO_STORE_ACTION = "SAVE_PAYMENT_SUM_TO_STORE_ACTION";

export const GET_MULTIPLE_LS_PAY_VISIBILITY_ACTION = "GET_MULTIPLE_LS_PAY_VISIBILITY_ACTION";
export const GET_MULTIPLE_LS_PAY_VISIBILITY_ACTION_SUCCESS = "GET_MULTIPLE_LS_PAY_VISIBILITY_ACTION_SUCCESS";
export const GET_MULTIPLE_LS_PAY_VISIBILITY_ACTION_FAILURE = "GET_MULTIPLE_LS_PAY_VISIBILITY_ACTION_FAILURE";

export const GET_MULTIPLE_LS_PAY_RESTRICTIONS_ACTION = "GET_MULTIPLE_LS_PAY_RESTRICTIONS_ACTION";
export const GET_MULTIPLE_LS_PAY_RESTRICTIONS_ACTION_SUCCESS = "GET_MULTIPLE_LS_PAY_RESTRICTIONS_ACTION_SUCCESS";
export const GET_MULTIPLE_LS_PAY_RESTRICTIONS_ACTION_FAILURE = "GET_MULTIPLE_LS_PAY_RESTRICTIONS_ACTION_FAILURE";

export const SET_PAY_ALL_ACCOUNTS_SUM_ACTION = "SET_PAY_ALL_ACCOUNTS_SUM_ACTION";
export const SET_PAY_ALL_ACCOUNTS_SUM_ACTION_SUCCESS = "SET_PAY_ALL_ACCOUNTS_SUM_ACTION_SUCCESS";
export const SET_PAY_ALL_ACCOUNTS_SUM_ACTION_FAILURE = "SET_PAY_ALL_ACCOUNTS_SUM_ACTION_FAILURE";

export const MULTIPLE_LS_PAY_LIST_ACTION = "MULTIPLE_LS_PAY_LIST_ACTION";
export const MULTIPLE_LS_PAY_LIST_ACTION_SUCCESS = "MULTIPLE_LS_PAY_LIST_ACTION_SUCCESS";
export const MULTIPLE_LS_PAY_LIST_ACTION_FAILURE = "MULTIPLE_LS_PAY_LIST_ACTION_FAILURE";

export const MULTIPLE_LS_PAY_ADDRESS_ACTION = "MULTIPLE_LS_PAY_ADDRESS_ACTION";
export const MULTIPLE_LS_PAY_ADDRESS_ACTION_SUCCESS = "MULTIPLE_LS_PAY_ADDRESS_ACTION_SUCCESS";
export const MULTIPLE_LS_PAY_ADDRESS_ACTION_FAILURE = "MULTIPLE_LS_PAY_ADDRESS_ACTION_FAILURE";

export const MULTIPLE_LS_PAY_ACTION = "MULTIPLE_LS_PAY_ACTION";
export const MULTIPLE_LS_PAY_ACTION_SUCCESS = "MULTIPLE_LS_PAY_ACTION_SUCCESS";
export const MULTIPLE_LS_PAY_ACTION_FAILURE = "MULTIPLE_LS_PAY_ACTION_FAILURE";

export const MULTIPLE_LS_PAY_CLEAR_BACK_URL_RESPONSE_ACTION = "MULTIPLE_LS_PAY_CLEAR_BACK_URL_RESPONSE_ACTION";

export const MULTIPLE_LS_PAY_OPEN_BACK_URL_MODAL_ACTION = "MULTIPLE_LS_PAY_OPEN_BACK_URL_MODAL_ACTION";
export const MULTIPLE_LS_PAY_CLOSE_BACK_URL_MODAL_ACTION = "MULTIPLE_LS_PAY_CLOSE_BACK_URL_MODAL_ACTION";

export const GET_FAST_PAY_PARAMS_ACTION = "GET_FAST_PAY_PARAMS_ACTION";
export const GET_FAST_PAY_PARAMS_ACTION_SUCCESS = "GET_FAST_PAY_PARAMS_ACTION_SUCCESS";
export const GET_FAST_PAY_PARAMS_ACTION_FAILURE = "GET_FAST_PAY_PARAMS_ACTION_FAILURE";

export const START_FAST_PAY_ACTION = "START_FAST_PAY_ACTION";
export const START_FAST_PAY_ACTION_SUCCESS = "START_FAST_PAY_ACTION_SUCCESS";
export const START_FAST_PAY_ACTION_FAILURE = "START_FAST_PAY_ACTION_FAILURE";

export const GET_FAST_PAY_STATUS_ACTION = "GET_FAST_PAY_STATUS_ACTION";
export const GET_FAST_PAY_STATUS_ACTION_SUCCESS = "GET_FAST_PAY_STATUS_ACTION_SUCCESS";
export const GET_FAST_PAY_STATUS_ACTION_FAILURE = "GET_FAST_PAY_STATUS_ACTION_FAILURE";

export const REJECT_FAST_PAY_ACTION = "REJECT_FAST_PAY_ACTION";
export const REJECT_FAST_PAY_ACTION_SUCCESS = "REJECT_FAST_PAY_ACTION_SUCCESS";
export const REJECT_FAST_PAY_ACTION_FAILURE = "REJECT_FAST_PAY_ACTION_FAILURE";

export const MULTIBONUS_CHECK_SHOW_ACTION = "MULTIBONUS_CHECK_SHOW_ACTION";
export const MULTIBONUS_CHECK_SHOW_ACTION_SUCCESS = "MULTIBONUS_CHECK_SHOW_ACTION_SUCCESS";
export const MULTIBONUS_CHECK_SHOW_ACTION_FAILURE = "MULTIBONUS_CHECK_SHOW_ACTION_FAILURE";

export const MULTIBONUS_GET_BALANCE_ACTION = "MULTIBONUS_GET_BALANCE_ACTION";
export const MULTIBONUS_GET_BALANCE_ACTION_SUCCESS = "MULTIBONUS_GET_BALANCE_ACTION_SUCCESS";
export const MULTIBONUS_GET_BALANCE_ACTION_FAILURE = "MULTIBONUS_GET_BALANCE_ACTION_FAILURE";

export const MULTIBONUS_REG_PROFILE_ACTION = "MULTIBONUS_REG_PROFILE_ACTION";
export const MULTIBONUS_REG_PROFILE_ACTION_SUCCESS = "MULTIBONUS_REG_PROFILE_ACTION_SUCCESS";
export const MULTIBONUS_REG_PROFILE_ACTION_FAILURE = "MULTIBONUS_REG_PROFILE_ACTION_FAILURE";

export const MULTIBONUS_CONFIRM_PROFILE_ACTION = "MULTIBONUS_CONFIRM_PROFILE_ACTION";
export const MULTIBONUS_CONFIRM_PROFILE_ACTION_SUCCESS = "MULTIBONUS_CONFIRM_PROFILE_ACTION_SUCCESS";
export const MULTIBONUS_CONFIRM_PROFILE_ACTION_FAILURE = "MULTIBONUS_CONFIRM_PROFILE_ACTION_FAILURE";

export const MULTIBONUS_RENEW_PROFILE_ACTION = "MULTIBONUS_RENEW_PROFILE_ACTION";
export const MULTIBONUS_RENEW_PROFILE_ACTION_SUCCESS = "MULTIBONUS_RENEW_PROFILE_ACTION_SUCCESS";
export const MULTIBONUS_RENEW_PROFILE_ACTION_FAILURE = "MULTIBONUS_RENEW_PROFILE_ACTION_FAILURE";

export const MULTIBONUS_PAYMENT_CALC_ACTION = "MULTIBONUS_PAYMENT_CALC_ACTION";
export const MULTIBONUS_PAYMENT_CALC_ACTION_SUCCESS = "MULTIBONUS_PAYMENT_CALC_ACTION_SUCCESS";
export const MULTIBONUS_PAYMENT_CALC_ACTION_FAILURE = "MULTIBONUS_PAYMENT_CALC_ACTION_FAILURE";

export const MULTIBONUS_REG_PAYMENT_ACTION = "MULTIBONUS_REG_PAYMENT_ACTION";
export const MULTIBONUS_REG_PAYMENT_ACTION_SUCCESS = "MULTIBONUS_REG_PAYMENT_ACTION_SUCCESS";
export const MULTIBONUS_REG_PAYMENT_ACTION_FAILURE = "MULTIBONUS_REG_PAYMENT_ACTION_FAILURE";

export const MULTIBONUS_CONFIRM_PAYMENT_ACTION = "MULTIBONUS_CONFIRM_PAYMENT_ACTION";
export const MULTIBONUS_CONFIRM_PAYMENT_ACTION_SUCCESS = "MULTIBONUS_CONFIRM_PAYMENT_ACTION_SUCCESS";
export const MULTIBONUS_CONFIRM_PAYMENT_ACTION_FAILURE = "MULTIBONUS_CONFIRM_PAYMENT_ACTION_FAILURE";

export const MULTIBONUS_RENEW_CODE_PAYMENT_ACTION = "MULTIBONUS_RENEW_CODE_PAYMENT_ACTION";
export const MULTIBONUS_RENEW_CODE_PAYMENT_ACTION_SUCCESS = "MULTIBONUS_RENEW_CODE_PAYMENT_ACTION_SUCCESS";
export const MULTIBONUS_RENEW_CODE_PAYMENT_ACTION_FAILURE = "MULTIBONUS_RENEW_CODE_PAYMENT_ACTION_FAILURE";

export const CRM_LS_EDIT_AVAIL_ACTION = "CRM_LS_EDIT_AVAIL_ACTION";
export const CRM_LS_EDIT_AVAIL_ACTION_SUCCESS = "CRM_LS_EDIT_AVAIL_ACTION_SUCCESS";
export const CRM_LS_EDIT_AVAIL_ACTION_FAILURE = "CRM_LS_EDIT_AVAIL_ACTION_FAILURE";

export const CRM_CHECK_ISU_ACTION = "CRM_CHECK_ISU_ACTION";
export const CRM_CHECK_ISU_ACTION_SUCCESS = "CRM_CHECK_ISU_ACTION_SUCCESS";

export const CRM_CHECK_CHANGE_TARIFF_ISU = "CRM_CHECK_CHANGE_TARIFF_ISU";
export const CRM_CHECK_CHANGE_TARIFF_ISU_SUCCESS = "CRM_CHECK_CHANGE_TARIFF_ISU_SUCCESS";

export const CRM_GET_CHANGE_TARIFF_ISU_FRAME = "CRM_GET_CHANGE_TARIFF_ISU_FRAME";

export const CRM_REJECT_CHANGE_TARIFF_ISU = "CRM_REJECT_CHANGE_TARIFF_ISU";
export const CRM_REJECT_CHANGE_TARIFF_ISU_SUCCESS = "CRM_REJECT_CHANGE_TARIFF_ISU_SUCCESS";

export const CRM_CHECK_GP_ACTION = "CRM_CHECK_GP_ACTION";

export const CHANGE_REQUISIT_ACTION = "CHANGE_REQUISIT_ACTION";
export const CHANGE_REQUISIT_ACTION_SUCCESS = "CHANGE_REQUISIT_ACTION_SUCCESS";
export const CHANGE_REQUISIT_ACTION_FAILURE = "CHANGE_REQUISIT_ACTION_FAILURE";

export const PRIVELEGES_CREATE_FRAME_LARGE_FAMILY_ACTION = "PRIVELEGES_CREATE_FRAME_LARGE_FAMILY_ACTION";
export const PRIVELEGES_CREATE_FRAME_LARGE_FAMILY_ACTION_SUCCESS =
    "PRIVELEGES_CREATE_FRAME_LARGE_FAMILY_ACTION_SUCCESS";
export const PRIVELEGES_CREATE_FRAME_LARGE_FAMILY_ACTION_FAILURE =
    "PRIVELEGES_CREATE_FRAME_LARGE_FAMILY_ACTION_FAILURE";

export const CRM_GET_GP_FRAME_ACTION = "CRM_GET_GP_FRAME_ACTION";

export const PAY_PROMO_EVENTS_ACTION = "PAY_PROMO_EVENTS_ACTION";
export const PAY_PROMO_EVENTS_ACTION_SUCCESS = "PAY_PROMO_EVENTS_ACTION_SUCCESS";
export const PAY_PROMO_EVENTS_ACTION_FAILURE = "PAY_PROMO_EVENTS_ACTION_FAILURE";

export const PAY_PROMO_EVENT_SET_ACTION = "PAY_PROMO_EVENT_SET_ACTION";
export const PAY_PROMO_EVENT_SET_ACTION_SUCCESS = "PAY_PROMO_EVENT_SET_ACTION_SUCCESS";
export const PAY_PROMO_EVENT_SET_ACTION_FAILURE = "PAY_PROMO_EVENT_SET_ACTION_FAILURE";

export const METERS_PROMO_EVENTS_ACTION = "METERS_PROMO_EVENTS_ACTION";
export const METERS_PROMO_EVENTS_ACTION_SUCCESS = "METERS_PROMO_EVENTS_ACTION_SUCCESS";
export const METERS_PROMO_EVENTS_ACTION_FAILURE = "METERS_PROMO_EVENTS_ACTION_FAILURE";

export const START_MOE_FAST_PAY_ACTION = "START_MOE_FAST_PAY_ACTION";
export const START_MOE_FAST_PAY_ACTION_SUCCESS = "START_MOE_FAST_PAY_ACTION_SUCCESS";
export const START_MOE_FAST_PAY_ACTION_FAILURE = "START_MOE_FAST_PAY_ACTION_FAILURE";

export const MULTI_PROMO_EVENTS_ACTION = "MULTI_PROMO_EVENTS_ACTION";
export const MULTI_PROMO_EVENTS_ACTION_SUCCESS = "MULTI_PROMO_EVENTS_ACTION_SUCCESS";
export const MULTI_PROMO_EVENTS_ACTION_FAILURE = "MULTI_PROMO_EVENTS_ACTION_FAILURE";

export const MULTI_PROMO_EVENT_SET_ACTION = "MULTI_PROMO_EVENT_SET_ACTION";
export const MULTI_PROMO_EVENT_SET_ACTION_SUCCESS = "MULTI_PROMO_EVENT_SET_ACTION_SUCCESS";
export const MULTI_PROMO_EVENT_SET_ACTION_FAILURE = "MULTI_PROMO_EVENT_SET_ACTION_FAILURE";

export const CHECK_SCHELUDED_INSPECTION_ACTION = "CHECK_SCHELUDED_INSPECTION_ACTION";
export const CHECK_SCHELUDED_INSPECTION_ACTION_SUCCESS = "CHECK_SCHELUDED_INSPECTION_ACTION_SUCCESS";
export const CHECK_SCHELUDED_INSPECTION_ACTION_FAILURE = "CHECK_SCHELUDED_INSPECTION_ACTION_FAILURE";

export const GET_SCHELUDEDINSPECTION_ACTION = "GET_SCHELUDEDINSPECTION_ACTION";
export const GET_SCHELUDEDINSPECTION_ACTION_SUCCESS = "GET_SCHELUDEDINSPECTION_ACTION_SUCCESS";
export const GET_SCHELUDEDINSPECTION_ACTION_FAILURE = "GET_SCHELUDEDINSPECTION_ACTION_FAILURE";

export const CONFIRM_SCHELUDED_INSPECTION_ACTION = "CONFIRM_SCHELUDED_INSPECTION_ACTION";
export const CONFIRM_SCHELUDED_INSPECTION_ACTION_SUCCESS = "CONFIRM_SCHELUDED_INSPECTION_ACTION_SUCCESS";
export const CONFIRM_SCHELUDED_INSPECTION_ACTION_FAILURE = "CONFIRM_SCHELUDED_INSPECTION_ACTION_FAILURE";

export const CHECK_LS_PAYAVAIL_ACTION = "CHECK_LS_PAYAVAIL_ACTION";
export const CHECK_LS_PAYAVAIL_ACTION_SUCCESS = "CHECK_LS_PAYAVAIL_ACTION_SUCCESS";
export const CHECK_LS_PAYAVAIL_ACTION_FAILURE = "CHECK_LS_PAYAVAIL_ACTION_FAILURE";

export const CHECK_NOTIFY_KSG_OVER_PAY_ACTION = "CHECK_NOTIFY_KSG_OVER_PAY_ACTION";
export const CHECK_NOTIFY_KSG_OVER_PAY_ACTION_SUCCESS = "CHECK_NOTIFY_KSG_OVER_PAY_ACTION_SUCCESS";
export const CHECK_NOTIFY_KSG_OVER_PAY_ACTION_FAILURE = "CHECK_NOTIFY_KSG_OVER_PAY_ACTION_FAILURE";

export const SAVE_NOTIFY_KSG_OVER_PAY_ACTION = "SAVE_NOTIFY_KSG_OVER_PAY_ACTION";
export const SAVE_NOTIFY_KSG_OVER_PAY_ACTION_SUCCESS = "SAVE_NOTIFY_KSG_OVER_PAY_ACTION_SUCCESS";
export const SAVE_NOTIFY_KSG_OVER_PAY_ACTION_FAILURE = "SAVE_NOTIFY_KSG_OVER_PAY_ACTION_FAILURE";

export const SAVE_NOTIFY_KSG_OVER_PAY_N_ACTION = "SAVE_NOTIFY_KSG_OVER_PAY_N_ACTION";
export const SAVE_NOTIFY_KSG_OVER_PAY_N_ACTION_SUCCESS = "SAVE_NOTIFY_KSG_OVER_PAY_N_ACTION_SUCCESS";
export const SAVE_NOTIFY_KSG_OVER_PAY_N_ACTION_FAILURE = "SAVE_NOTIFY_KSG_OVER_PAY_N_ACTION_FAILURE";

export const GET_LS_INSURANCE_ACTION = "GET_LS_INSURANCE_ACTION";
export const GET_LS_INSURANCE_ACTION_SUCCESS = "GET_LS_INSURANCE_ACTION_SUCCESS";
export const GET_LS_INSURANCE_ACTION_FAILURE = "GET_LS_INSURANCE_ACTION_FAILURE";

export const GET_TABS_ACTION = "GET_TABS_ACTION";
export const GET_TABS_ACTION_SUCCESS = "GET_TABS_ACTION_SUCCESS";
export const GET_TABS_ACTION_FAILURE = "GET_TABS_ACTION_FAILURE";

export const CHK_LS_REPORT_DETAIL_AVAIL_ACTION = "CHK_LS_REPORT_DETAIL_AVAIL_ACTION";
export const CHK_LS_REPORT_DETAIL_AVAIL_ACTION_SUCCESS = "CHK_LS_REPORT_DETAIL_AVAIL_ACTION_SUCCESS";
export const CHK_LS_REPORT_DETAIL_AVAIL_ACTION_FAILURE = "CHK_LS_REPORT_DETAIL_AVAIL_ACTION_FAILURE";

export const CRM_GET_REPORT_FRAME_ACTION = "CRM_GET_REPORT_FRAME_ACTION";
export const CRM_GET_REPORT_FRAME_ACTION_SUCCESS = "CRM_GET_REPORT_FRAME_ACTION_SUCCESS";
export const CRM_GET_REPORT_FRAME_ACTION_FAILURE = "CRM_GET_REPORT_FRAME_ACTION_FAILURE";

export const CHECK_EPR_PAY_ACTION = "CHECK_EPR_PAY_ACTION";
export const CHECK_EPR_PAY_ACTION_SUCCESS = "CHECK_EPR_PAY_ACTION_SUCCESS";

export const SAVE_REDIRECT_URL_ACTION = "SAVE_REDIRECT_URL_ACTION";

export const CHK_REPORT_PASP_ACTION = "CHK_REPORT_PASP_ACTION";
export const CHK_REPORT_PASP_ACTION_SUCCESS = "CHK_REPORT_PASP_ACTION_SUCCESS";
export const CHK_REPORT_PASP_ACTION_FAILURE = "CHK_REPORT_PASP_ACTION_FAILURE";

export const GET_LS_REPORT_HIST_ACTION = "GET_LS_REPORT_HIST_ACTION";
export const GET_LS_REPORT_HIST_ACTION_SUCCESS = "GET_LS_REPORT_HIST_ACTION_SUCCESS";
export const GET_LS_REPORT_HIST_ACTION_FAILURE = "GET_LS_REPORT_HIST_ACTION_FAILURE";

export const CRM_GET_REPORT_FRAME_EDIT_ACTION = "CRM_GET_REPORT_FRAME_EDIT_ACTION";
export const CRM_GET_REPORT_FRAME_EDIT_ACTION_SUCCESS = "CRM_GET_REPORT_FRAME_EDIT_ACTION_SUCCESS";
export const CRM_GET_REPORT_FRAME_EDIT_ACTION_FAILURE = "CRM_GET_REPORT_FRAME_EDIT_ACTION_FAILURE";

export const GET_LK_PROVIDER_FUNCTION_ACTION = "GET_LK_PROVIDER_FUNCTION_ACTION";
export const GET_LK_PROVIDER_FUNCTION_ACTION_SUCCESS = "GET_LK_PROVIDER_FUNCTION_ACTION_SUCCESS";
export const GET_LK_PROVIDER_FUNCTION_ACTION_FAILURE = "GET_LK_PROVIDER_FUNCTION_ACTION_FAILURE";

export const UCM_GET_METER_DATA_ACTION = "UCM_GET_METER_DATA_ACTION";
export const UCM_GET_METER_DATA_ACTION_SUCCESS = "UCM_GET_METER_DATA_ACTION_SUCCESS";
export const UCM_GET_METER_DATA_ACTION_FAILURE = "UCM_GET_METER_DATA_ACTION_FAILURE";

export const UCM_GET_LIST_EVENTS_ACTION = "UCM_GET_LIST_EVENTS_ACTION";
export const UCM_GET_LIST_EVENTS_ACTION_SUCCESS = "UCM_GET_LIST_EVENTS_ACTION_SUCCESS";
export const UCM_GET_LIST_EVENTS_ACTION_FAILURE = "UCM_GET_LIST_EVENTS_ACTION_FAILURE";

export const UCM_GET_MEASURINGS_GROUP_ACTION = "UCM_GET_MEASURINGS_GROUP_ACTION";
export const UCM_GET_MEASURINGS_GROUP_ACTION_SUCCESS = "UCM_GET_MEASURINGS_GROUP_ACTION_SUCCESS";
export const UCM_GET_MEASURINGS_GROUP_ACTION_FAILURE = "UCM_GET_MEASURINGS_GROUP_ACTION_FAILURE";

export const CRM_CHECK_COMPLAINT_POWER_SUPPLY_ENABLED_ACTION = "CRM_CHECK_COMPLAINT_POWER_SUPPLY_ENABLED_ACTION";
export const CRM_CHECK_COMPLAINT_POWER_SUPPLY_ENABLED_ACTION_SUCCESS =
    "CRM_CHECK_COMPLAINT_POWER_SUPPLY_ENABLED_ACTION_SUCCESS";
export const CRM_CHECK_COMPLAINT_POWER_SUPPLY_ENABLED_ACTION_FAILURE =
    "CRM_CHECK_COMPLAINT_POWER_SUPPLY_ENABLED_ACTION_FAILURE";

export const CRM_GET_COMPLAINT_POWER_SUPPLY_FRAME_ACTION = "CRM_GET_COMPLAINT_POWER_SUPPLY_FRAME_ACTION";
