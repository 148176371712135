// @flow

export const TextFieldStyle = (theme: any) => ({
    "font-size-18": {
        fontSize: 18,
    },
    "height-48": {
        height: 48,
    },
    "height-auto": {
        height: "auto",
    },
    labelActive: {
        color: theme.palette.secondary.light2,
    },
    labelSuccessful: {
        "&.Mui-disabled": {
            color: theme.palette.green.light,
        },
        color: theme.palette.green.light,
    },
    maskedInput: {
        "&:focus": {
            outline: "none",
        },
        border: "none",
        fontFamily: "inherit",
        width: "100%",
    },
    textInput: {
        width: "100%",
    },
    textInputSuccessful: {
        cursor: "default",
    },
    textLabelNoWrap: {
        fontSize: 16,
        overflow: "hidden",
        paddingBottom: 4,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    textLabelWrap: {
        "&:first-letter": {
            textTransform: "capitalize",
        },
        transform: "none",
    },
    textWrap: {
        background: `${theme.palette.common.white}`,
        border: `1px solid ${theme.palette.grey.border}`,
        borderRadius: 4,
        boxShadow: "inset 0px 1px 1px rgba(0, 0, 0, 0.1)",
        boxSizing: "border-box",
        display: "flex",
        padding: "1px 15px",
        position: "relative",
    },
    textWrapActive: {
        borderColor: theme.palette.secondary.light2,
    },
    textWrapError: {
        borderColor: theme.palette.error.main,
    },
    textWrapSuccessful: {
        borderColor: theme.palette.green.light,
    },
    treeMenuInputContent: {
        cursor: "pointer",
        display: "flex",
        position: "relative",
        width: "100%",
    },
});
