// @flow
import * as actionTypes from "../../constants/types/EnergyContractsActionTypes";
import {convertProviders, convertProvidersJur} from "../../utils/energyContractsUtils";
import {createReducer} from "./createReducer";

export type EContractsAvailProvider = {
    nnCode: number,
    nmValue: string,
};

export type EnergyContractsLoaderType = {
    show: boolean,
    text: string,
};

export type AccountsEditableListItemType = {
    kdProvider?: number,
    nmProvider?: string,
    vlLsList: Array<Object>,
};

export type EnergyContractsReducerType = {
    accountsEditableList: Array<AccountsEditableListItemType>,
    availProviders: Array<EContractsAvailProvider>,
    energyContracts: Array<Object>,
    fetchingEnergyContracts: boolean,
    loader: EnergyContractsLoaderType,
    prPowerSupply: boolean,
    prPowerSupplyTempLs: boolean,
    services: Array<Object>,
    supplyProviders: Array<Object>,
};

const initialState = {
    accountsEditableList: [],
    availProviders: [],
    fetchingEnergyContracts: false,
    loader: {
        show: false,
        text: "",
    },
    prPowerSupply: false,
    prPowerSupplyTempLs: false,
    services: [],
    supplyProviders: [],
};

export const energyContractsReducer = createReducer(initialState, {
    [actionTypes.GET_POW_SUP_PROVIDERS_ACTION_SUCCESS]: (state: EnergyContractsReducerType, {response}) => ({
        ...state,
        availProviders: convertProvidersJur(response),
    }),
    [actionTypes.CRM_GET_POWER_SUPPLY_CONTRACT_HIST_ACTION]: (state: EnergyContractsReducerType) => ({
        ...state,
        fetchingEnergyContracts: true,
    }),
    [actionTypes.CRM_GET_POWER_SUPPLY_CONTRACT_HIST_ACTION_SUCCESS]: (
        state: EnergyContractsReducerType,
        {response},
    ) => {
        const {vlServiceList = []} = response;

        return {
            ...state,
            energyContracts: vlServiceList,
            fetchingEnergyContracts: false,
        };
    },
    [actionTypes.CRM_GET_POWER_SUPPLY_CONTRACT_HIST_ACTION_FAILURE]: (state: EnergyContractsReducerType) => ({
        ...state,
        fetchingEnergyContracts: false,
    }),
    [actionTypes.POWER_SUPPLY_INFO_VISIBILITY_ACTION_SUCCESS]: (state: EnergyContractsReducerType, {response}) => ({
        ...state,
        ...response,
    }),
    [actionTypes.POWER_SUPPLY_PROVIDERS_ACTION_SUCCESS]: (state: EnergyContractsReducerType, {response}) => ({
        ...state,
        supplyProviders: convertProviders(response),
    }),

    [actionTypes.LS_LIST_EDITABLE_ACTION]: (state) => ({
        ...state,
        accountsEditableList: initialState.accountsEditableList,
    }),
    [actionTypes.LS_LIST_EDITABLE_ACTION_SUCCESS]: (
        state: EnergyContractsReducerType,
        {response},
    ): EnergyContractsReducerType => ({
        ...state,
        accountsEditableList: response?.map(({vlLsEditable}) => vlLsEditable) || [],
    }),
    [actionTypes.LS_LIST_EDITABLE_ACTION_FAILURE]: (state) => ({
        ...state,
        accountsEditableList: initialState.accountsEditableList,
    }),
});
