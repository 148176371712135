// @flow
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "../Button/Button";
import {styles} from "./SliderBtns.style";

type Props = {
    classes?: any,
    contentLength: number,
    elementIndex: number,
    onBtnClick: (value: string) => string,
    mode: number,
};

const SliderBtns = (props: Props) => {
    const {classes = {}, contentLength, elementIndex, onBtnClick, mode} = props;
    const firstElement = elementIndex === 0;
    const lastElement = elementIndex === contentLength - 1;

    const onPrevClick = () => {
        onBtnClick("back");
    };

    const onNextClick = () => {
        onBtnClick("forward");
    };

    return mode ? (
        <Grid xs={12} container justify="space-between">
            {firstElement ? null : (
                <Grid xs={5}>
                    <Button onClick={onPrevClick}>Назад</Button>
                </Grid>
            )}
            {lastElement ? null : (
                <Grid xs={5}>
                    <Button onClick={onNextClick}>Вперед</Button>
                </Grid>
            )}
        </Grid>
    ) : (
        <Grid xs={12} container justify="space-between" className={classes.zero}>
            <Grid item container xs={6} justify="flex-start">
                {firstElement ? null : (
                    <ChevronLeftIcon fontSize="large" onClick={onPrevClick} className={classes.zeroBtn} />
                )}
            </Grid>
            <Grid item container xs={6} justify="flex-end">
                {lastElement ? null : (
                    <ChevronRightIcon fontSize="large" onClick={onNextClick} className={classes.zeroBtn} />
                )}
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(SliderBtns);
