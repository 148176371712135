/* eslint-disable sort-keys,quotes,max-lines */
// @flow
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import moment from "moment";
import * as types from "../../constants/types/ActionTypes";
import * as addLSActiontypes from "../../constants/types/AddLSActionTypes";
import * as tmkProviderTypes from "../../constants/types/ActionTmkProviderTypes";
import * as profileActionTypes from "../../constants/types/ProfileActionTypes";
import * as indicationActionTypes from "../../constants/types/IndicationActionTypes";
import {kdProviders} from "../../constants/Providers";
import {
    updateField,
    groupIndicatorsDataMoe,
    getKdElementAsKeys,
    isShowPrivilegesInfo,
} from "../../utils/accoutReducerUtils";
import {memoizedAllDatesMes} from "../../utils/accountAllEventsUtils";
import {type AccountReducerType} from "../../constants/types/ReducerTypes";
import {
    defaultPeriod,
    getAccountsDropDownList,
    accountDetailInitialState,
    initialState,
} from "../../constants/AccountReducerInitialState";
import {SHOW_CAPTCHE} from "../../constants/KdResults";
import {createReducer} from "./createReducer";

const parseReadingsData = (data?: Array<Object>) =>
    data?.length
        ? data.map((item) => ({
              ...item,
              zone: `${item.zoneName || ""}${item.zoneCode ? ` (${item.zoneCode})` : ""}`,
          }))
        : [];

export const accountReducer = createReducer(initialState, {
    [types.ACCOUNT_LOAD_ACTION]: (state: AccountReducerType, {payload}): AccountReducerType => {
        const currentAccount = {
            ...[...state.accountsList].find((account) => account.idService.toString() === payload.accountId),
        };
        let currentPeriod = defaultPeriod;

        if (
            state.accountDetail.period &&
            state.accountDetail.period.provider === kdProviders[currentAccount.kdProvider]
        ) {
            const {period} = state.accountDetail;

            currentPeriod = period;
        }

        return {
            ...state,
            accountDetail: {
                ...currentAccount,
                ...accountDetailInitialState,
                fetching: true,
                period: currentPeriod,
            },
        };
    },
    [types.ACCOUNT_LOAD_ACTION_SUCCESS]: (state: AccountReducerType, {response}) => ({
        ...state,
        accountDetail: {...state.accountDetail, fetching: false, ...response},
    }),
    [types.PROVIDER_TAB_ACTION_SUCCESS]: (state: AccountReducerType, {response}) => ({
        ...state,
        accountDetail: {...state.accountDetail, privilegesTab: response.prVisible, ...response},
    }),
    [types.ACCOUNT_LOAD_ACTION_FAILURE]: (state: AccountReducerType) => ({
        ...state,
        accountDetail: {...state.accountDetail, fetching: false},
    }),
    [types.INDICATION_AND_PAY_ACTION_SUCCESS]: (
        state: AccountReducerType,
        {response, payload},
    ): AccountReducerType => ({
        ...state,
        accountsList: state.accountsList.map((account) =>
            account.kdProvider === payload.kdProvider ? {...account, ...response} : account,
        ),
    }),
    [types.GET_MAP_PROVIDER_LIST_SUCCESS]: (state: AccountReducerType, action): AccountReducerType => ({
        ...state,
        providerList: action.response,
    }),
    [types.GET_LS_LIST]: (state: AccountReducerType): AccountReducerType => ({
        ...state,
        fetchingAccountsList: true,
    }),
    [types.GET_LS_LIST_SUCCESS]: (state: AccountReducerType, action): AccountReducerType => ({
        ...state,
        accountsByAddress: groupBy(action.response, "nmLsGroup"),
        accountsByIdService: groupBy(action.response, "idService"),
        accountsDropDownList: getAccountsDropDownList(action.response),
        accountsList: action.response,
        fetchingAccountsList: false,
    }),
    [types.GET_LS_LIST_FAILURE]: (state: AccountReducerType): AccountReducerType => ({
        ...state,
        fetchingAccountsList: false,
    }),
    [types.LS_DELETE_SUCCESS]: (state: AccountReducerType, {payload}): AccountReducerType => {
        const accList = [...state.accountsList].filter((account) => account.idService !== payload.accountId);

        return {
            ...state,
            accountsByAddress: groupBy(accList, "nmLsGroup"),
            accountsDropDownList: getAccountsDropDownList(accList),
            accountsList: accList,
        };
    },
    [types.LS_LOAD_DATA]: (state: AccountReducerType, {payload}): AccountReducerType => ({
        ...state,
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            [payload.name]: true,
        },
    }),
    [types.LS_LOAD_DATA_SUCCESS]: (
        state: AccountReducerType,
        {response, payload: {normalizeFunction, name}},
    ): AccountReducerType => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            [name]: normalizeFunction ? normalizeFunction(response) : response,
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            [name]: false,
        },
    }),
    [types.LS_LOAD_DATA_MAP]: (state: AccountReducerType, {payload: {mapId, name}}): AccountReducerType => ({
        ...state,
        fetchingAccountDetail: updateField(state, "fetchingAccountDetail", {mapId, name, value: true}),
    }),
    [types.LS_LOAD_DATA_MAP_SUCCESS]: (
        state: AccountReducerType,
        {response, payload: {mapId, name, normalizeFunction}},
    ): AccountReducerType => ({
        ...state,
        accountDetail: updateField(state, "accountDetail", {mapId, name, normalizeFunction, value: response}),
        fetchingAccountDetail: updateField(state, "fetchingAccountDetail", {mapId, name, value: false}),
    }),
    [types.LS_LOAD_DATA_MAP_FAILURE]: (state: AccountReducerType, {payload: {mapId, name}}): AccountReducerType => ({
        ...state,
        fetchingAccountDetail: updateField(state, "fetchingAccountDetail", {mapId, name, value: false}),
    }),
    [types.LS_LOAD_INDICATOR_DATA_ACTION]: (state: AccountReducerType, {payload}): AccountReducerType => ({
        ...state,
        fetchingIndicatorsData: {
            ...state.fetchingIndicatorsData,
            [payload.nnLs]: true,
        },
    }),
    [types.LS_LOAD_INDICATOR_DATA_ACTION_SUCCESS]: (
        state: AccountReducerType,
        {response, payload},
    ): AccountReducerType => ({
        ...state,
        fetchingIndicatorsData: {
            ...state.fetchingIndicatorsData,
            [payload.nnLs]: false,
        },
        indicatorsData: {
            ...state.indicatorsData,
            [payload.nnLs]: payload.name === "equipment" ? groupIndicatorsDataMoe(response) : response,
        },
    }),
    [types.LS_LOAD_INDICATOR_DATA_ACTION_FAILURE]: (state: AccountReducerType, {payload}): AccountReducerType => ({
        ...state,
        fetchingIndicatorsData: {
            ...state.fetchingIndicatorsData,
            [payload.nnLs]: false,
        },
    }),
    [types.LS_CHECK_INDICATOR_CORRECTION_ACTION]: (state: AccountReducerType, {payload}): AccountReducerType => ({
        ...state,
        fetchingIndicatorsCorrData: {
            ...state.fetchingIndicatorsCorrData,
            [payload.nnLs]: true,
        },
    }),
    [types.LS_CHECK_INDICATOR_CORRECTION_ACTION_SUCCESS]: (state: AccountReducerType, {response, payload}) => ({
        ...state,
        indicatorsCorrData: {
            ...state.indicatorsCorrData,
            [payload.nnLs]: response.answer.data,
        },
        fetchingIndicatorsCorrData: {
            ...state.fetchingIndicatorsCorrData,
            [payload.nnLs]: false,
        },
    }),
    [types.LS_CHECK_INDICATOR_CORRECTION_ACTION_FAILURE]: (
        state: AccountReducerType,
        {payload},
    ): AccountReducerType => ({
        ...state,
        fetchingIndicatorsCorrData: {
            ...state.fetchingIndicatorsCorrData,
            [payload.nnLs]: false,
        },
    }),
    [indicationActionTypes.LS_LOAD_INDICATOR_IS_FLOAT_DATA_ACTION_SUCCESS]: (
        state: AccountReducerType,
        {payload, response},
    ): AccountReducerType => ({
        ...state,
        indicatorIsFloat: {
            ...state.indicatorIsFloat,
            [payload.idService]: response[0] ? response[0].prFloat : false,
        },
    }),
    [types.LS_LOAD_DATA_FAILURE]: (state: AccountReducerType, {payload, canceled}): AccountReducerType => ({
        ...state,
        accountDetail: canceled
            ? state.accountDetail
            : {
                  ...state.accountDetail,
                  [payload.name]: [],
              },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            [payload.name]: false,
        },
    }),
    [types.GET_LS_GROUPS_SUCCESS]: (state: AccountReducerType, {response, payload}): AccountReducerType => ({
        ...state,
        accountGroups: [...response],
        idService: payload.idService,
    }),
    [types.LS_SELECT_PERIOD_ACTION]: (state: AccountReducerType, {payload}): AccountReducerType => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            [payload.periodName]: payload.period,
        },
    }),
    [types.AUTH_LOGOUT]: (state: AccountReducerType): AccountReducerType => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            period: defaultPeriod,
        },
    }),
    [types.AUTH_LOGOUT_ACTION]: (state: AccountReducerType): AccountReducerType => ({
        ...state,
        fetchingAccountLogout: true,
    }),
    [types.AUTH_LOGOUT_ACTION_SUCCESS]: (state: AccountReducerType): AccountReducerType => ({
        ...state,
        accountDetail: {
            ...accountDetailInitialState,
            period: defaultPeriod,
        },
        fetchingAccountLogout: false,
    }),
    [types.AUTH_LOGOUT_ACTION_FAILURE]: (state: AccountReducerType): AccountReducerType => ({
        ...state,
        fetchingAccountLogout: false,
    }),
    [profileActionTypes.IS_CONFIRM_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            lsConfirm: {...response},
        },
    }),
    [types.CLEAR_ACCOUNT_DETAIL_ACTION]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetailInitialState,
            period: defaultPeriod,
        },
    }),
    [types.CLEAR_LOCAL_ACCOUNT_DETAIL_ACTION]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetailInitialState,
            period: state.accountDetail.period,
        },
    }),
    [types.ALL_EVENTS_ACTION]: (state: any, {payload}) => {
        const records =
            payload.results === "indications" ? state.accountDetail.indications : state.accountDetail.indicationsCom;
        const recordsWithAllDates = memoizedAllDatesMes([
            records,
            state.accountDetail.pays,
            state.accountDetail.invoice,
        ]);
        const newRecords = groupBy(recordsWithAllDates, (record) => moment(record.dtIndication).format("MMMM YYYY"));
        const sortedRecords = sortBy(newRecords, (record) => record[0].dtIndication).reverse();

        return {
            ...state,
            accountDetail: {
                ...state.accountDetail,
                allEventsMesSortedData: sortedRecords.length > 0 ? sortedRecords : [],
            },
        };
    },
    [types.CRM_GET_PRIVILEGES_HISTORY_ACTION]: (state) => ({
        ...state,
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            privilegesHistory: true,
        },
    }),
    [types.CRM_GET_PRIVILEGES_HISTORY_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            privilegesHistory: response.vlServiceList,
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            privilegesHistory: false,
        },
    }),
    [types.CRM_GET_PRIVILEGES_HISTORY_ACTION_FAILURE]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            privilegesHistory: [],
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            privilegesHistory: false,
        },
    }),
    [types.PRIVILEGES_AVAIL_ACTION]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            privilegesAvail: false,
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            privilegesInfo: true,
        },
    }),
    [types.PRIVILEGES_AVAIL_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            privilegesAvail: response && response.prAvail === 1,
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            privilegesInfo: response && response.prAvail === 1,
        },
    }),
    [types.PRIVILEGES_AVAIL_ACTION_FAILURE]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            privilegesAvail: false,
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            privilegesInfo: false,
        },
    }),
    [types.GET_PRIVILEGES_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            privileges: response || [],
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            privilegesInfo: Boolean(response && response.dtEn),
        },
    }),
    [types.GET_PRIVILEGES_ACTION_FAILURE]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            privileges: [],
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            privilegesInfo: false,
        },
    }),
    [types.PRIVILEGES_DAY_AVAIL_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            privileges: state.accountDetail.privileges.map((privilege) => ({
                ...privilege,
                showPrivilegesInfo: isShowPrivilegesInfo(privilege, (response || {}).nnDays),
            })),
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            privilegesInfo: false,
        },
    }),
    [types.PRIVILEGES_DAY_AVAIL_ACTION_FAILURE]: (state) => ({
        ...state,
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            privilegesInfo: false,
        },
    }),
    [tmkProviderTypes.GET_TMK_CHECK_BY_LS_ACTION]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            extraVlProviders: [],
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            extraVlProviders: true,
        },
    }),
    [tmkProviderTypes.GET_TMK_CHECK_BY_LS_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            extraVlProviders: response,
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            extraVlProviders: false,
        },
    }),
    [tmkProviderTypes.GET_TMK_CHECK_BY_LS_ACTION_FAILURE]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            extraVlProviders: [],
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            extraVlProviders: false,
        },
    }),
    [addLSActiontypes.GET_LS_QUESTION_ACTION_SUCCESS]: (state, {response, payload}) => ({
        ...state,
        controlQuestion: {
            ...state.controlQuestion,
            ...payload,
            questions: response.map(({idQuestion, nmQuestion}) => ({nmValue: nmQuestion, nnCode: idQuestion})),
            showCapthe: false,
            showModal: true,
        },
    }),
    [addLSActiontypes.LS_CONFIRM_ACTION_FAILURE]: (state, {response}) => {
        if (response.kdResult === SHOW_CAPTCHE) {
            return {
                ...state,
                controlQuestion: {
                    ...state.controlQuestion,
                    showCapthe: true,
                },
            };
        }

        return state;
    },
    [addLSActiontypes.CLOSE_LS_ADD_QUESTION_FORM_ACTION]: (state) => ({
        ...state,
        controlQuestion: {
            ...state.controlQuestion,
            showModal: false,
        },
    }),
    [addLSActiontypes.GET_LS_QUESTIONS_ELEMENTS]: (state) => ({
        ...state,
        controlQuestion: {
            ...state.controlQuestion,
            lsElements: null,
            kdLsImg: 0,
        },
    }),
    [addLSActiontypes.GET_LS_QUESTIONS_ELEMENTS_SUCCESS]: (state, {response}) => {
        const elements = getKdElementAsKeys(response.elements);

        return {
            ...state,
            controlQuestion: {
                ...state.controlQuestion,
                lsElements: elements,
                kdLsImg: response.kdLsImg,
            },
        };
    },
    [types.GET_PREPAY_PARAMS_SUCCESS]: (state: AccountReducerType, action): AccountReducerType => ({
        ...state,
        prepay: action.response,
    }),
    [types.LOAD_PAY_ADDRES_ACTION_SUCCESS]: (state: AccountReducerType, action): AccountReducerType => ({
        ...state,
        nmEmail: action.response.nmEmail,
        nnPhone: action.response.nnPhone,
    }),
    [types.GET_COUNTER_STATISTIC_FRAME_ACTION]: (state: AccountReducerType): AccountReducerType => ({
        ...state,
        statistic: {
            ...state.statistic,
            fetchingDetailCounterStatistic: true,
        },
    }),
    [types.GET_COUNTER_STATISTIC_FRAME_ACTION_SUCCESS]: (
        state: AccountReducerType,
        {response},
    ): AccountReducerType => ({
        ...state,
        statistic: {
            ...state.statistic,
            fetchingDetailCounterStatistic: false,
            ...response,
        },
    }),
    [types.GET_COUNTER_STATISTIC_FRAME_AVAIL_ACTION_SUCCESS]: (
        state: AccountReducerType,
        {response},
    ): AccountReducerType => ({
        ...state,
        statistic: {
            ...state.statistic,
            ...response,
        },
    }),

    [types.GET_COUNTER_STATISTIC_FRAME_ACTION_FAILURE]: (state: AccountReducerType): AccountReducerType => ({
        ...state,
        statistic: {
            ...state.statistic,
            fetchingDetailCounterStatistic: false,
        },
    }),
    [types.SET_PAY_ALL_ACCOUNTS_SUM_ACTION]: (state: AccountReducerType, {payload}) => ({
        ...state,
        payAll: {
            ...state.payAll,
            counters: {
                ...state.payAll.counters,
                ...payload,
            },
        },
    }),
    [types.GET_MULTIPLE_LS_PAY_RESTRICTIONS_ACTION_SUCCESS]: (state: AccountReducerType, {response}) => ({
        ...state,
        payAll: {
            ...state.payAll,
            restrictions: {
                ...response,
            },
        },
    }),
    [types.MULTIPLE_LS_PAY_LIST_ACTION_SUCCESS]: (state: AccountReducerType, {response}) => ({
        ...state,
        payAll: {
            ...state.payAll,
            lsList: response,
        },
    }),
    [types.MULTIPLE_LS_PAY_ADDRESS_ACTION_SUCCESS]: (state: AccountReducerType, {response}) => ({
        ...state,
        payAll: {
            ...state.payAll,
            email: response.nmEmail,
        },
    }),
    [types.BACK_URL_ACTION_SUCCESS]: (state: AccountReducerType, {response, payload}) => {
        if (payload.type === "/mass_pay_back_url_s") {
            return {
                ...state,
                payAll: {
                    ...state.payAll,
                    backUrlResponse: response,
                    backUrlModalOpen: true,
                },
            };
        }

        return state;
    },
    [types.MULTIPLE_LS_PAY_CLEAR_BACK_URL_RESPONSE_ACTION]: (state: AccountReducerType) => ({
        ...state,
        payAll: {
            ...state.payAll,
            backUrlResponse: {},
        },
    }),
    [types.MULTIPLE_LS_PAY_OPEN_BACK_URL_MODAL_ACTION]: (state: AccountReducerType) => ({
        ...state,
        payAll: {
            ...state.payAll,
            backUrlModalOpen: true,
        },
    }),
    [types.MULTIPLE_LS_PAY_CLOSE_BACK_URL_MODAL_ACTION]: (state: AccountReducerType) => ({
        ...state,
        payAll: {
            ...state.payAll,
            backUrlModalOpen: false,
        },
    }),
    [types.CRM_LS_EDIT_AVAIL_ACTION]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            requisites: {
                ...state.accountDetail.requisites,
                prAvail: {},
            },
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            requisitsPrAvail: true,
        },
    }),
    [types.CRM_LS_EDIT_AVAIL_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            requisites: {
                ...state.accountDetail.requisites,
                prAvail: response,
            },
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            requisitsPrAvail: false,
        },
    }),
    [types.CRM_LS_EDIT_AVAIL_ACTION_FAILURE]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            requisites: {
                ...state.accountDetail.requisites,
                prAvail: {},
            },
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            requisitsPrAvail: false,
        },
    }),
    [types.CRM_CHECK_ISU_ACTION]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            minimalFunctions: {
                ...state.accountDetail.minimalFunctions,
                prIsu: false,
                idMeter: undefined,
                nmSchema: undefined,
                loaded: false,
            },
        },
    }),
    [types.CRM_CHECK_ISU_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            minimalFunctions: {
                ...state.accountDetail.minimalFunctions,
                prIsu: response?.prAvail,
                idMeter: response?.idMeter,
                nnMeter: response?.nnMeter,
                nmSchema: response?.nmSchema,
                loaded: true,
            },
        },
    }),
    [types.GET_SCHELUDEDINSPECTION_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            scheludedInspectionBanerInfo: response.vlString,
        },
    }),
    [types.CHECK_LS_PAYAVAIL_ACTION_SUCCESS]: (state: AccountReducerType, action): AccountReducerType => ({
        ...state,
        prpayepd: action.response.prpayepd,
    }),
    [types.GET_LS_INSURANCE_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            insuranceData: response,
        },
    }),
    [types.CHECK_EPR_PAY_ACTION]: (state: AccountReducerType): AccountReducerType => ({
        ...state,
        prEpr: false,
        prSbp: false,
    }),
    [types.CHECK_EPR_PAY_ACTION_SUCCESS]: (state: AccountReducerType, action): AccountReducerType => ({
        ...state,
        prEpr: action.response.prEnable,
        prSbp: action.response.prSbp,
    }),
    [types.CRM_CHECK_CHANGE_TARIFF_ISU]: (state: AccountReducerType): AccountReducerType => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            minimalFunctions: {
                ...state.accountDetail.minimalFunctions,
                prTariffIsu: false,
            },
        },
    }),
    [types.CRM_CHECK_CHANGE_TARIFF_ISU_SUCCESS]: (state: AccountReducerType, {response}): AccountReducerType => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            minimalFunctions: {
                ...state.accountDetail.minimalFunctions,
                prTariffIsu: response?.prAvail,
            },
        },
    }),
    [types.GET_TABS_ACTION_SUCCESS]: (state: AccountReducerType, action): AccountReducerType => ({
        ...state,
        visibleTabs: action.response,
    }),
    [types.CHK_LS_REPORT_DETAIL_AVAIL_ACTION_SUCCESS]: (
        state: AccountReducerType,
        {response: {prDetailAvail, nmResult}},
    ): AccountReducerType => {
        const detailUnavailInfo = prDetailAvail ? "" : nmResult;

        return {
            ...state,
            detailUnavailInfo,
            prDetailAvail,
        };
    },
    [types.LS_LOAD_DATA_MULTIPLY_SUCCESS]: (
        state: AccountReducerType,
        {payload: {resultData, name}},
    ): AccountReducerType => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            [name]: resultData,
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            [name]: false,
        },
    }),
    [types.CHK_REPORT_PASP_ACTION_SUCCESS]: (state: AccountReducerType, action): AccountReducerType => ({
        ...state,
        prNeedPasport: action.response.prNeedPasport,
    }),
    [types.GET_LS_REPORT_HIST_ACTION]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            lsReportHist: [],
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            lsReportHist: true,
        },
    }),
    [types.GET_LS_REPORT_HIST_ACTION_SUCCESS]: (state: AccountReducerType, action): AccountReducerType => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            lsReportHist: action.response.vlServiceList,
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            lsReportHist: false,
        },
    }),
    [types.GET_LS_REPORT_HIST_ACTION_FAILURE]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            lsReportHist: [],
        },
        fetchingAccountDetail: {
            ...state.fetchingAccountDetail,
            lsReportHist: false,
        },
    }),
    [types.GET_LK_PROVIDER_FUNCTION_ACTION]: (state: AccountReducerType): AccountReducerType => ({
        ...state,
        functions: {},
    }),
    [types.GET_LK_PROVIDER_FUNCTION_ACTION_SUCCESS]: (state: AccountReducerType, {response}): AccountReducerType => ({
        ...state,
        functions: response.length
            ? Object.fromEntries(
                  response.map(({kdFunction, nmNameFunction, prEnabled}) => [
                      kdFunction,
                      {name: nmNameFunction, pr: prEnabled},
                  ]),
              )
            : {},
    }),
    [types.UCM_GET_METER_DATA_ACTION]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            minimalFunctions: {
                ...state.accountDetail.minimalFunctions,
                readings: {
                    fetching: true,
                    data: [],
                    link: undefined,
                    notice: undefined,
                },
            },
        },
    }),
    [types.UCM_GET_METER_DATA_ACTION_SUCCESS]: (state: AccountReducerType, {response}): AccountReducerType => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            minimalFunctions: {
                ...state.accountDetail.minimalFunctions,
                readings: {
                    fetching: false,
                    data: parseReadingsData(response[0]?.vlMeter?.filter(Boolean)),
                    link: response[0]?.linkNotice,
                    notice: response[0]?.vlNotice,
                },
            },
        },
    }),
    [types.UCM_GET_METER_DATA_ACTION_FAILURE]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            minimalFunctions: {
                ...state.accountDetail.minimalFunctions,
                readings: {
                    fetching: false,
                    data: [],
                    link: undefined,
                    notice: undefined,
                },
            },
        },
    }),
    [types.UCM_GET_LIST_EVENTS_ACTION]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            minimalFunctions: {
                ...state.accountDetail.minimalFunctions,
                journal: {
                    ...state.accountDetail.minimalFunctions.journal,
                    fetching: true,
                    data: [],
                    link: undefined,
                    notice: undefined,
                },
            },
        },
    }),
    [types.UCM_GET_LIST_EVENTS_ACTION_SUCCESS]: (state: AccountReducerType, {response}): AccountReducerType => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            minimalFunctions: {
                ...state.accountDetail.minimalFunctions,
                journal: {
                    ...state.accountDetail.minimalFunctions.journal,
                    fetching: false,
                    data: response[0]?.meterEventTypes?.filter(Boolean) || [],
                    link: response[0]?.linkNotice,
                    notice: response[0]?.vlNotice,
                },
            },
        },
    }),
    [types.UCM_GET_LIST_EVENTS_ACTION_FAILURE]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            minimalFunctions: {
                ...state.accountDetail.minimalFunctions,
                journal: {
                    ...state.accountDetail.minimalFunctions.journal,
                    fetching: false,
                    data: [],
                    link: undefined,
                    notice: undefined,
                },
            },
        },
    }),
    [types.UCM_GET_MEASURINGS_GROUP_ACTION]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            minimalFunctions: {
                ...state.accountDetail.minimalFunctions,
                measurings: {
                    ...state.accountDetail.minimalFunctions.measurings,
                    groups: {
                        data: [],
                        fetching: true,
                        loaded: false,
                    },
                },
            },
        },
    }),
    [types.UCM_GET_MEASURINGS_GROUP_ACTION_SUCCESS]: (state: AccountReducerType, {response}): AccountReducerType => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            minimalFunctions: {
                ...state.accountDetail.minimalFunctions,
                measurings: {
                    ...state.accountDetail.minimalFunctions.measurings,
                    groups: {
                        data: response?.vlMeasuringsGroup || [],
                        fetching: false,
                        loaded: true,
                    },
                },
            },
        },
    }),
    [types.UCM_GET_MEASURINGS_GROUP_ACTION_FAILURE]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            minimalFunctions: {
                ...state.accountDetail.minimalFunctions,
                measurings: {
                    ...state.accountDetail.minimalFunctions.measurings,
                    groups: {
                        data: [],
                        fetching: false,
                        loaded: false,
                    },
                },
            },
        },
    }),

    [types.CRM_CHECK_COMPLAINT_POWER_SUPPLY_ENABLED_ACTION]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            requisites: {
                ...state.accountDetail.requisites,
                complaintPowerSupply: {
                    ...state.accountDetail.requisites.complaintPowerSupply,
                    available: false,
                    hintText: undefined,
                },
            },
        },
    }),
    [types.CRM_CHECK_COMPLAINT_POWER_SUPPLY_ENABLED_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            requisites: {
                ...state.accountDetail.requisites,
                complaintPowerSupply: {
                    ...state.accountDetail.requisites.complaintPowerSupply,
                    available: Boolean(response?.prComplaint),
                    hintText: response?.prHint ? response?.nmResult : undefined,
                },
            },
        },
    }),
    [types.CRM_CHECK_COMPLAINT_POWER_SUPPLY_ENABLED_ACTION_FAILURE]: (state) => ({
        ...state,
        accountDetail: {
            ...state.accountDetail,
            requisites: {
                ...state.accountDetail.requisites,
                complaintPowerSupply: {
                    ...state.accountDetail.requisites.complaintPowerSupply,
                    available: false,
                    hintText: undefined,
                },
            },
        },
    }),
});
