// @flow
import loader from "../images/loader/loader.gif";

export const loaderString = `<div id="splash-screen" style="position:${""}
absolute; top: 50%; left: 50%; margin: -100px 0 0 -100px"><img width=200 src=${loader} /></div>`;

export const downloadingPaymentLoaderString = `<div id="splash-screen" 
    style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
        <img style="position: relative; right: -25px;" width=200 src=${loader} />
        <p style="font-size: 20px; color: blue; margin: 0 auto;">
            Получение файла квитанции...
        </p>
    </div>`;
