// @flow

import {ONE_MB_IN_BYTES} from "../../../constants/Application";

export const checkFileExtension = (file: File, fileExtensions?: Array<string>) => {
    const fileExtension = file.name.split(".").pop();

    return fileExtensions?.length ? fileExtensions.indexOf(fileExtension.toLowerCase()) !== -1 : true;
};

export const checkFileSize = (fileSize: number, maxFileSizeMb?: number) =>
    maxFileSizeMb ? fileSize < maxFileSizeMb * ONE_MB_IN_BYTES : true;
