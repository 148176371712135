/* eslint-disable global-require */

import {ElectricityIcon} from "../packages/components/Icons/ElectricityIcon";
import {EpdIcon} from "../packages/components/Icons/EpdIcon";
import {HeatingIcon} from "../packages/components/Icons/HeatingIcon";

export const accountIcons = {
    1: ElectricityIcon,
    2: EpdIcon,
    3: HeatingIcon,
};
