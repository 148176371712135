// @flow
import * as actionTypes from "../../constants/ActionTypes";
import {createReducer} from "../../../../redux/reducers/createReducer";
import {baseDataActionSuccess, type BaseDataReducerType} from "./baseReducers";

const initialState: BaseDataReducerType = {
    modalPageElements: [],
};

export const builderExampleReducer = createReducer(initialState, {
    [actionTypes.EXAMPLE_BUILDER_ACTION_SUCCESS]: baseDataActionSuccess,
});
