// @flow
import {type themeTypes} from "../../../themeStyle";

export const styles = (theme: themeTypes) => ({
    icon: {
        fontSize: 14,
        position: "absolute",
        right: 0,
        top: 11,
    },
    payAdditionalServicesBtn: {
        border: "1px solid white",
        borderRadius: "4px",
        padding: "2px 6px",
    },
    root: {
        boxSizing: "border-box",
        color: theme.palette.text.white,
        height: "60px",
        padding: "0 8px",
        verticalAlign: "baseline",
    },
    selected: {
        "&>p": {
            marginTop: "4px",
        },
        borderBottom: `4px solid ${theme.palette.common.white}`,
    },
});
