// @flow

export const styles = (theme: any) => ({
    dividerBlock: {
        background: theme.palette.grey.dark,
        minHeight: 1,
        width: "100%",
    },
    dividerMainBlock: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
    },
    dividerText: {
        color: theme.palette.grey.dark,
        margin: "0 10px",
    },
    fullWidth: {
        width: "100%",
    },
});
