// @flow
import React, {PureComponent} from "react";
import {withStyles} from "@material-ui/core/styles";
import {type themeTypes} from "../../../themeStyle";
import getFieldInstance from "./PatternParsed";

export type BPatternSrc = {
    key: string,
    type: string,
    format?: string,
    precision?: string | number,
};

type Props = {
    data: Object,
    pattern: string,
    src: Array<BPatternSrc>,
    theme?: themeTypes,
    renderElement?: (Object) => React$Node | string,
};

export class BPattern extends PureComponent<Props> {
    static defaultProps = {
        pattern: "",
        src: [],
    };

    render() {
        const {data, pattern, src, theme, renderElement} = this.props;

        if (renderElement) {
            const renderData = renderElement(data);

            return typeof renderData === "string" ? (
                <span dangerouslySetInnerHTML={{__html: renderData}} />
            ) : (
                renderData
            );
        }

        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: getFieldInstance({
                        data,
                        pattern,
                        src,
                        theme,
                    }),
                }}
            />
        );
    }
}

export default withStyles({}, {withTheme: true})(BPattern);
