// @flow
import moment from "moment/moment";

type GroupToAttributeParamsType = Array<{
    nmLsGroup: string,
    isDefault?: boolean,
}>;

export const groupToAttribute = (groups: GroupToAttributeParamsType) => {
    const attribute: Object = {
        nmAttrDataType: "INT",
        nmAttrType: "DICT",
        nmAttribute: "Группа",
        nmColumn: "KD_LS_OWNER",
        prBase: false,
        prRequired: true,
        prVisible: true,
    };
    const vlDict = [];

    groups.forEach((group, index) => {
        vlDict.push({nmValue: group.nmLsGroup, nnCode: index});

        if (group.isDefault) {
            attribute.vlDefault = index;
        }
    });

    attribute.vlDict = vlDict;

    return attribute;
};

type GroupByDayParamItemType = {
    vlMessages?: Array<{
        dtMessage: string,
    }>,
    dtCreate: string,
};

type GroupByDayParamsType = Array<GroupByDayParamItemType>;

type GroupByDayParamsReturnType = Array<{
    dataArray: Array<GroupByDayParamItemType>,
    date: string,
}>;

export const groupByDay = (
    data: GroupByDayParamsType,
    sort: "reverse" | "normal" = "normal",
): GroupByDayParamsReturnType => {
    const newArr = [];

    data.forEach((item) => {
        if (item.vlMessages) {
            if (sort === "reverse") {
                item.vlMessages.sort((item1, item2) => moment(item1.dtMessage) - moment(item2.dtMessage));
            } else {
                item.vlMessages.sort((item1, item2) => moment(item2.dtMessage) - moment(item1.dtMessage));
            }
        }

        const newArrItemDtCreate = newArr.length === 0 ? "" : newArr[newArr.length - 1].date;

        if (moment(item.dtCreate).isSame(newArrItemDtCreate, "day")) {
            newArr[newArr.length - 1].dataArray.push(item);
        } else {
            newArr.push({
                dataArray: [{...item}],
                date: item.dtCreate,
            });
        }
    });

    return newArr;
};
