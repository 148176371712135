// @flow
import * as actionTypes from "../../constants/types/ActionTypes";
import {createReducer} from "./createReducer";

export type CrmChangePswReducerType = {
    openModal?: boolean,
    idProfile?: string,
    nmLink?: string,
    login?: string,
    vlToken?: string,
};
const initialState: CrmChangePswReducerType = {
    idProfile: "",
    login: "",
    nmLink: "",
    openModal: false,
    vlToken: "",
};

export const crmChangePswReducer = createReducer(initialState, {
    [actionTypes.CRM_CHECK_PHONE_CONFIRM_ACTION_SUCCESS]: (state: CrmChangePswReducerType, {response}) => ({
        ...state,
        vlToken: response.vlToken,
    }),
    [actionTypes.OPEN_CHANGE_PSW_MODAL_ACTION]: (state: CrmChangePswReducerType, {payload}) => ({
        ...payload,
        openModal: true,
    }),
    [actionTypes.CLOSE_CHANGE_PSW_MODAL_ACTION]: () => initialState,
});
