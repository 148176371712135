// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu/Menu";
import TextField from "../../components/Form/TextField";
import SelectInputItem from "../../components/Icons/SelectInputIcon";

const iconWidth = 24;
const inputWidth = `calc(100% - ${iconWidth}px)`;

const styles = () => ({
    menuInnerContent: {
        "&:focus": {
            outline: "none",
        },
        overflowY: "auto",
    },
});

type State = {
    anchorEl: HTMLElement | null,
    width: number,
    maxHeight: string,
};

type Props = {
    inputValue: string,
    config: Object,
    InputProps: Object,
    label: string,
    error: boolean,
    helperText: string,
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    testID?: string,
    ComponentBody: (props: Object) => React.Node,
    meta?: Object,
};

class DropDownMenu extends React.Component<Props, State> {
    input: HTMLInputElement;

    state = {
        anchorEl: null,
        maxHeight: "",
        width: 0,
    };

    handleOpenMenu = () => {
        const {
            config: {disabled},
        } = this.props;
        const element = this.input;
        const clientRect: Object = element.getBoundingClientRect();

        if (disabled) {
            return null;
        }
        this.setState({
            anchorEl: this.input,
            maxHeight: `calc(100vh - ${clientRect.top}px)`,
            width: element && "offsetWidth" in element ? element.offsetWidth + iconWidth : 0,
        });

        return true;
    };

    handleClose = ({value}: Object) => {
        this.setState({
            anchorEl: null,
        });
        if (value !== undefined) {
            this.props.InputProps.onChange(value);
        }
    };

    getInstanceOfInput = (ref: any) => {
        this.input = ref;
    };

    render() {
        const {anchorEl, width, maxHeight} = this.state;
        const {
            label,
            error,
            helperText,
            config: {prRequired, disabled},
            classes = {},
            testID,
            ComponentBody,
            inputValue,
            meta,
            InputProps: {name, onBlur, onFocus},
        } = this.props;

        return (
            <React.Fragment>
                <TextField
                    testID={testID}
                    InputProps={{
                        name,
                        onBlur,
                        onFocus,
                        readOnly: true,
                        style: {
                            cursor: "pointer",
                            width: inputWidth,
                        },
                        value: inputValue,
                    }}
                    disabled={disabled}
                    onClick={this.handleOpenMenu}
                    inputRef={this.getInstanceOfInput}
                    label={label}
                    type="text"
                    selectIcon
                    error={error}
                    helperText={helperText}
                    required={prRequired}
                    InputIcon={SelectInputItem}
                    meta={meta}
                />
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
                    <div className={classes.menuInnerContent} style={{maxHeight, width}}>
                        <ComponentBody handleClose={this.handleClose} />
                    </div>
                </Menu>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DropDownMenu);
