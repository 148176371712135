// @flow
import React from "react";
import {Paper, ClickAwayListener} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {withStyles} from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import SearchIcon from "@material-ui/icons/Search";
import cn from "classnames";
import MenuItem from "@material-ui/core/MenuItem";
import {Typography} from "../../components/Typography/Typography";
import TextField from "../../components/Form/TextField";

const styles = (theme: any) => ({
    btn: {
        alignItems: "center",
        border: `1px solid ${theme.palette.grey.coolGray1}`,
        borderRadius: 3,
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        height: 44,
    },
    btnError: {
        border: `1px solid ${theme.palette.error.main}`,
    },
    btnName: {
        fontSize: 16,
        fontWeight: 200,
        overflow: "hidden",
        padding: 16,
        textAlign: "start",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
    },
    collapseContent: {
        position: "absolute",
        transformOrigin: "0 0 0",
        zIndex: 1,
    },
    dropDown: {
        position: "relative",
        zIndex: "2",
    },
    helperText: {
        color: theme.palette.error.main,
        fontSize: 12,
    },
    icon: {
        display: "block",
        paddingRight: 16,
        transition: "transform linear 200ms",
    },
    iconButton: {
        color: theme.palette.text.white,
        display: "block",
        height: `calc(${theme.sizing.appbarHeight}px - 5px)`,
        transition: "transform 300ms ease-in-out",
    },
    iconRotate: {
        transform: "rotate(-180deg)",
    },
    label: {
        color: "rgba(0,  0, 0, 0.54)",
        cursor: "default",
    },
    labelError: {
        color: theme.palette.error.main,
    },
    mainContent: {
        "&:hover": {
            background: theme.palette.topMenu.selected,
        },
        background: theme.palette.secondary.light2,
        borderLeft: `1px solid ${theme.palette.topMenu.separator}`,
        boxSizing: "border-box",
        cursor: "pointer",
        display: "flex",
        minHeight: theme.sizing.appbarHeight,
        padding: "0px 16px",
        [theme.breakpoints.down("md")]: {
            width: "auto",
        },
    },
    paperContainer: {
        maxHeight: "calc(100% - 96px)",
        overflowY: "auto",
    },
    searchField: {
        margin: "16px 16px 0 16px",
        position: "sticky",
        top: 0,
        width: "calc(100% - 32px)",
        zIndex: 1,
    },
});

export const FieldComboWithSearch = (props: any) => {
    const {classes, config, InputProps, error, helperText, ...fieldProps} = props;
    const fieldRef = React.useRef<HTMLElement | null>(null);
    const {value} = InputProps;
    const {vlDict} = config;
    const [open, setOpen] = React.useState(false);
    const [displayedValue, setDisplayedValue] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState("");
    const [filteredDict, setFilteredDict] = React.useState([]);
    const handleToggle = () => {
        setOpen(!open);
    };
    // eslint-disable-next-line no-magic-numbers
    const handleClose = () => {
        if (open) {
            setTimeout(() => setOpen(false), 0);
        }
    };
    const handleSelectItem = (newValue) => {
        InputProps.onChange(newValue);
        handleClose();
    };
    const handleSearch = (event: any) => {
        const {value: valueSearch} = event.target;
        const {allowedSymbols} = props;

        if (allowedSymbols) {
            const reg = new RegExp(allowedSymbols);

            if (!reg.test(valueSearch)) {
                return false;
            }
        }
        setSearchValue(valueSearch);

        return false;
    };

    React.useEffect(() => {
        const selectedItem = vlDict.find((item) => item.nnCode === value);

        setDisplayedValue(selectedItem?.nmValue);
    }, [value]);

    React.useEffect(() => {
        // eslint-disable-next-line no-magic-numbers
        setTimeout(() => setSearchValue(""), 100);
    }, [open]);

    React.useEffect(() => {
        if (searchValue) {
            setFilteredDict(
                vlDict.filter((item) => item.nmValue.toLowerCase().indexOf(searchValue.toLowerCase()) > -1),
            );
        } else {
            setFilteredDict([...vlDict]);
        }
    }, [searchValue]);

    return (
        <>
            <div
                className={cn(classes.label, {
                    [classes.labelError]: error,
                })}
            >{`${config.nmAttribute} ${config.prRequired ? "*" : ""}`}</div>
            <div
                className={cn(classes.btn, {
                    [classes.btnError]: error,
                })}
                onClick={handleToggle}
                ref={fieldRef}
            >
                <Typography className={classes.btnName} align="center" fontSize={22}>
                    {displayedValue}
                </Typography>
                <ArrowDropDownIcon
                    className={cn(classes.icon, {
                        [classes.iconRotate]: open,
                    })}
                />
            </div>
            {error && helperText ? <div className={classes.helperText}>{helperText}</div> : null}
            <Popover
                anchorEl={fieldRef.current}
                open={open}
                classes={{
                    paper: classes.popoverPaper,
                }}
                ModalClasses={{
                    root: classes.popoverModal,
                }}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                }}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                onClose={handleClose}
                disableAutoFocus
                disableEnforceFocus
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Paper>
                        <TextField
                            InputProps={{endAdornment: <SearchIcon />}}
                            className={classes.searchField}
                            onChange={handleSearch}
                            value={searchValue}
                        />
                        <div style={{marginTop: 20, minHeight: 200, width: fieldRef?.current?.clientWidth || 0}}>
                            {filteredDict?.length ? (
                                filteredDict.map(({nnCode, nmValue, kdDict, nmDict}, index) => (
                                    <MenuItem
                                        value={nnCode === undefined || InputProps.value === null ? kdDict : nnCode}
                                        key={nnCode || kdDict}
                                        testid={`${fieldProps.testID}-${index}`}
                                        onClick={() => handleSelectItem(nnCode)}
                                        selected={value === nnCode}
                                    >
                                        {nmValue || nmDict}
                                    </MenuItem>
                                ))
                            ) : (
                                <Typography className={classes.btnName} align="center" fontSize={22}>
                                    Нет данных для отображения
                                </Typography>
                            )}
                        </div>
                    </Paper>
                </ClickAwayListener>
            </Popover>
        </>
    );
};

export default withStyles(styles)(FieldComboWithSearch);
