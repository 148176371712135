/* eslint-disable sort-keys, max-lines */
// @flow
import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Async from "react-code-splitting";
import type {Location} from "react-router-dom";
import {type BrowserHistory} from "history/createBrowserHistory";
import {
    loadAccountAction,
    deleteAccountAction,
    checkScheduledInspectionAction,
    getScheduledInspectionAction,
    confirmScheduledInspectionAction,
    checkNotifyKsgOverPayAction,
    saveNotifyKsgOverPayAction,
    saveNotifyKsgOverPayNAction,
    getTabsAction,
    chkReportPaspAction,
    getLSReportHistAction,
    type LoadAccountActionType,
    type DeleteAccountActionType,
    type CheckScheduledInspectionActionType,
    type GetScheduledInspectionActionType,
    type ConfirmScheduledInspectionActionType,
    type CheckNotifyKsgOverPayActionType,
    type SaveNotifyKsgOverPayActionType,
    type SaveNotifyKsgOverPayNActionType,
    type GetTabsActionType,
    type ChkReportPaspActionType,
    type GetLSReportHistActionType,
} from "../redux/actions/accountAction";
import {getBuilderElementsAction, type BuilderGetElementsType} from "../redux/actions/builderAction";
import {getMultipleLSPayVisibility, type GetMultipleLSPayVisibilityActionType} from "../redux/actions/configAction";
import UnSupportedProviderPage from "../packages/components/UnSupported/UnSupportedProvider";
import {type StoreType} from "../redux/reducers";
import {
    MES_KD_PROVIDER,
    MOE_KD_PROVIDER,
    TMK_RTS_KD_PROVIDER,
    TMK_NRG_KD_PROVIDER,
    UFA_KD_PROVIDER,
    TKO_KD_PROVIDER,
    VLG_KD_PROVIDER,
    ORL_EPD_KD_PROVIDER,
    ORL_KD_PROVIDER,
    ALT_KD_PROVIDER,
    SAR_KD_PROVIDER,
    TMB_KD_PROVIDER,
    VLD_KD_PROVIDER,
    KSG_KD_PROVIDER,
} from "../constants/KdProvider";
import UnsupportedLs from "../packages/components/UnSupported/UnsupportedLs";
import ProfilePhoneAuthConfirm from "../components/Profile/ProfilePhoneAuthConfirmSteps/ProfilePhoneAuthConfirm";
import {
    isConfirmAction,
    profileEditAction,
    type IsConfirmActionType,
    type ProfileEditActionType,
} from "../redux/actions/profileAction";
import {
    phoneChangeConfirmAction,
    phoneSendSmsConfirmRepeatAction,
    sendSmsPhoneChangeRepeatAction,
    type PhoneChangeConfirmActionType,
    type PhoneSendSmsConfirmRepeatActionType,
    type SendSmsPhoneChangeRepeatActionType,
} from "../redux/actions/phoneAction";
import ProfilePhoneEmailConfirm from "../components/Profile/ProfilePhoneEmailConfirm";
import {authPhoneConfirmAction, type AuthPhoneConfirmActionType} from "../redux/actions/authAction";
import ScheduledInspectionModal from "../components/account/ScheduledInspectionModal/ScheduledInspectionModal";
import NotifyModal from "../components/account/NotifyModal/NotifyModal";
import WelcomePage from "./Welcome/WelcomePage";

type StoreProps = {|
    accountDetail: $PropertyType<$PropertyType<StoreType, "account">, "accountDetail">,
    accountsList: $PropertyType<$PropertyType<StoreType, "account">, "accountsList">,
    auth: $PropertyType<StoreType, "auth">,
    profileBuilder: $PropertyType<$PropertyType<StoreType, "builder">, "profile">,
    lsNoticeStatusList: Object,
    session: string,
    kdProvider: number,
|};

type DispatchProps = {|
    getBuilderElementsAction: BuilderGetElementsType,
    loadAccountAction: LoadAccountActionType,
    deleteAccountAction: DeleteAccountActionType,
    getMultipleLSPayVisibilityAction: GetMultipleLSPayVisibilityActionType,
    profileEditAction: ProfileEditActionType,
    phoneSendSmsConfirmRepeatAction: PhoneSendSmsConfirmRepeatActionType,
    sendSmsPhoneChangeRepeatAction: SendSmsPhoneChangeRepeatActionType,
    isConfirmAction: IsConfirmActionType,
    authPhoneConfirmAction: AuthPhoneConfirmActionType,
    phoneChangeConfirmAction: PhoneChangeConfirmActionType,
    checkScheduledInspectionAction: CheckScheduledInspectionActionType,
    getScheduledInspectionAction: GetScheduledInspectionActionType,
    confirmScheduledInspectionAction: ConfirmScheduledInspectionActionType,
    checkNotifyKsgOverPayAction: CheckNotifyKsgOverPayActionType,
    saveNotifyKsgOverPayAction: SaveNotifyKsgOverPayActionType,
    saveNotifyKsgOverPayNAction: SaveNotifyKsgOverPayNActionType,
    getTabsAction: GetTabsActionType,
    chkReportPaspAction: ChkReportPaspActionType,
    getLSReportHistAction: GetLSReportHistActionType,
|};

type OwnProps = {|
    history: BrowserHistory,
    match: {
        url: string,
        params: {
            accountId: string,
        },
    },
    location: Location,
    toConfirmPhone?: boolean,
|};

type State = {
    isConfirmModalOpen: boolean,
    isConfirmPhoneModalOpen: boolean,
    isCurrentPhone: boolean,
    phoneAttribute: Object,
    phone: string,
    toConfirmPhone: boolean,
    isScheludeInspectionModalOpen: boolean,
    scheludeInspectionData: Array<Object>,
    isNotifyModalOpen: boolean,
    notifyData: Array<Object>,
};

export type Props = {...OwnProps, ...StoreProps, ...DispatchProps};

const AsyncAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_EXAMPLE === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/example/pages/routes" /* webpackChunkName: "Example" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AsyncTmkrtsAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_TMKRTS === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/tmkrtsprovider/pages/routes" /* webpackChunkName: "Tmkrts" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AsyncOrlEpdAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_ORLEPD === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/orlepdprovider/pages/routes" /* webpackChunkName: "OrlEpd" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AsyncTmknrgAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_TMKNRG === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/tmknrgprovider/pages/routes" /* webpackChunkName: "Tmknrg" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AsyncMoeAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_MOE === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/moeprovider/pages/routes" /* webpackChunkName: "Moe" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );
const AsyncMesAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_MES === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/mesprovider/pages/routes" /* webpackChunkName: "Mes" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AsyncUfaAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_UFA === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/ufaprovider/pages/routes" /* webpackChunkName: "Ufa" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AsyncTkoAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_TKO === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/tkoprovider/pages/routes" /* webpackChunkName: "Tko" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AsyncVlgAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_VLG === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/vlgprovider/pages/routes" /* webpackChunkName: "Vlg" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AsyncAltAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_ALT === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/altprovider/pages/routes" /* webpackChunkName: "Alt" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AsyncOrlAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_ORLNRG === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/orlprovider/pages/routes" /* webpackChunkName: "Orl" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AsyncSarAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_SAR === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/sarprovider/pages/routes" /* webpackChunkName: "Sar" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AsyncTmbAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_TMB === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/tmbprovider/pages/routes" /* webpackChunkName: "Tmb" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AsyncVldAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_VLD === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/vldprovider/pages/routes" /* webpackChunkName: "Vld" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AsyncKsgAccountPage = (props: Props) =>
    process.env.REACT_APP_PROVIDER_KSG === "on" ? (
        <Async
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("../providers/ksgprovider/pages/routes" /* webpackChunkName: "Ksg" */)}
            componentProps={props}
        />
    ) : (
        <UnSupportedProviderPage />
    );

const AccountPages = {
    [MES_KD_PROVIDER]: AsyncMesAccountPage,
    [MOE_KD_PROVIDER]: AsyncMoeAccountPage,
    [ORL_KD_PROVIDER]: AsyncOrlAccountPage,
    [ORL_EPD_KD_PROVIDER]: AsyncOrlEpdAccountPage,
    [TMK_NRG_KD_PROVIDER]: AsyncTmknrgAccountPage,
    [TMK_RTS_KD_PROVIDER]: AsyncTmkrtsAccountPage,
    [TKO_KD_PROVIDER]: AsyncTkoAccountPage,
    [UFA_KD_PROVIDER]: AsyncUfaAccountPage,
    [VLG_KD_PROVIDER]: AsyncVlgAccountPage,
    [ALT_KD_PROVIDER]: AsyncAltAccountPage,
    [SAR_KD_PROVIDER]: AsyncSarAccountPage,
    [TMB_KD_PROVIDER]: AsyncTmbAccountPage,
    [VLD_KD_PROVIDER]: AsyncVldAccountPage,
    [KSG_KD_PROVIDER]: AsyncKsgAccountPage,
};

export class AccountMainPage extends React.Component<Props, State> {
    state = {
        isConfirmModalOpen: false,
        isConfirmPhoneModalOpen: false,
        isCurrentPhone: false,
        phoneAttribute: {},
        phone: "",
        toConfirmPhone: false,
        isScheludeInspectionModalOpen: false,
        scheludeInspectionData: [],
        isNotifyModalOpen: false,
        notifyData: [],
    };

    componentDidMount() {
        const {
            accountsList,
            history,
            session,
            match: {
                params: {accountId},
            },
        } = this.props;
        const account = this.getCurrentAccount(this.props);

        this.setState({
            toConfirmPhone: Boolean(this.props.location?.state?.toConfirmPhone),
        });
        this.props.getTabsAction(session);
        if (account.kdProvider) {
            this.props.loadAccountAction(accountId, this.props.session, account.kdProvider);
            this.props.chkReportPaspAction(this.props.session);
            this.loadSchelude();
            this.loadNotify();
        } else if (accountId !== "example" && accountsList.length !== 0) {
            if (accountId === "0") {
                history.replace(`/accounts/${accountsList[0].idService}`, {});
            } else {
                history.push(`/accounts/${accountsList[0].idService}`);
            }
        }
    }

    deleteLsHandler = async (idService: string) => {
        const res = await this.props.deleteAccountAction(idService, this.props.session);

        if (!res.error) {
            await this.props.getMultipleLSPayVisibilityAction(this.props.session);
        }
    };

    getCurrentAccount = (props: Props) => {
        const {
            accountsList,
            match: {
                params: {accountId},
            },
        } = props;

        return accountsList.find(({idService}) => idService.toString() === accountId) || {};
    };

    componentDidUpdate(prevProps: Props) {
        const {accountId} = this.props.match.params;

        if (
            this.props.profileBuilder.groupByColumn?.NN_PHONE &&
            (this.state.toConfirmPhone || this.props.location?.state?.toConfirmPhone) &&
            !this.state.isConfirmModalOpen &&
            !this.state.isConfirmPhoneModalOpen
        ) {
            this.setState({
                phoneAttribute: this.props.profileBuilder.groupByColumn.NN_PHONE,
            });
            this.setState({
                isConfirmPhoneModalOpen: true,
            });
        }
        if (accountId !== prevProps.match.params.accountId) {
            const account = this.getCurrentAccount(this.props);

            this.loadSchelude();
            this.loadNotify();

            this.props.chkReportPaspAction(this.props.session);
            if (account.kdProvider) {
                this.props.loadAccountAction(accountId, this.props.session, account.kdProvider);
            }
            if (account.kdProvider !== prevProps.kdProvider) {
                this.props.history.replace(this.props.location.pathname, {});
            }
        }
    }

    loadSchelude = async () => {
        const {session} = this.props;
        const {idService, kdProvider} = this.getCurrentAccount(this.props);

        if (kdProvider === MES_KD_PROVIDER) {
            const res = await this.props.checkScheduledInspectionAction(idService, session);

            if (!res.error && res?.response?.vlJson?.length) {
                this.setState({
                    scheludeInspectionData: res.response.vlJson,
                    isScheludeInspectionModalOpen: true,
                });
            } else {
                this.setState({
                    scheludeInspectionData: [],
                });
            }
            await this.props.getScheduledInspectionAction(idService, session);
        }
    };

    loadNotify = async () => {
        const {session} = this.props;
        const {idService, kdProvider} = this.getCurrentAccount(this.props);

        if (kdProvider === MES_KD_PROVIDER) {
            const res = await this.props.checkNotifyKsgOverPayAction(idService, session);

            if (!res.error && res?.response?.vlData?.length) {
                this.setState({
                    notifyData: res.response.vlData,
                    isNotifyModalOpen: true,
                });
            } else {
                this.setState({
                    notifyData: [],
                });
            }
        }
    };

    handleCloseProfilePhoneAuthModal = () => {
        this.props.history.push(this.props.location.pathname);
        this.setState({
            isConfirmPhoneModalOpen: false,
            toConfirmPhone: false,
        });
    };

    handleConfirmPhoneAuth = async (isChange: boolean, value: string) => {
        this.props.history.push(this.props.location.pathname);
        this.setState({
            toConfirmPhone: false,
        });
        if (isChange) {
            const res = await this.props.sendSmsPhoneChangeRepeatAction(value, this.props.session);

            if (!res.error) {
                this.setState({
                    isConfirmModalOpen: true,
                    isCurrentPhone: false,
                    phone: value,
                });
            }
        } else {
            const res = await this.props.phoneSendSmsConfirmRepeatAction(value, this.props.auth.idProfile);

            if (!res.error) {
                this.setState({
                    isConfirmModalOpen: true,
                    isCurrentPhone: true,
                    phone: value,
                });
            }
        }

        this.setState({
            isConfirmPhoneModalOpen: false,
        });
    };

    handleCloseConfirmModal = () => {
        this.props.history.push(this.props.location.pathname);
        this.setState({
            isConfirmModalOpen: false,
            toConfirmPhone: false,
        });
    };

    handleConfirmScheludeInspection = (idSchedule: string) => {
        const {session} = this.props;
        const {idService} = this.getCurrentAccount(this.props);

        this.props.confirmScheduledInspectionAction(idService, idSchedule, session);
    };

    handleCloseScheludeInspectionModal = () => {
        this.setState({
            isScheludeInspectionModalOpen: false,
        });
    };

    handleConfirmNotify = (idNotify: string) => {
        const {session} = this.props;
        const {idService} = this.getCurrentAccount(this.props);

        this.props.saveNotifyKsgOverPayAction(idService, idNotify, session);
    };

    handleCloseNotifyModal = (idNotify?: string) => {
        const {session} = this.props;
        const {idService} = this.getCurrentAccount(this.props);

        if (idNotify) {
            this.props.saveNotifyKsgOverPayNAction(idService, idNotify, session);
        }
        this.setState({
            isNotifyModalOpen: false,
        });
    };

    render() {
        const {auth, accountsList, kdProvider, history, accountDetail} = this.props;
        const {accountId} = this.props.match.params;

        if (accountDetail && accountDetail.kdStatus === 2) {
            return (
                <UnsupportedLs
                    message={accountDetail.nmLockMsg}
                    deleteLsHandler={this.deleteLsHandler}
                    idService={accountDetail.idService}
                />
            );
        }

        if (accountId === "example") {
            return (
                <React.Fragment>
                    <AsyncAccountPage {...this.props} />
                    <ProfilePhoneAuthConfirm
                        auth={auth}
                        open={this.state.isConfirmPhoneModalOpen}
                        phoneEmailType="phone"
                        profileEditAction={this.props.profileEditAction}
                        attribute={this.state.phoneAttribute}
                        onClosePhoneEmailChange={this.handleCloseProfilePhoneAuthModal}
                        onChangePhone={this.handleConfirmPhoneAuth}
                        phoneChangeConfirmAction={this.props.phoneChangeConfirmAction}
                    />
                    <ProfilePhoneEmailConfirm
                        isConfirmAction={this.props.isConfirmAction}
                        open={this.state.isConfirmModalOpen}
                        phoneEmailType="phone"
                        attribute={this.state.phoneAttribute}
                        onClosePhoneEmailChange={this.handleCloseConfirmModal}
                        phone={this.state.phone}
                        isCurrentPhone={this.state.isCurrentPhone}
                        auth={this.props.auth}
                        sendSmsPhoneChangeRepeatAction={this.props.sendSmsPhoneChangeRepeatAction}
                        authPhoneConfirmAction={this.props.authPhoneConfirmAction}
                        phoneChangeConfirmAction={this.props.phoneChangeConfirmAction}
                        phoneSendSmsConfirmRepeatAction={this.props.phoneSendSmsConfirmRepeatAction}
                    />
                </React.Fragment>
            );
        }

        const AccountPageComponent = AccountPages[kdProvider];

        if (AccountPageComponent) {
            return (
                <React.Fragment>
                    <AccountPageComponent {...this.props} />
                    <ProfilePhoneAuthConfirm
                        auth={auth}
                        open={this.state.isConfirmPhoneModalOpen}
                        phoneEmailType="phone"
                        profileEditAction={this.props.profileEditAction}
                        attribute={this.state.phoneAttribute}
                        onClosePhoneEmailChange={this.handleCloseProfilePhoneAuthModal}
                        onChangePhone={this.handleConfirmPhoneAuth}
                        phoneChangeConfirmAction={this.props.phoneChangeConfirmAction}
                    />
                    <ProfilePhoneEmailConfirm
                        isConfirmAction={this.props.isConfirmAction}
                        open={this.state.isConfirmModalOpen}
                        phoneEmailType="phone"
                        attribute={this.state.phoneAttribute}
                        onClosePhoneEmailChange={this.handleCloseConfirmModal}
                        phone={this.state.phone}
                        isCurrentPhone={this.state.isCurrentPhone}
                        auth={this.props.auth}
                        sendSmsPhoneChangeRepeatAction={this.props.sendSmsPhoneChangeRepeatAction}
                        authPhoneConfirmAction={this.props.authPhoneConfirmAction}
                        phoneChangeConfirmAction={this.props.phoneChangeConfirmAction}
                        phoneSendSmsConfirmRepeatAction={this.props.phoneSendSmsConfirmRepeatAction}
                    />
                    <ScheduledInspectionModal
                        open={this.state.isScheludeInspectionModalOpen}
                        scheduledInspections={this.state.scheludeInspectionData}
                        onConfirm={this.handleConfirmScheludeInspection}
                        onClose={this.handleCloseScheludeInspectionModal}
                    />
                    <NotifyModal
                        open={this.state.isNotifyModalOpen}
                        notifyData={this.state.notifyData}
                        onConfirm={this.handleConfirmNotify}
                        onClose={this.handleCloseNotifyModal}
                    />
                </React.Fragment>
            );
        }

        if (accountsList.length !== 0) {
            return (
                <React.Fragment>
                    <UnSupportedProviderPage />
                    <ProfilePhoneAuthConfirm
                        auth={auth}
                        open={this.state.isConfirmPhoneModalOpen}
                        phoneEmailType="phone"
                        profileEditAction={this.props.profileEditAction}
                        attribute={this.state.phoneAttribute}
                        onClosePhoneEmailChange={this.handleCloseProfilePhoneAuthModal}
                        onChangePhone={this.handleConfirmPhoneAuth}
                        phoneChangeConfirmAction={this.props.phoneChangeConfirmAction}
                    />
                    <ProfilePhoneEmailConfirm
                        isConfirmAction={this.props.isConfirmAction}
                        open={this.state.isConfirmModalOpen}
                        phoneEmailType="phone"
                        attribute={this.state.phoneAttribute}
                        onClosePhoneEmailChange={this.handleCloseConfirmModal}
                        phone={this.state.phone}
                        isCurrentPhone={this.state.isCurrentPhone}
                        auth={this.props.auth}
                        sendSmsPhoneChangeRepeatAction={this.props.sendSmsPhoneChangeRepeatAction}
                        authPhoneConfirmAction={this.props.authPhoneConfirmAction}
                        phoneChangeConfirmAction={this.props.phoneChangeConfirmAction}
                        phoneSendSmsConfirmRepeatAction={this.props.phoneSendSmsConfirmRepeatAction}
                    />
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <WelcomePage history={history} />
                <ProfilePhoneAuthConfirm
                    auth={auth}
                    open={this.state.isConfirmPhoneModalOpen}
                    phoneEmailType="phone"
                    profileEditAction={this.props.profileEditAction}
                    attribute={this.state.phoneAttribute}
                    onClosePhoneEmailChange={this.handleCloseProfilePhoneAuthModal}
                    onChangePhone={this.handleConfirmPhoneAuth}
                    phoneChangeConfirmAction={this.props.phoneChangeConfirmAction}
                />
                <ProfilePhoneEmailConfirm
                    isConfirmAction={this.props.isConfirmAction}
                    open={this.state.isConfirmModalOpen}
                    phoneEmailType="phone"
                    attribute={this.state.phoneAttribute}
                    onClosePhoneEmailChange={this.handleCloseConfirmModal}
                    phone={this.state.phone}
                    isCurrentPhone={this.state.isCurrentPhone}
                    auth={this.props.auth}
                    sendSmsPhoneChangeRepeatAction={this.props.sendSmsPhoneChangeRepeatAction}
                    authPhoneConfirmAction={this.props.authPhoneConfirmAction}
                    phoneChangeConfirmAction={this.props.phoneChangeConfirmAction}
                    phoneSendSmsConfirmRepeatAction={this.props.phoneSendSmsConfirmRepeatAction}
                />
            </React.Fragment>
        );
    }
}

export default connect<Props, OwnProps, StoreProps, DispatchProps, StoreType, _>(
    ({auth, account, notification, builder}: StoreType): StoreProps => ({
        accountDetail: account.accountDetail,
        accountsList: account.accountsList,
        auth,
        kdProvider: account.accountDetail.kdProvider,
        lsNoticeStatusList: notification.lsNoticeStatusList,
        profileBuilder: builder.profile,
        session: auth.session || "",
    }),
    (dispatch): DispatchProps => ({
        deleteAccountAction: bindActionCreators(deleteAccountAction, dispatch),
        getBuilderElementsAction: bindActionCreators(getBuilderElementsAction, dispatch),
        loadAccountAction: bindActionCreators(loadAccountAction, dispatch),
        getMultipleLSPayVisibilityAction: bindActionCreators(getMultipleLSPayVisibility, dispatch),
        profileEditAction: bindActionCreators(profileEditAction, dispatch),
        phoneSendSmsConfirmRepeatAction: bindActionCreators(phoneSendSmsConfirmRepeatAction, dispatch),
        sendSmsPhoneChangeRepeatAction: bindActionCreators(sendSmsPhoneChangeRepeatAction, dispatch),
        isConfirmAction: bindActionCreators(isConfirmAction, dispatch),
        authPhoneConfirmAction: bindActionCreators(authPhoneConfirmAction, dispatch),
        phoneChangeConfirmAction: bindActionCreators(phoneChangeConfirmAction, dispatch),
        checkScheduledInspectionAction: bindActionCreators(checkScheduledInspectionAction, dispatch),
        getScheduledInspectionAction: bindActionCreators(getScheduledInspectionAction, dispatch),
        confirmScheduledInspectionAction: bindActionCreators(confirmScheduledInspectionAction, dispatch),
        checkNotifyKsgOverPayAction: bindActionCreators(checkNotifyKsgOverPayAction, dispatch),
        saveNotifyKsgOverPayAction: bindActionCreators(saveNotifyKsgOverPayAction, dispatch),
        saveNotifyKsgOverPayNAction: bindActionCreators(saveNotifyKsgOverPayNAction, dispatch),
        getTabsAction: bindActionCreators(getTabsAction, dispatch),
        chkReportPaspAction: bindActionCreators(chkReportPaspAction, dispatch),
        getLSReportHistAction: bindActionCreators(getLSReportHistAction, dispatch),
    }),
)(AccountMainPage);
