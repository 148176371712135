// @flow
import * as React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import {withStyles} from "@material-ui/core/styles";
import cn from "classnames";
import Button from "../../packages/components/Button/Button";
import {SET_LS_SUBSCR_EMAIL, SET_LS_SUBSCR_BY_EVENT_KVITONOMIC} from "../../constants/Notification";
import {styles} from "./styles";

type Props = {
    auth: Object,
    nnPriority: number,
    idNotice: string,
    btnItem: Object,
    classes: Object,
    bannerAction: (idNotice: string, kdEventAction: string, vlAddParam: ?string) => Promise<any>,
    vlAddParam?: ?string,
};

export class NotificationButton extends React.PureComponent<Props> {
    bannerAction = (event: any) => {
        event.stopPropagation();
        this.props.bannerAction(this.props.idNotice, this.props.btnItem.kdEventAction, this.props.vlAddParam);
    };

    render() {
        const {auth, btnItem, classes = {}} = this.props;

        return (
            <Grid item>
                <Button
                    color="transparent"
                    size="sm"
                    border
                    textColor={this.props.nnPriority === 1 ? "error" : "primary-light"}
                    className={cn({
                        [classes.kvitonomic]: btnItem.kdEventAction === SET_LS_SUBSCR_BY_EVENT_KVITONOMIC,
                    })}
                    /* eslint-disable-next-line no-magic-numbers */
                    disabled={auth.prMirror && btnItem.kdEventAction === SET_LS_SUBSCR_EMAIL}
                    onClick={this.bannerAction}
                >
                    {btnItem.nmTitle}
                </Button>
            </Grid>
        );
    }
}

export default withStyles(styles)(NotificationButton);
