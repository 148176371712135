import trim from "lodash/trim";

const errorMessage = "Некорректный email";

export const emailFormatValidation = (value) => {
    const trimValue = trim(value);
    const baseFormatReg = new RegExp("(?!.*([\\.])\\1)^[^@]+@[^@]+\\.[^@]{2,}$");
    const [name, allDomains] = trimValue.split("@");
    const domain = allDomains?.split(".")?.slice(-1);
    const subDomains = allDomains?.split(".")?.slice(0, -1);
    const checkName = () => {
        const nameReg = new RegExp(
            // eslint-disable-next-line max-len
            "^([A-Za-z0-9!#\\$%&'\\*\\+\\-/=\\?\\^_`{\\|}~]+([A-Za-z0-9!#\\$%&'\\*\\+\\-/=\\?\\^_`{\\|}~\\.]+[A-Za-z0-9!#\\$%&'\\*\\+\\-/=\\?\\^_`{\\|}~])*|[А-Яа-яЁё0-9!#\\$%&'\\*\\+\\-/=\\?\\^_`{\\|}~]+([А-Яа-яЁё0-9!#\\$%&'\\*\\+\\-/=\\?\\^_`{\\|}~\\.]+[А-Яа-яЁё0-9!#\\$%&'\\*\\+\\-/=\\?\\^_`{\\|}~])*)$",
        );

        return nameReg.test(name);
    };
    const checkSubDomain = () => {
        const subDomainReg = new RegExp(
            "^(([A-Za-z0-9]+([A-Za-z0-9\\-_]+[A-Za-z0-9])*)+|([А-Яа-яЁё0-9]+([А-Яа-яЁё0-9\\-_]+[А-Яа-яЁё0-9])*)+)$",
        );

        return subDomains.every((item) => subDomainReg.test(item));
    };
    const checkDomain = () => {
        const domainReg = new RegExp(
            "^(([A-Za-z0-9]+[A-Za-z0-9\\-]*[A-Za-z0-9]+)|([А-Яа-яЁё0-9]+[А-Яа-яЁё0-9\\-]*[А-Яа-яЁё0-9]+))$",
        );
        const atLeastOneLetter = new RegExp("[A-Za-zА-Яа-яЁё]");

        return domainReg.test(domain) && atLeastOneLetter.test(domain);
    };

    return !value || (baseFormatReg.test(trimValue) && checkName() && checkSubDomain() && checkDomain())
        ? undefined
        : errorMessage;
};

export const emailFormatValidationBase = () => (value) => emailFormatValidation(value);
