// @flow
import * as actionTypes from "../../constants/types/ActionNotificationTypes";
import {type ApiMiddlewareActionType} from "../../redux/middlewares/apiMiddleware";
import {authKey} from "../../constants/Application";
import {getModalInfoDataActionQueryNames, type ModalInfoDataKeysType} from "../../constants/modalInfoTypes";
import type {ApiMiddlewareResponsePromiseType} from "../middlewares/apiMiddleware";
import {getLocaleStorageAction} from "./storageActions/localeStorageAction";

export type GetModalInfoDataActionType = (name: ModalInfoDataKeysType) => ApiMiddlewareResponsePromiseType;
export type GetCriticalNoticeType = (session: string) => ApiMiddlewareActionType;
export type GetBannerNoticeType = (session: string) => ApiMiddlewareActionType;

export const getBannerNotice = (session: string): ApiMiddlewareActionType => ({
    api: {
        list: true,
        query: "GetBannerNotice",
        session,
        types: [
            actionTypes.GET_BANNER_NOTICE_ACTION,
            actionTypes.GET_BANNER_NOTICE_ACTION_SUCCESS,
            actionTypes.GET_BANNER_NOTICE_ACTION_FAILURE,
        ],
    },
});

export const openNotice = (idNotice: string) => ({
    payload: {
        idNotice,
    },
    type: actionTypes.OPEN_NOTICE_ACTION,
});

export const setNoticeReaded = (idNotice: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idNotice,
        },
        query: "SetNoticeReaded",
        session,
        types: [
            actionTypes.SET_NOTICE_READER_ACTION,
            actionTypes.SET_NOTICE_READER_ACTION_SUCCESS,
            actionTypes.SET_NOTICE_READER_ACTION_FAILURE,
        ],
    },
    payload: {
        idNotice,
    },
});

export const getCriticalNotice = (session: string) => (dispatch: any): ApiMiddlewareActionType =>
    dispatch({
        api: {
            list: true,
            query: "GetCriticalNotice",
            session,
            types: [
                actionTypes.GET_CRITICAL_NOTICE_ACTION,
                actionTypes.GET_CRITICAL_NOTICE_ACTION_SUCCESS,
                actionTypes.GET_CRITICAL_NOTICE_ACTION_FAILURE,
            ],
        },
    }).then((res) => {
        if (Array.isArray(res.response)) {
            res.response?.forEach(({idNotice, prExpand}) => {
                prExpand && dispatch(setNoticeReaded(idNotice, session));
            });
        }

        return res;
    });

export const getLSNotice = ({idService, session, cancelToken, dtEn, dtSt}: Object): ApiMiddlewareActionType => ({
    api: {
        data: {
            dtEn,
            dtSt,
            idService,
        },
        list: true,
        query: "GetLSNotice",
        session,
        types: [
            actionTypes.GET_LS_NOTICE_ACTION,
            actionTypes.GET_LS_NOTICE_ACTION_SUCCESS,
            actionTypes.GET_LS_NOTICE_ACTION_FAILURE,
        ],
    },
    payload: {
        cancelToken,
    },
});

export const setActionDate = (idNotice: string, kdEventAction: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idNotice,
            kdEventAction,
        },
        query: "SetActionDate",
        session,
        types: [
            actionTypes.SET_ACTION_DATE_ACTION,
            actionTypes.SET_ACTION_DATE_ACTION_SUCCESS,
            actionTypes.SET_ACTION_DATE_ACTION_FAILURE,
        ],
    },
});

export const setLsSubscrEmail = (idService: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "SetLsSubscrEmail",
        session,
        types: [
            actionTypes.SET_LS_SUBSCR_EMAIL_ACTION,
            actionTypes.SET_LS_SUBSCR_EMAIL_ACTION_SUCCESS,
            actionTypes.SET_LS_SUBSCR_EMAIL_ACTION_FAILURE,
        ],
    },
});

export const getLSNoticeCount = (idService: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "GetLSNoticeCount",
        session,
        types: [
            actionTypes.GET_LS_NOTICE_COUNT_ACTION,
            actionTypes.GET_LS_NOTICE_COUNT_ACTION_SUCCESS,
            actionTypes.GET_LS_NOTICE_COUNT_ACTION_FAILURE,
        ],
    },
    payload: {
        idService,
    },
});

export const getLSNoticeCriticalStatus = (idService: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "GetLSNoticeCriticalStatus",
        session,
        types: [
            actionTypes.GET_LS_NOTICE_CRITICAL_STATUS_ACTION,
            actionTypes.GET_LS_NOTICE_CRITICAL_STATUS_ACTION_SUCCESS,
            actionTypes.GET_LS_NOTICE_CRITICAL_STATUS_ACTION_FAILURE,
        ],
    },
    payload: {
        idService,
    },
});

export const userSetLsSubscrbyEventAction = (idService: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "UserSetLsSubscrbyEvent",
        session,
        types: [
            actionTypes.USER_SET_LS_SUBSCR_BY_EVENT_ACTION,
            actionTypes.USER_SET_LS_SUBSCR_BY_EVENT_ACTION_SUCCESS,
            actionTypes.USER_SET_LS_SUBSCR_BY_EVENT_ACTION_FAILURE,
        ],
    },
    payload: {
        idService,
    },
});

export const closeBannerNoticeModal = () => ({
    type: actionTypes.CLOSE_BANNER_NOTICE_MODAL_ACTION,
});

export const openBannerNoticeModal = () => ({
    type: actionTypes.OPEN_BANNER_NOTICE_MODAL_ACTION,
});

export const closeCriticalNoticeModal = (fromModal: boolean) => ({
    payload: {
        fromModal,
    },
    type: actionTypes.CLOSE_CRITICAL_NOTICE_MODAL_ACTION,
});

export const openCriticalNoticeModal = () => ({
    type: actionTypes.OPEN_CRITICAL_NOTICE_MODAL_ACTION,
});

export const noticeRoutineAction = (session: string, storageAction: any) => (dispatch: any): ApiMiddlewareActionType =>
    dispatch({
        api: {
            query: "NoticeRoutine",
            session,
            types: [
                actionTypes.NOTICE_ROUTINE_ACTION,
                actionTypes.NOTICE_ROUTINE_ACTION_SUCCESS,
                actionTypes.NOTICE_ROUTINE_ACTION_FAILURE,
            ],
        },
    }).then((action) => {
        if (!action.error && action.response && action.response.kdResult === 0) {
            const {
                payload: {value},
            } = getLocaleStorageAction("auth") || {};

            dispatch(
                storageAction(authKey, {
                    ...value,
                    noticeRoutine: true,
                }),
            );

            dispatch(getCriticalNotice(session)).then((res) => {
                res?.response?.length > 0 && dispatch(openCriticalNoticeModal());
            });
            /*
             * https://jira.mesinfo.ru:9443/browse/ELKFL-9172
             * Dispatch(getBannerNotice(session));
             */
        }

        return action;
    });

export const getLsListNoticeStatusAction = (session: string) => ({
    api: {
        list: true,
        query: "GetLSListNoticeStatus",
        session,
        types: [
            actionTypes.GET_LS_LIST_NOTICE_STATUS_ACTION,
            actionTypes.GET_LS_LIST_NOTICE_STATUS_ACTION_SUCCESS,
            actionTypes.GET_LS_LIST_NOTICE_STATUS_ACTION_FAILURE,
        ],
    },
});
export const openInfoModalAction = (modalName: string) => ({
    payload: {
        modalName,
    },
    type: actionTypes.OPEN_INFO_MODAL_ACTION,
});
export const closeInfoModalAction = () => ({
    type: actionTypes.CLOSE_INFO_MODAL_ACTION,
});
export const getModalInfoDataAction = (name: ModalInfoDataKeysType) => ({
    api: {
        list: true,
        query: getModalInfoDataActionQueryNames[name],
        types: [
            actionTypes.GET_LS_LIST_NOTICE_STATUS_ACTION,
            actionTypes.GET_LS_LIST_NOTICE_STATUS_ACTION_SUCCESS,
            actionTypes.GET_LS_LIST_NOTICE_STATUS_ACTION_FAILURE,
        ],
    },
});
