// @flow
import React from "react";
import tomskIcon from "../../../images/providers/tmk/logo/tmkRnsLogo.svg";
import {SMALL_ICON_SIZE} from "../../../constants/iconConstants";

type Props = {
    fontSize?: number,
};

const TmkRnsLogoIcon = ({fontSize}: Props) => <img height={fontSize} width={fontSize} src={tomskIcon} alt="" />;

TmkRnsLogoIcon.defaultProps = {
    fontSize: SMALL_ICON_SIZE,
};

export default TmkRnsLogoIcon;
