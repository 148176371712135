// @flow
import * as React from "react";
import Divider from "@material-ui/core/Divider";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid/Grid";
import Hidden from "@material-ui/core/Hidden/Hidden";
import {type RouterHistory} from "react-router-dom";
import Panel from "../../packages/components/Panel/Panel";
import PanelHead from "../../packages/components/Panel/PanelHead";
import PanelBody from "../../packages/components/Panel/PanelBody";
import Content from "../../packages/components/Content/Content";
import Typography from "../../packages/components/Typography/Typography";
import Button from "../../packages/components/Button/Button";
import NotificationIcon from "../../packages/components/Icons/NotificationIcon";
import {reachGoal} from "../../utils/metricUtils";
import {
    SET_LS_SUBSCR_EMAIL,
    FILL_PROFILE_REQUISITES,
    SET_LS_SUBSCR_BY_EVENT,
    SET_LS_SUBSCR_BY_EVENT_KVITONOMIC,
} from "../../constants/Notification";
import NotificationButton from "./NotificationButton";
import {redirectActionConfig} from "./NotificationForDayPanelBody";

const styles = (theme: any) => ({
    btnContent: {
        flex: 1,
        textAlign: "right",
        [theme.breakpoints.down("xs")]: {
            textAlign: "center",
            width: "100%",
        },
    },
});

type State = {
    nnPhone: string,
    phoneOrEmailConfirmOpen: boolean,
};

type Props = {
    noticeItem: Object,
    accountsList: Array<Object>,
    history: RouterHistory,
    session: string,
    auth?: Object,
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    onCancel: () => void,
    onRead: (idNotice: string) => Promise<any>,
    setLsSubscrEmail: (idService: string, session?: string) => Promise<any>,
    userSetLsSubscrbyEventAction: (idService: string, session?: string) => Promise<any>,
    criticalNoticeAction?: (idNotice: string, idService: string) => void,
    openExtraRegistrationFormAction: () => void,
};

const boldFontWeight = 500;

export class CriticalNoticeModalBodyRow extends React.PureComponent<Props, State> {
    bannerAction = async (idNotice: string, kdEventAction: string, vlAddParam: ?string) => {
        this.handelOnRead();
        const {auth = {}, noticeItem, history, onCancel, accountsList, session} = this.props;
        const {idService} = accountsList.find((lsItem) => lsItem.idService === noticeItem.idService) || {
            idService: "",
            nnLs: "",
        };

        if (kdEventAction === SET_LS_SUBSCR_EMAIL) {
            this.props.setLsSubscrEmail(idService, session);

            return;
        } else if (kdEventAction === FILL_PROFILE_REQUISITES) {
            await this.props.openExtraRegistrationFormAction();

            return;
        } else if (kdEventAction === SET_LS_SUBSCR_BY_EVENT) {
            await this.props.userSetLsSubscrbyEventAction(idService, session);
        }

        const redirectAction =
            kdEventAction && redirectActionConfig[kdEventAction]
                ? redirectActionConfig[kdEventAction](noticeItem.idService, this.props.noticeItem, {vlAddParam})
                : undefined;

        if (redirectAction) {
            if (!auth.prMirror) {
                redirectAction.reachGoal && reachGoal(redirectAction.reachGoal);
            }
            history.push(redirectAction.url);
        }

        onCancel();
    };

    handelOnRead = () => {
        const {
            noticeItem: {idNotice, prReaded},
        } = this.props;

        if (!prReaded) {
            this.props.onRead(idNotice);
        }
    };

    attachRedirectAction = (event: SyntheticEvent<>, url: string) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(url, "_blank");
    };

    renderActionBtns = (justify: "center" | "flex-end") => {
        const {auth, noticeItem} = this.props;
        const {actions, nnPriority, idNotice, nmAttachLink, vlAddParam} = noticeItem;

        return (
            <Grid container spacing={16} justify={justify} direction="column">
                {actions
                    ? actions.map((btnItem, btnIndex) => (
                          <NotificationButton
                              auth={auth}
                              nnPriority={nnPriority}
                              idNotice={idNotice}
                              btnItem={btnItem}
                              key={btnIndex}
                              bannerAction={this.bannerAction}
                              vlAddParam={vlAddParam}
                          />
                      ))
                    : null}
                {nmAttachLink ? (
                    <Grid item>
                        <Button
                            color="transparent"
                            size="sm"
                            border
                            textColor={nnPriority === 1 ? "error" : "primary-light"}
                            onClick={(event: SyntheticEvent<>) => this.attachRedirectAction(event, nmAttachLink)}
                        >
                            Перейти
                        </Button>
                    </Grid>
                ) : null}
            </Grid>
        );
    };

    render() {
        const {noticeItem, classes = {}} = this.props;
        const {nmHeader, nmProvider, nnLs, actions, nnPriority, prExpand} = noticeItem;
        const [action = {}] = noticeItem.actions || [];

        return (
            <React.Fragment>
                <Divider />
                <Panel onOpen={this.handelOnRead} open={prExpand}>
                    <PanelHead>
                        <Content vertical={10}>
                            <Grid container alignItems="center" spasing={16}>
                                <Grid item md={1} sm={2} xs={2}>
                                    <NotificationIcon
                                        error={noticeItem.nnPriority === 1}
                                        read={noticeItem.prReaded}
                                        kvitonomic={action.kdEventAction === SET_LS_SUBSCR_BY_EVENT_KVITONOMIC}
                                    />
                                </Grid>
                                <Grid item md={4} sm={7} xs={10}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Typography
                                                fontSize={16}
                                                fontWeight={noticeItem.prReaded ? undefined : boldFontWeight}
                                                textColor={nnPriority === 1 ? "error" : "primary"}
                                                wordWrap="normal"
                                            >
                                                {nmHeader}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography fontSize={16} textColor="grey" wordWrap="normal">
                                                {nmProvider}
                                            </Typography>
                                        </Grid>
                                        <Hidden mdUp>
                                            <Grid item xs={12}>
                                                <Typography fontSize={16} textColor="grey" wordWrap="normal">
                                                    {nnLs}
                                                </Typography>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                                <Hidden smDown>
                                    <Grid item md={4}>
                                        <Typography
                                            verticalPadding={10}
                                            fontSize={16}
                                            textColor="grey"
                                            wordWrap="normal"
                                        >
                                            {nnLs}
                                        </Typography>
                                    </Grid>
                                </Hidden>
                                {actions && actions.length === 1 ? (
                                    <Grid item className={classes.btnContent}>
                                        {this.renderActionBtns("center")}
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Content>
                    </PanelHead>
                    <PanelBody>
                        <Content vertical={10}>
                            <Typography fontSize={16} dangerouslySetInnerHTML={{__html: noticeItem.nmText}} />
                            <div className={classes.btnContent}>
                                {actions && actions.length > 1 ? this.renderActionBtns("flex-end") : null}
                            </div>
                        </Content>
                    </PanelBody>
                </Panel>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CriticalNoticeModalBodyRow);
