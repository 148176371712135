// @flow
import * as React from "react";
import CloseIcon from "@material-ui/icons/Close";
import {withStyles} from "@material-ui/core/styles";
import cn from "classnames";

const DEFAULT_INDENT = 2.5;

const styles = (theme: any) => ({
    root: {
        cursor: "pointer",
        fill: theme.palette.primary.light,
        position: "absolute",
        right: theme.spacing.unit * DEFAULT_INDENT,
        top: theme.spacing.unit * DEFAULT_INDENT,
    },
});

type Props = {
    onClick?: (event: SyntheticEvent<*>) => void,
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    testID?: string,
    className?: string,
};

export const DialogCloseIcon = ({classes = {}, onClick, testID, className}: Props) => (
    <CloseIcon className={cn(classes.root, className)} onClick={onClick} testid={testID} />
);

DialogCloseIcon.defaultProps = {
    testID: "formClose",
};

export default withStyles(styles)(DialogCloseIcon);
