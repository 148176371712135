// @flow

export type BuilderServiceAttributesType = {
    [$key: string]: string,
};

export type BuilderServiceElementsType = {
    [$key: string]: string,
};

export type BuilderServiceGroupAttributesType = {
    [$key: string]: string,
};

export type BuilderStateType = {|
    attributes: Array<Object>,
    fetching: boolean,
    initialValues: {
        [key: string]: string,
    },
    groupAttributes: {
        [$key: number]: Array<Object>,
    },
    elements: Object,
    groupByColumn: {
        [$key: string]: Object,
    },
|};

export const builderServiceAttributes = {
    extraRegistration: "GetProfileAttributesEmpty",
    extraRegistrationUnnecessaryFields: "GetProfileAttributesEmptyEvent",
    ls: "GetLSAttributes",
    lsAdd: "GetLSAttributes",
    lsChange: "GetLSAttributesValues",
    registration: "GetRegistrationAttributes",
};

export const builderServiceElements = {
    accountMes: "GetSectionElements",
    adElementsLs: "GetAdElementsLS",
    ads: "GetAdElements",
    advertisement: "GetSectionElements",
    application: "GetSectionElements",
    auth: "GetAuthElements",
    autopayMes: "GetSectionElements",
    catalog: "GetSectionElements",
    catalogLeftBanner: "GetAdElements",
    catalogRightBanner: "GetAdElements",
    emailSubscr: "GetSectionElements",
    energyContracts: "GetSectionElements",
    extraRegistration: "GetProfileEmptyElements",
    extraRegistrationUnnecessaryFields: "GetProfileEmptyElements",
    faq: "GetSectionElements",
    lkkEirc: "GetSectionElements",
    loadDocuments: "GetSectionElements",
    ls: "GetLSRegistrationElements",
    lsAdd: "GetLSAddElements",
    lsIndications: "GetSectionElements",
    minimalFunction: "GetSectionElements",
    minimalFunctionJournal: "GetSectionElements",
    minimalFunctionMeasurements: "GetSectionElements",
    minimalFunctionReadings: "GetSectionElements",
    pay: "GetSectionElements",
    payAll: "GetSectionElements",
    paymentDocSubscr: "GetSectionElements",
    poll: "GetSectionElements",
    presentsBtn: "GetSectionElements",
    privileges: "GetSectionElements",
    profile: "GetSectionElements",
    registration: "GetRegistrationElements",
    requisites: "GetSectionElements",
    services: "GetSectionElements",
    statistic: "GetSectionElements",
};

export const builderServiceGroupAttributes = {
    profile: "GetProfileAttributesValues",
};

export const builderEmptyState: BuilderStateType = {
    attributes: [],
    elements: {},
    fetching: false,
    groupAttributes: {},
    groupByColumn: {},
    initialValues: {},
};

export type BuilderServiceElementsKeysType = $Keys<typeof builderServiceElements>;
export type BuilderServiceAttributesKeysType = $Keys<typeof builderServiceAttributes>;
export type BuilderServiceGroupAttributesKeysType = $Keys<typeof builderServiceGroupAttributes>;
