export const accountMesTabsSettings = [
    {
        kdSection: 8,
        label: "Уведомления",
        notification: true,
        value: "notifications",
    },
    {
        kdSection: 150,
        label: "История",
        value: "events",
    },
    {
        kdSection: 16,
        label: "Реквизиты ЛС",
        value: "requisites",
    },
    {
        kdSection: 19,
        label: "Льготы",
        value: "privileges",
    },
    {
        id: "id22013",
        kdSection: 17,
        label: "Статистика",
        value: "statistics",
    },
    {
        kdSection: 18,
        label: "Обращения",
        value: "application",
    },
    {
        kdSection: 20,
        label: "Мои услуги",
        value: "services",
    },
    {
        kdSection: 146,
        label: "Запрос справок и документов",
        value: "load-documents",
    },
];
