export const UNCONFIRMED_PHONE = 129;
export const UNCONFIRMED_EMAIL = 128;
export const CRM_CHANGE_PSW_CODE = 166;
export const EXTRA_REGISTRATION_CODE = 192;
export const SHOW_CAPTCHE_RESET_PASSWORD = 127;
export const SHOW_CAPTCHE = 114;
export const RESPONSE_RESULT_TEXT_CODE = 1000;
export const NO_BUSINESS_ERR_CODE = 0;
export const NOT_CONFIRMED_LOGIN_CODE = 129;
export const BILLING_UNAVAILABLE = 6013;
export const SERVICE_NOT_FOUND = 6011;
export const PROMOCODE_INVALID = 220;
export const PROMOCODE_ORDER_INVALID = 221;
export const PROMOCODE_ORDER_NOT_ALL_CONDITIONS = 222;
export const SBP_PAYMENT_PENDING = 228;
export const SBP_PAYMENT_CANCELLED = 229;
export const AGREEMENT_CODE = 233;
export const SUCCESS_CHANGE_DFA_CODE = 51;
export const REQUIRED_TFA = 1053;
