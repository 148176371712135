// @flow
import * as React from "react";
import {Link} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../styles";
import Typography from "../../../packages/components/Typography/Typography";
import TopMenuDropDownBtn from "../TopMenuDropDownBtn";
import {reachGoal} from "../../../utils/metricUtils";

type Props = {
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    prIndicationsVisible: boolean,
    prPaymentsVisible: boolean,
    onClose: () => void,
};

const TopMenuOperationsBody = (props: Props) => {
    const {prIndicationsVisible, prPaymentsVisible, onClose} = props;
    const handleClickTransferIndications = () => {
        reachGoal("m_indications_all");
        onClose();
    };
    const handleClickPay = () => {
        reachGoal("m_pay_all");
        onClose();
    };

    return (
        <React.Fragment>
            {prPaymentsVisible && (
                <TopMenuDropDownBtn disableRipple to={"/pay-all"} component={Link} onClick={handleClickPay}>
                    <Typography align="left" fontSize={14} fontWeight={400} textColor="white">
                        Оплаты
                    </Typography>
                </TopMenuDropDownBtn>
            )}
            {prIndicationsVisible && (
                <TopMenuDropDownBtn
                    disableRipple
                    to={"/transfer-indications-all"}
                    component={Link}
                    onClick={handleClickTransferIndications}
                >
                    <Typography align="left" fontSize={14} fontWeight={400} textColor="white">
                        Передача показаний
                    </Typography>
                </TopMenuDropDownBtn>
            )}
        </React.Fragment>
    );
};

export default withStyles(styles)(TopMenuOperationsBody);
