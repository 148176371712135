// @flow
/* eslint quote-props: ["error", "always"] */
import {getIn} from "final-form";
import {type FieldBuilderType, type FieldBuilderInitialValuesType} from "./FiledBuilderType";

type MapMasksType = {
    [$key: string]: RegExp,
};

const mapMasks: MapMasksType = {
    "7": /\d/,
    "8": /\d/,
    "9": /\d/,
};

export const getMask = (inputMask: string): Array<string | RegExp> =>
    inputMask.split("").map((mask) => mapMasks[mask] || mask);

export const getInitialValues = (attributes?: Array<FieldBuilderType>): FieldBuilderInitialValuesType => {
    const initialValues = {};

    if (attributes) {
        attributes.forEach((attribute: FieldBuilderType) => {
            if (attribute.vlAttribute) {
                initialValues[attribute.nmColumn] = attribute.vlAttribute;
            } else if (attribute.vlDefault) {
                initialValues[attribute.nmColumn] = attribute.vlDefault;
            }
        });
    }

    return initialValues;
};

export const findFirstInput = (inputs: Array<Object>, errors: Object) => {
    const firstInput = inputs.find((input) => {
        if (input.type === "file" && errors && errors.uploader) {
            return input.name && getIn(errors.uploader, input.name);
        }

        if (input.type === "textarea" && errors && errors.captchaResponse) {
            return input;
        }

        return input.name && getIn(errors, input.name);
    });

    if (firstInput && firstInput.type === "hidden") {
        const prevElement = firstInput.previousElementSibling;

        return prevElement;
    }

    if (firstInput && firstInput.type === "textarea") {
        return firstInput.parentElement.parentElement.parentElement;
    }

    return firstInput;
};
