// @flow
import * as React from "react";

const useText = (initText: string = "") => {
    const [text, changeText] = React.useState(initText);

    const handleChangeText = (value: string, event: Event) => {
        if (value || value === "") {
            changeText(value);
        } else if (event) {
            const {target} = event;
            const {value: eventValue} = target instanceof HTMLInputElement ? target : {};

            changeText(eventValue);
        }
    };

    return {onChangeText: handleChangeText, text};
};

export {useText};
