// @flow
import * as React from "react";
import cn from "classnames";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {withStyles} from "@material-ui/core/styles";
import Content from "../../../packages/components/Content/Content";
import Typography from "../../../packages/components/Typography/Typography";
import {styles} from "./styles";

type Props = {
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    open: boolean,
    onToggle: () => void,
    dropdownHeadName: string,
};

const TopMenuOperationsHeader = (props: Props) => {
    const {classes = {}, open, onToggle, dropdownHeadName} = props;
    const iconClassName = cn(classes.iconButton, {
        [classes.rotate]: open,
    });
    const content = cn(classes.mainContent, classes.noBorder, classes.noHover);

    return (
        <Content horizontal="xs" className={content} onClick={onToggle}>
            <Grid container className={classes.row} alignItems="center" justify="space-between" xs={12}>
                <Grid item xs={11} className={classes.item}>
                    <Typography textColor="white" whiteSpace="nowrap" textOverflow="ellipsis">
                        {dropdownHeadName}
                    </Typography>
                </Grid>
                <Grid item className={classes.item}>
                    <ExpandMoreIcon className={iconClassName} />
                </Grid>
            </Grid>
        </Content>
    );
};

export default withStyles(styles)(TopMenuOperationsHeader);
