// @flow
import {type themeTypes} from "../../../../themeStyle";

export const styles = (theme: themeTypes) => ({
    collapseContent: {
        transformOrigin: "0 0 0",
        width: theme.sizing.topMenuUserAccountWidth,
        [theme.breakpoints.down("md")]: {
            left: 0,
            width: "100%",
        },
    },
    paperContainer: {
        background: theme.palette.secondary.light2,
        border: `1px solid ${theme.palette.topMenu.separator}`,
        borderRadius: 0,
    },
});
