// @flow

export const themeEdgeOverridesStyle = {
    MuiGrid: {
        "grid-xs": {
            flexBasis: "auto",
        },
        "grid-xs-true": {
            flexBasis: "auto",
        },
    },
};
