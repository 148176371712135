// @flow
import React from "react";
import Typography from "../../packages/components/Typography/Typography";

export const SideBarZeroAccounts = () => (
    <Typography backgrColor="translucent" fontSize={14} padding={10} align="center">
        У вас не подключены лицевые счета
    </Typography>
);

export default SideBarZeroAccounts;
