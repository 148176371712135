// Пункт меню в шапке "Передача показаний по всем ЛС"
export const TRANSFER_INDICATIONS_ALL_KD_SECTION = 31;
// Пункт меню в шапке "Вопросы и ответы"
export const FAQ_KD_SECTION = 32;
// Пункт меню в шапке "Товары и услуги"
export const SERVICES_KD_SECTION = 30;
// Пункт меню в шапке "Каталог услуг без СУВК"
export const SERVICES_WITHOUT_SUVK_KD_SECTION = 37;
// Пункт меню в шапке "Оплата по всем ЛС МЭС"
export const ALL_LS_MES_PAY_KD_SECTION = 117;
// Пункт меню в шапке "Договоры энергоснабжения"
export const ENERGY_CONTRACTS_KD_SECTION = 128;
// Пункт меню в шапке "Оплата дополнительных услуг"
export const PAY_ADDITIONAL_SERVICES_KD_SECTION = 136;
// Пункт меню в шапке "ЛКК ЕИРЦ РБ"
export const LKK_EIRC_KD_SECTION = 145;
// Раздел доп информации югрей/роботрон
export const STATISTIC_YUGREY_ROBOTRON_KD_SECTION = 17;
// Раздел реквизиты ЛС
export const REQUISITES_KD_SECTION = 16;
