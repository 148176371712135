/* eslint-disable sort-keys */
// @flow
import {type themeTypes} from "../../../themeStyle";

const PADDING_XL = 10;
const PADDING_MD = 7;
const PADDING_SM = 6;
const PADDING_XS = 2;

const staticStyles = {
    rootDefault: {
        flex: 1,
    },
    "horizontal-padding-30": {
        paddingLeft: 30,
        paddingRight: 30,
    },
    "horizontal-padding-20": {
        paddingLeft: 20,
        paddingRight: 20,
    },
    "horizontal-padding-48": {
        paddingLeft: 48,
        paddingRight: 48,
    },
    "horizontal-padding-16": {
        paddingLeft: 16,
        paddingRight: 16,
    },
    "horizontal-padding-8": {
        paddingLeft: 8,
        paddingRight: 8,
    },
    "height-400": {
        height: 400,
    },
    "height-600": {
        height: 600,
    },
    "max-height-45vh": {
        maxHeight: "45vh",
    },
    "max-height-600": {
        maxHeight: "600px",
    },
    "min-height-24": {
        minHeight: 24,
    },
    "min-height-36": {
        minHeight: 36,
    },
    "min-height-250": {
        minHeight: 250,
    },
    "min-height-300": {
        minHeight: 300,
    },
    "min-height-340": {
        minHeight: 340,
    },
    "min-height-52": {
        minHeight: 52,
    },
    "overflow-x-scroll": {
        overflowX: "scroll",
    },
    "overflow-x-auto": {
        overflowX: "auto",
    },
    "overflow-y-auto": {
        overflowY: "auto",
    },
    "overflow-x-hidden": {
        overflowX: "hidden",
    },
    "overflow-y-hidden": {
        overflowY: "hidden",
    },
    "padding-right-16": {
        paddingRight: 16,
    },
    "padding-top-20": {
        paddingTop: 20,
    },
    "vertical-padding-lg": {
        paddingBottom: 50,
        paddingTop: 50,
    },
    "vertical-padding-4": {
        paddingBottom: 4,
        paddingTop: 4,
    },
    "vertical-padding-10": {
        paddingBottom: 10,
        paddingTop: 10,
    },
    "vertical-padding-15": {
        paddingBottom: 15,
        paddingTop: 15,
    },
    "vertical-padding-20": {
        paddingBottom: 20,
        paddingTop: 20,
    },
    "vertical-padding-30": {
        paddingBottom: 30,
        paddingTop: 30,
    },
    "vertical-padding-40": {
        paddingBottom: 40,
        paddingTop: 40,
    },
    "vertical-padding-48": {
        paddingBottom: 48,
        paddingTop: 48,
    },
    "vertical-padding-64": {
        paddingBottom: 64,
        paddingTop: 64,
    },
    "vertical-padding-100": {
        paddingBottom: 100,
        paddingTop: 100,
    },
    "vertical-margin-20": {
        marginBottom: 20,
        marginTop: 20,
    },
    "max-width-110": {
        maxWidth: 110,
    },
    "max-width-220": {
        maxWidth: 220,
    },
    "max-width-407": {
        maxWidth: 407,
    },
    "max-width-520": {
        maxWidth: 520,
    },
    "max-width-600": {
        maxWidth: 600,
    },
    "max-width-700": {
        maxWidth: 700,
    },
    "position-relative": {
        position: "relative",
    },
    "margin-top-8": {
        marginTop: 8,
    },
    "max-width-40%": {
        maxWidth: "40%",
    },
    "max-width-80%": {
        maxWidth: "80%",
    },
    "display-inlineBlock": {
        display: "inline-block",
    },
    "display-inlineTable": {
        display: "inline-table",
    },
    "display-flex": {
        display: "flex",
    },
    "align-right": {
        textAlign: "right",
    },
    "align-left": {
        textAlign: "left",
    },
};

export const styles = (theme: themeTypes) => ({
    "background-grey": {
        background: theme.palette.grey.coolGray1,
    },
    "background-light-grey": {
        background: theme.palette.grey.light2,
    },
    "background-translucent": {
        background: theme.palette.primary.translucent,
    },
    "background-white": {
        background: theme.palette.common.white,
    },
    "background-primary-light": {
        background: theme.palette.primary.light,
    },
    "background-black": {
        background: theme.palette.common.black,
    },
    border: {
        border: `1px solid ${theme.palette.grey.border}`,
    },
    error: {
        border: `1px solid ${theme.palette.error.main}`,
    },
    "horizontal-padding-24": {
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
    },
    "horizontal-padding-md": {
        paddingLeft: theme.spacing.unit * PADDING_MD,
        paddingRight: theme.spacing.unit * PADDING_MD,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing.unit * PADDING_XS,
            paddingRight: theme.spacing.unit * PADDING_XS,
        },
    },
    "horizontal-padding-sm": {
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,

        [theme.breakpoints.up("sm")]: {
            paddingLeft: theme.spacing.unit * PADDING_SM,
            paddingRight: theme.spacing.unit * PADDING_SM,
        },
    },
    "horizontal-padding-xl": {
        paddingLeft: theme.spacing.unit * PADDING_XS,
        paddingRight: theme.spacing.unit * PADDING_XS,

        [theme.breakpoints.up("lg")]: {
            paddingLeft: theme.spacing.unit * PADDING_XL,
            paddingRight: theme.spacing.unit * PADDING_XL,
        },
    },
    "horizontal-padding-xs": {
        paddingLeft: theme.spacing.unit * PADDING_XS,
        paddingRight: theme.spacing.unit * PADDING_XS,
    },
    underline: {
        borderBottom: `1px solid ${theme.palette.grey.coolGray1}`,
    },
    "padding-top-8": {
        paddingTop: theme.spacing.unit,
    },
    "vertical-padding-sm": {
        paddingBottom: theme.spacing.unit * PADDING_XS,
        paddingTop: theme.spacing.unit * PADDING_XS,
    },
    "vertical-padding-xs": {
        paddingBottom: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
    },
    "vertical-padding-24": {
        paddingBottom: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 3,
    },
    "vertical-padding-56": {
        paddingBottom: theme.spacing.unit * PADDING_MD,
        paddingTop: theme.spacing.unit * PADDING_MD,
    },
    ...staticStyles,
});
