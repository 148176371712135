// @flow
import React from "react";
import Typography, {type TypographyFontSize, type TypographyVerticalPadding} from "../Typography/Typography";

type Props = {
    fetching?: boolean,
    text?: string,
    fontSize?: TypographyFontSize,
    testID?: string,
    verticalPadding?: TypographyVerticalPadding,
    hide?: boolean,
};

const DEFAULT_FONT_SIZE = 16;
const DEFAULT_VERTICAL_PADDING = 16;

export const NoData = ({
    fetching = false,
    text = "Данных нет!",
    fontSize = DEFAULT_FONT_SIZE,
    testID = "NoData",
    verticalPadding = DEFAULT_VERTICAL_PADDING,
    hide = false,
}: Props) =>
    fetching || hide ? null : (
        <Typography verticalPadding={verticalPadding} fontSize={fontSize} testID={testID}>
            {text}
        </Typography>
    );

export default NoData;
