// @flow
import * as React from "react";
import {Link} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import ButtonBase from "@material-ui/core/ButtonBase";
import Menu from "@material-ui/icons/Menu";
import queryString from "qs";
import {type OperationsForAllAccountsType, type TopMenuItem} from "../../redux/reducers/configReducer";
import Typography from "../../packages/components/Typography/Typography";
import {SERVICE_PAGE_KD_SECTION_WITH_CRMKU} from "../../constants/ServicePageKdSection";
import {PAY_ADDITIONAL_SERVICES_KD_SECTION} from "../../constants/kdSections";
import BElement from "../../packages/Builder/BElement/BElement";
import TopMenuDropDown from "./TopMenuDropDown";
import TopMenuDropDownBtn from "./TopMenuDropDownBtn";
import SubMenuDropdown from "./SubMenu/SubMenuDropdown";

const style = (theme: any) => ({
    icon: {
        color: "white",
        fontSize: 14,
        position: "absolute",
        top: 6,
    },
    iconContainer: {
        "&:hover": {
            background: theme.palette.topMenu.selected,
        },
        background: theme.palette.secondary.light2,
        borderLeft: `1px solid ${theme.palette.topMenu.separator}`,
        borderRight: `1px solid ${theme.palette.topMenu.separator}`,
        color: theme.palette.text.white,
        display: "block",
        minHeight: theme.sizing.appbarHeight,
        padding: "0px 16px",
        [theme.breakpoints.down("sm")]: {
            padding: "0px 12px",
        },
    },
    payAdditionalServicesBtn: {
        border: "1px solid white",
        borderRadius: "4px",
        padding: "2px 6px",
    },
});

type Props = {
    textLinks: Array<TopMenuItem>,
    operationsForAllAccounts: OperationsForAllAccountsType,
    lkkEircRedirectButton: Object,
    lkkEskcRedirectButton: Object,
    classes?: {
        [$Keys<$Call<typeof style>>]: string,
    },
};

const PAY_ALL_ACCOUNTS_ITEM: TopMenuItem = {query: {}, text: "Оплата", url: "/pay-all"};

class TopMenuDrawer extends React.Component<Props> {
    renderBody = ({open, onClose}: Object) => {
        const {
            classes = {},
            textLinks = [],
            operationsForAllAccounts,
            lkkEircRedirectButton,
            lkkEskcRedirectButton,
        } = this.props;

        if (!open) {
            return null;
        }

        return (
            <React.Fragment>
                {textLinks.map((textLink: TopMenuItem, id: number) => {
                    if (
                        operationsForAllAccounts.isOperationsForAllAccountsVisible &&
                        textLink.url === "/transfer-indications-all"
                    ) {
                        return (
                            <SubMenuDropdown
                                key={id}
                                isMenuOpen={open}
                                onCloseMenu={onClose}
                                items={[PAY_ALL_ACCOUNTS_ITEM, {...textLink, text: "Передача показаний"}]}
                                headerText="Операции по всем лицевым счетам"
                            />
                        );
                    }

                    return (
                        <React.Fragment key={id}>
                            <TopMenuDropDownBtn
                                disableRipple
                                to={{
                                    pathname: textLink.url,
                                    search: queryString.stringify(textLink.query),
                                }}
                                component={Link}
                                onClick={onClose}
                            >
                                <Typography
                                    className={
                                        textLink.kdSection === PAY_ADDITIONAL_SERVICES_KD_SECTION &&
                                        classes.payAdditionalServicesBtn
                                    }
                                    align="center"
                                    fontSize={14}
                                    fontWeight={400}
                                    textColor="white"
                                >
                                    {textLink.kdSection === SERVICE_PAGE_KD_SECTION_WITH_CRMKU
                                        ? "Каталог услуг"
                                        : textLink.text}
                                    {textLink.kdSection === PAY_ADDITIONAL_SERVICES_KD_SECTION ? (
                                        <ErrorIcon className={classes.icon} />
                                    ) : null}
                                </Typography>
                            </TopMenuDropDownBtn>
                        </React.Fragment>
                    );
                })}
                <TopMenuDropDownBtn disableRipple onClick={onClose}>
                    <BElement
                        {...lkkEircRedirectButton}
                        underline="none"
                        color="transparent"
                        size="full"
                        zeroPadding
                        zeroMinHeight
                        link
                        typographyStyleExtra={{
                            border: "1px solid white",
                            borderRadius: "4px",
                            padding: "2px 6px",
                        }}
                        buttonGridItemStyleExtra={{
                            width: "100%",
                        }}
                    />
                </TopMenuDropDownBtn>
                <TopMenuDropDownBtn disableRipple onClick={onClose}>
                    <BElement
                        {...lkkEskcRedirectButton}
                        underline="none"
                        color="transparent"
                        size="full"
                        zeroPadding
                        zeroMinHeight
                        link
                        buttonGridItemStyleExtra={{
                            width: "100%",
                        }}
                    />
                </TopMenuDropDownBtn>
            </React.Fragment>
        );
    };

    renderHeader = ({onToggle}: Object) => {
        const {classes = {}} = this.props;

        return (
            <ButtonBase className={classes.iconContainer} onClick={onToggle}>
                <Menu />
            </ButtonBase>
        );
    };

    render() {
        return <TopMenuDropDown ComponentBody={this.renderBody} ComponentHead={this.renderHeader} />;
    }
}

export default withStyles(style)(TopMenuDrawer);
