// @flow
/* eslint no-magic-numbers: ["error", { "ignore": [0, 1] }] */
import * as React from "react";
import debug from "debug";
import WizardButtons, {type WizardButtonsProps} from "./WizardButtons";

type PropsPage = {
    onNext?: (event?: SyntheticEvent<>) => void | Promise<any>,
    onPrev?: () => void | Promise<any>,
    render?: (buttonProps: WizardButtonsProps) => React.Node,
    children?: React.Node,
};

type Props = {
    preventDefault?: boolean,
    children: React.Node,
    onLastStep: (...args: any) => void | Promise<any>,
    lastStepName: string,
    renderButton: (buttons: React$Node) => React$Node,
    renderPage: (page: React$Node) => React$Node,
};

type State = {
    page: number,
    disabled: boolean,
};

const logger = debug("tii:lkk:Wizard");

export class Wizard extends React.Component<Props, State> {
    static defaultProps = {
        lastStepName: "Войти",
        renderButton: (buttons: React$Node) => <div>{buttons}</div>,
        renderPage: (page: React$Node) => page,
    };

    state: State = {
        disabled: false,
        page: 0,
    };

    static Page = (props: PropsPage) => props.children || null;

    handleNext = (event?: SyntheticEvent<>) => {
        const {page} = this.state;
        const childrens = React.Children.toArray(this.props.children);
        const activePage = childrens[page];
        const isLastPage = page === childrens.length - 1;

        if (isLastPage && event && this.props.preventDefault) {
            event.preventDefault();
        }

        this.setState({disabled: true});

        return Promise.resolve(event)
            .then(activePage.props.onNext)
            .then((res) => {
                this.setState({disabled: false});

                if (isLastPage) {
                    return this.props.onLastStep(res);
                }

                this.setState((prevState) => ({
                    page: Math.min(prevState.page + 1, childrens.length - 1),
                }));

                return res;
            })
            .catch(() => {
                this.setState({disabled: false});
            });
    };

    handlePrevious = () => {
        const {page} = this.state;
        const activePage = React.Children.toArray(this.props.children)[page];

        return Promise.resolve()
            .then(activePage.props.onPrev)
            .then((res) => {
                this.setState((prevState) => ({
                    page: Math.max(prevState.page - 1, 0),
                }));

                return res;
            })
            .catch(() => {
                logger("handlePrevious broken");
            });
    };

    render() {
        const {children, renderButton, renderPage} = this.props;
        const {page} = this.state;
        const childrens = React.Children.toArray(children);
        const activePage = childrens[page];
        const isLastPage = page === childrens.length - 1;

        // Предусмостреть измнение количества children
        if (!activePage) {
            return null;
        }

        const {render}: PropsPage = activePage.props;

        const buttonProps: WizardButtonsProps = {
            isLastPage,
            lastStepName: this.props.lastStepName,
            onLastStep: this.props.onLastStep,
            onNext: this.handleNext,
            onPrev: this.handlePrevious,
            pageNumber: page,
        };

        if (render) {
            return render(buttonProps);
        }

        return (
            <React.Fragment>
                {renderPage(activePage)}
                {renderButton(<WizardButtons {...buttonProps} />)}
            </React.Fragment>
        );
    }
}

export default Wizard;
