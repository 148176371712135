// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import cn from "classnames";

const style = (theme: any) => ({
    button: {
        "&:hover": {
            background: theme.palette.topMenu.selected,
        },
        background: theme.palette.secondary.light2,
        borderBottom: `1px solid ${theme.palette.topMenu.separator}`,
        justifyContent: "flex-start",
        padding: "14px 16px",
        position: "relative",
        width: "100%",
        [theme.breakpoints.down("md")]: {
            justifyContent: "center",
            padding: "14px 0px",
        },
        zIndex: "2",
    },
});

type Props = {|
    classes?: {
        [$Keys<$Call<typeof style>>]: string,
    },
    className?: string,
|};

const TopMenuDropDownBtn = ({classes = {}, className, ...otherProps}: Props) => (
    <ButtonBase className={cn(classes.button, className)} {...otherProps} />
);

export default withStyles(style)(TopMenuDropDownBtn);
