// @flow
import TextElement from "./TextElement";
import CheckboxElement from "./CheckboxElement";
import ReferenceElement from "./ReferenceElement";
import BannerElement from "./BannerElement";
import ImageElement from "./ImageElement";
import SliderElement from "./SliderElement";
import ReferenceBtnElement from "./ReferenceBtnElement";
import CalendarElement from "./CalendarElement";
import ImageListElement from "./ImageListElement";
import {ListElement} from "./ListElement";
import ClickableTextElement from "./ClickableTextElement";

const elementsMap = {
    BANNER: BannerElement,
    BUTTON: ReferenceBtnElement,
    BUTTON_REF: ReferenceBtnElement,
    CALENDAR: CalendarElement,
    CHECKBOX: CheckboxElement,
    CLICKABLE_TEXT: ClickableTextElement,
    IMAGE: ImageElement,
    IMAGE_LIST: ImageListElement,
    LIST: ListElement,
    REFERENCE: ReferenceElement,
    REFERENCE_ARRAY: ReferenceElement,
    SLIDER: SliderElement,
    TEXT: TextElement,
};

export const getElement = (elementType?: string) => {
    if (!elementType) {
        return null;
    }

    return elementsMap[elementType];
};
