// @flow
import * as actionTypes from "../../constants/types/ActionTypes";
import {type ApiMiddlewareActionType, type ApiMiddlewareResponsePromiseType} from "../middlewares/apiMiddleware";

export type GetConfigSysSettingsType = () => ApiMiddlewareResponsePromiseType;
export type GetMenuSettingsActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetMultipleLSPayVisibilityActionType = (session: string) => ApiMiddlewareResponsePromiseType;

export const getConfigSysSettings = (): ApiMiddlewareActionType => ({
    api: {
        query: "GetSysSettings",
        types: [
            actionTypes.GET_CONFIG_SYS_SETTINGS,
            actionTypes.GET_CONFIG_SYS_SETTINGS_SUCCESS,
            actionTypes.GET_CONFIG_SYS_SETTINGS_FAILURE,
        ],
    },
});

export const getMenuSettings = (session: string): ApiMiddlewareActionType => ({
    api: {
        list: true,
        query: "MenuSettings",
        session,
        types: [
            actionTypes.GET_MENU_SETTINGS_ACTION,
            actionTypes.GET_MENU_SETTINGS_ACTION_SUCCESS,
            actionTypes.GET_MENU_SETTINGS_ACTION_FAILURE,
        ],
    },
});

export const getMultipleLSPayVisibility = (session: string): ApiMiddlewareActionType => ({
    api: {
        query: "GetMultipleLSPayVisibility",
        session,
        types: [
            actionTypes.GET_MULTIPLE_LS_PAY_VISIBILITY_ACTION,
            actionTypes.GET_MULTIPLE_LS_PAY_VISIBILITY_ACTION_SUCCESS,
            actionTypes.GET_MULTIPLE_LS_PAY_VISIBILITY_ACTION_FAILURE,
        ],
    },
});
