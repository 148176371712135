// Source https://github.com/reduxjs/react-redux/blob/master/src/utils/shallowEqual.js

function is(firstArg, secondArg) {
    if (firstArg === secondArg) {
        return firstArg !== 0 || secondArg !== 0 || 1 / firstArg === 1 / secondArg;
    }

    // eslint-disable-next-line no-self-compare
    return firstArg !== firstArg && secondArg !== secondArg;
}

export default function shallowEqual(objA, objB) {
    if (is(objA, objB)) {
        return true;
    }

    if (typeof objA !== "object" || objA === null || typeof objB !== "object" || objB === null) {
        return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
        return false;
    }
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysA.length; index++) {
        if (!Object.prototype.hasOwnProperty.call(objB, keysA[index]) || !is(objA[keysA[index]], objB[keysA[index]])) {
            return false;
        }
    }

    return true;
}
