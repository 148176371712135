// @flow
import * as React from "react";
import {DateTimePicker} from "material-ui-pickers";
import DateRange from "@material-ui/icons/DateRange";
import AccessTime from "@material-ui/icons/AccessTime";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Event from "@material-ui/icons/Event";
import Clear from "@material-ui/icons/Clear";
import TextField from "../../components/Form/TextField";
import {patternTypes} from "../../Builder/BPattern/PatternParsed/patternTypes";

type Props = {|
    defaultValue?: any,
    InputProps: any,
    onChange?: (event: SyntheticInputEvent<>) => void,
|};

export class CustomDateTimePicker extends React.Component<Props> {
    handleDateTimeChange = (value: Date) => {
        const date = patternTypes.datetime({data: value, format: "dd.mm.yyyy hh:mm"});

        this.props.InputProps.onChange(date);
    };

    render() {
        const {defaultValue, ...otherProps} = this.props;

        return (
            <DateTimePicker
                value={defaultValue}
                onChange={this.handleDateTimeChange}
                ampm={false}
                autoSubmit={false}
                cancelLabel="Отменить"
                clearLabel={<Clear />}
                clearable={true}
                dateRangeIcon={<DateRange />}
                format="HH:mm DD-MM-YYYY"
                invalidDateMessage="Неверная дата"
                invalidLabel="Не заполнено"
                keyboard={false}
                keyboardIcon={<Event />}
                leftArrowIcon={<KeyboardArrowLeft />}
                okLabel="Принять"
                returnMoment={false}
                rightArrowIcon={<KeyboardArrowRight />}
                showTabs={true}
                TextFieldComponent={TextField}
                timeIcon={AccessTime}
                {...otherProps}
            />
        );
    }
}

export default CustomDateTimePicker;
