import {Redirect, Route, Switch} from "react-router-dom";
import Async from "react-code-splitting";
import React from "react";
import AccountMainPage from "./pages/AccountMainPage";
import AuthorizationPage from "./pages/AuthorizationPage";
import DummyPage from "./pages/Dummy/DummyPage";
import WelcomePage from "./pages/Welcome/WelcomePage";
import PayMoeResultPage from "./pages/PayResult/PayMoeResultPage";
import Autopayment from "./pages/Autopayment/Autopayment";
import CallbackUrlPage from "./pages/CallbackUrlPage";
import {PayAdditionalServicesPage} from "./pages/PayAdditionalServices/PayAdditionalServicesPage";
import OpenMobileAppPage from "./pages/OpenMobileAppPage/OpenMobileAppPage";
import {OpenMobilePaymentSuccessPage} from "./pages/OpenMobilePaymentSuccessPage/OpenMobilePaymentSuccessPage";
import {OpenMobilePaymentFailurePage} from "./pages/OpenMobilePaymentFailurePage/OpenMobilePaymentFailurePage";
import RegisterMIRIframe from "./pages/RegisterMIRIframe/RegisterMIRIframe";
import {OpenUrlPage} from "./pages/OpenUrl/OpenUrlPage";

const AsyncAuthPage = (props) => (
    // eslint-disable-next-line capitalized-comments, no-inline-comments
    <Async componentProps={props} load={import("./pages/auth/AuthPage" /* webpackChunkName: "AuthPage" */)} />
);
const AsyncProfilePage = (props) => (
    // eslint-disable-next-line capitalized-comments, no-inline-comments
    <Async componentProps={props} load={import("./pages/Profile/ProfilePage" /* webpackChunkName: "ProfilePage" */)} />
);
const AsyncAddAccountPage = (props) => (
    <Async
        componentProps={props}
        // eslint-disable-next-line capitalized-comments, no-inline-comments
        load={import("./pages/AddAccount/AddAccountPage" /* webpackChunkName: "AddAccountPage" */)}
    />
);
const AsyncTestPage = (props) => (
    // eslint-disable-next-line capitalized-comments, no-inline-comments
    <Async componentProps={props} load={import("./pages/TestPage/TestPage" /* webpackChunkName: "TestPage" */)} />
);
const AsyncMapPage = (props) => (
    // eslint-disable-next-line capitalized-comments, no-inline-comments
    <Async componentProps={props} load={import("./pages/Map/routes" /* webpackChunkName: "MapPage" */)} />
);
const AsyncTransferIndications = (props) => (
    <Async
        componentProps={props}
        load={import(
            "./pages/TransferIndications" +
                // eslint-disable-next-line capitalized-comments, no-inline-comments
                "/TransferIndicationsPage" /* webpackChunkName: "TransferIndications" */
        )}
    />
);

const AsyncPayAllPage = (props) => (
    <Async
        componentProps={props}
        load={import(
            "./pages/PayAll" +
                // eslint-disable-next-line capitalized-comments, no-inline-comments
                "/PayAllPage" /* webpackChunkName: "PayAll" */
        )}
    />
);

const AsyncQuestionsAndAnswersPage = (props) => (
    <Async
        componentProps={props}
        // eslint-disable-next-line capitalized-comments, no-inline-comments
        load={import("./pages/QuestionsAndAnswersPage/routes" /* webpackChunkName: "FaqPage" */)}
    />
);
const AsyncServicesPage = (props) => (
    <Async
        componentProps={props}
        // eslint-disable-next-line capitalized-comments, no-inline-comments
        load={import("./pages/ServicesPage/routes" /* webpackChunkName: "ServicesPage" */)}
    />
);

const AsyncEnergyContractsPage = (props) => (
    <React.Fragment>
        <Async
            componentProps={props}
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("./pages/EnergyContractsPage" /* webpackChunkName: "EnergyContracts" */)}
        />
        <Async
            componentProps={props}
            // eslint-disable-next-line capitalized-comments, no-inline-comments
            load={import("./pages/CallbackUrlPage" /* webpackChunkName: "CallbackUrlPage" */)}
        />
    </React.Fragment>
);

export const routes = () => (
    <Switch>
        <Route component={AsyncAuthPage} exafct path={"/auth"} />
        <Route component={AsyncTestPage} path={"/test"} />
        <Route component={OpenMobileAppPage} path={"/open-mobile-app"} />
        <Route component={OpenMobilePaymentSuccessPage} path={"/open_mobile_gpay_s"} />
        <Route component={OpenMobilePaymentFailurePage} path={"/open_mobile_gpay_f"} />
        <Route component={RegisterMIRIframe} path="/register-mir-page" />
        <Route component={OpenUrlPage} path="/open_url" />
        <AuthorizationPage>
            <Switch>
                <Route component={WelcomePage} exact path="/home" />
                <Route component={Autopayment} path="/autopayment" />
                <Route component={DummyPage} exact path="/dummy" />
                <Route component={AccountMainPage} path="/accounts/:accountId" />
                <Route component={AsyncProfilePage} path="/profile" />
                <Route component={AsyncAddAccountPage} path="/account-add" />
                <Route component={AsyncMapPage} path="/map" />
                <Route component={AsyncQuestionsAndAnswersPage} path="/faq" />
                <Route component={AsyncPayAllPage} path="/pay-all" />
                <Route component={AsyncTransferIndications} path="/transfer-indications-all" />
                <Route component={PayMoeResultPage} path="/moepaystatus" />
                <Route component={PayMoeResultPage} path="/trashpaystatus" />
                <Route component={AsyncServicesPage} path="/services" />
                <Route component={AsyncEnergyContractsPage} path="/energy-contracts" />
                <Route component={PayAdditionalServicesPage} path="/pay-additional-services" />
                <Route component={CallbackUrlPage} path="/tmk_back_url_s" />
                <Route component={CallbackUrlPage} path="/tmk_back_url_f" />
                <Route component={CallbackUrlPage} path="/ufa_back_url_s" />
                <Route component={CallbackUrlPage} path="/ufa_back_url_f" />
                <Route component={CallbackUrlPage} path="/vlg_back_url_s" />
                <Route component={CallbackUrlPage} path="/vlg_back_url_f" />
                <Route component={CallbackUrlPage} path="/orl_back_url_s" />
                <Route component={CallbackUrlPage} path="/orl_back_url_f" />
                <Route component={CallbackUrlPage} path="/alt_back_url_c" />
                <Route component={CallbackUrlPage} path="/alt_back_url_d" />
                <Route component={CallbackUrlPage} path="/alt_back_url_s" />
                <Route component={CallbackUrlPage} path="/orl_back_url_f" />
                <Route component={CallbackUrlPage} path="/sar_back_url_c" />
                <Route component={CallbackUrlPage} path="/sar_back_url_d" />
                <Route component={CallbackUrlPage} path="/sar_back_url_f" />
                <Route component={CallbackUrlPage} path="/sar_back_url_s" />
                <Route component={CallbackUrlPage} path="/tmb_back_url" />
                <Route component={CallbackUrlPage} path="/stisubackurl" />
                <Route component={CallbackUrlPage} path="/mass_pay_back_url_s" />
                <Route component={CallbackUrlPage} path="/mass_pay_back_url_f" />
                <Route component={CallbackUrlPage} path="/payment_v_status_f" />
                <Route component={CallbackUrlPage} path="/payment_v_status_s" />
                <Route component={CallbackUrlPage} path="/vld_back_url" />
                <Route component={CallbackUrlPage} path="/lseditbackurl" />
                <Route component={CallbackUrlPage} path="/gpbackurl" />
                <Route component={CallbackUrlPage} path="/claimbackurl" />
                <Route component={CallbackUrlPage} path="/back_url_epr" />
                <Route component={CallbackUrlPage} path="/complaintbackurl" />
                <Redirect exact from="/accounts" to="accounts/0" />
                <Redirect exact from="" to="auth" />
            </Switch>
        </AuthorizationPage>
    </Switch>
);

export default routes;
