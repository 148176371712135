// @flow
import * as React from "react";
import type {DateType} from "material-ui-pickers/constants/prop-types";
import {getElement} from "./Elements";

type NmElementType =
    | "BANNER"
    | "BUTTON"
    | "BUTTON_REF"
    | "CALENDAR"
    | "CHECKBOX"
    | "IMAGE"
    | "IMAGE_LIST"
    | "LIST"
    | "REFERENCE"
    | "REFERENCE_ARRAY"
    | "SLIDER"
    | "TEXT";

type Props = {
    vlDefault?: any,
    content?: Array<Object>,
    fontSize?: 18 | 16 | 22,
    nmElementType?: NmElementType,
    prVisible?: boolean,
    name?: string,
    required?: boolean,
    prRequired?: boolean,
    margin?: "none" | "dense" | "normal",
    testID?: string,
    list?: boolean,
    zeroPadding?: boolean,
    zeroMinHeight?: boolean,
    spacing?: 8 | 16,
    direction?: "row" | "column",
    innerImage?: boolean,
    underline?: "solid" | "dashed" | "native" | "none",
    textColor?: "white",
    color?: "primary" | "primaryTranslucent" | "secondary" | "transparent",
    nmFileExtensions?: string,
    size?: "xs" | "sm" | "lg" | "full",
    link?: boolean,
    typographyStyleExtra?: Object,
    buttonGridItemStyleExtra?: Object,
    presetStyleExtra?: string,
    CustomComponent?: () => React.Node,
    onClick?: () => void,
    onChange?: () => void,
    isMirrorDisabled?: boolean,
    minDate?: DateType,
    maxDate?: DateType,
    capitalize?: boolean,
    disabled?: boolean,
    searchParams?: Object,
    extraParams?: Object,
};

export class BElement extends React.PureComponent<Props> {
    render() {
        const {
            nmElementType,
            nmFileExtensions,
            fontSize,
            vlDefault,
            prVisible,
            name,
            content,
            required,
            margin,
            testID,
            list,
            zeroPadding,
            zeroMinHeight,
            spacing,
            direction,
            innerImage,
            underline,
            textColor,
            color,
            size,
            link,
            isMirrorDisabled,
            typographyStyleExtra,
            buttonGridItemStyleExtra,
            presetStyleExtra,
            CustomComponent,
            onClick,
            onChange,
            prRequired,
            minDate,
            maxDate,
            capitalize,
            disabled,
            searchParams,
            extraParams,
        } = this.props;
        const Element = getElement(nmElementType);

        if (!Element || !prVisible) {
            return null;
        }

        return (
            <Element
                nmFileExtensions={nmFileExtensions}
                valueDefault={vlDefault}
                name={name}
                content={content}
                required={required}
                prRequired={prRequired}
                minDate={minDate}
                maxDate={maxDate}
                capitalize={capitalize}
                disabled={disabled}
                fontSize={fontSize}
                margin={margin}
                testID={testID}
                list={list}
                zeroMinHeight={zeroMinHeight}
                zeroPadding={zeroPadding}
                spacing={spacing}
                direction={direction}
                innerImage={innerImage}
                underline={underline}
                textColor={textColor}
                color={color}
                size={size}
                link={link}
                isMirrorDisabled={isMirrorDisabled}
                typographyStyleExtra={typographyStyleExtra}
                buttonGridItemStyleExtra={buttonGridItemStyleExtra}
                presetStyleExtra={presetStyleExtra}
                CustomComponent={CustomComponent}
                onClick={onClick}
                onChange={onChange}
                searchParams={searchParams}
                extraParams={extraParams}
            />
        );
    }
}

export default BElement;
