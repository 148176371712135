// @flow
import * as React from "react";
import {Link} from "react-router-dom";
import queryString from "qs";
import Typography from "../../../../packages/components/Typography/Typography";
import {type TopMenuItem} from "../../../../redux/reducers/configReducer";
import TopMenuDropDownBtn from "../../TopMenuDropDownBtn";

type Props = {
    items: Array<TopMenuItem>,
    classes?: Object,
    onClose?: () => void,
};

export const SubMenuBody = (props: Props) => {
    const {onClose, items} = props;

    return items.map<React.Node>((item: TopMenuItem, index: number) => (
        <TopMenuDropDownBtn
            key={item.url + index}
            disableRipple
            to={{
                pathname: item.url,
                search: queryString.stringify(item.query),
            }}
            component={Link}
            onClick={onClose}
        >
            <Typography align="center" fontSize={14} fontWeight={400} textColor="white">
                {item.text}
            </Typography>
        </TopMenuDropDownBtn>
    ));
};

export default SubMenuBody;
