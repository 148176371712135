// @flow

export const overrides = {
    MuiButton: {
        disabled: {
            color: "#fff !important",
        },
        root: {
            borderRadius: 4,
            fontSize: "1.13rem",
            fontWeight: 400,
        },
    },
    MuiCheckbox: {
        colorPrimary: {
            color: "#f44336",
        },
        colorSecondary: {
            color: "#2689D1 !important",
        },
        root: {
            color: "#2689D1",
        },
    },
    MuiCircularProgress: {
        root: {
            display: "block",
        },
    },
    MuiDialog: {
        paper: {
            margin: 16,
        },
        paperScrollPaper: {
            maxHeight: "calc(100% - 32px)",
        },
    },
    MuiDialogContent: {
        root: {
            overflowX: "hidden",
        },
    },
    MuiDialogTitle: {
        /* Отступ 80px взят из расчета отсутупа модалки от левой и правой границе а так-же размер крестика*/
        root: {
            maxWidth: "calc(100% - 80px)",
        },
    },
    MuiDrawer: {
        paperAnchorLeft: {
            left: "auto",
        },
    },
    MuiFormHelperText: {
        root: {
            fontSize: 12,
        },
    },
    MuiFormLabel: {
        /* Без !important перебивает совокупность стилей*/
        focused: {
            color: "#f49035 !important",
        },
        root: {
            fontSize: 14,
        },
    },
    MuiInput: {
        input: {
            "&::-ms-reveal": {
                display: "none",
            },
            fontSize: 16,
            padding: 0,
        },
        inputMarginDense: {
            minHeight: 20,
            paddingTop: 0,
        },
        root: {
            "&$formControl": {
                alignItems: "center",
            },
            width: "100%",
        },
    },
    MuiInputLabel: {
        formControl: {
            position: "static",
        },
    },
    MuiListSubheader: {
        root: {
            fontWeight: 400,
        },
    },
    MuiMenuItem: {
        root: {
            height: "auto",
            minHeight: 24,
            whiteSpace: "normal",
        },
    },
    MuiModal: {
        hidden: {
            visibility: "visible",
        },
    },
    MuiPickersDay: {
        selected: {
            "&:hover": {
                backgroundColor: "#f49035",
            },
            backgroundColor: "#f49035",
        },
    },
    MuiPickersModal: {
        dialog: {
            width: "100%",
        },
        dialogAction: {
            color: "#2689D1",
        },
    },
    MuiPickersToolbarButton: {
        toolbarBtn: {
            "&:first-child": {
                "&:before": {
                    borderLeft: "4px solid",
                    borderRadius: "0px 4px 0px 4px",
                    borderTop: "4px solid",
                    content: "' '",
                    display: "block",
                    height: 12,
                    position: "absolute",
                    right: -30,
                    top: 18,
                    transform: "rotate(45deg)",
                    width: 12,
                },
                borderBottom: "3px dashed",
                fontSize: 34,
                lineHeight: "40px",
                top: 40,
            },
            "&:last-child": {
                fontSize: 16,
                top: 18,
            },
            color: "#fff",
            left: "50%",
            opacity: "0.54",
            position: "absolute",
            transform: "translateX(-50%)",
        },
        toolbarBtnSelected: {
            "&:first-child": {
                "&:before": {
                    borderLeft: "4px solid",
                    borderRadius: "0px 4px 0px 4px",
                    borderTop: "4px solid",
                    content: "' '",
                    display: "block",
                    height: 12,
                    position: "absolute",
                    right: -30,
                    top: 10,
                    transform: "rotate(-135deg)",
                    width: 12,
                },
            },
            opacity: "1",
        },
    },
    MuiPickersYear: {
        selected: {
            "&:focus": {
                color: "#f49035",
            },
            color: "#f49035",
        },
    },
    MuiRadio: {
        colorPrimary: {
            color: "#2689D1 !important",
        },
        root: {
            color: "#2689D1",
        },
    },
    MuiSvgIcon: {
        colorAction: {
            color: "#fff",
        },
        colorPrimary: {
            color: "#2689D1",
        },
        root: {
            fontSize: 24,
            height: "1em !important",
            width: "1em !important",
        },
    },
    MuiSwitch: {
        bar: {
            backgroundColor: "#f2f2f2 !important",
            boxShadow: " 0 0 2px",
        },
        checked: {
            "& + $bar": {
                backgroundColor: "#88e1ff !important",
            },
            color: "#0099cc !important",
        },
        switchBase: {
            color: "#cccccc ",
        },
    },
    MuiTab: {
        flexContainer: {
            minHeight: 48,
        },
        root: {
            fontWeight: 400,
        },
        selected: {
            color: "#F3A52F",
        },
        textColorInherit: {
            opacity: 1,
        },
    },
    MuiTabs: {
        flexContainer: {
            position: "relative",
            zIndex: "auto",
        },
    },
    MuiTooltip: {
        tooltip: {
            fontSize: 14,
            maxWidth: "calc(100vw - 120px)",
        },
        touch: {
            fontSize: 14,
        },
    },
    MuiTypography: {
        body1: {
            fontSize: "1rem",
            fontWeight: 400,
        },
        subheading: {
            fontWeight: 400,
        },
        title: {
            fontWeight: 400,
            lineHeight: "1.3rem",
        },
    },
    TabIndicator: {
        root: {
            height: 4,
        },
    },
};
