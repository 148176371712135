// @flow
import * as React from "react";
import {connect} from "react-redux";
import get from "lodash/get";
import {bindActionCreators} from "redux";
import type {BrowserHistory} from "history/createBrowserHistory";

import {type BuilderStateType} from "../../constants/types/BuilderTypes";
import type {StoreType} from "../../redux/reducers";
import {getBuilderElementsAction, type BuilderGetElementsType} from "../../redux/actions/builderAction";
import {type TopMenuItem} from "../../redux/reducers/configReducer";
import WelcomePageButtons from "../../components/WelcomePage/WelcomePageButtons";
import WelcomePageSimple from "../../components/WelcomePage/WelcomePageSimple";

type StoreProps = {|
    authElements: BuilderStateType,
    profileBuilder: $PropertyType<$PropertyType<StoreType, "builder">, "profile">,
    topMenuItems: Array<TopMenuItem>,
|};
type DispatchProps = {|
    getBuilderElementsAction: BuilderGetElementsType,
|};
type OwnProps = {|
    history: BrowserHistory,
    type?: "simple" | "withButtons",
|};

export type Props = {...OwnProps, ...StoreProps, ...DispatchProps};

export class WelcomePage extends React.Component<Props> {
    static defaultProps = {
        type: "withButtons",
    };

    componentDidMount = () => {
        this.props.getBuilderElementsAction("auth");
    };

    render() {
        const {authElements, profileBuilder, history, type, topMenuItems} = this.props;
        const {NM_FIRST = "", NM_LAST = ""} = profileBuilder.initialValues;
        const {elements} = authElements;
        const url = get(elements["57"], "content[0].imgsrc", "");

        if (type === "withButtons") {
            return (
                <WelcomePageButtons
                    history={history}
                    backgroundUrl={url}
                    nmFirst={NM_FIRST}
                    nmLast={NM_LAST}
                    topMenuItems={topMenuItems}
                />
            );
        }

        return <WelcomePageSimple history={history} backgroundUrl={url} nmFirst={NM_FIRST} nmLast={NM_LAST} />;
    }
}

const mapStateToProps = ({builder, config}: StoreType): StoreProps => ({
    authElements: builder.auth,
    profileBuilder: builder.profile,
    topMenuItems: config.topMenuItems,
});

const mapDispatchToProps = (dispatch): DispatchProps =>
    bindActionCreators(
        {
            getBuilderElementsAction,
        },
        dispatch,
    );

export default connect<Props, OwnProps, StoreProps, DispatchProps, StoreType, _>(
    mapStateToProps,
    mapDispatchToProps,
)(WelcomePage);
