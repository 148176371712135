/* eslint-disable max-lines */
// @flow
import React, {PureComponent, Fragment} from "react";
import cn from "classnames";
import Grid from "@material-ui/core/Grid";
import {type RouterHistory, type Location} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ButtonBase from "@material-ui/core/ButtonBase";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import Hidden from "@material-ui/core/Hidden/Hidden";
import queryString from "qs";
import {type ApiMiddlewareResponsePromiseType} from "../../redux/middlewares/apiMiddleware";
import {openCloseSideBar} from "../../redux/actions/sideBarAction";
import {accountClearLocalAccountDetailAction} from "../../redux/actions/accountAction";
import {
    getMenuSettings,
    getMultipleLSPayVisibility,
    type GetMultipleLSPayVisibilityActionType,
} from "../../redux/actions/configAction";
import {getPowSupProvidersAction, type GetPowSupProvidersActionType} from "../../redux/actions/energyContractsAction";
import {
    openCriticalNoticeModal,
    getCriticalNotice,
    getLsListNoticeStatusAction,
    setLsSubscrEmail,
    setNoticeReaded,
    closeCriticalNoticeModal,
    userSetLsSubscrbyEventAction,
    type GetCriticalNoticeType,
} from "../../redux/actions/notificationAction";
import {
    authLogoutAction,
    openConfirmEmailOrSMSModalAction,
    openExtraRegistrationFormAction,
    authLoginAction,
    extraRegistrationProfileEditAction,
    closeExtraRegistrationFormAction,
    type AuthLogoutAction,
    type OpenConfirmEmailOrSMSModalActionType,
    type ExtraRegistrationProfileEditActionType,
    type AuthLoginActionType,
} from "../../redux/actions/authAction";
import {
    getExtraRegistrationAttributesAction,
    getBuilderElementsAction,
    type BuilderGetAttributesType,
    type BuilderGetElementsType,
} from "../../redux/actions/builderAction";
import {SERVICE_PAGE_KD_SECTION_TEST, SERVICE_PAGE_KD_SECTION_WITH_CRMKU} from "../../constants/ServicePageKdSection";
import {
    ALL_LS_MES_PAY_KD_SECTION,
    ENERGY_CONTRACTS_KD_SECTION,
    PAY_ADDITIONAL_SERVICES_KD_SECTION,
    LKK_EIRC_KD_SECTION,
} from "../../constants/kdSections";
import type {StoreType} from "../../redux/reducers";
import {type OperationsForAllAccountsType, type TopMenuItem} from "../../redux/reducers/configReducer";
import type {themeTypes} from "../../themeStyle";
import Typography from "../../packages/components/Typography/Typography";
import BElement from "../../packages/Builder/BElement/BElement";
import {getLocaleStorageAction} from "../../redux/actions/storageActions/localeStorageAction";
import {menuSettingsKey} from "../../constants/Application";
import TopMenuDrawer from "./TopMenuDrawer";
import TopMenuUserAccount from "./TopMenuUserAccount";
import TopMenuOperationsForAllAccounts from "./TopMenuOperationsForAllAccounts/TopMenuOperationsForAllAccounts";
import TopMenuNotifications from "./TopMenuNotifications";
import TopMenuSimpleBtn from "./TopMenuSimpleBtn";

const styles = (theme: themeTypes) => ({
    button: {
        boxSizing: "border-box",
        color: theme.palette.text.white,
        height: "60px",
        padding: "0 8px",
        verticalAlign: "baseline",
    },
    container: {
        background: theme.palette.secondary.light2,
        display: "flex",
    },
    iconContainer: {
        background: theme.palette.grey.sideBar,
        minHeight: theme.sizing.appbarHeight,
        padding: "0px 16px",
        [theme.breakpoints.down("xs")]: {
            padding: "0px 12px",
        },
    },
    poll: {
        paddingTop: "2px",
    },
    root: {
        background: theme.palette.secondary.light2,
        height: theme.sizing.appbarHeight,
        position: "relative",
    },
    toolbar: {
        minHeight: theme.sizing.appbarHeight,
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.down("sm")]: {
            justifyContent: "space-between",
        },
    },
});

type OwnProps = {|
    pathname: string,
    history: RouterHistory,
    classes?: {
        [$Keys<$Call<typeof styles, any>>]: string,
    },
    location: Location,
|};

type StoreProps = {|
    accountsList: Array<Object>,
    criticalNotice: Array<Object>,
    fetchingAccountLogout: boolean,
    kdProvider?: number,
    ls?: string,
    isProfileDropdownOpen: boolean,
    isCriticalNoticeModalOpen: boolean,
    poll: $PropertyType<$PropertyType<StoreType, "builder">, "poll">,
    lkkEirc: Object,
    pollConfig: {nmSection: string, kdSection: number, prVisible: boolean},
    profileBuilder: $PropertyType<$PropertyType<StoreType, "builder">, "profile">,
    servicesProviderList: Array<Object>,
    session: string,
    sideBarIsOpen?: boolean,
    topMenuItems?: Array<TopMenuItem>,
    operationsForAllAccounts: OperationsForAllAccountsType,
    vlToken: string | null,
    auth: $PropertyType<StoreType, "auth">,
    config: $PropertyType<StoreType, "config">,
    extraRegistrationBuilder: $PropertyType<$PropertyType<StoreType, "builder">, "registration">,
    autopayMesBuilder: $PropertyType<$PropertyType<StoreType, "builder">, "autopayMes">,
    paspDetailsFailed: Object,
|};

type DispatchProps = {|
    openCloseSideBar: () => void,
    authLogoutAction: AuthLogoutAction,
    accountClearLocalAccountDetailAction: () => void,
    getMenuSettings: (session: string) => void,
    getMultipleLSPayVisibility: GetMultipleLSPayVisibilityActionType,
    getPowSupProvidersAction: GetPowSupProvidersActionType,
    getBuilderElementsAction: BuilderGetElementsType,
    openCriticalNoticeModal: () => void,
    getCriticalNotice: GetCriticalNoticeType,
    setNoticeReaded: (idNotice: string, session?: string) => ApiMiddlewareResponsePromiseType,
    setLsSubscrEmail: (idService: string, session?: string) => ApiMiddlewareResponsePromiseType,
    userSetLsSubscrbyEventAction: (idService: string, session?: string) => ApiMiddlewareResponsePromiseType,
    getLsListNoticeStatusAction: (session: string) => ApiMiddlewareResponsePromiseType,
    closeCriticalNoticeModal: (fromModal: boolean) => void,
    extraRegistrationProfileEditAction: ExtraRegistrationProfileEditActionType,
    closeExtraRegistrationFormAction: () => void,
    openExtraRegistrationFormAction: () => void,
    openConfirmEmailOrSMSModalAction: OpenConfirmEmailOrSMSModalActionType,
    getExtraRegistrationAttributesAction: BuilderGetAttributesType,
    onLogin: AuthLoginActionType,
|};

type Props = {...OwnProps, ...StoreProps, ...DispatchProps};

export class TopMenu extends PureComponent<Props> {
    componentDidMount = () => {
        const {session = "", criticalNotice} = this.props;

        this.props.getMultipleLSPayVisibility(session);
        this.props.getBuilderElementsAction("poll", session, SERVICE_PAGE_KD_SECTION_TEST);
        this.props.getPowSupProvidersAction(session);
        if (process.env.REACT_APP_PROVIDER_UFA === "on") {
            this.props.getBuilderElementsAction("lkkEirc", session, LKK_EIRC_KD_SECTION);
        }

        if (criticalNotice && criticalNotice.length === 0) {
            this.props.getCriticalNotice(session);
        }
    };

    handleLogout = () =>
        this.props.authLogoutAction(
            this.props.session,
            this.props.vlToken,
            String(process.env.REACT_APP_SUDIR_LOGOUT_URL),
        );

    render() {
        const {
            auth = {},
            fetchingAccountLogout,
            profileBuilder,
            isProfileDropdownOpen,
            classes = {},
            poll: {elements},
            pollConfig,
            operationsForAllAccounts,
            criticalNotice,
            session,
            accountsList,
            isCriticalNoticeModalOpen,
            lkkEirc,
            paspDetailsFailed,
        } = this.props;
        const {idProfile = ""} = auth;
        let topMenuItems = getLocaleStorageAction(`${menuSettingsKey}-${idProfile}`)?.payload?.value || [];

        topMenuItems = Array.isArray(topMenuItems) ? topMenuItems : [];
        const {NM_LAST, NM_FIRST} = profileBuilder.initialValues;
        // Исключаем отображение "Оплата по всем ЛС МЭС" отдельным пунктом в меню и сортируем массив по kdSection
        const menuItems = topMenuItems
            ?.filter((item) => item.kdSection !== ALL_LS_MES_PAY_KD_SECTION)
            .sort((first, second) =>
                typeof first.kdSection === "number" && typeof second.kdSection === "number"
                    ? first.kdSection - second.kdSection
                    : 0,
            );
        const lkkEircRedirectButton = {...lkkEirc?.elements["578"]};
        const lkkEskcRedirectButton = {...lkkEirc?.elements["631"]};

        // Перемещаем пункт меню "Договора энергоснабжения" в конец списка
        if (menuItems[menuItems.length - 1]?.kdSection === PAY_ADDITIONAL_SERVICES_KD_SECTION) {
            menuItems.push(
                ...menuItems.splice(
                    menuItems.findIndex((item) => item.kdSection === ENERGY_CONTRACTS_KD_SECTION),
                    1,
                ),
            );
        }

        return (
            <Fragment>
                <AppBar className={classes.root} color="inherit" position="static" elevation={0} id="AppBar">
                    <Toolbar className={classes.toolbar} enterTouchDelay={0} testID="topMenu">
                        <Grid container wrap="nowrap" justify="space-between" alignItems="center">
                            <Grid item>
                                <Grid container alignItems="center" wrap="nowrap">
                                    <Hidden only={["lg", "xl"]} implementation="css">
                                        <Grid item>
                                            <ButtonBase
                                                className={classes.iconContainer}
                                                onClick={this.props.openCloseSideBar}
                                            >
                                                <KeyboardArrowLeft />
                                                <Typography display="inline">Ваши счета</Typography>
                                            </ButtonBase>
                                        </Grid>
                                    </Hidden>
                                    <Hidden mdUp implementation="css">
                                        <Grid item xs={12}>
                                            <TopMenuDrawer
                                                textLinks={menuItems}
                                                operationsForAllAccounts={operationsForAllAccounts}
                                                lkkEircRedirectButton={lkkEircRedirectButton}
                                                lkkEskcRedirectButton={lkkEskcRedirectButton}
                                            />
                                        </Grid>
                                    </Hidden>
                                    <Hidden smDown implementation="css">
                                        <Grid item>
                                            <div className={classes.container}>
                                                {menuItems.map((menuItem: TopMenuItem, index: number) =>
                                                    menuItem.url === "/transfer-indications-all" &&
                                                    operationsForAllAccounts.isOperationsForAllAccountsVisible ? (
                                                        <TopMenuOperationsForAllAccounts
                                                            operationsForAllAccounts={operationsForAllAccounts}
                                                            dropdownHeadName="Операции по всем лицевым счетам"
                                                        />
                                                    ) : (
                                                        <span>
                                                            <TopMenuSimpleBtn
                                                                key={index}
                                                                id={menuItem.id}
                                                                pathname={menuItem.url}
                                                                search={queryString.stringify(menuItem.query)}
                                                                selected={
                                                                    this.props.pathname.indexOf(menuItem.url) === 0
                                                                }
                                                                testID={menuItem.testID}
                                                                text={
                                                                    menuItem.kdSection ===
                                                                    SERVICE_PAGE_KD_SECTION_WITH_CRMKU
                                                                        ? "Каталог услуг"
                                                                        : menuItem.text
                                                                }
                                                                menuItem={menuItem}
                                                            />
                                                        </span>
                                                    ),
                                                )}
                                                {pollConfig && pollConfig.prVisible && (
                                                    <ButtonBase
                                                        className={cn(classes.button, classes.poll)}
                                                        disableRipple
                                                    >
                                                        <BElement
                                                            {...elements["219"]}
                                                            underline="none"
                                                            textColor="white"
                                                            zeroPadding
                                                            zeroMinHeight
                                                        />
                                                    </ButtonBase>
                                                )}
                                                {lkkEircRedirectButton && lkkEircRedirectButton.prVisible && (
                                                    <ButtonBase className={cn(classes.root)} disableRipple>
                                                        <BElement
                                                            {...lkkEircRedirectButton}
                                                            underline="none"
                                                            color="transparent"
                                                            size="sm"
                                                            link
                                                            zeroPadding
                                                            zeroMinHeight
                                                            typographyStyleExtra={{
                                                                border: "1px solid white",
                                                                borderRadius: "4px",
                                                                padding: "2px 6px",
                                                                width: "100%",
                                                            }}
                                                        />
                                                    </ButtonBase>
                                                )}
                                                {lkkEskcRedirectButton && lkkEskcRedirectButton.prVisible && (
                                                    <ButtonBase
                                                        className={cn(classes.button, classes.poll)}
                                                        disableRipple
                                                    >
                                                        <BElement
                                                            {...lkkEskcRedirectButton}
                                                            underline="none"
                                                            color="transparent"
                                                            size="full"
                                                            fontSize={14}
                                                            link
                                                            zeroPadding
                                                            zeroMinHeight
                                                            typographyStyleExtra={{
                                                                padding: "0 16px",
                                                                textAlign: "start",
                                                                width: "175px",
                                                            }}
                                                        />
                                                    </ButtonBase>
                                                )}
                                            </div>
                                        </Grid>
                                    </Hidden>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" wrap="nowrap">
                                    <Grid item>
                                        <TopMenuNotifications
                                            criticalNotice={criticalNotice}
                                            session={session}
                                            accountsList={accountsList}
                                            isCriticalNoticeModalOpen={isCriticalNoticeModalOpen}
                                            onOpenNotifications={this.props.openCriticalNoticeModal}
                                            onCloseNotifications={this.props.closeCriticalNoticeModal}
                                            setNoticeReaded={this.props.setNoticeReaded}
                                            setLsSubscrEmail={this.props.setLsSubscrEmail}
                                            getLsListNoticeStatusAction={this.props.getLsListNoticeStatusAction}
                                            history={this.props.history}
                                            auth={this.props.auth}
                                            config={this.props.config}
                                            authLoginAction={this.props.onLogin}
                                            location={this.props.location}
                                            extraRegistrationProfileEditAction={
                                                this.props.extraRegistrationProfileEditAction
                                            }
                                            openExtraRegistrationFormAction={this.props.openExtraRegistrationFormAction}
                                            closeExtraRegistrationFormAction={
                                                this.props.closeExtraRegistrationFormAction
                                            }
                                            getExtraRegistrationAttributesAction={
                                                this.props.getExtraRegistrationAttributesAction
                                            }
                                            getBuilderElementsAction={this.props.getBuilderElementsAction}
                                            extraRegistrationBuilder={this.props.extraRegistrationBuilder}
                                            userSetLsSubscrbyEventAction={this.props.userSetLsSubscrbyEventAction}
                                            openConfirmEmailOrSMSModalAction={
                                                this.props.openConfirmEmailOrSMSModalAction
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TopMenuUserAccount
                                            fetchingAccountLogout={fetchingAccountLogout}
                                            firstName={NM_FIRST}
                                            secondName={NM_LAST}
                                            handleLogout={this.handleLogout}
                                            isProfileDropdownOpen={isProfileDropdownOpen}
                                            paspDetailsFailed={paspDetailsFailed}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Fragment>
        );
    }
}

const mapStateToProps = ({
    account,
    sideBar,
    auth,
    builder,
    config,
    services,
    onboarding,
    notification,
    profile,
}: StoreType): StoreProps => ({
    accountsList: account.accountsList,
    auth,
    autopayMesBuilder: builder.autopayMes,
    config,
    criticalNotice: notification.criticalNotice,
    extraRegistrationBuilder: builder.extraRegistrationUnnecessaryFields,
    fetchingAccountLogout: account.fetchingAccountLogout,
    isCriticalNoticeModalOpen: notification.isCriticalNoticeModalOpen,
    isProfileDropdownOpen: onboarding.isProfileDropdownOpen,
    kdProvider: account.accountDetail.kdProvider,
    lkkEirc: builder.lkkEirc,
    ls: account.accountDetail.nnLs,
    operationsForAllAccounts: config.operationsForAllAccounts,
    paspDetailsFailed: profile.paspDetailsFailed,
    poll: builder.poll,
    pollConfig: config.poll,
    profileBuilder: builder.profile,
    servicesProviderList: services.providerList,
    session: auth.session || "",
    sideBarIsOpen: sideBar.sideBarIsOpen,
    topMenuItems: config.topMenuItems,
    vlToken: auth.newToken || null,
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    accountClearLocalAccountDetailAction: bindActionCreators(accountClearLocalAccountDetailAction, dispatch),
    authLogoutAction: bindActionCreators(authLogoutAction, dispatch),
    closeCriticalNoticeModal: bindActionCreators(closeCriticalNoticeModal, dispatch),
    closeExtraRegistrationFormAction: () => dispatch(closeExtraRegistrationFormAction()),
    extraRegistrationProfileEditAction: (attributes, idProfile) =>
        dispatch(extraRegistrationProfileEditAction(attributes, idProfile)),
    getBuilderElementsAction: bindActionCreators(getBuilderElementsAction, dispatch),
    getCriticalNotice: bindActionCreators(getCriticalNotice, dispatch),
    getExtraRegistrationAttributesAction: (name, idProfile) =>
        dispatch(getExtraRegistrationAttributesAction(name, idProfile)),
    getLsListNoticeStatusAction: bindActionCreators(getLsListNoticeStatusAction, dispatch),
    getMenuSettings: bindActionCreators(getMenuSettings, dispatch),
    getMultipleLSPayVisibility: bindActionCreators(getMultipleLSPayVisibility, dispatch),
    getPowSupProvidersAction: bindActionCreators(getPowSupProvidersAction, dispatch),
    onLogin: (auth, showCaptcha, oneTimePass) => dispatch(authLoginAction(auth, showCaptcha, oneTimePass)),
    openCloseSideBar: () => dispatch(openCloseSideBar()),
    openConfirmEmailOrSMSModalAction: (data) => dispatch(openConfirmEmailOrSMSModalAction(data)),
    openCriticalNoticeModal: bindActionCreators(openCriticalNoticeModal, dispatch),
    openExtraRegistrationFormAction: bindActionCreators(openExtraRegistrationFormAction, dispatch),
    setLsSubscrEmail: bindActionCreators(setLsSubscrEmail, dispatch),
    setNoticeReaded: bindActionCreators(setNoticeReaded, dispatch),
    userSetLsSubscrbyEventAction: bindActionCreators(userSetLsSubscrbyEventAction, dispatch),
});

export default connect<Props, OwnProps, StoreProps, DispatchProps, StoreType, _>(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(TopMenu));
