// @flow
import * as React from "react";

const useIsEditMode = () => {
    const [isEditMode, toogleEditMode] = React.useState(false);

    const handleToogleEditMode = () => {
        toogleEditMode(!isEditMode);
    };

    return {isEditMode, onToogleEditMode: handleToogleEditMode};
};

export {useIsEditMode};
