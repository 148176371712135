/* eslint-disable max-lines */
// @flow
import * as actionTypes from "../../constants/types/ActionTypes";

import {type ApiMiddlewareActionType, type ApiMiddlewareResponsePromiseType} from "../middlewares/apiMiddleware";
import {
    accountLoadDataNames,
    providersPlugin,
    type AccountLoadDataKeysType,
    type AccountParamsLoadDataType,
} from "../../constants/types/AccountTypes";
import {periodKey} from "../../constants/Application";
import {setLocaleStorageAction} from "./storageActions/localeStorageAction";

export type LoadAccountActionType = (
    accountId: string,
    session: string,
    kdProvider?: number,
) => ApiMiddlewareResponsePromiseType;
export type LoadAccountsListActionType = (session: string, params?: Object) => ApiMiddlewareResponsePromiseType;
export type DeleteAccountActionType = (idProfile: string, nnLs: string) => ApiMiddlewareResponsePromiseType;
export type LoadAccountDataActionType = (
    name: AccountLoadDataKeysType,
    params: AccountParamsLoadDataType,
    session?: string,
) => ApiMiddlewareResponsePromiseType;
export type LoadAccountDataMapActionType = (
    name: AccountLoadDataKeysType,
    params: AccountParamsLoadDataType,
    session?: string,
) => ApiMiddlewareResponsePromiseType;
export type LoadLsGroupsActionType = (idService: string, session: string) => ApiMiddlewareResponsePromiseType;
export type LsSetGroupActionType = (
    idService: string,
    session: string,
    idLsGroup: string,
) => ApiMiddlewareResponsePromiseType;
export type AccountEditActionType = (
    attributes: string,
    idService: string,
    session: string,
) => ApiMiddlewareResponsePromiseType;
export type GetTabsActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type PeriodType = {
    dtEn: string,
    dtSt: string,
    key: string,
    provider: string,
};
export type SelectAccountPerionActionType = (
    period: PeriodType,
    savePeriod?: boolean,
    periodName?: string,
) => {payload: {period: PeriodType, periodName: string, provider: string}};
export type AccountClearAccountDetailActionType = () => void;
export type IndicationAndPayAvailActionType = (session: string, kdProvder: number) => void;
export type CrmCheckCorrRequestAvailabilityActionType = (session?: string, idService: number, nnLs: string) => void;
export type CorrRequestInfoType = {
    nnPlomb?: string,
    vlCounter: Object,
    vlIndication: Array<Object>,
    vlDocs: Array<Object>,
    vlNote?: string,
};
export type CrmCheckCorrRequestActionType = (
    session?: string,
    idService: number,
    corrRequestDocs: CorrRequestInfoType,
) => Object;
export type CheckScheduledInspectionActionType = (
    idService: string,
    session: string,
) => ApiMiddlewareResponsePromiseType;
export type CheckNotifyKsgOverPayActionType = (idService: string, session: string) => ApiMiddlewareResponsePromiseType;
export type ConfirmScheduledInspectionActionType = (
    idService: string,
    idSchedule: string,
    session: string,
) => ApiMiddlewareResponsePromiseType;
export type SaveNotifyKsgOverPayActionType = (
    idService: string,
    idNotify: string,
    session: string,
) => ApiMiddlewareResponsePromiseType;
export type SaveNotifyKsgOverPayNActionType = (
    idService: string,
    idNotify: string,
    session: string,
) => ApiMiddlewareResponsePromiseType;
export type GetScheduledInspectionActionType = (idService: string, session: string) => ApiMiddlewareResponsePromiseType;
export type ChkLSReportDetailAvailActionType = (idService: string, session: string) => ApiMiddlewareResponsePromiseType;
export type ChkReportPaspActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type GetLSReportHistActionType = (session: string, data: Object) => ApiMiddlewareResponsePromiseType;

export const openFrameLink = (res: Object) => {
    if (!res.error && res.response.nmLink) {
        window.location.href = res.response.nmLink;
    }

    return res;
};

export const priviegesTabAction = (session: string, idService: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "PriviegesTab",
        session,
        types: [
            actionTypes.PROVIDER_TAB_ACTION,
            actionTypes.PROVIDER_TAB_ACTION_SUCCESS,
            actionTypes.PROVIDER_TAB_ACTION_FAILURE,
        ],
    },
});

export const loadAccountAction = (accountId: string, session: string, kdProvider?: number) => (dispatch: any) =>
    dispatch({
        api: {
            data: {
                kdProvider,
            },
            query: "IndicationAndPayAvail",
            session,
            types: [
                actionTypes.ACCOUNT_LOAD_ACTION,
                actionTypes.ACCOUNT_LOAD_ACTION_SUCCESS,
                actionTypes.ACCOUNT_LOAD_ACTION_FAILURE,
            ],
        },
        payload: {
            accountId,
        },
    }).then(() => {
        dispatch(priviegesTabAction(session, Number(accountId)));
    });

export const loadProviderInfo = (session: string): ApiMiddlewareActionType => ({
    api: {
        list: true,
        query: "MapProviderTabs",
        session,
        types: [
            actionTypes.GET_MAP_PROVIDER_LIST,
            actionTypes.GET_MAP_PROVIDER_LIST_SUCCESS,
            actionTypes.GET_MAP_PROVIDER_LIST_FAILURE,
        ],
    },
});
export const selectAccountPerionAction = (period: PeriodType, savePeriod?: boolean, periodName: string) => (
    dispatch: any,
): ApiMiddlewareActionType => {
    if (savePeriod) {
        dispatch(setLocaleStorageAction(periodKey, period));
    }

    return dispatch({
        payload: {
            period,
            periodName,
            provider: period.provider,
        },
        type: actionTypes.LS_SELECT_PERIOD_ACTION,
    });
};
export const loadAccountsListAction = (session: string, params?: Object): ApiMiddlewareActionType => ({
    api: {
        list: true,
        query: "LSList",
        session,
        types: [actionTypes.GET_LS_LIST, actionTypes.GET_LS_LIST_SUCCESS, actionTypes.GET_LS_LIST_FAILURE],
    },
    payload: {
        isDisabledAuthorisationError: params?.isDisabledAuthorisationError,
    },
});
export const deleteAccountAction = (idService: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "LSDelete",
        session,
        types: [actionTypes.LS_DELETE, actionTypes.LS_DELETE_SUCCESS, actionTypes.LS_DELETE_FAILURE],
    },
    payload: {
        accountId: idService,
    },
});
export const closeModalIndicationMes = () => ({
    type: actionTypes.CLOSE_MODAL_ACTION,
});
export const loadAccountIndicatorDataAction = (
    name: AccountLoadDataKeysType,
    {vlProvider, providerType, nnLs, kdProvider}: AccountParamsLoadDataType,
    session: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            plugin: providerType ? `${providerType}Proxy` : providersPlugin[kdProvider],
            proxyquery: accountLoadDataNames[name],
            vlProvider,
        },
        list: true,
        query: providerType ? `${providerType}Proxy` : providersPlugin[kdProvider],
        session,
        types: [
            actionTypes.LS_LOAD_INDICATOR_DATA_ACTION,
            actionTypes.LS_LOAD_INDICATOR_DATA_ACTION_SUCCESS,
            actionTypes.LS_LOAD_INDICATOR_DATA_ACTION_FAILURE,
        ],
    },
    payload: {
        name,
        nnLs,
    },
});

export const crmCheckCorrRequestAvailabilityAction = (
    session?: string,
    idService: number,
    nnLs: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            // eslint-disable-next-line camelcase
            nmRequest: JSON.stringify({id_service: [idService]}),
        },
        query: "CrmCheckCorrRequestAvailability",
        session,
        types: [
            actionTypes.LS_CHECK_INDICATOR_CORRECTION_ACTION,
            actionTypes.LS_CHECK_INDICATOR_CORRECTION_ACTION_SUCCESS,
            actionTypes.LS_CHECK_INDICATOR_CORRECTION_ACTION_FAILURE,
        ],
    },
    payload: {
        nnLs,
    },
});

export const crmCheckCorrRequestAction = (
    session?: string,
    idService: number,
    {nnPlomb, vlCounter, vlIndication, vlDocs, vlNote}: CorrRequestInfoType,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
            nnPlomb,
            vlCounter: JSON.stringify(vlCounter),
            vlDocs: JSON.stringify(vlDocs),
            vlIndication: JSON.stringify(vlIndication),
            vlNote,
        },
        query: "CrmCheckCorrRequest",
        session,
        types: [
            actionTypes.LS_CHECK_INDICATOR_CORRECTION_REQUEST_ACTION,
            actionTypes.LS_CHECK_INDICATOR_CORRECTION_REQUEST_ACTION_SUCCESS,
            actionTypes.LS_CHECK_INDICATOR_CORRECTION_REQUEST_ACTION_FAILURE,
        ],
    },
});

const getProxyquery = (name: string, kdProvider: number) => {
    const proxyqueryItem = accountLoadDataNames[name];

    if (proxyqueryItem && typeof proxyqueryItem !== "string") {
        return proxyqueryItem(kdProvider);
    }

    return proxyqueryItem;
};

export const loadAccountDataAction = (
    name: AccountLoadDataKeysType,
    {
        codTariff,
        accessInd,
        dtEn,
        dtStart,
        dtEnd,
        dtSt,
        dtPeriodStart,
        dtPeriodEnd,
        vlProvider,
        providerType,
        proxyplugin,
        kdTpMode,
        cancelToken,
        idService,
        idServiceProvider,
        noCache,
        normalizeFunction,
        kdProvider,
        additionalServices,
    }: AccountParamsLoadDataType,
    session: string,
) => (dispatch: any): ApiMiddlewareActionType => {
    const dateLength = 10;
    const params = {
        accessInd,
        codTariff,
        dtEn,
        dtEnd,
        dtPeriodEnd,
        dtPeriodStart,
        dtSt,
        dtStart,
        idService,
        idServiceProvider,
        kdTpMode,
    };
    const additionalServiceParams = {
        ...params,
        dtEn: dtEn?.substring(0, dateLength),
        dtSt: dtSt?.substring(0, dateLength),
    };
    const proxyParams = {
        plugin: providerType ? `${providerType}Proxy` : providersPlugin[kdProvider],
        proxyplugin,
        proxyquery: getProxyquery(name, kdProvider),
        vlProvider,
    };
    const baseService = {
        api: {
            data: {
                ...params,
                ...proxyParams,
            },
            list: true,
            query: providerType ? `${providerType}Proxy` : providersPlugin[kdProvider],
            session,
        },
        payload: {
            cancelToken,
            name,
            noCache,
            normalizeFunction,
        },
    };

    if (additionalServices) {
        const allServices = additionalServices.map((additionalServicesName) => ({
            api: {
                data: additionalServiceParams,
                list: true,
                query: additionalServicesName,
                session,
            },
        }));

        allServices.push(baseService);
        Promise.all(
            allServices.map(async (service) => {
                const res = await dispatch({
                    ...service,
                    api: {
                        ...service.api,
                        types: [
                            actionTypes.DEFAULT_ACTION,
                            actionTypes.DEFAULT_ACTION_SUCCESS,
                            actionTypes.DEFAULT_ACTION_FAILURE,
                        ],
                    },
                });

                if (!res.error && Array.isArray(res.response)) {
                    return Promise.resolve(res.response);
                }

                return Promise.reject(new Error());
            }),
        )
            .then((res) => {
                const resultData = res
                    .reduce((accumulator, currentValue = []) => accumulator.concat([...currentValue]), [])
                    .sort((firstVal, secondVal) => new Date(secondVal.dtPay) - new Date(firstVal.dtPay));

                return dispatch({
                    payload: {name, resultData},
                    type: actionTypes.LS_LOAD_DATA_MULTIPLY_SUCCESS,
                });
            })
            .catch(() =>
                dispatch({
                    type: actionTypes.LS_LOAD_DATA_FAILURE,
                }),
            );
    }

    return dispatch({
        ...baseService,
        api: {
            ...baseService.api,
            types: [actionTypes.LS_LOAD_DATA, actionTypes.LS_LOAD_DATA_SUCCESS, actionTypes.LS_LOAD_DATA_FAILURE],
        },
    });
};

export const loadAccountDataMapAction = (
    name: AccountLoadDataKeysType,
    {
        cancelToken,
        dtEn,
        dtSt,
        idCounter,
        idPu,
        mapId,
        vlProvider,
        providerType = "",
        dtStart,
        dtEnd,
        idService,
        idServiceProvider,
        dtPeriod,
        normalizeFunction,
        kdProvider,
    }: AccountParamsLoadDataType,
    session: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            dtEn,
            dtEnd,
            dtPeriod,
            dtSt,
            dtStart,
            idCounter,
            idPu,
            idService,
            idServiceProvider,
            plugin: providerType ? `${providerType}Proxy` : providersPlugin[kdProvider],
            proxyquery: getProxyquery(name, kdProvider),
            vlProvider,
        },
        list: true,
        query: providerType ? `${providerType}Proxy` : providersPlugin[kdProvider],
        session,
        types: [
            actionTypes.LS_LOAD_DATA_MAP,
            actionTypes.LS_LOAD_DATA_MAP_SUCCESS,
            actionTypes.LS_LOAD_DATA_MAP_FAILURE,
        ],
    },
    payload: {
        cancelToken,
        mapId,
        name,
        normalizeFunction,
    },
});
export const getAccountContactPhone = (session: string, kdProvider: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            kdProvider,
        },
        query: "GetContactPhone",
        session,
        types: [
            actionTypes.GET_ACCOUNT_CONTACT_PHONE_ACTION,
            actionTypes.GET_ACCOUNT_CONTACT_PHONE_ACTION_SUCCESS,
            actionTypes.GET_ACCOUNT_CONTACT_PHONE_ACTION_FAILURE,
        ],
    },
});
export const loadLsGroupsAction = (idService: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        list: true,
        query: "GetLSGroups",
        session,
        types: [actionTypes.GET_LS_GROUPS, actionTypes.GET_LS_GROUPS_SUCCESS, actionTypes.GET_LS_GROUPS_FAILURE],
    },
    payload: {
        idService,
    },
});
export const lsSetGroupAction = (idService: string, session: string, idLsGroup: string): ApiMiddlewareActionType => ({
    api: {
        data: {idLsGroup, idService},
        query: "LSSetGroup",
        session,
        types: [actionTypes.LS_SET_GROUP, actionTypes.LS_SET_GROUP_SUCCESS, actionTypes.LS_SET_GROUP_FAILURE],
    },
});
export const accountEditAction = (attributes: string, idService: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            attributes,
            idService,
        },
        query: "LSEdit",
        session,
        types: [actionTypes.LS_EDIT_ACTION, actionTypes.LS_EDIT_ACTION_SUCCESS, actionTypes.LS_EDIT_ACTION_FAILURE],
    },
});
export const accountClearAccountDetailAction = () => ({
    type: actionTypes.CLEAR_ACCOUNT_DETAIL_ACTION,
});
export const accountClearLocalAccountDetailAction = () => ({
    type: actionTypes.CLEAR_LOCAL_ACCOUNT_DETAIL_ACTION,
});
export const indicationAndPayAvailAction = (session: string, kdProvider: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            kdProvider,
        },
        query: "IndicationAndPayAvail",
        session,
        types: [
            actionTypes.INDICATION_AND_PAY_ACTION,
            actionTypes.INDICATION_AND_PAY_ACTION_SUCCESS,
            actionTypes.INDICATION_AND_PAY_ACTION_FAILURE,
        ],
    },
    payload: {
        kdProvider,
    },
});
export type PrivilegesAvailActionType = (session: string, vlProvider: string) => ApiMiddlewareResponsePromiseType;
export const privilegesAvailAction = (session: string, vlProvider: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            plugin: "bytProxy",
            proxyquery: "PrivilegesAvail",
            vlProvider,
        },
        query: "bytProxy",
        session,
        types: [
            actionTypes.PRIVILEGES_AVAIL_ACTION,
            actionTypes.PRIVILEGES_AVAIL_ACTION_SUCCESS,
            actionTypes.PRIVILEGES_AVAIL_ACTION_FAILURE,
        ],
    },
});
export type PriviegesDayCheckActionType = (session: string, vlProvider: string) => ApiMiddlewareResponsePromiseType;
export const priviegesDayCheckAction = (session: string, idService: number): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "PriviegesDayCheck",
        session,
        types: [
            actionTypes.PRIVILEGES_DAY_AVAIL_ACTION,
            actionTypes.PRIVILEGES_DAY_AVAIL_ACTION_SUCCESS,
            actionTypes.PRIVILEGES_DAY_AVAIL_ACTION_FAILURE,
        ],
    },
});
export type PivelegesCreateFrameActionType = (session: string, params: Object) => ApiMiddlewareResponsePromiseType;
export const pivelegesCreateFrameAction = (session: string, {idService, nmBackUrl, prMirror}: Object) => (
    dispatch: any,
) =>
    dispatch({
        api: {
            data: {
                idService,
                nmBackUrl,
                prMirror,
            },
            query: "PivelegesCreateFrame",
            session,
            types: [
                actionTypes.PIVELEGES_CREATE_FRAME_ACTION,
                actionTypes.PIVELEGES_CREATE_FRAME_ACTION_SUCCESS,
                actionTypes.PIVELEGES_CREATE_FRAME_ACTION_FAILURE,
            ],
        },
    }).then(openFrameLink);
export type PrivilegesRedirectActionType = (session: string, result: string) => ApiMiddlewareResponsePromiseType;
export const privilegesRedirectAction = (session: string, result: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            result,
        },
        query: "PrivilegesRedirect",
        session,
        types: [
            actionTypes.PRIVILEGES_REDIRECT_ACTION,
            actionTypes.PRIVILEGES_REDIRECT_ACTION_SUCCESS,
            actionTypes.PRIVILEGES_REDIRECT_ACTION_FAILURE,
        ],
    },
});
export type CrmGetPrivilegesHistoryActionType = (session?: string, params: Object) => ApiMiddlewareResponsePromiseType;
export const crmGetPrivilegesHistoryAction = (
    session: string,
    {cancelToken, idService, dtSt, dtEn}: Object,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            cancelToken,
            dtEn,
            dtSt,
            idService,
        },
        query: "CrmGetPrivilegesHistory",
        session,
        types: [
            actionTypes.CRM_GET_PRIVILEGES_HISTORY_ACTION,
            actionTypes.CRM_GET_PRIVILEGES_HISTORY_ACTION_SUCCESS,
            actionTypes.CRM_GET_PRIVILEGES_HISTORY_ACTION_FAILURE,
        ],
    },
    payload: {
        cancelToken,
    },
});
export type PivelegesEditFrameActionType = (session: string, params: Object) => ApiMiddlewareResponsePromiseType;
export const pivelegesEditFrameAction = (
    session: string,
    {idService, nmBackUrl, idCrmService, idCrmContact, prMirror}: Object,
) => (dispatch: any) =>
    dispatch({
        api: {
            data: {
                idCrmContact,
                idCrmService,
                idService,
                nmBackUrl,
                prMirror,
            },
            query: "PivelegesEditFrame",
            session,
            types: [
                actionTypes.PIVELEGES_EDIT_FRAME_ACTION,
                actionTypes.PIVELEGES_EDIT_FRAME_ACTION_SUCCESS,
                actionTypes.PIVELEGES_EDIT_FRAME_ACTION_FAILURE,
            ],
        },
    }).then(openFrameLink);

export type crmEditPrivilegesManyKindActionType = (session: string, params: Object) => ApiMiddlewareResponsePromiseType;
export const crmEditPrivilegesManyKindAction = (
    session: string,
    {idService, nmBackUrl, idCrmService, idCrmContact, prMirror}: Object,
) => (dispatch: any) =>
    dispatch({
        api: {
            data: {
                idCrmContact,
                idCrmService,
                idService,
                nmBackUrl,
                prMirror,
            },
            query: "CrmEditPrivilegesManyKind",
            session,
            types: [
                actionTypes.PIVELEGES_EDIT_FRAME_ACTION,
                actionTypes.PIVELEGES_EDIT_FRAME_ACTION_SUCCESS,
                actionTypes.PIVELEGES_EDIT_FRAME_ACTION_FAILURE,
            ],
        },
    }).then(openFrameLink);

export type GetPrivilegesActionType = (session: string, vlProvider: string) => ApiMiddlewareResponsePromiseType;
export const getPrivilegesAction = (session: string, vlProvider: string) => ({
    api: {
        data: {
            plugin: "bytProxy",
            proxyquery: "GetPrivileges",
            vlProvider,
        },
        list: true,
        query: "bytProxy",
        session,
        types: [
            actionTypes.GET_PRIVILEGES_ACTION,
            actionTypes.GET_PRIVILEGES_ACTION_SUCCESS,
            actionTypes.GET_PRIVILEGES_ACTION_FAILURE,
        ],
    },
});

export type GetPrivilegesInfoActionType = (
    session: string,
    vlProvider: string,
    idService: number,
) => ApiMiddlewareResponsePromiseType;

export const getPrivilegesInfoAction = (session: string, vlProvider: string, idService: number) => (dispatch: any) =>
    dispatch(privilegesAvailAction(session, vlProvider)).then((privilegesAvailRes) => {
        if (!privilegesAvailRes.error && privilegesAvailRes.response && privilegesAvailRes.response.prAvail === 1) {
            return dispatch(getPrivilegesAction(session, vlProvider)).then((privilegesRes) => {
                if (!privilegesRes.error && privilegesRes.response) {
                    return dispatch(priviegesDayCheckAction(session, idService));
                }

                return privilegesRes;
            });
        }

        return privilegesAvailRes;
    });

export type LsSaveDescriptionActionType = (
    session: string,
    idService: number,
    newDescription: string,
) => ApiMiddlewareActionType;

export const lsSaveDescriptionAction = (session: string, idService: number, newDescription: string) => (
    dispatch: any,
): ApiMiddlewareActionType =>
    dispatch({
        api: {
            data: {
                idService,
                nmLsDescription: newDescription,
            },
            list: true,
            query: "LSSaveDescription ",
            session,
            types: [
                actionTypes.LS_SAVE_DESCRIPTION_ACTION,
                actionTypes.LS_SAVE_DESCRIPTION_ACTION_SUCCESS,
                actionTypes.LS_SAVE_DESCRIPTION_ACTION_FAILURE,
            ],
        },
    }).then(() => dispatch(loadAccountsListAction(session)));

export type PrivelegesCreateFrameLargeFamilyActionType = (
    session: string,
    idService: number,
    prMirror: boolean,
) => ApiMiddlewareResponsePromiseType;
export const privelegesCreateFrameLargeFamilyAction = (session: string, idService: number, prMirror: boolean) => (
    dispatch: any,
) =>
    dispatch({
        api: {
            data: {
                idService,
                prMirror,
            },
            query: "CrmRegPrivilegesManyKind",
            session,
            types: [
                actionTypes.PRIVELEGES_CREATE_FRAME_LARGE_FAMILY_ACTION,
                actionTypes.PRIVELEGES_CREATE_FRAME_LARGE_FAMILY_ACTION_SUCCESS,
                actionTypes.PRIVELEGES_CREATE_FRAME_LARGE_FAMILY_ACTION_FAILURE,
            ],
        },
    }).then(openFrameLink);

export const checkScheduledInspectionAction = (idService: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
            session,
        },
        query: "CheckScheduledInspection",
        types: [
            actionTypes.CHECK_SCHELUDED_INSPECTION_ACTION,
            actionTypes.CHECK_SCHELUDED_INSPECTION_ACTION_SUCCESS,
            actionTypes.CHECK_SCHELUDED_INSPECTION_ACTION_FAILURE,
        ],
    },
});

export const checkNotifyKsgOverPayAction = (idService: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
            session,
        },
        query: "CheckNotifyKsgOverPay",
        types: [
            actionTypes.CHECK_NOTIFY_KSG_OVER_PAY_ACTION,
            actionTypes.CHECK_NOTIFY_KSG_OVER_PAY_ACTION_SUCCESS,
            actionTypes.CHECK_NOTIFY_KSG_OVER_PAY_ACTION_FAILURE,
        ],
    },
});

export const getScheduledInspectionAction = (idService: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
            session,
        },
        query: "GetScheduledInspection",
        types: [
            actionTypes.GET_SCHELUDEDINSPECTION_ACTION,
            actionTypes.GET_SCHELUDEDINSPECTION_ACTION_SUCCESS,
            actionTypes.GET_SCHELUDEDINSPECTION_ACTION_FAILURE,
        ],
    },
});

export const confirmScheduledInspectionAction = (
    idService: string,
    idSchedule: string,
    session: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            idSchedule,
            idService,
        },
        query: "ConfirmScheduledInspection",
        session,
        types: [
            actionTypes.CONFIRM_SCHELUDED_INSPECTION_ACTION,
            actionTypes.CONFIRM_SCHELUDED_INSPECTION_ACTION_SUCCESS,
            actionTypes.CONFIRM_SCHELUDED_INSPECTION_ACTION_FAILURE,
        ],
    },
});

export const saveNotifyKsgOverPayAction = (
    idService: string,
    idNotify: string,
    session: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            idNotify,
            idService,
        },
        query: "SaveNotifyKsgOverPay",
        session,
        types: [
            actionTypes.SAVE_NOTIFY_KSG_OVER_PAY_ACTION,
            actionTypes.SAVE_NOTIFY_KSG_OVER_PAY_ACTION_SUCCESS,
            actionTypes.SAVE_NOTIFY_KSG_OVER_PAY_ACTION_FAILURE,
        ],
    },
});

export const saveNotifyKsgOverPayNAction = (
    idService: string,
    idNotify: string,
    session: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            idNotify,
            idService,
        },
        query: "SaveNotifyKsgOverPayN",
        session,
        types: [
            actionTypes.SAVE_NOTIFY_KSG_OVER_PAY_N_ACTION,
            actionTypes.SAVE_NOTIFY_KSG_OVER_PAY_N_ACTION_SUCCESS,
            actionTypes.SAVE_NOTIFY_KSG_OVER_PAY_N_ACTION_FAILURE,
        ],
    },
});

export const getTabsAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        list: true,
        query: "GetTabs",
        session,
        types: [actionTypes.GET_TABS_ACTION, actionTypes.GET_TABS_ACTION_SUCCESS, actionTypes.GET_TABS_ACTION_FAILURE],
    },
});

export const chkLSReportDetailAvailAction = (session: string, idService: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "ChkLSReportDetailAvail",
        session,
        types: [
            actionTypes.CHK_LS_REPORT_DETAIL_AVAIL_ACTION,
            actionTypes.CHK_LS_REPORT_DETAIL_AVAIL_ACTION_SUCCESS,
            actionTypes.CHK_LS_REPORT_DETAIL_AVAIL_ACTION_FAILURE,
        ],
    },
});

export const crmGetReportFrameAction = (idService: number, nmBackUrl: string, session: string) => (dispatch: any) =>
    dispatch({
        api: {
            data: {
                idService,
                nmBackUrl,
            },
            query: "CrmGetReportFrame",
            session,
            types: [
                actionTypes.CRM_GET_REPORT_FRAME_ACTION,
                actionTypes.CRM_GET_REPORT_FRAME_ACTION_SUCCESS,
                actionTypes.CRM_GET_REPORT_FRAME_ACTION_FAILURE,
            ],
        },
    }).then(openFrameLink);

export const chkReportPaspAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        query: "ChkReportPasp",
        session,
        types: [
            actionTypes.CHK_REPORT_PASP_ACTION,
            actionTypes.CHK_REPORT_PASP_ACTION_SUCCESS,
            actionTypes.CHK_REPORT_PASP_ACTION_FAILURE,
        ],
    },
});

export const getLSReportHistAction = (data: Object, session: string): ApiMiddlewareActionType => ({
    api: {
        data,
        query: "GetLSReportHist",
        session,
        types: [
            actionTypes.GET_LS_REPORT_HIST_ACTION,
            actionTypes.GET_LS_REPORT_HIST_ACTION_SUCCESS,
            actionTypes.GET_LS_REPORT_HIST_ACTION_FAILURE,
        ],
    },
});

export const crmGetReportFrameEditAction = (data: Object, session: string) => (dispatch: any) =>
    dispatch({
        api: {
            data,
            query: "CrmGetReportFrameEdit",
            session,
            types: [
                actionTypes.CRM_GET_REPORT_FRAME_EDIT_ACTION,
                actionTypes.CRM_GET_REPORT_FRAME_EDIT_ACTION_SUCCESS,
                actionTypes.CRM_GET_REPORT_FRAME_EDIT_ACTION_FAILURE,
            ],
        },
    }).then(openFrameLink);

export const getLkProviderFunction = (kdProvider: number, session: string) => ({
    api: {
        data: {
            kdProvider,
        },
        list: true,
        query: "GetLkProviderFunction",
        session,
        types: [
            actionTypes.GET_LK_PROVIDER_FUNCTION_ACTION,
            actionTypes.GET_LK_PROVIDER_FUNCTION_ACTION_SUCCESS,
            actionTypes.GET_LK_PROVIDER_FUNCTION_ACTION_FAILURE,
        ],
    },
});
