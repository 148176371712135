/* eslint-disable max-lines */
// @flow
import * as React from "react";
import {bindActionCreators} from "redux";
import {withRouter, type Location} from "react-router-dom";
import {connect} from "react-redux";
import qs from "qs";
import type {BrowserHistory} from "history/createBrowserHistory";
import {withStyles} from "@material-ui/core/styles";
import {
    loadAccountsListAction,
    privilegesRedirectAction,
    type LoadAccountsListActionType,
    type PrivilegesRedirectActionType,
} from "../redux/actions/accountAction";
import {getProviderListAction, type GetProviderListActionType} from "../redux/actions/servicesAction";
import {
    authLoginAction,
    authLogout,
    closeConfirmEmailOrSMSModalAction,
    checkNeedPhoneConfirmAction,
    type AuthLoginActionType,
    type OpenConfirmEmailOrSMSModalActionParamsType,
    type CheckNeedPhoneConfirmActionType,
    saveRedirectUrlAction,
} from "../redux/actions/authAction";
import {type StoreType} from "../redux/reducers";
import SideBar from "../components/sidebar/SideBar";
import TopMenu from "../components/topmenu/TopMenu";
import {type ApiMiddlewareResponsePromiseType} from "../redux/middlewares/apiMiddleware";
import {
    type BuilderGetGroupAttributesType,
    type BuilderGetElementsType,
    getBuilderElementsAction,
    getBuilderGroupAttributesAction,
} from "../redux/actions/builderAction";
import ProfilePhoneEmailConfirm from "../components/Profile/ProfilePhoneEmailConfirm";
import {
    getLSNoticeCriticalStatus,
    getLSNoticeCount,
    getLsListNoticeStatusAction,
} from "../redux/actions/notificationAction";
import NotificationModals from "../components/Notificatin/NotificationModals";
import {redirectAuth} from "../utils/authUtils";
import {
    sendSmsPhoneChangeRepeatAction,
    phoneChangeConfirmAction,
    type PhoneChangeConfirmActionType,
    type SendSmsPhoneChangeRepeatActionType,
} from "../redux/actions/phoneAction";
import {publickUrl} from "../constants/Application";
import {
    addrEditRedirectAction,
    getPaspDetailsFailedAction,
    type AddrEditRedirectActionType,
    type GetPaspDetailsFailedActionType,
} from "../redux/actions/profileAction";
import {multipleLSPayCloseBackUrlModalAction} from "../redux/actions/account/payAction";
import Loader from "../packages/components/Loader/Loader";
import PayAllBackUrlModal from "../components/PayAll/BackUrlModal";
import {type BackUrlResponseType} from "../constants/types/ReducerTypes";
import {backUrlAction, type BackUrlActionType} from "../redux/actions/backUrlAction";

const styles = (theme) => ({
    content: {
        minHeight: "100%",
        overflow: "hidden",
        [theme.breakpoints.up("lg")]: {
            paddingLeft: theme.sizing.sideBarWidth,
        },
    },
});

type StoreProps = {|
    accountsList: Array<Object>,
    applicationLoaderText: string,
    remember: boolean,
    auth: $PropertyType<StoreType, "auth">,
    showApplicationLoader: boolean,
    noticeRoutine?: boolean,
    newToken?: string | null,
    session?: string,
    login?: string,
    phone?: string,
    tokenOneTimePass?: string,
    confirmEmailOrSMSModal?: OpenConfirmEmailOrSMSModalActionParamsType,
    payAllBackUrlResponse: BackUrlResponseType,
    payAllBackUrlModalOpen: boolean,
|};

type DispatchProps = {|
    authLoginAction: AuthLoginActionType,
    loadAccountsListAction: LoadAccountsListActionType,
    authLogout: () => void,
    getBuilderGroupAttributesAction: BuilderGetGroupAttributesType,
    getLSNoticeCriticalStatus: (idService: string, session: string) => ApiMiddlewareResponsePromiseType,
    getLSNoticeCount: (idService: string, session: string) => ApiMiddlewareResponsePromiseType,
    getLsListNoticeStatusAction: (session: string) => void,
    getBuilderElementsAction: BuilderGetElementsType,
    sendSmsPhoneChangeRepeatAction: SendSmsPhoneChangeRepeatActionType,
    phoneChangeConfirmAction: PhoneChangeConfirmActionType,
    closeConfirmEmailOrSMSModalAction: () => void,
    getProviderListAction: GetProviderListActionType,
    addrEditRedirectAction: AddrEditRedirectActionType,
    privilegesRedirectAction: PrivilegesRedirectActionType,
    multipleLSPayCloseBackUrlModalAction: () => void,
    checkPhoneAction: CheckNeedPhoneConfirmActionType,
    getPaspDetailsFailedAction: GetPaspDetailsFailedActionType,
    saveRedirectUrlAction: (session: string) => void,
    backUrlAction: BackUrlActionType,
|};

type OwnProps = {|
    history: BrowserHistory,
    children: React.Node,
    location: Location,
    classes?: any,
|};

type State = {
    loading: boolean,
};

export type Props = {...OwnProps, ...StoreProps, ...DispatchProps};

const ADVERTISEMENT_KD_SECTION = 7;

export class AuthorizationPage extends React.Component<Props, State> {
    state = {
        loading: true,
    };

    logOut = () => {
        const {pathname} = this.props.history.location;

        this.props.authLogout();
        redirectAuth(this.props.history);
        this.props.saveRedirectUrlAction(pathname);
    };

    logIn = async (pswToken: string, loginQuery: string, remember: boolean) => {
        const res = await this.props.authLoginAction({login: loginQuery, pswToken, remember});

        if (!res.error) {
            await this.loadAuthorizationData();
        }
    };

    componentDidMount = async () => {
        const {session = "", newToken, tokenOneTimePass, login, remember, phone} = this.props;

        if (session) {
            await this.loadAuthorizationData();

            return;
        }

        const pswToken = newToken || tokenOneTimePass;
        const loginQuery = login || phone;

        if (pswToken && loginQuery) {
            await this.logIn(pswToken, loginQuery, remember);
            this.handleCheckPhone();

            return;
        }
        this.logOut();
    };

    handleCheckPhone = async () => {
        const {response} = await this.props.checkPhoneAction(this.props.auth.session || "");

        if (response?.prNeedPhoneConfirm) {
            this.props.history.push(this.props.location.pathname, {toConfirmPhone: true});
        }
    };

    ifFrameRedirection = (session: string) => {
        const data = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        const {result} = data;
        const pathname = window.location.pathname.replace(publickUrl, "");

        if (result) {
            if (pathname === "/profile") {
                this.props.backUrlAction(session, "/profile", data);
                this.props.history.push(pathname);

                return;
            }

            if (/\/accounts\/\d+\/privileges/.test(pathname)) {
                this.props.backUrlAction(session, "/privileges", data);
                this.props.history.push(pathname);

                return;
            }

            if (/\/accounts\/\d+\/load-documents/.test(pathname)) {
                this.props.backUrlAction(session, "/load-documents", data);
                this.props.history.push(pathname);
            }
        }
    };

    componentDidUpdate({noticeRoutine, accountsList}: Props) {
        if (
            this.props.noticeRoutine &&
            (this.props.accountsList.length > accountsList.length || noticeRoutine !== this.props.noticeRoutine)
        ) {
            const {session = ""} = this.props;

            this.props.getLsListNoticeStatusAction(session);
        }
    }

    loadAuthorizationData = async () => {
        const {session = "", history} = this.props;

        await this.props.loadAccountsListAction(session, {
            isDisabledAuthorisationError: true,
        });
        if (
            !history?.location?.state ||
            (history?.location?.state && !history.location.state.preventGetPaspDetailsFailedAction)
        ) {
            await this.props.getPaspDetailsFailedAction(session, {
                isDisabledAuthorisationError: true,
            });
        }
        this.setState({loading: false});
        this.props.getBuilderGroupAttributesAction("profile", session, {
            isDisabledAuthorisationError: true,
        });
        this.props.getBuilderElementsAction("advertisement", session, ADVERTISEMENT_KD_SECTION, {
            isDisabledAuthorisationError: true,
        });

        this.ifFrameRedirection(session);
    };

    renderPhoneOrEmailConfirmModal = () => {
        const {confirmEmailOrSMSModal: {openModalConfirm, phoneEmailType, phone, email} = {}} = this.props.auth;

        return openModalConfirm ? (
            <ProfilePhoneEmailConfirm
                open
                phoneEmailType={phoneEmailType}
                onClosePhoneEmailChange={this.props.closeConfirmEmailOrSMSModalAction}
                phone={phone}
                email={email}
                auth={this.props.auth}
                getBuilderGroupAttributesAction={this.props.getBuilderGroupAttributesAction}
                phoneChangeConfirmAction={this.props.phoneChangeConfirmAction}
                sendSmsPhoneChangeRepeatAction={this.props.sendSmsPhoneChangeRepeatAction}
            />
        ) : null;
    };

    handleCLosePayAllBackUrlModal = () => {
        this.props.multipleLSPayCloseBackUrlModalAction();
    };

    renderPayAllBackUrlModal = () => {
        const {payAllBackUrlResponse, payAllBackUrlModalOpen} = this.props;

        return (
            <PayAllBackUrlModal
                open={payAllBackUrlModalOpen}
                title="Статус платежа"
                onClose={this.handleCLosePayAllBackUrlModal}
                data={payAllBackUrlResponse}
            />
        );
    };

    render() {
        const {
            classes = {},
            children,
            accountsList,
            history,
            showApplicationLoader,
            applicationLoaderText,
        } = this.props;
        const {loading} = this.state;

        if (loading) {
            return null;
        }

        return (
            <React.Fragment>
                <NotificationModals accountsList={accountsList} history={history} />
                <Loader
                    loaderType={applicationLoaderText ? "globalLoaderWithText" : "globalLoader"}
                    text={applicationLoaderText}
                    fetching={showApplicationLoader}
                />
                <SideBar />
                <div className={classes.content} id="authPage">
                    <TopMenu
                        pathname={this.props.history.location.pathname}
                        history={this.props.history}
                        location={this.props.location}
                    />
                    {children}
                </div>
                {this.renderPhoneOrEmailConfirmModal()}
                {this.renderPayAllBackUrlModal()}
            </React.Fragment>
        );
    }
}

const mapStoreToProps = ({auth, account, application}: StoreType): StoreProps => ({
    accountsList: account.accountsList,
    applicationLoaderText: application.loader.text,
    auth,
    confirmEmailOrSMSModal: auth.confirmEmailOrSMSModal,
    login: auth.login,
    newToken: auth.newToken,
    noticeRoutine: auth.noticeRoutine,
    payAllBackUrlModalOpen: account.payAll.backUrlModalOpen,
    payAllBackUrlResponse: account.payAll.backUrlResponse,
    phone: auth.phone,
    remember: Boolean(auth.remember),
    session: auth.session,
    showApplicationLoader: application.loader.show,
    tokenOneTimePass: auth.tokenOneTimePass,
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    addrEditRedirectAction: bindActionCreators(addrEditRedirectAction, dispatch),
    authLoginAction: bindActionCreators(authLoginAction, dispatch),
    authLogout: bindActionCreators(authLogout, dispatch),
    backUrlAction: bindActionCreators(backUrlAction, dispatch),
    checkPhoneAction: bindActionCreators(checkNeedPhoneConfirmAction, dispatch),
    closeConfirmEmailOrSMSModalAction: bindActionCreators(closeConfirmEmailOrSMSModalAction, dispatch),
    getBuilderElementsAction: bindActionCreators(getBuilderElementsAction, dispatch),
    getBuilderGroupAttributesAction: bindActionCreators(getBuilderGroupAttributesAction, dispatch),
    getLSNoticeCount: bindActionCreators(getLSNoticeCount, dispatch),
    getLSNoticeCriticalStatus: bindActionCreators(getLSNoticeCriticalStatus, dispatch),
    getLsListNoticeStatusAction: bindActionCreators(getLsListNoticeStatusAction, dispatch),
    getPaspDetailsFailedAction: bindActionCreators(getPaspDetailsFailedAction, dispatch),
    getProviderListAction: bindActionCreators(getProviderListAction, dispatch),
    loadAccountsListAction: bindActionCreators(loadAccountsListAction, dispatch),
    multipleLSPayCloseBackUrlModalAction: bindActionCreators(multipleLSPayCloseBackUrlModalAction, dispatch),
    phoneChangeConfirmAction: bindActionCreators(phoneChangeConfirmAction, dispatch),
    privilegesRedirectAction: bindActionCreators(privilegesRedirectAction, dispatch),
    saveRedirectUrlAction: bindActionCreators(saveRedirectUrlAction, dispatch),
    sendSmsPhoneChangeRepeatAction: bindActionCreators(sendSmsPhoneChangeRepeatAction, dispatch),
});

export default connect<Props, OwnProps, StoreProps, DispatchProps, StoreType, _>(
    mapStoreToProps,
    mapDispatchToProps,
)(withRouter<Props>(withStyles(styles)(AuthorizationPage)));
