// @flow
import {createReducer} from "../../../../redux/reducers/createReducer";
import * as actionTypes from "../../constants/ActionTypes";
import {makeFetchingReducer} from "../../utils/redux/fetchingUtils";

export type FetchingReducersType = {
    [key: string]: boolean,
};

const initialState = {};

export const fetchingReducers = createReducer(initialState, {
    ...makeFetchingReducer([
        actionTypes.EXAMPLE_DATA_ACTION,
        actionTypes.EXAMPLE_DATA_ACTION_SUCCESS,
        actionTypes.EXAMPLE_DATA_ACTION_FAILURE,
    ]),
    ...makeFetchingReducer([
        actionTypes.EXAMPLE_BUILDER_ACTION,
        actionTypes.EXAMPLE_BUILDER_ACTION_SUCCESS,
        actionTypes.EXAMPLE_BUILDER_ACTION_FAILURE,
    ]),
});
