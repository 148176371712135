import {
    MES_KD_PROVIDER,
    UFA_KD_PROVIDER,
    MOE_KD_PROVIDER,
    TKO_KD_PROVIDER,
    TMK_NRG_KD_PROVIDER,
    TMK_RTS_KD_PROVIDER,
    VLG_KD_PROVIDER,
    ORL_KD_PROVIDER,
    ORL_EPD_KD_PROVIDER,
    ALT_KD_PROVIDER,
    SAR_KD_PROVIDER,
    TMB_KD_PROVIDER,
    VLD_KD_PROVIDER,
    KSG_KD_PROVIDER,
} from "./KdProvider";

export const kdProviders = {
    1: "mes",
    2: "moe",
};

export const connectedProviders = {
    1: "REACT_APP_PROVIDER_MES",
    2: "REACT_APP_PROVIDER_MOE",
    3: "REACT_APP_PROVIDER_TMKNRG",
    4: "REACT_APP_PROVIDER_TMKRTS",
    5: "REACT_APP_PROVIDER_UFA",
};

export const turnedOnProviders = {
    [ALT_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_ALT === "on",
    [KSG_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_KSG === "on",
    [MES_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_MES === "on",
    [MOE_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_MOE === "on",
    [ORL_EPD_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_ORLEPD === "on",
    [ORL_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_ORLNRG === "on",
    [SAR_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_SAR === "on",
    [TKO_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_TKO === "on",
    [TMB_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_TMB === "on",
    [TMK_NRG_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_TMKNRG === "on",
    [TMK_RTS_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_TMKRTS === "on",
    [UFA_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_UFA === "on",
    [VLD_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_VLD === "on",
    [VLG_KD_PROVIDER]: process.env.REACT_APP_PROVIDER_VLG === "on",
};
