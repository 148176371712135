// @flow
import * as React from "react";
import CloseIcon from "@material-ui/icons/Close";
import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden/Hidden";
import {withStyles} from "@material-ui/core/styles";
import Content from "../../packages/components/Content/Content";
import Typography from "../../packages/components/Typography/Typography";
import SideBarAddAccount from "./SideBarAddAccount";
import {styles} from "./SideBarStyle";

type Props = {
    classes?: Object,
    handelCloseSideBar: () => void,
};

export const SideBarHead = ({classes = {}, handelCloseSideBar}: Props) => (
    <div className={classes.sideBarHead}>
        <Content vertical="sm" horizontal="xs">
            <Grid container spacing={8} alignItems="center">
                <Hidden lgUp>
                    <Grid item>
                        <ButtonBase onClick={handelCloseSideBar}>
                            <CloseIcon />
                        </ButtonBase>
                    </Grid>
                </Hidden>
                <Grid item>
                    <Typography fontSize={24} lineHeight={1} fontWeight={400}>
                        Ваши счета:
                    </Typography>
                </Grid>
            </Grid>
        </Content>
        <SideBarAddAccount handelCloseSideBar={handelCloseSideBar} />
    </div>
);

export default withStyles(styles)(SideBarHead);
