export const accountAltTabsSettings = [
    {
        kdSection: 8,
        label: "Уведомления",
        notification: true,
        value: "notifications",
    },
    {
        kdSection: 150,
        label: "История",
        value: "events",
    },
    {
        kdSection: 16,
        label: "Реквизиты ЛС",
        value: "requisites",
    },
    {
        kdSection: 17,
        label: "Статистика",
        value: "statistics",
    },
    {
        kdSection: 18,
        label: "Обращения",
        value: "application",
    },
];
