// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {type IconPropsType} from "./iconType";

const DEFAULT_SIZE = 40;

export const ElectricityIcon = ({fontSize, ...otherProps}: IconPropsType) => (
    <SvgIcon {...otherProps} style={{fontSize}} width="40" height="40">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d={
                "M20.7298 0.415283C20.0107 -0.138428 19.0089 -0.138428 18.2898 " +
                "0.415283L0.783923 13.8914C-0.729047 15.0559 0.0945729 17.4761 2.00391 " +
                "17.4761H4.5098V37.4761C4.5098 38.5806 5.40523 39.4761 6.5098 " +
                "39.4761H32.5098C33.6144 39.4761 34.5098 38.5806 34.5098 " +
                "37.4761V17.4761H37.0157C38.925 17.4761 39.7487 15.0559 38.2357 13.8914L20.7298 0.415283Z"
            }
            transform="translate(0.490181 0.523926)"
            fill="#E3E5E6"
        />
        <path d="M0 13L9 0L7 10H12L1.5 23L5 13H0Z" transform="translate(14 12)" fill="#C0C9D0" />
    </SvgIcon>
);

ElectricityIcon.defaultProps = {
    fontSize: DEFAULT_SIZE,
    viewBox: "0 0 40 40",
};

export default ElectricityIcon;
