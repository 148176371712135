// @flow

import React from "react";
import Dialog from "../../../packages/components/Dialog/Dialog";
import Wizard from "../../../packages/components/Wizard/Wizard";
import DialogCloseIcon from "../../../packages/components/Dialog/DialogCloseIcon";
import {type StoreType} from "../../../redux/reducers";
import {
    type PhoneChangeConfirmActionType,
    type SendSmsPhoneChangeRepeatActionType,
} from "../../../redux/actions/phoneAction";
import {type BuilderGetGroupAttributesType} from "../../../redux/actions/builderAction";
import {
    type ProfileEditActionType,
    type EmailSubscrChgFlagsType,
    type GetAdSubscrStatusActionType,
} from "../../../redux/actions/profileAction";
import ProfilePhoneAuthConfirmMain from "./ProfilePhoneAuthConfirmMain";

type Props = {
    auth?: $PropertyType<StoreType, "auth">,
    open: boolean,
    phoneEmailType: string,
    attribute: Object,
    onClosePhoneEmailChange: Function,
    phoneChangeConfirmAction: PhoneChangeConfirmActionType,
    sendSmsPhoneChangeRepeatAction?: SendSmsPhoneChangeRepeatActionType,
    profileEditAction: ProfileEditActionType,
    profileBuilder?: $PropertyType<$PropertyType<StoreType, "builder">, "profile">,
    getAdSubscrStatusAction?: GetAdSubscrStatusActionType,
    onChangePhone: Function,
};

const PAPER_PROPS = {
    component: "form",
};

const ProfilePhoneAuthConfirm = (props: Props) => {
    const {
        open,
        phoneEmailType,
        attribute,
        onClosePhoneEmailChange,
        profileEditAction,
        auth,
        profileBuilder,
        onChangePhone,
    } = props;

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            maxWidth="sm"
            open={open}
            onClose={onClosePhoneEmailChange}
            PaperProps={PAPER_PROPS}
            testID="ProfilePhoneEmailChange"
        >
            <DialogCloseIcon onClick={onClosePhoneEmailChange} />
            <Wizard preventDefault onLastStep={onClosePhoneEmailChange}>
                <Wizard.Page
                    render={(buttonProps) => (
                        <ProfilePhoneAuthConfirmMain
                            buttonProps={buttonProps}
                            onNext={buttonProps.onNext}
                            phoneEmailType={phoneEmailType}
                            onChangePhone={onChangePhone}
                            onClose={onClosePhoneEmailChange}
                            profileEditAction={profileEditAction}
                            auth={auth}
                            attribute={attribute}
                            profileBuilder={profileBuilder}
                        />
                    )}
                />
            </Wizard>
        </Dialog>
    );
};

export default ProfilePhoneAuthConfirm;
