// @flow
import * as React from "react";
import {Grid} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";

const styles = {
    clickableItem: {
        cursor: "pointer",
    },
    innerImage: {
        width: "100%",
    },
    root: {
        backgroundSize: "cover",
        display: "block",
        left: "50%",
        minHeight: "100%",
        minWidth: "100%",
        position: "absolute",
        top: "50%;",
        transform: "translate(-50%, -50%)",
        zIndex: -1,
    },
};

type Props = {
    content: Array<{
        imgsrc: string,
        nmLink: string,
        nnOrder: number,
        vlContent: string,
        vlContentB: string,
    }>,
    classes?: Object,
    innerImage?: boolean,
    nmFileExtensions?: string,
    isMirrorDisabled?: boolean,
    direction?: "row" | "column",
};
export const ImageListElement = ({content = [], direction = "row"}: Props) => {
    if (content.length) {
        return (
            <Grid container spacing={16} direction={direction} alignItems="center">
                {content.map(({imgsrc, nnOrder}) => (
                    <Grid item key={nnOrder}>
                        <img src={imgsrc} alt="" />
                    </Grid>
                ))}
            </Grid>
        );
    }

    return null;
};

export default withStyles(styles)(ImageListElement);
