// @flow
import * as React from "react";
import qs from "qs";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {type BrowserHistory} from "history/createBrowserHistory";
import {regAutoPaymentAction, type RegAutoPaymentActionType} from "../../redux/actions/account/mesAutoPayAction";
import type {StoreType} from "../../redux/reducers";

type OwnProps = {|
    location: Location,
    history: BrowserHistory,
|};

type StoreProps = {|
    session: string,
|};

type DispatchProps = {|
    regAutoPaymentAction: RegAutoPaymentActionType,
|};

export type Props = {...OwnProps, ...StoreProps, ...DispatchProps};

export class Autopayment extends React.Component<Props> {
    componentDidMount = async () => {
        const query: Object = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        });
        const params = {
            bindStatus: query.BIND_STATUS,
            exp: query.EXP,
            idAutopaymentParam: query.id_autopayment_param,
            idClient: query.CLIENT_ID,
        };

        await this.props.regAutoPaymentAction(params, this.props.session);

        this.props.history.push("/accounts/0");
    };

    render() {
        return null;
    }
}

const mapStateToProps = ({auth}: StoreType): StoreProps => ({
    session: auth.session || "",
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    regAutoPaymentAction: bindActionCreators(regAutoPaymentAction, dispatch),
});

export default connect<Props, OwnProps, StoreProps, DispatchProps, StoreType, _>(
    mapStateToProps,
    mapDispatchToProps,
)(Autopayment);
