// @flow
import React from "react";
import banerIcon from "../../../images/providers/tmb/logo/TmbLogo.png";
import {SMALL_ICON_SIZE} from "../../../constants/iconConstants";

type Props = {
    fontSize?: number,
};

const TmbLogoIcon = ({fontSize}: Props) => <img height={fontSize} width={fontSize} src={banerIcon} alt="" />;

TmbLogoIcon.defaultProps = {
    fontSize: SMALL_ICON_SIZE,
};

export default TmbLogoIcon;
