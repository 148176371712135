// @flow
import * as actionTypes from "../../constants/types/ActionTypes";
import * as mesActionTypes from "../../constants/types/MesAutoPayActionTypes";
import {RESPONSE_RESULT_TEXT_CODE} from "../../constants/KdResults";
import {getAnnulReasonsConfig} from "../../utils/autopayMesUtils";
import {createReducer} from "./createReducer";

export type TransferIndicationsType = {
    fetchingTransferIndications: boolean,
    data: Object,
    modalState: Object,
    callSaveIndications: boolean,
    nnLs: string,
    prCorrReqAvail?: number,
};

export type AutopayType = {
    annulReasonsConfig: Object,
    autopayDefParams: Object,
    autopayStatus: Object,
    requestInfo: Object,
};

export type MesReducerType = {
    autopay: AutopayType,
    transferIndications: TransferIndicationsType,
    fetchingBillLink: boolean,
    fetchingMultibonusBalance: boolean,
    paymentSum: string,
};

const initialData = {
    formData: {},
    prFlatMeter: "0",
};
const initialModalState = {
    modalButtonCancelTitle: "",
    modalErrorOpen: false,
    modalMassage: "",
    modalOpen: false,
    modalTitle: "",
    modalType: "",
};

const initialState: MesReducerType = {
    autopay: {
        annulReasonsConfig: {},
        autopayDefParams: {},
        autopayStatus: {},
        requestInfo: {},
    },
    fetchingBillLink: false,
    fetchingMultibonusBalance: false,
    paymentSum: "",
    transferIndications: {
        callSaveIndications: false,
        data: initialData,
        fetchingTransferIndications: false,
        modalState: initialModalState,
        nnLs: "",
        prCorrect: "",
    },
};

export const mesReducer = createReducer(initialState, {
    [actionTypes.GET_PRINT_BILL_LINK_ACTION]: (state) => ({
        ...state,
        fetchingBillLink: true,
    }),
    [actionTypes.GET_PRINT_BILL_LINK_ACTION_SUCCESS]: (state) => ({
        ...state,
        fetchingBillLink: false,
    }),
    [actionTypes.GET_PRINT_BILL_LINK_ACTION_FAILURE]: (state) => ({
        ...state,
        fetchingBillLink: false,
    }),

    [actionTypes.GET_ACCOUNT_CONTACT_PHONE_ACTION]: (state) => ({
        ...state,
        transferIndications: {
            ...state.transferIndications,
            fetchingTransferIndications: true,
        },
    }),
    [actionTypes.GET_ACCOUNT_CONTACT_PHONE_ACTION_SUCCESS]: (state) => ({
        ...state,
        transferIndications: {
            ...state.transferIndications,
            fetchingTransferIndications: false,
        },
    }),
    [actionTypes.GET_ACCOUNT_CONTACT_PHONE_ACTION_FAILURE]: (state) => ({
        ...state,
        transferIndications: {
            ...state.transferIndications,
            fetchingTransferIndications: false,
        },
    }),
    [actionTypes.LS_TRANSFER_INDICATION_ACTION]: (state) => ({
        ...state,
        transferIndications: {
            ...state.transferIndications,
            fetchingTransferIndications: true,
        },
    }),
    [actionTypes.LS_TRANSFER_INDICATION_ACTION_FAILURE]: (state) => ({
        ...state,
        transferIndications: {
            ...state.transferIndications,
            fetchingTransferIndications: false,
        },
    }),
    [actionTypes.LS_TRANSFER_INDICATION_ACTION_SUCCESS]: (state: MesReducerType, action): MesReducerType => {
        const {kdResult, nmResult, prCorrect, prCorrReqAvail} = action.response;
        const {name, params, prFlatMeter, nnLs, hideResultModal} = action.payload;

        if (name === "CalcCharge") {
            if (kdResult === RESPONSE_RESULT_TEXT_CODE) {
                return {
                    ...state,
                    transferIndications: {
                        callSaveIndications: false,
                        data: {
                            formData: params,
                            prFlatMeter,
                        },
                        fetchingTransferIndications: false,
                        modalState: {
                            modalButtonCancelTitle: "Отменить",
                            modalErrorOpen: false,
                            modalMassage: nmResult,
                            modalOpen: !hideResultModal,
                            modalTitle: "Подтверждение",
                            modalType: "confirmCalcCharge",
                        },
                        nnLs,
                        prCorrReqAvail,
                        prCorrect,
                    },
                };
            }

            return {
                ...state,
                transferIndications: {
                    ...state.transferIndications,
                    callSaveIndications: true,
                    data: {
                        formData: params,
                        prFlatMeter,
                    },
                    fetchingTransferIndications: false,
                    modalState: initialModalState,
                    nnLs,
                },
            };
        }

        return {
            ...state,
            transferIndications: {
                ...state.transferIndications,
                callSaveIndications: false,
                data: initialData,
                fetchingTransferIndications: false,
                modalState: {
                    modalButtonCancelTitle: "Закрыть",
                    modalErrorOpen: false,
                    modalMassage: nmResult,
                    modalOpen: !hideResultModal,
                    modalTitle: "Уведомление",
                    modalType: "successCalcCharge",
                },
                nnLs,
            },
        };
    },
    [actionTypes.LS_TRANSFER_INDICATION_ACTION_FAILURE]: (state: MesReducerType, action): MesReducerType => {
        const {kdResult, nmResult, prCorrect, prCorrReqAvail} = action.response;
        const {name, params, prFlatMeter, nnLs, hideResultModal} = action.payload;

        if (name === "CalcCharge") {
            if (kdResult !== 0) {
                return {
                    ...state,
                    transferIndications: {
                        callSaveIndications: false,
                        data: {
                            formData: params,
                            prFlatMeter,
                        },
                        fetchingTransferIndications: false,
                        modalState: {
                            modalButtonCancelTitle: "Отменить",
                            modalErrorOpen: !hideResultModal,
                            modalMassage: nmResult,
                            modalOpen: false,
                            modalTitle: "Подтверждение",
                            modalType: "confirmCalcCharge",
                        },
                        nnLs,
                        prCorrReqAvail,
                        prCorrect,
                    },
                };
            }
        }

        return {...state};
    },
    [actionTypes.CLOSE_MODAL_ACTION]: (state: MesReducerType): MesReducerType => ({
        ...state,
        transferIndications: {
            ...state.transferIndications,
            modalState: {
                ...state.transferIndications.modalState,
                modalErrorOpen: false,
                modalOpen: false,
            },
        },
    }),
    [mesActionTypes.GET_AUTO_PAYMENT_STATUS_ACTION_SUCCESS]: (state: MesReducerType, action): MesReducerType => ({
        ...state,
        autopay: {
            ...state.autopay,
            autopayStatus: action.response,
        },
    }),
    [mesActionTypes.GET_AUTO_PAYMENT_DEFAULT_PARAMS_ACTION_SUCCESS]: (
        state: MesReducerType,
        action,
    ): MesReducerType => ({
        ...state,
        autopay: {
            ...state.autopay,
            autopayDefParams: action.response,
        },
    }),
    [mesActionTypes.GET_AUTO_PAYMENT_REQUEST_INFO_ACTION_SUCCESS]: (state: MesReducerType, action): MesReducerType => ({
        ...state,
        autopay: {
            ...state.autopay,
            requestInfo: action.response,
        },
    }),
    [mesActionTypes.GET_AUTO_PAYMENT_ANNUL_REASONS_ACTION_SUCCESS]: (state: MesReducerType, action): MesReducerType => {
        const config = getAnnulReasonsConfig(action.response);

        return {
            ...state,
            autopay: {
                ...state.autopay,
                annulReasonsConfig: config,
            },
        };
    },
    [actionTypes.SAVE_PAYMENT_SUM_TO_STORE_ACTION]: (state: MesReducerType, action) => {
        const {sum} = action.payload;

        return {
            ...state,
            paymentSum: sum,
        };
    },
    [actionTypes.MULTIBONUS_GET_BALANCE_ACTION]: (state: MesReducerType) => ({
        ...state,
        fetchingMultibonusBalance: true,
    }),
    [actionTypes.MULTIBONUS_GET_BALANCE_ACTION_SUCCESS]: (state: MesReducerType) => ({
        ...state,
        fetchingMultibonusBalance: false,
    }),
    [actionTypes.MULTIBONUS_GET_BALANCE_ACTION_FAILURE]: (state: MesReducerType) => ({
        ...state,
        fetchingMultibonusBalance: false,
    }),
});
