// @flow

export const ABONENT_SAVE_INDICATION_ACTION = "ABONENT_SAVE_INDICATION_ACTION";
export const ABONENT_SAVE_INDICATION_ACTION_SUCCESS = "ABONENT_SAVE_INDICATION_ACTION_SUCCESS";
export const ABONENT_SAVE_INDICATION_ACTION_FAILURE = "ABONENT_SAVE_INDICATION_ACTION_FAILURE";

export const OPEN_CONFIRM_TRANSFER_INDICATION_ACTION = "OPEN_CONFIRM_TRANSFER_INDICATION_ACTION";

export const CLOSE_CONFIRM_TRANSFER_INDICATION_ACTION = "CLOSE_CONFIRM_TRANSFER_INDICATION_ACTION";

export const ADD_REQUEST_LIST_ACTION = "ADD_REQUEST_LIST_ACTION";
export const REMOVE_ITEM_FROM_REQUEST_LIST_ACTION = "REMOVE_ITEM_FROM_REQUEST_LIST_ACTION";

export const LS_LOAD_INDICATOR_IS_FLOAT_DATA_ACTION = "LS_LOAD_INDICATOR_IS_FLOAT_DATA_ACTION";
export const LS_LOAD_INDICATOR_IS_FLOAT_DATA_ACTION_SUCCESS = "LS_LOAD_INDICATOR_IS_FLOAT_DATA_ACTION_SUCCESS";
export const LS_LOAD_INDICATOR_IS_FLOAT_DATA_ACTION_FAILURE = "LS_LOAD_INDICATOR_IS_FLOAT_DATA_ACTION_FAILURE";
