// @flow
import {type themeTypes} from "../../../../themeStyle";

export const styles = (theme: themeTypes) => ({
    blueText: {
        color: theme.palette.primary.light,
    },
    checkIcon: {
        fill: theme.palette.secondary.main,
    },
    clearIcon: {
        fill: theme.palette.grey.coolGray8,
    },
    container: {
        alignItems: "flex-start",
        borderBottom: "1px solid transparent",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "5px 16px",
        marginRight: 20,
        minHeight: 30,
    },
    editIcon: {
        fill: theme.palette.grey.coolGray8,
        fontSize: "20px",
    },
    greyText: {
        color: theme.palette.grey.coolGray8,
        fontStyle: "italic",
    },
    icons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: 50,
    },
    innerInput: {},
    root: {
        "& $innerInput": {
            alignItems: "flex-start !important",
            overflow: "hidden",
            padding: 0,
        },
        margin: 0,
        minHeight: 30,
        width: "80%",
    },
    textWrap: {
        background: "transparent",
        border: "none",
        borderRadius: 0,
        boxShadow: "none",
        height: 30,
        padding: 0,
    },
    textarea: {
        fontSize: "12px",
        minHeight: 30,
    },
    underline: {
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
});
