// @flow
import * as React from "react";
import MaterialDialog from "@material-ui/core/Dialog";

export const Dialog = ({children, testID, ...otherProps}: any) => (
    <MaterialDialog {...otherProps} testid={`formContainer${testID ? `-${testID}` : ""}`}>
        {children}
    </MaterialDialog>
);

export default Dialog;
