/* eslint-disable sort-keys */
// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import Dialog from "../Dialog/Dialog";
import DialogContent from "../Dialog/DialogContent";
import DialogTitle from "../Dialog/DialogTitle";
import DialogCloseIcon from "../Dialog/DialogCloseIcon";
import Typography from "../Typography/Typography";

const styles = () => ({
    paperWidthSm: {
        maxWidth: 900,
    },
});

type Props = {
    title: string,
    onCancel: Function,
    open: boolean,
    children?: React.Node,
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    testID?: string,
};

export const ModalNotification = (props: Props) => {
    const {title, onCancel, open, children, classes = {}, testID} = props;

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={onCancel} classes={{...classes}} testID={testID}>
            <DialogCloseIcon onClick={onCancel} />
            <DialogTitle disableTypography>
                <Typography textColor="primary" fontSize={22} lineHeight={1}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

export default withStyles(styles)(ModalNotification);
