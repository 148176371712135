// @flow
import * as actionTypes from "../../constants/ActionTypes";
import {createReducer} from "../../../../redux/reducers/createReducer";
import {baseDataActionSuccess, type BaseDataReducerType} from "./baseReducers";

const initialState: BaseDataReducerType = {
    modalTableMetadata: [],
    panelHeadMetadata: [],
    panelRowsMetadata: [],
    panelTabsMetadata: [],
};

export const metadataReducer = createReducer(initialState, {
    [actionTypes.EXAMPLE_METADATA_ACTION_SUCCESS]: baseDataActionSuccess,
});
