// @flow

import * as React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {loaderDelay} from "../../../constants/Application";
import {type themeTypes} from "../../../themeStyle";
import Typography from "../Typography/Typography";

const fullWidth = 12;

const styles = (theme: themeTypes) => ({
    balanceLoaderContent: {
        alignItems: "flex-end",
        display: "flex",
        minHeight: 52,
        position: "relative",
    },
    balanceProgress: {
        color: theme.palette.text.white,
    },
    bannerProgress: {
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 99,
    },
    content: {
        position: "relative",
    },
    globalLoaderContent: {
        background: theme.palette.background.dark,
        bottom: 0,
        left: 0,
        position: "fixed",
        right: 0,
        top: 0,
        zIndex: theme.zIndex.maxZIndex,
    },
    globalLoaderWithTextCard: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: 16,
        maxWidth: 600,
        minHeight: 260,
        width: "100%",
    },
    globalLoaderWithTextProgress: {
        display: "flex",
        justifyContent: "center",
        left: "50%",
        position: "relative",
        top: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 99,
    },
    globalLoaderWithTextTypography: {
        marginTop: 30,
    },
    innerContent: {
        maxWidth: "100%",
        position: "relative",
    },
    minHeightContent: {
        minHeight: 60,
    },
    progress: {
        left: "50%",
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 99,
    },
});

export type LoaderType =
    | "innerLoader"
    | "periodLoader"
    | "default"
    | "balanceLoader"
    | "innerLoaderOrData"
    | "periodStatisticLoader"
    | "globalLoader"
    | "globalLoaderWithText";

type Props = {
    className?: string,
    delay?: number,
    fetching: boolean,
    children?: React.Node,
    loaderType: LoaderType,
    classes?: {
        [$Keys<$Call<typeof styles, any>>]: string,
    },
    size?: number,
    // Для loaderType = globalLoaderWithText
    text?: string,
};

type State = {
    showLoader: boolean,
};

export class Loader extends React.Component<Props, State> {
    timeoutId: TimeoutID;

    static defaultProps = {
        classes: {},
        delay: typeof loaderDelay === "number" ? loaderDelay : 0,
        loaderType: "default",
    };

    state = {
        showLoader: false,
    };

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({
                showLoader: true,
            });
        }, this.props.delay);
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId);
    }

    renderPeriodLoader = () => {
        const {classes = {}, fetching, loaderType} = this.props;
        const {showLoader} = this.state;

        return (
            <Grid
                item
                xs={loaderType === "periodStatisticLoader" ? 2 : fullWidth}
                sm={2}
                md={2}
                lg={1}
                className={classes.content}
            >
                <div className={classes.progress}>{fetching && showLoader ? <CircularProgress /> : null}</div>
            </Grid>
        );
    };

    renderInnerLoader = () => {
        const {classes = {}, loaderType, children, fetching, className = ""} = this.props;
        const {showLoader} = this.state;

        return (
            <div
                className={`${classes.innerContent} ${className} ${
                    fetching && showLoader ? classes.minHeightContent : ""
                }`}
            >
                <div className={classes.progress}>{fetching && showLoader ? <CircularProgress /> : null}</div>
                {loaderType === "innerLoaderOrData" && fetching ? null : children}
            </div>
        );
    };

    renderBannerLoader = () => {
        const {classes = {}, children, fetching} = this.props;
        const {showLoader} = this.state;

        return (
            <div className={classes.balanceLoaderContent}>
                {fetching && showLoader ? <CircularProgress className={classes.balanceProgress} /> : null}
                {fetching ? null : children}
            </div>
        );
    };

    renderDefaultLoader = () => {
        const {className, children, fetching, size} = this.props;
        const {showLoader} = this.state;

        return (
            <>
                {fetching && showLoader ? <CircularProgress className={className} size={size} /> : null}
                {fetching ? null : children}
            </>
        );
    };

    renderGlobalLoader = () => {
        const {classes = {}, fetching} = this.props;
        const {showLoader} = this.state;

        return fetching && showLoader ? (
            <div className={classes.globalLoaderContent}>
                <div className={classes.progress}>
                    <CircularProgress className={classes.balanceProgress} size={64} />
                </div>
            </div>
        ) : null;
    };

    renderGlobalLoaderWithText = () => {
        const {classes = {}, fetching, text} = this.props;
        const {showLoader} = this.state;

        return fetching && showLoader ? (
            <div className={classes.globalLoaderContent}>
                <div className={classes.globalLoaderWithTextProgress}>
                    <Paper className={classes.globalLoaderWithTextCard}>
                        <CircularProgress size={54} />
                        <Typography fontSize={18} className={classes.globalLoaderWithTextTypography}>
                            {text}
                        </Typography>
                    </Paper>
                </div>
            </div>
        ) : null;
    };

    renderLoader = () => {
        const {loaderType} = this.props;

        switch (loaderType) {
            case "periodLoader":
                return this.renderPeriodLoader();
            case "periodStatisticLoader":
                return this.renderPeriodLoader();
            case "innerLoader":
                return this.renderInnerLoader();
            case "innerLoaderOrData":
                return this.renderInnerLoader();
            case "balanceLoader":
                return this.renderBannerLoader();
            case "globalLoader":
                return this.renderGlobalLoader();
            case "globalLoaderWithText":
                return this.renderGlobalLoaderWithText();
            default:
                return this.renderDefaultLoader();
        }
    };

    render() {
        return this.renderLoader();
    }
}

export default withStyles(styles)(Loader);
