// @flow
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import type {BrowserHistory} from "history/createBrowserHistory";
import Typography from "../../../packages/components/Typography/Typography";
import Button from "../../../packages/components/Button/Button";
import Content from "../../../packages/components/Content/Content";
import UserAccountIcon from "../../../packages/components/Icons/UserAccountIcon";
import {styles} from "./styles";

type Props = {
    history: BrowserHistory,
    backgroundUrl: string,
    nmFirst: string,
    nmLast: string,
    classes?: Object,
};

const WelcomePageSimple = (props: Props) => {
    const {backgroundUrl, classes = {}, history, nmFirst, nmLast} = props;

    const handleAddLs = React.useCallback(() => {
        history.push("/account-add");
    }, [history]);

    return (
        <div className={classes.mainContent}>
            <img src={backgroundUrl} alt="" className={classes.backgroundImg} />
            <Grid container justify="center" alignItems="center" spacing={0}>
                <Grid item xs={12}>
                    <Content horizontal={30} position="relative" vertical="xs">
                        <Typography align="center" component="div">
                            <UserAccountIcon iconColor="#fff" backgroundColor="#f49035" fontSize={108} />
                            <Typography fontSize={36}>Добро пожаловать,</Typography>
                            <Typography
                                fontSize={36}
                                textOverflow="ellipsis"
                                wordWrap="normal"
                            >{`${nmFirst} ${nmLast}!`}</Typography>
                            <Grid container justify="center" alignItems="center" spacing={8}>
                                <Grid item>
                                    <Typography fontSize={18} component="div" verticalPadding={8}>
                                        Начните работу с сервисом{" "}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        fontSize={18}
                                        textColor="primary-light"
                                        link
                                        capitalize
                                        justify="left"
                                        underline="solid"
                                        onClick={handleAddLs}
                                    >
                                        с добавления Вашего лицевого счета
                                    </Button>
                                </Grid>
                            </Grid>
                        </Typography>
                    </Content>
                </Grid>
            </Grid>
        </div>
    );
};

export default withStyles(styles)(WelcomePageSimple);
