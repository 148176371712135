// @flow
import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import MoreVer from "@material-ui/icons/MoreVert";

const styles = (theme: any) => ({
    defaultClass: {
        alignItems: "flex-start",
        color: "rgba(0, 0, 0, 0.87)",
        overflow: "hidden",
        padding: 0,
        width: "100%",
    },
    linkActive: {
        "&:before": {
            background: theme.palette.tab.secondary,
            bottom: 0,
            content: "''",
            display: "block",
            left: 0,
            position: "absolute",
            top: 0,
            width: 6,
        },
        backgroundColor: theme.palette.text.white,
        position: "relative",
    },
    listItemSecondaryAction: {
        marginRight: 10,
        top: 35,
        zIndex: 10,
    },
    moreVer: {
        color: theme.palette.grey.coolGray8,
        fontSize: 30,
    },
});

class SideBarAccountInfo extends Component<any> {
    changePanelHandler = (event) => {
        event.preventDefault();
        this.props.changePanel(this.props.account.idService);
    };

    handleClick = (event: any) => {
        const accountId = this.props.account.idService;
        const splitedPath = this.props.location.pathname.split("/");

        if (splitedPath.indexOf(accountId) !== -1) {
            event.preventDefault();
        }
    };

    render() {
        const {account, classes, children, settingsIdService} = this.props;

        return (
            <li>
                <ListItem
                    divider
                    className={classes.defaultClass}
                    component={NavLink}
                    to={`/accounts/${account.idService}`}
                    activeClassName={classes.linkActive}
                    onClick={this.handleClick}
                >
                    {React.Children.map(children, (child, index) => (
                        <div key={index}>
                            <ListItem
                                component={"div"}
                                style={{
                                    padding: 0,
                                    transform:
                                        settingsIdService === account.idService ? "translateX(0)" : "translateX(-100%)",
                                    transition: "0.5s linear",
                                    width: "260px",
                                }}
                                activeClassName={classes.linkActive}
                            >
                                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                                    <MoreVer
                                        className={classes.moreVer}
                                        onClick={this.changePanelHandler}
                                        testID={`sideBarAccountOption-${this.props.account.nnLs}`}
                                    />
                                </ListItemSecondaryAction>
                                {child}
                            </ListItem>
                        </div>
                    ))}
                </ListItem>
            </li>
        );
    }
}

export default withStyles(styles)(SideBarAccountInfo);
