// @flow
import * as actionTypes from "../../constants/types/ActionTypes";
import * as serviceTypes from "../../constants/types/ServiceAttributesTypes";
import * as questionActionTypes from "../../constants/types/QuestionRequestActionTypes";
import {getConfigs} from "../../utils/servicesUtils";
import {createReducer} from "./createReducer";

const transformToTree = (serviceArr: Array<Object>) => {
    const resultArr = [];

    serviceArr.forEach((service) => {
        const index = resultArr.findIndex((item) => item.title === service.nmCrmCategory);
        const newItem = {
            hint: service.nmHint,
            kdCrmService: service.kdCrmService,
            text: service.nmCrmService,
            value: service.uuidCrmService,
        };

        if (index === -1) {
            resultArr.push({
                data: [newItem],
                title: service.nmCrmCategory,
            });
        } else {
            resultArr[index].data.push(newItem);
        }
    });

    return resultArr;
};

export const normalizeFieldBuilderData = (data: Array<Object>) => {
    const newData = data.map<Object>((fieldData) => ({
        ...fieldData,
        nmColumn: fieldData.nmColumnName,
        nmTable: "LKA.LKA_APPLICATION_FORM",
        prVisible: true,
    }));

    newData.sort((field1, field2) => (field1.nnOrder < field2.nnOrder ? -1 : 1));

    return newData;
};

const getDef = (defaultValues: Array<Object>, attributes: Array<Object>) => {
    const res = {serviceInfo: {}};

    attributes.forEach((attribute) => {
        defaultValues.forEach((value) => {
            if (attribute.nmColumnName === value.nmCrmAttr) {
                if (attribute.prMultipleVals) {
                    res.serviceInfo[value.nmCrmAttr] = [value.vlAttribute];
                } else {
                    res.serviceInfo[value.nmCrmAttr] = value.vlAttribute;
                }
            }
        });
    });

    return res;
};

export type ApplicationFormReducerType = {|
    providerList: Array<Object>,
    serviceList: Array<Object>,
    accountsList: Array<Object>,
    allAccountsList: Array<Object>,
    attributes: Array<Object>,
    attributesDefVal: Object,
    documents: Array<Object>,
    emailRequestForm: {
        emailRequestLsList: Array<Object>,
        emailRequestAttributes: Array<Object>,
        documents: Array<Object>,
        initialValues: Object,
    },
    fetchingServiceList: boolean,
    fetchingAttributes: boolean,
    openApplicationForm: boolean,
    faq: Array<Object>,
    serviceDefaultValue: Array<Object>,
|};

const initialState: ApplicationFormReducerType = {
    accountsList: [],
    allAccountsList: [],
    attributes: [],
    attributesDefVal: {},
    documents: [],
    emailRequestForm: {
        documents: [],
        emailRequestAttributes: [],
        emailRequestLsList: [],
        initialValues: {},
    },
    faq: [],
    fetchingAttributes: false,
    fetchingServiceList: false,
    openApplicationForm: false,
    providerList: [],
    serviceDefaultValue: [],
    serviceList: [],
};

export const applicationFormReducer = createReducer(initialState, {
    [actionTypes.GET_APPLICATION_FORM_LS_LIST_ACTION_SUCCESS]: (state: ApplicationFormReducerType, {response}) => ({
        ...state,
        accountsList: response.map(({kdProvider, nnLs, kdSystem, idCrmProvider}) => ({
            idCrmProvider,
            kdProvider,
            kdSystem,
            nmValue: nnLs,
            nnCode: nnLs,
        })),
        allAccountsList: response.map(({kdProvider, nnLs, kdSystem, idCrmProvider, idService}) => ({
            idCrmProvider,
            idService,
            kdProvider,
            kdSystem,
            nmValue: nnLs,
            nnCode: nnLs,
        })),
    }),
    [actionTypes.GET_PROVIDER_LIST_ACTION_SUCCESS]: (state: ApplicationFormReducerType, {response}) => ({
        ...state,
        providerList: response.map(({nmProvider, kdProvider, prLsLess, idCrmProvider}) => ({
            idCrmProvider,
            nmValue: nmProvider,
            nnCode: kdProvider,
            prLsLess,
        })),
    }),
    [actionTypes.GET_SERVICE_LIST_ACTION]: (state: ApplicationFormReducerType) => ({
        ...state,
        attributes: [],
        documents: [],
        fetchingServiceList: true,
    }),
    [actionTypes.GET_SERVICE_LIST_ACTION_SUCCESS]: (state: ApplicationFormReducerType, {response}) => ({
        ...state,
        fetchingServiceList: false,
        serviceList: transformToTree(response),
    }),
    [actionTypes.GET_SERVICE_LIST_ACTION_FAILURE]: (state: ApplicationFormReducerType) => ({
        ...state,
        fetchingServiceList: false,
    }),
    [actionTypes.CHANGE_PROVIDER_ACTION]: (state: ApplicationFormReducerType, {payload: {kdProvider}}) => {
        if (state.providerList && state.allAccountsList) {
            const provider = state.providerList.find(({nnCode}) => nnCode === kdProvider);
            const newAccountList1 =
                provider && provider.prLsLess
                    ? [
                          {
                              idCrmProvider: undefined,
                              kdProvider: "",
                              kdSystem: undefined,
                              nmValue: "Без лицевого счета",
                              nnCode: "none",
                          },
                      ]
                    : [];
            const newAccountList2 = state.allAccountsList
                ? state.allAccountsList.filter((account) => account.kdProvider === kdProvider)
                : [];

            return {
                ...state,
                accountsList: [...newAccountList1, ...newAccountList2],
                attributes: [],
                documents: [],
                serviceList: [],
            };
        }

        return state;
    },
    [actionTypes.GET_SERVICE_ATTR_ACTION]: (state: ApplicationFormReducerType) => ({
        ...state,
        attributes: [],
        documents: [],
        fetchingAttributes: true,
    }),
    [actionTypes.GET_SERVICE_ATTR_ACTION_SUCCESS]: (state: ApplicationFormReducerType, {response}) => ({
        ...state,
        attributes: getConfigs(
            response.attributes.sort((field1, field2) => (field1.nnOrder < field2.nnOrder ? -1 : 1)),
        ),
        attributesDefVal: getDef(state.serviceDefaultValue, normalizeFieldBuilderData(response.attributes)),
        documents: response.documents,
        fetchingAttributes: false,
    }),
    [actionTypes.GET_SERVICE_ATTR_ACTION_FAILURE]: (state: ApplicationFormReducerType) => ({
        ...state,
        fetchingAttributes: false,
    }),
    [serviceTypes.GET_SERVICE_ATTR_DEFAULT_VALUE_ACTION]: (state: ApplicationFormReducerType) => ({
        ...state,
        serviceDefaultValue: [],
    }),
    [serviceTypes.GET_SERVICE_ATTR_DEFAULT_VALUE_ACTION_SUCCESS]: (state: ApplicationFormReducerType, {response}) => ({
        ...state,
        serviceDefaultValue: response,
    }),
    [serviceTypes.GET_SERVICE_ATTR_DEFAULT_VALUE_ACTION_FAILURE]: (state: ApplicationFormReducerType) => ({
        ...state,
        fetchingAttributes: false,
    }),
    [actionTypes.OPEN_APPLICATION_FORM_MODAL_ACTION]: (state: ApplicationFormReducerType) => ({
        ...state,
        openApplicationForm: true,
    }),
    [actionTypes.CRM_SERVICE_FAQ_LIST_ACTION]: (state: ApplicationFormReducerType) => ({
        ...state,
        faq: [],
    }),
    [actionTypes.CRM_SERVICE_FAQ_LIST_ACTION_SUCCESS]: (state: ApplicationFormReducerType, {response}) => ({
        ...state,
        faq: response,
    }),
    [actionTypes.CRM_SERVICE_FAQ_LIST_ACTION_FAILURE]: (state: ApplicationFormReducerType) => ({
        ...state,
        faq: [],
    }),
    [actionTypes.CLOSE_APPLICATION_FORM_MODAL_ACTION]: () => initialState,
    [questionActionTypes.GET_EMAIL_REQUEST_LS_LIST_ACTION_SUCCESS]: (state, {response}) => ({
        ...state,
        emailRequestForm: {
            ...state.emailRequestForm,
            emailRequestLsList: response,
        },
    }),
    [questionActionTypes.GET_EMAIL_REQUEST_ATTRIBUTES_ACTION_SUCCESS]: (state, {response: {attributes = []}}) => ({
        ...state,
        emailRequestForm: {
            ...state.emailRequestForm,
            documents: [
                {
                    idDocType: 1,
                    nmVdDoc: "Произвольный документ",
                    prRequired: false,
                },
            ],
            emailRequestAttributes: attributes,
            initialValues: attributes.reduce(
                (res, {nmColumn, vlDefault}) => (vlDefault ? {...res, [nmColumn]: vlDefault} : res),
                {},
            ),
        },
    }),
    [questionActionTypes.REG_EMAIL_REQUEST_ACTION_SUCCESS]: (state) => ({
        ...state,
        openApplicationForm: false,
    }),
    [questionActionTypes.REG_EMAIL_REQUEST_ACTION_FAILURE]: (state) => ({
        ...state,
        openApplicationForm: false,
    }),
});
