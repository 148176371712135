// @flow
import * as React from "react";
import TextField from "../../components/Form/TextField";

type Props = {
    InputProps: Object,
};

const FIOTextField = (props: Props) => {
    const handleChange = (event: any) => {
        const {value} = event.currentTarget;
        const bigFirstLetter = value.length === 1 ? value.toUpperCase() : value;
        const allowedSymbols = /[A-Za-zА-Яа-яЁё\s.-]/;
        const firstAllowedSymbol = /[A-Za-zА-Яа-яЁё]/;

        if (
            (value.length === 1 && !firstAllowedSymbol.test(event.nativeEvent.data)) ||
            !allowedSymbols.test(event.nativeEvent.data)
        ) {
            return false;
        }
        props.InputProps.onChange(bigFirstLetter);

        return false;
    };
    const handleBlur = (event: SyntheticEvent<HTMLInputElement>) => {
        const {value} = event.currentTarget;

        if (value) {
            props.InputProps.onChange(value.charAt(0).toUpperCase() + value.slice(1));
        }
        props.InputProps.onBlur();
    };

    return (
        <TextField
            {...props}
            InputProps={{
                ...props.InputProps,
                onBlur: handleBlur,
                onChange: handleChange,
            }}
        />
    );
};

export default FIOTextField;
