// @flow

import {type themeTypes} from "../../../themeStyle";

export const styles = (theme: themeTypes) => ({
    backgroundImg: {
        bottom: 0,
        position: "absolute",
        width: "100%",
    },
    divider: {
        margin: "24px 0",
    },
    mainContent: {
        alignItems: "center",
        backgroundColor: theme.palette.grey.authBackground,
        display: "flex",
        justifyContent: "center",
        minHeight: `calc(100vh - ${theme.sizing.appbarHeight}px)`,
        position: "relative",
        zIndex: 1,
    },
    paperContainer: {
        border: `1px solid ${theme.palette.grey.border}`,
        boxShadow: "none",
        maxHeight: 400,
        position: "relative",
        width: 500,
    },
    requestButton: {
        "&:hover": {
            backgroundColor: theme.palette.common.white,
        },
        border: `1px solid ${theme.palette.grey.border}`,
        marginTop: 16,
    },
});
