// @flow
import * as actionTypes from "../../../constants/types/MesAutoPayActionTypes";
import {loaderString} from "../../../constants/LoaderString";
import {type ApiMiddlewareActionType, type ApiMiddlewareResponsePromiseType} from "../../middlewares/apiMiddleware";

export type AutopaySaveParamsType = {
    idService: number,
    kdProvider: number,
    prSum: number,
    vlSum?: number,
    nmEmail: string,
    nnPhone: string,
    nnPhoneHome: number,
    nmMiddle: string,
    nmFirst: string,
    nmLast: string,
};

export type RegAutoPaymentParamsType = {
    idAutopaymentParam: string,
    bindStatus: string,
    idClient: string,
    exp: string,
};

export type EditAutoPaymentParamsType = {
    idService: number,
    idStatement: number,
    kdProvider: number,
    kdTask: number,
    dtStatement: string,
    prSum: string,
    vlSum: number,
    nmEmail: string,
    nnPhone: string,
    nnPhoneHome: number,
    nmAddress: string,
    nmIssued: string,
    nnNum: number,
    nnLs: string,
    nnSeries: number,
    nmDoc: string,
    nmMiddle: string,
    nmFirst: string,
    nmLast: string,
};

export type CancelAutoPaymentParamsType = {
    nmMiddle: string,
    nmFirst: string,
    nmLast: string,
    nmDoc: string,
    nnSeries: string,
    nnNum: string,
    nmAddress: string,
    nnPhoneHome: number,
    nnPhone: number,
    nmEmail: string,
    kdProvider: number,
    nnLs: string,
    prSum: string,
    vlSum: string,
    idStatement: number,
    kdReasonAnnulment: string,
};

export type GetAutoPaymentStatusType = (
    nnLs: string,
    kdProvider: number,
    session: string,
) => ApiMiddlewareResponsePromiseType;

export type GetAutoPaymentDefaultParamsActionType = (
    idService: number,
    session: string,
) => ApiMiddlewareResponsePromiseType;

export type SaveAutoPaymentParamsActionType = (
    params: AutopaySaveParamsType,
    session: string,
) => ApiMiddlewareResponsePromiseType;

export type GetAutoPaymentRequestInfoActionType = (
    nnLs: string,
    kdProvider: number,
    session: string,
) => ApiMiddlewareResponsePromiseType;

export type EditAutoPaymentParamsActionType = (
    params: EditAutoPaymentParamsType,
    session: string,
) => ApiMiddlewareResponsePromiseType;

export type GetAutoPaymentAnnulReasonsActionType = (session: string) => ApiMiddlewareResponsePromiseType;

export type RegAutoPaymentActionType = (
    params: RegAutoPaymentParamsType,
    session: string,
) => ApiMiddlewareResponsePromiseType;

export type CancelAutoPaymentActionType = (
    params: CancelAutoPaymentParamsType,
    session: string,
) => ApiMiddlewareResponsePromiseType;

export const getAutoPaymentStatusAction = (
    nnLs: string,
    kdProvider: number,
    session: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            kdProvider,
            nnLs,
        },
        query: "GetAutoPaymentStatus",
        session,
        types: [
            actionTypes.GET_AUTO_PAYMENT_STATUS_ACTION,
            actionTypes.GET_AUTO_PAYMENT_STATUS_ACTION_SUCCESS,
            actionTypes.GET_AUTO_PAYMENT_STATUS_ACTION_FAILURE,
        ],
    },
});

export const getAutoPaymentDefaultParamsAction = (idService: number, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: "GetAutoPaymentDefaultParams",
        session,
        types: [
            actionTypes.GET_AUTO_PAYMENT_DEFAULT_PARAMS_ACTION,
            actionTypes.GET_AUTO_PAYMENT_DEFAULT_PARAMS_ACTION_SUCCESS,
            actionTypes.GET_AUTO_PAYMENT_DEFAULT_PARAMS_ACTION_FAILURE,
        ],
    },
});

export const saveAutoPaymentParamsAction = (params: AutopaySaveParamsType, session: string) => (
    dispatch: any,
): ApiMiddlewareActionType => {
    const autopayWindow = window.open();

    autopayWindow.document.write(loaderString);

    return dispatch({
        api: {
            data: params,
            query: "SaveAutoPaymentParams",
            session,
            types: [
                actionTypes.SAVE_AUTO_PAYMENT_PARAMS_ACTION,
                actionTypes.SAVE_AUTO_PAYMENT_PARAMS_ACTION_SUCCESS,
                actionTypes.SAVE_AUTO_PAYMENT_PARAMS_ACTION_FAILURE,
            ],
        },
    })
        .then((action) => {
            if (!action.error && action.response && action.response.nmLink) {
                autopayWindow.location.href = action.response.nmLink;
            } else {
                autopayWindow.close();
            }

            return action;
        })
        .catch(() => autopayWindow.close());
};

export const regAutoPaymentAction = (params: RegAutoPaymentParamsType, session: string): ApiMiddlewareActionType => ({
    api: {
        data: params,
        query: "RegAutoPayment",
        session,
        types: [
            actionTypes.REG_AUTO_PAYMENT_ACTION,
            actionTypes.REG_AUTO_PAYMENT_ACTION_SUCCESS,
            actionTypes.REG_AUTO_PAYMENT_ACTION_FAILURE,
        ],
    },
});

export const getAutoPaymentRequestInfoAction = (
    nnLs: string,
    kdProvider: number,
    session: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            kdProvider,
            nnLs,
        },
        query: "GetAutoPaymentRequestInfo",
        session,
        types: [
            actionTypes.GET_AUTO_PAYMENT_REQUEST_INFO_ACTION,
            actionTypes.GET_AUTO_PAYMENT_REQUEST_INFO_ACTION_SUCCESS,
            actionTypes.GET_AUTO_PAYMENT_REQUEST_INFO_ACTION_FAILURE,
        ],
    },
});

export const editAutoPaymentParamsAction = (
    params: EditAutoPaymentParamsType,
    session: string,
): ApiMiddlewareActionType => ({
    api: {
        data: params,
        query: "EditAutoPaymentParams",
        session,
        types: [
            actionTypes.EDIT_AUTO_PAYMENT_PARAMS_ACTION,
            actionTypes.EDIT_AUTO_PAYMENT_PARAMS_ACTION_SUCCESS,
            actionTypes.EDIT_AUTO_PAYMENT_PARAMS_ACTION_FAILURE,
        ],
    },
});

export const getAutoPaymentAnnulReasonsAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        list: true,
        query: "GetAutoPaymentAnnulReasons",
        session,
        types: [
            actionTypes.GET_AUTO_PAYMENT_ANNUL_REASONS_ACTION,
            actionTypes.GET_AUTO_PAYMENT_ANNUL_REASONS_ACTION_SUCCESS,
            actionTypes.GET_AUTO_PAYMENT_ANNUL_REASONS_ACTION_FAILURE,
        ],
    },
});

export const cancelAutoPaymentAction = (
    params: CancelAutoPaymentParamsType,
    session: string,
): ApiMiddlewareActionType => ({
    api: {
        data: params,
        query: "CancelAutoPayment",
        session,
        types: [
            actionTypes.EDIT_AUTO_PAYMENT_PARAMS_ACTION,
            actionTypes.EDIT_AUTO_PAYMENT_PARAMS_ACTION_SUCCESS,
            actionTypes.EDIT_AUTO_PAYMENT_PARAMS_ACTION_FAILURE,
        ],
    },
});
