// @flow
import moment from "moment";
import * as actionTypes from "../../../constants/types/ActionTypes";

export type SetLocaleStorageActionType = (key: string, value: any) => void;
export type GetLocaleStorageActionType = (key: string) => void;
export type LocaleStorageRemoveItemActionType = (key: string) => void;
export type ClearLocaleStorageActionType = () => void;

export const isAuthExpire = (key: string) => {
    const item = JSON.parse(window.localStorage.getItem(key)) || {};

    return item && moment().isBefore(item.expires);
};

export const setLocaleStorageAction = (key: string, value: any) => {
    let newValue = null;

    if (key === "auth" && value.remember) {
        newValue = {
            ...value,
            expires: moment()
                .add(1, "month")
                .toString(),
        };
    }

    window.localStorage.setItem(key, JSON.stringify(newValue || value));

    return {
        payload: {
            key,
            value: newValue || value,
        },
        type: actionTypes.SET_LOCALE_STORAGE_ACTION,
    };
};

export const localeStorageRemoveItemAction = (key: string) => {
    const value = JSON.parse(window.localStorage.getItem(key)) || {};

    if (key === "auth") {
        window.localStorage.removeItem(key);
    } else if (!value.expires) {
        window.localStorage.removeItem(key);
    }

    return {
        type: actionTypes.LOCALE_STORAGE_REMOVE_ITEM_ACTION,
    };
};

export const getLocaleStorageAction = (key: string) => {
    let value = JSON.parse(window.localStorage.getItem(key)) || {};

    if (key === "auth" && value.expires && !isAuthExpire(key)) {
        localeStorageRemoveItemAction(key);
        value = null;
    }

    return {
        payload: {
            value,
        },
        type: actionTypes.GET_LOCALE_STORAGE_ACTION,
    };
};

export const clearLocaleStorageAction = () => {
    window.localStorage.clear();

    return {
        type: actionTypes.CLEAR_LOCALE_STORAGE_ACTION,
    };
};
