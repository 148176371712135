// @flow
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {SMALL_ICON_SIZE} from "../../../constants/iconConstants";
import {type IconPropsType} from "./iconType";

const WarningOutlineIcon = ({backgroundColor, iconColor, ...otherProps}: IconPropsType) => (
    <SvgIcon {...otherProps}>
        <path fill={iconColor} d={"M 12 5.99 L 19.53 19 H 4.47 L 12 5.99 M 12 2 L 1 21 h 22 L 12 2 Z"} />
        <path fill={backgroundColor} d={"M 13 16 h -2 v 2 h 2 Z m 0 -6 h -2 v 5 h 2 Z"} />
    </SvgIcon>
);

WarningOutlineIcon.defaultProps = {
    backgroundColor: "#FFAD5C",
    fontSize: SMALL_ICON_SIZE,
    iconColor: "#DE7D26",
    viewBox: "0 0 53 53",
};

export default WarningOutlineIcon;
