// @flow

import React from "react";
import Dialog from "../../packages/components/Dialog/Dialog";
import Wizard from "../../packages/components/Wizard/Wizard";
import DialogCloseIcon from "../../packages/components/Dialog/DialogCloseIcon";
import {type StoreType} from "../../redux/reducers";
import {
    type PhoneChangeConfirmActionType,
    type SendSmsPhoneChangeRepeatActionType,
    type PhoneSendSmsConfirmRepeatActionType,
} from "../../redux/actions/phoneAction";
import {type BuilderGetGroupAttributesType} from "../../redux/actions/builderAction";
import type {IsConfirmActionType} from "../../redux/actions/profileAction";
import type {AuthPhoneConfirmActionType} from "../../redux/actions/authAction";
import ProfilePhoneChangeSmsConfirm from "./ProfilePhoneEmailChangeSteps/ProfilePhoneChangeSmsConfirm";
import ProfileEmailSend from "./ProfilePhoneEmailChangeSteps/ProfileEmailSend";

type Props = {
    auth: $PropertyType<StoreType, "auth">,
    open: boolean,
    phoneEmailType: string,
    attribute?: Object,
    onClosePhoneEmailChange: Function,
    phoneChangeConfirmAction: PhoneChangeConfirmActionType,
    sendSmsPhoneChangeRepeatAction: SendSmsPhoneChangeRepeatActionType,
    getBuilderGroupAttributesAction?: BuilderGetGroupAttributesType,
    phone?: string,
    email?: string,
    onRedirectToAccount?: () => void,
    isConfirmAction?: IsConfirmActionType,
    isCurrentPhone?: boolean,
    authPhoneConfirmAction?: AuthPhoneConfirmActionType,
    phoneSendSmsConfirmRepeatAction?: PhoneSendSmsConfirmRepeatActionType,
};

export const ProfilePhoneEmailConfirm = (props: Props) => {
    const {
        open,
        phoneEmailType = "",
        onClosePhoneEmailChange,
        phoneChangeConfirmAction,
        sendSmsPhoneChangeRepeatAction,
        getBuilderGroupAttributesAction,
        auth,
        email = "",
        phone = "",
        isConfirmAction,
        isCurrentPhone,
        authPhoneConfirmAction,
        phoneSendSmsConfirmRepeatAction,
        onRedirectToAccount,
    } = props;

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            maxWidth="sm"
            open={open}
            onClose={onClosePhoneEmailChange}
            PaperProps={{
                component: "form",
            }}
            testID="ProfilePhoneEmailConfirm"
        >
            <DialogCloseIcon onClick={onClosePhoneEmailChange} />
            <Wizard preventDefault onLastStep={onClosePhoneEmailChange}>
                {phoneEmailType === "phone" ? (
                    <Wizard.Page
                        render={(buttonProps) => (
                            <ProfilePhoneChangeSmsConfirm
                                isConfirmAction={isConfirmAction}
                                phone={phone}
                                buttonProps={buttonProps}
                                onClose={onClosePhoneEmailChange}
                                phoneChangeConfirmAction={phoneChangeConfirmAction}
                                sendSmsPhoneChangeRepeatAction={sendSmsPhoneChangeRepeatAction}
                                getBuilderGroupAttributesAction={getBuilderGroupAttributesAction}
                                auth={auth}
                                isCurrentPhone={isCurrentPhone}
                                authPhoneConfirmAction={authPhoneConfirmAction}
                                phoneSendSmsConfirmRepeatAction={phoneSendSmsConfirmRepeatAction}
                                onRedirectToAccount={onRedirectToAccount}
                            />
                        )}
                    />
                ) : null}
                {phoneEmailType === "email" ? (
                    <Wizard.Page
                        render={(buttonProps) => (
                            <ProfileEmailSend
                                email={email}
                                buttonProps={buttonProps}
                                onClose={onClosePhoneEmailChange}
                            />
                        )}
                    />
                ) : null}
            </Wizard>
        </Dialog>
    );
};

export default ProfilePhoneEmailConfirm;
