// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import TopMenuDropDown from "../TopMenuDropDown";
import {styles} from "../styles";
import Loader from "../../../packages/components/Loader/Loader";
import {type OperationsForAllAccountsType} from "../../../redux/reducers/configReducer";
import TopMenuOperationsBody from "./TopMenuOperationsBody";
import TopMenuOperationsHeader from "./TopMenuOperationsHeader";

type Props = {
    classes?: {
        [$Keys<$Call<typeof styles>>]: string,
    },
    operationsForAllAccounts: OperationsForAllAccountsType,
    dropdownHeadName: string,
};

const TopMenuOperationsForAllAccounts = (props: Props) => {
    const {classes = {}, operationsForAllAccounts, dropdownHeadName} = props;
    const {
        fetching,
        isOperationsForAllAccountsVisible,
        prIndicationsVisible,
        prPaymentsVisible,
    } = operationsForAllAccounts;
    const ComponentBody = ({onClose}: Object) => (
        <TopMenuOperationsBody
            prIndicationsVisible={prIndicationsVisible}
            prPaymentsVisible={prPaymentsVisible}
            onClose={onClose}
        />
    );

    return (
        <div>
            <Loader fetching={fetching} />
            {!fetching && isOperationsForAllAccountsVisible && (
                <TopMenuDropDown
                    ComponentBody={ComponentBody}
                    dropdownHeadName={dropdownHeadName}
                    ComponentHead={TopMenuOperationsHeader}
                    className={classes.dropDown}
                />
            )}
        </div>
    );
};

export default withStyles(styles)(TopMenuOperationsForAllAccounts);
