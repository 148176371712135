// @flow
import React from "react";
import banerIcon from "../../../../images/providers/ksg/logo/KsgLogoBlue.svg";
import {SMALL_ICON_SIZE} from "../../../../constants/iconConstants";

type Props = {
    fontSize?: number,
};

const KsgLogoBlueIcon = ({fontSize}: Props) => <img height={fontSize} width={fontSize} src={banerIcon} alt="" />;

KsgLogoBlueIcon.defaultProps = {
    fontSize: SMALL_ICON_SIZE,
};

export default KsgLogoBlueIcon;
