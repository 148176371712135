// @flow
import {type themeTypes} from "../../../../themeStyle";

export const styles = (theme: themeTypes) => ({
    icon: {
        color: theme.palette.common.white,
        transition: "transform linear 200ms",
    },
    iconRotate: {
        transform: "rotate(-180deg)",
    },
});
