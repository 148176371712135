// @flow

import React, {useEffect} from "react";
import Iframe from "react-iframe";
import Dialog from "../../packages/components/Dialog/Dialog";
import DialogCloseIcon from "../../packages/components/Dialog/DialogCloseIcon";
import DialogContent from "../../packages/components/Dialog/DialogContent";
import DialogTitle from "../../packages/components/Dialog/DialogTitle";
import {
    MES_KD_PROVIDER,
    MOE_KD_PROVIDER,
    TKO_KD_PROVIDER,
    KSG_KD_PROVIDER,
    TMK_NRG_KD_PROVIDER,
    TMK_RTS_KD_PROVIDER,
    VLG_KD_PROVIDER,
    UFA_KD_PROVIDER,
    ALT_KD_PROVIDER,
    TMB_KD_PROVIDER,
    VLD_KD_PROVIDER,
    SAR_KD_PROVIDER,
    ORL_EPD_KD_PROVIDER,
    ORL_KD_PROVIDER,
} from "../../constants/KdProvider";
import {turnedOnProviders} from "../../constants/Providers";

type Props = {
    insideModal?: boolean,
    isIframeModalOpen?: boolean,
    closeIframeModal?: () => void,
    url?: string,
};

const mesProviders = [KSG_KD_PROVIDER, MES_KD_PROVIDER, MOE_KD_PROVIDER, TKO_KD_PROVIDER];
const tmkProviders = [TMK_NRG_KD_PROVIDER, TMK_RTS_KD_PROVIDER];
const orlProviders = [ORL_EPD_KD_PROVIDER, ORL_KD_PROVIDER];

export const RegisterMIRIframe = (props: Props) => {
    const removeSmartbanner = () => {
        const smartbannerEnablingMetaTag = document.querySelector("meta[name='smartbanner:enabled-platforms']");

        if (smartbannerEnablingMetaTag) {
            smartbannerEnablingMetaTag.setAttribute("content", "none");
        }
    };

    useEffect(() => {
        removeSmartbanner();

        const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        const eventer = window[eventMethod];
        const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

        const listener = (event) => {
            if (event.data === "iframe_process_complete") {
                if (props.insideModal && props.closeIframeModal) {
                    props.closeIframeModal();
                } else if (mesProviders.some((provider) => turnedOnProviders[provider])) {
                    window.location.replace("mesapp://mirregistrationcomplete");
                } else if (tmkProviders.some((provider) => turnedOnProviders[provider])) {
                    window.location.replace("tmkapp://mirregistrationcomplete");
                } else if (turnedOnProviders[VLG_KD_PROVIDER]) {
                    window.location.replace("vlgapp://mirregistrationcomplete");
                } else if (turnedOnProviders[UFA_KD_PROVIDER]) {
                    window.location.replace("ufaapp://mirregistrationcomplete");
                } else if (turnedOnProviders[ALT_KD_PROVIDER]) {
                    window.location.replace("altapp://mirregistrationcomplete");
                } else if (turnedOnProviders[VLD_KD_PROVIDER]) {
                    window.location.replace("vldapp://mirregistrationcomplete");
                } else if (turnedOnProviders[SAR_KD_PROVIDER]) {
                    window.location.replace("sarapp://mirregistrationcomplete");
                }
            }
        };

        eventer(messageEvent, listener);

        return () => {
            const eventMethodCleaner = window.removeEventListener ? "removeEventListener" : "detachEvent";
            const eventCleaner = window[eventMethodCleaner];

            eventCleaner(messageEvent, listener);
        };
    }, []);

    const getFrameUrl = () => {
        if (mesProviders.some((provider) => turnedOnProviders[provider])) {
            return "https://form.privetmir.ru/register-iframe/zhkkh/mosenergosbyt/";
        } else if (tmkProviders.some((provider) => turnedOnProviders[provider])) {
            return "https://form.privetmir.ru/register-iframe/zhkkh/tomskenergosbyt-eirc-to/";
        } else if (orlProviders.some((provider) => turnedOnProviders[provider])) {
            return "https://privetmir.ru/register-iframe/interrao-orel/";
        } else if (turnedOnProviders[VLG_KD_PROVIDER]) {
            return "https://privetmir.ru/register-iframe/sevesk_mp/";
        } else if (turnedOnProviders[UFA_KD_PROVIDER]) {
            return "https://privetmir.ru/register-iframe/bashrts_mp/";
        } else if (turnedOnProviders[ALT_KD_PROVIDER]) {
            return "https://privetmir.ru/register-iframe/altaiensb_mp/";
        } else if (turnedOnProviders[TMB_KD_PROVIDER]) {
            return "https://privetmir.ru/register-iframe/tesk_mp/";
        } else if (turnedOnProviders[VLD_KD_PROVIDER]) {
            return "https://privetmir.ru/register-iframe/esbvolga_mp/";
        } else if (turnedOnProviders[SAR_KD_PROVIDER]) {
            return "https://privetmir.ru/register-iframe/saratovenergo_mp/";
        }

        return null;
    };

    return props.insideModal ? (
        <Dialog open={props.isIframeModalOpen} fullWidth onClose={props.closeIframeModal}>
            <DialogTitle> </DialogTitle>
            <DialogCloseIcon onClick={props.closeIframeModal} />
            <DialogContent>
                <Iframe url={props.url} width="100%" height="500px" frameBorder="0" />
            </DialogContent>
        </Dialog>
    ) : (
        <Iframe url={getFrameUrl()} width="100%" height="100%" frameBorder="0" />
    );
};
export default RegisterMIRIframe;
