/* eslint-disable max-lines */
// @flow
import {push} from "connected-react-router";
import * as actionTypes from "../../constants/types/ActionTypes";
import {type ApiMiddlewareActionType, type ApiMiddlewareResponsePromiseType} from "../middlewares/apiMiddleware";
import {authKey, branchName, onboardingSettingsKey, periodKey} from "../../constants/Application";
import {CODE_UNAUTORIZATION} from "../../constants/StatusCode";
import {
    CRM_CHANGE_PSW_CODE,
    EXTRA_REGISTRATION_CODE,
    SHOW_CAPTCHE,
    AGREEMENT_CODE,
    RESPONSE_RESULT_TEXT_CODE,
    REQUIRED_TFA,
} from "../../constants/KdResults";
import {authLogoutAndRedirect} from "../middlewares/systemErrorMiddleware";
import {getDeviceToken} from "../../utils/browserUtils";
import {setCookieAction, setSessionCookieAction} from "./storageActions/cookieStorageAction";
import {
    getLocaleStorageAction,
    localeStorageRemoveItemAction,
    setLocaleStorageAction,
} from "./storageActions/localeStorageAction";
import {noticeRoutineAction} from "./notificationAction";
import {getAdElementsPopupAction} from "./adPopupAction";
import {getMenuSettings} from "./configAction";

export type AuthLoginActionType = (
    auth: Object,
    showCaptch?: boolean,
    oneTimePass?: boolean,
) => ApiMiddlewareResponsePromiseType;
export type AuthRegistrationActionType = (
    attributes: string,
    formValues: Object,
    payload: Object,
) => ApiMiddlewareResponsePromiseType;
export type AuthPhoneConfirmActionType = (code: string, idProfile?: string) => ApiMiddlewareResponsePromiseType;
export type SendSmsOnetimePswActionType = (login: Object) => ApiMiddlewareResponsePromiseType;
export type AuthLogoutAction = (
    session?: string,
    vlToken: string | null,
    sudirLogoutUrl: string,
) => ApiMiddlewareResponsePromiseType;
export type AuthLogoutType = () => {
    type: string,
};
export type ExtraRegistrationProfileEditActionType = (
    attributes: string,
    idProfile: string,
    skipResult?: boolean,
) => ApiMiddlewareResponsePromiseType;
export type SetAgreementActionType = (idProfile: string) => ApiMiddlewareResponsePromiseType;
export type OpenConfirmEmailOrSMSModalActionParamsType = {
    openModalConfirm: boolean,
    phoneEmailType: string,
    phone: string,
    email: string,
};
export type OpenConfirmEmailOrSMSModalActionType = (
    data: OpenConfirmEmailOrSMSModalActionParamsType,
) => ApiMiddlewareResponsePromiseType;
export type CheckNeedPhoneConfirmActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type CheckSudirProfileActionType = (sudirAuthCode: string, backUrl: string) => ApiMiddlewareResponsePromiseType;
export type SudirCheckDiffActionType = (sudirProfile: string, session: string) => ApiMiddlewareResponsePromiseType;
export type SudirRegUserActionType = (
    sudirProfile: string,
    prSubscrEnabled: boolean,
) => ApiMiddlewareResponsePromiseType;
export type SudirLinkUserActionType = (sudirProfile: string, session: string) => ApiMiddlewareResponsePromiseType;
export type SudirUpdateUserActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type SudirNoUpdateUserActionType = (session: string) => ApiMiddlewareResponsePromiseType;
export type SendTfaActionActionType = ({
    idProfile: string,
    kdTfa: number,
    vlTfaAuthToken: string,
}) => ApiMiddlewareResponsePromiseType;
export type ResendTfaActionType = ({
    idProfile: string,
    kdTfa: number,
    nnSuopSession: number,
    vlTfaAuthToken: string,
}) => ApiMiddlewareResponsePromiseType;

const vlDeviceInfo = JSON.stringify({
    appver: branchName,
    type: "browser",
    userAgent: window.navigator.userAgent,
});

export const authInitAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        query: "Init",
        session,
        types: [
            actionTypes.AUTH_INIT_ACTION,
            actionTypes.AUTH_INIT_ACTION_SUCCESS,
            actionTypes.AUTH_INIT_ACTION_FAILURE,
        ],
    },
});
export const getProfileShowOnboardingAction = (session: string) => (dispatch: any): ApiMiddlewareActionType =>
    dispatch({
        api: {
            query: "GetProfileShowOnboarding",
            session,
            types: [actionTypes.DEFAULT_ACTION, actionTypes.DEFAULT_ACTION_SUCCESS, actionTypes.DEFAULT_ACTION_FAILURE],
        },
    }).then((action) => {
        if (!action.error) {
            dispatch(
                setLocaleStorageAction(onboardingSettingsKey, {
                    prShowOnboarding: action.response.prShowOnboarding,
                }),
            );
        }
    });
export const authLoginAction = (auth: Object, showCaptch?: boolean, oneTimePass?: boolean) => (
    dispatch: any,
    getState: Function,
): ApiMiddlewareActionType => {
    const vlTfaDeviceToken = getDeviceToken();
    const {
        captchaResponse,
        password,
        pswToken,
        pswOnetime,
        login,
        remember,
        sudirProfile,
        isSudir,
        fLoginSudir,
        prMirror,
        kdTfa,
        nnTfaCode,
    } = auth;
    const authData: Object = {
        fLoginSudir,
        login,
        prMirror,
        psw: password,
        pswOnetime,
        pswToken,
        remember,
        sudirProfile,
        vlDeviceInfo,
    };
    let updateTfaState = true;

    // Формируем параметры для ДФА
    if (nnTfaCode) {
        authData.nnTfaCode = nnTfaCode;
        authData.kdTfa = kdTfa;
        updateTfaState = false;
    } else if (vlTfaDeviceToken) {
        authData.vlTfaDeviceToken = vlTfaDeviceToken;
    }

    if (showCaptch && captchaResponse) {
        authData.captchaResponse = captchaResponse;
        authData.plugin = "captchaChecker";
    }

    return dispatch({
        api: {
            action: "auth",
            data: authData,
            query: "login",
            types: [
                actionTypes.AUTH_LOGIN_ACTION,
                actionTypes.AUTH_LOGIN_ACTION_SUCCESS,
                actionTypes.AUTH_LOGIN_ACTION_FAILURE,
            ],
        },
        payload: {
            login: authData.login,
            oneTimePass,
            prMirror,
            remember,
            skipkdResults: [CRM_CHANGE_PSW_CODE, EXTRA_REGISTRATION_CODE, AGREEMENT_CODE, REQUIRED_TFA],
            updateTfaState,
        },
    }).then((action) => {
        if (!action.error) {
            const {session, idProfile, newToken} = action.response;

            return session
                ? dispatch(authInitAction(session)).then((result) => {
                      if (!result.error) {
                          dispatch(getMenuSettings(session));
                          const store = getState();

                          dispatch(
                              setLocaleStorageAction(authKey, {
                                  idProfile,
                                  isSudir: Boolean(sudirProfile) || Boolean(isSudir),
                                  login: authData.login,
                                  newToken,
                                  prMirror,
                                  remember: remember || store.auth.remember,
                                  session,
                              }),
                          );

                          if (window.gib) {
                              window.gib.setAuthStatus(window.gib.IS_AUTHORIZED);
                              window.gib.setSessionID(session);
                              window.gib.setLogin(idProfile);
                          }

                          window.ym(process.env.REACT_APP_YACOUNTER, "setUserID", idProfile);
                          window.ym(process.env.REACT_APP_YACOUNTER, "userParams", {
                              UserID: idProfile,
                          });
                          if (session) {
                              dispatch(noticeRoutineAction(session, setLocaleStorageAction));
                              dispatch(getAdElementsPopupAction(session));
                              dispatch(getProfileShowOnboardingAction(session));
                          }
                      }

                      return result;
                  })
                : action;
        }

        if (pswToken && !prMirror) {
            authLogoutAndRedirect({dispatch, getState});
        }

        return action;
    });
};
export const sendTfaAction = ({
    idProfile,
    kdTfa,
    vlTfaAuthToken,
}: {
    idProfile?: string,
    kdTfa?: number,
    vlTfaAuthToken?: string,
}): ApiMiddlewareActionType => ({
    api: {
        data: {
            idProfile,
            kdTfa,
            vlTfaAuthToken,
        },
        query: "SendTfa",
        types: [actionTypes.DEFAULT_ACTION, actionTypes.SEND_TFA_ACTION_SUCCESS, actionTypes.SEND_TFA_ACTION_FAILURE],
    },
});
export const resendTfaAction = ({
    idProfile,
    vlTfaAuthToken,
    kdTfa,
    nnSuopSession,
}: {
    idProfile?: string,
    vlTfaAuthToken?: string,
    kdTfa?: number,
    nnSuopSession?: string,
}): ApiMiddlewareActionType => ({
    api: {
        data: {
            idProfile,
            kdTfa,
            nnSuopSession,
            vlTfaAuthToken,
        },
        query: "ResendTfa",
        types: [actionTypes.DEFAULT_ACTION, actionTypes.RESEND_TFA_ACTION_SUCCESS, actionTypes.DEFAULT_ACTION_FAILURE],
    },
});
export const openExtraRegistrationFormAction = () => ({
    type: actionTypes.OPEN_EXTRA_REGISTRATION_FORM_ACTION,
});
export const closeExtraRegistrationFormAction = () => ({
    type: actionTypes.CLOSE_EXTRA_REGISTRATION_FORM_ACTION,
});
export const extraRegistrationProfileEditAction = (
    attributes: string,
    idProfile: string,
    skipResult?: boolean,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            attributes,
            idProfile,
        },
        query: "ProfileEditEmpty",
        types: [
            actionTypes.EXTRA_REGISTRATION_PROFILE_EDIT_ACTION,
            actionTypes.CLOSE_EXTRA_REGISTRATION_FORM_ACTION,
            actionTypes.EXTRA_REGISTRATION_PROFILE_EDIT_ACTION_FAILURE,
        ],
    },
    payload: {
        skipkdResults: skipResult ? [RESPONSE_RESULT_TEXT_CODE] : undefined,
    },
});
export const openConfirmEmailOrSMSModalAction = (data: OpenConfirmEmailOrSMSModalActionParamsType) => ({
    payload: data,
    type: actionTypes.OPEN_CONFIRM_EMAIL_OR_SMS_MODAL_ACTION,
});
export const closeConfirmEmailOrSMSModalAction = () => ({
    type: actionTypes.CLOSE_CONFIRM_EMAIL_OR_SMS_MODAL_ACTION,
});
export const authRegistrationAction = (
    attributes: string,
    formValues: Object,
    payload: Object,
): ApiMiddlewareActionType => {
    const {PSW, PSW_RPT, captchaResponse, prSubscrInfo} = formValues;
    const registrationData: Object = {
        PSW,
        PSW_RPT,
        attributes,
        prSubscrInfo,
    };

    if (captchaResponse) {
        registrationData.captchaResponse = captchaResponse;
        registrationData.plugin = "captchaChecker";
    }

    return {
        api: {
            data: registrationData,
            query: "ProfileRegistration",
            types: [
                actionTypes.AUTH_REGISTRATION_ACTION,
                actionTypes.AUTH_REGISTRATION_ACTION_SUCCESS,
                actionTypes.AUTH_REGISTRATION_ACTION_FAILURE,
            ],
        },
        payload,
    };
};
export const authPhoneConfirmAction = (code: string, idProfile?: string): ApiMiddlewareActionType => ({
    api: {
        data: {code, idProfile},
        query: "PhoneConfirm",
        types: [
            actionTypes.AUTH_PHONE_CONFIRM_ACTION,
            actionTypes.AUTH_PHONE_CONFIRM_ACTION_SUCCESS,
            actionTypes.AUTH_PHONE_CONFIRM_ACTION_FAILURE,
        ],
    },
});
export const authLogout = () => (dispatch: any): ApiMiddlewareActionType => {
    dispatch(localeStorageRemoveItemAction(authKey));
    dispatch(localeStorageRemoveItemAction(periodKey));
    if (window.gib) {
        window.gib.setAuthStatus(window.gib.IS_GUEST);
    }

    return dispatch({
        type: actionTypes.AUTH_LOGOUT,
    });
};
export const sendSmsOnetimePswAction = ({captchaResponse, phone, vlToken}: Object) => {
    const sendSmsData: Object = {
        login: phone,
    };

    if (!captchaResponse) {
        sendSmsData.vlToken = vlToken;
    }
    if (captchaResponse) {
        sendSmsData.captchaResponse = captchaResponse;
        sendSmsData.plugin = "captchaChecker";
    }

    return {
        api: {
            data: sendSmsData,
            query: "SendSmsOnetimePsw",
            types: [
                actionTypes.SEND_SMS_ONETIME_PSW_ACTION,
                actionTypes.SEND_SMS_ONETIME_PSW_ACTION_SUCCESS,
                actionTypes.SEND_SMS_ONETIME_PSW_ACTION_FAILURE,
            ],
        },
        payload: {
            phone,
            skipkdResults: [SHOW_CAPTCHE],
        },
    };
};
export const authLogoutAction = (session: string, vlToken: string | null, sudirLogoutUrl: string) => (
    dispatch: any,
): ApiMiddlewareActionType =>
    dispatch({
        api: {
            action: "invalidate",
            data: {
                vlToken,
            },
            query: "ProfileExit",
            session,
            types: [
                actionTypes.AUTH_LOGOUT_ACTION,
                actionTypes.AUTH_LOGOUT_ACTION_SUCCESS,
                actionTypes.AUTH_LOGOUT_ACTION_FAILURE,
            ],
        },
        payload: {
            skipkdResults: [CODE_UNAUTORIZATION],
        },
    }).then((action) => {
        const authData = getLocaleStorageAction(authKey);

        dispatch(localeStorageRemoveItemAction(authKey));
        dispatch(localeStorageRemoveItemAction(periodKey));
        if (window.gib) {
            window.gib.setAuthStatus(window.gib.IS_GUEST);
        }
        if (authData.payload.value?.isSudir) {
            window.location = sudirLogoutUrl;
        } else {
            dispatch(push("/auth"));
        }

        return action;
    });
export const openChangePswModalAction = ({nmLink, idProfile, login}: Object) => ({
    payload: {
        idProfile,
        login,
        nmLink,
    },
    type: actionTypes.OPEN_CHANGE_PSW_MODAL_ACTION,
});
export const closeChangePswModalAction = () => ({
    type: actionTypes.CLOSE_CHANGE_PSW_MODAL_ACTION,
});
export const removeSession = () => (dispatch: any, getState: Function) => {
    const store = getState();
    const {remember, newToken, login} = store.auth;
    const storageAction = remember ? setCookieAction : setSessionCookieAction;

    dispatch(
        storageAction(
            authKey,
            JSON.stringify({
                login,
                newToken,
                remember,
            }),
        ),
    );

    return {
        type: actionTypes.REMOVE_SESSION_ACTION,
    };
};
export const checkNeedPhoneConfirmAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        query: "CheckNeedPhoneConfirm",
        session,
        types: [
            actionTypes.CHECK_NEED_PHONE_CONFIRM_ACTION,
            actionTypes.CHECK_NEED_PHONE_CONFIRM_ACTION_SUCCESS,
            actionTypes.CHECK_NEED_PHONE_CONFIRM_ACTION_FAILURE,
        ],
    },
});
export const checkSudirProfileAction = (sudirAuthCode: string, backUrl: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            backUrl,
            sudirAuthCode,
        },
        query: "CheckSudirProfile",
        types: [
            actionTypes.CHECK_SUDIR_PROFILE_ACTION,
            actionTypes.CHECK_SUDIR_PROFILE_ACTION_SUCCESS,
            actionTypes.CHECK_SUDIR_PROFILE_ACTION_FAILURE,
        ],
    },
    payload: {
        isSudirLogout: true,
    },
});
export const sudirCheckDiffAction = (sudirProfile: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            sudirProfile,
        },
        query: "SudirCheckDiff",
        session,
        types: [
            actionTypes.SUDIR_CHECK_DIFF_ACTION,
            actionTypes.SUDIR_CHECK_DIFF_ACTION_SUCCESS,
            actionTypes.SUDIR_CHECK_DIFF_ACTION_FAILURE,
        ],
    },
});
export const sudirUpdateUserAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        query: "SudirUpdateUser",
        session,
        types: [
            actionTypes.SUDIR_UPDATE_USER_ACTION,
            actionTypes.SUDIR_UPDATE_USER_ACTION_SUCCESS,
            actionTypes.SUDIR_UPDATE_USER_ACTION_FAILURE,
        ],
    },
});
export const sudirNoUpdateUserAction = (session: string): ApiMiddlewareActionType => ({
    api: {
        query: "SudirNoUpdateUser",
        session,
        types: [
            actionTypes.SUDIR_NO_UPDATE_USER_ACTION,
            actionTypes.SUDIR_NO_UPDATE_USER_ACTION_SUCCESS,
            actionTypes.SUDIR_NO_UPDATE_USER_ACTION_FAILURE,
        ],
    },
});
export const sudirRegUserAction = (sudirProfile: string, prSubscrEnabled: boolean): ApiMiddlewareActionType => ({
    api: {
        data: {
            prSubscrEnabled,
            sudirProfile,
        },
        query: "SudirRegUser",
        types: [
            actionTypes.SUDIR_REG_USER_ACTION,
            actionTypes.SUDIR_REG_USER_ACTION_SUCCESS,
            actionTypes.SUDIR_REG_USER_ACTION_FAILURE,
        ],
    },
});
export const sudirLinkUserAction = (sudirProfile: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            sudirProfile,
        },
        query: "SudirLinkUser",
        session,
        types: [
            actionTypes.SUDIR_LINK_USER_ACTION,
            actionTypes.SUDIR_LINK_USER_ACTION_SUCCESS,
            actionTypes.SUDIR_LINK_USER_ACTION_FAILURE,
        ],
    },
});
export const setAgreementAction = (idProfile: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idProfile,
        },
        query: "SetAgreement",
        types: [
            actionTypes.EXTRA_REGISTRATION_PROFILE_EDIT_ACTION,
            actionTypes.CLOSE_EXTRA_REGISTRATION_FORM_ACTION,
            actionTypes.EXTRA_REGISTRATION_PROFILE_EDIT_ACTION_FAILURE,
        ],
    },
});
export const saveRedirectUrlAction = (location: string) => ({
    payload: {location},
    type: actionTypes.SAVE_REDIRECT_URL_ACTION,
});
