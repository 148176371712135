// @flow
import map from "lodash/map";
import moment from "moment";
import trim from "lodash/trim";
import keysIn from "lodash/keysIn";

import {getPeriodTypes} from "../constants/types/PeriodTypes";
import {snakeCaseKeys} from "./objectUtils";
import "moment/locale/ru";

moment.locale("ru");

type FormRequestType = {
    kdAttribute?: any,
    kdEntity?: any,
    nmColumn?: any,
    vlAttribute: any,
};

export const dayFormat = (date?: string) => {
    if (date !== undefined) {
        return moment(date, "DD.MM.YYYY")
            .startOf("day")
            .format();
    }

    return "";
};

export const endOfDayFormat = (date?: string) => {
    if (date !== undefined) {
        return moment(date, "DD.MM.YYYY")
            .endOf("day")
            .format();
    }

    return "";
};

export const toRequestServiceAttributes = (values: Object, groupedAttributes: Object): string => {
    const formatedValues = map(values, (value: string, key: string): FormRequestType => {
        const attribute = groupedAttributes[key] || {};

        return {
            // Replace: kdAttribute: attribute.kdAttribute,
            kdEntity: attribute.kdEntity,
            nmColumn: attribute.nmColumn,
            vlAttribute: value === undefined ? "" : trim(value),
        };
    });

    return JSON.stringify(snakeCaseKeys(formatedValues));
};

export const toRequestAttributes = (values: Object, attributes: Array<Object>) => {
    const keys = keysIn(values);

    let res = [];

    keys.forEach((key) => {
        const el = attributes.find((attr) => attr.nmColumn === key);

        if (el) {
            res = [
                ...res,
                {
                    kdEntity: el.kdEntity,
                    nmColumn: el.nmColumn,
                    vlAttribute: values[key] === undefined ? "" : trim(values[key]),
                },
            ];
        }
    });

    return res;
};

export const parsePeriod = (period: string) => {
    const result = {};
    const startYear = 2014;

    if (getPeriodTypes[period]) {
        return getPeriodTypes[period];
    }
    if (!isNaN(Number(period)) && Number(period) >= startYear) {
        const endMonth = 11;

        return {
            dtEn: moment([Number(period), endMonth, 1])
                .endOf("month")
                .format(),
            dtSt: moment([Number(period), 0, 1]).format(),
        };
    }

    return result;
};
