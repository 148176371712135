// @flow
import qs from "querystring";
import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {type BrowserHistory} from "history/createBrowserHistory";
import {backUrlAction, type BackUrlActionType} from "../../redux/actions/backUrlAction";
import type {StoreType} from "../../redux/reducers";

type OwnProps = {|
    location: Location,
    history: BrowserHistory,
|};

type StoreProps = {|
    session: string,
|};

type DispatchProps = {|
    backUrlAction: BackUrlActionType,
|};

type Props = {...OwnProps, ...StoreProps, ...DispatchProps};

const AVAILABLE_BACK_URL_WITH_DATA = {
    "/alt_back_url_c": true,
    "/alt_back_url_d": true,
    "/alt_back_url_s": true,
    "/back_url_epr": true,
    "/claimbackurl": true,
    "/complaintbackurl": true,
    "/energy-contracts": true,
    "/gpbackurl": true,
    "/lseditbackurl": true,
    "/mass_pay_back_url_f": true,
    "/mass_pay_back_url_s": true,
    "/orl_back_url_f": true,
    "/orl_back_url_s": true,
    "/payment_v_status_f": true,
    "/payment_v_status_s": true,
    "/stisubackurl": true,
    "/tmb_back_url": true,
    "/vld_back_url": true,
    "/vlg_back_url_f": true,
    "/vlg_back_url_s": true,
};
const AVAILABLE_BACK_URL_WITH_RESULT = {
    "/energy-contracts": true,
};

export class CallbackUrlPage extends React.Component<Props> {
    componentDidMount = async () => {
        const type = this.props.location.pathname;
        let data = null;

        if (AVAILABLE_BACK_URL_WITH_DATA[type]) {
            data = qs.parse(this.props.location.search.substring(1));
        }

        if (AVAILABLE_BACK_URL_WITH_RESULT[type] && !data?.result) {
            return;
        }

        if ((AVAILABLE_BACK_URL_WITH_RESULT[type] && data?.result) || !AVAILABLE_BACK_URL_WITH_RESULT[type]) {
            await this.props.backUrlAction(this.props.session, type, data);
        }

        this.props.history.push(AVAILABLE_BACK_URL_WITH_RESULT[type] ? "/energy-contracts" : "/accounts/0");
    };

    render() {
        return null;
    }
}

const mapStateToProps = ({auth}: StoreType): StoreProps => ({
    session: auth.session || "",
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    backUrlAction: bindActionCreators(backUrlAction, dispatch),
});

export default connect<Props, OwnProps, StoreProps, DispatchProps, StoreType, _>(
    mapStateToProps,
    mapDispatchToProps,
)(CallbackUrlPage);
