// @flow
import * as actionTypes from "../../constants/types/ActionTypes";
import * as profileActionTypes from "../../constants/types/ProfileActionTypes";
import {
    builderServiceAttributes,
    builderServiceElements,
    builderServiceGroupAttributes,
    type BuilderServiceAttributesKeysType,
    type BuilderServiceElementsKeysType,
    type BuilderServiceGroupAttributesKeysType,
} from "../../constants/types/BuilderTypes";
import {type ApiMiddlewareActionType, type ApiMiddlewareResponsePromiseType} from "../middlewares/apiMiddleware";

export type BuilderGetAttributesType = (
    name: BuilderServiceAttributesKeysType,
    session?: string,
    idService?: string,
) => ApiMiddlewareResponsePromiseType;
export type BuilderGetGroupAttributesType = (
    name: BuilderServiceGroupAttributesKeysType,
    session?: string,
    isDisabledAuthorisationError?: Object,
) => ApiMiddlewareResponsePromiseType;

export type BuilderGetElementsType = (
    name: BuilderServiceElementsKeysType,
    session?: string,
    kdSection?: number,
    params?: Object,
) => ApiMiddlewareResponsePromiseType;

export type GetBuilderElementsType = (
    name: BuilderServiceElementsKeysType,
    session?: string,
    kdSection?: number,
) => ApiMiddlewareResponsePromiseType;

export type GetAdElementsLsActionPropsType = {
    name: BuilderServiceElementsKeysType,
    session: string,
    kdSection?: number,
    idService?: number,
};

export type GetAdElementsLsActionType = (props: GetAdElementsLsActionPropsType) => ApiMiddlewareActionType;

export const getBuilderAttributesAction = (
    name: BuilderServiceAttributesKeysType,
    session?: string,
    idService?: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
        },
        query: builderServiceAttributes[name],
        session,
        types: [
            actionTypes.GET_BUILDER_ATTRIBUTES,
            actionTypes.GET_BUILDER_ATTRIBUTES_SUCCESS,
            actionTypes.GET_BUILDER_ATTRIBUTES_FAILURE,
        ],
    },
    payload: {
        name,
    },
});

export const getExtraRegistrationAttributesAction = (
    name: BuilderServiceAttributesKeysType,
    idProfile?: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            idProfile,
        },
        query: builderServiceAttributes[name],
        types: [
            actionTypes.GET_BUILDER_ATTRIBUTES,
            actionTypes.GET_BUILDER_ATTRIBUTES_SUCCESS,
            actionTypes.GET_BUILDER_ATTRIBUTES_FAILURE,
        ],
    },
    payload: {
        name,
    },
});

export const getBuilderElementsAction = (
    name: BuilderServiceElementsKeysType,
    session?: string,
    kdSection?: number,
    params?: Object,
    // eslint-disable-next-line max-params
): ApiMiddlewareActionType => ({
    api: {
        data: {
            kdSection,
        },
        query: builderServiceElements[name],
        session,
        types: [
            actionTypes.GET_BUILDER_ELEMENTS,
            actionTypes.GET_BUILDER_ELEMENTS_SUCCESS,
            actionTypes.GET_BUILDER_ELEMENTS_FAILURE,
        ],
    },
    payload: {
        isDisabledAuthorisationError: params?.isDisabledAuthorisationError,
        name,
    },
});

export const getAdElementsLsAction = ({
    name,
    session,
    kdSection,
    idService,
}: GetAdElementsLsActionPropsType): ApiMiddlewareActionType => ({
    api: {
        data: {
            idService,
            kdSection,
        },
        query: builderServiceElements[name],
        session,
        types: [
            actionTypes.GET_BUILDER_ELEMENTS,
            actionTypes.GET_BUILDER_ELEMENTS_SUCCESS,
            actionTypes.GET_BUILDER_ELEMENTS_FAILURE,
        ],
    },
    payload: {
        name,
    },
});

export const getBuilderGroupAttributesAction = (
    name: BuilderServiceGroupAttributesKeysType,
    session?: string,
    params?: Object,
): ApiMiddlewareActionType => ({
    api: {
        query: builderServiceGroupAttributes[name],
        session,
        types: [
            profileActionTypes.GET_BUILDER_GROUP_ATTRIBUTES,
            profileActionTypes.GET_BUILDER_GROUP_ATTRIBUTES_SUCCESS,
            profileActionTypes.GET_BUILDER_GROUP_ATTRIBUTES_FAILURE,
        ],
    },
    payload: {
        isDisabledAuthorisationError: params?.isDisabledAuthorisationError,
        name,
    },
});
