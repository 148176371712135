// @flow
import * as actionTypes from "../../constants/types/ActionTypes";
import {
    type ApiMiddlewareActionType,
    type ApiMiddlewareResponsePromiseType,
} from "../../redux/middlewares/apiMiddleware";
import {SHOW_CAPTCHE} from "../../constants/KdResults";

export type PhoneSendSmsConfirmRepeatActionType = (
    phone: string,
    idProfile?: string,
) => ApiMiddlewareResponsePromiseType;
export type PhoneSendPswRestoreSmsActionType = (data: Object) => ApiMiddlewareResponsePromiseType;
export type PhonePswRestoreConfirmActionType = (values: Object) => ApiMiddlewareResponsePromiseType;
export type PhoneChangeConfirmActionType = (session: string, nnCode: string) => ApiMiddlewareResponsePromiseType;
export type SendSmsPhoneChangeRepeatActionType = (
    nnPhoneNew: string,
    session: string,
) => ApiMiddlewareResponsePromiseType;
export type PhoneChangeConfirmWOAuthActionType = (session: string, nnCode: string) => ApiMiddlewareResponsePromiseType;
export type SendSmsPhoneChangeRepeatWOAuthActionType = (
    nnPhoneNew: string,
    idProfile: string,
) => ApiMiddlewareResponsePromiseType;

export const phoneSendSmsConfirmRepeatAction = (phone: string, idProfile?: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idProfile,
            nnPhone: phone,
        },
        query: "SendSmsConfirmRepeat",
        types: [
            actionTypes.PHONE_SEND_SMS_CONFIRM_REPEAT_ACTION,
            actionTypes.PHONE_SEND_SMS_CONFIRM_REPEAT_ACTION_SUCCESS,
            actionTypes.PHONE_SEND_SMS_CONFIRM_REPEAT_ACTION_FAILURE,
        ],
    },
});

export const phoneSendPswRestoreSmsAction = (data: Object): ApiMiddlewareActionType => {
    const {captchaResponse, phone, vlToken} = data;
    const phoneData: Object = {
        captchaResponse,
        nnPhone: phone,
    };

    if (captchaResponse) {
        phoneData.plugin = "captchaChecker";
    } else {
        phoneData.vlToken = vlToken;
    }

    return {
        api: {
            data: phoneData,
            query: "SendPswRestoreSms",
            types: [
                actionTypes.PHONE_SEND_PSW_RESTORE_SMS_ACTION,
                actionTypes.PHONE_SEND_PSW_RESTORE_SMS_ACTION_SUCCESS,
                actionTypes.PHONE_SEND_PSW_RESTORE_SMS_ACTION_FAILURE,
            ],
        },
        payload: {
            skipkdResults: [SHOW_CAPTCHE],
        },
    };
};

export const phonePswRestoreConfirmAction = (values: Object): ApiMiddlewareActionType => ({
    api: {
        data: values,
        query: "PswRestorePhoneConfirm",
        types: [
            actionTypes.PHONE_PSW_RESTORE_CONFIRM_ACTION,
            actionTypes.PHONE_PSW_RESTORE_CONFIRM_ACTION_SUCCESS,
            actionTypes.PHONE_PSW_RESTORE_CONFIRM_ACTION_FAILURE,
        ],
    },
});

export const phoneChangeConfirmAction = (session: string, nnCode?: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            nnCode,
            session,
        },
        query: "PhoneChangeConfirm",
        types: [
            actionTypes.PHONE_CHANGE_CONFIRM_ACTION,
            actionTypes.PHONE_CHANGE_CONFIRM_ACTION_SUCCESS,
            actionTypes.PHONE_CHANGE_CONFIRM_ACTION_FAILURE,
        ],
    },
});

export const sendSmsPhoneChangeRepeatAction = (nnPhoneNew: string, session: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            nnPhoneNew,
            session,
        },
        query: "SendSmsPhoneChangeRepeat",
        types: [
            actionTypes.SEND_SMS_PHONE_CHANGE_REPEAT_ACTION,
            actionTypes.SEND_SMS_PHONE_CHANGE_REPEAT_ACTION_SUCCESS,
            actionTypes.SEND_SMS_PHONE_CHANGE_REPEAT_ACTION_FAILURE,
        ],
    },
});

export const phoneChangeConfirmWOAuthAction = (idProfile: string, nnCode?: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idProfile,
            nnCode,
        },
        query: "PhoneChangeConfirmWOAuth",
        types: [
            actionTypes.PHONE_CHANGE_CONFIRM_WO_AUTH_ACTION,
            actionTypes.PHONE_CHANGE_CONFIRM_WO_AUTH_ACTION_SUCCESS,
            actionTypes.PHONE_CHANGE_CONFIRM_WO_AUTH_ACTION_FAILURE,
        ],
    },
});

export const sendSmsPhoneChangeRepeatWOAuthAction = (
    nnPhoneNew: string,
    idProfile: string,
): ApiMiddlewareActionType => ({
    api: {
        data: {
            idProfile,
            nnPhoneNew,
        },
        query: "SendSmsPhoneChangeRepeatWOAuth",
        types: [
            actionTypes.SEND_SMS_PHONE_CHANGE_REPEAT_WO_AUTH_ACTION,
            actionTypes.SEND_SMS_PHONE_CHANGE_REPEAT_WO_AUTH_ACTION_SUCCESS,
            actionTypes.SEND_SMS_PHONE_CHANGE_REPEAT_WO_AUTH_ACTION_FAILURE,
        ],
    },
});
