// @flow
import * as actionTypes from "../../../constants/types/ActionTypes";
import type {ApiMiddlewareActionType, ApiMiddlewareResponsePromiseType} from "../../middlewares/apiMiddleware";

export type MoePayStatusActionType = (
    session: string,
    idPayment: string,
    query: string,
) => ApiMiddlewareResponsePromiseType;

export const moePayStatusAction = (session: string, idPayment: string, query: string): ApiMiddlewareActionType => ({
    api: {
        data: {
            idPayment,
        },
        query,
        session,
        types: [
            actionTypes.MOE_PAY_STATUS_ACTION,
            actionTypes.MOE_PAY_STATUS_ACTION_SUCCESS,
            actionTypes.MOE_PAY_STATUS_ACTION_FAILURE,
        ],
    },
});

export const closeMoePayStatusInfoAction = () => ({
    type: actionTypes.CLOSE_MOE_PAY_STATUS_INFO_ACTION,
});
