// @flow
import type {themeTypes} from "../../themeStyle";

export const styles = (theme: themeTypes) => ({
    accountsListHeight: {
        height: "calc(100vh - 120px)",
    },
    drawerPaper: {
        backgroundColor: theme.palette.grey.sideBar,
        border: "none",
        float: "left",
        overflow: "hidden",
        width: theme.sizing.sideBarWidth,
    },
    sideBarHead: {
        maxHeight: theme.sizing.sideBarHeadHeight,
        overflow: "hidden",
        zIndex: "auto",
    },
    subheadAddress: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "14px",
        paddingBottom: 3,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 12,
        position: "static",
    },
    subheadRoot: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    tooltipOverride: {
        maxWidth: 230,
    },
});
