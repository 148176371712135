// @flow
import * as React from "react";
import {withStyles} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Delete from "@material-ui/icons/Delete";
import {type BrowserHistory} from "history/createBrowserHistory";
import Typography from "../../packages/components/Typography/Typography";
import ModalConfirm from "../../packages/components/Modal/ModalConfirm";
import Form from "../../packages/FormBuilder/Form";
import {
    type DeleteAccountActionType,
    type AccountClearAccountDetailActionType,
} from "../../redux/actions/accountAction";
import {
    type GetMenuSettingsActionType,
    type GetMultipleLSPayVisibilityActionType,
} from "../../redux/actions/configAction";

export type Props = {
    auth: Object,
    accountsList: Array<Object>,
    classes?: any,
    clearAccountDetailAction: AccountClearAccountDetailActionType,
    currentLs: string,
    deleteAccountAction: DeleteAccountActionType,
    session: string,
    nnLs: string,
    history: BrowserHistory,
    idService: string,
    getMenuSettings: GetMenuSettingsActionType,
    getMultipleLSPayVisibilityAction: GetMultipleLSPayVisibilityActionType,
};
export type State = {
    openDialog: boolean,
};
const styles = () => ({
    delCompon: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        width: "45%",
    },
    marginTop4: {
        marginTop: 4,
    },
});

class SideBarAccountDelete extends React.PureComponent<Props, State> {
    state = {
        openDialog: false,
    };

    handleDeleteLs = (event: SyntheticEvent<>) => {
        event.preventDefault();
        this.setState({openDialog: true});
    };

    handelConfirmDeleteLS = async () => {
        const {currentLs, history, nnLs, session, idService} = this.props;
        const res = await this.props.deleteAccountAction(idService, session);

        this.handleClose();

        if (!res.error) {
            await this.props.getMultipleLSPayVisibilityAction(session);

            if (currentLs === nnLs) {
                this.props.clearAccountDetailAction();
                history.push("/accounts/");
            }
        }
    };

    handleClose = () => {
        this.setState({openDialog: false});
    };

    render() {
        const {auth, classes = {}} = this.props;

        return (
            <React.Fragment>
                <ListItem
                    component={"div"}
                    onClick={this.handleDeleteLs}
                    className={classes.delCompon}
                    testID={`sidebarAccountRemove-${this.props.nnLs}`}
                >
                    <Delete color="inherit" />
                    <Typography
                        className={classes.marginTop4}
                        display="inline"
                        color="inherit"
                        textColor="inherit"
                        uppercase
                    >
                        Удалить
                    </Typography>
                </ListItem>
                <Form
                    onSubmit={this.handelConfirmDeleteLS}
                    render={({handleSubmit, submitting}) => (
                        <ModalConfirm
                            testID="AccountDeleteForm"
                            title="Удаление ЛС"
                            onConfirm={handleSubmit}
                            buttonConfirmDisable={auth.prMirror || submitting}
                            onCancel={this.handleClose}
                            open={this.state.openDialog}
                        >
                            <Typography fontSize={14}>Вы уверены что хотите удалить этот счет?</Typography>
                        </ModalConfirm>
                    )}
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(SideBarAccountDelete);
