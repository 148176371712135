// @flow
import {type Element} from "../../components/AdPopup/AdPopupTypes";
import * as types from "../../constants/types/AdPopupTypes";
import {getKdElementAsKeys} from "../../utils/accoutReducerUtils";
import {createReducer} from "./createReducer";

export type AdsElements = {
    [key: string]: Element,
};

export type AdsReducerType = {
    elements: AdsElements,
    open: boolean,
    close?: boolean,
};

const initialState: AdsReducerType = {
    elements: {},
    open: false,
};

export const adsReducer = createReducer(initialState, {
    [types.GET_AD_ELEMENTS_POPUP_ACTION_SUCCESS]: (state, action) => {
        const elements = getKdElementAsKeys(action.response.elements);

        return {
            ...state,
            elements: {...state.elements, ...elements},
            open: true,
        };
    },
    [types.CLOSE_AD_POPUP]: (state) => ({
        ...state,
        open: false,
    }),
});
