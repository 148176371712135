/* eslint-disable sort-keys */
// @flow

import type {themeTypes} from "../../../themeStyle";

export const TypographyStyles = (theme: themeTypes) => ({
    rootDefault: {
        wordWrap: "break-word",
    },
    colorDefault: {
        borderColor: theme.palette.text.main,
        color: theme.palette.text.main,
    },
    uppercase: {
        textTransform: "uppercase",
    },
    capitalize: {
        textTransform: "capitalize",
    },
    underline: {
        borderBottom: "2px dashed",
    },
    "background-color-translucent": {
        background: theme.palette.primary.translucent,
    },
    "background-color-primary-light": {
        background: theme.palette.primary.light,
    },
    "background-color-white": {
        background: theme.palette.primary.white,
    },
    "background-color-opacity-primary-light": {
        background: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.opacityLight,
    },
    "background-color-secondary": {
        background: theme.palette.secondary.main,
    },
    "background-color-grey": {
        background: theme.palette.grey.coolGray1,
    },
    "background-color-grey-hightlight": {
        background: theme.palette.grey.hightlight,
    },
    "background-color-secondary-TopMenu": {
        background: theme.palette.topMenu.profile,
    },
    "color-black": {
        borderColor: theme.palette.common.black,
        color: theme.palette.common.black,
    },
    "color-primary": {
        borderColor: theme.palette.text.primary,
        color: theme.palette.text.primary,
    },
    "color-primary-light": {
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
    },
    "color-grey": {
        borderColor: theme.palette.grey.dark,
        color: theme.palette.grey.dark,
    },
    "color-grey-sideBar": {
        borderColor: theme.palette.grey.coolGray8,
        color: theme.palette.grey.coolGray8,
    },
    "color-grey-Banner": {
        borderColor: theme.palette.grey.banner,
        color: theme.palette.grey.banner,
    },
    "color-grey-coolGray1": {
        borderColor: theme.palette.grey.coolGray1,
        color: theme.palette.grey.coolGray1,
    },
    "color-grey-coolGray4": {
        borderColor: theme.palette.grey.coolGray4,
        color: theme.palette.grey.coolGray4,
    },
    "color-grey-dark": {
        borderColor: theme.palette.grey.dark,
        color: theme.palette.grey.dark,
    },
    "color-secondary": {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
    },
    "color-error": {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
    },
    "color-white": {
        borderColor: theme.palette.common.white,
        color: theme.palette.common.white,
    },
    "color-green": {
        borderColor: theme.palette.common.green,
        color: theme.palette.common.green,
    },
    "color-inherit": {
        borderColor: "inherit",
        color: "inherit",
    },
    "display-inline": {
        display: "inline",
    },
    "display-inline-block": {
        display: "inline-block",
    },
    "fontSize-7": {
        fontSize: "0.5rem",
    },
    "fontSize-10": {
        fontSize: "0.71rem",
    },
    "fontSize-12": {
        fontSize: "0.86rem",
    },
    "fontSize-13": {
        fontSize: "0.93rem",
    },
    "fontSize-14": {
        fontSize: "1rem",
    },
    "fontSize-16": {
        fontSize: "1.14rem",
    },
    "fontSize-18": {
        fontSize: "1.28rem",
    },
    "fontSize-20": {
        fontSize: "1.42rem",
    },
    "fontSize-22": {
        fontSize: "1.57rem",
    },
    "fontSize-24": {
        fontSize: "1.71rem",
    },
    "fontSize-28": {
        fontSize: "2rem",
    },
    "fontSize-30": {
        fontSize: "2.28rem",
    },
    "fontSize-36": {
        fontSize: "2.57rem",
    },
    "fontSize-40": {
        fontSize: "2.85rem",
    },
    "fontSize-56": {
        fontSize: "4rem",
    },
    "fontSize-64": {
        fontSize: "4.57rem",
    },
    "fontSize-80": {
        fontSize: "5.71rem",
    },
    "fontSize-112": {
        fontSize: "8rem",
    },
    "font-weight-300": {
        fontWeight: 300,
    },
    "font-weight-400": {
        fontWeight: 400,
    },
    "font-weight-500": {
        fontWeight: 500,
    },
    "font-weight-700": {
        fontWeight: 700,
    },
    "font-weight-bold": {
        fontWeight: "bold",
    },
    "line-height-1": {
        lineHeight: 1,
    },
    "line-height-14": {
        lineHeight: "14px",
    },
    "line-height-16": {
        lineHeight: "16px",
    },
    "line-height-21": {
        lineHeight: "21px",
    },
    "line-height-28": {
        lineHeight: "28px",
    },
    "line-height-36": {
        lineHeight: "36px",
    },
    "line-height-42": {
        lineHeight: "42px",
    },
    "minWidth-250": {
        minWidth: 250,
    },
    "padding-10": {
        padding: 10,
    },
    "padding-16": {
        padding: 16,
    },
    "padding-15": {
        padding: 15,
    },
    "padding-20": {
        padding: 20,
    },
    "padding-28": {
        padding: 28,
    },
    "padding-5": {
        padding: 5,
    },
    "padding-8": {
        padding: 8,
    },
    "padding-horizontal-0": {
        paddingLeft: "0px",
        paddingRight: "0px",
    },
    "padding-horizontal-10": {
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    "padding-horizontal-16": {
        paddingLeft: "16px",
        paddingRight: "16px",
    },
    "padding-horizontal-8": {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    "padding-vertical-0": {
        paddingTop: "0px",
        paddingBottom: "0px",
    },
    "padding-vertical-8": {
        paddingTop: "8px",
        paddingBottom: "8px",
    },
    "padding-vertical-10": {
        paddingTop: "8px",
        paddingBottom: "8px",
    },
    "padding-vertical-14": {
        paddingTop: "14px",
        paddingBottom: "14px",
    },
    "padding-vertical-16": {
        paddingTop: "16px",
        paddingBottom: "16px",
    },
    "padding-vertical-24": {
        paddingTop: "24px",
        paddingBottom: "24px",
    },
    "padding-vertical-32": {
        paddingTop: "16px",
        paddingBottom: "16px",
    },
    "padding-vertical-36": {
        paddingTop: "36px",
        paddingBottom: "36px",
    },
    "padding-vertical-42": {
        paddingTop: "42px",
        paddingBottom: "42px",
    },
    "mobile-horisontal-padding-0": {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    "white-space-nowrap": {
        whiteSpace: "nowrap",
    },
    "text-overflow-ellipsis": {
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    "word-wrap-normal": {
        wordWrap: "normal",
    },
});
/* eslint-enable sort-keys */
