// @flow
import React from "react";
import {ConnectedRouter} from "connected-react-router";
import {connect} from "react-redux";
import Routes from "./routes";
import SystemError from "./components/SystemError/SystemError";
import type {StoreType} from "./redux/reducers";
import {history} from "./history";
import InfoModal from "./components/ModalInfo/InfoModal";

export type OwnProps = {
    classes: Object,
};

type StoreProps = {|
    provider: string,
|};

export type Props = {...OwnProps, ...StoreProps};

const Root = ({provider, classes}: Props) =>
    provider ? (
        <ConnectedRouter history={history}>
            <div className={classes.center}>
                <Routes />
                <SystemError />
                <InfoModal />
            </div>
        </ConnectedRouter>
    ) : null;

export default connect<Props, OwnProps, StoreProps, _, StoreType, _>(
    ({config}: StoreType): StoreProps => ({
        provider: config.settings.providerRegType,
    }),
    null,
)(Root);
