// @flow

export const GET_AUTO_PAYMENT_STATUS_ACTION = "GET_AUTO_PAYMENT_STATUS_ACTION";
export const GET_AUTO_PAYMENT_STATUS_ACTION_SUCCESS = "GET_AUTO_PAYMENT_STATUS_ACTION_SUCCESS";
export const GET_AUTO_PAYMENT_STATUS_ACTION_FAILURE = "GET_AUTO_PAYMENT_STATUS_ACTION_FAILURE";

export const GET_AUTO_PAYMENT_DEFAULT_PARAMS_ACTION = "GET_AUTO_PAYMENT_DEFAULT_PARAMS_ACTION";
export const GET_AUTO_PAYMENT_DEFAULT_PARAMS_ACTION_SUCCESS = "GET_AUTO_PAYMENT_DEFAULT_PARAMS_ACTION_SUCCESS";
export const GET_AUTO_PAYMENT_DEFAULT_PARAMS_ACTION_FAILURE = "GET_AUTO_PAYMENT_DEFAULT_PARAMS_ACTION_FAILURE";

export const SAVE_AUTO_PAYMENT_PARAMS_ACTION = "SAVE_AUTO_PAYMENT_PARAMS_ACTION";
export const SAVE_AUTO_PAYMENT_PARAMS_ACTION_SUCCESS = "SAVE_AUTO_PAYMENT_PARAMS_ACTION_SUCCESS";
export const SAVE_AUTO_PAYMENT_PARAMS_ACTION_FAILURE = "SAVE_AUTO_PAYMENT_PARAMS_ACTION_FAILURE";

export const REG_AUTO_PAYMENT_ACTION = "REG_AUTO_PAYMENT_ACTION";
export const REG_AUTO_PAYMENT_ACTION_SUCCESS = "REG_AUTO_PAYMENT_ACTION_SUCCESS";
export const REG_AUTO_PAYMENT_ACTION_FAILURE = "REG_AUTO_PAYMENT_ACTION_FAILURE";

export const GET_AUTO_PAYMENT_REQUEST_INFO_ACTION = "GET_AUTO_PAYMENT_REQUEST_INFO_ACTION";
export const GET_AUTO_PAYMENT_REQUEST_INFO_ACTION_SUCCESS = "GET_AUTO_PAYMENT_REQUEST_INFO_ACTION_SUCCESS";
export const GET_AUTO_PAYMENT_REQUEST_INFO_ACTION_FAILURE = "GET_AUTO_PAYMENT_REQUEST_INFO_ACTION_FAILURE";

export const EDIT_AUTO_PAYMENT_PARAMS_ACTION = "EDIT_AUTO_PAYMENT_PARAMS_ACTION";
export const EDIT_AUTO_PAYMENT_PARAMS_ACTION_SUCCESS = "EDIT_AUTO_PAYMENT_PARAMS_ACTION_SUCCESS";
export const EDIT_AUTO_PAYMENT_PARAMS_ACTION_FAILURE = "EDIT_AUTO_PAYMENT_PARAMS_ACTION_FAILURE";

export const GET_AUTO_PAYMENT_ANNUL_REASONS_ACTION = "GET_AUTO_PAYMENT_ANNUL_REASONS_ACTION";
export const GET_AUTO_PAYMENT_ANNUL_REASONS_ACTION_SUCCESS = "GET_AUTO_PAYMENT_ANNUL_REASONS_ACTION_SUCCESS";
export const GET_AUTO_PAYMENT_ANNUL_REASONS_ACTION_FAILURE = "GET_AUTO_PAYMENT_ANNUL_REASONS_ACTION_FAILURE";

export const DDOE_LINK_ACTION = "DDOE_LINK_ACTION";
export const DDOE_LINK_ACTION_SUCCESS = "DDOE_LINK_ACTION_SUCCESS";
export const DDOE_LINK_ACTION_FAILURE = "DDOE_LINK_ACTION_FAILURE";
