// @flow

import * as React from "react";
import MuiPickersUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";
import moment from "moment";
import {withStyles} from "@material-ui/core/styles";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import DatePicker from "material-ui-pickers/DatePicker";
import {Event, KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import {Field} from "react-final-form";
import {get} from "lodash/object";
import type {DateType} from "material-ui-pickers/constants/prop-types";
import TextField from "../../../components/Form/TextField";
import {CLIENT_SIDE_DATE_FORMAT, SERVER_SIDE_DATE_FORMAT} from "../../../../constants/DateFormat";
import {required} from "../../../validations/baseValidations";

const styles = (theme: any) => ({
    primaryLightColor: {
        color: `${theme.palette.primary.light}`,
    },
});
type Props = {
    name: string,
    prRequired?: boolean,
    margin?: "none" | "dense" | "normal",
    content?: Array<{
        vlContent: string,
    }>,
    staticContent?: string,
    onChange?: (event: SyntheticInputEvent<>) => void,
    onClick?: (event: SyntheticInputEvent<>) => void,
    minDate?: DateType,
    maxDate?: DateType,
    classes?: Object,
    config?: Object,
};

export const CalendarElement = (props: Props) => {
    const {classes = {}, name, content, prRequired, minDate, maxDate} = props;

    return (
        <Field
            name={name}
            validate={prRequired ? required : undefined}
            render={({input, meta}) => {
                const dateValue = input.value
                    ? moment(input.value, props.config?.customFormat || SERVER_SIDE_DATE_FORMAT)
                    : null;

                return (
                    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale="ru">
                        <DatePicker
                            inputProps={{
                                ...input,
                                onFocus: (event: any) => {
                                    input.onFocus(event);
                                },
                            }}
                            onChange={(event) => {
                                const date = event
                                    ? event.format(props.config?.customFormat || SERVER_SIDE_DATE_FORMAT)
                                    : undefined;

                                input.onChange(date);
                            }}
                            format={CLIENT_SIDE_DATE_FORMAT}
                            label={get(content, "0.vlContent")}
                            TextFieldComponent={TextField}
                            value={dateValue}
                            required={prRequired}
                            minDate={minDate}
                            maxDate={maxDate}
                            meta={meta}
                            error={meta.invalid || meta.error}
                            helperText={meta.invalid || meta.error ? meta.error : undefined}
                            clearable
                            keyboard
                            keyboardIcon={<Event className={classes.primaryLightColor} />}
                            rightArrowIcon={<KeyboardArrowRight className={classes.primaryLightColor} />}
                            leftArrowIcon={<KeyboardArrowLeft className={classes.primaryLightColor} />}
                            clearLabel="Очистить"
                            cancelLabel="Отменить"
                            okLabel="Принять"
                            invalidDateMessage="Неверная дата"
                            maxDateMessage="Дата не может быть больше текущей"
                        />
                    </MuiPickersUtilsProvider>
                );
            }}
        />
    );
};

export default withStyles(styles)(CalendarElement);
