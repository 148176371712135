// @flow
import React, {Component, Fragment} from "react";
import {withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import {type BrowserHistory} from "history/createBrowserHistory";
import ListSubheader from "@material-ui/core/ListSubheader";
import forEach from "lodash/forEach";
import get from "lodash/get";
import {
    type DeleteAccountActionType,
    type AccountClearAccountDetailActionType,
} from "../../redux/actions/accountAction";
import Content from "../../packages/components/Content/Content";
import type {GetMenuSettingsActionType, GetMultipleLSPayVisibilityActionType} from "../../redux/actions/configAction";
import {styles} from "./SideBarStyle";
import SideBarAccountDetail from "./SideBarAccountDetail";
import SideBarAccountInfo from "./SideBarAccountInfo";
import SideBarAccountDelete from "./SideBarAccountDelete";
import SideBarAccountSettings from "./SideBarAccountSettings";

export type Props = {
    auth: Object,
    accountsList: Array<Object>,
    classes?: Object,
    clearAccountDetailAction: AccountClearAccountDetailActionType,
    currentLs: string,
    accountsByAddress: any,
    session: string,
    deleteAccountAction: DeleteAccountActionType,
    location: Object,
    history: BrowserHistory,
    lsNoticeStatusList: Object,
    getMenuSettings: GetMenuSettingsActionType,
    lsDescriptionLength: number,
    getMultipleLSPayVisibilityAction: GetMultipleLSPayVisibilityActionType,
    handleCloseSideBar: () => void,
};
export type State = {
    settingsIdService: ?number,
};
class SideBarAccounts extends Component<Props, State> {
    state = {
        settingsIdService: undefined,
    };

    changePanel = (settingsIdService: number) => {
        this.setState((state) => {
            if (settingsIdService === state.settingsIdService) {
                return {settingsIdService: undefined};
            }

            return {settingsIdService};
        });
    };

    renderMenuItems(accounts) {
        return accounts.map((account) => (
            <SideBarAccountInfo
                key={account.nnLs}
                account={account}
                location={this.props.location}
                changePanel={this.changePanel}
                settingsIdService={this.state.settingsIdService}
            >
                <Fragment>
                    {account.prLsGroupEdit ? (
                        <SideBarAccountSettings
                            idService={account.idService}
                            handelCloseSideBar={this.props.handleCloseSideBar}
                        />
                    ) : null}
                    <SideBarAccountDelete
                        auth={this.props.auth}
                        accountsList={this.props.accountsList}
                        clearAccountDetailAction={this.props.clearAccountDetailAction}
                        session={this.props.session}
                        nnLs={account.nnLs}
                        idService={account.idService}
                        deleteAccountAction={this.props.deleteAccountAction}
                        currentLs={this.props.currentLs}
                        history={this.props.history}
                        getMenuSettings={this.props.getMenuSettings}
                        getMultipleLSPayVisibilityAction={this.props.getMultipleLSPayVisibilityAction}
                    />
                </Fragment>
                <SideBarAccountDetail
                    auth={this.props.auth}
                    account={account}
                    status={get(this.props.lsNoticeStatusList, `${account.idService}`, {})}
                    session={this.props.session}
                    lsDescriptionLength={this.props.lsDescriptionLength}
                    handleCloseSideBar={this.props.handleCloseSideBar}
                />
            </SideBarAccountInfo>
        ));
    }

    render() {
        const {accountsByAddress, classes = {}} = this.props;
        const menus = [];

        let index = 0;

        forEach(accountsByAddress, (accounts, address: string) => {
            const subhead = (
                <Tooltip
                    title={accounts[0].nmLsGroupFull}
                    placement="bottom"
                    classes={{
                        popper: classes.tooltipOverride,
                    }}
                    enterTouchDelay={0}
                >
                    <ListSubheader
                        component="div"
                        classes={{root: classes.subheadAddress}}
                        testId={`sideBarAddress-${index}`}
                    >
                        {address}
                    </ListSubheader>
                </Tooltip>
            );

            menus.push(
                <List key={address} subheader={subhead} disablePadding={true}>
                    {this.renderMenuItems(accounts)}
                </List>,
            );
            index += 1;
        });

        return (
            <Content overflowX="hidden" overflowY="auto" className={classes.accountsListHeight}>
                {menus}
            </Content>
        );
    }
}

export default withStyles(styles)(SideBarAccounts);
