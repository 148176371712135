/* eslint-disable global-require */
// @flow
if (
    process.env.REACT_APP_AUTHOR &&
    process.env.REACT_APP_ANDROID_TITLE &&
    process.env.REACT_APP_APPLE_STORE_ID &&
    process.env.REACT_APP_PLAY_MARKET_ID
) {
    require("smartbanner.js/dist/smartbanner.min");
    require("smartbanner.js/dist/smartbanner.min.css");
}
